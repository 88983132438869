// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Icons
import { Add } from "iconsax-react";

// Types
import type { Message } from "../../types/chat";

// Components
import Text from "../UI/Text";
import CustomTooltip from "../UI/Tooltip";
import MessageDate from "./MessageDate";
import Avatar from "../UI/Avatar/Avatar";

// Utils
import { _getIconForFileType } from "../../utils/helpers/getIconForFileType";

// Context
import { useUser } from "../../utils/store/userStore";
import { useSocket } from "../../context/Socket";

const Sender = ({
  item,
  isOpen,
  setIsOpen
}: {
  item: Message;
  isOpen: boolean;
  setIsOpen: (id: number | null) => void;
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const { socket } = useSocket();
  const user = useUser((state) => state.userdata);

  // Close popup when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef, setIsOpen]);

  const sendReaction = (value: string) => {
    if (socket && item.messageId) {
      const data = { messageId: item.messageId, reaction: value };
      socket?.emit(`${user?.roleName}/react`, data, () => {
        setIsOpen(null);
      });
    }
  };

  // Dictionnaire pour les réactions
  const reactionMap: { [key: string]: string } = {
    like: "&#128077;",
    dislike: "&#128078;",
    love: "&#128147;",
    laugh: "&#128514;"
  };

  const currentReaction =
    item.reactions && item.reactions.length > 0
      ? reactionMap[item.reactions[0].type]
      : null;

  const likeReaction = item.reactions.filter((item) => item.type === "like");
  const dislikeReaction = item.reactions.filter(
    (item) => item.type === "dislike"
  );
  const loveReaction = item.reactions.filter((item) => item.type === "love");
  const laughReaction = item.reactions.filter((item) => item.type === "laugh");

  return (
    <div className="flex flex-row gap-2">
      <Avatar link={item.sender.image} className="size-10" />
      <div className="relative flex max-w-[70%] flex-col gap-2">
        <div className="relative flex max-w-xs flex-col gap-1 break-words rounded-lg rounded-tl-none bg-blueViolet-400 p-2 text-sm text-white">
          <Text className="whitespace-pre-wrap break-words text-right text-white">
            {typeof item.content === "object"
              ? item.content.message
              : item.content}
          </Text>
          {item.attachment && (
            <Link
              to={item.attachment.url}
              className="flex flex-col items-center gap-2 rounded-lg border bg-white p-2 text-black sm:flex-row"
            >
              <div className="flex items-center justify-center rounded-lg bg-blueViolet-50 p-2">
                {_getIconForFileType(item.attachment.fileExt)}
              </div>
              <div className="flex flex-col">
                <Text className="text-sm text-withCustom-500">
                  {item.attachment.fileName}
                </Text>
                <Text className="text-sm text-withCustom-500">
                  {item.attachment.fileExt.toUpperCase()}
                </Text>
              </div>
            </Link>
          )}
        </div>

        <div className="flex flex-row gap-1">
          <div
            onClick={() =>
              setIsOpen(isOpen ? null : item.messageId ? item.messageId : null)
            }
            className="flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-full border border-blueViolet-200 bg-white p-1 text-xs hover:bg-blueViolet-200"
          >
            <Add size={12} />
          </div>
          {(likeReaction.length > 0 ||
            dislikeReaction.length > 0 ||
            loveReaction.length > 0 ||
            laughReaction.length > 0) && (
            <div className="flex h-[22px] flex-row items-center gap-1 bg-white">
              {likeReaction.length > 0 && (
                <CustomTooltip
                  position="top"
                  content={likeReaction
                    .map((react) => react.sender.firstName)
                    .join(", ")}
                >
                  <div
                    onClick={() => sendReaction("like")}
                    className="flex cursor-pointer flex-row items-center gap-1 rounded-full border bg-white px-2 py-1 hover:bg-blueViolet-200"
                  >
                    <span
                      className="text-xs"
                      dangerouslySetInnerHTML={{ __html: reactionMap.like }}
                    />
                    <Text className="text-xs">{likeReaction.length}</Text>
                  </div>
                </CustomTooltip>
              )}
              {dislikeReaction.length > 0 && (
                <CustomTooltip
                  position="top"
                  content={dislikeReaction
                    .map((react) => react.sender.firstName)
                    .join(", ")}
                >
                  <div
                    onClick={() => sendReaction("dislike")}
                    className="flex cursor-pointer flex-row items-center gap-1 rounded-full border bg-white px-2 py-1 hover:bg-blueViolet-200"
                  >
                    <span
                      className="text-xs"
                      dangerouslySetInnerHTML={{ __html: reactionMap.dislike }}
                    />
                    <Text className="text-xs">{dislikeReaction.length}</Text>
                  </div>
                </CustomTooltip>
              )}
              {loveReaction.length > 0 && (
                <CustomTooltip
                  position="top"
                  content={loveReaction
                    .map((react) => react.sender.firstName)
                    .join(", ")}
                >
                  <div
                    onClick={() => sendReaction("love")}
                    className="flex cursor-pointer flex-row items-center gap-1 rounded-full border bg-white px-2 py-1 hover:bg-blueViolet-200"
                  >
                    <span
                      className="text-xs"
                      dangerouslySetInnerHTML={{ __html: reactionMap.love }}
                    />
                    <Text className="text-xs">{loveReaction.length}</Text>
                  </div>
                </CustomTooltip>
              )}
              {laughReaction.length > 0 && (
                <CustomTooltip
                  position="top"
                  content={laughReaction
                    .map((react) => react.sender.firstName)
                    .join(", ")}
                >
                  <div
                    onClick={() => sendReaction("laugh")}
                    className="flex cursor-pointer flex-row items-center gap-1 rounded-full border bg-white px-2 py-1 hover:bg-blueViolet-200"
                  >
                    <span
                      className="text-xs"
                      dangerouslySetInnerHTML={{ __html: reactionMap.laugh }}
                    />
                    <Text className="text-xs">{laughReaction.length}</Text>
                  </div>
                </CustomTooltip>
              )}
            </div>
          )}
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              ref={popupRef}
              key={"test"}
              initial={{ y: 5, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 5, opacity: 0 }}
              className="absolute right-[-90px] top-8 flex flex-row gap-1 rounded-sm border bg-white p-1 shadow-lg"
            >
              <span
                onClick={() => sendReaction("like")}
                className="cursor-pointer hover:scale-105"
              >
                &#128077;
              </span>
              <span
                onClick={() => sendReaction("dislike")}
                className="cursor-pointer hover:scale-105"
              >
                &#128078;
              </span>
              <span
                onClick={() => sendReaction("love")}
                className="cursor-pointer hover:scale-105"
              >
                &#128147;
              </span>
              <span
                onClick={() => sendReaction("laugh")}
                className="cursor-pointer hover:scale-105"
              >
                &#128514;
              </span>
            </motion.div>
          )}
        </AnimatePresence>
        <MessageDate
          createdAt={item.createdAt}
          className="text-start text-xs text-textGray"
        />
      </div>
    </div>
  );
};

export default Sender;
