// Requires
import { Link } from "react-router-dom";

// Components
import { HorizontalDivider } from "../../../UI/Divider";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";
import { useEffect, useState } from "react";
import { getProposal } from "../../../../services/Job/proposal";
import { ProposalDetails } from "../../../../types/proposal";
import Avatar from "../../../UI/Avatar/Avatar";

const UnderReview = () => {
  const [data, setData] = useState<ProposalDetails[]>();
  const [count, setCount] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const response = await getProposal(0, 10, undefined, true);
      console.log(response);
      if (response) {
        setData(response.proposals);
        setCount(response.count);
        setIsLoading(false);
      }
    };

    fetch();
  }, []);

  return (
    <div className="flex flex-col gap-4 p-2 md:w-[calc(100%/3)] md:max-w-[calc(100%/3)]">
      <div className="flex flex-row items-center gap-4">
        <Title type="bloc">Proposition envoyée</Title>
        <Text className="text-sm text-withCustom-500">{count}</Text>
      </div>
      {isLoading ? (
        <div className="loader" />
      ) : (
        <div className="flex max-h-[400px] flex-col gap-4 overflow-scroll md:pr-7">
          {data && data.length > 0 ? (
            data.map((proposal) => {
              return (
                <Link
                  key={proposal.id}
                  to={"/admin/jobs/"}
                  className="flex cursor-pointer flex-col gap-4 rounded-lg border p-4 shadow-lg hover:bg-blueViolet-50"
                >
                  <Text className="max-w-full text-wrap break-words font-semibold">
                    {proposal.job.title}
                  </Text>
                  <Text className="max-w-full text-wrap break-words text-sm">
                    {proposal.motivation}
                  </Text>
                  <HorizontalDivider />
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-2">
                      <Avatar
                        link={proposal.company.image}
                        className="size-6"
                      />
                      <Text>{proposal.company.displayName}</Text>
                    </div>
                    <Text>{proposal.price}&euro;</Text>
                  </div>
                </Link>
              );
            })
          ) : (
            <p>Aucune proposition pour le moment</p>
          )}
        </div>
      )}
    </div>
  );
};

export default UnderReview;
