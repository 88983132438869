const Avatar = ({
  link,
  className
}: {
  link: string | undefined;
  className: string;
}) => {
  return (
    <img
      src={link && link.length > 0 ? link : "https://picsum.photos/150/150"}
      className={`rounded-lg object-cover object-center ${className}`}
      alt="Avatar utilisateur"
    />
  );
};

export default Avatar;
