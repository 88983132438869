// Requires
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// Components
import Text from "../Text";
import { ArrowUp2 } from "iconsax-react";

const ButtonWithDrawer = ({
  children,
  name
}: {
  children: React.ReactNode;
  name: string;
}) => {
  const [openDrawerIndex, setOpenDrawerIndex] = useState<number | null>(null);
  const [drawerPosition, setDrawerPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleButtonClick = (
    index: number,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setDrawerPosition({ top: rect.bottom + window.scrollY, left: rect.left });
    setOpenDrawerIndex(openDrawerIndex === index ? null : index);
  };

  return (
    <div className="relative">
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
          handleButtonClick(0, e)
        }
      >
        <Text
          className={`flex cursor-pointer flex-row items-center gap-2 text-nowrap rounded-lg border p-2 hover:bg-blueViolet-50 hover:!text-blueViolet-600 ${openDrawerIndex !== null ? "bg-blueViolet-50 !text-blueViolet-600" : ""}`}
        >
          {name}{" "}
          <ArrowUp2
            size={18}
            className={`${openDrawerIndex !== null ? "rotate-180" : "rotate-0"} duration-150`}
          />
        </Text>
      </div>

      <AnimatePresence>
        {openDrawerIndex !== null && drawerPosition && (
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 5 }}
            className="fixed z-10 flex max-h-[200px] flex-col gap-2 overflow-y-auto rounded-lg border bg-white p-4 shadow-lg"
            style={{
              top: drawerPosition.top + 12,
              left: drawerPosition.left,
              transform: "translateY(10px)"
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ButtonWithDrawer;
