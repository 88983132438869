// PreciseAvailabilityCheckbox.tsx

import React, { useState } from "react";
import CustomTooltip from "../../../UI/Tooltip";
import Text from "../../../UI/Text";
import Disponibilities from "../../../Onboarding/Student/Disponibilities";
import { InfoCircle } from "iconsax-react";

// Types
interface JobCreateType {
  studentWeeklyAvailability?: any; // Replace 'any' with the correct type if known
  // Add other fields if necessary
}

interface ErrorType {
  checkbox?: string;
}

interface PreciseAvailabilityCheckboxProps {
  data: JobCreateType;
  setData: React.Dispatch<React.SetStateAction<JobCreateType>>;
  errors: ErrorType;
}

const PreciseAvailabilityCheckbox: React.FC<
  PreciseAvailabilityCheckboxProps
> = ({ data, setData, errors }) => {
  const [hasPreciseAvailability, setHasPreciseAvailability] =
    useState<boolean>(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasPreciseAvailability(e.target.checked);
  };

  return (
    <div className="flex flex-col gap-2">
      {/* Checkbox and Label */}
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="preciseAvailability"
          checked={hasPreciseAvailability}
          onChange={handleCheckboxChange}
          className="h-4 w-4 rounded border-gray-300 text-blueViolet-600 focus:ring-blueViolet-500"
        />
        <Text>Nous avons des contraintes de disponibilité plus précises</Text>
        <CustomTooltip
          position="top"
          content="Si vous avez besoin de disponibilités précises dans la semaine, signalez-le ici et gagnez du temps dans votre recrutement !"
          className="ml-2"
        >
          <InfoCircle size={16} className="cursor-pointer" />
        </CustomTooltip>
      </div>
      {/* Conditionally Render Disponibilities */}
      {hasPreciseAvailability && (
        <Disponibilities
          setDataWeeklyAvailability={(availabilityData: any) => {
            setData((prevData) => ({
              ...prevData,
              studentWeeklyAvailability: availabilityData
            }));
          }}
          error={errors?.checkbox}
        />
      )}
    </div>
  );
};

export default PreciseAvailabilityCheckbox;
