// Requires
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Context

// Components
import Text from "../../UI/Text";
import Date from "../../UI/Date";

// Types
import { ActiveJobsForStudent } from "../../../types/proposal";

// Services
import { getActivesJobs } from "../../../services/Job/job";
import Avatar from "../../UI/Avatar/Avatar";

export const Table = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [data, setData] = useState<ActiveJobsForStudent[]>();
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (Number(value) < 10) {
      setLimit(10);
      return;
    }

    if (Number(value) > 100) {
      setLimit(100);
      return;
    }

    setLimit(Number(value));
    return;
  };

  const filters = {
    search: searchParams.get("search"),
    date: searchParams.get("date"),
    type: searchParams.get("type"),
    startingType: searchParams.get("startingType")
  };

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const response = await getActivesJobs(page, limit, filters);
      setCount(response.count);
      setData(response.jobs);
      setIsLoading(false);
    };
    fetch();
  }, [
    page,
    limit,
    filters.search,
    filters.date,
    filters.startingType,
    filters.type
  ]);

  return (
    <div className="flex flex-col overflow-x-auto">
      <table className="w-full table-auto">
        <thead className="border-b border-t bg-backgroundGray">
          <tr>
            <th className="px-4 py-2 text-left">Titre de la mission</th>
            <th className="px-4 py-2 text-left">Entreprise</th>
            <th className="px-4 py-2 text-left">Montant Total (HT)</th>
            <th className="px-4 py-2 text-left">Date de début</th>
            <th className="px-4 py-2 text-left">Type</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            data &&
            data?.map((item) => {
              return (
                <tr
                  key={item.id}
                  className="cursor-pointer py-2 hover:bg-backgroundGray"
                >
                  <td
                    onClick={() => navigate(`../${item.id}`)}
                    className="border-t px-4 py-2"
                  >
                    {item.title}
                  </td>
                  <td className="border-t px-4 py-2">
                    <div className="flex flex-row gap-2">
                      <Avatar
                        link={item.company.image}
                        className="size-6 rounded-lg"
                      />
                      <Text>{item.company.displayName}</Text>
                    </div>
                  </td>
                  <td className="border-t px-4 py-2">
                    {item.totalAmount}&euro;
                  </td>
                  <td className="border-t px-4 py-2">
                    {item.startingType === "Date" ? (
                      <Date date={item.startDate} format="DD/MM/YYYY" />
                    ) : item.startingType === "asap" ? (
                      "Dès que possible"
                    ) : (
                      "Non spécifié"
                    )}
                  </td>
                  <td className="border-t px-4 py-2">
                    {item.type === "recurrent" ? "Recurrent" : "Ponctuelle"}
                  </td>
                </tr>
              );
            })}
          {isLoading && (
            <tr>
              <td colSpan={5}>
                <div className="flex items-center justify-center p-2">
                  <div className="loader" />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex flex-row justify-between border-t p-4 text-sm">
        <div className="flex flex-row items-center gap-2">
          <Text>Afficher</Text>
          <input
            type="number"
            className="w-[80px] rounded-lg border p-2 focus:outline-blueViolet-600"
            value={limit}
            min={10}
            max={100}
            onChange={handleInputChange}
          />
          <Text>missions sur {count}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => {
              setOffset(offset - limit);
              setPage(page - 1);
            }}
            disabled={offset === 0}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &lt;
          </button>
          {count &&
            [...Array(Math.ceil(count / limit))].map((_, i) => {
              const pageNumber = i + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => {
                    setOffset((pageNumber - 1) * limit);
                    setPage(pageNumber - 1);
                  }}
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                    pageNumber === Math.ceil(offset / limit) + 1
                      ? "bg-blueViolet-600 text-white"
                      : ""
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}
          <button
            onClick={() => {
              setOffset(offset + limit);
              setPage(page + 1);
            }}
            disabled={count === undefined ? true : offset + limit >= count}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
