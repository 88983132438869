// Requires
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// Components
import { LittleCard } from "../../../../../components/Cards/Company/HorizontalLittleCard";
import JobResume from "../../../../../components/Jobs/Details/JobResume";
import Remember from "../../../../../components/Jobs/Details/Remember";
import Button from "../../../../../components/UI/Button";
import Title from "../../../../../components/UI/Title";
import ProfilSought from "../../../../../components/Jobs/Details/ProfilSought";

// Services
import { getJobDetails } from "../../../../../services/Job/job";

// Types
import type { JobDetailsType } from "../../../../../types/job";

const JobDetails = () => {
  /* status index 2 = hiring
     status index 3 = hired
     status index 4 = closed
    */

  const { id } = useParams();

  const [data, setData] = useState<JobDetailsType>();

  useEffect(() => {
    const fetch = async () => {
      const response = await getJobDetails(id);
      setData(response);
    };

    fetch();
  }, [id]);

  if (data) {
    return (
      <div className="flex flex-col gap-24 max-md:gap-6">
        <div className="flex flex-row items-center gap-4">
          <Title type="page">{data?.title}</Title>
        </div>
        <div className="flex flex-col gap-8">
          <div className="md:hidden">
            <LittleCard data={data} />
          </div>
          <div className="flex flex-row gap-8 max-md:flex-col">
            <div className="flex flex-1 flex-col gap-8 md:max-w-[calc(100%-530px)]">
              <Remember data={data} />
              {data.status.index < 3 && <ProfilSought data={data} />}
            </div>
            <div className="flex h-fit flex-col gap-8 max-md:flex-col-reverse md:min-w-[450px]">
              {data.status.index < 3 &&
                (data.hasProposal ? (
                  <Button type="disabled">
                    Proposition commerciale envoyée
                  </Button>
                ) : (
                  <Link to={`proposal`}>
                    <Button type="full">Postuler à la mission</Button>
                  </Link>
                ))}
              <div className="max-md:hidden">
                <LittleCard data={data} />
              </div>
              <JobResume data={data} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <div className="loader" />
    </div>
  );
};

export default JobDetails;
