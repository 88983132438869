// Components
import { Additem } from "iconsax-react";
import Text from "./Text";
import Title from "./Title";

// Types et configuration pour chaque type de bloc
type blocType = "expériences" | "formations" | "portfolio" | "certifications";

const blocConfig = {
  expériences: {
    articleSingulier: "une",
    articlePluriel: "tes",
    singulier: "expérience",
    pluriel: "expériences",
    title: undefined
  },
  formations: {
    articleSingulier: "une",
    articlePluriel: "tes",
    singulier: "formation",
    pluriel: "formations",
    title: undefined
  },
  portfolio: {
    articleSingulier: "un",
    articlePluriel: "ton",
    singulier: "document",
    pluriel: "document",
    title: "Ton portfolio ou tout autre document utile pour ton profil"
  },
  certifications: {
    articleSingulier: "une",
    articlePluriel: "tes",
    singulier: "certification",
    pluriel: "certifications",
    title: undefined
  }
};

const AddNew = ({ type }: { type: blocType }) => {
  const { articleSingulier, articlePluriel, singulier, pluriel, title } =
    blocConfig[type];

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-2">
        <Title type="page">
          {title || (
            <>
              <span className="capitalize">{articlePluriel}</span> {pluriel}
            </>
          )}
        </Title>
        <Text className="text-xs"> Recommandé</Text>
      </div>
      <div className="flex flex-row items-center gap-4 rounded-lg border p-4 hover:border-blueViolet-600">
        <div className="flex items-center justify-center rounded-lg bg-backgroundGray p-4">
          <Additem size={18} />
        </div>
        <Title type="bloc">
          Ajouter {articleSingulier} {singulier}
        </Title>
      </div>
    </div>
  );
};

export default AddNew;
