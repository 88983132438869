// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

// Icons
import { CircleAlert } from "lucide-react";

// Components
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Types
import type { JobCreateType } from "../../../../../../types/job";

// Context
import { useEffect } from "react";
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import CustomTooltip from "../../../../../../components/UI/Tooltip";
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";
import CitySearchComponent from "../../../../../../components/Misc/CitySearchComponent";

// Types
interface Props {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  updateFormData: (newData: { [key: string]: any }) => void;
}

type Error = {
  workMode?: string;
  location?: string;
  workingFrequency?: string;
};

const Step6 = ({ updateFormData }: Props) => {
  const [data, setData] = useState<JobCreateType>({});

  const [errors, setErrors] = useState<Error>({});

  const { handleNextStep, handlePrevStep, step } = useUI();

  const handleWorkMode = (type: "remote" | "onSite" | "hybrid") => {
    setData((prev) => ({ ...prev, workMode: type }));
  };

  const nextStep = () => {
    const newError: Error = {};

    if (!data.workMode) {
      newError.workMode = "Vous devez sélectionner un mode de travail";
    }

    if (
      data.workMode &&
      (data.workMode === "onSite" || data.workMode === "hybrid")
    ) {
      if (!data.location || !data.cityId) {
        newError.location = "Vous devez indiquer la ville de la mission";
      }
    }

    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      return;
    }

    const localData = [
      {
        key: "step3",
        value: JSON.stringify({
          workMode: data.workMode,
          workingFrequency: data.workingFrequency || 0,
          location: data.location,
          cityId: data.cityId
        })
      },
      { key: "publishJobStep", value: "3" }
    ];

    setLocalStorage(localData);
    updateFormData(data);
    handleNextStep();
  };

  useEffect(() => {
    const localData = getLocalStorage("step3");
    if (localData) {
      const formatedLocalData: JobCreateType = JSON.parse(localData);
      setData({
        workMode: formatedLocalData.workMode,
        workingFrequency: Number(formatedLocalData.workingFrequency),
        location: formatedLocalData.location
      });
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center gap-16 max-md:gap-8">
      <Title type="page">Mode de travail</Title>
      <div className="flex w-full flex-col gap-16 max-md:gap-8 md:w-[70%]">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            {errors.workMode && (
              <Text className="text-sm text-red-500">{errors.workMode}</Text>
            )}
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div
              className={`flex cursor-pointer flex-col justify-between gap-4 overflow-hidden rounded-lg border p-8 ${data.workMode === "remote" ? "border-blueViolet-600 bg-white" : "border-blueViolet-200 hover:border-blueViolet-600"}`}
              onClick={() => handleWorkMode("remote")}
            >
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center gap-2">
                  <input
                    type="checkbox"
                    id="workModeRemote"
                    className="peer hidden"
                    checked={data.workMode === "remote"}
                    readOnly
                  />
                  <label
                    htmlFor="workModeRemote"
                    className="relative inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-gray-300 bg-white bg-clip-content p-0.5 hover:border-gray-400 peer-checked:border-indigo-600 peer-checked:bg-indigo-600"
                  ></label>
                  <Text
                    className={
                      data.workMode === "remote" ? "font-semibold" : ""
                    }
                  >
                    Télétravail
                  </Text>
                </div>
                <CustomTooltip
                  position="top"
                  content="La plupart de nos missions se font en télétravail. C’est le mode privilégié par les étudiants."
                  className="max-w-[200px]"
                >
                  <CircleAlert size={18} />
                </CustomTooltip>
              </div>
            </div>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div
              className={`flex cursor-pointer flex-col justify-between gap-4 overflow-hidden rounded-lg border p-8 ${data.workMode === "onSite" ? "border-blueViolet-600 bg-white" : "border-blueViolet-200 hover:border-blueViolet-600"}`}
              onClick={() => handleWorkMode("onSite")}
            >
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center gap-2">
                  <input
                    type="checkbox"
                    id="workModeOnSite"
                    className="peer hidden"
                    checked={data.workMode === "onSite"}
                    readOnly
                  />
                  <label
                    htmlFor="workModeOnSite"
                    className="relative inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-gray-300 bg-white bg-clip-content p-0.5 hover:border-gray-400 peer-checked:border-indigo-600 peer-checked:bg-indigo-600"
                  ></label>
                  <Text
                    className={
                      data.workMode === "onSite" ? "font-semibold" : ""
                    }
                  >
                    Sur site
                  </Text>
                </div>
                <CustomTooltip
                  position="top"
                  content="La mission s’effectuera en totalité en présentiel. Il vous faudra alors préciser la ville de la mission."
                  className="max-w-[200px]"
                >
                  <CircleAlert size={18} />
                </CustomTooltip>
              </div>
              {data.workMode === "onSite" && errors.location && (
                <Text className="text-sm text-red-500">{errors.location}</Text>
              )}
              <AnimatePresence>
                {data.workMode === "onSite" && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: "auto" }}
                    exit={{ height: 0 }}
                  >
                    <Text className="mx-14 font-semibold">
                      Lieu de la mission
                    </Text>
                    <div className="mx-14 w-1/2">
                      <CitySearchComponent
                        setCityData={(selectedCity: {
                          id: number;
                          label: string;
                          zip_code: number;
                        }) => {
                          setData((prev: any) => ({
                            ...prev,
                            location: selectedCity.label,
                            cityId: selectedCity.id
                          }));
                        }}
                        error={errors.location}
                        setError={(value) => {
                          setErrors((prev) => ({ ...prev, location: value }));
                        }}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div
              className={`flex cursor-pointer flex-col justify-between gap-2 overflow-hidden rounded-lg border p-8 ${data.workMode === "hybrid" ? "border-blueViolet-600 bg-white" : "border-blueViolet-200 hover:border-blueViolet-600"}`}
              onClick={() => handleWorkMode("hybrid")}
            >
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center gap-2">
                  <input
                    type="checkbox"
                    id="workModeHybrid"
                    className="peer hidden"
                    checked={data.workMode === "hybrid"}
                    readOnly
                  />
                  <label
                    htmlFor="workModeHybrid"
                    className="relative inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-gray-300 bg-white bg-clip-content p-0.5 hover:border-gray-400 peer-checked:border-indigo-600 peer-checked:bg-indigo-600"
                  ></label>
                  <Text
                    className={
                      data.workMode === "hybrid"
                        ? "font-semibold text-gray-600"
                        : ""
                    }
                  >
                    Hybride
                  </Text>
                </div>
                <CustomTooltip
                  position="top"
                  content="La mission s’effectuera partiellement en télétravail et sur site. Il vous faudra alors préciser la ville de la mission. Vous conviendrez entre vous de la fréquence de télétravail"
                  className="max-w-[200px]"
                >
                  <CircleAlert size={18} />
                </CustomTooltip>
              </div>
              <AnimatePresence>
                {data.workMode === "hybrid" && (
                  <motion.div
                    className="flex flex-col"
                    initial={{ height: 0 }}
                    animate={{ height: "auto" }}
                    exit={{ height: 0 }}
                  >
                    {data.workMode === "hybrid" && errors.location && (
                      <Text className="text-sm text-red-500">
                        {errors.location}
                      </Text>
                    )}
                    <Text className="mx-14 font-semibold">
                      Lieu de la mission
                    </Text>
                    <div className="mx-14 w-1/2">
                      <CitySearchComponent
                        setCityData={(selectedCity: {
                          id: number;
                          label: string;
                          zip_code: number;
                        }) => {
                          setData((prev: any) => ({
                            ...prev,
                            location: selectedCity.label,
                            cityId: selectedCity.id
                          }));
                        }}
                      />
                    </div>
                    {data.workMode === "hybrid" && errors.workingFrequency && (
                      <Text className="text-sm text-red-500">
                        {errors.workingFrequency}
                      </Text>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div onClick={handlePrevStep}>
            <Button type="fullLite">Précédent</Button>
          </div>
          <div className="flex-1 text-center">
            <Text>Étape {step + 1}/6</Text>
          </div>
          <div>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div onClick={nextStep}>
              <Button type="full">Suivant</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step6;
