// Icons
import { ChartSquare } from "iconsax-react";

// Components
import { BlocTitle } from "../../../Bloc/Title";
import Title from "../../../UI/Title";
import Text from "../../../UI/Text";
import { useEffect, useState } from "react";
import { getJob } from "../../../../services/Job/job";

const JobsClosedCounter = () => {
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const filters = {
        status: "closed"
      };

      const response = await getJob(0, 10, filters);
      if (response) setCount(response.count);

      setIsLoading(false);
    };

    fetch();
  }, []);

  return (
    <div className="flex flex-col gap-4 rounded-lg border bg-white p-4 shadow-lg">
      <BlocTitle>
        <ChartSquare size={18} />
        <Title type="bloc">Missions effectuées</Title>
      </BlocTitle>
      <div className="flex items-center justify-center">
        <Text className="!text-4xl font-semibold !text-blueViolet-600">
          {isLoading ? <div className="loader" /> : count}
        </Text>
      </div>
    </div>
  );
};

export default JobsClosedCounter;
