// Requires
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";

// Icons
import { Additem } from "iconsax-react";

// Components
import Button from "../../../UI/Button";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";
import Form from "./Portfolios/Form";
import Highlight from "../../../UI/Highlight";
import PortfolioCard from "../../../Cards/Portfolio/PortfolioCard";

// Types
import { PortfoliosType, PortfolioType } from "../../../../types/user";

// Services
import { getOgs } from "../../../../services/Misc/misc";
import {
  deletePortfolio,
  updatePorfolio
} from "../../../../services/User/user";

// Utils
import { useUser } from "../../../../utils/store/userStore";

const Portfolios = ({ initialData }: { initialData?: PortfoliosType }) => {
  const user = useUser((state) => state.userdata);

  const [isVisible, setIsVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [portfolios, setPortfolios] = useState<PortfoliosType>(
    initialData || { portfolios: [] }
  );

  const cancelAddOrUpdate = () => {
    setIsVisible(false);
    setEditingIndex(null);
  };

  const callBackForm = async (data: PortfolioType) => {
    let response: { image: string; title: string } = {
      image: "",
      title: ""
    };

    if (data.link) {
      response = await getOgs(data.link);
    }

    if (editingIndex !== null) {
      setPortfolios((prev) => ({
        ...prev,
        portfolios: prev.portfolios.map((item, index) =>
          index === editingIndex
            ? {
                ...item,
                ...data,
                ogTitle: response.title,
                ogImage: response.image
              }
            : item
        )
      }));
      setEditingIndex(null);
    } else {
      setPortfolios((prev) => ({
        ...prev,
        portfolios: [
          ...(prev?.portfolios || []),
          {
            ...data,
            ogTitle: response.title,
            ogImage: response.image,
            // Ensure new portfolios do not have an id yet
            id: undefined
          }
        ]
      }));
    }

    setIsVisible(false);
  };

  const handleDelete = async (index: number) => {
    const portfolioId = portfolios.portfolios[index].id;

    if (portfolioId) {
      const response = await deletePortfolio(portfolioId);

      if (response) {
        setPortfolios((prev) => ({
          ...prev,
          portfolios: prev.portfolios.filter((_, i) => i !== index)
        }));
      }
    } else {
      setPortfolios((prev) => ({
        ...prev,
        portfolios: prev.portfolios.filter((_, i) => i !== index)
      }));
    }
  };

  useEffect(() => {
    if (user?.portfolios)
      setPortfolios({
        portfolios: user.portfolios
      });
  }, [user]);

  const handleEdit = (index: number) => {
    const portfolioToEdit = portfolios.portfolios[index];

    if (portfolioToEdit) {
      setEditingIndex(index);
      setIsVisible(true);
    }
  };

  const handleAddOrUpdate = async () => {
    setIsLoading(true);

    const promises = portfolios.portfolios.map((item) => {
      const data = new FormData();
      data.append("title", item.title);
      data.append("link", item.link || "");
      data.append("date", item.date || new Date().toISOString());

      if (item.id) {
        // Assuming 'id' is the identifier
        data.append("portfolioId", item.id.toString());
      }

      item.attachments?.forEach((file) => {
        data.append(`attachments`, file);
      });

      return updatePorfolio(data);
    });

    await Promise.all(promises);

    setIsLoading(false);
    setSuccess(true);
    await useUser.getState().getUser(); // Refresh user data

    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Portfolio & Autres documents</Title>
      <div onClick={() => setIsVisible(true)} className="flex flex-col gap-8">
        <div className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border p-4">
          <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-blueViolet-50 p-4">
            <Additem size={18} />
          </div>
          <Text className="font-semibold">
            Ajouter un portfolio ou un autre document
          </Text>
        </div>
      </div>
      <AnimatePresence>
        {isVisible && (
          <Form
            cancel={cancelAddOrUpdate}
            callback={callBackForm}
            initialData={
              editingIndex !== null
                ? portfolios.portfolios[editingIndex]
                : undefined
            }
          />
        )}
      </AnimatePresence>
      <div className="flex flex-row flex-wrap gap-4">
        {portfolios.portfolios.map((portfolio, index) => {
          const key = portfolio.id || `new-${index}`;
          return (
            <div className="w-[calc(50%-8px)]" key={key}>
              <PortfolioCard
                item={portfolio}
                index={index}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            </div>
          );
        })}
      </div>
      {success && <Highlight type="Success">Enregistrement réussi</Highlight>}
      {initialData === portfolios ? (
        <div className="flex flex-row justify-end">
          <Button type="disabled">Enregistrer</Button>
        </div>
      ) : (
        <div className="flex flex-row justify-end">
          <Button type="full" onClick={handleAddOrUpdate}>
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Portfolios;
