// Icons
import { User, Building } from "iconsax-react";

// Components
import Text from "../../UI/Text";

// Types
import { Review } from "../../../types/user";

const LittleReviewCard = ({ data }: { data: Review }) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-blueViolet-200 p-4">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row flex-wrap gap-4">
          <div className="flex flex-row gap-2">
            <User size={18} className="text-blueViolet-600" />
            <Text className="font-semibold !text-blueViolet-600">
              {data.from.firstName} {data.from.lastName}
            </Text>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Building size={18} className="text-withCustom-500" />
            <Text className="text-sm !text-withCustom-500">
              {data.companyName}
            </Text>
          </div>
        </div>
        <div className="flex flex-row items-center gap-2 text-sm">
          <Text className="text-sm">{data.comment}</Text>
        </div>
      </div>
    </div>
  );
};

export default LittleReviewCard;
