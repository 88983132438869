import { Link } from "react-router-dom";
import Button from "../UI/Button";
import Text from "../UI/Text";
import Title from "../UI/Title";

const Blank = () => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border bg-white p-4 shadow-lg">
      <Title type="bloc">Gérer ses finances avec Blank</Title>
      <Text className="text-sm">
        Ouvre un compte pro en moins de 2 minutes et profite de 70€ offerts
      </Text>
      <Link
        to="https://landing.blank.app/partenariat/taftavie?utm_medium=partnership&utm_source=taftavie&utm_campaign=taftavie#"
        target="_blank"
      >
        <Button type="full" className="w-fit">
          Profiter de l'offre
        </Button>
      </Link>
    </div>
  );
};

export default Blank;
