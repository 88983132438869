import { CloseCircle, TickCircle, Warning2 } from "iconsax-react";

const Highlight = ({
  type,
  children,
  className
}: {
  type: "Warning" | "Error" | "Success" | "Information" | "Default";
  children: React.ReactNode;
  className?: string;
}) => {
  if (type === "Error") {
    return (
      <div className="flex w-full flex-row items-center gap-4 rounded-lg border border-[#C55050] bg-[#F7E8E8] p-4 text-[#C55050]">
        <CloseCircle size={18} />
        {children}
      </div>
    );
  }

  if (type === "Warning") {
    return (
      <div
        className={`flex w-full flex-row items-center gap-4 rounded-lg border border-[#E09C27] bg-[#FBF3E5] p-4 text-[#E09C27] ${className}`}
      >
        <Warning2 size={18} className="min-h-[18px] min-w-[18px]" />
        {children}
      </div>
    );
  }

  if (type === "Success") {
    return (
      <div
        className={`flex w-full flex-row items-center gap-4 rounded-lg border border-[#2D803D] bg-[#E8F7EB] p-4 text-[#2D803D] ${className}`}
      >
        <TickCircle size={18} className="min-h-[18px] min-w-[18px]" />
        {children}
      </div>
    );
  }

  if (type === "Information") {
    return (
      <div
        className={`flex w-full flex-row items-center gap-4 rounded-lg border border-blueViolet-600 bg-blueViolet-50 p-4 text-blueViolet-600 ${className}`}
      >
        <Warning2 size={18} className="min-h-[18px] min-w-[18px]" />
        {children}
      </div>
    );
  }

  if (type === "Default") {
    return (
      <div className="flex w-full flex-row items-center gap-4 rounded-lg border border-[#6565650] bg-white p-4 text-[#656565]">
        <CloseCircle size={18} />
        {children}
      </div>
    );
  }

  return null;
};

export default Highlight;

// Warning, Error, Success, Informations, Default
// Jaune, Rouge, Vert, Bleu, Gris
