// Components
import Button from "../UI/Button";
import Title from "../UI/Title";

// Type
import type { FileType } from "../../types/file";

const Transaction = ({ item }: { item: FileType }) => {
  return (
    <div key={item.id} className="flex flex-row justify-between">
      <div className="flex flex-col">
        <Title type="custom" classname="text-base">
          {item.fileName}
        </Title>
        {/* <div className="text-sm text-textGray">{item.date}</div> */}
      </div>
      <div className="flex flex-row items-center gap-4">
        {/* <Text>{item.price}</Text> */}
        <Button type="lite">Facture</Button>
      </div>
    </div>
  );
};

export default Transaction;
