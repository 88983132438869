// Requires
import { fetchApi } from "../Misc/api";

// Types
import type { JobCreateType } from "../../types/job";

type Filter = {
  status?: string | null;
  type?: string | null;
  date?: string | null;
  search?: string | null;
  startingType?: string | null;
};

// Retrieve details of a specific mission
export const getJobDetails = async (id: string | undefined) => {
  const response = await fetchApi(`/job/${id}`, "GET");
  if (response.error) return false;

  return response;
};

// Retrieve all missions posted by the connected company member
export const getJob = async (page: number, limit: number, filters: Filter) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString()
  });

  if (filters.status) queryParams.append("status", filters.status);
  if (filters.type) queryParams.append("type", filters.type);
  if (filters.date) queryParams.append("date", filters.date);
  if (filters.search) queryParams.append("search", filters.search);
  if (filters.startingType)
    queryParams.append("startingType", filters.startingType);

  const response = await fetchApi(`/job?${queryParams.toString()}`, "GET");
  if (response.error) throw new Error("Get Job call api failed!");

  return response;
};

type FiltersSelectedType = {
  city?: string;
  search?: string;
  // Add other filter types if needed
};

export const getAllJobs = async (
  pageNumber: number = 0,
  limit: number = 20,
  filters: FiltersSelectedType = {}
) => {
  try {
    let query = `/job?page=${pageNumber}&limit=${limit}`;

    // Build query string with filters
    Object.keys(filters).forEach((key) => {
      const value = filters[key as keyof FiltersSelectedType];
      if (value) {
        query += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    });

    const response = await fetchApi(query, "GET");
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Create a new Job
export const createJob = async (formData: FormData | JobCreateType) => {
  const response = await fetchApi("/job", "POST", formData);
  if (response.error) return false;
  return response;
};

// Prefilled data for new job with IA
export const createJobWithIA = async (data: FormData | { text: string }) => {
  const response = await fetchApi("/pdf/job", "POST", data);
  if (response.error) return false;
  return response;
};

// Add or remove a job to favorites list for a student user
export const addOrRemoveLikedJob = async (jobId: number, action: string) => {
  const response = await fetchApi(
    `/job/${jobId}/fav`,
    action === "add" ? "GET" : "DELETE"
  );
  if (response.error) return false;
  return response;
};

// Get a favorite jobs for a student user
export const getJobsFav = async (page: number, limit: number) => {
  const response = await fetchApi(
    `/job/fav?page=${page}&limit=${limit}`,
    "GET"
  );
  if (response.error) return false;
  return response;
};

// Get a recommended jobs for a student user
export const getRecommendedJobs = async (page: number, limit: number) => {
  const response = await fetchApi(
    `/job/recommended?page=${page}&limit=${limit}`,
    "GET"
  );
  if (response.error) return false;
  return response;
};

// Get actives jobs for a student user
export const getActivesJobs = async (
  page: number,
  limit: number,
  filters: Filter | undefined
) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString()
  });

  if (filters) {
    if (filters.type) queryParams.append("type", filters.type);
    if (filters.date) queryParams.append("date", filters.date);
    if (filters.search) queryParams.append("search", filters.search);
    if (filters.startingType)
      queryParams.append("startingType", filters.startingType);
  }

  const response = await fetchApi(
    `/job/active?${queryParams.toString()}`,
    "GET"
  );
  if (response.error) return false;
  return response;
};
