// Context
import { useChat } from "../../context/Chat/ChatContext";
import Avatar from "../UI/Avatar/Avatar";

// Components
import Text from "../UI/Text";
import UserPresence from "./UserPresence";

const UserInformations = () => {
  // TODO: Récupérer le profilTitle

  const { activeConversation } = useChat();

  return (
    <div className="flex flex-row gap-2">
      <Avatar link={activeConversation?.target?.image} className="size-10" />
      <div className="flex flex-col">
        <div className="flex flex-row gap-4">
          <Text className="font-semibold text-blueViolet-950">
            {activeConversation?.target?.firstName}{" "}
            {activeConversation?.target?.lastName}
          </Text>
          <UserPresence />
        </div>
      </div>
    </div>
  );
};

export default UserInformations;
