// Icons
import { TableDocument } from "iconsax-react";

// Components
import Title from "../../../../components/UI/Title";
import { CSVTag, PDFTag } from "../../../../components/UI/FileTags";
import Filters from "../../../../components/Table/Filters";
import TableAdminCompagnies from "../../../../components/Table/Admin/Companies/Table";
import ButtonWithDrawer from "../../../../components/UI/Button/ButtonWithDrawer";
import Text from "../../../../components/UI/Text";

// Utils
import { _ADMIN } from "../../../../utils/misc";

const Companies = () => {
  return (
    <div className="flex flex-col rounded-lg p-4">
      <div className="flex flex-col gap-12">
        <Title type="page">
          Gérer l'ensemble des entreprises inscrites sur Juwa
        </Title>

        <div className="flex flex-col gap-8 rounded-lg border p-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-2">
              <TableDocument size={18} />
              <Title type="bloc">Tableau des entreprises</Title>
            </div>
            <div className="flex flex-row items-center gap-2">
              <PDFTag />
              <CSVTag />
            </div>
          </div>
          <div className="rounded-lg border">
            <div className="flex flex-row justify-between p-4">
              <ButtonWithDrawer name="Action">
                <Text className="cursor-pointer text-sm hover:text-red-500">
                  Supprimer
                </Text>
              </ButtonWithDrawer>
              <Filters typeList={_ADMIN.filters.companies} />
            </div>
            <TableAdminCompagnies />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companies;
