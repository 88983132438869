import { fetchApi } from "../Misc/api";

type FiltersSelectedType = {
  categories: number[];
  skills: number[];
  degrees: number[];
  languages: number[];
  search: string;
  location: string;
};

const buildQueryString = (filters: FiltersSelectedType) => {
  const queryParts: string[] = [];

  if (filters.categories.length > 0) {
    queryParts.push(`categories=[${filters.categories}]`);
  }

  if (filters.skills.length > 0) {
    queryParts.push(`skills=[${filters.skills}]`);
  }
  if (filters.degrees.length > 0) {
    queryParts.push(`degrees=[${filters.degrees}]`);
  }

  if (filters.languages.length > 0) {
    queryParts.push(`languages=[${filters.languages}]`);
  }

  if (filters.search.length > 0) {
    queryParts.push(`search=${filters.search}`);
  }

  if (filters.location.length > 0) {
    queryParts.push(`location=${filters.location}`);
  }

  return queryParts.length > 0 ? `&${queryParts.join("&")}` : "";
};

export const GetAllStudents = async (
  page: number,
  limit: number,
  filters: FiltersSelectedType = {
    categories: [],
    skills: [],
    degrees: [],
    languages: [],
    search: "",
    location: ""
  }
) => {
  const queryString = buildQueryString(filters);

  const response = await fetchApi(
    `/student?page=${page}&limit=${limit}${queryString}`,
    "GET"
  );
  if (response.error) return false;

  return response;
};
