import React from "react";
import Button from "./UI/Button";
import Text from "./UI/Text";

interface NextPreviousProps {
  step: number;
  prevStep?: () => void;
  nextStep: () => void;
  isLoading: boolean;
  disableNext?: boolean;
  nameNext?: string;
  disablePrev?: boolean;
  noNextButton?: boolean;
}

const NextPrevious: React.FC<NextPreviousProps> = ({
  step,
  prevStep,
  nextStep,
  isLoading,
  disableNext = false,
  nameNext = "Suivant",
  disablePrev
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="next-previous flex flex-1 flex-row items-center justify-between">
        {prevStep && !disablePrev && (
          <div onClick={prevStep}>
            <Button type="fullLite">Précédent</Button>
          </div>
        )}
        <div className="flex-1 text-center">
          <Text className="text-xs md:text-base">Étape {step + 1}/14</Text>
        </div>
        <div
          onClick={
            !disableNext
              ? () => {
                  nextStep();
                }
              : () => {}
          }
        >
          <Button type={disableNext ? "disabled" : "full"}>
            {isLoading ? <div className="loader" /> : nameNext}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NextPrevious;
