// Providers
import { ChatProvider } from "../context/Chat/ChatProvider";
import { FloatingNotificationProvider } from "../context/FloatingNotification";
import { PopupProvider } from "../context/Popup";
import { SocketProvider } from "../context/Socket";
import { PopupProvider as Test } from "./context/Popup";
import { UIProvider } from "./context/UI";

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <PopupProvider>
      <UIProvider>
        <Test>
          <PopupProvider>
            <SocketProvider>
              <FloatingNotificationProvider>
                <ChatProvider>{children}</ChatProvider>
              </FloatingNotificationProvider>
            </SocketProvider>
          </PopupProvider>
        </Test>
      </UIProvider>
    </PopupProvider>
  );
};

export default Providers;
