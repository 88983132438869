import { Discover } from "iconsax-react";
import { BlocTitle } from "../../../Bloc/Title";
import Button from "../../../UI/Button";
import Title from "../../../UI/Title";
import UnderReview from "./UnderReview";
import InProgress from "./InProgress";
import Closed from "./Closed";

const Pipe = () => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center justify-between">
        <BlocTitle>
          <Discover size={18} />
          <Title type="bloc">Résumé de tes missions</Title>
        </BlocTitle>
        <Button type="full" className="text-xs md:text-base">
          Voir toutes les missions
        </Button>
      </div>
      <div className="flex w-full flex-col md:flex-row">
        <UnderReview />
        <InProgress />
        <Closed />
      </div>
    </div>
  );
};

export default Pipe;
