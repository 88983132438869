// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

// Icons
import { CloseSquare, Edit2 } from "iconsax-react";

// Components
import Button from "../UI/Button";
import Text from "../UI/Text";
import Title from "../UI/Title";
import { usePopup } from "../../context/Popup";

const ScheduleVideo = () => {
  const [openPopup, setOpenPopup] = useState<"time" | "duration" | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);

  const { closePopup } = usePopup();

  // Handle closing the popups when clicking outside
  const handleOutsideClick = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setOpenPopup(null);
    }
  };

  useEffect(() => {
    if (openPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [openPopup]);

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-row items-center justify-between gap-8">
        <div className="flex flex-row items-center gap-2">
          <Edit2 size={18} />
          <Title type="custom" classname="text-base">
            Programmer une visio
          </Title>
        </div>
        <CloseSquare
          size={18}
          className="cursor-pointer hover:text-blueViolet-600"
          onClick={closePopup}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            <div className="flex flex-col gap-1">
              <Text>Date</Text>
              <input type="date" className="rounded-lg border p-2" />
            </div>
            <div className="relative flex flex-col gap-1">
              <Text>Heure de début</Text>
              <input
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenPopup(openPopup === "time" ? null : "time");
                }}
                type="time"
                className="rounded-lg border p-2"
              />
              <AnimatePresence>
                {openPopup === "time" && (
                  <motion.div
                    ref={popupRef}
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 15 }}
                    className="absolute left-0 top-[80px] z-50 flex h-40 max-h-40 w-full flex-col gap-2 rounded-lg border bg-white p-2 shadow-lg"
                  >
                    <div className="flex flex-col gap-2 overflow-scroll">
                      {[
                        "01:00",
                        "02:00",
                        "03:00",
                        "04:00",
                        "05:00",
                        "06:00",
                        "07:00",
                        "08:00",
                        "09:00",
                        "10:00",
                        "11:00",
                        "12:00",
                        "13:00",
                        "14:00",
                        "15:00",
                        "16:00",
                        "17:00",
                        "18:00",
                        "19:00",
                        "20:00",
                        "21:00",
                        "22:00",
                        "23:00",
                        "00:00"
                      ].map((time) => (
                        <Text
                          key={time}
                          className="cursor-pointer rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white"
                        >
                          {time}
                        </Text>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="relative flex flex-col gap-1">
            <Text>Durée</Text>
            <input
              onClick={(e) => {
                e.stopPropagation();
                setOpenPopup(openPopup === "duration" ? null : "duration");
              }}
              type="text"
              placeholder="Selectionner la durée"
              className="rounded-lg border p-2"
            />
            <AnimatePresence>
              {openPopup === "duration" && (
                <motion.div
                  ref={popupRef}
                  initial={{ y: 15, opacity: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 15 }}
                  className="absolute left-0 top-[80px] z-50 flex h-[150px] w-full flex-col gap-2 overflow-hidden rounded-lg border bg-white p-2 shadow-lg"
                >
                  <div className="flex flex-col gap-2 overflow-scroll">
                    {["30 minutes", "1 heure", "2 heures", "3 heures"].map(
                      (duration) => (
                        <Text
                          key={duration}
                          className="cursor-pointer rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white"
                        >
                          {duration}
                        </Text>
                      )
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <Button type="full" className="w-full text-center">
            Envoyer l'invitation
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleVideo;
