// Requires
import { AnimatePresence, motion } from "framer-motion";
import { ArrowUp2, CloseCircle, SearchNormal1 } from "iconsax-react";
import { useSearchParams } from "react-router-dom";

// Icons
import { memo, useEffect, useRef, useState } from "react";

// Components
import Statut from "../UI/Jobs/Statut";
import Text from "../UI/Text";
import Button from "../UI/Button";
import Title from "../UI/Title";

// eslint-disable-next-line react/display-name
const DropdownItem = memo(
  ({
    item,
    index,
    isOpen,
    handleDropdownClick
  }: {
    item: string;
    index: number;
    isOpen: boolean;
    handleDropdownClick: (index: number) => void;
  }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const setFilter = (query: string, value: string) => {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set(query, value);
        return newParams;
      });
    };

    const togglePopup = () => {
      setDatePickerState(false);
    };

    const [datePickerState, setDatePickerState] = useState<boolean>(false);

    return item !== "Date de début" && item !== "Date de candidature" ? (
      <div
        onClick={() => handleDropdownClick(index)}
        className="relative flex cursor-pointer flex-row items-center gap-2 px-4 py-2 hover:bg-blueViolet-50"
      >
        {item !== "Date de début" && <Text>{item}</Text>}

        <motion.div animate={{ rotate: isOpen ? 180 : 0 }}>
          <ArrowUp2 size={18} />
        </motion.div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key={item}
              initial={{ y: 15, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 15, opacity: 0 }}
              className="absolute left-0 z-40 mt-2 min-w-full rounded-lg border bg-white p-2"
              style={{ top: "100%" }} // Ensures dropdown is positioned below the trigger
            >
              {item === "Status" && (
                <div className="flex flex-col gap-2">
                  <div onClick={() => setFilter("status", "underReview")}>
                    <Statut State={{ index: 0, name: "underReview" }} />
                  </div>
                  <div onClick={() => setFilter("status", "hiring")}>
                    <Statut State={{ index: 1, name: "hiring" }} />
                  </div>
                  <div onClick={() => setFilter("status", "hired")}>
                    <Statut State={{ index: 2, name: "hired" }} />
                  </div>
                  <div onClick={() => setFilter("status", "closed")}>
                    <Statut State={{ index: 3, name: "closed" }} />
                  </div>
                </div>
              )}
              {item === "Etudiant" && (
                <div className="flex flex-col gap-2">Student list</div>
              )}
              {item === "Type de mission" && (
                <div className="flex flex-col gap-2">
                  <div onClick={() => setFilter("type", "recurent")}>
                    <Text className="rounded-lg p-2 hover:bg-blueViolet-600 hover:text-white">
                      Récurrente
                    </Text>
                  </div>
                  <div onClick={() => setFilter("type", "punctual")}>
                    <Text className="rounded-lg p-2 hover:bg-blueViolet-600 hover:text-white">
                      Ponctuelle
                    </Text>
                  </div>
                </div>
              )}
              {item === "Etat" && (
                <div className="flex flex-col gap-2">
                  <div onClick={() => setFilter("jobStatus", "underReview")}>
                    <Statut State={{ index: 0, name: "underReview" }} />
                  </div>
                  <div onClick={() => setFilter("jobStatus", "accepted")}>
                    <Statut State={{ index: 1, name: "accepted" }} />
                  </div>
                  <div onClick={() => setFilter("jobStatus", "hired")}>
                    <Statut State={{ index: 2, name: "inProgress" }} />
                  </div>
                  <div onClick={() => setFilter("jobStatus", "closed")}>
                    <Statut State={{ index: 3, name: "rejected" }} />
                  </div>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    ) : (
      <div
        onClick={() => setDatePickerState(true)}
        className="relative flex cursor-pointer flex-row items-center gap-2 px-4 py-2 hover:bg-blueViolet-50"
      >
        <Text>{item}</Text>
        {datePickerState && (
          <AnimatePresence>
            <Popup close={togglePopup} />
          </AnimatePresence>
        )}
      </div>
    );
  }
);

const Popup = ({ close }: { close: () => void }) => {
  const [choice, setChoice] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateSelected, setDateSelected] = useState<string>();

  useEffect(() => {
    const dateFilter = searchParams.get("date");
    const startingTypeFilter = searchParams.get("startingType");

    if (startingTypeFilter === "asap") {
      setChoice("asap");
    } else if (startingTypeFilter === "unspecified") {
      setChoice("unspecified");
    } else if (dateFilter) {
      setChoice("date");
      setDateSelected(dateFilter);
    }
  }, []);

  const setFilter = () => {
    if (choice) {
      setSearchParams(() => {
        const newParams = new URLSearchParams();
        if (choice === "asap" || choice === "unspecified") {
          newParams.set("startingType", choice);
        } else {
          newParams.set("date", dateSelected!);
        }
        close();
        return newParams;
      });
    }
  };

  return (
    <motion.div
      key="popupVoiler"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
    >
      <motion.div
        key={"popupContent"}
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 5 }}
        className="flex h-auto w-fit flex-col gap-4 rounded-lg border bg-white p-4 shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row items-center justify-between gap-12">
          <Title type="bloc">Choisir une date de début</Title>{" "}
          <CloseCircle size={18} onClick={close} />
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-2">
            <input
              type="radio"
              name="choice"
              id="asap"
              className="peer hidden"
              checked={choice === "asap"}
              onChange={() => setChoice("asap")}
            />
            <label
              htmlFor="asap"
              className="relative inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-gray-300 bg-white bg-clip-content p-0.5 hover:border-gray-400 peer-checked:border-indigo-600 peer-checked:bg-indigo-600"
            ></label>
            <Text>Dès que possible</Text>
          </div>
          <div className="flex flex-row items-center gap-2">
            <input
              type="radio"
              name="choice"
              id="unspecified"
              className="peer hidden"
              checked={choice === "unspecified"}
              onChange={() => setChoice("unspecified")}
            />
            <label
              htmlFor="unspecified"
              className="relative inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-gray-300 bg-white bg-clip-content p-0.5 hover:border-gray-400 peer-checked:border-indigo-600 peer-checked:bg-indigo-600"
            ></label>
            <Text>Non défini</Text>
          </div>
          <input
            type="date"
            value={choice === "date" ? dateSelected : ""}
            onChange={(e) => {
              setChoice("date");
              setDateSelected(e.target.value as string);
            }}
            className="rounded-lg border p-2"
          />

          <div onClick={setFilter}>
            <Button type="full">Valider</Button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const Filters = ({ typeList }: { typeList: string[] }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const DEBOUNCE_DELAY = 500;

  const [searchParams, setSearchParams] = useSearchParams();
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDropdownClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setOpenIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setFilter = (query: string, value: string) => {
    if (query === "search") {
      setIsLoading(true);
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      const newTimer = setTimeout(() => {
        setSearchParams((prevParams) => {
          const newParams = new URLSearchParams(prevParams);
          newParams.set(query, value);
          setIsLoading(false);
          return newParams;
        });
      }, DEBOUNCE_DELAY);

      setDebounceTimer(newTimer);
    } else {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set(query, value);
        return newParams;
      });
    }
  };

  const resetFilters = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete("search");
      newParams.delete("date");
      newParams.delete("startingType");
      newParams.delete("status");
      newParams.delete("type");
      newParams.delete("jobStatus");
      return newParams;
    });
  };

  return (
    <div className="flex grow flex-col items-center justify-between gap-3 md:flex-row md:gap-0">
      <div
        className="flex flex-row items-center gap-4 max-md:flex-col max-md:items-end max-md:gap-1"
        ref={containerRef}
      >
        <div className="flex w-fit flex-row rounded-lg border">
          {typeList.map((item, index) => {
            const isOpen = openIndex === index;
            return (
              <DropdownItem
                key={item}
                item={item}
                index={index}
                isOpen={isOpen}
                handleDropdownClick={handleDropdownClick}
              />
            );
          })}
        </div>
        {typeList.length > 0 && (
          <div onClick={resetFilters}>
            <Text className="cursor-pointer text-sm text-textGray hover:text-blueViolet-600">
              Réinitialiser les filtres
            </Text>
          </div>
        )}
      </div>
      <div className="relative inline-block h-fit w-full md:w-fit">
        {isLoading ? (
          <div className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 transform text-textGray">
            <div className="loader" />
          </div>
        ) : (
          <SearchNormal1
            size={18}
            className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 transform text-textGray"
          />
        )}
        <input
          onChange={(e) => setFilter("search", e.currentTarget.value)}
          type="text"
          className="w-full rounded-md border py-2 pl-10 pr-4 shadow-sm focus:outline-blueViolet-600"
          placeholder="Rechercher"
        />
      </div>
    </div>
  );
};

export default Filters;
