import { type ReactNode, createContext, useContext, useState } from "react";

interface PopupInterface {
  isOpen: boolean;
  togglePopup: (
    type: string,
    proposalId?: number,
    studentId?: number[]
  ) => void;
  isType: string | undefined;
  proposalId: number | null;
  closePopup: () => void;
  studentsChecked: number[] | null;
}

export const PopupContext = createContext<PopupInterface | null>(null);

export const PopupProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isType, setType] = useState<string>();
  const [proposalId, setProposalId] = useState<number | null>(null);
  const [studentsChecked, setStudentsChecked] = useState<number[]>([]);

  const togglePopup = (
    type: string,
    proposalId?: number,
    studentId?: number[]
  ) => {
    setIsOpen(!isOpen);
    setType(type);

    if (type === "proposalsDetails" && proposalId) {
      setProposalId(proposalId);
      return;
    }

    if (type === "shortList" && studentId) {
      setStudentsChecked(studentId);
      return;
    }

    if (type === "suggestJob" && studentId) {
      setStudentsChecked(studentId);
      return;
    }

    if (type === "setNotation" && studentId) {
      setStudentsChecked(studentId);
      return;
    }
  };

  const closePopup = () => {
    setIsOpen(false);
    setType(undefined);
    setProposalId(null);
    setStudentsChecked([]);
  };

  return (
    <PopupContext.Provider
      value={{
        isOpen,
        togglePopup,
        isType,
        proposalId,
        closePopup,
        studentsChecked
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
