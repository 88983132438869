// Components
import ProtectedRoute from "../pages/Protected/Common/ProtectedRoute";
import SubPage from "../components/Utils/SubPage";
import Companies from "../pages/Protected/Administration/Companies/Companies";
import Jobs from "../pages/Protected/Administration/Jobs/Jobs";
import StudentsProposal from "../pages/Protected/Administration/Proposals/StudentsProposal";
import Students from "../pages/Protected/Administration/Students/Students";
import PublishJob from "../pages/Protected/Company/Jobs/Publish/PublishJob";
import JobLayout from "../pages/Protected/Company/Jobs/Publish/New/Layout";
import Job from "../pages/Protected/Company/Jobs/Manage/[id]/Job";
import Profil from "../pages/Protected/Student/Profil/[id]/Profil";
import Compagny from "../pages/Protected/Company/MyCompany/Compagny";

export const adminRouters = {
  path: "admin",
  element: <ProtectedRoute />,
  children: [
    {
      path: "jobs",
      element: <SubPage />,
      children: [
        {
          path: "all",
          element: <Jobs />
        },
        {
          path: "proposals",
          element: <StudentsProposal />
        },
        {
          path: "publish",
          element: <SubPage />,
          children: [
            { path: "", element: <PublishJob /> },
            { path: "new", element: <JobLayout /> }
          ]
        },
        {
          path: ":id",
          element: <Job />
        }
      ]
    },
    {
      path: "company",
      element: <SubPage />,
      children: [
        {
          path: "all",
          element: <Companies />
        },
        {
          path: ":id",
          element: <Compagny />
        }
      ]
    },
    {
      path: "student",
      element: <SubPage />,
      children: [
        {
          path: "all",
          element: <Students />
        },
        {
          path: ":id",
          element: <Profil />
        }
      ]
    }
  ]
};
