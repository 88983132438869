// Icons
import { CloseSquare } from "iconsax-react";

// Components
import Title from "../../UI/Title";
import Highlight from "../../UI/Highlight";
import Button from "../../UI/Button";
import { useState } from "react";
import { cancelSubscription } from "../../../services/Payment/payment";
import { usePopup } from "../../../context/Popup";
import Text from "../../UI/Text";

const CancelSubscription = () => {
  const [reason, setReason] = useState<string>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { closePopup } = usePopup();

  const handleConfirm = async () => {
    setIsLoading(true);
    setError(undefined);
    if (!reason || reason.length < 50) {
      setError("La raison doit contenir au moins 50 caractères");
      setIsLoading(false);
      return;
    }

    const response = await cancelSubscription({ data: { reason: reason } });

    if (response) {
      setIsLoading(false);
      closePopup();
      window.location.reload();
    }
  };

  return (
    <div className="flex max-w-[550px] flex-col gap-8 p-4">
      <div className="flex flex-row items-center justify-between gap-8">
        <Title type="bloc">Annuler votre abonnement</Title>
        <CloseSquare
          onClick={closePopup}
          size={18}
          className="cursor-pointer hover:text-blueViolet-600"
        />
      </div>
      <div className="flex flex-col gap-4">
        <Highlight type="Information">
          Choisir d'annuler un abonnement suspend le renouvellement automatique
          de celui-ci, vous continuerez de profiter des avantages jusqu'au
          prochain jour de renouvellement.
        </Highlight>
        <Title type="bloc">Indiquer la raison de l'annulation</Title>
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className="textareautoresize resize-none rounded-lg border p-2 focus:outline-blueViolet-600"
          placeholder="Indiquer une raison. Cela nous permettra d'adapter nos services."
        />
        {error && <Text className="text-red-500">{error}</Text>}
      </div>
      <div className="flex flex-row items-center justify-end">
        <div onClick={handleConfirm}>
          <Button type="full">Confirmer mon annulation</Button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscription;
