// Requires
import type React from "react";
import { useEffect, useState } from "react";

// Components
import Text from "../../UI/Text";

// Services
import { getCompanySizeRanges } from "../../../services/Misc/misc";

// Types

type ErrorType = {
  global?: string;
  userActivity?: string;
  companyActivity?: string;
};

interface CompanySizeDropdownProps {
  errors: ErrorType | undefined;
  setData: React.Dispatch<
    React.SetStateAction<{
      siret?: string;
      userActivity?: string;
      companyActivity?: number;
      companySizeId?: number;
    }>
  >;
}

const CompanySizeDropdown = ({ errors, setData }: CompanySizeDropdownProps) => {
  const [sizeRanges, setSizeRanges] = useState<
    {
      id: number;
      label: string;
      min: number;
      max: number | null;
    }[]
  >();

  useEffect(() => {
    const fetch = async () => {
      const response = await getCompanySizeRanges();
      if (response) setSizeRanges(response);
    };

    fetch();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2">
        <Text className="font-semibold">Effectif de votre entreprise</Text>
        {errors?.userActivity && (
          <Text className="text-sm text-red-500">{errors.userActivity}</Text>
        )}
        <select
          onChange={(e) => {
            const selectedValue = parseInt(e.currentTarget.value);
            setData((prev) => ({
              ...prev,
              companySizeId: selectedValue || 0
            }));
          }}
          className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${errors?.userActivity && "border border-red-500"}`}
        >
          <option value={""}>Indiquer votre effectif</option>
          {sizeRanges?.map((item, _i) => (
            <option key={_i} value={item.id}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default CompanySizeDropdown;
