// Components
import { useEffect, useState } from "react";
import NextPrevious from "../../../../../../components/NextPrevious";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Context
import { useUI } from "../../../../../../utils/context/UI";
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";
import { SendRecommendationRequestMail } from "../../../../../../services/Mail/recommendation-mail";

const Fourteen = () => {
  const { step, handleNextStep, handlePrevStep } = useUI();

  const localStorage = getLocalStorage("custom");

  // Define the data state with initial empty values
  const [data, setData] = useState({
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: ""
  });

  // State for email input
  const [emails, setEmails] = useState("");

  // State for message content
  const [message, setMessage] = useState<string>(
    "Bonjour, \n\nJe suis inscrit sur Juwa, la plateforme collaborative qui met en relation étudiants freelances et clients. \n\nJe vous invite à y découvrir mon profil et à m’y laisser une recommandation qui me permettra d’améliorer ma réputation.\n\n Merci d’avance !"
  );

  // Loading and error states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const nextStep = async () => {
    setError(""); // Reset any previous errors

    // Split emails by comma and trim whitespace
    const emailList = emails
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email);

    // Validate email format using a simple regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emailList.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      setError(`Invalid email addresses: ${invalidEmails.join(", ")}`);
      return;
    }

    // Prepare the payload
    const payload = {
      emails: emailList,
      personalMessage: message ?? ""
    };

    setIsLoading(true);

    if (emailList.length > 0) {
      try {
        const response = await SendRecommendationRequestMail(payload);

        if (response.error) {
          // Handle HTTP errors
          throw new Error(
            response.error || "Failed to send recommendation emails."
          );
        }

        // On success, set local storage and proceed to next step
        const localData = [{ key: "studentStep", value: "13" }];
        setLocalStorage(localData);
        handleNextStep();
        return;
      } catch (err) {
        console.error("Error sending recommendation emails:", err);
        setError((err as Error).message || "An unexpected error occurred.");
        return;
      } finally {
        setIsLoading(false); // Stop loading
      }
    }

    const localData = [{ key: "studentStep", value: "13" }];
    setLocalStorage(localData);
    handleNextStep();
  };

  useEffect(() => {
    if (localStorage) {
      const parsedData: { firstName: string; lastName: string } =
        JSON.parse(localStorage);

      setData((prev) => ({
        ...prev,
        firstName: parsedData.firstName,
        lastName: parsedData.lastName
      }));
    }
  }, [localStorage]);

  return (
    <div className="w-full p-4 md:px-16">
      <div className="flex items-center justify-center">
        <div className="flex min-w-[50%] flex-col gap-24 md:max-w-[50%]">
          <div className="flex flex-col gap-16">
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-2">
                <Title type="page">Tes recommandations</Title>
                <Text className="text-xs">Recommandé</Text>
              </div>
              <Text className="text-sm">
                Envoie une demande à tes anciens employeurs ou personnes avec
                qui tu as eu l’occasion de travailler pour qu’ils te laissent
                une recommandation sur ton profil ! Tu as juste à rentrer leur
                adresse mail dans le champs ci-dessous et un mail automatique
                leur sera envoyé !
              </Text>
            </div>

            <div className="flex flex-col gap-2">
              <Title type="page">Emails séparés par une virgule</Title>
              <input
                type="text"
                placeholder="email@email.com, email2@email2.com"
                className="rounded-lg border p-2 focus:outline-blueViolet-600"
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-2">
              <Title type="page">Message</Title>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="h-[450px] resize-none rounded-lg border p-2 focus:outline-blueViolet-600"
              />
            </div>

            {error && <Text className="text-sm text-red-500">{error}</Text>}
          </div>
          <NextPrevious
            step={step}
            nextStep={nextStep}
            isLoading={isLoading}
            prevStep={() => {
              setLocalStorage([{ key: "studentStep", value: "11" }]);
              handlePrevStep();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Fourteen;
