// Require
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// Components
import Button from "../../UI/Button";
import Title from "../../UI/Title";
import Text from "../../UI/Text";

// Services
import { companyPaymentBySEPA } from "../../../services/Payment/payment";

// Utils
import { validateBic, validateIban } from "../../../utils/helpers/validators";

// Types
type Errors = {
  all?: string;
  iban?: string;
  bic?: string;
};

const SEPA = ({
  code,
  type
}: {
  code: string | undefined;
  type?: "job" | "subscription";
}) => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");
  const facturationMode = searchParams.get("facturationMode");

  const [data, setData] = useState<{ iban: string; bic: string }>({
    iban: "",
    bic: ""
  });

  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    const newErrors: Errors = {};
    setErrors(newErrors);
    setIsLoading(true);

    if (!data)
      newErrors.all = "Vous devez completer le formulaire entierement.";

    if (!data.iban || !validateIban(data.iban))
      newErrors.iban = "Vous devez indiquer un IBAN valide.";

    if (!data.bic || !validateBic(data.bic))
      newErrors.bic = "Vous devez indiquer un BIC valide.";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    const response = await companyPaymentBySEPA(
      Number(id),
      {
        ...data,
        couponCode: code,
        ...(type === "subscription" && {
          frequency:
            facturationMode === "1"
              ? "MONTHLY"
              : facturationMode === "3"
                ? "QUARTERLY"
                : "YEARLY",
          plan: plan ?? undefined
        })
      },
      type ?? "job"
    );

    if (response && response.signatureUrl) {
      setIsLoading(false);
      window.location.href = response.signatureUrl;
      return;
    }

    setErrors({ all: "Une erreur est survenue." });
    setIsLoading(false);
  };

  return (
    <div className="flex h-fit flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="page">Effectuer un paiement par prélévement SEPA</Title>
      <div className="flex flex-col gap-2">
        <input
          onChange={handleInputChange}
          name="iban"
          type="text"
          placeholder="IBAN"
          className="rounded-lg border p-2 focus:outline-blueViolet-600"
        />
        {errors.iban && (
          <Text className="text-sm text-red-500">{errors.iban}</Text>
        )}
        <input
          onChange={handleInputChange}
          name="bic"
          type="text"
          placeholder="BIC"
          className="rounded-lg border p-2 focus:outline-blueViolet-600"
        />
        {errors.bic && (
          <Text className="text-sm text-red-500">{errors.bic}</Text>
        )}
      </div>
      <div onClick={handleSubmit}>
        <Button type="full">
          {isLoading ? <div className="loader" /> : "Procéder au paiement"}
        </Button>
      </div>
    </div>
  );
};

export default SEPA;
