import { motion } from "framer-motion";
import { useState } from "react";

const ToggleSwitch = ({ isActive }: { isActive: boolean }) => {
  const [state, setState] = useState<boolean>(isActive);

  return (
    <div
      onClick={() => setState((prev) => !prev)}
      className="relative flex w-fit cursor-pointer flex-row gap-4 rounded-lg bg-backgroundGray p-2"
    >
      <motion.div
        initial={{ left: 8 }}
        animate={{ left: state ? 56 : 8 }}
        className={`absolute h-[calc(100%-15px)] w-[calc(50%-10px)] rounded-lg ${state ? "bg-green-200" : "bg-red-100"}`}
      />
      <div className="z-0 mx-1">Non</div>
      <div className="z-0 mx-1">Oui</div>
    </div>
  );
};

export default ToggleSwitch;
