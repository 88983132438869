// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";

// Icons
import { ArrowDown2 } from "iconsax-react";

// Services
import {
  getCategories,
  getLanguages,
  getSkills
} from "../../services/Misc/misc";

// Components
import Text from "../UI/Text";

const DropdownWithSearch = ({
  title,
  type,
  withSearch,
  handleUpdateFilter
}: {
  title: string;
  type: string;
  withSearch: boolean;
  handleUpdateFilter?: (type: string, value: number) => void;
}) => {
  const [dropdownIsOpen, setDropDownIsOpen] = useState<boolean>(false);
  const [elementsSelected, setElementsSelected] = useState<
    { id: number; title: string }[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [categories, setCategories] =
    useState<{ id: number; title: string }[]>();
  const [skills, setSkills] = useState<{ id: number; title: string }[]>();
  const [languages, setLanguages] = useState<{ id: number; title: string }[]>();

  // Use a callback to prevent immediate execution during render
  const addElement = useCallback(
    (id: number, title: string) => {
      setElementsSelected((prev) => {
        const isAlreadySelected = prev.some((el) => el.id === id);
        if (isAlreadySelected) {
          return prev.filter((el) => el.id !== id);
        }
        if (handleUpdateFilter) handleUpdateFilter(type, id);
        return [...prev, { id: id, title: title }];
      });
    },
    [handleUpdateFilter, type]
  );

  const removeElement = useCallback(
    (id: number) => {
      setElementsSelected((prev) => prev.filter((el) => el.id !== id));
      if (handleUpdateFilter) handleUpdateFilter(type, id);
    },
    [handleUpdateFilter, type]
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setDropDownIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (type === "domaine") {
        const response = await getCategories();
        setCategories(response);
      }
      if (type === "skills") {
        const response = await getSkills();
        setSkills(response);
      }
      if (type === "languages") {
        const response = await getLanguages("");
        setLanguages(response);
      }
    };

    fetch();
  }, [type]);

  // Filtrer les compétences et les langues en fonction de la recherche et des éléments déjà sélectionnés
  const filteredSkills = skills?.filter(
    (skill) =>
      skill.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !elementsSelected.some((el) => el.id === skill.id)
  );
  const filteredLanguages = languages?.filter(
    (language) =>
      language.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !elementsSelected.some((el) => el.id === language.id)
  );

  const handleDropdownClick = () => {
    setDropDownIsOpen((prev) => !prev);
    // if (!dropdownIsOpen) {
    //   setDropDownIsOpen(true);
    // }
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <Text className="font-semibold">{title}</Text>
      <div
        ref={dropdownRef}
        className="relative flex w-full cursor-pointer flex-row items-center justify-between gap-4 rounded-lg border p-2"
        // onClick={handleDropdownClick}
      >
        <div className="flex grow flex-row flex-wrap items-center gap-2">
          {elementsSelected.length < 1 && !withSearch && (
            <Text className="text-sm">
              Aucun {title.toLowerCase()} sélectionné
            </Text>
          )}
          {withSearch && (
            <>
              {elementsSelected.length > 0 && (
                <div className="flex flex-row flex-wrap gap-1">
                  {elementsSelected.map((el) => (
                    <span
                      key={el.id}
                      onClick={() => removeElement(el.id)}
                      className="h-fit cursor-pointer rounded-lg bg-blueViolet-600 px-2 py-1 text-xs text-white"
                    >
                      {el.title}
                    </span>
                  ))}
                </div>
              )}
              <input
                ref={inputRef}
                type="text"
                placeholder={`Rechercher des ${title.toLowerCase()}`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="grow cursor-pointer rounded-lg border p-1 text-sm focus:outline-blueViolet-600"
                // onFocus={handleDropdownClick}
              />
            </>
          )}
          {elementsSelected.length > 0 &&
            !withSearch &&
            elementsSelected.map((item) => (
              <span
                key={item.id}
                onClick={() => removeElement(item.id)}
                className="h-fit cursor-pointer rounded-lg bg-blueViolet-600 px-2 py-1 text-xs text-white"
              >
                {item.title}
              </span>
            ))}
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: dropdownIsOpen ? 180 : 0 }}
            className="h-fit"
          >
            <ArrowDown2
              onClick={handleDropdownClick}
              size={18}
              className="min-h-[18px] min-w-[18px]"
            />
          </motion.div>
        </div>
      </div>
      <AnimatePresence>
        {dropdownIsOpen && (
          <motion.div
            className="flex max-h-[150px] w-full flex-row flex-wrap gap-2 overflow-scroll rounded-lg border bg-whiteSimple p-2 shadow-lg"
            initial={{ y: 15, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 15, opacity: 0 }}
          >
            {type === "skills" &&
              filteredSkills &&
              filteredSkills.length > 0 &&
              filteredSkills.map((item) => (
                <span
                  key={item.id}
                  onClick={() => addElement(item.id, item.title)}
                  className="cursor-pointer rounded-lg bg-white px-2 py-1 text-xs"
                >
                  {item.title}
                </span>
              ))}
            {type === "languages" &&
              filteredLanguages &&
              filteredLanguages.length > 0 &&
              filteredLanguages.map((item) => (
                <span
                  key={item.id}
                  onClick={() => addElement(item.id, item.title)}
                  className="cursor-pointer rounded-lg bg-white px-2 py-1 text-xs"
                >
                  {item.title}
                </span>
              ))}
            {(type === "skills" && filteredSkills?.length === 0) ||
            (type === "languages" && filteredLanguages?.length === 0) ? (
              <Text className="text-sm">Aucun résultat</Text>
            ) : null}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DropdownWithSearch;
