// Requires
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Icons
import { TableDocument } from "iconsax-react";

// Components
import TableAdminStudents from "../../../../components/Table/Admin/Students/Table";
import { PDFTag, CSVTag } from "../../../../components/UI/FileTags";
import Title from "../../../../components/UI/Title";
import Text from "../../../../components/UI/Text";
import Filters from "../../../../components/Table/Filters";
import ButtonWithDrawer from "../../../../components/UI/Button/ButtonWithDrawer";
import SkillsSelector from "../../../../components/Misc/SkillsSelector";
import LanguageSelector from "../../../../components/Misc/LanguageSelector";
import SchoolSearch from "../../../../components/Misc/SchoolSearch";
import LanguageSearch from "../../../../components/Misc/LanguageSearch";

// Services
import { getCategories } from "../../../../services/Misc/misc";

// Helpers
import { _ADMIN, _educationLevel } from "../../../../utils/misc";
import { capitalizeFirstLetter } from "../../../../utils/helpers/capitalizeFirstLetter";

// Context
import { usePopup } from "../../../../context/Popup";

// Types
interface Student {
  displayName: string;
  profileTitle: string;
  category: string;
  phoneNumber: string;
  skills: string;
  city: string;
  email: string;
  siret: string;
  languages: string;
  degree: string;
  callTalentFinder: string;
  certificate: string;
  publicMark: string;
  privateMark: string;
}

const Students = () => {
  const [categories, setCategories] =
    useState<{ id: number; title: string }[]>();

  const [languagesSelected, setLanguagesSelected] = useState<
    { id: number; title: string }[]
  >([]);

  const [categoriesSelected, setCategoriesSelected] = useState<number[]>([]);
  const [degreesSelected, setDegreesSelected] = useState<number[]>([]);
  const [citySelected, setCitySelected] = useState<{
    id: number;
    label: string;
    zip_code: string;
  }>();
  const [skillsSelected, setSkillsSelected] = useState<
    { id: number; title: string }[]
  >([]);
  const [schoolSelected, setSchoolSelected] = useState<{
    id: number;
    name: string;
  }>();

  const [buttonDrawerContent, setButtonDrawerContent] = useState<
    { name: string; checked: boolean; dataValue: keyof Student }[]
  >([
    { name: "Nom complet", checked: true, dataValue: "displayName" },
    { name: "Intitulé de poste", checked: true, dataValue: "profileTitle" },
    { name: "Domaine d'étude", checked: true, dataValue: "category" },
    { name: "Téléphone", checked: true, dataValue: "phoneNumber" },
    { name: "Compétences", checked: true, dataValue: "skills" },
    { name: "Ville", checked: true, dataValue: "city" },
    { name: "Email", checked: true, dataValue: "email" },
    { name: "SIRET", checked: false, dataValue: "siret" },
    { name: "Langues", checked: false, dataValue: "languages" },
    { name: "Niveau d'etude", checked: false, dataValue: "degree" },
    {
      name: "Call Talent Finder",
      checked: false,
      dataValue: "callTalentFinder"
    },
    {
      name: "Certificat de scolarité",
      checked: false,
      dataValue: "certificate"
    },
    { name: "Note privée", checked: false, dataValue: "privateMark" },
    { name: "Note publique", checked: false, dataValue: "publicMark" }
  ]);

  const [checkedStudent, setCheckedStudent] = useState<number[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const { togglePopup } = usePopup();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newData = buttonDrawerContent.map((item) => {
      if (item.name === e.target.name) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });
    setButtonDrawerContent(newData);
  };

  const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const categories = searchParams.get("categories")
      ? JSON.parse(searchParams.get("categories") as string)
      : [];

    const newCategories = e.target.checked
      ? [...categories, e.target.value]
      : categories.filter((cat: string) => cat !== e.target.value);

    setSearchParams({
      ...Object.fromEntries(searchParams),
      categories: JSON.stringify(newCategories)
    });

    setCategoriesSelected((prev) => {
      if (e.target.checked) {
        return [...prev, parseInt(e.target.value)];
      } else {
        return prev.filter((cat) => cat !== parseInt(e.target.value));
      }
    });
  };

  const handleCityChange = (data: {
    id: number;
    zip_code: string;
    label: string;
  }) => {
    setCitySelected(data);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      city: data.label
    });
  };

  const handleLanguageChange = (data: { id: number; title: string }[]) => {
    const languagesArray = data.map((lang) => lang.id);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      languages: `[${languagesArray.join(",")}]`
    });
    setLanguagesSelected(data);
  };

  const handleDegreeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const degrees = searchParams.get("degrees")?.split(",") || [];
    const newDegrees = e.target.checked
      ? [...degrees, e.target.value]
      : degrees.filter((deg) => deg !== e.target.value);

    setSearchParams({
      ...Object.fromEntries(searchParams),
      degrees: newDegrees.join(",")
    });

    setDegreesSelected((prev) => {
      if (e.target.checked) {
        return [...prev, Number(e.target.value)];
      } else {
        return prev.filter((deg) => deg !== Number(e.target.value));
      }
    });
  };

  const handleSkillsChange = (data: { id: number; title: string }[]) => {
    const skillsIds = data.map((skill) => skill.id);

    setSearchParams({
      ...Object.fromEntries(searchParams),
      skills: JSON.stringify(skillsIds)
    });

    setSkillsSelected(data);
  };

  const handleOpenPopup = (type: "shortList" | "suggestJob") => {
    if (type === "shortList") {
      if (
        !checkedStudent ||
        (checkedStudent && checkedStudent.length < 1) ||
        (checkedStudent && checkedStudent.length > 3)
      ) {
        alert("Vous devez séléctionner au minimum 1 étudiant et au maximum 3.");
        return;
      }

      togglePopup(type, undefined, checkedStudent);
    } else if (type === "suggestJob" && checkedStudent.length > 0) {
      togglePopup(type, undefined, checkedStudent);
    } else {
      alert("Vous devez séléctionner au minimum un étudiant.");
    }
  };

  const handleCheckedStudent = (id: number) => {
    setCheckedStudent((prev) => {
      if (prev.includes(id)) {
        return prev.filter((student) => student !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleSchoolChange = (data: { id: number; name: string }) => {
    setSchoolSelected(data);

    setSearchParams({
      ...Object.fromEntries(searchParams),
      school: data.id.toString()
    });
  };

  const resetCheckedStudent = () => {
    setCheckedStudent([]);
  };

  const resetFilters = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete("search");
      newParams.delete("city");
      newParams.delete("categories");
      newParams.delete("languages");
      newParams.delete("degrees");
      newParams.delete("skills");
      newParams.delete("school");
      return newParams;
    });

    setCitySelected(undefined);
    setCategoriesSelected([]);
    setDegreesSelected([]);
    setSkillsSelected([]);
    setLanguagesSelected([]);
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getCategories();
      setCategories(response);
    };
    fetch();
  }, []);

  return (
    <div className="borderrounded-lg flex flex-col">
      <div className="flex flex-col gap-12">
        <Title type="page">
          Gérer les étudiants inscrits sur la plateforme
        </Title>

        <div className="flex flex-col gap-8 rounded-lg border p-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-2">
              <TableDocument size={18} />
              <Title type="bloc">Tableau des étudiants</Title>
            </div>
            <div className="flex flex-row items-center gap-2">
              <PDFTag />
              <CSVTag />
            </div>
          </div>
          <div className="rounded-lg border">
            <div className="flex flex-col gap-6 p-4">
              <div className="flex flex-row items-center justify-between gap-4">
                <div className="relative max-w-[calc(100%-180px)]">
                  <div className="flex gap-4 overflow-x-auto whitespace-nowrap">
                    {_ADMIN.filters.students.map((item) => {
                      return (
                        <ButtonWithDrawer
                          key={item}
                          name={
                            item === "Domaine d'études" &&
                            categoriesSelected.length > 0
                              ? `${item} (${categoriesSelected.length})`
                              : item === "Ville" && citySelected
                                ? `${capitalizeFirstLetter(citySelected.label)}`
                                : item === "Niveaux d'études" &&
                                    degreesSelected.length > 0
                                  ? `${item} (${degreesSelected.length})`
                                  : item === "Compétences" &&
                                      skillsSelected.length > 0
                                    ? `${item} (${skillsSelected.length})`
                                    : item === "Langues parlées" &&
                                        languagesSelected.length > 0
                                      ? `${item} (${languagesSelected.length})`
                                      : item === "Etablissement actuel" &&
                                          schoolSelected
                                        ? `${schoolSelected.name}`
                                        : item
                          }
                        >
                          {item === "Domaine d'études" && categories ? (
                            categories.map((category, _i) => (
                              <div
                                key={_i}
                                className="flex flex-1 items-center justify-start gap-2"
                              >
                                <input
                                  type="checkbox"
                                  id={category.title}
                                  name={"categories"}
                                  value={category.id}
                                  checked={categoriesSelected.includes(
                                    category.id
                                  )}
                                  className="checkbox-large !h-4 !w-4 cursor-pointer"
                                  readOnly
                                  onChange={handleCategoriesChange}
                                />
                                <Text className="text-nowrap text-sm">
                                  {category.title}
                                </Text>
                              </div>
                            ))
                          ) : item === "Compétences" ? (
                            <SkillsSelector
                              initialData={skillsSelected}
                              callback={handleSkillsChange}
                            />
                          ) : item === "Langues parlées" ? (
                            <LanguageSelector
                              callback={handleLanguageChange}
                              initialData={languagesSelected}
                            />
                          ) : item === "Ville" ? (
                            <LanguageSearch callback={handleCityChange} />
                          ) : item === "Niveaux d'études" ? (
                            _educationLevel.map((level, _i) => (
                              <div
                                key={_i}
                                className="flex flex-1 items-center justify-start gap-2"
                              >
                                <input
                                  type="checkbox"
                                  id={level.name}
                                  name={level.name}
                                  value={level.id}
                                  onChange={handleDegreeChange}
                                  checked={degreesSelected.includes(level.id)}
                                  className="checkbox-large !h-4 !w-4 cursor-pointer"
                                />
                                <Text className="text-nowrap text-sm">
                                  {level.name}
                                </Text>
                              </div>
                            ))
                          ) : item === "Etablissement actuel" ? (
                            <SchoolSearch callback={handleSchoolChange} />
                          ) : (
                            <p>test</p>
                          )}
                        </ButtonWithDrawer>
                      );
                    })}
                  </div>
                </div>

                <div onClick={resetFilters}>
                  <Text className="cursor-pointer text-sm text-textGray hover:text-blueViolet-600">
                    Réinitialiser les filtres
                  </Text>
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center gap-4">
                  <ButtonWithDrawer name="Colonnes à afficher">
                    {buttonDrawerContent.map((item, _i) => {
                      return (
                        <div
                          key={_i}
                          className="flex flex-1 items-center justify-start gap-2"
                        >
                          <input
                            type="checkbox"
                            id={item.name}
                            name={item.name}
                            className="checkbox-large !h-4 !w-4 cursor-pointer"
                            checked={item.checked}
                            readOnly
                            onChange={handleChange}
                          />
                          <Text className="text-nowrap text-sm">
                            {item.name}
                          </Text>
                        </div>
                      );
                    })}
                  </ButtonWithDrawer>
                  <ButtonWithDrawer name="Action">
                    <div onClick={() => handleOpenPopup("shortList")}>
                      <Text className="cursor-pointer rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white">
                        Placer dans la shortlist d'une mission
                      </Text>
                    </div>
                    <div onClick={() => handleOpenPopup("suggestJob")}>
                      <Text className="cursor-pointer rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white">
                        Suggérer une mission
                      </Text>
                    </div>
                  </ButtonWithDrawer>
                </div>
                <Filters typeList={[]} />
              </div>
            </div>
            <TableAdminStudents
              checkCallback={handleCheckedStudent}
              colToDisplay={buttonDrawerContent}
              resetCheckedCallback={resetCheckedStudent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Students;
