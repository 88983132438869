// Requires
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

// Icons
import { Additem } from "iconsax-react";

// Components
import Button from "../../../UI/Button";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";
import Form from "./Certifications/Form";
import CertificationCard from "../../../Register/CertificationCard";
import Highlight from "../../../UI/Highlight";

// Types
import { CertificationsType, CertificationType } from "../../../../types/user";

// Services
import {
  deleteCertification,
  updateCertification
} from "../../../../services/User/user";
import { useUser } from "../../../../utils/store/userStore";

const Certifications = ({
  initialData
}: {
  initialData?: CertificationsType;
}) => {
  const [certifications, setCertifications] = useState<CertificationsType>(
    initialData || { certifications: [] }
  );

  const [indexToEdit, setIndexToEdit] = useState<number | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const callBackForm = (data: CertificationType) => {
    setCertifications((prev) => ({
      ...prev,
      certifications: [...(prev?.certifications || []), data]
    }));
    setIsVisible(false);
  };

  const handleEdit = (index: number) => {
    setIndexToEdit(index);
    setIsVisible(true);
  };

  const handleRemove = async (index: number) => {
    const certificationId = certifications?.certifications[index].id;

    if (certificationId) {
      const response = await deleteCertification(certificationId);

      if (response) {
        setCertifications((prev) => ({
          ...prev,
          certifications: prev.certifications.filter((_, i) => i !== index)
        }));
      }
    } else {
      setCertifications((prev) => ({
        ...prev,
        certifications: prev.certifications.filter((_, i) => i !== index)
      }));
    }
  };

  const handleCancel = () => {
    setIndexToEdit(null);
    setIsVisible(false);
  };

  const AddOrUpdate = async () => {
    setIsLoading(true);

    try {
      if (certifications && certifications.certifications.length > 0) {
        // Separate existing certifications (with id) and new certifications (without id)
        const existingCertifications = certifications.certifications.filter(
          (c) => c.id
        );
        const newCertifications = certifications.certifications.filter(
          (c) => !c.id
        );

        // Update existing certifications
        const updatePromises = existingCertifications.map(async (item) => {
          const data = new FormData();
          data.append("name", item.name);
          data.append("link", item.link || "");
          data.append(
            "issuedDate",
            item.issuedDate
              ? new Date(item.issuedDate).toISOString()
              : new Date().toISOString()
          );

          if (item.id) {
            // Assuming 'id' is the identifier for certifications
            data.append("certificateId", item.id.toString());
          }

          if (item.attachment) {
            data.append("attachment", item.attachment);
          }

          return await updateCertification(data);
        });

        // Add new certifications
        const addPromises = newCertifications.map(async (item) => {
          const data = new FormData();
          data.append("name", item.name);
          data.append("link", item.link || "");
          data.append(
            "issuedDate",
            item.issuedDate
              ? new Date(item.issuedDate).toISOString()
              : new Date().toISOString()
          );

          if (item.attachment) {
            data.append("attachment", item.attachment);
          }

          return await updateCertification(data); // Assuming you have an addCertification function
        });

        // Execute all update and add operations concurrently
        // const updateResponses = await Promise.all(updatePromises);
        const addResponses = await Promise.all(addPromises);

        // Optionally handle responses (e.g., check for success/failure)
        // For example:
        // const allSuccessful = [...updateResponses, ...addResponses].every(response => response.success);
        // if (!allSuccessful) { /* handle partial failures */ }

        // Update state after successful operations
        setIsLoading(false);
        setSuccess(true);
        useUser.getState().getUser(); // Refresh user data

        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      } else {
        // If there are no certifications to add or update
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error adding/updating certifications:", error);
      setIsLoading(false);
      // Optionally set an error state here to inform the user
      // setError(true); // Assuming you have an error state
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(false);
      }, 3000);
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Certifications</Title>
      <div onClick={() => setIsVisible(true)} className="flex flex-col gap-8">
        <div className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border p-4">
          <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-blueViolet-50 p-4">
            <Additem size={18} />
          </div>
          <Text className="font-semibold">Ajouter une certification</Text>
        </div>
      </div>
      <AnimatePresence>
        {isVisible && (
          <Form
            cancel={handleCancel}
            callBackForm={callBackForm}
            initialData={
              indexToEdit !== null
                ? certifications?.certifications[indexToEdit]
                : undefined
            }
          />
        )}
      </AnimatePresence>
      <div className="flex flex-row flex-wrap gap-4">
        {certifications.certifications.map((certification, index) => {
          return (
            <div className="w-[calc(50%-8px)]" key={index}>
              <CertificationCard
                item={certification}
                index={index}
                onEdit={handleEdit}
                onDelete={handleRemove}
              />
            </div>
          );
        })}
      </div>
      {success && (
        <Highlight type="Success">
          Les données ont bien étés enregistrées
        </Highlight>
      )}
      {initialData == certifications ? (
        <div className="flex flex-row justify-end">
          <Button type="disabled">Enregistrer</Button>
        </div>
      ) : (
        <div onClick={AddOrUpdate} className="flex flex-row justify-end">
          <Button type="full">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Certifications;
