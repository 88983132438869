// Icons
import { DocumentDownload } from "iconsax-react";

// Components
import Text from "./Text";
import CustomTooltip from "./Tooltip";

export const CSVTag = () => {
  return (
    <CustomTooltip position="left" content="Télécharger au format CSV">
      <div className="flex cursor-pointer flex-row items-center justify-center gap-1 rounded-lg bg-blueViolet-50 p-2">
        <DocumentDownload size={18} />
        <Text>CSV</Text>
      </div>
    </CustomTooltip>
  );
};

export const PDFTag = () => {
  return (
    <CustomTooltip position="left" content="Télécharger au format PDF">
      <div className="flex cursor-pointer flex-row items-center justify-center gap-1 rounded-lg bg-blueViolet-50 p-2">
        <DocumentDownload size={18} />
        <Text>PDF</Text>
      </div>
    </CustomTooltip>
  );
};
