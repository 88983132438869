// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

// Icons
import {
  CalendarEdit,
  Call,
  Folder2,
  Kanban,
  Notification,
  Personalcard,
  Security,
  Sms,
  Trash,
  Verify,
  Book,
  ArchiveBook,
  Briefcase,
  ArrowLeft2
} from "iconsax-react";

// Components
import AccountDeletion from "../../../../../components/Profil/Edit/AccountDeletion";
import Administration from "../../../../../components/Profil/Edit/Administration";
import AvailabilityPreferences from "../../../../../components/Profil/Edit/AvailabilityPreferences";
import NotificationSettings from "../../../../../components/Profil/Edit/NotificationSettings";
import PasswordSecurity from "../../../../../components/Profil/Edit/PasswordSecurity";
import PersonnalInformations from "../../../../../components/Profil/Edit/PersonnalInformations";
import ProfilContent from "../../../../../components/Profil/Edit/ProfilContent";
import Certifications from "../../../../../components/Profil/Edit/ProfilContent/Certifications"; // Added
import Formations from "../../../../../components/Profil/Edit/ProfilContent/Formations"; // Added
import Portfolios from "../../../../../components/Profil/Edit/ProfilContent/Portfolios"; // Added
import ProfessionalExperiences from "../../../../../components/Profil/Edit/ProfilContent/ProfessionalExperiences"; // Added
import Text from "../../../../../components/UI/Text";
import Avatar from "../../../../../components/UI/Avatar/Avatar";

// Stores
import { useUser } from "../../../../../utils/store/userStore";

const EditProfil = () => {
  const user = useUser((state) => state.userdata);
  const [activeMenuItem, setActiveMenuItem] = useState(0); // TODO: is mobile maybe set to -1 (sry Stani)

  const _COMPANY_MEMBER_MODE = user?.roleName === "company";

  const menuLinks: {
    id: number;
    name: string;
    icon: React.ReactNode;
    element: React.ReactNode;
    onlyForStudent: boolean;
  }[] = [
    {
      id: 0,
      name: "Profil",
      icon: <Kanban size={18} />,
      element: <ProfilContent />,
      onlyForStudent: false
    },
    {
      id: 1,
      name: "Informations personnelles",
      icon: <Personalcard size={18} />,
      element: <PersonnalInformations />,
      onlyForStudent: false
    },
    {
      id: 2,
      name: "Administratif",
      icon: <Folder2 size={18} />,
      element: <Administration />,
      onlyForStudent: false
    },
    {
      id: 3,
      name: "Disponibilités et préférences",
      icon: <CalendarEdit size={18} />,
      element: <AvailabilityPreferences />,
      onlyForStudent: true
    },
    {
      id: 4,
      name: "Expériences Professionnelles",
      icon: <Briefcase size={18} />, // Replace with an appropriate icon
      element: (
        <ProfessionalExperiences
          data={
            user?.experiences
              ? { experiences: user.experiences }
              : { experiences: [] }
          }
        />
      ),
      onlyForStudent: true
    },
    {
      id: 5,
      name: "Formations",
      icon: <Book size={18} />, // Replace with an appropriate icon
      element: (
        <Formations
          data={
            user?.education
              ? { formations: user.education }
              : { formations: [] }
          }
        />
      ),
      onlyForStudent: true
    },
    {
      id: 6,
      name: "Portfolios & Autres documents",
      icon: <ArchiveBook size={18} />, // Replace with an appropriate icon
      element: (
        <Portfolios
          initialData={
            user?.portfolios
              ? { portfolios: user.portfolios }
              : { portfolios: [] }
          }
        />
      ),
      onlyForStudent: true
    },
    {
      id: 7,
      name: "Certifications",
      icon: <Verify size={18} />, // Replace with an appropriate icon
      element: (
        <Certifications
          initialData={
            user?.certifications
              ? { certifications: user.certifications }
              : { certifications: [] }
          }
        />
      ),
      onlyForStudent: true
    },
    {
      id: 8,
      name: "Email et mot de passe",
      icon: <Security size={18} />,
      element: <PasswordSecurity />,
      onlyForStudent: false
    },
    {
      id: 9,
      name: "Notifications",
      icon: <Notification size={18} />,
      element: <NotificationSettings />,
      onlyForStudent: false
    },
    {
      id: 10,
      name: "Suppression du compte",
      icon: <Trash size={18} />,
      element: <AccountDeletion />,
      onlyForStudent: false
    }
  ];

  return (
    <div className="flex flex-col gap-8">
      <div
        className="relative h-[250px] rounded-t-lg bg-cover bg-center"
        style={{ backgroundImage: `url('${user?.banner}')` }}
      ></div>

      <div className="flex flex-row gap-8 pl-6 pr-6 max-md:flex-col">
        <div className="z-40 mt-[-135px] flex flex-col gap-8">
          <div className="flex w-fit min-w-[350px] flex-col gap-8 rounded-lg bg-white p-4 shadow-lg">
            <div className="flex flex-row items-center gap-4">
              <Avatar link={user?.image} className="size-20" />
              <div className="flex flex-col">
                <Text className="text-xl font-semibold">
                  {user?.firstName} {user?.lastName.toLocaleUpperCase()}
                </Text>
                <Text>{user?.profileTitle}</Text>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Text className="font-semibold">Information de contact</Text>
              <div className="flex flex-row items-center gap-2">
                <Call size={18} />
                <Text>{user?.phoneNumber}</Text>
              </div>
              <div className="flex flex-row items-center gap-2">
                <Sms size={18} />
                <Text>{user?.email}</Text>
              </div>
            </div>
          </div>

          <div
            className={`flex gap-4 rounded-lg border p-4 shadow-lg hover:bg-blueViolet-50 hover:text-blueViolet-600 md:hidden ${
              activeMenuItem != -1 ? "" : "md:hidden"
            }`}
            onClick={() => setActiveMenuItem(-1)}
          >
            <ArrowLeft2 />
            Retour au menu
          </div>

          <div
            className={`flex flex-col gap-4 rounded-lg border p-4 shadow-lg ${
              activeMenuItem != -1 ? "max-md:hidden" : ""
            }`}
          >
            {menuLinks
              .filter((item) => !_COMPANY_MEMBER_MODE || !item.onlyForStudent)
              .map((item) => (
                <div
                  key={item.id}
                  className={`group flex cursor-pointer flex-row items-center gap-1 rounded-lg p-2 ${
                    activeMenuItem === item.id
                      ? "bg-blueViolet-50 text-blueViolet-600"
                      : "hover:bg-blueViolet-50 hover:text-blueViolet-600"
                  }`}
                  onClick={() => setActiveMenuItem(item.id)}
                >
                  {item.icon}
                  <Text
                    className={`group-hover:!text-blueViolet-600 ${
                      activeMenuItem === item.id
                        ? "!text-blueViolet-600"
                        : "text-blueViolet-950"
                    }`}
                  >
                    {item.name}
                  </Text>
                </div>
              ))}
          </div>
        </div>

        <div className="flex h-fit grow flex-col gap-8">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeMenuItem}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
              className="flex flex-col gap-8"
            >
              {menuLinks[activeMenuItem]?.element}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default EditProfil;
