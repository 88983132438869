// Icons
import { Chart1 } from "iconsax-react";

// Components
import Title from "../UI/Title";
import Text from "../UI/Text";

// Types
import { userCounts } from "../../types/admin";

const UsersStats = ({ data }: { data: userCounts }) => {
  return (
    <div className="flex flex-row gap-4 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-1 flex-col gap-4 rounded-lg border p-4">
        <div className="flex flex-row items-center gap-2">
          <Chart1 size={18} />
          <Title type="bloc">Etudiant inscrits</Title>
        </div>
        <div className="flex items-center justify-center">
          <Text className="!text-3xl font-semibold !text-blueViolet-600">
            {data.studentCount}
          </Text>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-4 rounded-lg border p-4">
        <div className="flex flex-row items-center gap-2">
          <Chart1 size={18} />
          <Title type="bloc">Entreprise inscrites</Title>
        </div>
        <div className="flex items-center justify-center">
          <Text className="!text-3xl font-semibold !text-blueViolet-600">
            {data.companyCount}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default UsersStats;
