// Requires
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Icons
import { CloseSquare, Document } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import Title from "../UI/Title";
import { LittleCard } from "../Cards/Company/HorizontalLittleCard";
import ProfilSought from "../Jobs/Details/ProfilSought";
import Remember from "../Jobs/Details/Remember";
import JobResume from "./Admin/Shortlist/JobResume";

// Services
import { getJobDetails } from "../../services/Job/job";

// Types
import { JobDetailsType } from "../../types/job";
import { usePopup } from "../../context/Popup";

const JobDetails = () => {
  const { id } = useParams();
  const { closePopup } = usePopup();

  const [job, setJob] = useState<JobDetailsType>();

  useEffect(() => {
    const fetch = async () => {
      const response = await getJobDetails(id);
      setJob(response);
    };

    fetch();
  }, []);

  if (!job) return <div className="loader" />;

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-row items-center justify-between gap-8">
        <div className="flex flex-row items-center gap-2">
          <Document size={18} />
          <Title type="bloc">Rappel de la mission</Title>
        </div>
        <CloseSquare
          size={18}
          onClick={closePopup}
          className="cursor-pointer"
        />
      </div>
      <Content>
        <div className="flex flex-col gap-8">
          <div className="flex flex-row gap-8 max-md:flex-col">
            <div className="flex flex-1 flex-col gap-8 md:max-w-[calc(100%-530px)]">
              <Remember data={job} />
              <ProfilSought data={job} />
            </div>
            <div className="flex h-fit flex-col gap-8 md:min-w-[450px]">
              <LittleCard data={job} />
              <JobResume data={job} />
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default JobDetails;
