// Stores
import { useUser } from "../../../utils/store/userStore";

// Components
import Personalization from "./ProfilContent/Personalization";
import Professionalization from "./ProfilContent/Professionalization";

const ProfilContent = () => {
  const user = useUser((state) => state.userdata);

  const _COMPANY_MEMBER_MODE = user?.roleName === "company";

  return (
    <>
      <Personalization />
      {!_COMPANY_MEMBER_MODE && (
        <>
          <Professionalization />
        </>
      )}
    </>
  );
};

export default ProfilContent;
