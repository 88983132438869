// Helpers
import config from "../../utils/config";

// Types
type Error = {
  error: boolean;
  message: string;
};

export const fetchApi = async (
  action: string,
  method: "GET" | "POST" | "DELETE" | "PATCH",
  data?: any
) => {
  const headers: HeadersInit = {
    ...(!(data instanceof FormData) && { "Content-Type": "application/json" }) // Ensure the content type is set unless data is FormData
  };

  try {
    const response: Response = await fetch(`${config.backUrl}${action}`, {
      method: method,
      headers,
      body: data
        ? data instanceof FormData
          ? data
          : JSON.stringify(data)
        : null,
      credentials: "include"
    });

    if (!response.ok) {
      const error: Error = await response.json();
      return {
        error: true,
        message: error.message
      };
    }

    return await response.json();
  } catch (error) {
    return {
      error: true,
      message: error instanceof Error ? error.message : "Unknown error"
    };
  }
};
