// Components
import DisponibilityAndPreferences from "./AvailabilityPreferences/DisponibilityAndPreferences";
import AlternationsAndInterships from "./AvailabilityPreferences/AlternationsAndInternships";

const AvailabilityPreferences = () => {
  return (
    <>
      <DisponibilityAndPreferences />
      <AlternationsAndInterships />
    </>
  );
};

export default AvailabilityPreferences;
