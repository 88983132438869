// Requires
import { useEffect, useState } from "react";

// Components
import Text from "../../../UI/Text";
import FileCard from "../../../Chat/FileCard";
import { getOwnSchoolCertificate } from "../../../../services/User/user";
import { useUser } from "../../../../utils/store/userStore";

// Types
type FileData = {
  fileName: string;
  fileType: string;
  url: string;
};

const SchoolCertificateFileView = ({ title }: { title?: boolean }) => {
  const [file, setFile] = useState<FileData | null>(null);

  const user = useUser((state) => state.userdata);

  useEffect(() => {
    const fetchCertificate = async () => {
      if (user) {
        const response = await getOwnSchoolCertificate();
        if (response && response.url) {
          const url = response.url;

          // Extract the file name from the URL
          const fileName = url.split("/").pop().split("?")[0];

          // Infer the file type from the file extension
          const fileExtension = fileName.split(".").pop().toLowerCase();
          let fileType = "";
          switch (fileExtension) {
            case "pdf":
              fileType = "application/pdf";
              break;
            case "jpg":
            case "jpeg":
              fileType = "image/jpeg";
              break;
            case "png":
              fileType = "image/png";
              break;
            // Add more cases as needed
            default:
              fileType = "application/octet-stream";
          }

          setFile({
            fileName: fileName,
            fileType: fileType,
            url: url
          });
        }
      }
    };
    fetchCertificate();
  }, [user]);

  const handleDownload = () => {
    if (file) {
      // Create an anchor element and simulate a click to download the file
      const link = document.createElement("a");
      link.href = file.url;
      link.download = file.fileName;
      link.click();
    }
  };

  return (
    <>
      {title && <Text className="font-semibold">Document joint</Text>}
      {file && (
        <div
          className="flex cursor-pointer flex-row flex-wrap gap-4"
          onClick={handleDownload}
        >
          <FileCard file={file} />
        </div>
      )}
    </>
  );
};

export default SchoolCertificateFileView;
