// Requires
import { useEffect, useState } from "react";

// Components
import { InlineWidget } from "react-calendly";
import Button from "../../../../../components/UI/Button";
import Text from "../../../../../components/UI/Text";
import Title from "../../../../../components/UI/Title";

// Context
import { useNavigate } from "react-router-dom";
import { useUI } from "../../../../../utils/context/UI";
import {
  getLocalStorage,
  removeFromLocalStorage
} from "../../../../../utils/helpers/localStorage";
import { useMobile } from "../../../../../utils/mobile/responsive";

const Five = () => {
  const { step } = useUI();

  const [data, setData] = useState<{
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber: string;
  }>({
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: ""
  });

  const navigate = useNavigate();

  const isMobile = useMobile();

  useEffect(() => {
    const data = getLocalStorage("companyRegisterInfos");
    if (data) {
      const dataParse = JSON.parse(data);
      setData((prev) => ({
        ...prev,
        email: dataParse.step1.email,
        firstName: `${dataParse.step3.firstName} ${dataParse.step3.lastName}`,
        phoneNumber: dataParse.step3.phone
      }));
    }
  }, []);

  const nextStep = () => {
    removeFromLocalStorage("companyRegisterInfos");
    removeFromLocalStorage("companyRegisterStep");

    navigate("/company/subscription");
  };

  return (
    <div className="w-full md:p-0">
      <div className="flex items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center gap-12 md:gap-0">
          <div className="flex items-center justify-center">
            <Title type="page">
              Pour bien débuter, échangeons ensemble sur vos besoins
              <span style={{ color: "red" }}> * </span>
            </Title>
          </div>
          <div className="h-[850px] w-full overflow-hidden md:h-auto">
            <InlineWidget
              pageSettings={{
                backgroundColor: "ffffff",
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: "00a2ff",
                textColor: "4d5055"
              }}
              prefill={{
                email: data?.email ?? "test",
                name: data?.firstName,
                location: data.phoneNumber
              }}
              styles={
                isMobile
                  ? { width: "100%", height: "850px" }
                  : { width: "100%", height: "700px" }
              }
              url="https://calendly.com/d/cqts-t7n-238/demo-juwa"
            />
          </div>
          <div className="flex w-full min-w-[50%] max-w-[50%] flex-1 items-center justify-between">
            <Button type="full" className="!cursor-default opacity-0">
              Accéder à la plateforme
            </Button>
            <div className="flex flex-1 items-center justify-center">
              <Text>Étape {step + 1}/5</Text>
            </div>

            <div onClick={nextStep}>
              <Button type="full">Accéder à la plateforme</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
