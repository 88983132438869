import { Link } from "react-router-dom";
import Logo from "../../assets/logos/Juwa_logo_marine.svg";
import Button from "../../components/UI/Button";

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex h-screen min-h-[100vh] w-full flex-col gap-8 overflow-y-auto">
      <div className="flex w-full flex-row items-center justify-between border-b p-4">
        <img src={Logo} alt="Logo Juwa" className="w-[120px]" />
        <div className="flex flex-row gap-2">
          <Link to="/auth/login">
            <Button type="full">Se connecter</Button>
          </Link>
          <Link to="/auth/register">
            <Button type="full">S'inscrire</Button>
          </Link>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Layout;
