// Icons
import { Building4, Edit, Trash } from "iconsax-react";

// Components
import Date from "../../UI/Date";
import Text from "../../UI/Text";

const Alternation = ({
  item,
  removeCallback,
  editCallback,
  index
}: {
  item: {
    start: string;
    end: string;
    type: "internship" | "alternation" | "fullTimeAvailability";
  };
  index?: number;
  removeCallback?: (id: number) => void;
  editCallback?: (id: number) => void;
}) => {
  return (
    <div className="flex flex-row items-center gap-4 rounded-lg border p-4">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-4">
          <Building4 size={18} />
          <div className="flex flex-row items-center gap-1">
            <Text>
              {item.type === "internship" ? "Stage" : "Alternance"} du
            </Text>
            <Date
              format="DD/MM/YY"
              date={item.start}
              className="font-semibold"
            />
            <Text>jusqu'au</Text>
            <Date format="DD/MM/YY" date={item.end} className="font-semibold" />
          </div>
        </div>
        <div className="flex flex-row items-center gap-1">
          {editCallback !== undefined && index !== undefined && (
            <div
              onClick={() => editCallback(index)}
              className="rounded-lg p-2 hover:cursor-pointer hover:bg-blueViolet-50 hover:text-blueViolet-600"
            >
              <Edit size={18} />
            </div>
          )}
          {removeCallback != undefined && index != undefined && (
            <div
              onClick={() => removeCallback(index)}
              className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50 hover:text-red-500"
            >
              <Trash size={18} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alternation;
