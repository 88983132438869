// Requires
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";

// Components
import JobResume from "../Popup/Admin/Shortlist/JobResume";
import Button from "../UI/Button";
import Text from "../UI/Text";

// Services
import { getJobDetails, getAllJobs } from "../../services/Job/job";

// Types
import { JobDetailsType } from "../../types/job";

const JobSearch = ({
  callback
}: {
  callback: (data: JobDetailsType) => void;
}) => {
  const [query, setQuery] = useState<string>("");
  const [uniqueId, setUniqueId] = useState<string>("");
  const [dataJob, setDataJob] = useState<JobDetailsType>();
  const [searchResults, setSearchResults] = useState<JobDetailsType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const handleValidate = async () => {
    if (!uniqueId) return;

    setIsLoading(true);
    const response = await getJobDetails(uniqueId);

    if (response) {
      setDataJob(response);
      callback(response);
    }

    setIsLoading(false);
  };

  const handleEdit = () => {
    setDataJob(undefined);
    setUniqueId("");
  };

  const handleSearch = async () => {
    if (!query.trim()) return;

    setIsSearching(true);
    const filters = { search: query };

    const response = await getAllJobs(0, 20, filters);
    setSearchResults(response?.jobs || []);
    setIsSearching(false);
  };

  const handleValidateSearch = async (id: number) => {
    setUniqueId(id.toString());
    await handleValidate();
    setQuery("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleValidate();
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        <Text>Choisissez maintenant la mission correspondante :</Text>
        <div className="flex w-fit flex-row items-center gap-4 rounded-lg border p-2">
          <input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            type="text"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            placeholder="Recherche par titre..."
          />
          <Text>OU</Text>
          <input
            onKeyDown={handleKeyDown}
            onChange={(e) => setUniqueId(e.target.value)}
            value={uniqueId}
            type="text"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            placeholder="Identifiant de la mission"
          />
          <div onClick={dataJob ? handleEdit : handleValidate}>
            <Button type="full">
              {isLoading ? (
                <div className="loader" />
              ) : dataJob ? (
                "Modifier"
              ) : (
                "Valider"
              )}
            </Button>
          </div>
        </div>

        <AnimatePresence>
          {query && (
            <div className="rounded-lg border p-2 shadow-lg">
              {isSearching && <div className="loader" />}
              {!isSearching && searchResults.length === 0 && (
                <Text>Aucun résultat avec le mot-clé : {query}</Text>
              )}
              {!isSearching &&
                searchResults.length > 0 &&
                searchResults.map((job, index) => (
                  <div
                    key={index}
                    onClick={() => handleValidateSearch(job.id)}
                    className="group flex cursor-pointer flex-row gap-2 overflow-hidden rounded-lg p-2 hover:bg-blueViolet-600"
                  >
                    <img
                      src={job.company.image}
                      alt={job.title}
                      className="size-10 rounded-lg"
                    />
                    <div className="flex flex-col">
                      <Text className="font-semibold group-hover:text-white">
                        {job.title}
                      </Text>
                      <Text className="text-sm group-hover:text-white">
                        {job.company?.displayName} - {job.owner.firstName}{" "}
                        {job.owner.lastName}
                      </Text>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence key={"jobResume"}>
        {dataJob && <JobResume data={dataJob} />}
      </AnimatePresence>
    </div>
  );
};

export default JobSearch;
