const Title = ({
  children,
  classname,
  type
}: {
  children: React.ReactNode;
  classname?: string;
  type: "page" | "bloc" | "custom";
}) => {
  if (type === "page") {
    return (
      <div
        className={`text-xl font-semibold md:text-center md:text-2xl lg:text-start ${classname || ""}`}
      >
        {children}
      </div>
    );
  }

  if (type === "bloc") {
    return (
      <div
        className={`text-center text-sm font-semibold md:text-lg lg:text-start ${classname || ""}`}
      >
        {children}
      </div>
    );
  }

  if (type === "custom") {
    return (
      <div
        className={`text-center font-semibold lg:text-start ${classname || ""}`}
      >
        {children}
      </div>
    );
  }

  return null;
};

export default Title;
