// Components
import Button from "../../../UI/Button";
import Highlight from "../../../UI/Highlight";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";

// Requires
import { useState } from "react";

// Helpers
import { validateEmail } from "../../../../utils/helpers/validators";

// Services
import {
  updateEmail,
  resendEmailConfirmation,
  validateUserEmail
} from "../../../../services/User/user";
import { useUser } from "../../../../utils/store/userStore";

// Types
type Error = {
  newEmail?: string;
  code?: string;
};

const Email = () => {
  const [newEmail, setNewEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [errors, setErrors] = useState<Error>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const [step, setStep] = useState<"email" | "code">("email");

  const user = useUser((state) => state.userdata);

  const update = async () => {
    setErrors({});
    setIsLoading(true);
    setSuccess("");

    const newErrors: Error = {};

    if (!newEmail) {
      newErrors.newEmail = "Tu dois entrer une nouvelle adresse email";
    } else if (!validateEmail(newEmail)) {
      newErrors.newEmail = "Le format de l'adresse email n'est pas valide";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    const data = {
      email: newEmail
    };

    const response = await updateEmail(data);
    if (response) {
      setSuccess(
        `Un code de vérification a été envoyé à l'adresse email suivante : ${newEmail}`
      );
      setStep("code");
    } else {
      setErrors({
        newEmail:
          "Une erreur s'est produite lors de l'envoi du code de vérification"
      });
    }
    setIsLoading(false);
  };

  const verifyCode = async () => {
    setErrors({});
    setIsLoading(true);
    setSuccess("");

    const newErrors: Error = {};

    if (!code) {
      newErrors.code = "Tu dois entrer le code de vérification";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    const data = {
      code: code
    };

    const response = await validateUserEmail(data);
    if (response) {
      setSuccess("Ton adresse email a bien été mise à jour");
      setStep("email");
      setNewEmail("");
      setCode("");
    } else {
      setErrors({ code: "Le code de vérification est invalide" });
    }
    setIsLoading(false);
  };

  const resendCode = async () => {
    setIsLoading(true);
    setSuccess("");

    const response = await resendEmailConfirmation();
    if (response) {
      setSuccess(`Un nouveau code a été envoyé à ${newEmail}`);
    } else {
      setErrors({
        code: "Une erreur s'est produite lors de l'envoi du code de vérification"
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-col">
        <Title type="bloc">Adresse email</Title>
        <Text>{user?.email}</Text>
      </div>
      {success && <Highlight type="Success">{success}</Highlight>}
      {step === "email" && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Text className="font-semibold">Nouvelle adresse email</Text>
            {errors.newEmail && (
              <Text className="text-sm text-red-500">{errors.newEmail}</Text>
            )}
            <input
              type="email"
              className="rounded-lg border p-2 focus:outline-blueViolet-600"
              placeholder="email@example.com"
              value={newEmail}
              onChange={(e) => setNewEmail(e.currentTarget.value)}
            />
          </div>
          <div onClick={update} className="flex flex-row justify-end">
            {!newEmail ? (
              <Button type="disabled">Envoyer le code de vérification</Button>
            ) : (
              <Button type="full">
                {isLoading ? (
                  <div className="loader" />
                ) : (
                  "Envoyer le code de vérification"
                )}
              </Button>
            )}
          </div>
        </div>
      )}
      {step === "code" && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Text className="font-semibold">Code de vérification</Text>
            {errors.code && (
              <Text className="text-sm text-red-500">{errors.code}</Text>
            )}
            <input
              type="text"
              className="rounded-lg border p-2 focus:outline-blueViolet-600"
              placeholder="Code de vérification"
              value={code}
              onChange={(e) => setCode(e.currentTarget.value)}
            />
          </div>
          <div className="flex flex-row gap-2">
            <div onClick={verifyCode}>
              <Button type="full">
                {isLoading ? (
                  <div className="loader" />
                ) : (
                  "Vérifier et mettre à jour"
                )}
              </Button>
            </div>
            <div onClick={resendCode}>
              <Button type="cancel">
                {isLoading ? <div className="loader" /> : "Renvoyer le code"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Email;
