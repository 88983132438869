// Icons
import { Additem } from "iconsax-react";

// Requires
import { useState } from "react";

// Components
import ExperienceCard from "../../../Register/ExperienceCard";
import Button from "../../../UI/Button";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";
import Form from "./Formations/Form";
import Highlight from "../../../UI/Highlight";

// Services
import { updateFormation } from "../../../../services/User/user";

// Types
import { FormationsType, FormationType } from "../../../../types/user";
import { useUser } from "../../../../utils/store/userStore";

const Formations = ({ data }: { data?: FormationsType }) => {
  const [formations, setFormations] = useState<FormationsType>(
    data ?? { formations: [] }
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleCancel = () => {
    setIsOpen(false);
    setEditingIndex(null);
  };

  const AddOrUpdate = (data: FormationType) => {
    setIsOpen(false);

    if (editingIndex !== null) {
      setFormations((prev) => ({
        ...prev,
        formations: prev.formations.map((item, index) =>
          index === editingIndex ? { ...item, ...data } : item
        )
      }));
      setEditingIndex(null);
    } else {
      setFormations((prev) => ({
        ...prev,
        formations: [...(prev?.formations || []), data]
      }));
    }
  };

  const handleRemove = (index: number) => {
    setFormations((prev) => ({
      ...prev,
      formations: prev.formations.filter((_, i) => i !== index)
    }));
  };

  const handleEdit = (index: number) => {
    const formationToEdit = formations?.formations[index];
    if (formationToEdit) {
      setIsOpen(true);
      setEditingIndex(index);
    }
  };

  const isFormationsType = (data: any): data is FormationsType => {
    return data.formations.every((formation: any) => {
      return (
        Array.isArray(formation.skills) &&
        formation.skills.every((skill: any) => typeof skill === "number")
      );
    });
  };

  const createOrUpdate = async () => {
    setIsLoading(true);
    if (formations) {
      const transformedFormations = {
        formations: formations.formations.map(
          ({ id, studentId, link, skills, ...rest }) => ({
            ...rest,
            skills: skills?.map((skill) => skill.id),
            start: rest.start?.toString() || new Date().toISOString(),
            end: rest.end?.toString() || new Date().toISOString()
          })
        )
      };

      if (isFormationsType(transformedFormations)) {
        const response = await updateFormation(transformedFormations);

        if (response) {
          setSuccess(true);
          setIsLoading(false);
          useUser.getState().getUser();
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
          return;
        }
      }
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Formations</Title>
      <div onClick={() => setIsOpen(true)} className="flex flex-col gap-8">
        <div className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border p-4">
          <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-blueViolet-50 p-4">
            <Additem size={18} />
          </div>
          <Text className="font-semibold">Ajouter une formation</Text>
        </div>
      </div>
      {isOpen && (
        <Form
          callback={AddOrUpdate}
          initialData={
            editingIndex !== null
              ? formations.formations?.[editingIndex]
              : undefined
          }
          cancel={handleCancel}
        />
      )}
      {formations &&
        formations.formations.length > 0 &&
        formations.formations.map((item, _id) => (
          <ExperienceCard
            key={_id}
            data={item}
            index={_id}
            onDelete={handleRemove}
            onEdit={handleEdit}
          />
        ))}
      {!formations ||
        (formations && formations.formations.length < 1 && (
          <Text>Aucune formation pour le moment</Text>
        ))}
      {success && (
        <Highlight type="Success">
          Les modifications ont bien été appliquées.
        </Highlight>
      )}
      {formations.formations !== data?.formations ? (
        <div onClick={createOrUpdate} className="flex flex-row justify-end">
          <Button type="full">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      ) : (
        <div className="flex flex-row justify-end">
          <Button type="disabled">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Formations;
