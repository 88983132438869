// Requires
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// Components
import AvatarChanger from "../../../Misc/AvatarChanger";
import BannerChanger from "../../../Misc/BannerChanger";
import Button from "../../../UI/Button";
import Highlight from "../../../UI/Highlight";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";

// Stores
import { useUser } from "../../../../utils/store/userStore";

// Services
import { updateStudentProfile } from "../../../../services/User/user";

// Types
type Error = {
  presentation?: string;
  images?: string;
};

const Personalization = () => {
  const user = useUser((state) => state.userdata);

  const _COMPANY_MEMBER_MODE = user?.roleName === "company";

  const [image, setImage] = useState<File>();
  const [banner, setBanner] = useState<File>();
  const [presentation, setPresentation] = useState<string>(
    user?.presentation || ""
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const updateImage = (image: File) => {
    setImage(image);
  };

  const updateBanner = (image: File) => {
    setBanner(image);
  };

  const updatePersonalization = async (isCompany = false) => {
    setError({});
    const isPresentationValid =
      presentation?.length >= 200 && presentation?.length <= 500;
    const hasNewPresentation = presentation !== user?.presentation;
    const hasMediaUpdate = image || banner;

    if (hasMediaUpdate || (!isCompany && hasNewPresentation)) {
      setIsLoading(true);

      const formData = new FormData();
      if (image) formData.append("image", image);
      if (banner) formData.append("banner", banner);

      if (!isCompany && hasNewPresentation) {
        if (!isPresentationValid) {
          setError({
            presentation:
              "Tu dois de présenter avec un minimum de 200 caractères et maximum 500 caractères"
          });
          setIsLoading(false);
          return;
        }
        formData.append("presentation", presentation);
      }

      try {
        const response = await updateStudentProfile(formData);
        if (!response) {
          setError({
            images:
              "Une erreur s'est produite lors de la mise à jour du profil."
          });
        } else {
          setSuccess(true);
          setTimeout(() => {
            useUser.getState().getUser();
            setSuccess(false);
          }, 2000);
        }
      } catch (err) {
        setError({ images: "Une erreur inattendue s'est produite." });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Personnalise ton profil</Title>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-8">
          <AvatarChanger callback={updateImage} />
          <BannerChanger callback={updateBanner} />
          {error?.images && (
            <Text className="text-xs text-red-500">{error.images}</Text>
          )}
          {!_COMPANY_MEMBER_MODE && (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <Text className="font-semibold">Présentation</Text>
                <Text className="text-sm">
                  Présente toi de manière engageante pour montrer aux
                  entreprises ta personnalité, ton expertise et leur faire
                  comprendre pourquoi ils devraient faire appel à toi
                </Text>
              </div>
              <textarea
                className="textarea-autorezise h-[150px] w-full resize-none rounded-lg border p-4 focus:outline-blueViolet-600"
                placeholder="Décris toi au mieux"
                maxLength={500}
                value={presentation}
                onChange={(e) => setPresentation(e.currentTarget.value)}
              />
              <div className="flex flex-row items-end justify-between">
                {error?.presentation && (
                  <Text className="text-xs text-red-500">
                    {error.presentation}
                  </Text>
                )}
                <Text
                  className={`text-xs ${!error?.presentation ? "ml-auto" : ""}`}
                >
                  {presentation.length}/500 caractères
                </Text>
              </div>
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {success && (
          <motion.div
            key={"animated"}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <Highlight type="Success">
              Les modifications ont bien été appliquées.
            </Highlight>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        onClick={() =>
          updatePersonalization(_COMPANY_MEMBER_MODE ? true : false)
        }
        className="flex flex-row justify-end"
      >
        {banner || image ? (
          <Button type="full">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        ) : (
          <Button type="disabled">Enregistrer</Button>
        )}
      </div>
    </div>
  );
};

export default Personalization;
