// Requires
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// Components
import Title from "../../../../../../components/UI/Title";
import Text from "../../../../../../components/UI/Text";
import Avatar from "../../../../../../components/UI/Avatar/Avatar";

// Services
import { getPaymentResume } from "../../../../../../services/Payment/payment";

const Resume = ({
  code
}: {
  code?: { id: number; title: string; percent: number; expiredDate: string };
}) => {
  const { id } = useParams();

  const [data, setData] = useState<{
    company: { id: number; name: string };
    job: { id: number; title: string };
    student: { id: number; firstName: string; lastName: string; image: string };
    fees: { juwaFeesHT: number; juwaFeesPercent: number; juwaFeesVAT: number };
    service: { hours: number; hourlyRate: number; totalAmount: number };
    totals: { totalHT: number; totalTTC: number };
    totalAmount: number;
    paymentType: "SIMPLE" | "COMPLEXE" | "RECURENT";
  }>();

  useEffect(() => {
    const fetch = async () => {
      const response = await getPaymentResume(Number(id));
      setData(response);
    };

    fetch();
  }, [id]);

  return (
    <div className="flex h-fit flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Résumé rapide</Title>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-1">
          <Text className="font-semibold">Titre de la mission :</Text>
          <Link to={"#"}>{data?.job.title}</Link>
        </div>
        <div className="flex flex-row items-center gap-1">
          <Text className="font-semibold">Type de mission:</Text>
          <Link to={"#"}>
            {data?.paymentType === "SIMPLE" || data?.paymentType === "COMPLEXE"
              ? "Mission ponctuelle"
              : "Mission recurrente"}
          </Link>
        </div>
        <div className="flex flex-col gap-4">
          <Title type="bloc">Etudiant séléctionné</Title>
          <div className="rounde-lg flex flex-row items-center gap-2 rounded-lg border p-2">
            <Avatar link={data?.student.image} className="size-12 rounded-lg" />
            <div className="flex grow items-center justify-center">
              <Text className="font-semibold">
                {data?.student.firstName} {data?.student.lastName}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
