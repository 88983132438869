// Components
import VisualIdentity from "./VisualIdentity";

const GeneralInformations = ({ companyMode }: { companyMode?: boolean }) => {
  return (
    <div className="flex flex-col gap-8">
      <VisualIdentity companyMode={companyMode} />
      {/* <CompanyInformations /> */}
    </div>
  );
};

export default GeneralInformations;
