// Requires
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

// Icons
import { Document, SmsEdit, Trash } from "iconsax-react";

// Hooks and Context
import { usePopup } from "../../../context/Popup";
import { useChat } from "../../../context/Chat/ChatContext";

// Components
import Button from "../../UI/Button";
import Text from "../../UI/Text";
import Title from "../../UI/Title";

// Utils
import { useUser } from "../../../utils/store/userStore";

// Services
import { unsetInShortlist } from "../../../services/Admin/Jobs";
import { useState } from "react";

const StudentHorizontalCardMin = ({
  item,
  callbackDelete
}: {
  item: {
    id: number;
    firstName: string;
    lastName: string;
    image: string;
    proposalId: number;
  };
  callbackDelete: (id: number) => void;
}) => {
  const { togglePopup } = usePopup();
  const navigate = useNavigate();

  const { id } = useParams();

  const _ADMIN_MODE = useUser((state) => state.userdata?.roleName === "admin");

  // Chat context
  const { createConversation, setActiveConversation } = useChat();

  // State for loading and error
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const handleDelete = async () => {
    await unsetInShortlist(Number(id), item.id);
    callbackDelete(item.id);
  };

  const contactStudent = async () => {
    if (item.id) {
      setLoading(true);
      setError("");

      try {
        const response = await createConversation(
          item.id, // Student ID
          Number(id), // Job ID from URL params
          `Bonjour ${item.firstName}, je suis intéressé(e) pour collaborer avec vous. Pouvez-vous m'en dire plus ?`,
          []
        );

        // Assuming response.id contains the conversation ID
        if (response.id) {
          setActiveConversation(response);
          navigate("/messages");
        } else {
          throw new Error("Invalid response from server.");
        }
      } catch (err: any) {
        setError(err.message || "An unexpected error occurred.");
        console.error("Error sending message:", err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 1, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.5 }}
      className="relative flex flex-col gap-4 rounded-lg border bg-white p-4 text-black"
    >
      <div className="flex flex-row justify-between gap-4">
        <div className="flex flex-row gap-4">
          <img
            alt="pic"
            src={item.image}
            height={50}
            width={50}
            className="h-[80px] min-h-[80px] w-[80px] min-w-[80px] rounded-lg object-cover object-center"
          />
          <div className="flex flex-col gap-1">
            <Title type="bloc">
              {item.firstName} {item.lastName}
            </Title>
            <Text className="text-sm">test</Text>
            <div className="flex flex-row flex-wrap gap-2">
              <div className="w-fit rounded-lg border border-blue-50 px-2 py-1 text-sm">
                test
              </div>
              <div className="w-fit rounded-lg border border-blue-50 px-2 py-1 text-sm">
                test
              </div>
              <div className="w-fit rounded-lg border border-blue-50 px-2 py-1 text-sm">
                test
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        {item.proposalId && (
          <div
            className="flex flex-1 flex-row gap-4"
            onClick={() => togglePopup("proposalsDetails")}
          >
            <Button type="fullLite" className="text-center text-sm">
              <Document size={18} /> Voir la proposition
            </Button>
          </div>
        )}
        <Button
          onClick={contactStudent}
          type={loading ? "disabled" : "fullLite"}
          className="flex-1 text-center text-sm"
        >
          <SmsEdit size={18} /> Contacter l'étudiant
        </Button>
      </div>
      {_ADMIN_MODE && (
        <div
          onClick={handleDelete}
          className="absolute right-4 top-4 cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50 hover:text-blueViolet-600"
        >
          <Trash size={18} />
        </div>
      )}
      {error && <Text className="text-red-500">{error}</Text>}
    </motion.div>
  );
};

export default StudentHorizontalCardMin;
