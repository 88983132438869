// Required
import { Link } from "react-router-dom";

// Components
import Button from "../UI/Button";
import Text from "../UI/Text";

export const NeedHelp = () => {
  return (
    <div className="flex w-full min-w-[251px] flex-col gap-8 rounded-lg border border-blueViolet-100 bg-blueViolet-50 p-4 shadow-sm">
      <div className="flex flex-row items-center gap-2">
        <img
          src="https://storagettv.blob.core.windows.net/static/Alexandre.png"
          className="size-12 rounded-lg"
        />
        <Text>
          Alexandre <br /> Hachet
        </Text>
      </div>
      <div className="flex flex-col gap-4">
        <Text className="text-wrap text-sm">
          Echangez en direct avec votre expert Juwa
        </Text>
        <Link to={"/company/expert"}>
          <Button type="full" className="w-full text-center text-sm">
            Contactez votre expert
          </Button>
        </Link>
      </div>
    </div>
  );
};

export const NeedHelpMobile = () => {
  return (
    <Link to={"/company/expert"}>
      <Button type="full" className="w-full text-center text-sm">
        Contactez votre expert
      </Button>
    </Link>
  );
};
