// Components
import Button from "../UI/Button";
import Text from "../UI/Text";

const GetBiz = () => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4">
      <div className="flex flex-row gap-2">
        <Text className="font-semibold">
          Se faire accompagner pour créer son entreprise
        </Text>
        <img
          src="https://storagettv.blob.core.windows.net/static/GetBiz.png"
          className="ml-auto h-auto w-[150px] object-contain"
          alt="GetBiz"
        />
      </div>
      <Text>
        Crée ton auto-entreprise en 10 minutes et reçois ton SIRET sous 48h.
      </Text>
      <div className="flex flex-row gap-4">
        <Button
          type="full"
          className="grow"
          onClick={() => {
            window.open("https://www.getbiz.fr/taftavie", "_blank");
          }}
        >
          Profiter de l'offre
        </Button>
      </div>
    </div>
  );
};

export default GetBiz;
