// Requires
import { useState } from "react";

// Components
import Text from "../../UI/Text";

// Icons
import { Trash } from "iconsax-react";

// Types
import { ProposalQuotation } from "../../../types/proposal";
import { ProposalErrorsType } from "../../../pages/Protected/Student/Jobs/Proposal/New/[id]/Proposal";

export const validateContent = (value: string): string => {
  if (!value.trim()) {
    return "Le contenu ne peut pas être vide.";
  }
  return "";
};

export const validatePrice = (value: number): string => {
  if (isNaN(value)) {
    return "Entrez un nombre valide supérieur ou égal à 15.";
  }
  if (value < 15) {
    return "Le prix doit être d'au moins 15.";
  }
  return "";
};

export const validateQuantity = (value: number): string => {
  if (isNaN(value)) {
    return "Entrez un nombre valide pour la quantité.";
  }
  if (value < 1) {
    return "La quantité doit être d'au moins 1.";
  }
  return "";
};

const Line = ({
  id,
  updateQuotation,
  remove,
  errors,
  setErrors
}: {
  id: number;
  updateQuotation: (quotation: ProposalQuotation, id: number) => void;
  remove: (id: number) => void;
  errors: ProposalErrorsType;
  setErrors: React.Dispatch<React.SetStateAction<ProposalErrorsType>>;
}) => {
  const [content, setContent] = useState<string>("");
  const [price, setPrice] = useState<number>(15);
  const [quantity, setQuantity] = useState<number>(1);

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContent(newContent);
    const contentError = validateContent(newContent);

    if (!contentError) {
      updateQuotation({ content: newContent, price, quantity }, id);
    }
    setErrors((prev) => ({ ...prev, [`content${id}`]: contentError }));
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedPrice = parseInt(value, 10);

    setPrice(parsedPrice);

    const priceError = validatePrice(parsedPrice);

    if (!priceError) {
      updateQuotation({ price: parsedPrice, quantity, content }, id);
    }
    setErrors((prev) => ({ ...prev, [`price${id}`]: priceError }));
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newQuantity = parseInt(value, 10);

    setQuantity(newQuantity);

    const quantityError = validateQuantity(newQuantity);
    if (!quantityError) {
      updateQuotation({ content, price, quantity: newQuantity }, id);
    }
    setErrors((prev) => ({ ...prev, [`quantity${id}`]: quantityError }));
  };

  return (
    <div className="flex flex-col gap-2 rounded-lg">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-between">
          <Text className="font-semibold">
            Ligne {id + 1} <span className="text-red-500"> *</span>
          </Text>
          {id + 1 !== 1 && (
            <Trash
              onClick={() => remove(id)}
              className="cursor-pointer hover:text-blueViolet-600"
              size={18}
            />
          )}
        </div>
        <textarea
          name="content"
          onChange={handleContentChange}
          className={`h-[150px] resize-none rounded-lg border p-2 focus:outline-blueViolet-600 ${
            errors[`content${id}`] ? "border-red-500" : "border-gray-300"
          }`}
          placeholder="Ajoute une brève description de cette étape"
          value={content}
        />
        {errors[`content${id}`] && (
          <p className="text-sm text-red-500">{errors[`content${id}`]}</p>
        )}
      </div>
      <div className="flex flex-row justify-between gap-4 >:flex-1">
        <div className="flex flex-col gap-2">
          <Text className="font-semibold">
            Prix unitaire (en €)<span className="text-red-500"> *</span>
          </Text>
          <input
            name="price"
            onChange={handlePriceChange}
            type="number"
            min={15}
            step={1}
            className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${
              errors[`price${id}`] ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Add a price"
            value={price}
            required
          />
          {errors[`price${id}`] && (
            <p className="text-sm text-red-500">{errors[`price${id}`]}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Text className="font-semibold">
            Quantité<span className="text-red-500"> *</span>
          </Text>
          <input
            name="quantity"
            onChange={handleQuantityChange}
            type="number"
            min={1}
            step={1}
            className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${
              errors[`quantity${id}`] ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Ajoute une quantité"
            value={quantity}
            required
          />
          {errors[`quantity${id}`] && (
            <p className="text-sm text-red-500">{errors[`quantity${id}`]}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Line;
