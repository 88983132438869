// Requires
import { useEffect, useState } from "react";

// Components
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Misc
import { _educationLevel } from "../../../../../../utils/misc";

// Icons

// Helpers
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

// Types
type ErrorType = {
  job?: string;
  education?: string;
};

const One = () => {
  const { step, handleNextStep } = useUI();

  const [job, setJob] = useState<string>("");
  const [education, setEducation] = useState<string>("");

  const [error, setError] = useState<ErrorType>({});

  // Validate data, save to localstorage and go to nextstep
  const nextStep = () => {
    const newError: ErrorType = {};

    if (job.length < 5) {
      newError.job =
        "Ton intitulé de poste doit comprendre entre 5 et 50 caractères";
    }

    if (!education || education === "") {
      newError.education = "Tu dois choisir un niveau d'études";
    }

    setError(newError);

    if (Object.keys(newError).length > 0) {
      return;
    }

    const localData = [
      { key: "job", value: job },
      { key: "education", value: education },
      { key: "studentStep", value: "1" }
    ];
    setLocalStorage(localData);

    handleNextStep(); // Go to next step
  };

  // Check if data already exist in localstorage
  useEffect(() => {
    const job = getLocalStorage("job");
    if (job) setJob(job);

    const education = getLocalStorage("education");
    if (education) setEducation(education);
  }, []);

  return (
    <div className="flex min-w-[50%] flex-col gap-10 md:max-w-[50%] md:gap-24">
      <div className="flex items-center justify-center">
        <Title type="page" classname="md:text-3xl text-2xl">
          Bienvenue dans la communauté !
        </Title>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-1">
          <Text className="font-semibold">
            Quel sera ton intitulé de profil ?
            <span style={{ color: "red" }}> * </span>
          </Text>
        </div>
        <Text className="text-sm">
          Résume tes domaines d’expertise/compétences utiles pour les missions
          que tu veux faire sur Juwa
        </Text>
        <input
          minLength={5}
          maxLength={50}
          onChange={(e) => setJob(e.currentTarget.value)}
          type="text"
          className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${
            error.job ? "border-red-500" : "border"
          }`}
          placeholder="Développeur(se) React, Designer(euse) Figma, Community Manager, Expert prospection B2B, …"
          value={job && job}
        />
        <div className="flex flex-row items-center justify-between">
          <Text className="text-red-500">{error.job}</Text>
          <div className="flex justify-end text-sm text-textGray">
            {job.length}/50 caractères
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Text className="font-semibold">
          Sélectionne ton niveau d'études
          <span style={{ color: "red" }}> * </span>
        </Text>
        <select
          className={`rounded-lg border p-2 focus:outline-blue-600 ${
            error.education ? "border-red-500" : "border"
          }`}
          onChange={(e) => setEducation(e.currentTarget.value)}
          value={education}
          name="education"
          id="education-select"
        >
          <option value="">Aucun niveau d'étude sélectionné</option>
          {_educationLevel.map((item, _i) => (
            <option className="p-2" key={_i} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
        {error.education && (
          <Text className="text-red-500">{error.education}</Text>
        )}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex-1 text-center">
          <Text>Étape {step + 1}/14</Text>
        </div>
        <div onClick={nextStep}>
          <Button type="full">Suivant</Button>
        </div>
      </div>
    </div>
  );
};

export default One;
