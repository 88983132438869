// Icons
import { Component, Location, Verify } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import Text from "../UI/Text";
import Title from "../UI/Title";

// Types
import type { companyType } from "../../types/company";

// Helpers
import { capitalizeWords } from "../../utils/helpers/capitalizeWords";

const Informations = ({ data }: { data: companyType }) => {
  return (
    <div className="flex h-fit w-full flex-col gap-6 rounded-lg border p-4 shadow-lg md:max-w-[350px]">
      <BlocTitle>
        <Verify size={18} />
        <Title type="bloc">Détails de l'entreprise</Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-row items-center gap-2">
          <Component size={18} /> <Text className="font-semibold">SIRET:</Text>{" "}
          <Text>{data.siret}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <Component size={18} /> <Text className="font-semibold">SIREN:</Text>{" "}
          <Text>{data.siren}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <Component size={18} /> <Text className="font-semibold">TVA:</Text>{" "}
          <Text>{data.vatNumber ? data.vatNumber : "Non indiqué"}</Text>
        </div>
        <div className="flex flex-row flex-wrap items-center gap-2">
          <Location size={18} />{" "}
          <Text className="text-nowrap font-semibold">Siège social:</Text>{" "}
          <Text>{capitalizeWords(data.address)}</Text>
        </div>
      </Content>
    </div>
  );
};

export default Informations;
