// Icons
import { Briefcase } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import Title from "../UI/Title";
import CompletedMission from "../Cards/Mission/CompletedMission";
import Text from "../UI/Text";

// Types
import { CompletedMissions } from "../../types/user";

const Mission = ({ data }: { data?: CompletedMissions[] }) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <Briefcase size={18} />
        <Title type="bloc">Missions réalisées</Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-row flex-wrap gap-8">
          {data && data.length > 0 ? (
            data.map((item, _id) => <CompletedMission key={_id} data={item} />)
          ) : (
            <Text>Aucune missions réalisées pour le moment</Text>
          )}
        </div>
      </Content>
    </div>
  );
};

export default Mission;
