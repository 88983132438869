// Icons
import { ImportSquare } from "iconsax-react";

// Requires
import { useState, useRef, useEffect } from "react";

// Components
import Button from "../Button";
import Text from "../Text";

type Error = {
  prompt?: string;
  file?: string;
};

const UploadFile = ({ callback }: { callback: (data: File) => void }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<Error>();

  const handleClick = () => {
    if (inputRef) {
      inputRef.current?.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.size > 2000000) {
      return alert("Maximum 2mo");
    }

    if (file && file.size < 2000000) {
      setFile(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files?.[0];
    if (droppedFile && droppedFile.size > 2000000) {
      return alert("Maximum 2mo");
    }

    if (droppedFile && droppedFile.size < 2000000) {
      setFile(droppedFile);
    }
  };

  useEffect(() => {
    if (file) {
      callback(file);
    }
  }, [file]);

  return (
    <div className="flex flex-col gap-2">
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        className={`flex h-28 w-full flex-row items-center gap-2 rounded-lg border p-4 ${isDragging && "border-blueViolet-600"}`}
      >
        <div className="flex size-16 items-center justify-center rounded-lg bg-blueViolet-50">
          <ImportSquare size={18} />
        </div>
        <Text>Glisser-déposer le PDF ou</Text>
        <div>
          <Button type="fullLite">
            {file ? file.name : "Parcourir l'ordinateur"}
          </Button>
        </div>
        <input
          onChange={handleFileChange}
          ref={inputRef}
          type="file"
          accept="application/pdf"
          className="hidden"
        />
      </div>
      <Text className="text-sm text-red-500">{error?.file && error.file}</Text>
    </div>
  );
};

export default UploadFile;
