// Requires
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

// Components
import NextPrevious from "../../../../../../components/NextPrevious";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Icons
import { Instagram, Twitch } from "iconsax-react";
import { LinkedinIcon } from "lucide-react";
import { setLocalStorage } from "../../../../../../utils/helpers/localStorage";

// import SocialMediaDropdown from "path-to-SocialMediaDropdown-component";

const Thirteen = () => {
  const { step, handlePrevStep } = useUI();
  const navigation = useNavigate();

  const [experience, setExperience] = useState("");
  const [discoveryMethod, setDiscoveryMethod] = useState("");
  const [missionType, setMissionType] = useState("");
  const [selectedSocialMedia, setSelectedSocialMedia] = useState("");
  const [reason, setReason] = useState("");

  const experienceOptions = [
    "Je débute",
    "J'ai déjà fait des missions",
    "J'ai plusieurs clients réguliers"
  ];
  const discoveryOptions = [
    "Réseaux sociaux",
    "Bouche à oreille",
    "Recherche internet",
    "TV"
  ];

  // Handle form submission
  const handleSubmit = async () => {
    // // Map experience to profil
    // let profil = "";
    // switch (experience) {
    //   case "Je débute":
    //     profil = "beginner";
    //     break;
    //   case "J'ai déjà fait des missions":
    //     profil = "intermediate";
    //     break;
    //   case "J'ai plusieurs clients réguliers":
    //     profil = "advanced";
    //     break;
    //   default:
    //     profil = "beginner";
    // }

    // // Map discoveryMethod to origin
    // let origin = "";
    // if (discoveryMethod === "Réseaux sociaux") {
    //   origin = selectedSocialMedia.toLowerCase();
    // } else {
    //   switch (discoveryMethod) {
    //     case "Bouche à oreille":
    //       origin = "word_of_mouth";
    //       break;
    //     case "Recherche internet":
    //       origin = "internet_search";
    //       break;
    //     case "TV":
    //       origin = "tv";
    //       break;
    //     default:
    //       origin = "other";
    //   }
    // }

    // // Map missionType to jobType.type
    // let jobTypeType = "";
    // if (missionType === "Ponctuelles") {
    //   jobTypeType = "punctual";
    // } else if (missionType === "Récurrentes") {
    //   jobTypeType = "recurrent";
    // } else {
    //   jobTypeType = "unspecified";
    // }

    // // Create the data object
    // const data = {
    //   profil: profil,
    //   origin: origin,
    //   jobType: {
    //     type: jobTypeType,
    //     reason: reason
    //   }
    // };

    // // Send data to API
    // try {
    //   const response = await fetchApi("/student/preferences", "POST", data);

    //   if (response.ok) {
    //     // Handle success
    //     console.log("Data sent successfully");
    //     // Update local storage and navigate
    //     const localData = [{ key: "studentStep", value: "1" }];
    //     setLocalStorage(localData);

    //     navigation("/Auth/Login");
    //   } else {
    //     // console.error("Server error:", response.statusText);
    //   }
    // } catch (error) {
    //   // console.error("Network error:", error);
    // }

    navigation("/auth/login");
  };

  return (
    <div className="w-full overflow-scroll p-4 md:px-16">
      <div className="flex items-center justify-center">
        <div className="flex min-w-[50%] flex-col gap-24 md:max-w-[50%]">
          <div className="flex flex-col">
            <Title type="page">
              Dernière étape avant de te lancer sur Juwa
            </Title>
            <Text className="text-sm italic text-gray-600">
              Another day another slay!
            </Text>
          </div>
          <div className="flex flex-col gap-8">
            {/* Experience Section */}
            <div className="flex flex-col gap-2">
              <Text>Parle nous de ton expérience en tant que freelance</Text>
              <div className="flex flex-col gap-4 p-3 md:flex-row md:items-center md:p-0">
                {experienceOptions.map((option) => (
                  <div
                    key={option}
                    className={`flex transform cursor-pointer items-center justify-center rounded-lg border-2 p-2 text-center align-middle transition-all duration-300 ease-in-out ${
                      experience === option
                        ? "scale-105 border-blueViolet-500 bg-blueViolet-200 shadow-lg"
                        : "bg-blueViolet-50 hover:scale-105 hover:shadow-md"
                    }`}
                    onClick={() => setExperience(option)}
                  >
                    <Text>{option}</Text>
                  </div>
                ))}
              </div>
            </div>

            {/* Discovery Method Section */}
            <div className="flex flex-col gap-2">
              <Text className="mb-4">Comment as-tu connu Juwa ?</Text>
              {discoveryOptions.map((option) => (
                <div key={option} className="flex flex-col gap-2">
                  <div className="flex flex-row items-center gap-2">
                    <input
                      type="radio"
                      className="h-4 w-4 cursor-pointer"
                      checked={discoveryMethod === option}
                      onChange={() => setDiscoveryMethod(option)}
                    />
                    <label>{option}</label>
                  </div>
                  {/* Social Media Dropdown */}
                  {discoveryMethod === "Réseaux sociaux" &&
                    option === "Réseaux sociaux" && (
                      <SocialMediaDropdown
                        setSelectedSocialMedia={setSelectedSocialMedia}
                      />
                    )}
                </div>
              ))}
            </div>

            {/* Mission Type Section */}
            <div className="flex flex-col gap-2">
              <Text>
                Sur Juwa, tu souhaites faire de préférence des missions :
              </Text>
              <div className="flex flex-row items-center gap-2">
                <input
                  type="radio"
                  className="h-4 w-4 cursor-pointer"
                  checked={missionType === "Ponctuelles"}
                  onChange={() => setMissionType("Ponctuelles")}
                />
                <label>Ponctuelles</label>
              </div>
              <div className="flex flex-row items-center gap-2">
                <input
                  type="radio"
                  className="h-4 w-4 cursor-pointer"
                  checked={missionType === "Récurrentes"}
                  onChange={() => setMissionType("Récurrentes")}
                />
                <label>Récurrentes</label>
              </div>
              <div className="flex flex-col gap-2">
                <label>Raison ?</label>
                <input
                  type="text"
                  className="rounded-lg border p-2"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  maxLength={100}
                />
              </div>
            </div>
          </div>
          <NextPrevious
            step={step}
            nextStep={handleSubmit}
            prevStep={() => {
              setLocalStorage([
                { key: "studentStep", value: (step - 1).toString() }
              ]);
              handlePrevStep();
            }}
            isLoading={false}
            nameNext="Se lancer"
          />
        </div>
      </div>
    </div>
  );
};

export default Thirteen;

interface SocialMediaDropdownProps {
  setSelectedSocialMedia: React.Dispatch<React.SetStateAction<string>>;
}

const SocialMediaDropdown = ({
  setSelectedSocialMedia
}: SocialMediaDropdownProps) => {
  const socialMediaOptions = [
    {
      value: "LinkedIn",
      label: "LinkedIn",
      icon: <LinkedinIcon size="20" color="#0077b5" />
    },
    {
      value: "Instagram",
      label: "Instagram",
      icon: <Instagram size="20" color="#e4405f" />
    },
    {
      value: "Twitch",
      label: "Twitch",
      icon: <Twitch size="20" color="#9146ff" />
    },
    {
      value: "TikTok",
      label: "TikTok",
      icon: (
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M16.8217 5.1344C16.0886 4.29394 15.6479 3.19805 15.6479 2H14.7293M16.8217 5.1344C17.4898 5.90063 18.3944 6.45788 19.4245 6.67608C19.7446 6.74574 20.0786 6.78293 20.4266 6.78293V10.2191C18.645 10.2191 16.9932 9.64801 15.6477 8.68211V15.6707C15.6477 19.1627 12.8082 22 9.32386 22C7.50043 22 5.85334 21.2198 4.69806 19.98C3.64486 18.847 2.99994 17.3331 2.99994 15.6707C2.99994 12.2298 5.75592 9.42509 9.17073 9.35079M16.8217 5.1344C16.8039 5.12276 16.7861 5.11101 16.7684 5.09914M6.9855 17.3517C6.64217 16.8781 6.43802 16.2977 6.43802 15.6661C6.43802 14.0734 7.73249 12.7778 9.32394 12.7778C9.62087 12.7778 9.9085 12.8288 10.1776 12.9124V9.40192C9.89921 9.36473 9.61622 9.34149 9.32394 9.34149C9.27287 9.34149 8.86177 9.36884 8.81073 9.36884M14.7244 2H12.2097L12.2051 15.7775C12.1494 17.3192 10.8781 18.5591 9.32386 18.5591C8.35878 18.5591 7.50971 18.0808 6.98079 17.3564"
              stroke="#000000"
              strokeLinejoin="round"
            ></path>{" "}
          </g>
        </svg>
      )
    }
  ];
  return (
    <div className="ml-6 mt-2 w-full">
      <Select
        onChange={(e) => setSelectedSocialMedia(e?.label || "")}
        options={socialMediaOptions}
        getOptionLabel={(props) => {
          // eslint-disable-next-line react/prop-types
          const { icon, label }: { icon: React.ReactNode; label: string } =
            props;
          return (
            <div className="flex items-center gap-2">
              {icon}
              <span>{label}</span>
            </div>
          ) as unknown as string;
        }}
        styles={{
          option: (styles, { isFocused }) => ({
            ...styles,
            cursor: "pointer",
            backgroundColor: isFocused ? "#f0f0f0" : "white",
            color: isFocused ? "black" : "black",
            transition: "background-color 0.3s ease-in-out"
          }),
          control: (styles) => ({
            ...styles,
            cursor: "pointer",
            transition: "border-color 0.3s ease-in-out",
            ":hover": {
              borderColor: "#0077b5"
            }
          })
        }}
        placeholder="Sélectionnez un réseau social"
        className="w-full"
      />
    </div>
  );
};
