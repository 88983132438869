// Icons
import { TableDocument } from "iconsax-react";

// Requires
import { useState } from "react";

// Components
import Content from "../../Bloc/Content";
import { BlocTitle } from "../../Bloc/Title";
import Text from "../../UI/Text";
import Title from "../../UI/Title";
import AddNewLine from "./AddNewLine";
import Line from "./Line";

// Types
import { ProposalQuotation } from "../../../types/proposal";
import { ProposalErrorsType } from "../../../pages/Protected/Student/Jobs/Proposal/New/[id]/Proposal";

const Estimate = ({
  updateQuotation,
  removeQuotation,
  errors,
  setErrors
}: {
  updateQuotation: (quotation: ProposalQuotation, id: number) => void;
  removeQuotation: (id: number) => void;
  errors: ProposalErrorsType;
  setErrors: React.Dispatch<React.SetStateAction<ProposalErrorsType>>;
}) => {
  const [lines, setLines] = useState<number[]>([0]);

  const handleIncrementLine = () => {
    setLines((prev) => [...prev, prev.length]);
    updateQuotation({ content: "", price: 15, quantity: 1 }, lines.length);
  };

  const handleDeleteLine = (id: number) => {
    setLines((prev) => prev.filter((lineId) => lineId !== id));
    removeQuotation(id);
  };

  return (
    <div className="flex flex-col gap-2 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <TableDocument size={18} />
        <Title type="bloc">Ton devis</Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-col gap-6">
          <Text className="text=sm">
            Ajoute autant de lignes qu'il y a d'étapes nécessaires à la
            réalisation de la mission: audit initial, création d'un élément,
            ajout d'une fonctionnalité...
          </Text>
          {lines.map((lineId) => (
            <Line
              errors={errors}
              setErrors={setErrors}
              key={lineId}
              id={lineId}
              updateQuotation={updateQuotation}
              remove={handleDeleteLine}
            />
          ))}
          <AddNewLine increment={handleIncrementLine} />
        </div>
      </Content>
    </div>
  );
};

export default Estimate;
