// Components
import { BlocTitle } from "../../Bloc/Title";
import FileCard from "../../Chat/FileCard";
import Title from "../../UI/Title";

const Document = ({ data }: { data?: { fileName: string; url: string } }) => {
  const formatedData = {
    fileName: data?.fileName + ".png",
    url: data?.url ?? "",
    fileType: "png"
  };

  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4">
      <BlocTitle>
        <Title type="bloc">Document joint</Title>
      </BlocTitle>
      {data && formatedData ? (
        <div className="flex flex-row flex-wrap gap-4">
          <FileCard file={formatedData} />
        </div>
      ) : (
        <p>Aucun document</p>
      )}
    </div>
  );
};

export default Document;
