// Icons
import { Location, Map, Star1, Routing } from "iconsax-react";

// Requires
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

// Services
import { getUser } from "../../services/Public/user";

// Components
import Layout from "./Layout";
import Disponibility from "../../components/Profil/Disponibility";
import Title from "../../components/UI/Title";
import Text from "../../components/UI/Text";
import Presentation from "../../components/Profil/Presentation";
import Certification from "../../components/Profil/Certification";
import Experience from "../../components/Profil/Experience";
import Formation from "../../components/Profil/Formation";
import Mission from "../../components/Profil/Mission";
import Recommendation from "../../components/Profil/Recommendation";
import Skill from "../../components/Profil/Skill";
import Tag from "../../components/UI/Tag";

// Types
import { User } from "../../types/user";

// Helpers
import { getDegreeName } from "../../utils/helpers/getDegreeName";

const Profil = () => {
  const { slug } = useParams();

  const [data, setData] = useState<User>();

  useEffect(() => {
    if (slug) {
      const fetch = async () => {
        const response = await getUser(slug);
        setData(response);
      };
      fetch();
    }
  }, [slug]);

  return (
    <Layout>
      <div className="flex items-center justify-center pb-5 md:py-5">
        <div className="flex flex-col gap-8 max-md:w-full max-md:px-4 md:max-w-[1280px]">
          <div
            className="relative h-[250px] rounded-t-lg bg-cover bg-center"
            style={{ backgroundImage: `url('${data?.banner}')` }}
          ></div>
          <div className="z-40 mt-[-80px] flex flex-col gap-2 px-6">
            <img
              src={data?.image}
              alt=""
              className="h-[80px] w-[80px] rounded-lg border-4 border-white object-cover object-center"
            />
            <div className="flex flex-row items-center justify-between gap-4">
              <div className="flex flex-row items-center gap-4">
                <Title type="custom" classname="text-xl">
                  {data?.firstName} {data?.lastName}
                </Title>
                <div className="flex flex-row items-center gap-2">
                  <Text className="text-sm">{data?.publicMark}</Text>
                  <Star1 size={18} />
                </div>
                <div className="flex flex-row items-center gap-2">
                  {data?.languages.map((item) => (
                    <Tag key={item.id} className="text-blueViolet-600">
                      {item.title}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-8 max-md:flex-wrap max-md:gap-2 max-md:gap-x-8">
              <div className="max-md:w-full max-md:font-bold">
                <Text>{data?.profileTitle}</Text>
              </div>
              <div className="flex flex-row items-center gap-2">
                <Routing size={18} />
                <Text className="text-sm">
                  {getDegreeName(data?.actualDegree)}
                </Text>
              </div>
              <div className="flex flex-row items-center gap-2">
                <Location size={18} />
                <Text className="text-sm">{data?.city}</Text>
              </div>
              <div className="flex flex-row items-center gap-2">
                <Map size={18} />
                <Text className="text-sm">
                  Préfére travailler{" "}
                  {data?.workMode === "onSite"
                    ? "sur site"
                    : data?.workMode === "remote"
                      ? "en télétravail"
                      : "en mode hybride"}
                </Text>
              </div>
            </div>
          </div>
          <div className="flex flex-row-reverse gap-8 max-md:hidden">
            <div className="flex grow flex-col gap-8">
              <Mission data={data?.completedMissions ?? []} />
              <Experience data={data?.experiences} />
              <Formation data={data?.education} />
            </div>
            <div className="flex min-w-[350px] max-w-[500px] flex-col gap-8">
              <Presentation data={data?.presentation} />
              <Skill data={data?.skills} />
              <Disponibility
                publicMode={true}
                data={data?.weeklyAvailability}
              />
              <Certification data={data?.certifications} />
              <Recommendation data={data?.recommendations} />
            </div>
          </div>

          <div className="flex flex-col gap-4 md:hidden">
            <Presentation data={data?.presentation} />
            <Mission data={data?.completedMissions ?? []} />
            <Experience data={data?.experiences} />
            <Formation data={data?.education} />
            <Skill data={data?.skills} />
            <Disponibility publicMode={true} data={data?.weeklyAvailability} />
            <Certification data={data?.certifications} />
            <Recommendation data={data?.recommendations} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profil;
