// Types
type data = {
  key: string;
  value: string;
};

// Set a new key(s)/value(s)
export const setLocalStorage = (data: data[]) => {
  for (const item of data) {
    localStorage.setItem(item.key, item.value);
  }

  return true;
};

// Get a specific key/value
export const getLocalStorage = (data: string) => {
  const item = localStorage.getItem(data);
  return item;
};

// Delete a specific key
export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
