// Components
import Blank from "../../Ads/Blank";
import JobsClosedCounter from "./Cards/JobsClosedCounter";
import ProfilComplete from "./Blocs/ProfilComplete";
import CA12Month from "./Blocs/CA12Month";
import CAInProgress from "./Blocs/CAInProgress";
import CAEvolution from "./Charts/CAEvolution";
import Pipe from "./Pipe/Pipe";

const StudentDashboard = () => {
  return (
    <div className="flex h-fit gap-6 max-md:flex-wrap max-md:>:w-full">
      <div className="flex flex-col gap-8 md:w-[350px] md:min-w-[350px] md:max-w-[350px]">
        <ProfilComplete />
        <Blank />
        <CA12Month />
        <CAInProgress />
        <JobsClosedCounter />
      </div>
      <div className="flex w-[calc(100%-374px)] flex-col gap-8">
        <CAEvolution />
        <Pipe />
      </div>
    </div>
  );
};

export default StudentDashboard;
