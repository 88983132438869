import { memo, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowUp2 } from "iconsax-react";
import Text from "../UI/Text";
import { FilterType } from "./FindJobsNewFilters";

// eslint-disable-next-line react/display-name
const DropdownItem = memo(
  ({
    filter,
    index,
    isOpen,
    handleDropdownClick,
    setFilter,
    value
  }: {
    filter: FilterType;
    index: number;
    isOpen: boolean;
    handleDropdownClick: (index: number) => void;
    setFilter: (
      name: string,
      value: string,
      field?: "min" | "max",
      list?: boolean
    ) => void;
    value: {
      min: string;
      max: string;
      value: string;
    };
  }) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [minValue, setMinValue] = useState<string>("");
    const [maxValue, setMaxValue] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");

    const containerRef = useRef<HTMLDivElement>(null);

    // Update local state when the value prop changes
    useEffect(() => {
      if (filter.type === "multiselect") {
        setSelectedValues(
          value.value ? value.value.slice(1, -1).split(",") : []
        );
      } else if (filter.type === "range") {
        setMinValue(value.min || "");
        setMaxValue(value.max || "");
      } else {
        setInputValue(value.value || "");
      }
      // Reset search term when dropdown is closed
      if (!isOpen) {
        setSearchTerm("");
      }
    }, [value, filter.type, isOpen]);

    const handleSelectChange = (optionValue: string) => {
      setInputValue(optionValue);
      setFilter(filter.name, optionValue);
      handleDropdownClick(index); // Close dropdown after selection
    };

    const handleMultiSelectChange = (optionValue: string) => {
      let newValues = [...selectedValues];
      if (newValues.includes(optionValue)) {
        newValues = newValues.filter((v) => v !== optionValue);
      } else {
        newValues.push(optionValue);
      }
      setSelectedValues(newValues);
      setFilter(filter.name, newValues.join(","), undefined, true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      setFilter(filter.name, e.target.value);
    };

    const handleMinValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newMinValue = e.target.value;
      setMinValue(newMinValue);
      setFilter(filter.name, newMinValue, "min");
    };

    const handleMaxValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newMaxValue = e.target.value;
      setMaxValue(newMaxValue);
      setFilter(filter.name, newMaxValue, "max");
    };

    // Handle click outside to close the dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        handleDropdownClick(index);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen]);

    // Map selected values to labels
    const getSelectedLabels = () => {
      if (filter.type === "select") {
        const idx = filter.options?.indexOf(inputValue);
        if (idx !== undefined && idx !== -1 && filter.optionsLabels) {
          return filter.optionsLabels[idx];
        }
        return inputValue;
      } else if (filter.type === "multiselect") {
        if (
          selectedValues.length > 0 &&
          filter.options &&
          filter.optionsLabels
        ) {
          return selectedValues
            .map((val) => {
              const idx = filter.options?.indexOf(val);
              return filter.optionsLabels![idx!];
            })
            .join(", ");
        }
        return selectedValues.join(", ");
      } else if (filter.type === "range") {
        if (minValue || maxValue) {
          return `${minValue || "..."} - ${maxValue || "..."}`;
        }
        return "";
      } else {
        return inputValue;
      }
    };

    const selectedLabel = getSelectedLabels();

    // Filter options based on search term
    const filteredOptions = () => {
      if (filter.type === "select" || filter.type === "multiselect") {
        if (filter.options && filter.optionsLabels) {
          return filter.options
            .map((option, idx) => ({
              value: option,
              label: filter.optionsLabels![idx]
            }))
            .filter(
              (option) =>
                option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                option.value.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else if (filter.options) {
          return filter.options
            .filter((option) =>
              option.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((option) => ({ value: option, label: option }));
        }
      }
      return [];
    };

    const optionsToDisplay = filteredOptions();

    return (
      <div ref={containerRef} className="relative">
        {/* Dropdown Title */}
        <div
          onClick={() => handleDropdownClick(index)}
          className="flex cursor-pointer flex-row items-center gap-2 px-4 py-2 hover:bg-blueViolet-50"
        >
          <Text>{filter.label}</Text>
          <motion.div animate={{ rotate: isOpen ? 180 : 0 }}>
            <ArrowUp2 size={18} />
          </motion.div>
        </div>
        {/* Display the selected value under the label */}
        {selectedLabel && (
          <Text className="px-4 text-sm text-gray-600">{selectedLabel}</Text>
        )}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key={filter.name}
              initial={{ y: 15, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 15, opacity: 0 }}
              className="absolute left-0 z-40 mt-2 min-w-full rounded-lg border bg-white p-2"
              style={{ top: "100%" }}
            >
              {(filter.type === "select" || filter.type === "multiselect") && (
                <div className="flex flex-col gap-2">
                  {filter.withSearch && (
                    <input
                      type="text"
                      placeholder={`${filter.label}`}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="mb-2 w-full rounded-lg border p-2"
                    />
                  )}
                  {/* Add a wrapper div for the options list with max height and scroll */}
                  <div className="max-h-60 overflow-y-auto">
                    {optionsToDisplay.length > 0 ? (
                      optionsToDisplay.map((option) => (
                        <div
                          key={option.value}
                          className="flex cursor-pointer items-center gap-2 p-2 hover:bg-gray-100"
                          onClick={() => {
                            if (filter.type === "select") {
                              handleSelectChange(option.value);
                            } else {
                              handleMultiSelectChange(option.value);
                            }
                          }}
                        >
                          {filter.type === "multiselect" && (
                            <input
                              className="h-4 w-4 cursor-pointer"
                              type="checkbox"
                              checked={selectedValues.includes(option.value)}
                              onChange={() =>
                                handleMultiSelectChange(option.value)
                              }
                            />
                          )}
                          <span>{option.label}</span>
                        </div>
                      ))
                    ) : (
                      <div className="p-2 text-sm text-gray-500">
                        Aucun résultat
                      </div>
                    )}
                  </div>
                </div>
              )}

              {(filter.type === "number" ||
                filter.type === "date" ||
                filter.type === "text") && (
                <div className="p-2">
                  <input
                    type={filter.type}
                    value={inputValue}
                    onChange={handleInputChange}
                    className="w-full rounded-lg border p-2"
                  />
                </div>
              )}
              {filter.type === "range" && (
                <div className="p-2">
                  <div className="flex gap-2">
                    <input
                      type="number"
                      placeholder="Min"
                      value={minValue}
                      min={0}
                      onChange={handleMinValueChange}
                      className="w-full rounded-lg border p-2"
                    />
                    <input
                      type="number"
                      placeholder="Max"
                      min={0}
                      value={maxValue}
                      onChange={handleMaxValueChange}
                      className="w-full rounded-lg border p-2"
                    />
                  </div>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export default DropdownItem;
