// Requires
import { motion } from "framer-motion";

// Icons
import { Folder } from "iconsax-react";

// Context
import { useChat } from "../../context/Chat/ChatContext";

// Components
import Content from "../Bloc/Content";
import { BlocTitleWithButton } from "../Bloc/Title";
import Button from "../UI/Button";
import { HorizontalDivider } from "../UI/Divider";
import Text from "../UI/Text";
import Title from "../UI/Title";
import { Doc } from "./Doc";
import { ChatProvider } from "../../context/Chat/ChatProvider";

export const LastDocumentsAnimated = ({
  maxHeight,
  minWidth,
  button
}: {
  maxHeight?: string;
  minWidth?: string;
  button?: boolean;
}) => {
  const { documents, newDocs, setNewDocs } = useChat();
  return (
    <motion.div
      initial={{ width: "0px", minWidth: "0px" }}
      animate={{ width: "400px", minWidth: "400px" }}
      exit={{ width: "0px", minWidth: "0px", opacity: 0 }}
      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
      className={
        "flex h-fit max-h-[600px] flex-col gap-6 overflow-hidden rounded-lg border p-4 shadow-lg"
      }
    >
      <BlocTitleWithButton>
        <div className="flex flex-row items-center gap-4">
          <Folder size={18} />
          <Title type="bloc">Document échangés</Title>
        </div>
        {button && <Button type="outline">Tout voir</Button>}
      </BlocTitleWithButton>
      {documents && documents.length > 0 && (
        <div className="overflow-y-scroll">
          <Content>
            {documents.map((item) => (
              <motion.div
                key={item.id}
                initial={{
                  opacity: newDocs.includes(item) ? 0 : 1,
                  y: newDocs.includes(item) ? 5 : 0
                }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                onAnimationComplete={() => setNewDocs([])}
                className="flex flex-col gap-2"
              >
                <Doc item={item} />
                <HorizontalDivider />
              </motion.div>
            ))}
          </Content>
        </div>
      )}
      {documents && documents.length < 1 && (
        <Text>Vous n'avez pas de documents récents</Text>
      )}
    </motion.div>
  );
};

export const LastDocumentsStatic = ({
  className,
  attachmentsType
}: {
  className?: string;
  attachmentsType: "conversationId" | "all";
}) => {
  return (
    <>
      <ChatProvider>
        <LastDocumentsStaticComponent
          className={className}
          attachmentsType={attachmentsType}
        />
      </ChatProvider>
    </>
  );
};

const LastDocumentsStaticComponent = ({
  className,
  attachmentsType
}: {
  className?: string;
  attachmentsType: "conversationId" | "all";
}) => {
  const { documents: data } = useChat();

  return (
    <div
      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
      className={`flex h-fit max-h-[500px] flex-col gap-6 overflow-hidden rounded-lg border p-4 shadow-lg ${className}`}
    >
      <BlocTitleWithButton>
        <div className="flex flex-row items-center gap-2">
          <Folder size={18} />
          <Title type="bloc">Documents récents</Title>
        </div>
      </BlocTitleWithButton>
      {data && data.length > 0 && (
        <div className="overflow-y-scroll">
          <Content>
            {data.map((item, _index) => (
              <div key={item.id} className="flex flex-col gap-2">
                <Doc item={item} />
                {_index < data.length - 1 && <HorizontalDivider />}
              </div>
            ))}
          </Content>
        </div>
      )}
      {!data || (data && data.length < 1 && <Text>Aucun document récent</Text>)}
    </div>
  );
};
