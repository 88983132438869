// Requires
import { AnimatePresence, motion } from "framer-motion";
import { SetStateAction, useState, useEffect } from "react";

// Components
import Button from "../../components/UI/Button";
import Text from "../../components/UI/Text";

// Helpers
import { fetchApi } from "../../services/Misc/api";

const CitySearchComponent = ({
  setCityData,
  error,
  setError,
  callback
}: {
  setCityData: (value: SetStateAction<any>) => void;
  error?: string;
  setError?: (value: string) => void;
  callback?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [selectedCity, setSelectedCity] = useState<any>();
  const [query, setQuery] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [results, setResults] = useState<
    { id: number; label: string; zip_code: string }[] | null
  >(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (selectedCity) {
      setCityData(selectedCity);

      if (selectedCity && callback) {
        callback(selectedCity);
      }

      if (setError) setError("");
    }
  }, [selectedCity]);

  const handleCitySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setQuery(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (value.length < 3) {
      setResults(null);
      setIsSearching(false);
    }

    if (value.length >= 3) {
      setIsSearching(true);
      const newTimeoutId = setTimeout(async () => {
        const response = await fetchApi(`/misc/city?search=${value}`, "GET");
        if (response) {
          setResults(response);
          setIsSearching(false);
        } else {
          setResults(null);
          setIsSearching(false);
        }
      }, 2000);

      setTimeoutId(newTimeoutId);
    }
  };
  return (
    <>
      <div className="flex flex-row gap-2 py-2">
        <input
          value={query}
          onChange={handleCitySearch}
          type="text"
          className={`grow rounded-lg border p-2 focus:outline-blueViolet-600 max-md:w-full max-md:shrink ${error && "border-red-500"}`}
          placeholder={
            selectedCity
              ? selectedCity.label.toUpperCase()
              : "Entrer le nom de la ville"
          }
          disabled={selectedCity ? true : false}
        />
        {selectedCity && (
          <div onClick={() => setSelectedCity(null)}>
            <Button type="full">Modifier</Button>
          </div>
        )}
      </div>
      <AnimatePresence mode="sync">
        {query && (
          <motion.div
            key={"animate"}
            initial={{ y: 5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 5, opacity: 0 }}
            className="max-h-[200px] overflow-scroll rounded-lg border p-2 shadow-lg"
          >
            {isSearching ? (
              <div className="loader" />
            ) : results && results?.length > 0 ? (
              results?.map((item, _i) => (
                <div
                  onClick={() => {
                    setQuery("");
                    setSelectedCity(item);
                  }}
                  key={item.id}
                  className="flex flex-col gap-1 rounded-lg p-2 hover:bg-blueViolet-600 hover:text-white"
                >
                  <Text className="font-semibold">
                    {item.label.toUpperCase()}
                  </Text>
                  <Text className="text-sm">{item.zip_code}</Text>
                </div>
              ))
            ) : (
              <p>Aucun resultat</p>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CitySearchComponent;
