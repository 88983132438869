const Text = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <p className={`m-0 text-base text-blueViolet-950 ${className}`}>
      {children}
    </p>
  );
};

export default Text;
