// Icons
import { Receipt21 } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitleWithButton } from "../Bloc/Title";
import Title from "../UI/Title";
import Transaction from "./Transaction";

// Type
import type { FileType } from "../../types/file";
import Text from "../UI/Text";

export const LastTransactions = ({ className }: { className?: string }) => {
  const data: FileType[] = [
    // {
    // 	id: 0,
    // 	name: "Commission mission #89708H",
    // 	date: "01-02-2024",
    // 	price: "138,24£",
    // },
    // {
    // 	id: 1,
    // 	name: "Commission mission #89708H",
    // 	date: "05-07-2022>",
    // 	price: "138,24£",
    // },
    // {
    // 	id: 2,
    // 	name: "Commission mission #89708H",
    // 	date: "18-01-23",
    // 	price: "138,24£",
    // },
    // {
    // 	id: 3,
    // 	name: "Commission mission #89708H",
    // 	date: "27-06-23",
    // 	price: "138,24£",
    // },
    // {
    // 	id: 4,
    // 	name: "Commission mission #89708H",
    // 	date: "15-04-22",
    // 	price: "138,24£",
    // },
    // {
    // 	id: 5,
    // 	name: "Commission mission #89708H",
    // 	date: "19-12-24",
    // 	price: "138,24£",
    // },
  ];

  return (
    <div
      className={`flex h-fit max-h-[350px] flex-col gap-6 overflow-hidden whitespace-nowrap rounded-lg border p-4 shadow-lg ${className}`}
    >
      <BlocTitleWithButton>
        <div className="flex flex-row items-center gap-2">
          <Receipt21 size={18} />
          <Title type="bloc">Dernières transactions</Title>
        </div>
      </BlocTitleWithButton>
      {data.length > 0 && (
        <div className="overflow-y-scroll">
          <Content>
            {data.map((item) => (
              <Transaction key={item.id} item={item} />
            ))}
          </Content>
        </div>
      )}
      {data.length < 1 && <Text>Vous n'avez pas de transactions récentes</Text>}
    </div>
  );
};

export default LastTransactions;
