type EducationLevelType = {
  id: number;
  value: string;
  name: string;
};

export const _educationLevel: EducationLevelType[] = [
  { id: 1, value: "highSchool", name: "Lycée" },
  { id: 2, value: "bachelor1", name: "Bac" },
  { id: 3, value: "bachelor2", name: "Bac +2" },
  { id: 4, value: "bachelor3", name: "Bac +3" },
  { id: 5, value: "bachelor4", name: "Bac +4" },
  { id: 6, value: "bachelor5", name: "Bac +5 | Master" },
  { id: 7, value: "bachelor6", name: "Bac +6" },
  { id: 8, value: "bachelor7", name: "Bac +7" },
  { id: 9, value: "bachelor8", name: "Bac +8 | Doctorat" }
];

type phoneNumberIndicatifType = {
  country: string;
  code: string;
  abbreviation: string;
};

export const phoneNumberIndicatif: phoneNumberIndicatifType[] = [
  { country: "France", code: "+33", abbreviation: "FR" },
  { country: "Afghanistan", code: "+93", abbreviation: "AF" },
  { country: "Albania", code: "+355", abbreviation: "AL" },
  { country: "Algeria", code: "+213", abbreviation: "DZ" },
  { country: "Andorra", code: "+376", abbreviation: "AD" },
  { country: "Angola", code: "+244", abbreviation: "AO" },
  { country: "Argentina", code: "+54", abbreviation: "AR" },
  { country: "Armenia", code: "+374", abbreviation: "AM" },
  { country: "Australia", code: "+61", abbreviation: "AU" },
  { country: "Austria", code: "+43", abbreviation: "AT" },
  { country: "Azerbaijan", code: "+994", abbreviation: "AZ" },
  { country: "Bahamas", code: "+1-242", abbreviation: "BS" },
  { country: "Bahrain", code: "+973", abbreviation: "BH" },
  { country: "Bangladesh", code: "+880", abbreviation: "BD" },
  { country: "Barbados", code: "+1-246", abbreviation: "BB" },
  { country: "Belarus", code: "+375", abbreviation: "BY" },
  { country: "Belgium", code: "+32", abbreviation: "BE" },
  { country: "Belize", code: "+501", abbreviation: "BZ" },
  { country: "Benin", code: "+229", abbreviation: "BJ" },
  { country: "Bhutan", code: "+975", abbreviation: "BT" },
  { country: "Bolivia", code: "+591", abbreviation: "BO" },
  { country: "Bosnia and Herzegovina", code: "+387", abbreviation: "BA" },
  { country: "Botswana", code: "+267", abbreviation: "BW" },
  { country: "Brazil", code: "+55", abbreviation: "BR" },
  { country: "Brunei", code: "+673", abbreviation: "BN" },
  { country: "Bulgaria", code: "+359", abbreviation: "BG" },
  { country: "Burkina Faso", code: "+226", abbreviation: "BF" },
  { country: "Burundi", code: "+257", abbreviation: "BI" },
  { country: "Cambodia", code: "+855", abbreviation: "KH" },
  { country: "Cameroon", code: "+237", abbreviation: "CM" },
  { country: "Canada", code: "+1", abbreviation: "CA" },
  { country: "Cape Verde", code: "+238", abbreviation: "CV" },
  { country: "Central African Republic", code: "+236", abbreviation: "CF" },
  { country: "Chad", code: "+235", abbreviation: "TD" },
  { country: "Chile", code: "+56", abbreviation: "CL" },
  { country: "China", code: "+86", abbreviation: "CN" },
  { country: "Colombia", code: "+57", abbreviation: "CO" },
  { country: "Comoros", code: "+269", abbreviation: "KM" },
  { country: "Congo (DRC)", code: "+243", abbreviation: "CD" },
  { country: "Congo (Republic)", code: "+242", abbreviation: "CG" },
  { country: "Costa Rica", code: "+506", abbreviation: "CR" },
  { country: "Croatia", code: "+385", abbreviation: "HR" },
  { country: "Cuba", code: "+53", abbreviation: "CU" },
  { country: "Cyprus", code: "+357", abbreviation: "CY" },
  { country: "Czech Republic", code: "+420", abbreviation: "CZ" },
  { country: "Denmark", code: "+45", abbreviation: "DK" },
  { country: "Djibouti", code: "+253", abbreviation: "DJ" },
  { country: "Dominica", code: "+1-767", abbreviation: "DM" },
  { country: "Dominican Republic", code: "+1-809", abbreviation: "DO" },
  { country: "Ecuador", code: "+593", abbreviation: "EC" },
  { country: "Egypt", code: "+20", abbreviation: "EG" },
  { country: "El Salvador", code: "+503", abbreviation: "SV" },
  { country: "Equatorial Guinea", code: "+240", abbreviation: "GQ" },
  { country: "Eritrea", code: "+291", abbreviation: "ER" },
  { country: "Estonia", code: "+372", abbreviation: "EE" },
  { country: "Eswatini", code: "+268", abbreviation: "SZ" },
  { country: "Ethiopia", code: "+251", abbreviation: "ET" },
  { country: "Fiji", code: "+679", abbreviation: "FJ" },
  { country: "Finland", code: "+358", abbreviation: "FI" },
  { country: "Gabon", code: "+241", abbreviation: "GA" },
  { country: "Gambia", code: "+220", abbreviation: "GM" },
  { country: "Georgia", code: "+995", abbreviation: "GE" },
  { country: "Germany", code: "+49", abbreviation: "DE" },
  { country: "Ghana", code: "+233", abbreviation: "GH" },
  { country: "Greece", code: "+30", abbreviation: "GR" },
  { country: "Grenada", code: "+1-473", abbreviation: "GD" },
  { country: "Guatemala", code: "+502", abbreviation: "GT" },
  { country: "Guinea", code: "+224", abbreviation: "GN" },
  { country: "Guinea-Bissau", code: "+245", abbreviation: "GW" },
  { country: "Guyana", code: "+592", abbreviation: "GY" },
  { country: "Haiti", code: "+509", abbreviation: "HT" },
  { country: "Honduras", code: "+504", abbreviation: "HN" },
  { country: "Hungary", code: "+36", abbreviation: "HU" },
  { country: "Iceland", code: "+354", abbreviation: "IS" },
  { country: "India", code: "+91", abbreviation: "IN" },
  { country: "Indonesia", code: "+62", abbreviation: "ID" },
  { country: "Iran", code: "+98", abbreviation: "IR" },
  { country: "Iraq", code: "+964", abbreviation: "IQ" },
  { country: "Ireland", code: "+353", abbreviation: "IE" },
  { country: "Israel", code: "+972", abbreviation: "IL" },
  { country: "Italy", code: "+39", abbreviation: "IT" },
  { country: "Ivory Coast", code: "+225", abbreviation: "CI" },
  { country: "Jamaica", code: "+1-876", abbreviation: "JM" },
  { country: "Japan", code: "+81", abbreviation: "JP" },
  { country: "Jordan", code: "+962", abbreviation: "JO" },
  { country: "Kazakhstan", code: "+7", abbreviation: "KZ" },
  { country: "Kenya", code: "+254", abbreviation: "KE" },
  { country: "Kiribati", code: "+686", abbreviation: "KI" },
  { country: "Kuwait", code: "+965", abbreviation: "KW" },
  { country: "Kyrgyzstan", code: "+996", abbreviation: "KG" },
  { country: "Laos", code: "+856", abbreviation: "LA" },
  { country: "Latvia", code: "+371", abbreviation: "LV" },
  { country: "Lebanon", code: "+961", abbreviation: "LB" },
  { country: "Lesotho", code: "+266", abbreviation: "LS" },
  { country: "Liberia", code: "+231", abbreviation: "LR" },
  { country: "Libya", code: "+218", abbreviation: "LY" },
  { country: "Liechtenstein", code: "+423", abbreviation: "LI" },
  { country: "Lithuania", code: "+370", abbreviation: "LT" },
  { country: "Luxembourg", code: "+352", abbreviation: "LU" },
  { country: "Madagascar", code: "+261", abbreviation: "MG" },
  { country: "Malawi", code: "+265", abbreviation: "MW" },
  { country: "Malaysia", code: "+60", abbreviation: "MY" },
  { country: "Maldives", code: "+960", abbreviation: "MV" },
  { country: "Mali", code: "+223", abbreviation: "ML" },
  { country: "Malta", code: "+356", abbreviation: "MT" },
  { country: "Marshall Islands", code: "+692", abbreviation: "MH" },
  { country: "Mauritania", code: "+222", abbreviation: "MR" },
  { country: "Mauritius", code: "+230", abbreviation: "MU" },
  { country: "Mexico", code: "+52", abbreviation: "MX" },
  { country: "Micronesia", code: "+691", abbreviation: "FM" },
  { country: "Moldova", code: "+373", abbreviation: "MD" },
  { country: "Monaco", code: "+377", abbreviation: "MC" },
  { country: "Mongolia", code: "+976", abbreviation: "MN" },
  { country: "Montenegro", code: "+382", abbreviation: "ME" },
  { country: "Morocco", code: "+212", abbreviation: "MA" },
  { country: "Mozambique", code: "+258", abbreviation: "MZ" },
  { country: "Myanmar", code: "+95", abbreviation: "MM" },
  { country: "Namibia", code: "+264", abbreviation: "NA" },
  { country: "Nauru", code: "+674", abbreviation: "NR" },
  { country: "Nepal", code: "+977", abbreviation: "NP" },
  { country: "Netherlands", code: "+31", abbreviation: "NL" },
  { country: "New Zealand", code: "+64", abbreviation: "NZ" },
  { country: "Nicaragua", code: "+505", abbreviation: "NI" },
  { country: "Niger", code: "+227", abbreviation: "NE" },
  { country: "Nigeria", code: "+234", abbreviation: "NG" },
  { country: "North Korea", code: "+850", abbreviation: "KP" },
  { country: "North Macedonia", code: "+389", abbreviation: "MK" },
  { country: "Norway", code: "+47", abbreviation: "NO" },
  { country: "Oman", code: "+968", abbreviation: "OM" },
  { country: "Pakistan", code: "+92", abbreviation: "PK" },
  { country: "Palau", code: "+680", abbreviation: "PW" },
  { country: "Panama", code: "+507", abbreviation: "PA" },
  { country: "Papua New Guinea", code: "+675", abbreviation: "PG" },
  { country: "Paraguay", code: "+595", abbreviation: "PY" },
  { country: "Peru", code: "+51", abbreviation: "PE" },
  { country: "Philippines", code: "+63", abbreviation: "PH" },
  { country: "Poland", code: "+48", abbreviation: "PL" },
  { country: "Portugal", code: "+351", abbreviation: "PT" },
  { country: "Qatar", code: "+974", abbreviation: "QA" },
  { country: "Romania", code: "+40", abbreviation: "RO" },
  { country: "Russia", code: "+7", abbreviation: "RU" },
  { country: "Rwanda", code: "+250", abbreviation: "RW" },
  { country: "Saint Kitts and Nevis", code: "+1-869", abbreviation: "KN" },
  { country: "Saint Lucia", code: "+1-758", abbreviation: "LC" },
  {
    country: "Saint Vincent and the Grenadines",
    code: "+1-784",
    abbreviation: "VC"
  },
  { country: "Samoa", code: "+685", abbreviation: "WS" },
  { country: "San Marino", code: "+378", abbreviation: "SM" },
  { country: "Sao Tome and Principe", code: "+239", abbreviation: "ST" },
  { country: "Saudi Arabia", code: "+966", abbreviation: "SA" },
  { country: "Senegal", code: "+221", abbreviation: "SN" },
  { country: "Serbia", code: "+381", abbreviation: "RS" },
  { country: "Seychelles", code: "+248", abbreviation: "SC" },
  { country: "Sierra Leone", code: "+232", abbreviation: "SL" },
  { country: "Singapore", code: "+65", abbreviation: "SG" },
  { country: "Slovakia", code: "+421", abbreviation: "SK" },
  { country: "Slovenia", code: "+386", abbreviation: "SI" },
  { country: "Solomon Islands", code: "+677", abbreviation: "SB" },
  { country: "Somalia", code: "+252", abbreviation: "SO" },
  { country: "South Africa", code: "+27", abbreviation: "ZA" },
  { country: "South Korea", code: "+82", abbreviation: "KR" },
  { country: "South Sudan", code: "+211", abbreviation: "SS" },
  { country: "Spain", code: "+34", abbreviation: "ES" },
  { country: "Sri Lanka", code: "+94", abbreviation: "LK" },
  { country: "Sudan", code: "+249", abbreviation: "SD" },
  { country: "Suriname", code: "+597", abbreviation: "SR" },
  { country: "Sweden", code: "+46", abbreviation: "SE" },
  { country: "Switzerland", code: "+41", abbreviation: "CH" },
  { country: "Syria", code: "+963", abbreviation: "SY" },
  { country: "Taiwan", code: "+886", abbreviation: "TW" },
  { country: "Tajikistan", code: "+992", abbreviation: "TJ" },
  { country: "Tanzania", code: "+255", abbreviation: "TZ" },
  { country: "Thailand", code: "+66", abbreviation: "TH" },
  { country: "Timor-Leste", code: "+670", abbreviation: "TL" },
  { country: "Togo", code: "+228", abbreviation: "TG" },
  { country: "Tonga", code: "+676", abbreviation: "TO" },
  { country: "Trinidad and Tobago", code: "+1-868", abbreviation: "TT" },
  { country: "Tunisia", code: "+216", abbreviation: "TN" },
  { country: "Turkey", code: "+90", abbreviation: "TR" },
  { country: "Turkmenistan", code: "+993", abbreviation: "TM" },
  { country: "Tuvalu", code: "+688", abbreviation: "TV" },
  { country: "Uganda", code: "+256", abbreviation: "UG" },
  { country: "Ukraine", code: "+380", abbreviation: "UA" },
  { country: "United Arab Emirates", code: "+971", abbreviation: "AE" },
  { country: "United Kingdom", code: "+44", abbreviation: "GB" },
  { country: "United States", code: "+1", abbreviation: "US" },
  { country: "Uruguay", code: "+598", abbreviation: "UY" },
  { country: "Uzbekistan", code: "+998", abbreviation: "UZ" },
  { country: "Vanuatu", code: "+678", abbreviation: "VU" },
  { country: "Vatican City", code: "+379", abbreviation: "VA" },
  { country: "Venezuela", code: "+58", abbreviation: "VE" },
  { country: "Vietnam", code: "+84", abbreviation: "VN" },
  { country: "Yemen", code: "+967", abbreviation: "YE" },
  { country: "Zambia", code: "+260", abbreviation: "ZM" },
  { country: "Zimbabwe", code: "+263", abbreviation: "ZW" }
];

export const _ADMIN = {
  filters: {
    jobs: ["Status", "Date de début"],
    proposals: ["Date de candidature"],
    companies: [],
    students: [
      "Domaine d'études",
      "Compétences",
      "Langues parlées",
      "Niveaux d'études",
      "Etablissement actuel",
      "Ville",
      "Disponibilité"
    ]
  }
};

export const _COMPANY = {
  filters: {
    membersJobs: ["Status", "Etudiant", "Date de début", "Type de mission"],
    lastProposals: ["Type de mission", "Date de début"],
    jobs: ["Status", "Type de mission", "Date de début"]
  }
};

export const _STUDENT = {
  filters: {
    proposals: ["Etat", "Type de mission", "Date de début"],
    actives: ["Type de mission", "Date de début"]
  }
};

export const _COUNTRY_LIST_ISO = [
  "AFG",
  "ZAF",
  "ALB",
  "DZA",
  "DEU",
  "AND",
  "AGO",
  "ATG",
  "SAU",
  "ARG",
  "ARM",
  "AUS",
  "AUT",
  "AZE",
  "BHS",
  "BHR",
  "BGD",
  "BRB",
  "BEL",
  "BLZ",
  "BEN",
  "BTN",
  "BLR",
  "MMR",
  "BOL",
  "BIH",
  "BWA",
  "BRA",
  "BRN",
  "BGR",
  "BFA",
  "BDI",
  "KHM",
  "CMR",
  "CAN",
  "CPV",
  "CHL",
  "CHN",
  "CYP",
  "COL",
  "COM",
  "COG",
  "COD",
  "PRK",
  "KOR",
  "CRI",
  "CIV",
  "HRV",
  "CUB",
  "DNK",
  "DJI",
  "DMA",
  "EGY",
  "ARE",
  "ECU",
  "ERI",
  "ESP",
  "EST",
  "SWZ",
  "USA",
  "ETH",
  "FJI",
  "FIN",
  "FRA",
  "GAB",
  "GMB",
  "GEO",
  "GHA",
  "GRC",
  "GRD",
  "GTM",
  "GIN",
  "GNB",
  "GNQ",
  "GUY",
  "HTI",
  "HND",
  "HUN",
  "IND",
  "IDN",
  "IRQ",
  "IRN",
  "IRL",
  "ISL",
  "ISR",
  "ITA",
  "JAM",
  "JPN",
  "JOR",
  "KAZ",
  "KEN",
  "KGZ",
  "KIR",
  "KWT",
  "LAO",
  "LVA",
  "LBN",
  "LBR",
  "LBY",
  "LIE",
  "LTU",
  "LUX",
  "MDG",
  "MYS",
  "MWI",
  "MDV",
  "MLI",
  "MLT",
  "MAR",
  "MHL",
  "MUS",
  "MRT",
  "MEX",
  "FSM",
  "MDA",
  "MCO",
  "MNG",
  "MNE",
  "MOZ",
  "NAM",
  "NRU",
  "NPL",
  "NIC",
  "NER",
  "NGA",
  "NOR",
  "NZL",
  "OMN",
  "UGA",
  "UZB",
  "PAK",
  "PLW",
  "PAN",
  "PNG",
  "PRY",
  "NLD",
  "PER",
  "PHL",
  "POL",
  "PRT",
  "QAT",
  "ROU",
  "GBR",
  "RUS",
  "RWA",
  "KNA",
  "VCT",
  "LCA",
  "SLB",
  "SLV",
  "WSM",
  "STP",
  "SEN",
  "SRB",
  "SYC",
  "SLE",
  "SGP",
  "SVK",
  "SVN",
  "SOM",
  "SDN",
  "SSD",
  "LKA",
  "SWE",
  "CHE",
  "SUR",
  "SYR",
  "TJK",
  "TZA",
  "TCD",
  "CZE",
  "THA",
  "TLS",
  "TGO",
  "TON",
  "TTO",
  "TUN",
  "TKM",
  "TUR",
  "TUV",
  "UKR",
  "URY",
  "VUT",
  "VAT",
  "VEN",
  "VNM",
  "YEM",
  "ZMB",
  "ZWE"
];
