// Requires
import { useCallback, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useParams, useSearchParams } from "react-router-dom";

// Components
import Title from "../../../../../components/UI/Title";
import Text from "../../../../../components/UI/Text";
import SEPA from "../../../../../components/Company/Payment/SEPA";
import Resume from "./Payment/Resume";
import Reduction from "./Payment/Reduction";
import BankTransfert from "../../../../../components/Company/Payment/BankTransfert";
import PaymentResume from "./Payment/PaymentResume";
import Card from "../../../../../components/Company/Payment/Card";

// Services
import { youSignSuccess } from "../../../../../services/Payment/payment";

const Payment = () => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const [signState, setSignState] = useState(false);

  const [tabIsSelected, setTabIsSelected] = useState<
    "SEPA" | "CARD" | "bankTransfert"
  >("SEPA");

  const [codeAdded, setCodeAdded] = useState<{
    id: number;
    title: string;
    percent: number;
    expiredDate: string;
  }>();

  const _TABS: {
    name: "SEPA" | "CARD" | "bankTransfert";
    element: React.ReactNode;
  }[] = [
    {
      name: "SEPA",
      element: <SEPA code={codeAdded ? codeAdded.title : undefined} />
    },
    { name: "CARD", element: <Card /> },
    { name: "bankTransfert", element: <BankTransfert /> }
  ];

  const codeCallback = (code: {
    id: number;
    title: string;
    percent: number;
    expiredDate: string;
  }) => {
    setCodeAdded(code);
  };

  const validateSign = useCallback(async () => {
    const token = searchParams.get("signingtoken");

    if (id && token) {
      const response = await youSignSuccess(Number(id), token);
      if (response) {
        return;
      }

      return;
    }
  }, [id, searchParams]);

  useEffect(() => {
    const state = searchParams.get("state");
    const signingtoken = searchParams.get("signingtoken");

    if (state === "success" && signingtoken) {
      setSignState(true);
      validateSign();
      return;
    }

    setSignState(false);
    return;
  }, [searchParams, validateSign]);

  if (signState)
    return (
      <div className="flex h-full flex-col p-4">
        <Title type="page">
          Vérification de la signature du mandat de prélévement
        </Title>
        <div className="flex h-full items-center justify-center">
          <Text className="!text-2xl">
            Félicitation, vous avez signez votre mandat de prélévement SEPA !
          </Text>
        </div>
      </div>
    );

  return (
    <div className="flex h-full flex-col gap-8 p-4">
      <Title type="page">Procéder au paiement de la mission</Title>
      <div className="flex flex-col items-center gap-16">
        <div className="flex items-center justify-center">
          <div className="flex flex-row gap-4">
            {_TABS.map((tab, _id) => (
              <div key={_id} onClick={() => setTabIsSelected(tab.name)}>
                <Text
                  className={`cursor-pointer rounded-lg border bg-blueViolet-50 px-4 py-2 text-3xl hover:border hover:border-blueViolet-600 ${
                    tabIsSelected === tab.name ? "border-blueViolet-600" : ""
                  }`}
                >
                  {tab.name === "CARD" && "Carte bancaire"}
                  {tab.name === "SEPA" && "Prélévement SEPA"}
                  {tab.name === "bankTransfert" && "Virement bancaire"}
                </Text>
              </div>
            ))}
          </div>
        </div>
        <div className="flex w-fit flex-row gap-8">
          <div className="flex flex-col gap-8">
            {tabIsSelected !== "CARD" && <Resume code={codeAdded} />}
            <AnimatePresence mode="wait">
              <motion.div
                key={tabIsSelected}
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 5 }}
              >
                <div className="flex justify-center">
                  <div className="w-fit">
                    {_TABS.find((tab) => tab.name === tabIsSelected)?.element}
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          {tabIsSelected !== "CARD" && (
            <div className="flex flex-col gap-8">
              <Reduction callback={codeCallback} />
              <PaymentResume />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
