// Requires
import { Link } from "react-router-dom";

// Assets
import logo from "../../../assets/logos/Juwa_logo_marine.svg";
import logoSymbol from "../../../assets/logos/Juwa_embleme_marine.svg";
import { ArrowLeft2 } from "iconsax-react";

const Header = ({
  sidebarIsOpen,
  toggleSidebar
}: {
  sidebarIsOpen: boolean;
  toggleSidebar: () => void;
}) => {
  return (
    <div className="flex flex-row items-center justify-between">
      {sidebarIsOpen && (
        <Link to={"/"}>
          <img src={logo} alt="Logo Juwa" className="w-[180px]" />
        </Link>
      )}
      {!sidebarIsOpen && (
        <img src={logoSymbol} alt="Taftavie Logo" className="w-[80px]" />
      )}
      <div
        onClick={toggleSidebar}
        className="flex cursor-pointer items-center justify-center rounded-lg bg-blueViolet-50 p-2 md:hidden"
      >
        <ArrowLeft2 size={18} />
      </div>
    </div>
  );
};

export default Header;
