// Requires
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

// Icons
import { Chart1 } from "iconsax-react";

// Components
import Title from "../UI/Title";

// Context
import { usePopup } from "../../context/Popup";

// Types
import { JobsByCategories } from "../../types/admin";

const MissionsByCategories = ({ data }: { data: JobsByCategories[] }) => {
  const { togglePopup } = usePopup();

  const COLORS = [
    "#6A0DAD", // Violet profond
    "#FF6F61", // Rouge rosé
    "#FFD700", // Doré
    "#6BCB77", // Vert frais
    "#4A90E2", // Bleu doux
    "#F7CAC9", // Rose pastel
    "#92A8D1", // Bleu gris
    "#955251", // Brun terracotta
    "#B565A7", // Mauve
    "#009688", // Vert émeraude
    "#F8B400", // Jaune moutarde
    "#FF6347", // Tomate
    "#E0BBE4", // Lavande clair
    "#F4A261" // Orange doux
  ];

  const top4 = data.sort((a, b) => b.jobCount - a.jobCount).slice(0, 4);
  const others = data.slice(4);

  const otherJobs = others.reduce((acc, curr) => acc + curr.jobCount, 0);
  const otherJobsPercentage = Math.round(
    (otherJobs / data.reduce((acc, curr) => acc + curr.jobCount, 0)) * 100
  );

  const handleOtherClick = () => {
    togglePopup("OthersJobsByCategories");
  };

  return (
    <div className="flex grow flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <Chart1 size={18} />
        <Title type="bloc">Missions par catégories</Title>
      </div>
      <div className="flex flex-row items-center gap-8">
        <div className="flex h-[350px] min-w-[50%] max-w-[50%] items-center">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={[
                  ...top4,
                  { categoryName: "Autres", jobCount: otherJobs }
                ]}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="jobCount"
              >
                {top4.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                <Cell key="others" fill={COLORS[COLORS.length - 1]} />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="flex h-full grow flex-row flex-wrap items-center justify-center">
          <div className="flex h-fit flex-col flex-wrap gap-6">
            {top4.map((item, _id) => {
              return (
                <div key={_id} className="flex flex-row gap-2">
                  <div
                    className="h-auto w-[15px] rounded-lg"
                    style={{ backgroundColor: COLORS[_id] }}
                  />
                  <div className="flex flex-col">
                    <p className="text-sm">{item.categoryName}</p>
                    <p className="text-sm">{item.jobCount * 10}%</p>
                  </div>
                </div>
              );
            })}
            <div
              onClick={handleOtherClick}
              className="flex cursor-pointer flex-row gap-2"
            >
              <div
                className="h-auto w-[15px] rounded-lg"
                style={{ backgroundColor: COLORS[COLORS.length - 1] }}
              />
              <div className="flex flex-col">
                <p className="text-sm">Autres</p>
                <p className="text-sm">{otherJobsPercentage}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionsByCategories;
