// Components
import SubPage from "../components/Utils/SubPage";

// Pages
import JobDetails from "../pages/Protected/Student/Jobs/[id]/Job";
import Favoris from "../pages/Protected/Student/Jobs/Favoris";
import Jobs from "../pages/Protected/Student/Jobs/Jobs";
import Proposal from "../pages/Protected/Student/Jobs/Proposal/New/[id]/Proposal";
import Profil from "../pages/Protected/Student/Profil/[id]/Profil";
import EditProfil from "../pages/Protected/Student/Profil/[id]/Edit";
import ProtectedRoute from "../pages/Protected/Common/ProtectedRoute";
import Proposals from "../pages/Protected/Student/Jobs/Proposal/All/Proposals";
import InProgress from "../pages/Protected/Student/Jobs/InProgress";
import Recommend from "../pages/Protected/Student/Jobs/Recommend";

export const studentRouters = {
  path: "student",
  element: <ProtectedRoute />,
  children: [
    {
      path: "",
      element: <SubPage />,
      children: [
        {
          path: "jobs",
          element: <SubPage />,
          children: [
            {
              path: "",
              element: <SubPage />,
              children: [
                {
                  path: "",
                  element: <Jobs />
                },
                {
                  path: ":id",
                  element: <SubPage />,
                  children: [
                    {
                      path: "",
                      element: <JobDetails />
                    },
                    {
                      path: "proposal",
                      element: <Proposal />
                    }
                  ]
                }
              ]
            },
            {
              path: "candidatures",
              element: <Proposals />
            },
            {
              path: "forme",
              element: <Recommend />
            },
            {
              path: "inprogress",
              element: <InProgress />
            },
            {
              path: "favoris",
              element: <Favoris />
            }
          ]
        },
        {
          path: "profil",
          element: <SubPage />,
          children: [
            {
              path: "",
              element: <Profil />
            },
            {
              path: "edit",
              element: <EditProfil />
            }
          ]
        }
      ]
    }
  ]
};
