// Requires
import dayjs from "dayjs";

// Context
import { useChat } from "../../context/Chat/ChatContext";

// Components
import { HorizontalDivider } from "../UI/Divider";
import Text from "../UI/Text";

const DateSeparator = () => {
  const { messages } = useChat();

  return (
    <div className="flex flex-row items-center gap-4">
      <HorizontalDivider />
      <Text className="text-nowrap">
        {messages?.messages[0]?.createdAt
          ? dayjs(messages.messages[0].createdAt)
              .locale("fr")
              .format(" DD MMMM YYYY à HH[h] mm")
          : ""}
      </Text>
      <HorizontalDivider />
    </div>
  );
};

export default DateSeparator;
