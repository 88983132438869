// Requires
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

// Icons
import { InfoCircle, TickSquare } from "iconsax-react";

// Components
import Button from "../UI/Button";
import Text from "../UI/Text";
import Title from "../UI/Title";

// Utils
import { useMobile } from "../../utils/mobile/responsive";

// Context
import { usePopup } from "../../context/Popup";
import CustomTooltip from "../UI/Tooltip";

type PlanType = {
  name: string;
  formatedName: string;
  commission: number;
  price: number;
  isActive: boolean;
  options: string[];
  lastPlan?: string;
  facturationMode: number;
  isCustom?: boolean;
  isCancelled?: boolean;
};

const Card = ({
  name,
  formatedName,
  commission,
  price,
  isActive,
  facturationMode,
  options,
  isCustom,
  lastPlan,
  isCancelled
}: PlanType) => {
  const isMobile = useMobile();

  const { togglePopup } = usePopup();

  return (
    <div
      className={`flex w-full flex-1 grow flex-col rounded-lg md:w-[calc(25%-18px)] ${isMobile && "mb-14"}`}
    >
      <div
        className="flex flex-row items-center justify-center gap-2 rounded-t-lg bg-blueViolet-600 p-2 text-white"
        style={{ opacity: isActive ? 1 : 0 }}
      >
        <Text className="text-white">Mon plan actuel</Text>
      </div>
      <div
        className={`flex h-full flex-col gap-8 border-2 p-4 ${isActive ? "rounded-b-lg border-blueViolet-600" : "rounded-lg border-blueViolet-200"}`}
      >
        <div className="flex flex-col gap-1">
          <Title type="custom" classname="text-2xl text-start">
            {name}
          </Title>
          <Text className="text-start text-xs">
            {commission}% de frais de service par mission
          </Text>
        </div>
        <div className="flex flex-col justify-start gap-1">
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Title type="custom" classname="text-2xl text-start">
              {price === 0 ? "Gratuit" : isCustom ? "Sur-Mesure" : `${price}€`}
            </Title>
          </motion.div>
          {price === 0 || isCustom ? (
            <Text className="text-start text-sm opacity-0">
              HT/
              {facturationMode === 1
                ? "mois"
                : facturationMode === 3
                  ? "trimestre"
                  : "annuel"}{" "}
              par utlisateur
            </Text>
          ) : (
            <Text className="text-start text-sm">
              HT/{" "}
              {facturationMode === 1
                ? "mois"
                : facturationMode === 3
                  ? "trimestre"
                  : "annuel"}{" "}
              par utlisateur
            </Text>
          )}
        </div>
        <div className="flex min-h-[50px] w-fit flex-col">
          {!isActive &&
            !isCustom &&
            (isCancelled ? (
              <Link
                to={`payment?plan=${formatedName.toLowerCase()}&facturationMode=${facturationMode}`}
              >
                <Button type="fullLite">Choisir ce plan</Button>
              </Link>
            ) : (
              <CustomTooltip
                content="Veuillez attendre la fin de votre abonnement en cours"
                position="top"
              >
                <Button type="disabled">Choisir ce plan</Button>
              </CustomTooltip>
            ))}
          {!isActive &&
            isCustom &&
            (isCancelled ? (
              <Button type="full">Faire évoluer mon plan</Button>
            ) : (
              <CustomTooltip
                position="top"
                content="Veuillez attendre la fin de votre abonnement en cours"
              >
                <Button type="disabled">Faire évoluer mon plan</Button>
              </CustomTooltip>
            ))}
          {isActive && name !== "Standard" && isCancelled && (
            <div onClick={() => togglePopup("cancelSubscription")}>
              <Button type="fullLite">Annuler le renouvellement</Button>
            </div>
          )}
          {isActive && name !== "Standard" && !isCancelled && (
            <Button type="fullLite">Abonnement annulé</Button>
          )}
        </div>
        <div className="flex flex-col gap-4">
          {lastPlan ? (
            <Text className="font-semibold">
              Toute l'offre {lastPlan} avec :{" "}
            </Text>
          ) : (
            <Text className="font-semibold">Inclus :</Text>
          )}
          {!lastPlan && (
            <>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Chat, Appel et visios sécurisés</Text>{" "}
                <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Paiements securisés</Text>{" "}
                <InfoCircle size={18} />
              </div>
            </>
          )}
          {lastPlan === "Standard" && (
            <>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Contactez nos meilleurs talents</Text>{" "}
                <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Accompagnement par un expert</Text>{" "}
                <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Formalisation du besoin</Text>{" "}
                <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Profils recommandés</Text>{" "}
                <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Nombre de candidatures</Text>{" "}
                <InfoCircle size={18} />
              </div>
            </>
          )}
          {lastPlan === "Starter" && (
            <>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">NDA</Text> <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Gestions des droits d'auteur</Text>{" "}
                <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">
                  Gestion des stages & alternances
                </Text>{" "}
                <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm">Conseiller dédié</Text>{" "}
                <InfoCircle size={18} />
              </div>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm !text-blueViolet-600">
                  Jusqu'à 3 missions pilotées
                </Text>{" "}
                <InfoCircle size={18} />
              </div>
            </>
          )}
          {lastPlan === "Business" && (
            <>
              <div className="flex flex-row items-center gap-3">
                <TickSquare size={18} color="green" />{" "}
                <Text className="text-sm !text-blueViolet-600">
                  Jusqu'à 10 missions pilotées
                </Text>{" "}
                <InfoCircle size={18} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
