// Requires
import { useRef, useState } from "react";

// Icons
import { ImportSquare } from "iconsax-react";

// Components
import Button from "../Button";
import Text from "../Text";
import FileCard from "../../Chat/FileCard";

// Types
type FileData = {
  fileName: string;
  fileType: string;
};

type Error = {
  file?: string;
};

const JoinFile = ({
  onChange,
  onRemove,
  title
}: {
  onChange: (files: File[]) => void;
  onRemove: (index: number) => void;
  title?: boolean;
}) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<FileData[]>([]);

  const [error, setError] = useState<Error>({});

  const openFileInput = () => {
    if (inputFile) {
      inputFile.current?.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({});
    const files = e.target.files;

    if (files && files?.length > 3) {
      setError({ file: "Tu ne peux ajouter que 3 fichiers au maximum" });
      return;
    }

    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setFiles(
        filesArray.map((file) => {
          return {
            fileName: file.name,
            fileType: file.type
          };
        })
      );

      e.target.value = "";

      onChange(filesArray);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prev) => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      return newFiles;
    });

    onRemove(index);
  };

  return (
    <>
      <div
        onClick={openFileInput}
        className="flex cursor-pointer flex-col gap-4"
      >
        {title && (
          <Text className="font-semibold">
            Joindre un document{" "}
            <span className="text-xs font-normal">- facultatif</span>
          </Text>
        )}
        <div className="flex flex-row gap-4 rounded-lg border border-dashed p-4">
          <div className="flex items-center justify-center rounded-lg bg-blueViolet-50 p-4">
            <ImportSquare size={24} />
          </div>
          <div className="flex flex-row items-center gap-4 max-md:flex-wrap">
            <Text>Glisser-déposer le PDF ou </Text>
            <Button type="fullLite">Parcourir l'ordinateur</Button>
          </div>
        </div>
        <input
          multiple
          onChange={handleFileChange}
          ref={inputFile}
          type="file"
          className="hidden"
        />
      </div>
      {error.file && <Text className="text-sm text-red-500">{error.file}</Text>}
      <div className="flex flex-row flex-wrap gap-4">
        {files.length > 0 &&
          files.map((file, index) => (
            <FileCard
              key={index}
              file={file}
              removeFile={() => handleRemoveFile(index)}
            />
          ))}
      </div>
    </>
  );
};

export default JoinFile;
