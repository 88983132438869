// Icons
import { TextalignJustifyright } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import Title from "../UI/Title";
import Text from "../UI/Text";

const Presentation = ({ data }: { data?: string }) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <TextalignJustifyright size={18} />
        <Title type="bloc">Présentation</Title>
      </BlocTitle>
      <Content>
        <Text className="break-words">
          {data ?? "Aucune présentation pour le moment"}
        </Text>
      </Content>
    </div>
  );
};

export default Presentation;
