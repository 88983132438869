// Requires
import { useState } from "react";

// Components
import Title from "../../../../../../components/UI/Title";
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Date from "../../../../../../components/UI/Date";

// Services
import { checkReductionCode } from "../../../../../../services/Payment/payment";

const Reduction = ({ callback }: { callback: (code: any) => void }) => {
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [codeData, setCodeData] = useState<{
    id: number;
    title: string;
    percent: number;
    expiredDate: string;
  }>();

  const handleValidate = async () => {
    setError(false);
    if (code && code.length > 0) {
      setIsLoading(true);
      const response = await checkReductionCode(code);
      if (response) {
        setCodeData(response);
        setIsLoading(false);
        callback(response);
        return;
      }

      setError(true);
      setIsLoading(false);
      return;
    }
  };

  const handleEdit = async () => {
    setCodeData(undefined);
    callback(undefined);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleValidate();
    }
  };

  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Code de réduction</Title>
      <div className="flex flex-row gap-2">
        <input
          onKeyDown={handleKeyDown}
          disabled={codeData ? true : false}
          onChange={(e) => setCode(e.target.value)}
          type="text"
          placeholder="Entrer un code de réduction"
          className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${error && "border-red-500"}`}
        />
        {!codeData && (
          <div onClick={handleValidate}>
            <Button type="full">
              {isLoading ? <div className="loader" /> : "Valider"}
            </Button>
          </div>
        )}

        {codeData && (
          <div onClick={handleEdit}>
            <Button type="disabled" className="cursor-pointer">
              Modifier
            </Button>
          </div>
        )}
      </div>
      {error && (
        <Text className="text-sm text-red-500">Le code est invalide</Text>
      )}
      {codeData && (
        <div className="flex flex-col">
          <Text>Vous avez valider un code de réduction.</Text>
          <Text>
            Celui-ci vous octrois une réduction de{" "}
            <span className="font-semibold">{codeData.percent}%</span>
          </Text>
          <Text className="flex flex-row gap-1">
            Il expire le{" "}
            <Date
              format="DD/MM/YYYY"
              date={codeData.expiredDate}
              className="font-semibold"
            />
            et ne peut être utilisé qu'une seul fois.
          </Text>
        </div>
      )}
    </div>
  );
};

export default Reduction;
