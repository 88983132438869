// Icons
import { Building, Star1 } from "iconsax-react";

// Components
import Button from "../../UI/Button";
import { VerticalDivider } from "../../UI/Divider";
import Text from "../../UI/Text";

// Types
import { CompletedMissions } from "../../../types/user";

const CompletedMission = ({ data }: { data: CompletedMissions }) => {
  return (
    <div className="flex w-full flex-row gap-6 rounded-lg border border-blueViolet-200 p-4">
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-col">
          <Text className="font-semibold">{data.title}</Text>
          <div className="flex flex-row items-center gap-2">
            <Building size={14} className="!text-withCustom-500" />
            <Text className="text-sm !text-withCustom-500">
              {data.companyName}
            </Text>
          </div>
        </div>
        <Button type="fullLite" className="w-fit">
          Voir la mission
        </Button>
      </div>
      <VerticalDivider />
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-row gap-4">
            <img src={data.ownerImage} className="size-14 rounded-lg" />
            <div className="flex flex-col">
              <Text className="font-semibold">{data.ownerName}</Text>
              <Text className="text-xs !text-withCustom-500">
                {data.companyName}
              </Text>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex flex-row items-center gap-1">
              <Text>5</Text>
              <Star1 size={18} />
            </div>
          </div>
        </div>
        <div>Très bon travail !</div>
      </div>
    </div>
  );
};

export default CompletedMission;
