// Requires
import { useEffect, useState } from "react";

// Components
import Tag from "../UI/Tag";
import Text from "../UI/Text";

// Types
import { LanguagesType } from "../../types/misc";

// Helpers
import { getLanguages } from "../../services/Misc/misc";

const LanguageSelector = ({
  callback,
  initialData
}: {
  callback: (languages: LanguagesType[]) => void;
  initialData: LanguagesType[];
}) => {
  const [languagesSelected, setLanguagesSelected] = useState<
    LanguagesType[] | []
  >(initialData || []);

  const [languagesList, setLanguagesList] = useState<LanguagesType[]>([]);
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    const fetch = async () => {
      const response = await getLanguages();
      setLanguagesList(response);
    };

    fetch();
  }, []);

  // Callback
  useEffect(() => {
    callback(languagesSelected);
  }, [languagesSelected]);

  const updateLanguagesSelected = (item: LanguagesType) => {
    setLanguagesSelected((prevSelected) => {
      if (prevSelected.some((lang) => lang.id === item.id)) {
        return prevSelected.filter((lang) => lang.id !== item.id);
      }
      return [...prevSelected, item];
    });
  };

  const filteredLanguages =
    query &&
    languagesList
      .filter((item) =>
        item.title?.toLowerCase().includes(query?.toLowerCase())
      )
      .filter((item) => !languagesSelected.some((lang) => lang.id === item.id));

  return (
    <div className="flex flex-col gap-2">
      <Text className="font-semibold">Langues parlées</Text>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <input
            onChange={(e) => setQuery(e.currentTarget.value)}
            type="text"
            placeholder="Français, Anglais..."
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          />
          {query && query.length > 0 && (
            <div className="flex flex-row flex-wrap gap-2 rounded-lg border bg-white p-2">
              {filteredLanguages &&
                filteredLanguages.length > 0 &&
                filteredLanguages.map((item) => (
                  <div
                    className="cursor-pointer"
                    key={item.id}
                    onClick={() => updateLanguagesSelected(item)}
                  >
                    <Tag>{item.title}</Tag>
                  </div>
                ))}
              {filteredLanguages && filteredLanguages.length < 1 && (
                <Text>Aucun résultat avec le mot-clé actuel</Text>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row flex-wrap gap-2">
          {languagesSelected &&
            languagesSelected.length > 0 &&
            languagesSelected.map((item) => (
              <div
                onClick={() => updateLanguagesSelected(item)}
                key={item.id}
                className="cursor-pointer"
              >
                <Tag className="bg-blueViolet-600 text-white">{item.title}</Tag>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
