// Icons
import { Additem } from "iconsax-react";

// Requires
import { useState } from "react";

// Components
import ExperienceCard from "../../../Register/ExperienceCard";
import Button from "../../../UI/Button";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";
import Form from "./Experiences/Form";
import Highlight from "../../../UI/Highlight";

// Types
import { ExperiencesType, ExperienceType } from "../../../../types/user";

// Services
import { updateExperience } from "../../../../services/User/user";
import { useUser } from "../../../../utils/store/userStore";

const Experiences = ({ data }: { data?: ExperiencesType }) => {
  const [experiences, setExperiences] = useState<ExperiencesType>(
    data ?? { experiences: [] }
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleCancel = () => {
    setIsOpen(false);
    setEditingIndex(null);
  };

  const AddOrUpdate = (data: ExperienceType) => {
    setIsOpen(false);

    if (editingIndex !== null) {
      setExperiences((prev) => ({
        ...prev,
        experiences: prev.experiences.map((item, index) =>
          index === editingIndex ? { ...item, ...data } : item
        )
      }));
      setEditingIndex(null);
    } else {
      setExperiences((prev) => ({
        ...prev,
        experiences: [...(prev?.experiences || []), data]
      }));
    }
  };

  const handleRemove = (index: number) => {
    setExperiences((prev) => ({
      ...prev,
      experiences: prev.experiences.filter((_, i) => i !== index)
    }));
  };

  const handleEdit = (index: number) => {
    const formationToEdit = experiences?.experiences[index];
    if (formationToEdit) {
      setIsOpen(true);
      setEditingIndex(index);
    }
  };

  const isExperiencesType = (data: any): data is ExperiencesType => {
    return data.experiences.every((experience: any) => {
      return (
        Array.isArray(experience.skills) &&
        experience.skills.every((skill: any) => typeof skill === "number")
      );
    });
  };

  const createOrUpdate = async () => {
    setIsLoading(true);
    if (experiences) {
      const transformedExperiences = {
        experiences: experiences.experiences.map(
          ({ id, studentId, link, skills, ...rest }) => ({
            ...rest,
            skills: skills?.map((skill) => skill.id),
            start: rest.start?.toString() || new Date().toISOString(),
            end: rest.end?.toString() || new Date().toISOString()
          })
        )
      };

      if (isExperiencesType(transformedExperiences)) {
        const response = await updateExperience(transformedExperiences);

        if (response) {
          setSuccess(true);
          setIsLoading(false);
          useUser.getState().getUser();
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
          return;
        }
      }
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Expériences</Title>
      <div onClick={() => setIsOpen(true)} className="flex flex-col gap-8">
        <div className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border p-4">
          <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-blueViolet-50 p-4">
            <Additem size={18} />
          </div>
          <Text className="font-semibold">Ajouter une expérience</Text>
        </div>
      </div>
      {isOpen && (
        <Form
          callback={AddOrUpdate}
          initialData={
            editingIndex !== null
              ? experiences.experiences?.[editingIndex]
              : undefined
          }
          cancel={handleCancel}
        />
      )}
      {experiences &&
        experiences.experiences.length > 0 &&
        experiences.experiences.map((item, _id) => (
          <ExperienceCard
            key={_id}
            data={item}
            index={_id}
            onDelete={handleRemove}
            onEdit={handleEdit}
          />
        ))}
      {!experiences ||
        (experiences && experiences.experiences.length < 1 && (
          <Text>Aucune formation pour le moment</Text>
        ))}
      {success && (
        <Highlight type="Success">
          Les modifications ont bien été appliquées.
        </Highlight>
      )}
      {experiences.experiences !== data?.experiences ? (
        <div onClick={createOrUpdate} className="flex flex-row justify-end">
          <Button type="full">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      ) : (
        <div className="flex flex-row justify-end">
          <Button type="disabled">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Experiences;
