// Icons
import { Activity } from "iconsax-react";

// Components
import { BlocTitle } from "../Bloc/Title";
import Text from "../UI/Text";
import Title from "../UI/Title";

const Advancement = ({ step }: { step: number }) => {
  let progressWidth = 0;

  if (step === 0) {
    progressWidth = 0;
  }

  if (step === 1) {
    progressWidth = 10;
  }

  if (step === 2) {
    progressWidth = 38;
  }

  if (step === 3) {
    progressWidth = 64;
  }

  if (step === 4) {
    progressWidth = 100;
  }

  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <Activity size={18} />
        <Title type="bloc">Suivi de la mission</Title>
      </BlocTitle>
      <div className="relative flex flex-row items-center px-8">
        <div className="z-40 flex grow flex-row justify-between">
          <div className="flex flex-col items-center justify-center gap-2">
            <div
              className={`flex h-9 w-9 items-center justify-center rounded-full border border-blueViolet-600 ${step >= 1 ? "bg-blueViolet-600 text-white" : "bg-white text-blueViolet-600"}`}
            >
              1
            </div>
            <Text className="text-sm text-blueViolet-600">Mission postée</Text>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <div
              className={`flex h-9 w-9 items-center justify-center rounded-full border border-blueViolet-600 ${step >= 2 ? "bg-blueViolet-600 text-white" : "bg-white text-blueViolet-600"}`}
            >
              2
            </div>
            <Text className="text-sm text-blueViolet-600">
              Pré-sélection des profils
            </Text>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <div
              className={`flex h-9 w-9 items-center justify-center rounded-full border border-blueViolet-600 ${step >= 3 ? "bg-blueViolet-600 text-white" : "bg-white text-blueViolet-600"}`}
            >
              3
            </div>
            <Text className="text-sm text-blueViolet-600">
              Mission en cours
            </Text>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <div
              className={`flex h-9 w-9 items-center justify-center rounded-full border border-blueViolet-600 ${step >= 4 ? "bg-blueViolet-600 text-white" : "bg-white text-blueViolet-600"}`}
            >
              4
            </div>
            <Text className="text-sm text-blueViolet-600">
              Mission cloturée
            </Text>
          </div>
        </div>
        <div className="absolute left-0 top-3 z-30 w-full rounded-lg bg-blueViolet-200">
          <div
            style={{ width: `${progressWidth}%` }}
            className="rounded-lg bg-blueViolet-600 p-1"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Advancement;
