// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";

// Context
import { usePopup } from "../../context/Popup";

// Components
import Chat from "../Chat/Chat";
import ChatConversationsPanel from "../Message/ChatConversationsPanel";
import JobDetails from "../Popup/JobDetails";
import ScheduleVideo from "../Popup/ScheduleVideo";
import Title from "./Title";
import Popup from "../Charts/MissionsByCategories/Popup";
import ProposalDetailsPopup from "../Popup/ProposalDetails";
import Shortlist from "../Popup/Admin/Shortlist/Shortlist";
import SuggestJob from "../Popup/Admin/SuggestJob/SuggestJob";
import BankTransfertConfirmation from "../Popup/Payment/BankTransfertConfirmation";
import Notation from "../Popup/Admin/Notation/Notation";
import CancelSubscription from "../Popup/Payment/cancelSubscription";
import Ticket from "../Popup/Ticket/Ticket";

const FullScreenPopup = () => {
  const { isOpen, isType, togglePopup } = usePopup();
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      togglePopup("");
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const popupComponents: { [key: string]: JSX.Element } = {
    chat: (
      <>
        <Title type="bloc">Messagerie</Title>
        <div className="flex flex-row gap-8">
          <ChatConversationsPanel />
          <Chat ldStatic={true} />
        </div>
      </>
    ),
    scheduleVideo: <ScheduleVideo />,
    proposalsDetails: <ProposalDetailsPopup />,
    jobDetails: <JobDetails />,
    OthersJobsByCategories: <Popup />,
    shortList: <Shortlist />,
    suggestJob: <SuggestJob />,
    bankTransfertConfirmation: <BankTransfertConfirmation />,
    setNotation: <Notation />,
    cancelSubscription: <CancelSubscription />,
    createTicket: <Ticket />
  };

  return (
    <AnimatePresence>
      {isOpen && isType && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute left-0 top-0 z-40 flex h-screen w-screen items-center justify-center bg-black bg-opacity-60"
          onClick={() => togglePopup("")}
        >
          <motion.div
            initial={{ y: 15 }}
            animate={{ y: 0 }}
            exit={{ y: 15 }}
            className="z-50 flex max-h-[650px] flex-col gap-8 overflow-y-auto rounded-lg border bg-white shadow-lg max-md:max-w-[95%] md:max-w-[70%]"
            ref={popupRef}
            onClick={(e) => e.stopPropagation()}
          >
            {popupComponents[isType]}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FullScreenPopup;
