import { useLocation } from "react-router-dom";

function capitalizeFirstLetterOfEachWord(sentence: string) {
  return sentence
    .split(" ") // Divise la phrase en mots
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)) // Met en majuscule la première lettre de chaque mot
    .join(" "); // Reconstitue la phrase
}

const usePathname = () => {
  const location = useLocation();
  const { pathname } = location;

  let formatedPathname = pathname.replace(/\//g, " / ");
  formatedPathname = formatedPathname.replace("-", " ");

  formatedPathname = capitalizeFirstLetterOfEachWord(formatedPathname);

  return {
    pathname,
    formatedPathname,
    lastPathname: formatedPathname.split(" / ").pop()
  };
};

export default usePathname;
