// Requires
import { fetchApi } from "../Misc/api";

// Retrieve the list of files sent and received from the logged in user
export const getFiles = async () => {
  const response = await fetchApi("/attachment?page=0&limit=10", "GET");
  if (response.error) return false;

  return response;
};

// Retrieve the list of files attached to a specific conversation
export const getFilesFromConversation = async (
  conversationId: number,
  role: string
) => {
  const response = await fetchApi(`/attachment/${conversationId}`, "GET");
  // const response = await { error: true };
  if (response.error) return false;

  return response;
};
