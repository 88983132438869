// Components
import Button from "./Button";

const Premium = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col gap-3 rounded-lg bg-backgroundGray p-4">
      {children}
      <Button className="w-full text-center" type="outline">
        Voir tout les plans
      </Button>
    </div>
  );
};

export default Premium;
