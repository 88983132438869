// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Components
import Text from "../../../../../../components/UI/Text";
import IA from "./IA";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

// Types
import { JobCreateType } from "../../../../../../types/job";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import { getLocalStorage } from "../../../../../../utils/helpers/localStorage";

const JobLayout = () => {
  const { step, direction, resetStep, specificStep } = useUI();

  const [withIA, setWithIA] = useState<boolean>(false);
  const [formData, setFormData] = useState<JobCreateType>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("ia")) {
      setWithIA(true);
      return;
    }
    setWithIA(false);
  }, [searchParams]);

  // Load form data from localStorage
  useEffect(() => {
    const localStep = getLocalStorage("publishJobStep");
    const localData = {
      step1: getLocalStorage("step1"),
      step2: getLocalStorage("step2"),
      step3: getLocalStorage("step3"),
      step4: getLocalStorage("step4"),
      step5: getLocalStorage("step5")
    };

    // Load data from local storage if available
    if (localData) {
      if (localData.step1) {
        const formatedStep = JSON.parse(localData.step1);
        setFormData((prev) => ({ ...prev, ...formatedStep }));
      }
      if (localData.step2) {
        const formatedStep = JSON.parse(localData.step2);
        setFormData((prev) => ({ ...prev, ...formatedStep }));
      }
      if (localData.step3) {
        const formatedStep = JSON.parse(localData.step3);
        setFormData((prev) => ({ ...prev, ...formatedStep }));
      }
      if (localData.step4) {
        const formatedStep = JSON.parse(localData.step4);
        setFormData((prev) => ({ ...prev, ...formatedStep }));
      }
      if (localData.step5) {
        const formatedStep = JSON.parse(localData.step5);
        setFormData((prev) => ({ ...prev, ...formatedStep }));
      }
    }

    // Set specific step if saved in localStorage
    if (localStep) {
      specificStep(Number(localStep));
      return;
    }

    resetStep();
  }, []);

  // Number of steps per part
  const stepByPart = [2, 2, 2];

  const [progressValue, setProgressValue] = useState({
    part1: 0,
    part2: 0,
    part3: 0
  });

  useEffect(() => {
    let currentPart = 1;
    let accumulatedSteps = 0;

    for (let i = 0; i < stepByPart.length; i++) {
      accumulatedSteps += stepByPart[i];
      if (step <= accumulatedSteps) {
        currentPart = i + 1;
        break;
      }
    }

    // Update progress bar values
    const newProgress = {
      part1:
        currentPart >= 1
          ? Math.min(((step - 1) / stepByPart[0]) * 100, 100)
          : 0,
      part2:
        currentPart >= 2
          ? Math.min(((step - stepByPart[0] - 1) / stepByPart[1]) * 100, 100)
          : 0,
      part3:
        currentPart >= 3
          ? Math.min(
              ((step - stepByPart[0] - stepByPart[1] - 1) / stepByPart[2]) *
                100,
              100
            )
          : 0
    };

    setProgressValue(newProgress);
  }, [step]);

  // Update form data
  const updateFormData = (newData: any) => {
    setFormData((prev) => ({ ...prev, ...newData }));
  };

  // Define steps based on withIA condition
  const steps = withIA
    ? [
        { id: 0, step: 1, element: <IA updateFormData={updateFormData} /> },
        { id: 1, step: 2, element: <Step1 updateFormData={updateFormData} /> },
        { id: 2, step: 3, element: <Step2 updateFormData={updateFormData} /> },
        { id: 3, step: 4, element: <Step6 updateFormData={updateFormData} /> },
        { id: 4, step: 5, element: <Step3 updateFormData={updateFormData} /> },
        { id: 5, step: 6, element: <Step4 updateFormData={updateFormData} /> },
        {
          id: 6,
          step: 7,
          element: formData ? <Step5 data={formData} /> : null
        }
      ]
    : [
        { id: 1, step: 1, element: <Step1 updateFormData={updateFormData} /> },
        { id: 2, step: 2, element: <Step2 updateFormData={updateFormData} /> },
        { id: 3, step: 3, element: <Step6 updateFormData={updateFormData} /> },
        { id: 4, step: 4, element: <Step3 updateFormData={updateFormData} /> },
        { id: 5, step: 5, element: <Step4 updateFormData={updateFormData} /> },
        {
          id: 6,
          step: 6,
          element: formData ? <Step5 data={formData} /> : null
        }
      ];

  const pageTransition = {
    initial: { opacity: 0, x: direction === "forward" ? "100%" : "-100%" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: direction === "forward" ? "-100%" : "100%" },
    transition: { duration: 0.3 }
  };
  const variants = {
    enter: (direction: any) => {
      return {
        opacity: 0,
        x: direction === "forward" ? "100%" : "-100%"
      };
    },
    center: {
      opacity: 1,
      x: 0
    },
    exit: (direction: any) => {
      return {
        opacity: 0,
        x: direction === "forward" ? "-100%" : "100%"
      };
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-8 max-md:gap-8">
      <div className="flex w-full flex-col gap-12 max-md:gap-8 md:w-[80%]">
        <div
          id="progressBar"
          className="flex flex-row items-start justify-center gap-4"
        >
          <div id="progressBarPart" className="flex flex-1 flex-row gap-6">
            <div className="flex w-full flex-col items-center gap-2">
              <div className="h-4 w-full rounded-full bg-blueViolet-100">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${progressValue.part1}%` }}
                  className="h-4 rounded-full bg-blueViolet-600"
                />
              </div>
              <div
                className={`h-2 w-2 rounded-full ${
                  progressValue.part1 === 100
                    ? "bg-blueViolet-600"
                    : "bg-blueViolet-100"
                }`}
              />
              <Text
                className={`text-center text-sm ${progressValue.part1 === 100 ? "text-blueViolet-600" : "text-blueViolet-100"}`}
              >
                Profil recherché
              </Text>
            </div>
          </div>
          <div id="progressBarPart" className="flex flex-1 flex-row gap-6">
            <div className="flex w-full flex-col items-center gap-2">
              <div className="h-4 w-full rounded-full bg-blueViolet-100">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${progressValue.part2}%` }}
                  className="h-4 rounded-full bg-blueViolet-600"
                />
              </div>
              <div
                className={`h-2 w-2 rounded-full ${
                  progressValue.part2 === 100
                    ? "bg-blueViolet-600"
                    : "bg-blueViolet-100"
                }`}
              />
              <Text
                className={`text-center text-sm ${progressValue.part2 === 100 ? "text-blueViolet-600" : "text-blueViolet-100"}`}
              >
                Conditions
              </Text>
            </div>
          </div>
          <div id="progressBarPart" className="flex flex-1 flex-row gap-6">
            <div className="flex w-full flex-col items-center gap-2">
              <div className="h-4 w-full rounded-full bg-blueViolet-100">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${progressValue.part3}%` }}
                  className="h-4 rounded-full bg-blueViolet-600"
                />
              </div>
              <div
                className={`h-2 w-2 rounded-full ${
                  progressValue.part3 === 100
                    ? "bg-blueViolet-600"
                    : "bg-blueViolet-100"
                }`}
              />
              <Text
                className={`text-center text-sm ${progressValue.part3 === 100 ? "text-blueViolet-600" : "text-blueViolet-100"}`}
              >
                Description de la mission
              </Text>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col gap-8">
          <AnimatePresence mode="wait">
            <motion.div
              custom={direction}
              key={steps[step].id}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={pageTransition.transition}
            >
              {steps[step].element}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default JobLayout;
