// Components
import SubPage from "../components/Utils/SubPage";
import ProtectedRoute from "../pages/Protected/Common/ProtectedRoute";

// Pages
import Compagny from "../pages/Protected/Company/MyCompany/Compagny";
import FindTalent from "../pages/Protected/Company/FindTalent";
import ManageJobs from "../pages/Protected/Company/Jobs/Manage/ManageJobs";
import LastProposals from "../pages/Protected/Company/Jobs/Manage/Proposals/LastProposals";
import Job from "../pages/Protected/Company/Jobs/Manage/[id]/Job";
import Payment from "../pages/Protected/Company/Jobs/Manage/payment";
import JobLayout from "../pages/Protected/Company/Jobs/Publish/New/Layout";
import PublishJob from "../pages/Protected/Company/Jobs/Publish/PublishJob";
import MyExpert from "../pages/Protected/Company/MyExpert";
import MyPlan from "../pages/Protected/Company/Subscription/MyPlan";
import EditProfil from "../pages/Protected/Student/Profil/[id]/Edit";
import Profil from "../pages/Protected/Student/Profil/[id]/Profil";
import EditCompany from "../pages/Protected/Company/MyCompany/Edit/Edit";
import { ChatProvider } from "../context/Chat/ChatProvider";
import PlanPayment from "../pages/Protected/Company/Subscription/Payment";

export const CompanyRouters = {
  path: "company",
  element: <ProtectedRoute />,
  children: [
    {
      path: "",
      element: <SubPage />,
      children: [
        {
          path: "jobs",
          element: <SubPage />,
          children: [
            {
              path: "publier-une-mission",
              element: <SubPage />,
              children: [
                { path: "", element: <PublishJob /> },
                { path: "publier", element: <JobLayout /> }
              ]
            }
          ]
        },
        {
          path: "find-talents",
          element: <FindTalent />
        },
        {
          path: "manages-jobs",
          element: <SubPage />,
          children: [
            {
              path: "",
              element: <ManageJobs />
            },
            {
              path: ":id",
              element: <Job />
            },
            {
              path: "candidatures",
              element: <SubPage />,
              children: [
                {
                  path: "",
                  element: <LastProposals />
                },
                {
                  path: "payment",
                  element: <SubPage />,
                  children: [{ path: ":id", element: <Payment /> }]
                }
              ]
            }
          ]
        },
        {
          path: "company",
          element: <SubPage />,
          children: [
            {
              path: "",
              element: <Compagny />
            },
            {
              path: "edit",
              element: <EditCompany />
            }
          ]
        },
        {
          path: "subscription",
          element: <SubPage />,
          children: [
            {
              path: "",
              element: <MyPlan />
            },
            {
              path: "payment",
              element: <PlanPayment />
            }
          ]
        },
        {
          path: "expert",
          element: (
            <ChatProvider>
              <MyExpert />
            </ChatProvider>
          )
        },
        {
          path: "student",
          element: <SubPage />,
          children: [
            {
              path: ":id",
              element: <Profil />
            }
          ]
        },
        {
          path: "account",
          element: <EditProfil />
        }
      ]
    }
  ]
};
