// Requires
import {
  Tooltip,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Area
} from "recharts";

// Icons
import { Menu } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import Title from "../UI/Title";
import Text from "../UI/Text";

const CA = () => {
  const data: { name: string; ca: number; va: number }[] = [
    {
      name: "Janvier",
      ca: 0,
      va: 0
    },
    {
      name: "Février",
      ca: 100,
      va: 100
    },
    {
      name: "Mars",
      ca: 200,
      va: 300
    },
    {
      name: "Avril",
      ca: 300,
      va: 400
    },
    {
      name: "Mai",
      ca: 400,
      va: 450
    },
    {
      name: "Juin",
      ca: 500,
      va: 600
    },
    {
      name: "Juillet",
      ca: 600,
      va: 625
    },
    {
      name: "Aout",
      ca: 700,
      va: 740
    },
    {
      name: "Septembre",
      ca: 800,
      va: 900
    },
    {
      name: "Octobre",
      ca: 900,
      va: 1000
    },
    {
      name: "Novembre",
      ca: 1300,
      va: 1500
    },
    {
      name: "Décembre",
      ca: 1100,
      va: 1300
    }
  ];

  return (
    <div className="flex h-[400px] grow flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <div className="flex w-full flex-row items-start justify-between">
        <div className="flex flex-row items-center gap-2">
          <Menu size={18} />
          <Title type="bloc">
            Evolution du chiffre et du volume d'affaires
          </Title>
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center gap-2">
            <div className="size-4 rounded-md bg-[#AABDF7]" />
            <Text>Chiffre d'affaire</Text>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="size-4 rounded-md bg-[#82ca9d]" />
            <Text>Volume d'affaire</Text>
          </div>
        </div>
      </div>
      <Content>
        <ResponsiveContainer>
          <AreaChart
            className="w-max min-w-full"
            height={400}
            data={data}
            margin={{ left: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" tickLine={false} />
            <YAxis tickLine={false} />
            <CartesianGrid vertical={false} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="ca"
              stroke="#AABDF9"
              fillOpacity={0.5}
              fill="#AABDF7"
            />
            <Area
              type="monotone"
              dataKey="va"
              stroke="#82ca9d"
              fillOpacity={0}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Content>
    </div>
  );
};

export default CA;
