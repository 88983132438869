// Requires
import { ChangeEvent } from "react";

// Types
import { ErrorType } from "../../../pages/Auth/Register/Student/Step/Part4/Nine";

// Components
import Text from "../../UI/Text";

interface StudentTransportationProps {
  error: ErrorType;
  handleTransportChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const StudentTransportation = ({
  error,
  handleTransportChange
}: StudentTransportationProps) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-1 flex-col gap-2">
        <Text>
          Tu te déplaces en<span style={{ color: "red" }}> * </span>
        </Text>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-2">
          <input
            id="transportType-motorcycle"
            onChange={handleTransportChange}
            name="transportType"
            value="motorcycle"
            type="checkbox"
            className="checkbox-large"
          />
          <label htmlFor="transportType-motorcycle">Deux-roues</label>
        </div>
        <div className="flex flex-row items-center gap-2">
          <input
            id="transportType-publicTransport"
            onChange={handleTransportChange}
            name="transportType"
            value="publicTransport"
            type="checkbox"
            className="checkbox-large"
          />
          <label htmlFor="transportType-publicTransport">
            Transport en commun
          </label>
        </div>
        <div className="flex flex-row items-center gap-2">
          <input
            id="transportType-bike"
            onChange={handleTransportChange}
            name="transportType"
            value="bike"
            type="checkbox"
            className="checkbox-large"
          />
          <label htmlFor="transportType-bike">Vélo</label>
        </div>
        <div className="flex flex-row items-center gap-2">
          <input
            id="transportType-car"
            onChange={handleTransportChange}
            name="transportType"
            value="car"
            type="checkbox"
            className="checkbox-large"
          />
          <label htmlFor="transportType-car">Voiture</label>
        </div>
      </div>
      {error.transportType && (
        <Text className="text-sm text-red-500">{error.transportType}</Text>
      )}
    </div>
  );
};
