// Icons
import { ArrowLeft2, Message } from "iconsax-react";

// Components
import { BlocTitle } from "../../../components/Bloc/Title";
import Chat from "../../../components/Chat/Chat";
import { LastDocumentsAnimated } from "../../../components/Documents/LastDocuments";
import ChatConversationsPanel from "../../../components/Message/ChatConversationsPanel";
import Title from "../../../components/UI/Title";

// Require
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";

// Context
import { useChat } from "../../../context/Chat/ChatContext";

// Utils
import { useUI } from "../../../utils/context/UI";

const Messages = () => {
  // TODO: Searchbar for conversations

  const { lastDocumentsIsOpen, toggleLastDocuments } = useUI();
  const {
    conversations,
    fetchConversations,
    setIsExpertMode,
    activeConversation,
    setActiveConversation
  } = useChat();

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    if (activeConversation === undefined && conversations?.length) {
      setActiveConversation(conversations?.[0]);
    }
  }, [conversations]);

  useEffect(() => {
    setIsExpertMode(false);
    return () => {
      setIsExpertMode(true);
    };
  }, [activeConversation]);

  return (
    <div className="flex h-full flex-col justify-between gap-12">
      <div className="flex grow flex-col gap-4 rounded-lg border p-4 shadow-lg md:gap-8">
        <div className="max-md:hidden">
          <div className="flex flex-row items-center gap-2">
            <Message size={18} />
            <Title type="bloc">Messagerie</Title>
          </div>
        </div>
        <div className={`flex ${activeConversation ? "" : "hidden"}`}>
          <div
            className="flex cursor-pointer items-center rounded-lg bg-blueViolet-50 p-2 pr-4 md:hidden"
            onClick={() => setActiveConversation(undefined)}
          >
            <BlocTitle>
              <ArrowLeft2 size={18} />
              <Title type="bloc">Messagerie</Title>
            </BlocTitle>
          </div>
        </div>
        <div className="flex h-full flex-wrap gap-8">
          <div className={` ${activeConversation && "max-md:hidden"}`}>
            <ChatConversationsPanel />
          </div>
          <Chat
            ldStatic={false}
            OpenLD={toggleLastDocuments}
            onExit={() => setActiveConversation(undefined)}
          />
          {conversations && (
            <AnimatePresence>
              {lastDocumentsIsOpen && (
                <div className="max-md:hidden">
                  <LastDocumentsAnimated button={false} />
                </div>
              )}
            </AnimatePresence>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
