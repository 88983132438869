import Text from "../../UI/Text";

const Card = () => {
  return (
    <div className="rounded-lg border p-4">
      <Text>
        Le paiement par carte bancaire n'est pas disponible pour le moment.
      </Text>
    </div>
  );
};

export default Card;
