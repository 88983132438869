// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

// Context
import { useUI } from "../../../../utils/context/UI";

// Components
import Text from "../../../../components/UI/Text";
import One from "./Part1/One";
import Three from "./Part1/Three";
import Two from "./Part1/Two";
import Five from "./Part2/Five";
import Four from "./Part2/Four";

// Helpers
import { useSearchParams } from "react-router-dom";
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../utils/helpers/localStorage";

const Company = () => {
  const { step, direction, specificStep, resetStep } = useUI();

  // Nombre d'étapes par partie
  const stepByPart = [2, 2];

  const [searchParams] = useSearchParams();
  const redirectStep = searchParams.get("step");

  const [progressValue, setProgressValue] = useState({
    part1: 0,
    part2: 0
  });

  useEffect(() => {
    const localStep = getLocalStorage("companyRegisterStep");
    if (localStep) {
      specificStep(Number(localStep));
    } else {
      resetStep();
    }
  }, []);

  useEffect(() => {
    if (redirectStep) {
      const localData = [{ key: "companyRegisterStep", value: "2" }];
      setLocalStorage(localData);
      specificStep(Number(redirectStep));

      // Supprimer le paramètre "redirectStep" de l'URL
      const currentUrlParams = new URLSearchParams(window.location.search);

      // Vérifie si le paramètre existe et le supprime
      if (currentUrlParams.has("step")) {
        currentUrlParams.delete("step");

        // Mettre à jour l'URL sans recharger la page
        const newUrl = `${window.location.pathname}?${currentUrlParams.toString()}`;
        window.history.replaceState(null, "", newUrl);
      }
    }
  }, [redirectStep]);

  useEffect(() => {
    // Calculer la partie actuelle et la progression
    let currentPart = 1;
    let accumulatedSteps = 0;

    for (let i = 0; i < stepByPart.length; i++) {
      accumulatedSteps += stepByPart[i];
      if (step <= accumulatedSteps) {
        currentPart = i + 1;
        break;
      }
    }

    // Mettre à jour la barre de progression en fonction de la partie actuelle
    const newProgress = {
      part1:
        currentPart >= 1
          ? Math.min(((step - 1) / stepByPart[0]) * 100, 100)
          : 0,
      part2:
        currentPart >= 2
          ? Math.min(((step - stepByPart[0] - 1) / stepByPart[1]) * 100, 100)
          : 0,
      part3:
        currentPart >= 3
          ? Math.min(
              ((step - stepByPart[0] - stepByPart[1] - 1) / stepByPart[2]) *
                100,
              100
            )
          : 0,
      part4:
        currentPart >= 4
          ? Math.min(
              ((step - stepByPart[0] - stepByPart[1] - stepByPart[2] - 1) /
                stepByPart[3]) *
                100,
              100
            )
          : 0
    };

    setProgressValue(newProgress);
  }, [step]);

  const pageTransition = {
    initial: { opacity: 0, x: direction === "forward" ? "100%" : "-100%" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: direction === "forward" ? "-100%" : "100%" },
    transition: { duration: 0.3 }
  };

  const steps = [
    { id: 0, step: 1, element: <One /> },
    { id: 1, step: 2, element: <Two /> },
    { id: 2, step: 3, element: <Three /> },
    { id: 3, step: 4, element: <Four /> },
    { id: 4, step: 5, element: <Five /> }
  ];

  return (
    <div className="flex w-full grow flex-col justify-center">
      <div className="flex max-h-[calc(100vh-108px)] min-h-[calc(100vh-108px)] w-full flex-col gap-8 overflow-scroll max-md:pb-[108px] md:gap-8 md:p-8">
        <div className="flex items-center justify-center p-4 md:p-0">
          <div
            id="progressBar"
            className="flex min-w-[50%] flex-row justify-center gap-1 md:max-w-[50%] md:gap-4"
          >
            <div id="progressBarPart" className="flex flex-1 flex-row gap-6">
              <div className="flex w-full flex-col items-center gap-2">
                <div className="h-4 w-full rounded-full bg-blueViolet-100">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${progressValue.part1}%` }}
                    className="h-4 rounded-full bg-blueViolet-600"
                  />
                </div>
                <div
                  className={`h-2 w-2 rounded-full ${progressValue.part1 === 100 ? "bg-blueViolet-600" : "bg-blueViolet-100"}`}
                />
                <Text
                  className={`text-center text-sm ${progressValue.part1 === 100 ? "text-blueViolet-600" : "text-blueViolet-100"}`}
                >
                  Informations personnelles
                </Text>
              </div>
            </div>
            <div id="progressBarPart" className="flex flex-1 flex-row gap-6">
              <div className="flex w-full flex-col items-center gap-2">
                <div className="h-4 w-full rounded-full bg-blueViolet-100">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${progressValue.part2}%` }}
                    className="h-4 rounded-full bg-blueViolet-600"
                  />
                </div>
                <div
                  className={`h-2 w-2 rounded-full ${
                    progressValue.part2 === 100
                      ? "bg-blueViolet-600"
                      : "bg-blueViolet-100"
                  }`}
                />
                <Text
                  className={`text-center text-sm ${
                    progressValue.part2 === 100
                      ? "text-blueViolet-600"
                      : "text-blueViolet-100"
                  }`}
                >
                  A propos de l'entreprise
                </Text>
              </div>
            </div>
          </div>
        </div>
        <AnimatePresence mode="wait">
          <div className="flex items-center justify-center">
            <motion.div
              key={steps[step]?.id || 0}
              initial={pageTransition.initial}
              animate={pageTransition.animate}
              exit={pageTransition.exit}
              transition={pageTransition.transition}
              className="flex w-full grow flex-col items-center justify-center gap-8 p-4 md:p-0"
            >
              {steps[step]?.element}
            </motion.div>
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Company;
