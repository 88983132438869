// RateInput.tsx

import React from "react";
import Text from "../../../UI/Text";

// Types
interface JobCreateType {
  rate?: number;
  // Add other fields if necessary
}

interface ErrorType {
  rate?: string;
  // Add other fields if necessary
}

interface RateInputProps {
  data: JobCreateType;
  setData: React.Dispatch<React.SetStateAction<JobCreateType>>;
  errors: ErrorType;
  setErrors: React.Dispatch<React.SetStateAction<ErrorType>>;
}

const RateInput: React.FC<RateInputProps> = ({
  data,
  setData,
  errors,
  setErrors
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: Number(value)
    }));
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4">
        <div className="flex flex-1 flex-col gap-2">
          <Text className="text-xl font-semibold">
            Taux horaire pour l'étudiant
          </Text>
          {errors.rate && <Text className="text-red-500">{errors.rate}</Text>}
          <div className={`flex flex-row items-center gap-4`}>
            <input
              placeholder="Entrer le prix à l'heure"
              onChange={(e) => {
                if (Number(e.target.value) >= 15)
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    rate: ""
                  }));
                else
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    rate: "Le taux horaire doit être supérieur ou égal à 15€"
                  }));
                handleChange(e);
              }}
              name="rate"
              value={data.rate ?? ""}
              min={15}
              type="number"
              className={`w-[80px] rounded-lg border border-blueViolet-200 p-2 focus:outline-blueViolet-600 ${errors.rate && "border-red-500"}`}
            />
            <Text className="text-sm text-withCustom-500">
              &euro; par heure
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateInput;
