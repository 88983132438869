// Requires
import { fetchApi } from "../Misc/api";

// Retrieve all jobs (page, limit, filters)
export const getJobs = async (
  page: number,
  limit: number,
  status?: string | null,
  date?: string | null,
  search?: string | null,
  startingType?: string | null
) => {
  let url = "/job?page=" + page + "&limit=" + limit;

  if (status) url += "&status=" + status;
  if (date) url += "&date=" + date;
  if (search) url += "&search=" + search;
  if (startingType) url += "&startingType=" + startingType;

  const response = await fetchApi(url, "GET");
  if (response.error) return false;

  return response;
};

// Set a students into a job shortlist
export const setInShortlist = async (jobId: number, studentId: number[]) => {
  const data = { studentIds: [...studentId] };

  const response = await fetchApi(`/job/${jobId}/select`, "POST", data);
  if (response.error) return false;

  return true;
};

// Unset a student from a job shortlist
export const unsetInShortlist = async (jobId: number, studentId: number) => {
  const response = await fetchApi(
    `/job/${jobId}/unselect/${studentId}`,
    "POST"
  );

  if (response.error) return false;

  return;
};

// Recommend a job to a students
export const recommendJobToStudents = async (
  jobId: number,
  studentsIds: number[],
  notification?: { email: boolean; sms: boolean }
) => {
  const data = {
    studentsIds: studentsIds,
    notification: notification
  };

  const response = await fetchApi(`/job/${jobId}/recommend`, "POST", data);

  if (response.error) return false;

  return true;
};

// Validate a job
export const validateJob = async (jobId: number) => {
  const response = await fetchApi(`/job/${jobId}/accept`, "PATCH");
  if (response.error) return false;

  return true;
};

// Delete a specific job (company user account)
export const deleteJob = async (jobId: number) => {
  const response = await fetchApi(`/job/${jobId}`, "DELETE");
  if (response.error) return false;

  return true;
};
