// Require
import { useEffect, useState } from "react";

// Icons
import {
  ArrowUp2,
  Briefcase,
  Building4,
  ConvertCard,
  Edit,
  Home,
  InfoCircle,
  LogoutCurve,
  Messages1,
  SearchNormal1,
  Setting,
  User
} from "iconsax-react";

// Hooks
import usePathname from "../../../hooks/usePathname";

// Components
import { HorizontalDivider } from "../../UI/Divider";
import URL from "../../UI/URL";
import { NeedHelp, NeedHelpMobile } from "../NeedHelp";

// Utils
import { useMobile } from "../../../utils/mobile/responsive";

// Icon Size
const size: number = 18;

// Type
type LinksType = {
  name: string;
  path?: string;
  icon?: React.ReactElement;
  sub?: LinksType[];
  extern?: boolean;
};

const CompagnyMenu = ({ isOpen }: { isOpen: boolean }) => {
  const { pathname } = usePathname();
  const isMobile = useMobile();

  const [subMenuIsOpen, setSubMenuIsOpen] = useState<boolean>(false);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => window.removeEventListener("resize", updateScreenHeight);
  }, []);

  const MainLinks: LinksType[] = [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: (
        <Home
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Publier une mission",
      path: "/company/jobs/publier-une-mission",
      icon: (
        <Edit
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Chercher un talent",
      path: "/company/find-talents",
      icon: (
        <SearchNormal1
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Gérer mes missions",
      icon: (
        <Briefcase
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      ),
      sub: [
        { name: "Toutes mes missions", path: "/company/manages-jobs" },
        {
          name: "Proposition reçues",
          path: "/company/manages-jobs/candidatures"
        }
      ]
    },
    {
      name: "Messagerie",
      path: "/messages",
      icon: (
        <Messages1
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    }
  ];

  const SecondaryLinks: LinksType[] = [
    {
      name: "Mon entreprise",
      path: "/company/company",
      icon: (
        <Building4
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Mon plan Juwa",
      path: "/company/subscription",
      icon: (
        <ConvertCard
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Mon compte",
      path: "/company/account",
      icon: (
        <Setting
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    }
  ];

  const OthersLinks: LinksType[] = [
    {
      name: "Déconnexion",
      path: "/auth/logout",
      icon: (
        <LogoutCurve
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Besoin d'aide ?",
      path: "https://juwa.co/help",
      extern: true,
      icon: (
        <InfoCircle
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    }
  ];

  const handleIsOpen = () => {
    setSubMenuIsOpen(!subMenuIsOpen);
  };

  return (
    <div className="flex grow flex-col justify-between">
      <div className="flex flex-col gap-4 md:gap-6">
        <ul className="flex flex-col gap-1 overflow-hidden md:gap-4">
          {MainLinks.map((item) => (
            <li key={item.name} className="cursor-pointer">
              <URL
                callback={handleIsOpen}
                target={item.path}
                sub={item.sub}
                classname={`flex group-hover:text-blueViolet-600 flex-row flex-nowrap items-center p-2 ${
                  pathname === item.path &&
                  "bg-blueViolet-200 rounded-lg text-blueViolet-600"
                }`}
                style={{
                  gap: isOpen ? 24 : 0,
                  justifyContent: isOpen ? "start" : "center"
                }}
              >
                {item.icon}
                {isOpen && <span className="text-nowrap">{item.name}</span>}
                {item.sub && isOpen && (
                  <ArrowUp2
                    size={18}
                    className={
                      !subMenuIsOpen
                        ? "rotate-180 duration-100"
                        : "rotate-0 duration-100"
                    }
                  />
                )}
              </URL>
            </li>
          ))}
        </ul>
        <HorizontalDivider />
        <ul className="flex flex-col gap-1 overflow-hidden md:gap-4">
          {SecondaryLinks.map((item) => (
            <li key={item.name}>
              <URL
                target={item.path}
                sub={item.sub}
                classname={`flex group-hover:text-primaryPurple flex-row flex-nowrap items-center p-2 ${
                  pathname === item.path && "bg-backgroundGray rounded-lg"
                }`}
                style={{
                  gap: isOpen ? 24 : 0,
                  justifyContent: isOpen ? "start" : "center"
                }}
              >
                {item.icon}
                {isOpen && <span className="text-nowrap">{item.name}</span>}
              </URL>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col gap-6">
        {isOpen ? (
          isMobile ? (
            <NeedHelpMobile />
          ) : screenHeight < 950 ? (
            <NeedHelpMobile />
          ) : (
            <NeedHelp />
          )
        ) : null}
        <ul className="flex flex-col gap-1 overflow-hidden md:gap-4">
          {!isOpen && !isMobile && (
            <div className="flex flex-row flex-nowrap items-center justify-center rounded-lg bg-blueViolet-600 p-2 text-white">
              <User
                size={16}
                className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
              />
            </div>
          )}
          {OthersLinks.map((item) => (
            <li key={item.name}>
              <URL
                target={item.path}
                extern={item.extern}
                sub={item.sub}
                classname="flex flex-row flex-nowrap items-center p-1"
                style={{
                  gap: isOpen ? 24 : 0,
                  justifyContent: isOpen ? "start" : "center"
                }}
              >
                {item.icon}
                {isOpen && <span className="text-nowrap">{item.name}</span>}
              </URL>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CompagnyMenu;
