// Components
import Content from "../../Bloc/Content";
import { BlocTitle } from "../../Bloc/Title";
import { HorizontalDivider } from "../../UI/Divider";
import Text from "../../UI/Text";
import Title from "../../UI/Title";

// Types
import { ProposalQuotation } from "../../../types/proposal";

const Estimation = ({ data }: { data: ProposalQuotation[] }) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4">
      <BlocTitle>
        <Title type="bloc">Estimation chiffrée</Title>
      </BlocTitle>
      <Content>
        {data.map((item, index) => (
          <div key={index} className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col gap-1">
                <Text className="font-semibold">Etape {index + 1}</Text>
              </div>
              <div className="flex flex-row gap-1">
                <Text>{item.quantity} x</Text>
                <Text>{item.price}&euro;</Text>
              </div>
            </div>
            {data.length - 1 !== index && <HorizontalDivider />}
          </div>
        ))}
        <HorizontalDivider />
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <Text className="font-semibold">TOTAL</Text>
          </div>
          <Text className="font-semibold">
            {data.reduce((acc, cur) => acc + (cur.totalPrice ?? 0), 0)}&euro;
          </Text>
        </div>
      </Content>
    </div>
  );
};

export default Estimation;
