import { useState } from "react";
import Text from "../UI/Text";
import { Desktop, Mobile } from "../../utils/mobile/responsive";
import DropdownItem from "./DropdownItem"; // Import the updated DropdownItem

// Define the type for filters
export type FilterType = {
  name: string;
  label: string;
  type: "select" | "multiselect" | "number" | "date" | "text" | "range";
  options?: string[];
  optionsLabels?: string[];
  withSearch?: boolean;
};

// Filters Component
const Filters = ({
  filters,
  handleUpdateFilter
}: {
  filters: FilterType[];
  handleUpdateFilter?: (type: string, value: string) => void;
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [filterValues, setFilterValues] = useState<{ [key: string]: string }>(
    {}
  );
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const DEBOUNCE_DELAY = 500;

  const handleDropdownClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const setFilter = (
    name: string,
    value: string,
    field?: "min" | "max",
    list?: boolean
  ) => {
    if (field) {
      // For range filters, store min and max separately
      const key = `${field}${name.charAt(0).toUpperCase() + name.slice(1)}`;
      setFilterValues((prevValues) => ({
        ...prevValues,
        [key]: value
      }));
      if (handleUpdateFilter) {
        handleUpdateFilter(key, value);
      }
    } else if (name === "search") {
      setIsLoading(true);
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      const newTimer = setTimeout(() => {
        setFilterValues((prevValues) => ({
          ...prevValues,
          [name]: value
        }));
        setIsLoading(false);
        if (handleUpdateFilter) {
          handleUpdateFilter(name, value);
        }
      }, DEBOUNCE_DELAY);

      setDebounceTimer(newTimer);
    } else {
      if (list) {
        value = "[" + value + "]"; // Convert the value to a list
      }
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }));
      if (handleUpdateFilter) {
        handleUpdateFilter(name, value);
      }
    }
  };

  const resetFilters = () => {
    setFilterValues({});
    if (handleUpdateFilter) {
      handleUpdateFilter("reset", "");
    }
  };

  return (
    <div className="flex grow flex-col items-center justify-between gap-3 md:flex-row md:gap-0">
      <div className="flex flex-row items-center gap-4 max-md:flex-col">
        <div className="flex w-fit flex-row rounded-lg border >:w-1/2 >:flex-1 max-md:flex-wrap">
          {filters.map((filter, index) => {
            const isOpen = openIndex === index;
            return (
              <DropdownItem
                key={`desktop-${filter.name}`}
                filter={filter}
                index={index}
                isOpen={isOpen}
                handleDropdownClick={handleDropdownClick}
                setFilter={setFilter}
                value={{
                  min:
                    filterValues[
                      `min${filter.name.charAt(0).toUpperCase() + filter.name.slice(1)}`
                    ] || "",
                  max:
                    filterValues[
                      `max${filter.name.charAt(0).toUpperCase() + filter.name.slice(1)}`
                    ] || "",
                  value: filterValues[filter.name] || ""
                }}
              />
            );
          })}
        </div>
        {filters.length > 0 && (
          <div onClick={resetFilters}>
            <Text className="cursor-pointer text-sm text-textGray hover:text-blueViolet-600">
              Réinitialiser les filtres
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
