// Requires
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";

dayjs.extend(relativeTime);
dayjs.locale("fr");

const formatMessageDate = (
  createdAt: string | number | dayjs.Dayjs | Date | null | undefined
) => {
  const now = dayjs();
  const created = dayjs(createdAt);
  const diffInHours = now.diff(created, "hour", true); // true for float values

  if (diffInHours < 24) {
    return created.format("HH:mm"); // Display time in 24-hour format
  } else {
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays}j`; // Append 'j' for days in French
  }
};

const MessageDate = ({
  createdAt,
  className
}: {
  createdAt: Date | string | number | dayjs.Dayjs;
  className?: string;
}) => {
  return <span className={className}>{formatMessageDate(createdAt)}</span>;
};

export default MessageDate;
