export const BlocTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row items-center gap-2">{children}</div>
    </div>
  );
};

export const BlocTitleWithButton = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return <div className="flex flex-row justify-between">{children}</div>;
};
