// Icons
import { Edit, Location, Map, Routing, Share, Star1 } from "iconsax-react";

// Requires
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// Services
import { getSpecificUser, getUser } from "../../../../../services/User/user";

// Components
import Certification from "../../../../../components/Profil/Certification";
import Disponibility from "../../../../../components/Profil/Disponibility";
import Experience from "../../../../../components/Profil/Experience";
import Formation from "../../../../../components/Profil/Formation";
import Mission from "../../../../../components/Profil/Mission";
import Presentation from "../../../../../components/Profil/Presentation";
import Recommendation from "../../../../../components/Profil/Recommendation";
import Skill from "../../../../../components/Profil/Skill";
import Button from "../../../../../components/UI/Button";
import Tag from "../../../../../components/UI/Tag";
import Text from "../../../../../components/UI/Text";
import Title from "../../../../../components/UI/Title";
import Avatar from "../../../../../components/UI/Avatar/Avatar";

// Types
import { User } from "../../../../../types/user";

// Helpers
import { getDegreeName } from "../../../../../utils/helpers/getDegreeName";

// Context
import { useFloatingNotification } from "../../../../../context/FloatingNotification";

// Utils
import { useUser } from "../../../../../utils/store/userStore";

const Profil = () => {
  const { id } = useParams();

  const _ADMIN_MODE = useUser((state) => state.userdata?.roleName === "admin");
  const _COMPANY_MODE = useUser(
    (state) => state.userdata?.roleName === "company"
  );

  const [data, setData] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { toggleFloatingNotification } = useFloatingNotification();

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const response =
        id === undefined ? await getUser() : await getSpecificUser(Number(id));
      setData(response);
      setIsLoading(false);
    };
    fetch();
  }, [id]);

  const handleClickShare = () => {
    const url = window.location.origin + `/profil/${data?.slug}`;
    navigator.clipboard.writeText(url);
    toggleFloatingNotification(
      "Le lien de partage à été copié dans votre presse-papier."
    );
  };

  if (!isLoading && data) {
    return (
      <div className="flex flex-col gap-8">
        <div
          className="relative h-[250px] rounded-t-lg bg-cover bg-center"
          style={{ backgroundImage: `url('${data?.banner}')` }}
        >
          <div className="absolute right-4 top-4 flex flex-col gap-4">
            {!_ADMIN_MODE && !_COMPANY_MODE && (
              <Link to="edit">
                <Button className="" type="fullLite">
                  <Edit size={18} />
                  Modifier les informations
                </Button>
              </Link>
            )}
            <div onClick={handleClickShare} className="md:hidden">
              <Button type="full" className="flex items-center gap-2">
                <Share size={18} />{" "}
                {_ADMIN_MODE || _COMPANY_MODE
                  ? "Partager son profil"
                  : "Partager mon profil"}
              </Button>
            </div>
          </div>
        </div>
        <div className="z-40 mt-[-85px] flex flex-col gap-2 px-6">
          <Avatar
            link={data.image}
            className="!size-24 border-4 border-white shadow-lg"
          />
          <div className="flex flex-row items-center justify-between gap-4">
            <div className="flex flex-row items-center gap-4">
              <Title type="custom" classname="text-xl">
                {data?.firstName} {data?.lastName}
              </Title>
              <div className="flex flex-row items-center gap-2">
                <Text className="text-sm">{data?.publicMark}</Text>
                <Star1 size={18} />
              </div>
              <div className="flex flex-row items-center gap-2">
                {data?.languages.map((item) => (
                  <Tag key={item.id} className="text-blueViolet-600">
                    {item.title}
                  </Tag>
                ))}
              </div>
            </div>
            <div onClick={handleClickShare} className="max-md:hidden">
              <Button type="full" className="flex flex-row items-center gap-2">
                <Share size={18} />{" "}
                {_ADMIN_MODE || _COMPANY_MODE
                  ? "Partager son profil"
                  : "Partager mon profil"}
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-8 max-md:flex-wrap max-md:gap-2 max-md:gap-x-8">
              <div className="max-md:w-full max-md:font-bold">
                <Text>{data?.profileTitle}</Text>
              </div>

              <div className="flex flex-row items-center gap-2">
                <Routing size={18} />
                <Text className="text-sm">
                  {getDegreeName(data?.actualDegree)}
                </Text>
              </div>
              <div className="flex flex-row items-center gap-2">
                <Location size={18} />
                <Text className="text-sm">{data?.city}</Text>
              </div>
              <div className="flex flex-row items-center gap-2">
                <Map size={18} />
                <Text className="text-sm">
                  Préfére travailler{" "}
                  {data?.workMode === "onSite"
                    ? "sur site"
                    : data?.workMode === "remote"
                      ? "en télétravail"
                      : "en mode hybride"}
                </Text>
              </div>
            </div>
            {_ADMIN_MODE && (
              <Button type="full" className="w-fit">
                Proposer une mission à {data?.firstName}
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-row-reverse gap-8 max-md:hidden">
          <div className="flex grow flex-col gap-8">
            <Mission data={data?.completedMissions ?? []} />
            <Experience data={data?.experiences} />
            <Formation data={data?.education} />
          </div>
          <div className="flex min-w-[350px] max-w-[465px] flex-col gap-8">
            <Presentation data={data?.presentation} />
            <Skill data={data?.skills} />
            <Disponibility />
            <Certification data={data?.certifications} />
            <Recommendation data={data?.recommendations} />
          </div>
        </div>

        <div className="flex flex-col gap-4 md:hidden">
          <Presentation data={data?.presentation} />
          <Mission data={data?.completedMissions ?? []} />
          <Experience data={data?.experiences} />
          <Formation data={data?.education} />
          <Skill data={data?.skills} />
          <Disponibility />
          <Certification data={data?.certifications} />
          <Recommendation data={data?.recommendations} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full items-center justify-center">
      <div className="loader" />
    </div>
  );
};

export default Profil;
