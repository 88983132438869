import Text from "../../../UI/Text";
import Title from "../../../UI/Title";

const MessagesAndFiles = () => {
  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-col">
        <Title type="bloc">Messagerie et documents</Title>
        <Text className="text-sm">
          Rester au courant lorsque des personnes vous contactent par la
          messagerie à propos de vos candidatures
        </Text>
      </div>
      <div className="flex flex-col gap-4">
        <Text className="font-semibold">Je souhaite être alerté par :</Text>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-4">
            <div className="toggle-switch">
              <input
                className="toggle-input"
                defaultChecked={true}
                id="toggle"
                type="checkbox"
              />
              <label className="toggle-label" htmlFor="toggle" />
            </div>
            <Text>Email</Text>
          </div>
          {/* <div className="flex flex-row items-center gap-4">
            <div className="toggle-switch">
              <input className="toggle-input" id="toggle" type="checkbox" />
              <label className="toggle-label" htmlFor="toggle" />
            </div>
            <Text>SMS</Text>
          </div> */}
          <div className="flex flex-row items-center gap-4">
            <div className="toggle-switch">
              <input
                className="toggle-input"
                id="toggle"
                defaultChecked={true}
                type="checkbox"
              />
              <label className="toggle-label" htmlFor="toggle" />
            </div>
            <Text>Push</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesAndFiles;
