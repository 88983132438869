const DashboardLoading = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className="shimmer h-5 w-[60%] rounded-lg bg-gray-300" />
      <div className="flex h-fit flex-row gap-6">
        <div className="shimmer relative flex h-fit min-w-[calc(33%-10px)] flex-col gap-4 rounded-lg border shadow-lg">
          <div className="h-[200px] w-full rounded-t-lg object-cover object-center" />
          <div className="flex flex-col gap-4 p-4"></div>
        </div>
        <div className="shimmer relative flex h-fit min-w-[calc(33%-10px)] flex-col gap-4 rounded-lg border shadow-lg">
          <div className="h-[200px] w-full rounded-t-lg object-cover object-center" />
          <div className="flex flex-col gap-4 p-4"></div>
        </div>
        <div className="shimmer relative flex h-fit min-w-[calc(33%-10px)] flex-col gap-4 rounded-lg border shadow-lg">
          <div className="h-[200px] w-full rounded-t-lg object-cover object-center" />
          <div className="flex flex-col gap-4 p-4"></div>
        </div>
      </div>
      <div className="flex flex-row gap-6">
        <div
          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
          className={`shimmer flex h-[500px] h-fit max-h-[250px] flex-1 flex-col gap-6 overflow-hidden rounded-lg border p-4 shadow-lg`}
        ></div>
        <div
          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
          className={`shimmer flex h-[500px] h-fit max-h-[250px] flex-1 flex-col gap-6 overflow-hidden rounded-lg border p-4 shadow-lg`}
        ></div>
      </div>
    </div>
  );
};

export default DashboardLoading;
