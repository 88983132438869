// Components
import { useState } from "react";
import AvatarChanger from "../../../../Misc/AvatarChanger";
import BannerChanger from "../../../../Misc/BannerChanger";
import Button from "../../../../UI/Button";
import Title from "../../../../UI/Title";
import { updateCompanyProfile } from "../../../../../services/Company/company";
import { AnimatePresence } from "framer-motion";
import Highlight from "../../../../UI/Highlight";
import { useUser } from "../../../../../utils/store/userStore";

const VisualIdentity = ({ companyMode }: { companyMode?: boolean }) => {
  const [data, setData] = useState<{ image: File | null; banner: File | null }>(
    {
      image: null,
      banner: null
    }
  );

  const user = useUser((state) => state.userdata);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleFileAvatarChange = (image: File) => {
    setData({ ...data, image: image });
  };

  const handleFileBannerChange = (image: File) => {
    setData({ ...data, banner: image });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (data.image || data.banner) {
      const formData = new FormData();

      if (data.image) {
        formData.append("image", data.image);
      }

      if (data.banner) {
        formData.append("banner", data.banner);
      }

      const response = await updateCompanyProfile(formData);
      if (response) {
        setSuccess(true);
        setIsLoading(false);
        setTimeout(() => {
          setSuccess(false);
          setData({ image: null, banner: null });
          useUser.getState().getUser();
        }, 2000);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Identité visuelle de l'entreprise</Title>
      <div className="flex flex-col gap-8">
        <AvatarChanger
          companyMode={companyMode}
          callback={handleFileAvatarChange}
        />
        <BannerChanger
          companyMode={companyMode}
          callback={handleFileBannerChange}
        />
        <AnimatePresence>
          {success && (
            <Highlight type="Success">Modifications enregistrées</Highlight>
          )}
        </AnimatePresence>
        {(!isLoading && data.banner) || (!isLoading && data.image) ? (
          <div onClick={handleSubmit} className="flex justify-end">
            <Button type="full">Enregistrer</Button>
          </div>
        ) : (
          <div className="flex justify-end">
            <Button type="disabled">
              {!isLoading ? "Enregistrer" : <div className="loader" />}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VisualIdentity;
