// Requires
import { useState } from "react";

// Icons
import { CloseSquare } from "iconsax-react";

// Components
import Title from "../../UI/Title";
import Button from "../../UI/Button";

// Context
import { usePopup } from "../../../context/Popup";

// Services
import { createTicket } from "../../../services/Tickets/tickets";

// Types
type Errors = {
  type?: string;
  description?: string;
};

const Ticket = () => {
  const { closePopup } = usePopup();

  const [type, setType] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [errors, setErrors] = useState<Errors>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resError, setResError] = useState<string>("");

  const handleSubmit = async () => {
    const newErrors: Errors = {};
    setErrors(newErrors);
    setIsLoading(true);
    setResError("");

    if (!type) newErrors.type = "Vous devez indiquer le type de problème";
    if (!description)
      newErrors.description = "Vous devez indiquer une description";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    const data = {
      title: "testtest",
      content: description,
      type: type
    };

    const response = await createTicket(data);
    console.log(response);
    if (response) {
      setIsLoading(false);
      setDescription("");
      setErrors({});
      setType("");
      closePopup();
      return;
    }

    setResError("Une erreur est survenue");
    setIsLoading(false);
  };

  return (
    <div className="flex max-w-[550px] flex-col gap-8 p-4">
      <div className="flex flex-row items-center justify-between gap-8">
        <Title type="bloc">Signaler un problème</Title>
        <CloseSquare
          onClick={closePopup}
          size={18}
          className="cursor-pointer hover:text-blueViolet-600"
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label>Type de problème</label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            <option>Séléctionner le type de problème</option>
            <option value="PAYMENT">Problème de paiement</option>
            <option value="BUG">Bug sur la plateforme</option>
            <option value="UX">Amélioration de l'UX</option>
            <option value="QUESTION">J'ai une question</option>
            <option value="OTHER">Autre</option>
          </select>
          {errors?.type && (
            <p className="text-sm text-red-500">{errors.type}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label>Description</label>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder="Merci d'indiquer dans ce champs le plus de détails possible"
            className="textareaautoresize max-h-[200px] resize-none rounded-lg border p-2 focus:outline-blueViolet-600"
          />
          {errors?.description && (
            <p className="text-sm text-red-500">{errors.description}</p>
          )}
        </div>
        {resError && <p className="text-sm text-red-500">{resError}</p>}
        <div className="flex justify-end">
          {!type || !description ? (
            <Button type="disabled">Envoyer</Button>
          ) : (
            <div onClick={handleSubmit}>
              <Button type="full">
                {isLoading ? <div className="loader" /> : "Envoyer"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ticket;
