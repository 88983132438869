// Icons
import { Edit } from "iconsax-react";

// Components
import Text from "../../../UI/Text";

// Helpers
import { getDegreeName } from "../../../../utils/helpers/getDegreeName";

// Types
import type { JobCreateType } from "../../../../types/job";

const Profil = ({
  data,
  edit
}: {
  data: JobCreateType;
  edit: (id: number) => void;
}) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <Text className="text-xl font-semibold">Profil recherché</Text>
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex flex-col gap-2 md:flex-row md:items-center">
          <Text className="font-semibold">Métier :</Text>
          <Text className="rounded-lg bg-blueViolet-50 px-2 py-1 text-blueViolet-600">
            {data?.profileTitle}
          </Text>
        </div>
        <div
          onClick={() => edit(1)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex flex-col items-start gap-2 md:flex-row md:items-center">
          <Text className="font-semibold">Niveau d'étude :</Text>
          <Text className="rounded-lg bg-blueViolet-50 px-2 py-1 text-blueViolet-600">
            {getDegreeName(data?.degree)}
          </Text>
        </div>
        <div
          onClick={() => edit(1)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex flex-col flex-wrap items-start gap-2 md:flex-row md:items-center">
          <Text className="font-semibold">Domaine d'étude :</Text>
          {data?.categories?.map((item, _i) => (
            <Text
              key={_i}
              className="flex-nowrap rounded-lg bg-blueViolet-50 px-2 py-1 text-blueViolet-600 md:text-nowrap"
            >
              {item.title}
            </Text>
          ))}
        </div>
        <div
          onClick={() => edit(1)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex flex-col flex-wrap items-start gap-2 md:flex-row md:items-center">
          <Text className="font-semibold">Compétences :</Text>
          {data?.skills?.map((item) => (
            <Text
              key={item.id}
              className="flex-nowrap rounded-lg bg-blueViolet-50 px-2 py-1 text-blueViolet-600 md:text-nowrap"
            >
              {item.title}
            </Text>
          ))}
        </div>
        <div
          onClick={() => edit(2)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex flex-col flex-wrap items-start gap-2 md:flex-row md:items-center">
          <Text className="font-semibold">Langues parlées :</Text>
          {data?.languages?.map((item) => (
            <Text
              key={item.id}
              className="flex-nowrap rounded-lg bg-blueViolet-50 px-2 py-1 text-blueViolet-600 md:text-nowrap"
            >
              {item.title}
            </Text>
          ))}
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          onClick={() => edit(2)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
    </div>
  );
};

export default Profil;
