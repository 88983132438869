// Requires
import { useEffect, useState } from "react";

// Context
import { useChat } from "../../context/Chat/ChatContext";
import { useSocket } from "../../context/Socket";

// Components
import Text from "../UI/Text";

const UserPresence = () => {
  const { socket } = useSocket();
  const { activeUsers, userId } = useChat();

  // const [searchParams] = useSearchParams();
  // const userId = Number(searchParams.get("studentId"));

  const [isOnline, setIsOnline] = useState<boolean>(false);

  useEffect(() => {
    const userIsOnline = activeUsers.users.includes(userId ?? 0);
    setIsOnline(userIsOnline);
  }, [activeUsers, userId]);

  useEffect(() => {
    if (socket) {
      socket.on("connected", (data) => {
        setIsOnline(true);
      });

      socket.on("disconnected", (data) => {
        setIsOnline(false);
      });
    }
  }, [socket]);

  return (
    <div className="flex flex-row items-center gap-1">
      {isOnline ? (
        <>
          <div className="h-3 w-3 rounded bg-green-600" />
          <Text className="text-sm text-green-600">En ligne</Text>
        </>
      ) : (
        <>
          <div className="h-3 w-3 rounded bg-red-500" />
          <Text className="text-sm text-red-500">Hors ligne</Text>
        </>
      )}
    </div>
  );
};

export default UserPresence;
