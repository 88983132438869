// Requires
import { useEffect, useState } from "react";

// Components
import JobCardExtra from "../../../../components/Jobs/Card/JobCardExtra";
import Title from "../../../../components/UI/Title";

// Types
import { JobType } from "../../../../types/job";
import Text from "../../../../components/UI/Text";
import { getJobsFav } from "../../../../services/Job/job";

const Favoris = () => {
  const [data, setData] = useState<JobType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string>();

  useEffect(() => {
    const fetchFavoriteJobs = async () => {
      setIsLoading(true);
      const response = await getJobsFav(0, 10);
      if (response) {
        setData(response);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      return;
    };
    fetchFavoriteJobs();
  }, []);
  return (
    <div className="flex flex-col gap-24">
      <div className="flex flex-row items-center justify-between">
        <Title type="page">Les missions que vous avez enregistrées</Title>
      </div>
      <div className="flex flex-col gap-8">
        {data?.length && data.length > 0 ? (
          data.map((item) => <JobCardExtra key={item.id} item={item} />)
        ) : isLoading ? (
          <div className="flex h-fit w-full justify-center">
            <div className="loader" />
          </div>
        ) : (
          <div className="flex w-full items-center justify-center">
            {error ? (
              <Text className="text-red-500">{error}</Text>
            ) : (
              <Text>Aucune mission enregistrée.</Text>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Favoris;
