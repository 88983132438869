// Requires
import { fetchApi } from "../Misc/api";

export const companyPaymentBySEPA = async (
  jobId: number,
  data: {
    iban: string;
    bic: string;
    couponCode?: string;
    frequency?: number | string;
    plan?: string;
  },
  type?: "job" | "subscription"
) => {
  const url =
    type === "subscription"
      ? `/subscription/init`
      : `/job-hiring/${jobId}/start-payment`;

  if (type === "subscription") {
    if (data.frequency === "1") data.frequency = "MONTHLY";
    else if (data.frequency === "4") data.frequency = "QUARTERLY";
    else if (data.frequency === "12") data.frequency = "YEARLY";

    if (data.plan === "starter") data.plan = "STARTER";
    else if (data.plan === "business") data.plan = "BUSINESS";
    else if (data.plan === "business_plus") data.plan = "BUSINESSPLUS";
  }

  const response = await fetchApi(url, "POST", data);

  if (response.error) return false;
  return response;
};

// Get a resume of payment (Proposal)
export const getPaymentResume = async (id: number) => {
  const response = await fetchApi(`/job-hiring/${id}/payment-details`, "GET");
  if (response.error) return false;

  return response;
};

// Check if a reduction code is valid
export const checkReductionCode = async (code: string) => {
  const data = {
    couponCode: code
  };

  const response = await fetchApi(`/job-hiring/apply-coupon`, "POST", data);
  if (response.error) return false;

  return response;
};

// If youSign return a success
export const youSignSuccess = async (id: number, token: string) => {
  const data = {
    signToken: token
  };

  const response = await fetchApi(`/job-hiring/success/${id}`, "POST", data);

  if (response.error) return false;

  return true;
};

// youSign return a success (subscription)
export const subscriptionYouSignSuccess = async (token: string) => {
  const data: { signToken: string } = {
    signToken: token
  };

  const response = await fetchApi(
    "/subscription/signature-callback",
    "POST",
    data
  );

  if (response.error) return false;

  return true;
};

// Cancel a subscription
export const cancelSubscription = async (data: {
  data: { reason: string };
}) => {
  const response = await fetchApi("/subscription/cancel", "POST", data);
  if (response.error) return false;

  return true;
};
