// Icons
import { CloseCircle } from "iconsax-react";

// Helpers
import { _getIconForFileType } from "../../utils/helpers/getIconForFileType";

// Components
import Text from "../UI/Text";

// Types
type FileData = {
  fileName: string;
  fileContent?: string;
  fileType: string;
};

const FileCard = ({
  file,
  removeFile
}: {
  file: FileData;
  removeFile?: () => void;
}) => {
  const formatedFile = file.fileName.split(".");

  return (
    <div className="relative flex w-[calc(50%-4px)] min-w-[calc(50%-4px)] flex-grow flex-row items-center gap-3 rounded-lg border px-3 py-2">
      <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-blueViolet-50">
        {_getIconForFileType(file.fileType)}
      </div>
      <div className="flex flex-col">
        <Text className="text-sm font-semibold">{formatedFile[0]}</Text>
        <Text className="text-sm text-withCustom-500">
          {formatedFile[1].toUpperCase()}
        </Text>
      </div>
      {removeFile && (
        <div className="absolute right-1 top-1">
          <CloseCircle
            onClick={removeFile}
            size={18}
            className="cursor-pointer text-withCustom-500 hover:text-blueViolet-600"
          />
        </div>
      )}
    </div>
  );
};

export default FileCard;
