// Icons
import { InfoCircle, TickCircle } from "iconsax-react";

// Utils
import { Desktop, Mobile } from "../../utils/mobile/responsive";

const SectionHeader = ({ title }: { title: string }) => (
  <div className="flex flex-row items-start gap-1 rounded-lg bg-blueViolet-200 p-4">
    <span className="text-start text-lg font-semibold">{title}</span>
  </div>
);
interface InfoItemProps {
  item: { plan?: string; content?: string; type?: string };
}

const PlanTitle = ({ plan }: { plan?: string }) => {
  const planClass = "text-black text-md font-semibold";

  return (
    <div>
      {plan === STANDARD ? (
        <span className={planClass}>Standard</span>
      ) : plan === STARTER ? (
        <span className={planClass}>Starter</span>
      ) : plan === BUSINESS ? (
        <span className={planClass}>Business</span>
      ) : plan === BUSINESS_PLUS ? (
        <span className={planClass}>Business+</span>
      ) : null}
    </div>
  );
};

const Item = ({ item }: InfoItemProps) => (
  <div className="flex w-[90%] flex-row justify-between rounded-lg p-4 text-start md:w-[calc(20%-13px)] md:flex-col">
    <Mobile>
      <PlanTitle plan={item.plan} />
    </Mobile>
    {item.content}
  </div>
);

const Title = ({ title, line }: { title?: string; line?: boolean }) => (
  <>
    <Mobile>
      {line && <div className="my-4 w-full border-t border-gray-300"></div>}{" "}
      <div className="flex w-[95%] justify-between gap-20 rounded-lg p-4 text-start">
        {title}
        <InfoCircle color="black" size={18} />
      </div>
    </Mobile>
    <Desktop>
      <div className="flex w-full items-center justify-start rounded-lg p-4 text-start md:w-[calc(20%-13px)]">
        {title}
      </div>
    </Desktop>
  </>
);

const InfoItem = ({ item }: InfoItemProps) => (
  <div className="flex w-[90%] flex-row justify-between rounded-lg p-4 text-start md:w-[calc(20%-13px)] md:flex-col">
    <Mobile>
      <PlanTitle plan={item.plan} />
    </Mobile>
    <TickCircle color="green" size={18} />
  </div>
);

export const HorizontalDivider = () => (
  <div className="my-4 w-full border-t border-gray-300"></div>
);

interface SectionProps {
  data: { type?: string; content?: string }[];
}

const Section = ({ data }: SectionProps) => (
  <div className="flex flex-col flex-wrap items-center justify-center gap-0 text-nowrap md:flex-row md:gap-4 md:text-wrap">
    {data.map((item, index) =>
      item.type === "info" ? (
        <InfoItem item={item} key={index} />
      ) : item.type === "title" ? (
        <Title
          title={item.content}
          key={index}
          line={data[index - 1] && data[index - 1]?.type !== "title"}
        />
      ) : (
        <Item key={index} item={item} />
      )
    )}
  </div>
);
// Section.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       type: PropTypes.string,
//       content: PropTypes.string
//     })
//   ).isRequired
// };

// Define plan names as constants
const STANDARD = "Standard";
const STARTER = "Starter";
const BUSINESS = "Business";
const BUSINESS_PLUS = "Business+";

const sectionsData = [
  {
    title: "Plateforme",
    data: [
      { type: "title", content: "Commision" }, // Feature title
      { plan: STANDARD, content: "20%" }, // Standard plan entry
      { plan: STARTER, content: "5%" }, // Starter plan entry
      { plan: BUSINESS, content: "5%" }, // Business plan entry
      { plan: BUSINESS_PLUS, content: "-%" }, // Business+ plan entry

      { type: "title", content: "Chat, appel et visios sécurisés" }, // Feature title
      { plan: STANDARD, type: "info" }, // Standard plan entry
      { plan: STARTER, type: "info" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Paiements sécurisés" }, // Feature title
      { plan: STANDARD, type: "info" }, // Standard plan entry
      { plan: STARTER, type: "info" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" } // Business+ plan entry
    ]
  },
  {
    title: "Recrutement",
    data: [
      { type: "title", content: "Suivi de mission" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Formalisation du besoin" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Profils recommandés" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Nombre de candidatures" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" } // Business+ plan entry
    ]
  },
  {
    title: "Administratif",
    data: [
      { type: "title", content: "Devis & factures automatisés" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "NDA" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, content: "-" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Gestion des droits d'auteurs" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Gestion de la confidentialité" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Gestion des freelances" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Conseiller dédié" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, type: "info" }, // Business plan entry
      { plan: BUSINESS_PLUS, type: "info" }, // Business+ plan entry

      { type: "title", content: "Nombre de missions pilotées" }, // Feature title
      { plan: STANDARD, content: "-" }, // Standard plan entry
      { plan: STARTER, content: "-" }, // Starter plan entry
      { plan: BUSINESS, content: "3" }, // Business plan entry
      { plan: BUSINESS_PLUS, content: "10" } // Business+ plan entry
    ]
  }
];

export const PlanTable = () => (
  <div className="flex flex-col gap-4">
    {sectionsData.map((section, index) => (
      <div key={index}>
        <SectionHeader title={section.title} />
        <Section data={section.data} />
      </div>
    ))}
  </div>
);
