// Icons
import { ImportCircle } from "iconsax-react";

// Requires
import { useEffect, useRef, useState } from "react";

// Components
import FileCard from "../../../../../../components/Chat/FileCard";
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

// Types
import type { JobCreateType } from "../../../../../../types/job";

// Rich text editor
import ReactQuill from "react-quill";
import "../../../../../../styles/snow-override-react-quill-theme.css";

interface Props {
  updateFormData: (newData: { [key: string]: any }) => void;
}

type Error = {
  title?: string;
  context?: string;
  objectives?: string;
  deliverables?: string;
};

const Step4 = ({ updateFormData }: Props) => {
  const [errors, setErrors] = useState<Error>({});

  const [data, setData] = useState<JobCreateType>({
    title: "",
    context: "",
    objectives: "",
    deliverables: "",
    attachments: []
  });

  const { handleNextStep, handlePrevStep, step } = useUI();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [focusedField, setFocusedField] = useState<string | null>(null);

  useEffect(() => {
    const localData = getLocalStorage("step5");
    if (localData) {
      const formatedLocalData: JobCreateType = JSON.parse(localData);
      setData({
        title: formatedLocalData.title || "",
        context: formatedLocalData.context || "",
        objectives: formatedLocalData.objectives || "",
        deliverables: formatedLocalData.deliverables || "",
        attachments: formatedLocalData.attachments || []
      });
    }
  }, []);

  const handleChange = (name: string, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFile = () => {
    if (inputRef) {
      inputRef.current?.click();
    }
  };

  const handleFileUpdate = () => {
    if (inputRef.current?.files) {
      const fileArray = Array.from(inputRef.current.files);
      setData((prev) => ({
        ...prev,
        attachments: fileArray
      }));
    }
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const removeFile = (index: number) => {
    setData((prevData) => {
      const updatedAttachments = prevData.attachments?.filter(
        (_, i) => i !== index
      );
      return {
        ...prevData,
        attachments: updatedAttachments
      };
    });
  };

  const nextStep = () => {
    const newError: Error = {};

    // Validate Title
    if (!data.title || data.title.trim().length < 10) {
      newError.title = "Vous devez entrer un titre de minimum 10 caractères";
    }

    // Validate Context
    const contextText = data.context || ""; //getPlainText(data.context || "");
    if (!contextText || contextText.trim().length < 50) {
      newError.context =
        "Vous devez indiquer le contexte de la mission avec un minimum de 50 caractères";
    }

    // Validate Objectives
    const objectivesText = data.objectives || ""; //getPlainText(data.objectives || "");
    if (!objectivesText || objectivesText.trim().length < 50) {
      newError.objectives =
        "Vous devez indiquer les objectifs de la mission avec un minimum de 50 caractères";
    }

    // Validate Deliverables
    const deliverablesText = data.deliverables || ""; //getPlainText(data.deliverables || "");
    if (!deliverablesText || deliverablesText.trim().length < 50) {
      newError.deliverables =
        "Vous devez indiquer les livrables attendus en fin de mission avec un minimum de 50 caractères";
    }

    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      return;
    }

    const localData = [
      {
        key: "publishJobStep",
        value: "5"
      },
      {
        key: "step5",
        value: JSON.stringify({
          title: data.title,
          context: data.context,
          objectives: data.objectives,
          deliverables: data.deliverables
        })
      }
    ];

    setLocalStorage(localData);
    updateFormData(data);
    handleNextStep();
  };

  const infoTexts: { [key: string]: string } = {
    title: "Donnez un titre impactant à votre mission qui résume votre besoin",
    context:
      "Précisez dans quel contexte votre besoin se situe : renfort dans un projet plus large, besoin de déléguer votre business… Vous pouvez décrire plus précisément le profil dont vous avez besoin",
    objectives:
      "Définissez les objectifs qualitatifs pour cette mission : vous souhaitez que l’étudiant réalise votre prospection, qu’il réalise votre site web, qu’il réalise des visuels…",
    deliverables:
      "Définissez les critères de succès pour cette mission : Publication de votre site, livraison d’une documentation, publication de 5 posts Linkedin…"
  };

  // Quill Editor Modules and Formats (optional customization)
  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"]
    ]
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image"
  ];

  return (
    <div className="flex flex-col items-center justify-center gap-16 max-md:gap-8">
      <Title type="page">Description de la mission</Title>
      <div className="flex flex-col gap-16 max-md:gap-8 md:w-[70%]">
        <div className="flex flex-col gap-8">
          {/* Titre de la mission */}
          <div className="flex flex-col gap-4">
            <Text className="text-xl font-semibold">Titre de la mission</Text>

            {errors.title && (
              <Text className="text-sm text-red-500">{errors.title}</Text>
            )}

            <div className="flex w-full flex-row items-start gap-2 max-md:flex-col max-md:items-stretch">
              <input
                type="text"
                name="title"
                value={data.title}
                onChange={(e) => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    title: ""
                  }));
                  handleChange(e.target.name, e.target.value);
                }}
                onFocus={() => setFocusedField("title")}
                onBlur={() => setFocusedField(null)}
                placeholder="Indiquer le titre de la mission"
                className={`${
                  focusedField === "title" ? "basis-3/4" : "flex-1"
                } rounded-lg border p-2 focus:outline-blueViolet-600 ${
                  errors.title && "border-red-500"
                }`}
              />

              {focusedField === "title" && (
                <InfoTextComponent infoText={infoTexts["title"]} />
              )}
            </div>
          </div>

          {/* Contexte */}
          <div className="flex flex-col gap-4">
            <Text className="text-xl font-semibold">Contexte</Text>
            {errors.context && (
              <Text className="text-sm text-red-500">{errors.context}</Text>
            )}
            <div className="flex w-full flex-row items-start gap-2 max-md:flex-col max-md:items-stretch">
              <div
                className={`${
                  focusedField === "context"
                    ? "max-w-[100%] flex-1 md:max-w-[75%] md:basis-3/4"
                    : "max-w-[100%] flex-1"
                }`}
              >
                <div className="h-52 flex-1">
                  <ReactQuill
                    theme="snow"
                    value={data.context}
                    onChange={(content, delta, source, editor) => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        context: ""
                      }));
                      handleChange("context", content);
                    }}
                    onFocus={() => setFocusedField("context")}
                    onBlur={() => setFocusedField(null)}
                    placeholder="Entrer le contexte de la mission..."
                    modules={quillModules}
                    formats={quillFormats}
                    className={`h-40 ${errors.context ? "border-red-500" : ""}`}
                  />
                </div>
                <div className="flex justify-start p-2 max-md:mt-4">
                  <Text className="text-sm">
                    {(data.context || "").length}/5000 caractères
                    {/* getPlainText(data.context || "") */}
                  </Text>
                </div>
              </div>

              {focusedField === "context" && (
                <InfoTextComponent infoText={infoTexts["context"]} />
              )}
            </div>
          </div>

          {/* Objectifs */}
          <div className="flex flex-col gap-4">
            <Text className="text-xl font-semibold">Objectifs</Text>
            {errors.objectives && (
              <Text className="text-sm text-red-500">{errors.objectives}</Text>
            )}
            <div className="flex w-full flex-row items-start gap-2 max-md:flex-col max-md:items-stretch">
              <div
                className={`${
                  focusedField === "objectives"
                    ? "max-w-[100%] flex-1 md:max-w-[75%] md:basis-3/4"
                    : "max-w-[100%] flex-1"
                }`}
              >
                <div className="h-52 flex-1">
                  <ReactQuill
                    theme="snow"
                    value={data.objectives}
                    onChange={(content, delta, source, editor) => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        objectives: ""
                      }));
                      handleChange("objectives", content);
                    }}
                    onFocus={() => setFocusedField("objectives")}
                    onBlur={() => setFocusedField(null)}
                    placeholder="Entrer les objectifs de la mission..."
                    modules={quillModules}
                    formats={quillFormats}
                    className={`h-40 ${
                      errors.objectives ? "border-red-500" : ""
                    }`}
                  />
                </div>
                <div className="flex justify-start p-2 max-md:mt-4">
                  <Text className="text-sm">
                    {(data.objectives || "").length}/5000 caractères
                    {/* {getPlainText(data.objectives || "").length}/5000 caractères */}
                  </Text>
                </div>
              </div>

              {focusedField === "objectives" && (
                <InfoTextComponent infoText={infoTexts["objectives"]} />
              )}
            </div>
          </div>

          {/* Livrables */}
          <div className="flex flex-col gap-4">
            <Text className="text-xl font-semibold">Livrables</Text>
            {errors.deliverables && (
              <Text className="text-sm text-red-500">
                {errors.deliverables}
              </Text>
            )}
            <div className="flex w-full flex-row items-start gap-2 max-md:flex-col max-md:items-stretch">
              <div
                className={`${
                  focusedField === "deliverables"
                    ? "max-w-[100%] flex-1 md:max-w-[75%] md:basis-3/4"
                    : "max-w-[100%] flex-1"
                }`}
              >
                <div className="h-52 flex-1">
                  <ReactQuill
                    theme="snow"
                    value={data.deliverables}
                    onChange={(content, delta, source, editor) => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        deliverables: ""
                      }));
                      handleChange("deliverables", content);
                    }}
                    onFocus={() => setFocusedField("deliverables")}
                    onBlur={() => setFocusedField(null)}
                    placeholder="Lister les livrables attendus à la fin de la mission"
                    modules={quillModules}
                    formats={quillFormats}
                    className={`h-40 ${
                      errors.deliverables ? "border-red-500" : ""
                    }`}
                  />
                </div>
                <div className="flex p-2 max-md:mt-4">
                  <Text className="text-sm">
                    {(data.deliverables || "").length}/5000 caractères
                    {/* {getPlainText(data.deliverables || "").length}/5000 caractères */}
                  </Text>
                </div>
              </div>

              {focusedField === "deliverables" && (
                <InfoTextComponent infoText={infoTexts["deliverables"]} />
              )}
            </div>
          </div>

          {/* Joindre des documents supplémentaires */}
          <div className="flex flex-col gap-4">
            <Text className="text-xl font-semibold">
              Joindre des documents supplémentaires
            </Text>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div
              onClick={handleFile}
              className="flex cursor-pointer items-center gap-4 rounded-lg border p-2"
            >
              <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-blueViolet-50">
                <ImportCircle size={18} />
              </div>
              <Text className="text-sm">
                Glisser/Déposer le(s) document(s) ou
              </Text>
              <Button type="fullLite">Parcourir l'ordinateur</Button>
            </div>
            <input
              name="attachments"
              onChange={handleFileUpdate}
              ref={inputRef}
              className="hidden"
              type="file"
              multiple
              accept=".png,.jpg,.jpeg,.gif,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp3,.mp4,.zip,.rar,.ai,.psd,.xd"
            />
            <div className="flex flex-row flex-wrap gap-4">
              {data.attachments &&
                data.attachments.length > 0 &&
                data.attachments.map((file, _i) => {
                  const fileFormated = {
                    fileName: file.name,
                    fileType: file.type
                  };
                  return (
                    <FileCard
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      key={_i}
                      file={fileFormated}
                      removeFile={() => removeFile(_i)}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <div onClick={handlePrevStep}>
            <Button type="fullLite">Précédent</Button>
          </div>
          <div className="flex-1 text-center">
            <Text>Étape {step + 1}/6</Text>
          </div>
          <div>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div onClick={nextStep}>
              <Button type="full">Suivant</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;

interface InfoTextComponentProps {
  infoText: string;
}

const InfoTextComponent = ({ infoText }: InfoTextComponentProps) => {
  return (
    <div className="flex basis-1/4 flex-col rounded-lg bg-blueViolet-50 p-4 shadow-md">
      <ImportCircle size={24} className="text-blueViolet-600" />
      <Text className="mt-2 text-gray-700">{infoText}</Text>
    </div>
  );
};
