const Unauthorized = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <h1>
        Vous n'avez pas accés à cette page car votre certificat de scolarité
        et/ou votre entreprise n'est pas verifiée.
      </h1>
    </div>
  );
};

export default Unauthorized;
