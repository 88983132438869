// Requires
import { Link } from "react-router-dom";

// Components
import Text from "../../UI/Text";
import Title from "../../UI/Title";

// Context
import { usePopup } from "../../../context/Popup";

const StudentHorizontalCardExtraMin = ({
  data
}: {
  data: {
    id: number;
    firstName: string;
    lastName: string;
    image: string;
    profileTitle: string;
  };
}) => {
  const { togglePopup } = usePopup();

  return (
    <Link
      onClick={() => togglePopup("proposalDetails")}
      to={`/company/student/${data.id}`}
      className="flex flex-row items-center justify-between p-4"
    >
      <div className="flex flex-row items-center gap-4">
        <img
          src={data.image}
          className="h-[60px] w-[60px] rounded-lg object-cover object-center"
        />
        <div className="flex flex-col gap-1">
          <Title type="custom" classname="group-hover:text-white">
            {data.firstName} {data.lastName}
          </Title>
          <Text className="text-sm group-hover:text-white">
            {data.profileTitle}
          </Text>
        </div>
      </div>
    </Link>
  );
};

export default StudentHorizontalCardExtraMin;
