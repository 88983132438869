import { fetchApi } from "../Misc/api";

export const getUser = async (slug: string) => {
  if (slug) {
    const response = await fetchApi(`/user/public/${slug}`, "GET");
    if (response.error) return false;

    return response;
  }
};
