// Icons
import { QuoteUp } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import Text from "../UI/Text";
import Title from "../UI/Title";
import LittleReviewCard from "../Cards/Review/LittleReviewCard";

// Types
import { Review } from "../../types/user";

const Recommendation = ({ data }: { data?: Review[] }) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <QuoteUp size={18} />
        <Title type="bloc">Recommandations</Title>
      </BlocTitle>
      <Content>
        {data &&
          data.map((item, _id) => <LittleReviewCard key={_id} data={item} />)}
        {!data || (data.length < 1 && <Text>Aucune recommandation</Text>)}
      </Content>
    </div>
  );
};

export default Recommendation;
