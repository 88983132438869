// Requires
import { useState } from "react";

// Types
import type { JobCreateType } from "../../../types/job";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";

dayjs.locale("fr");

type DateTypeModalSelectorType = {
  data: JobCreateType;
  setData: React.Dispatch<React.SetStateAction<JobCreateType>>;
};

const DateTypeModalSelector = ({
  data,
  setData
}: DateTypeModalSelectorType) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempSelectedDate, setTempSelectedDate] = useState(
    data.startingAt || dayjs().format("DD-MM-YYYY")
  );

  const handleDateEndTypeClick = (type: any) => {
    if (type === "date") {
      setIsModalOpen(true);
    } else {
      setData((prevData) => ({
        ...prevData,
        startingType: "unspecified"
      }));
    }
  };

  const handleValidation = () => {
    const date = tempSelectedDate;

    setData((prevData) => ({
      ...prevData,
      startingType: "date",
      startingAt: date
    }));
    setIsModalOpen(false);
  };

  const handleChange = (e: any) => {
    setTempSelectedDate(e.target.value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTempSelectedDate(data.startingAt || "");
  };

  const formatDate = (dateStr: string) => {
    const date = dayjs(dateStr);
    return date.isValid() ? date.format("DD/MM/YYYY") : "";
  };

  return (
    <div className="relative">
      {/* Options */}
      <div className="flex h-full flex-row flex-nowrap gap-4">
        {/* A une date donnée */}
        <div
          className={`flex h-full flex-1 cursor-pointer items-center justify-center rounded-lg border p-2 ${
            data.startingType === "date"
              ? "border-blueViolet-600 bg-blueViolet-50 text-blueViolet-600"
              : "border-blueViolet-200 text-blueViolet-600 hover:bg-blueViolet-50"
          }`}
          onClick={() => handleDateEndTypeClick("date")}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleDateEndTypeClick("date");
          }}
        >
          {data.startingType === "date" && data.startingAt
            ? `Date sélectionnée: ${formatDate(data.startingAt)}`
            : "À une date souhaitée"}
        </div>
      </div>

      {/* Modal */}
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            key={"datePickerModal"}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 5 }}
            className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-50 pt-12"
            onClick={closeModal}
          >
            <div
              className="w-80 rounded-lg bg-white p-6 shadow-lg"
              onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
            >
              <h2 className="mb-4 text-xl font-semibold">
                Date de début souhaitée
              </h2>
              <input
                type="date"
                name="endingAt"
                min={dayjs().format("YYYY-MM-DD")}
                onChange={handleChange}
                value={tempSelectedDate}
                className="mb-4 w-full rounded-lg border p-2"
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={closeModal}
                  className="rounded-lg bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
                >
                  Annuler
                </button>
                <button
                  onClick={handleValidation}
                  disabled={!tempSelectedDate}
                  className={`rounded-lg px-4 py-2 text-white ${
                    tempSelectedDate
                      ? "bg-blueViolet-600 hover:bg-blueViolet-700"
                      : "cursor-not-allowed bg-blueViolet-300"
                  }`}
                >
                  {tempSelectedDate
                    ? `Valider (${formatDate(tempSelectedDate)})`
                    : "Valider"}
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DateTypeModalSelector;
