// Icons
import { TableDocument } from "iconsax-react";

// Components
import Content from "../../../../../../components/Bloc/Content";
import { BlocTitleWithButton } from "../../../../../../components/Bloc/Title";
import Title from "../../../../../../components/UI/Title";
import Filters from "../../../../../../components/Table/Filters";
import Table from "../../../../../../components/Student/Proposals/Table";

// Misc
import { _STUDENT } from "../../../../../../utils/misc";

const Proposals = () => {
  return (
    <div className="flex h-full flex-col gap-8">
      <Title type="page">Candidatures envoyées</Title>
      <div className="w-full flex-row gap-8 md:flex md:w-auto">
        <div className="flex grow flex-col gap-4 rounded-lg border p-4 shadow-lg">
          <BlocTitleWithButton>
            <div className="flex flex-row items-center gap-2">
              <TableDocument size={18} />
              <Title type="bloc">Candidatures envoyées</Title>
            </div>
            {/* <div className="flex flex-row items-center gap-4">
              <CSVTag />
              <PDFTag />
            </div> */}
          </BlocTitleWithButton>
          <Content>
            <div className="flex flex-col rounded-lg border">
              <div className="p-2">
                <Filters typeList={_STUDENT.filters.proposals} />
              </div>
              <Table />
            </div>
          </Content>
        </div>
      </div>
    </div>
  );
};

export default Proposals;
