// Requires
import { useEffect, useState } from "react";

// Components
import LanguageSelector from "../../../Misc/LanguageSelector";
import Button from "../../../UI/Button";
import Highlight from "../../../UI/Highlight";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";
import PhoneValidationComponent from "../../../Onboarding/PhoneValidationComponent";

// Store
import { useUser } from "../../../../utils/store/userStore";

// Helpers
import { validateEmail } from "../../../../utils/helpers/validators";

// Services
import { updateGeneralInformations } from "../../../../services/User/user";

// Types
import { LanguagesType } from "../../../../types/misc";

type Errors = {
  phoneNumber?: string;
  email?: string;
};

const GeneralInformations = () => {
  const user = useUser((state) => state.userdata);

  const _COMPANY_MEMBER_MODE = user?.roleName === "company";

  const [data, setData] = useState<{
    email: string;
    phoneNumber: string;
    isTih: boolean;
    languagesId: { id: number; title: string }[];
  }>({
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    isTih: user?.tih || false,
    languagesId: user?.languages || []
  });

  const [phoneNumberId, setPhoneNumberId] = useState<string>("+33");

  const [phoneNumberIsValidate, setPhoneNumberIsValidate] =
    useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>(
    user?.phoneNumber || ""
  );

  const [isEditingPhoneNumber, setIsEditingPhoneNumber] =
    useState<boolean>(false);

  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({
    phoneNumber: "",
    email: ""
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setData((prev) => {
      return {
        ...prev,
        [name]: name === "isTih" ? value === "true" : value
      };
    });
  };

  const handleLanguagesChange = (languages: LanguagesType[]) => {
    setData((prev) => {
      return {
        ...prev,
        languagesId: languages
      };
    });
  };

  const areLanguagesEqual = (
    languages1: { id: number; title: string }[],
    languages2: { id: number; title: string }[]
  ) => {
    if (languages1.length !== languages2.length) return false;

    const sortedLang1 = [...languages1].sort((a, b) => a.id - b.id);
    const sortedLang2 = [...languages2].sort((a, b) => a.id - b.id);

    return sortedLang1.every(
      (lang, index) => lang.id === sortedLang2[index].id
    );
  };

  const updateInformations = async () => {
    const newErrors: Errors = {};
    setErrors(newErrors);
    setIsLoading(true);

    if (!data.email || !validateEmail(data.email)) {
      newErrors.email = "Tu dois indiquer une adresse email valide";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    const newValues = { ...data };

    try {
      if (user) {
        const response = await updateGeneralInformations(
          newValues,
          user?.roleName
        );

        if (response) {
          setSuccess(true);
          setIsLoading(false);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        } else {
          setErrors({ ...newErrors, email: "Erreur lors de la mise à jour." });
        }
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des informations:", error);
      setErrors({
        ...newErrors,
        email: "Une erreur inattendue s'est produite."
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setData({
        email: user.email,
        phoneNumber: user.phoneNumber,
        isTih: user.tih || false,
        languagesId: user.languages
      });
    }
  }, [user]);

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Informations générales</Title>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center justify-between">
            <Text className="font-semibold">Numéro de téléphone</Text>
            {!isEditingPhoneNumber && (
              <Button type="full" onClick={() => setIsEditingPhoneNumber(true)}>
                Modifier
              </Button>
            )}
          </div>

          {!isEditingPhoneNumber ? (
            <div className="flex flex-row items-center gap-2">
              <Text className="text-lg">{phoneNumber}</Text>
            </div>
          ) : (
            <PhoneValidationComponent
              error={errors}
              setError={setErrors}
              phoneNumber={phoneNumber}
              phoneNumberIsValidate={phoneNumberIsValidate}
              setPhoneNumber={setPhoneNumber}
              setPhoneNumberIsValidate={setPhoneNumberIsValidate}
              phoneNumberId={phoneNumberId}
              setPhoneNumberId={setPhoneNumberId}
              title={false}
            />
          )}
        </div>

        {!_COMPANY_MEMBER_MODE && (
          <div className="flex flex-col gap-1">
            <Text className="font-semibold">Reconnaissance TIH</Text>
            <select
              onChange={handleChange}
              className="rounded-lg border p-2 focus:outline-blueViolet-600"
              name="isTih"
              value={data?.isTih === true ? "true" : "false"}
            >
              <option value={"true"}>Oui</option>
              <option value={"false"}>Non</option>
            </select>
          </div>
        )}
      </div>

      {!_COMPANY_MEMBER_MODE && (
        <LanguageSelector
          callback={handleLanguagesChange}
          initialData={data.languagesId}
        />
      )}

      {success && (
        <Highlight type="Success">
          Les modifications ont été appliquées.
        </Highlight>
      )}
      {data.email !== user?.email ||
      data.phoneNumber !== user?.phoneNumber ||
      data.isTih !== user.tih ||
      !areLanguagesEqual(data.languagesId, user.languages) ? (
        <div
          onClick={updateInformations}
          className="flex flex-row items-end justify-end"
        >
          <Button type="full">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      ) : (
        <div className="flex flex-row items-end justify-end">
          <Button type="disabled">Enregistrer</Button>
        </div>
      )}
    </div>
  );
};

export default GeneralInformations;
