// Icons
import { Notification } from "iconsax-react";

// Requires
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Helpers
import { capitalizeWords } from "../../../utils/helpers/capitalizeWords";

// Store
import { useUser } from "../../../utils/store/userStore";

// Context
import { useSocket } from "../../../context/Socket";

// Services
import { getNotifications } from "../../../services/Notification/notification";

// Type
import type {
  NotificationsType,
  NotificationType
} from "../../../types/notification";

// Components
import NotifyDrawer from "./Notify/NotifyDrawer";
import Button from "../Button";
import CustomTooltip from "../Tooltip";
import Avatar from "../Avatar/Avatar";

// Utils
import { Desktop } from "../../../utils/mobile/responsive";

// Context
import { usePopup } from "../../../context/Popup";

const UserTools = () => {
  // TODO: Lazy loading notification

  const user = useUser((state) => state.userdata);

  const url =
    user?.roleName === "company"
      ? "/company/company"
      : `/${user?.roleName}/profil`;

  const { socket } = useSocket();
  const { togglePopup } = usePopup();

  const [notifyDrawerIsOpen, setNotifiyDrawerIsOpen] = useState<boolean>(false);

  const [notificationCount, setNotificationCount] = useState<number>();
  const [notificationsData, setNotificationsData] =
    useState<NotificationType[]>();

  const updateCount = () => {
    setNotificationCount((prev) => {
      if (prev) {
        return prev - 1;
      }
    });
  };

  useEffect(() => {
    const fetch = async () => {
      const response: NotificationsType = await getNotifications(0, 10);
      setNotificationCount(response.count);
      setNotificationsData(response.notifications);
    };

    fetch();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("notification", (data) => {
        setNotificationCount((prev) => {
          if (prev) {
            return prev + 1;
          }

          return 1;
        });

        setNotificationsData((prev) => {
          if (prev) {
            return [...prev, data];
          }

          return [...data];
        });
      });
    }
  }, [socket]);

  return (
    <div className="relative flex flex-row gap-8">
      <div className="flex flex-row items-center gap-4">
        <CustomTooltip position="bottom" content="Signaler un problème">
          <div onClick={() => togglePopup("createTicket")}>
            <Button type="fullLite" className="!text-sm !text-black">
              ?
            </Button>
          </div>
        </CustomTooltip>
        <div className="relative flex cursor-pointer items-center justify-center rounded-lg bg-blueViolet-50 p-2 hover:border-primaryPurple hover:bg-blueViolet-100">
          <div
            onClick={() => setNotifiyDrawerIsOpen((prev) => !prev)}
            className="relative"
          >
            <Notification size={18} />
            <AnimatePresence>
              {notifyDrawerIsOpen && notificationsData && (
                <NotifyDrawer
                  updateCount={updateCount}
                  data={notificationsData}
                />
              )}{" "}
            </AnimatePresence>
          </div>
          {notificationCount && notificationCount > 0 ? (
            <div className="absolute right-[-10px] top-[-10px] flex max-h-4 min-w-4 items-center justify-center rounded-full bg-blueViolet-600 p-1 text-xs text-white">
              {notificationCount}
            </div>
          ) : null}
        </div>
      </div>
      <Link className="flex items-center" to={url}>
        <div className="flex cursor-pointer flex-row items-center gap-2 hover:text-primaryPurple">
          <Avatar link={user?.image} className="h-[36px] w-[36px] border" />
          <Desktop>
            {capitalizeWords(`${user?.firstName} ${user?.lastName}`)}
          </Desktop>
        </div>
      </Link>
    </div>
  );
};

export default UserTools;
