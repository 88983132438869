import Text from "../../../UI/Text";
import Title from "../../../UI/Title";

const Closed = () => {
  return (
    <div className="flex flex-col gap-4 p-2 md:w-[calc(100%/3)] md:max-w-[calc(100%/3)]">
      <div className="flex flex-row items-center gap-4">
        <Title type="bloc">Mission terminée</Title>
        <Text className="text-sm text-withCustom-500">255</Text>
      </div>
      <div className="flex max-h-[400px] flex-col gap-4 overflow-scroll md:pr-7">
        {/* <Link
          to={"/admin/jobs/"}
          className="flex cursor-pointer flex-col gap-4 rounded-lg border p-4 shadow-lg hover:bg-blueViolet-50"
        >
          <Text className="max-w-full text-wrap break-words font-semibold">
            test
          </Text>
          <Text className="max-w-full text-wrap break-words text-sm">test</Text>
          <HorizontalDivider />
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-2">
              <img
                src={"https://piscum.photos/100/100"}
                className="h-6 w-6 rounded-full bg-blueViolet-50"
              />
              <Text>test</Text>
            </div>
            <Text>10&euro;</Text>
          </div>
        </Link> */}
        <p>Aucune pour le moment</p>
      </div>
    </div>
  );
};

export default Closed;
