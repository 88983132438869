const Tag = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <span
      className={`w-fit rounded-lg bg-blueViolet-50 px-3 py-1 text-sm ${className}`}
    >
      {children}
    </span>
  );
};

export default Tag;
