// Icons
import { Edit2, Trash } from "iconsax-react";

// Types
import type { PortfolioType } from "../../../types/user";

// Components
import Date from "../../UI/Date";
import Text from "../../UI/Text";

const PortfolioCard = ({
  item,
  index,
  onDelete,
  onEdit
}: {
  index: number;
  item: PortfolioType;
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
}) => {
  // TODO: Remettres les OG si disponible

  return (
    <div className="flex flex-col gap-4 rounded-lg border border-blueViolet-200 p-4">
      <div className="relative h-[180px] w-full rounded-lg bg-blueViolet-50">
        {item.ogImage && (
          <img
            src={item.ogImage}
            className="h-[180px] w-full rounded-lg object-cover object-center"
          />
        )}
        <div className="absolute bottom-3 left-3 flex items-center justify-center rounded-lg bg-gray-500 bg-opacity-50 px-2 py-1">
          <Date
            format="DD/MM/YY"
            date={item.date ? item.date : "01-01-2024"}
            className="text-sm text-white"
          />
        </div>
        <div className="absolute right-3 top-3 flex flex-row gap-4 rounded-lg bg-white p-2">
          <Trash
            onClick={() => onDelete(index)}
            className="cursor-pointer"
            size={18}
            color="red"
          />
          <Edit2
            className="cursor-pointer"
            size={18}
            onClick={() => onEdit(index)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <Text className="font-semibold">
          {item.ogTitle ? item.ogTitle : item.title}
        </Text>
        <Text>{item.link}</Text>
      </div>
    </div>
  );
};

export default PortfolioCard;
