// Requires
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Icons
import { CloseSquare } from "iconsax-react";

// Components
import Title from "../../../UI/Title";
import CustomTooltip from "../../../UI/Tooltip";
import Text from "../../../UI/Text";
import Button from "../../../UI/Button";

// Context
import { usePopup } from "../../../../context/Popup";

// Services
import { getSpecificUser } from "../../../../services/User/user";

// Types
import { User } from "../../../../types/user";

// Context
import { useFloatingNotification } from "../../../../context/FloatingNotification";
import { addInternalScore } from "../../../../services/Scoring/scoring";
import Avatar from "../../../UI/Avatar/Avatar";

// Types
type Errors = {
  content?: string;
  score?: string;
};

const Notation = () => {
  const { closePopup, studentsChecked } = usePopup();
  const { toggleFloatingNotification } = useFloatingNotification();

  const [dataStudents, setDataStudents] = useState<User[]>([]);
  const [data, setData] = useState<{
    score: number;
    comment: string;
    studentId: number | undefined;
  }>({
    score: 0,
    comment: "",
    studentId: dataStudents[0]?.id
  });
  const [resError, setResError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    setErrors({});

    const newErrors: Errors = {};

    if (!data.score) {
      newErrors.score = "Vous devez indiquer une note.";
    }

    if (!data.comment) {
      newErrors.content = "Vous devez mettre un commentaire";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    const response = await addInternalScore(
      data.studentId ?? 0,
      Number(data.score),
      data.comment
    );

    if (response) {
      setIsLoading(false);
      setData({ score: 0, comment: "", studentId: undefined });
      closePopup();
      toggleFloatingNotification("La note a bien été ajoutée");
      return;
    }

    setResError("Une erreur est survenu");
    setIsLoading(false);
    return;
  };

  useEffect(() => {
    const fetchStudents = async () => {
      if (studentsChecked && studentsChecked.length > 0) {
        const studentPromises = studentsChecked.map((studentId) =>
          getSpecificUser(studentId)
        );
        const responses = await Promise.all(studentPromises);
        setDataStudents(responses);
      }
    };

    fetchStudents();
  }, [studentsChecked]);

  useEffect(() => {
    if (dataStudents.length > 0 && data.studentId === undefined) {
      setData((prevData) => ({
        ...prevData,
        studentId: dataStudents[0].id
      }));
    }
  }, [dataStudents]);

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex flex-row items-center justify-between gap-16">
        <Title type="bloc">Mettre une note à un étudiant</Title>
        <CloseSquare
          size={18}
          className="cursor-pointer hover:text-blueViolet-600"
          onClick={closePopup}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Text>Vous avez sélectionné l'étudiant suivant :</Text>
        <div className="flex w-fit flex-row gap-4 rounded-lg border p-2">
          {dataStudents && dataStudents.length > 0 ? (
            dataStudents.map((student, index) => (
              <CustomTooltip
                key={index}
                id={index.toString()}
                position="top"
                content={`${student.firstName} ${student.lastName}`}
              >
                <Link target="_blank" to={`/admin/student/${student.id}`}>
                  <Avatar link={student.image} className="size-11 rounded-lg" />
                </Link>
              </CustomTooltip>
            ))
          ) : (
            <div className="loader" />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <label htmlFor="">Note sur 5</label>
              <input
                type="number"
                min={0}
                max={5}
                value={data.score}
                className="rounded-lg border p-2 focus:outline-blueViolet-600"
                name="score"
                onChange={handleInputChange}
              />
            </div>
            {errors?.score && (
              <Text className="text-sm text-red-600">{errors.score}</Text>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="">Commentaire :</label>
            <textarea
              className="textareaautoresize resize-none rounded-lg border p-2 focus:outline-blueViolet-600"
              value={data.comment}
              name="comment"
              onChange={handleInputChange}
            />
            {errors?.content && (
              <Text className="text-sm text-red-600">{errors.content}</Text>
            )}
          </div>
        </div>
        {!isLoading ? (
          <div onClick={handleConfirm}>
            <Button type="full">Valider</Button>
          </div>
        ) : (
          <Button type="disabled">
            <div className="loader" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Notation;
