// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

// Icons
import { Personalcard } from "iconsax-react";

// Components
import Text from "../../../../../components/UI/Text";
import GeneralInformations from "../../../../../components/Company/MyCompany/Edit/GeneralInformations/GeneralInformations";
import Avatar from "../../../../../components/UI/Avatar/Avatar";

// Services

// Store
import { useUser } from "../../../../../utils/store/userStore";

const EditCompany = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(0);

  const menuLinks: {
    id: number;
    name: string;
    icon: React.ReactNode;
    element: React.ReactNode;
    onlyForStudent: boolean;
  }[] = [
    {
      id: 0,
      name: "Informations générales",
      icon: <Personalcard size={18} />,
      element: <GeneralInformations companyMode={true} />,
      onlyForStudent: false
    }
  ];

  const user = useUser((state) => state.userdata);

  return (
    <div className="flex flex-col gap-8">
      <div
        className="relative h-[250px] rounded-t-lg bg-cover bg-center"
        style={{
          backgroundImage: `url(${user?.company?.banner})`
        }}
      />

      <div className="flex flex-row gap-8 pl-6 pr-6 max-md:flex-col">
        <div className="z-40 mt-[-90px] flex flex-col gap-8">
          <div className="flex w-fit min-w-[350px] flex-col gap-8 rounded-lg bg-white p-4 shadow-lg">
            <div className="flex flex-row items-center gap-4">
              <Avatar link={user?.company?.image} className="size-20" />
              <div className="flex flex-col">
                <Text className="text-xl font-semibold">
                  {user?.company?.displayName}
                </Text>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
            {menuLinks.map((item) => (
              <div
                key={item.id}
                className={`group flex cursor-pointer flex-row items-center gap-1 rounded-lg p-2 ${
                  activeMenuItem === item.id
                    ? "bg-blueViolet-50 text-blueViolet-600"
                    : "hover:bg-blueViolet-50 hover:text-blueViolet-600"
                }`}
                onClick={() => setActiveMenuItem(item.id)}
              >
                {item.icon}
                <Text
                  className={`group-hover:!text-blueViolet-600 ${
                    activeMenuItem === item.id
                      ? "!text-blueViolet-600"
                      : "text-blueViolet-950"
                  }`}
                >
                  {item.name}
                </Text>
              </div>
            ))}
          </div>
        </div>

        <div className="flex h-fit grow flex-col gap-8">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeMenuItem}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
              className="flex flex-col gap-8"
            >
              {menuLinks[activeMenuItem].element}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
