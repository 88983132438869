// Icons
import { TableDocument } from "iconsax-react";

// Requires
import { useEffect, useRef, useState } from "react";

// Components
import Filters from "../../../../../components/Table/Filters";
import Title from "../../../../../components/UI/Title";
import Table from "../../../../../components/Table/Company/ManageJobs/Table";

// Utils
import { _COMPANY } from "../../../../../utils/misc";

const ManageJobs = () => {
  const [, setOpenMenu] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex h-full flex-col gap-8">
      <Title type="page">Gérer l'ensemble de mes missions</Title>
      <div className="w-full flex-row gap-8 md:flex md:w-auto">
        <div className="flex grow flex-col gap-8 rounded-lg border p-4 shadow-lg">
          <div className="flex flex-row items-center gap-2">
            <div className="flex flex-row items-center gap-2">
              <TableDocument size={18} />
              <Title type="bloc">Tableau des missions</Title>
            </div>
            {/* <div className="flex flex-row items-center gap-4">
              <CSVTag />
              <PDFTag />
            </div> */}
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col rounded-lg border">
              <div className="p-4">
                <Filters typeList={_COMPANY.filters.jobs} />
              </div>
              <Table />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageJobs;
