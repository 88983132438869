// NeedTypeSelector.tsx

import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CircleAlert } from "lucide-react";
import CustomTooltip from "../../../UI/Tooltip";
import Text from "../../../UI/Text";
import dayjs from "dayjs";
// import { JobCreateType } from "../../../../types/job";

// Types
interface JobCreateType {
  type?: "punctual" | "recurent";
  duration?: number;
  endingType?: "asap" | "unspecified" | "date";
  endingAt?: string;
  startingAt?: string;
  // Add other fields if necessary
}

interface ErrorType {
  type?: string;
  duration?: string;
  endingType?: string;
  endingAt?: string;
  // Add other fields if necessary
}

interface WarningType {
  duration?: string;
}

interface NeedTypeSelectorProps {
  data: JobCreateType;
  setData: React.Dispatch<React.SetStateAction<JobCreateType>>;
  errors: ErrorType;
  setErrors: React.Dispatch<React.SetStateAction<ErrorType>>;
  warnings: WarningType;
}

const NeedTypeSelector: React.FC<NeedTypeSelectorProps> = ({
  data,
  setData,
  errors,
  setErrors,
  warnings
}) => {
  const handleNeedTypeClick = (type: "punctual" | "recurent") => {
    setData((prev) => ({ ...prev, type }));
  };

  const handleDateEndTypeClick = (type: "asap" | "unspecified" | "date") => {
    setData((prev) => ({ ...prev, endingType: type }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: name === "duration" ? Number(value) : value
    }));
  };

  useEffect(() => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      duration: ""
    }));
  }, [data.duration]);

  useEffect(() => {
    if (
      data.endingType === "date" &&
      (!data.endingAt || (data.startingAt && data.endingAt < data.startingAt))
    ) {
      setData((prevData) => ({
        ...prevData,
        endingAt: data.startingAt
      }));
    }
  }, [data.endingType, data.startingAt]);

  const formatDate = (dateStr?: string) => {
    if (!dateStr) return "";
    const date = dayjs(dateStr);
    return date.isValid() ? date.format("DD/MM/YYYY") : "";
  };

  return (
    <div className="flex flex-col gap-4">
      <Text className="text-xl font-semibold">Qualifiez votre besoin</Text>
      {errors.type && (
        <Text className="text-sm text-red-500">{errors.type}</Text>
      )}

      {/* Punctual Need */}
      <div
        className={`flex cursor-pointer flex-col justify-between gap-4 overflow-hidden rounded-lg border p-8 ${
          data.type === "punctual"
            ? "border-blueViolet-600 bg-white"
            : "border-blueViolet-200 hover:border-blueViolet-600"
        }`}
        onClick={() => handleNeedTypeClick("punctual")}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <input
              type="checkbox"
              id="punctualNeed"
              className="peer hidden"
              checked={data.type === "punctual"}
              readOnly
            />
            <label
              htmlFor="punctualNeed"
              className="relative inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-gray-300 bg-white bg-clip-content p-0.5 hover:border-gray-400 peer-checked:border-indigo-600 peer-checked:bg-indigo-600"
            ></label>
            <Text>Besoin ponctuel</Text>
          </div>
          <CustomTooltip
            key={"test"}
            position="top"
            content="Vous souhaitez un rendu précis qui entraînera la fin de la mission. Ex : Livraison d’un site vitrine, d’une charte graphique…"
            className="max-w-[200px]"
          >
            <CircleAlert size={18} />
          </CustomTooltip>
        </div>
        <AnimatePresence>
          {data.type === "punctual" && (
            <motion.div
              className="flex flex-col gap-8"
              key={data.type}
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
            >
              {/* Number of Estimated Hours */}
              <div className="flex flex-col gap-2">
                <Text className="font-semibold">Nombre d'heures estimé</Text>
                {errors.duration && (
                  <Text className="text-sm text-red-500">
                    {errors.duration}
                  </Text>
                )}
                <div className="flex flex-row items-center gap-2">
                  <input
                    onChange={handleChange}
                    name="duration"
                    value={data.duration ?? ""}
                    min={1}
                    type="number"
                    placeholder="0"
                    className={`w-[80px] rounded-lg border p-1 focus:outline-blueViolet-600 ${errors.duration && "border-red-500"}`}
                  />
                  <Text className="text-sm text-withCustom-500">heures</Text>
                </div>
              </div>
              {/* Desired Delivery Date */}
              <div className="flex flex-col gap-2">
                <Text className="font-semibold">
                  Date de livraison souhaitée
                </Text>
                {errors.endingType && (
                  <Text className="text-sm text-red-500">
                    {errors.endingType}
                  </Text>
                )}
                <div className="flex flex-row flex-nowrap gap-4">
                  {/* ASAP Option */}
                  <div
                    className={`flex flex-1 cursor-pointer items-end justify-center rounded-lg border p-2 text-center ${
                      data.endingType === "asap"
                        ? "border-blueViolet-600 bg-blueViolet-50 text-blueViolet-600"
                        : "border-blueViolet-200 text-blueViolet-600 hover:bg-blueViolet-50"
                    }`}
                    onClick={() => handleDateEndTypeClick("asap")}
                  >
                    Dès que possible
                  </div>
                  {/* Date Option */}
                  <div
                    className={`flex flex-1 cursor-pointer items-end justify-center rounded-lg border p-2 ${
                      data.endingType === "date"
                        ? "border-blueViolet-600 bg-blueViolet-50 text-blueViolet-600"
                        : "border-blueViolet-200 text-blueViolet-600 hover:bg-blueViolet-50"
                    }`}
                    onClick={() => handleDateEndTypeClick("date")}
                  >
                    À une date souhaitée
                  </div>
                  {/* Unspecified Option */}
                  <div
                    className={`flex flex-1 cursor-pointer items-end justify-center rounded-lg border p-2 text-center ${
                      data.endingType === "unspecified"
                        ? "border-blueViolet-600 bg-blueViolet-50 text-blueViolet-600"
                        : "border-blueViolet-200 text-blueViolet-600 hover:bg-blueViolet-50"
                    }`}
                    onClick={() => handleDateEndTypeClick("unspecified")}
                  >
                    Je ne sais pas
                  </div>
                </div>
                {data.endingType === "date" && (
                  <>
                    <input
                      name="endingAt"
                      type="date"
                      value={data.endingAt ?? ""}
                      onChange={handleChange}
                      className="rounded-lg border p-2 focus:outline-blueViolet-600"
                    />
                  </>
                )}
                {errors.endingAt && (
                  <Text className="text-sm text-red-500">
                    {errors.endingAt}
                  </Text>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Recurrent Need */}
      <div
        className={`flex cursor-pointer flex-col justify-between gap-4 overflow-hidden rounded-lg border p-8 ${
          data.type === "recurent"
            ? "border-blueViolet-600 bg-white"
            : "border-blueViolet-200 hover:border-blueViolet-600"
        }`}
        onClick={() => handleNeedTypeClick("recurent")}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <input
              type="checkbox"
              id="recurentNeed"
              className="peer hidden"
              checked={data.type === "recurent"}
              readOnly
            />
            <label
              htmlFor="recurentNeed"
              className="relative inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border-2 border-gray-300 bg-white bg-clip-content p-0.5 hover:border-gray-400 peer-checked:border-indigo-600 peer-checked:bg-indigo-600"
            ></label>
            <Text>Besoin récurrent</Text>
          </div>
          <CustomTooltip
            id="recurentNeed"
            position="top"
            content="Vous avez un besoin récurrent avec des objectifs par semaine ou par mois. Estimez un nombre d’heures par semaine et vous êtes partis !"
            className="max-w-[200px]"
          >
            <CircleAlert size={18} />
          </CustomTooltip>
        </div>
        <AnimatePresence>
          {data.type === "recurent" && (
            <motion.div
              key={data.type}
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              className="flex flex-col gap-8"
            >
              {/* Expected Hours Per Week */}
              <div className="flex flex-col gap-2">
                {errors.duration && (
                  <Text className="text-sm text-red-500">
                    {errors.duration}
                  </Text>
                )}
                {warnings.duration && (
                  <Text className="text-sm text-orange-500">
                    {warnings.duration}
                  </Text>
                )}
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col gap-2">
                    <Text className="text-sm font-semibold">
                      Nombre d'heures par semaine attendu
                    </Text>
                    <div className="flex flex-row items-center gap-2">
                      <input
                        onChange={handleChange}
                        name="duration"
                        type="number"
                        min={1}
                        value={data.duration ?? ""}
                        placeholder="0"
                        className="w-[80px] rounded-lg border p-1 focus:outline-blueViolet-600"
                      />
                      <Text className="text-sm text-withCustom-500">
                        heures
                      </Text>
                    </div>
                  </div>
                  {/* <div className="flex flex-col gap-2">
                    <Text className="font-semibold text-sm">
                      Duree en semaines
                    </Text>

                    <div className="flex flex-row items-center gap-2">
                      <input
                        onChange={handleChange}
                        name="duration"
                        type="number"
                        value={data.duration ?? ""}
                        placeholder="0"
                        className="w-[80px] rounded-lg border p-1 focus:outline-blueViolet-600"
                      />
                      <Text className="text-sm text-withCustom-500">
                        heures
                      </Text>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* Desired Delivery Date */}
              <div className="flex flex-col gap-2">
                <Text className="font-semibold">
                  Date de livraison souhaitée
                </Text>
                <div className="flex flex-row flex-nowrap gap-4">
                  {/* Date Option */}
                  <div
                    className={`flex flex-1 cursor-pointer items-end justify-center rounded-lg border p-2 text-center ${
                      data.endingType === "date"
                        ? "border-blueViolet-600 bg-blueViolet-50 text-blueViolet-600"
                        : "border-blueViolet-200 text-blueViolet-600 hover:bg-blueViolet-50"
                    }`}
                    onClick={() => handleDateEndTypeClick("date")}
                  >
                    À une date souhaitée
                  </div>
                  {/* Unspecified Option */}
                  <div
                    className={`flex flex-1 cursor-pointer items-center justify-center rounded-lg border p-2 text-center ${
                      data.endingType === "unspecified"
                        ? "border-blueViolet-600 bg-blueViolet-50 text-blueViolet-600"
                        : "border-blueViolet-200 text-blueViolet-600 hover:bg-blueViolet-50"
                    }`}
                    onClick={() => handleDateEndTypeClick("unspecified")}
                  >
                    Je ne sais pas
                  </div>
                </div>
                {data.endingType === "date" && (
                  <input
                    type="date"
                    name="endingAt"
                    onChange={handleChange}
                    value={data.endingAt ?? ""}
                    className="rounded-lg border p-2"
                  />
                )}
                {data.endingType === "date" && errors.endingAt && (
                  <Text className="text-sm text-red-500">
                    {errors.endingAt}
                  </Text>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default NeedTypeSelector;
