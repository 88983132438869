import Text from "../../../UI/Text";
import Title from "../../../UI/Title";

const Essentials = () => {
  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-col">
        <Title type="bloc">Notifications essentielles</Title>
        <Text className="text-sm">
          Il s’agit de notifications en lien avec ton activité sur Juwa et pour
          le bon fonctionnement de la plateforme
        </Text>
      </div>
      <div className="flex flex-col gap-4">
        <Text className="font-semibold">Je souhaite être alerté par :</Text>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-4">
            <div className="toggle-switch">
              <input
                className="toggle-input"
                defaultChecked={true}
                id="toggle"
                type="checkbox"
              />
              <label className="toggle-label" htmlFor="toggle" />
            </div>
            <Text>Email</Text>
          </div>
          <div className="flex flex-row items-center gap-4">
            <div className="toggle-switch">
              <input
                className="toggle-input"
                defaultChecked={true}
                id="toggle"
                type="checkbox"
              />
              <label className="toggle-label" htmlFor="toggle" />
            </div>
            <Text>SMS</Text>
          </div>
          <div className="flex flex-row items-center gap-4">
            <div className="toggle-switch">
              <input
                className="toggle-input"
                defaultChecked={true}
                id="toggle"
                type="checkbox"
              />
              <label className="toggle-label" htmlFor="toggle" />
            </div>
            <Text>Push</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Essentials;
