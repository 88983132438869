// Requires
import type React from "react";
import { useEffect, useState } from "react";

// Components
import Button from "../../../../../components/UI/Button";
import Text from "../../../../../components/UI/Text";
import Title from "../../../../../components/UI/Title";

// Services
import { updateStudentProfile } from "../../../../../services/User/user";

// Context
import { useUI } from "../../../../../utils/context/UI";

// Helpers
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../utils/helpers/localStorage";

// Validators
import PhoneValidationComponent from "../../../../../components/Onboarding/PhoneValidationComponent";

// Types
type ErrorType = {
  lastName?: string;
  firstName?: string;
  phoneNumber?: string;
};

const Three = () => {
  const { step, handleNextStep } = useUI();

  const [errors, setErrors] = useState<ErrorType>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [phoneNumberId, setPhoneNumberId] = useState<string>("+33");

  const [phoneNumberIsValidate, setPhoneNumberIsValidate] =
    useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [data, setData] = useState<{
    lastName?: string;
    firstName?: string;
    phoneNumber?: string;
    gender?: "male" | "female" | "unspecified" | "other" | string;
    birth?: string;
  }>({
    lastName: "",
    firstName: "",
    phoneNumber: "",
    gender: "unspecified",
    birth: ""
  });

  useEffect(() => {
    const localData = getLocalStorage("companyRegisterInfos");

    if (localData) {
      const parse: {
        step3: {
          firstName: string;
          lastName: string;
          phone: string;
          gender: "male" | "female" | "unspecified" | "other";
          birth: string;
        };
      } = JSON.parse(localData);
      if (localData) {
        const parse: {
          step3: {
            firstName: string;
            lastName: string;
            phone: string;
            gender: "male" | "female" | "unspecified" | "other";
            birth: string;
          };
        } = JSON.parse(localData);

        if (parse.step3) {
          setData((prev) => ({
            ...prev,
            firstName: parse.step3.firstName || "",
            lastName: parse.step3.lastName || "",
            phoneNumber: parse.step3.phone || "",
            gender: parse.step3.gender || "unspecified",
            birth: parse.step3.birth || ""
          }));
        }
      }
    }
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const nextStep = async () => {
    setIsLoading(true);
    const newErrors: ErrorType = {};
    setErrors(newErrors);

    if (!data?.lastName) {
      newErrors.lastName = "Vous devez entrer votre nom";
    }

    if (!data?.firstName) {
      newErrors.firstName = "Vous devez entrer votre prénom";
    }

    if (
      !phoneNumber ||
      !phoneNumberIsValidate
      // || !validatePhoneNumber(phoneNumberId + data?.phoneNumber)
    ) {
      newErrors.phoneNumber =
        "Vous devez indiquer un numéro de téléphone valide";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    if (data?.firstName && data.lastName && phoneNumber) {
      const formData = new FormData();
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("phone", phoneNumberId + phoneNumber);
      if (data.gender) formData.append("gender", data.gender);
      if (data.birth) formData.append("birth", data.birth);

      const response = await updateStudentProfile(formData);

      if (!response) {
        setIsLoading(false);
        return;
      }

      const exLocalData = getLocalStorage("companyRegisterInfos");
      let exLocalDataParse = {};

      if (exLocalData) {
        try {
          exLocalDataParse = JSON.parse(exLocalData);
        } catch (error) {
          console.error("Erreur lors du parsing des données", error);
        }
      }

      const updatedLocalData = {
        ...exLocalDataParse,
        step3: {
          firstName: data.firstName,
          lastName: data.lastName,
          phone: phoneNumberId + phoneNumber,
          gender: data.gender,
          birth: data.birth
        }
      };

      setLocalStorage([
        { key: "companyRegisterStep", value: "3" },
        {
          key: "companyRegisterInfos",
          value: JSON.stringify(updatedLocalData)
        }
      ]);

      setIsLoading(false);

      handleNextStep();
    }
  };

  return (
    <div className="flex min-w-[50%] max-w-[50%] flex-col max-md:max-w-[80%]">
      <div className="flex flex-col gap-12 max-md:gap-6">
        <div className="flex items-center justify-center">
          <Title type="page">Quelques informations à propos de vous</Title>
        </div>

        <div className="flex flex-col gap-8 max-md:gap-4">
          <div className="flex flex-row gap-4 max-md:flex-col">
            <div className="flex grow flex-col gap-2">
              <Text className="font-semibold">
                Nom<span style={{ color: "red" }}> * </span>
              </Text>
              {errors?.lastName && (
                <Text className={`text-sm text-red-500`}>
                  {errors.lastName}
                </Text>
              )}
              <input
                name="lastName"
                onChange={handleInputChange}
                value={data?.lastName}
                type="text"
                placeholder="Entrer votre nom"
                className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${errors?.lastName && "border border-red-500"}`}
              />
            </div>
            <div className="flex grow flex-col gap-2">
              <Text className="font-semibold">
                Prénom<span style={{ color: "red" }}> * </span>
              </Text>
              {errors?.firstName && (
                <Text className="text-sm text-red-500">{errors.firstName}</Text>
              )}
              <input
                onChange={handleInputChange}
                name="firstName"
                value={data?.firstName}
                type="text"
                placeholder="Entrer votre prénom"
                className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${errors?.firstName && "border border-red-500"}`}
              />
            </div>
          </div>
          <PhoneValidationComponent
            error={errors}
            setError={setErrors}
            phoneNumber={phoneNumber}
            phoneNumberIsValidate={phoneNumberIsValidate}
            setPhoneNumber={setPhoneNumber}
            setPhoneNumberIsValidate={setPhoneNumberIsValidate}
            phoneNumberId={phoneNumberId}
            setPhoneNumberId={setPhoneNumberId}
          />
          <div className="flex flex-row gap-4 max-md:flex-col">
            <div className="flex grow flex-col gap-2">
              <div className="flex flex-row items-center gap-1">
                <Text className="font-semibold">Genre</Text>
              </div>
              <select
                value={data?.gender || "unspecified"} // Auto-select the gender from data
                name="gender"
                onChange={handleInputChange}
                className="rounded-lg border p-2 focus:outline-blueViolet-600"
              >
                <option value="unspecified">Séléctionner une réponse</option>
                <option value="male">Homme</option>
                <option value="female">Femme</option>
                <option value="other">Autre</option>
              </select>
            </div>
            <div className="flex grow flex-col gap-2">
              <div className="flex flex-row items-center gap-1">
                <Text className="font-semibold">Date de naissance</Text>
              </div>
              <input
                value={data?.birth}
                onChange={handleInputChange}
                type="date"
                name="birth"
                placeholder="Entrer votre date de naissance"
                className="rounded-lg border p-2 focus:outline-blueViolet-600"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex-1 text-center">
            <Text>Étape {step + 1}/5</Text>
          </div>
          <div onClick={nextStep}>
            <Button type="full">
              {isLoading ? <div className="loader" /> : "Suivant"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Three;
