// Requires
import { motion } from "framer-motion";
import { useEffect, useState, useCallback } from "react";
import { Navigate } from "react-router-dom";

// Components
import FindJobsTopFilter from "../../../../components/StudentFindJobs/FindJobsTopFilter";
import JobCardExtra from "../../../../components/Jobs/Card/JobCardExtra";
import Title from "../../../../components/UI/Title";
import Filters, {
  FilterType
} from "../../../../components/StudentFindJobs/FindJobsNewFilters";

// Services
import { getAllJobs } from "../../../../services/Job/job";
import {
  getCategories,
  getLanguages,
  getSkills
} from "../../../../services/Misc/misc";
import { fetchApi } from "../../../../services/Misc/api";

// Types
import type { JobsType } from "../../../../types/job";
import { LanguagesType, SkillType } from "../../../../types/misc";

// Context
import { useUI } from "../../../../utils/context/UI";
import { getDegreeName } from "../../../../utils/helpers/getDegreeName";

const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

type FiltersSelectedType = {
  city: string;
  search: string;
};

const Jobs = () => {
  const { findFilterIsOpen } = useUI();

  const [data, setData] = useState<JobsType>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [filtersSelected, setFiltersSelected] = useState<FiltersSelectedType>({
    city: "",
    search: ""
  });
  const debouncedFilters = useDebounce(filtersSelected, 300);

  // Define filtersConfig as a state variable
  const [filtersConfig, setFiltersConfig] = useState<FilterType[]>([
    {
      name: "workMode",
      label: "Mode de travail",
      type: "select",
      options: ["remote", "onSite", "hybrid"],
      optionsLabels: ["À distance", "Présentiel", "Hybride"]
    },
    {
      name: "type",
      label: "Type",
      type: "select",
      options: ["recurent", "punctual"],
      optionsLabels: ["Récurrente", "Ponctuelle"]
    },
    {
      name: "duration",
      label: "Durée de la mission (h)",
      type: "range"
    },
    {
      name: "hourlyRate",
      label: "Taux horaire",
      type: "range"
    }
  ]);

  const handleUpdateFilter = (type: string, value: string) => {
    if (type === "reset") {
      setFiltersSelected({
        city: "",
        search: ""
      });
    }
    setFiltersSelected((prev) => ({
      ...prev,
      [type]: value
    }));
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        // Fetch Degrees
        const degreeResponse = await fetchApi("/misc/degrees", "GET");
        let degreeFilter: FilterType | null = null;
        if (degreeResponse) {
          const degrees = Object.keys(degreeResponse);
          degreeFilter = {
            name: "degree",
            label: "Diplôme",
            type: "select",
            options: degrees.map((degree: any) => degree),
            optionsLabels: degrees.map(
              (degree: any) => getDegreeName(degree) || ""
            )
          };
        }

        // Fetch Languages
        const languagesResponse = await getLanguages("");
        const languagesFilter: FilterType = {
          name: "languages",
          label: "Langues",
          type: "multiselect",
          options: languagesResponse.map((lang: LanguagesType) =>
            lang.id.toString()
          ),
          optionsLabels: languagesResponse.map(
            (lang: LanguagesType) => lang.title
          ),
          withSearch: true
        };

        // Fetch Skills
        const skillsResponse = await getSkills("");
        const skillsFilter: FilterType = {
          name: "skills",
          label: "Compétences",
          type: "multiselect",
          options: skillsResponse.map((skill: SkillType) =>
            skill.id.toString()
          ),
          optionsLabels: skillsResponse.map((skill: SkillType) => skill.title),
          withSearch: true
        };

        // Fetch Categories
        const categoriesResponses = await getCategories();
        const categoriesFilter: FilterType = {
          name: "categories",
          label: "Catégories",
          type: "multiselect",
          options: categoriesResponses.map((cat: any) => cat.id.toString()),
          optionsLabels: categoriesResponses.map((cat: any) => cat.title),
          withSearch: true
        };

        // Construct new filtersConfig
        const newFiltersConfig: FilterType[] = [
          ...[
            {
              name: "workMode",
              label: "Mode de travail",
              type: "select",
              options: ["remote", "onSite", "hybrid"],
              optionsLabels: ["À distance", "Présentiel", "Hybride"]
            },
            {
              name: "type",
              label: "Type",
              type: "select",
              options: ["recurent", "punctual"],
              optionsLabels: ["Récurrente", "Ponctuelle"]
            },
            {
              name: "duration",
              label: "Durée de la mission (h)",
              type: "range"
            },
            {
              name: "hourlyRate",
              label: "Taux horaire",
              type: "range"
            }
          ],
          degreeFilter,
          languagesFilter,
          skillsFilter,
          categoriesFilter
        ].filter(Boolean) as FilterType[]; // Filter out any nulls

        setFiltersConfig(newFiltersConfig);
      } catch (error) {
        console.error("Erreur lors de la récupération des filtres:", error);
      }
    };

    fetchFilters();
  }, []);

  const fetchData = useCallback(
    async (pageNumber: number) => {
      setIsLoading(true);
      const response = await getAllJobs(pageNumber, 20, debouncedFilters);

      if (response) {
        setData((prev) =>
          pageNumber === 0
            ? response
            : {
                count: response.count + (prev?.count || 0),
                jobs: [...(prev?.jobs || []), ...response.jobs]
              }
        );
        setInitialDataLoaded(true);
        if (response?.jobs?.length < 20) {
          setHasMoreData(false);
        }
      } else {
        setError(
          "Vous n'avez pas accès à cette page car votre certificat de scolarité et/ou votre entreprise n'est pas vérifié"
        );
      }
      setIsLoading(false);
    },
    [debouncedFilters]
  );

  useEffect(() => {
    setPage(0);
    setHasMoreData(true);
    setInitialDataLoaded(false);
    fetchData(0);
  }, [debouncedFilters, fetchData]);

  if (error) {
    return <Navigate to="/unauthorized" />;
  } else {
    return (
      <div className="flex flex-col gap-24 max-md:gap-8">
        <div className="flex flex-row items-center justify-between">
          <Title type="page">Parcourez l'ensemble des missions</Title>
        </div>
        <div className="flex flex-col gap-8">
          <FindJobsTopFilter handleUpdateFilter={handleUpdateFilter} />
          <div className="flex w-full flex-col gap-8">
            <motion.div
              className="flex-nowrap"
              initial={{ width: "0px", x: -15, display: "none" }}
              animate={{
                width: findFilterIsOpen ? "100%" : "0px",
                x: findFilterIsOpen ? 0 : -15,
                display: findFilterIsOpen ? "flex" : "none"
              }}
            >
              <Filters
                filters={filtersConfig}
                handleUpdateFilter={handleUpdateFilter}
              />
            </motion.div>
            <motion.div
              animate={{
                width: "100%"
              }}
              className="flex flex-col flex-wrap gap-8"
            >
              {data?.jobs?.length && data.jobs.length > 0 ? (
                data?.jobs.map((item) => (
                  <JobCardExtra key={item.id} item={item} />
                ))
              ) : isLoading ? (
                <div className="flex h-fit w-full justify-center">
                  <div className="loader" />
                </div>
              ) : (
                <div className="flex w-full items-center justify-center">
                  <p>Aucune mission trouvée</p>
                </div>
              )}
            </motion.div>
          </div>
        </div>
      </div>
    );
  }
};

export default Jobs;
