// Icons
import { Additem } from "iconsax-react";

// Components
import Text from "../../UI/Text";

const AddNewLine = ({ increment }: { increment: () => void }) => {
  return (
    <div className="flex flex-col gap-4">
      <div
        onClick={increment}
        className="flex cursor-pointer flex-row gap-4 rounded-lg border border-dashed p-4"
      >
        <div className="flex items-center justify-center rounded-lg bg-blueViolet-50 p-4">
          <Additem size={24} />
        </div>
        <div className="flex flex-row items-center gap-4">
          <Text>Ajouter une ligne supplémentaire</Text>
        </div>
      </div>
    </div>
  );
};

export default AddNewLine;
