// Icons
import { HambergerMenu, SearchNormal1 } from "iconsax-react";

// Context
import { useUI } from "../../utils/context/UI";

// Requires
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

// Components
import UserTools from "./Topbar/UserTools";

// Utils
import { Desktop } from "../../utils/mobile/responsive";
import { useUser } from "../../utils/store/userStore";

const Topbar = () => {
  const { toggleSidebar } = useUI();
  const navigate = useNavigate();

  const user = useUser((state) => state.userdata);

  const [query, setQuery] = useState<string>("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      navigate(`/company/find-talents?search=${query}`);
    }
  };

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex flex-row gap-2">
        <div
          onClick={toggleSidebar}
          className="flex cursor-pointer items-center justify-center rounded-lg bg-blueViolet-50 p-2"
        >
          <HambergerMenu size={18} />
        </div>
      </div>
      <Desktop>
        {user?.roleName === "company" && (
          <div className="flex flex-row items-center gap-2">
            <div className="relative inline-block h-fit">
              <Link
                to={`/company/find-talents?search=${query}`}
                className="absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer rounded-full bg-blueViolet-600 p-2 text-textGray"
              >
                <SearchNormal1 size={18} className="text-white" />
              </Link>
              <input
                onKeyDown={handleKeyDown}
                type="text"
                className="w-full rounded-full border p-3 pl-12 pr-12 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blueViolet-600"
                placeholder="Rechercher un talent"
                onChange={(e) => setQuery(e.currentTarget.value)}
              />
            </div>
            <Link
              className="cursor-pointer text-blueViolet-600"
              to="/company/jobs/publier-une-mission"
            >
              Publier une mission
            </Link>
          </div>
        )}
      </Desktop>
      <UserTools />
    </div>
  );
};

export default Topbar;
