// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

// Icons
import { Call, MoreCircle, Sms } from "iconsax-react";

// Components
import Text from "../../UI/Text";
import Avatar from "../../UI/Avatar/Avatar";

// Type
import type { companyMemberType } from "../../../types/company";

// Helpers
import { capitalizeWords } from "../../../utils/helpers/capitalizeWords";
import { capitalizeFirstLetter } from "../../../utils/helpers/capitalizeFirstLetter";

const CollaboratorHorizontalCardMin = ({
  item
}: {
  item: companyMemberType;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="flex min-w-[calc(50%-16px)] flex-col gap-6 rounded-lg border p-4 md:max-w-[calc(50%-16px)]">
      <div className="relative z-0 flex flex-row justify-between gap-4">
        <div className="flex flex-row gap-4">
          <Avatar link={item.image} className="size-12" />
          <div className="flex flex-col">
            <Text className="font-semibold">
              {capitalizeWords(`${item.firstName} ${item.lastName}`)}
            </Text>
            <Text className="text-sm">
              {capitalizeFirstLetter(
                item.role === "owner"
                  ? "Responsable"
                  : "En attente de validation"
              )}
            </Text>
          </div>
        </div>
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className="group relative h-fit cursor-pointer rounded-lg bg-blueViolet-50 p-2 hover:bg-blueViolet-100"
        >
          <MoreCircle className="group-hover:!text-blueViolet-600" size={18} />
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ y: 15, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 15, opacity: 0 }}
                className="absolute right-0 top-9 z-10 flex flex-col gap-2 rounded-lg border bg-white p-2 shadow-lg"
              >
                <Text className="cursor-pointer text-nowrap rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white">
                  Modifier le profil
                </Text>
                <Text className="cursor-pointer text-nowrap rounded-lg p-2 text-sm text-red-600 hover:bg-blueViolet-600 hover:text-white">
                  Supprimer le profil
                </Text>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Text className="font-semibold">Informations sur le contact</Text>
        <div className="flex flex-row items-center gap-2">
          <Call size={18} />
          <Text>{item.phoneNumber}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <Sms size={18} />
          <Text>{item.email}</Text>
        </div>
      </div>
    </div>
  );
};

export default CollaboratorHorizontalCardMin;
