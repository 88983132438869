// Icons
import { Additem } from "iconsax-react";

// Components
import Text from "../../UI/Text";

const AddNewAlternation = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center gap-2">
        <Text className="font-semibold">Périodes d'alternance</Text>
      </div>
      <div className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border p-4 hover:border-blueViolet-600">
        <div className="flex items-center justify-center rounded-lg bg-blueViolet-50 p-4">
          <Additem size={18} />
        </div>
        <Text>Ajouter une période d'alternance</Text>
      </div>
    </div>
  );
};

export default AddNewAlternation;
