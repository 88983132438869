// Icons
import { Paperclip, Send } from "iconsax-react";

// Requires
import { useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";

// Context
import { useChat } from "../../context/Chat/ChatContext";

// Components
import FileCard from "./FileCard";
import Text from "../UI/Text";

// Types
type FileData = {
  fileName: string;
  fileContent: string; // Base64 string without prefix
  fileType: string;
};

type Message = {
  jobId: number;
  studentId: number;
  content: string;
  files: { fileName: string; fileContent: string }[]; // Only name and Base64 content
};

// Styles for MentionsInput
const mentionInputStyles = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal"
  },
  highlighter: {
    overflow: "hidden"
  },
  input: {
    margin: 0,
    padding: 2
  },
  suggestions: {
    list: {
      backgroundColor: "#fff",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5"
      }
    }
  }
};

const ChatInput = () => {
  const [message, setMessage] = useState<string>("");

  // const [files, setFiles] = useState<FileData[]>([]);
  const [files, setFiles] = useState<FileData[]>([]);
  const [error, setError] = useState<string | null>(null); // New error state

  const inputRef = useRef<HTMLInputElement>(null);

  const { sendMessage } = useChat();

  // List of users to mention
  const mentions = [
    { id: "admin", display: "admin" },
    { id: "expert", display: "expert" }
  ];

  const sendMessageWrapper = async () => {
    if (!message.trim() && files.length === 0) {
      return;
    }

    sendMessage(message, files);
    setMessage("");
    setFiles([]);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setError(null); // Clear error on successful send
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessageWrapper();
    }
  };

  const handleFiles = () => {
    inputRef.current?.click();
  };

  // useEffect(() => {
  //   console.log("error", error);
  // }, [error]);

  const updateFiles = () => {
    if (inputRef.current?.files) {
      const fileArray = Array.from(inputRef.current.files);
      const MAX_FILE_SIZE = 3;
      const oversizedFiles = fileArray.filter(
        (file) => file.size > MAX_FILE_SIZE * 1024 * 1024
      ); // 10 MB

      if (oversizedFiles.length > 0) {
        setError(
          `Ce fichier dépasse la limite de ${MAX_FILE_SIZE}Mo: ${oversizedFiles.map((f) => f.name).join(", ")}`
        );
        return;
      }

      // setFiles((prevFiles) => [...prevFiles, ...fileArray]);
      setError(null);

      // If no oversized files, proceed to read files
      const promises = fileArray.map((file) => {
        return new Promise<FileData>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target?.result) {
              // Extract Base64 string by removing the Data URL prefix
              const base64String = (e.target.result as string).split(",")[1];
              resolve({
                fileName: file.name,
                fileContent: base64String, // Only Base64 content
                fileType: file.type
              });
            } else {
              reject(new Error("File reading failed"));
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading file"));
          };
          reader.readAsDataURL(file); // Read as Data URL
        });
      });

      Promise.all(promises)
        .then((filesData) => {
          setFiles((prevFiles) => [...prevFiles, ...filesData]);
          setError(null); // Clear any previous errors
        })
        .catch((error) => {
          console.error("Error reading files:", error);
          setError("An error occurred while reading the files.");
          // Optionally, provide user feedback about the error
        });
    }
  };

  const removeFile = (index: number) => {
    setFiles((prev) => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  return (
    <div className="flex h-auto flex-row items-center gap-4 rounded-b-lg md:border-x md:border-b md:p-4">
      <div className="flex w-full flex-col gap-4 rounded-lg border p-4">
        <Text className="text-xs italic text-gray-500">
          Mentionnez @[nom de l'expert] pour le notifier directement
        </Text>
        <div className="flex w-full flex-row items-start">
          <MentionsInput
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={mentionInputStyles}
            placeholder="Écrire un message..."
            className="textareaautoresize focus:outline-blueviolet-600 flex-grow resize-none p-2 placeholder:p-2"
            onKeyDown={handleKeyDown}
          >
            <Mention
              trigger="@"
              data={mentions}
              style={{ color: "blue" }}
              appendSpaceOnAdd={true}
            />
          </MentionsInput>
          <div
            className="rounded-lg bg-blueViolet-50 p-2"
            onClick={sendMessageWrapper}
          >
            <Send
              size={18}
              className="cursor-pointer text-withCustom-500 hover:text-blueViolet-600"
            />
          </div>
        </div>
        {/* Display Error Message */}
        {error && (
          <div className="mt-2 rounded bg-red-100 p-2 text-red-700">
            {error}
          </div>
        )}
        <div className="flex flex-col gap-2">
          {files.length > 0 && (
            <div className="flex w-full flex-row flex-wrap gap-2">
              {files.map((file, index) => (
                <FileCard
                  key={`${file.fileName}-${index}`} // More unique key
                  file={{
                    fileName: file.fileName,
                    fileType: file.fileType
                  }}
                  // <FileCard
                  //   key={`${file.name}-${index}`} // More unique key
                  //   file={{
                  //     fileName: file.name,
                  //     fileType: file.type
                  //   }}
                  removeFile={() => removeFile(index)}
                />
              ))}
            </div>
          )}
          <div
            onClick={handleFiles}
            className="w-fit rounded-lg bg-blueViolet-50 p-2 hover:bg-blueViolet-100"
          >
            <Paperclip
              size={18}
              className="cursor-pointer text-withCustom-500 hover:text-blueViolet-600"
            />
          </div>
        </div>
        <input
          onChange={updateFiles}
          ref={inputRef}
          type="file"
          className="hidden"
          multiple
          accept=".png,.jpg,.jpeg,.gif,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp3,.mp4,.zip,.rar,.ai,.psd,.xd"
        />
      </div>
    </div>
  );
};

export default ChatInput;
