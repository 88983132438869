// Requires
import { useEffect } from "react";

// Components
import Chat from "../../../components/Chat/Chat";
import { LastDocumentsStatic } from "../../../components/Documents/LastDocuments";
import MyExpertInformations from "../../../components/MyExpert/MyExpertInformations";
import Title from "../../../components/UI/Title";

// Helpers
import { capitalizeWords } from "../../../utils/helpers/capitalizeWords";

// Context
import { useChat } from "../../../context/Chat/ChatContext";

// Utils
import { Desktop } from "../../../utils/mobile/responsive";
import { useUser } from "../../../utils/store/userStore";

const MyExpert = () => {
  const user = useUser((state) => state.userdata);

  const {
    setActiveConversation,
    activeConversation,
    fetchConversations,
    setIsExpertMode,
    isExpertMode,
    conversations
  } = useChat();

  useEffect(() => {
    if (isExpertMode) {
      fetchConversations();
    }
  }, [isExpertMode]);

  useEffect(() => {
    if (activeConversation === undefined && conversations?.length) {
      // console.log(conversations);
      setActiveConversation(conversations?.[0]);
    }
  }, [conversations]);

  useEffect(() => {
    setIsExpertMode(true);
    return () => {
      setIsExpertMode(false);
    };
  }, [activeConversation]);

  return (
    <div className="flex h-full flex-col gap-8">
      <Title type="page">
        Bonjour {capitalizeWords(`${user?.firstName} ${user?.lastName}`)},
        discutons de vos besoins !
      </Title>
      <div className="relative flex grow flex-col gap-8 md:flex-row">
        <div className="flex flex-col gap-8 md:min-w-[350px]">
          <MyExpertInformations />
          <Desktop>
            <LastDocumentsStatic attachmentsType="conversationId" />
          </Desktop>
        </div>
        <Chat ldStatic={true} />
      </div>
    </div>
  );
};

export default MyExpert;
