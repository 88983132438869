// Requires
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// Icons
import { CloseSquare, Document as DocumentIcon, SmsEdit } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import StudentHorizontalCardExtraMin from "../Cards/Student/StudentHorizontalCardExtraMin";
import Button from "../UI/Button";
import Title from "../UI/Title";
import Description from "./Proposals/Description";
import Document from "./Proposals/Document";
import Estimation from "./Proposals/Estimation";
import Quotations from "./Proposals/Quotations";
import Text from "../UI/Text";

// Context
import { usePopup } from "../../context/Popup";

// Services
import {
  getProposalDetailsFromJob,
  responseToProposal
} from "../../services/Job/proposal";
import { newCompanyTechnicalAccount } from "../../services/Company/company";

// Utils
import { useUser } from "../../utils/store/userStore";

// Misc
import { _COUNTRY_LIST_ISO } from "../../utils/misc";

// Types
import { CreateCompanyTechnicalAccount } from "../../types/company";
import type { ProposalDetails } from "../../types/proposal";
import { ChatProvider } from "../../context/Chat/ChatProvider";
import { useChat } from "../../context/Chat/ChatContext";

export const ContactStudentButton = ({
  proposal
}: {
  proposal: ProposalDetails;
}) => {
  const { proposalId, closePopup } = usePopup();
  const { createConversation, setActiveConversation } = useChat();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const contactStudent = () => {
    if (proposal.student && proposalId) {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    setLoading(true);
    setError("");

    try {
      // const response = await emitSendEvent(dataToSend);
      const response = await createConversation(
        proposal.student.id,
        proposal.job.id,
        `Bonjour, je suis intéressé(e) par votre proposition pour la mission de ${proposal.job.title}. Pouvez-vous m'en dire plus ?`,
        []
      );

      // Assuming response.id contains the conversation ID
      if (response.id) {
        setActiveConversation(response);
        closePopup();
        navigate("/messages");
      } else {
        throw new Error("Invalid response from server.");
      }
    } catch (err: any) {
      setError(err.message || "An unexpected error occurred.");
      console.error("Error sending message:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button type={loading ? "disabled" : "fullLite"} onClick={contactStudent}>
      {/* <div className="group cursor-pointer rounded-lg border duration-100 hover:bg-blueViolet-600 hover:text-white"> */}
      <div className="flex flex-row items-center gap-2">
        <SmsEdit size={18} />
        <Text className="text-blueViolet-600">Contacter l'étudiant</Text>
      </div>
      {/* </div> */}
    </Button>
  );
};

const ProposalDetailsPopup = () => {
  const { proposalId, closePopup } = usePopup();

  const [data, setData] = useState<ProposalDetails>();
  const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const navigate = useNavigate();

  const user = useUser((state) => state.userdata);

  const AccepteOrRejectProposal = async (action: "accepted" | "refused") => {
    if (proposalId && user) {
      const response = await responseToProposal(proposalId, action, user);
      if (response) {
        closePopup();
        navigate(
          `/company/manages-jobs/candidatures/payment/${response.jobHiringId}`
        );
      }
    }
  };

  const handleTogglePopup = () => {
    setPopupIsOpen(!popupIsOpen);
  };

  useEffect(() => {
    const fetch = async () => {
      if (proposalId) {
        const response = await getProposalDetailsFromJob(4, proposalId);
        if (response) {
          setData(response);
          return;
        }

        setError(
          "Une erreur est survenue durant le chargement des informations.."
        );
      }
    };

    fetch();
  }, [proposalId]);

  if (data) {
    return (
      <div className="relative flex flex-col gap-4 p-4">
        <BlocTitle>
          <DocumentIcon size={18} />
          <Title type="custom" classname="text-basep">
            Proposition commerciale
          </Title>
        </BlocTitle>
        <Content>
          <div className="flex flex-row gap-4">
            <div className="flex w-[calc(100%-370px)] flex-col gap-4">
              <Description data={data.motivation} />
              <Quotations data={data?.quotations} />
            </div>
            <div className="flex min-w-[350px] max-w-[350px] flex-col gap-4">
              <Estimation data={data.quotations} />
              <Document data={data?.attachment} />
              <div className="group cursor-pointer rounded-lg border duration-100 hover:bg-blueViolet-600 hover:text-white">
                <StudentHorizontalCardExtraMin data={data.student} />
              </div>
            </div>
          </div>
        </Content>
        <div className="flex flex-row-reverse items-end gap-4">
          <ChatProvider>
            <ContactStudentButton proposal={data} />
          </ChatProvider>
          <div
            onClick={
              user?.hasTechnicalAccount
                ? () => AccepteOrRejectProposal("accepted")
                : () => handleTogglePopup()
            }
          >
            <Button type="full">Accepter la proposition</Button>
          </div>
          <div onClick={() => AccepteOrRejectProposal("refused")}>
            <Button type="fullLite" className="w-fit">
              Refuser la proposition
            </Button>
          </div>
        </div>
        <AnimatePresence>
          {popupIsOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute left-0 top-0 h-full w-full bg-black bg-opacity-45"
            />
          )}
        </AnimatePresence>
        <AnimatePresence key={"popupAnimation"}>
          {popupIsOpen && <Popup togglePopup={handleTogglePopup} />}{" "}
        </AnimatePresence>
      </div>
    );
  }

  if (error) {
    return <p className="p-4">{error}</p>;
  }

  return (
    <div className="p-4">
      <div className="loader" />
    </div>
  );
};

const Popup = ({ togglePopup }: { togglePopup: () => void }) => {
  const [data, setData] = useState<CreateCompanyTechnicalAccount>({
    birthdate: "",
    country: "",
    nationality: ""
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const createTechnicalAccount = async () => {
    const response = await newCompanyTechnicalAccount(data);
    if (response) {
      // TODO: Close popup and accept proposal ?
    }
  };

  return (
    <motion.div
      key={"popupAnimation2"}
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 5 }}
      className="l-0 fixed inset-0 top-0 flex h-screen w-screen items-center justify-center"
    >
      <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-lg">
        <div className="flex flex-row items-center justify-between gap-8">
          <Title type="bloc">Informations supplémentaires</Title>
          <CloseSquare
            size={18}
            onClick={togglePopup}
            className="cursor-pointer"
          />
        </div>
        <Text>
          Pour valider une proposition, nous avons besoin d'information
          supplémentaires.
        </Text>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <label htmlFor="">Pays de l'entreprise</label>
            <select
              className="rounded-lg border p-2"
              name="country"
              value={data.country}
              onChange={handleInputChange}
            >
              {_COUNTRY_LIST_ISO.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="">
              Date de naissance du dirigeant de l'entreprise
            </label>
            <input
              type="date"
              name="birthdate"
              className="rounded-lg border p-2"
              value={data.birthdate}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="">Nationnalité du dirigeant de l'entreprise</label>
            <select
              className="rounded-lg border p-2"
              name="nationality"
              value={data.nationality}
              onChange={handleInputChange}
            >
              {_COUNTRY_LIST_ISO.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div onClick={createTechnicalAccount}>
          <Button type="full">Enregistrer</Button>
        </div>
      </div>
    </motion.div>
  );
};

export default ProposalDetailsPopup;
