// Requires
import { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

// Mobiles
import { Capacitor } from "@capacitor/core";
import {
  type ActionPerformed,
  PushNotifications,
  type PushNotificationSchema,
  type Token
} from "@capacitor/push-notifications";
import { fcm } from "../../../services/User/user";

// Components
import Sidebar from "../../../components/Sidebar/Sidebar";
import FullScreenPopup from "../../../components/UI/FullScreenPopup";
import Topbar from "../../../components/UI/Topbar";
import UserErrors from "../../../components/UI/Warnings/UserErrors";
import UserWarnings from "../../../components/UI/Warnings/UserWarnings";
import FloatingNotification from "../../../components/UI/FloatingNotification/FloatingNotification";

// Context
import { useUI } from "../../../utils/context/UI";
import { useFloatingNotification } from "../../../context/FloatingNotification";

// Utils
import { useMobile } from "../../../utils/mobile/responsive";
import { useUser } from "../../../utils/store/userStore";

const Layout = ({ children }: { children: React.ReactElement }) => {
  const getUser = useUser((state) => state.getUser);
  const user = useUser((state) => state.userdata);

  const { isOpen, message } = useFloatingNotification();

  const navigate = useNavigate();

  const { sidebarIsOpen, toggleSidebar } = useUI();

  const isMobile = useMobile();

  const { resetStep } = useUI();

  useEffect(() => {
    const fetch = async () => {
      resetStep();
      const response = await getUser();

      if (!response) {
        navigate("/auth/login");
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          PushNotifications.register();
        }
      });

      PushNotifications.addListener("registration", async (token: Token) => {
        await fcm(token.value);
      });

      PushNotifications.addListener("registrationError", (error: any) => {
        alert("Error on registration: " + JSON.stringify(error));
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotificationSchema) => {
          alert("Push received: " + JSON.stringify(notification));
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          alert("Push action performed: " + JSON.stringify(notification));
        }
      );
    }
  }, []);

  if (user) {
    return (
      <>
        <div className="flex h-screen flex-row">
          <Sidebar />
          <div
            className="flex grow flex-col items-center gap-4 overflow-y-scroll p-4 md:gap-8 md:p-4"
            onClick={() => {
              if (sidebarIsOpen && isMobile) {
                toggleSidebar();
              }
            }}
          >
            <Topbar />
            {user.roleName !== "admin" && user.roleName !== "company" && (
              <div className="flex w-full flex-col gap-2">
                <UserWarnings />
                <UserErrors />
              </div>
            )}
            <div className="w-full max-w-[1920px] grow">{children}</div>
          </div>
        </div>
        <FullScreenPopup />
        <AnimatePresence>
          {isOpen && (
            <FloatingNotification position="bottom-center">
              {message}
            </FloatingNotification>
          )}
        </AnimatePresence>
      </>
    );
  }

  return null;
};

export default Layout;
