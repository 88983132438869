// Requires
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

// Icons
import { ChartCircle } from "iconsax-react";

// Components
import Title from "../UI/Title";

// Types
import { studentGenderStats } from "../../types/admin";

const Parity = ({ data }: { data: studentGenderStats }) => {
  const data2: { name: string; value: number; percentage: number }[] = [
    {
      name: "Homme",
      value: data.counts.male,
      percentage: data.percentages.male
    },
    {
      name: "Femme",
      value: data.counts.female,
      percentage: data.percentages.female
    },
    {
      name: "Non spécifié",
      value: data.counts.notSpecified + data.counts.other,
      percentage:
        Number(data.percentages.notSpecified) + Number(data.percentages.other)
    }
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <div className="flex max-w-[500px] flex-col rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <ChartCircle />
        <Title type="bloc">Parité des étudiants</Title>
      </div>
      <div className="relative flex flex-1 items-center justify-center">
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={data2}
              startAngle={180}
              endAngle={0}
              innerRadius="50%"
              outerRadius="80%"
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {data2.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex flex-row gap-6">
          {data2.map((item, _id) => (
            <div key={_id} className="flex h-fit flex-row gap-1">
              <div
                className="h-auto w-[15px] rounded-lg"
                style={{ backgroundColor: COLORS[_id] }}
              />
              <div className="flex flex-col text-sm">
                <p>{item.name}</p>
                <p>{item.percentage}%</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Parity;
