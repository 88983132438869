// Requires
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Icons
import { CloseSquare } from "iconsax-react";

// Components
import Title from "../../../UI/Title";
import Text from "../../../UI/Text";
import Button from "../../../UI/Button";
import CustomTooltip from "../../../UI/Tooltip";
import JobSearch from "../../../Misc/JobSearch";

// Context
import { usePopup } from "../../../../context/Popup";
import { useFloatingNotification } from "../../../../context/FloatingNotification";

// Services
import { getSpecificUser } from "../../../../services/User/user";
import { setInShortlist } from "../../../../services/Admin/Jobs";

// Types
import { User } from "../../../../types/user";
import { JobDetailsType } from "../../../../types/job";

const Shortlist = () => {
  const { studentsChecked, closePopup } = usePopup();
  const [dataStudents, setDataStudents] = useState<User[]>([]);
  const [job, setJob] = useState<JobDetailsType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { toggleFloatingNotification } = useFloatingNotification();

  const handleSelectJob = (data: JobDetailsType) => {
    setJob(data);
  };

  const handleValidate = async () => {
    if (job && job.studentSelected && studentsChecked) {
      const total = job?.studentSelected.length + studentsChecked.length;
      if (total > 3) {
        alert(
          "Le nombre maximum d'étudiants dans la shortlist ne doit pas dépasser 3"
        );
        return;
      }
    }

    if (job && studentsChecked && studentsChecked.length > 0) {
      setIsLoading(true);
      const response = await setInShortlist(job?.id, studentsChecked);
      if (response) {
        setIsLoading(false);
        closePopup();
        toggleFloatingNotification(
          "Les étudiants ont bien étaient ajoutés à la shortlist de la mission séléctionnée"
        );
        return;
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchStudents = async () => {
      if (studentsChecked && studentsChecked.length > 0) {
        const studentPromises = studentsChecked.map((studentId) =>
          getSpecificUser(studentId)
        );
        const responses = await Promise.all(studentPromises);
        setDataStudents(responses);
      }
    };

    fetchStudents();
  }, [studentsChecked]);

  if (!dataStudents.length) {
    return (
      <div className="p-4">
        <div className="loader" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex flex-row items-center justify-between gap-12">
        <Title type="bloc">Ajouter dans la shortlist d'une mission</Title>
        <CloseSquare
          size={18}
          onClick={closePopup}
          className="cursor-pointer hover:text-blueViolet-600"
        />
      </div>

      <div className="flex flex-col gap-2">
        <Text>Vous avez sélectionné les étudiants suivants :</Text>
        <div className="flex w-fit flex-row gap-4 rounded-lg border p-2">
          {dataStudents.map((student, index) => (
            <CustomTooltip
              key={index}
              id={index.toString()}
              position="top"
              content={`${student.firstName} ${student.lastName}`}
            >
              <Link target="_blank" to={`/admin/student/${student.id}`}>
                <img
                  src={student.image}
                  alt={`${student.firstName} ${student.lastName}`}
                  className="size-11 rounded-lg"
                />
              </Link>
            </CustomTooltip>
          ))}
        </div>
      </div>

      <JobSearch callback={handleSelectJob} />

      {job && (
        <Text>
          Il y à actuellement{" "}
          <span className="font-semibold">{job.studentSelected?.length}</span>{" "}
          étudiants dans la shortlist de la mission séléctionné.
        </Text>
      )}

      <div onClick={handleValidate}>
        <Button type="full">
          {isLoading ? <div className="loader" /> : "Valider"}
        </Button>
      </div>
    </div>
  );
};

export default Shortlist;
