// Requires
import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

// Components
import Text from "../../../UI/Text";
import Tag from "../../../UI/Tag";

// Services
import {
  getStudents,
  toggleCallTalentFinder,
  toggleSchoolCertificate
} from "../../../../services/Admin/Student";

// Icons
import { CloseSquare, TickSquare } from "iconsax-react";

// Utils
import { capitalizeFirstLetter } from "../../../../utils/helpers/capitalizeFirstLetter";
import { getDegreeName } from "../../../../utils/helpers/getDegreeName";

// Types
import { SkillType } from "../../../../types/misc";

// Context
import { useFloatingNotification } from "../../../../context/FloatingNotification";
import { usePopup } from "../../../../context/Popup";

// Types
interface StudentType {
  displayName: string;
  profileTitle: string;
  category: string;
  phoneNumber: string;
  skills: string;
  city: string;
  email: string;
  siret: string;
  languages: string;
  degree: string;
  callTalentFinder: string;
  certificate: string;
  actualDegree: string;
  publicMark: string;
  privateMark: string;
}

type Student = {
  actualDegree: string;
  call: boolean;
  category: {
    id: number;
    title: string;
  };
  city: {
    department_number: string;
    id: number;
    label: string;
    region_geojson_name: string;
    zip_code: string;
  };
  company: {
    siret: string;
    verified: boolean;
  };
  displayName: string;
  email: {
    confirmed: boolean;
    value: string;
  };
  id: number;
  languages: {
    id: number;
    title: string;
  }[];
  phoneNumber: {
    confirmed: boolean;
    value: string;
  };
  profileTitle: string;
  skills: SkillType[];
  studentSchoolCertificate: {
    end: string;
    sent: {
      createdAt: string;
      fileExt: string;
      fileName: string;
      id: number;
      size: number;
      type: string;
      url: string;
    };
    verified: boolean;
  };
  tih: boolean;
  hasDoneCall: boolean;
};

const TableAdminStudents = ({
  colToDisplay,
  checkCallback,
  resetCheckedCallback
}: {
  colToDisplay?: {
    name: string;
    checked: boolean;
    dataValue: keyof StudentType;
  }[];
  checkCallback?: (id: number) => void;
  resetCheckedCallback: () => void;
}) => {
  const [data, setData] = useState<Student[]>([]);
  const [count, setCount] = useState<number>();
  const [limit, setLimit] = useState<number>(10);
  const [tempLimit, setTempLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [jobId, setJobId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const { toggleFloatingNotification } = useFloatingNotification();

  const { togglePopup } = usePopup();

  const filters = {
    search: searchParams.get("search") || "",
    categories: searchParams.get("categories") || "",
    city: searchParams.get("city") || "",
    degrees: searchParams.get("degrees") || "",
    skills: searchParams.get("skills") || "",
    languages: searchParams.get("languages") || "",
    school: searchParams.get("school") || ""
  };

  const columns: {
    name: string;
    checked: boolean;
    dataValue: keyof StudentType;
  }[] = colToDisplay?.filter((item) => item.checked) || [
    { name: "Nom complet", checked: true, dataValue: "displayName" }
  ];

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    jobId: number
  ) => {
    const el = event.currentTarget;
    const rect = el.getBoundingClientRect();

    setPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    });

    setJobId(jobId);
    setIsOpen(!isOpen);
  };

  const resolveDataValue = (item: Student, dataValue: keyof StudentType) => {
    switch (dataValue) {
      case "displayName":
        return item.displayName ? (
          <Link to={`/admin/student/${item.id}`}>{item.displayName}</Link>
        ) : (
          "N/A"
        );
      case "profileTitle":
        return item.profileTitle ?? "N/A";
      case "category":
        return item.category?.title ?? "N/A";
      case "phoneNumber":
        return item.phoneNumber?.value ?? "N/A";
      case "skills":
        return item.skills.length > 0 ? (
          <div className="flex max-w-[250px] flex-row flex-wrap gap-2">
            {item.skills.slice(0, 5).map((skill, _id) => (
              <Tag key={_id}>{skill.title}</Tag>
            ))}
          </div>
        ) : (
          "N/A"
        );
      case "city":
        return capitalizeFirstLetter(item.city?.label) ?? "N/A";
      case "email":
        return item.email?.value;
      case "certificate":
        return item.studentSchoolCertificate?.verified ? (
          <TickSquare size={18} color="green" />
        ) : (
          <div className="flex flex-row items-center gap-4">
            <CloseSquare size={18} color="red" />
            <Link to={item.studentSchoolCertificate?.sent?.url ?? "#"}>
              {item.studentSchoolCertificate?.sent?.url
                ? "Voir"
                : "Indisponible"}
            </Link>
          </div>
        );
      case "siret":
        return item.company.siret ?? <CloseSquare size={18} color="red" />;
      case "callTalentFinder":
        return item.hasDoneCall ? (
          <TickSquare size={18} color="green" />
        ) : (
          <CloseSquare size={18} color="red" />
        );
      case "languages":
        return (
          <div className="flex max-w-[250px] flex-row flex-wrap gap-2">
            {item.languages.slice(0, 5).map((language, _id) => (
              <Tag key={_id}>{language.title}</Tag>
            ))}
          </div>
        );
      case "degree":
        return (
          <Text>
            {capitalizeFirstLetter(
              getDegreeName(item.actualDegree) || "Non spécififié"
            )}
          </Text>
        );
      default:
        return "N/A";
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      setTempLimit(numericValue);
    }
  };

  const handleSelectAll = () => {
    if (allChecked) {
      setCheckedItems([]);
      resetCheckedCallback();
    } else {
      const visibleCompanyIds = data.map((item) => item.id);
      setCheckedItems(visibleCompanyIds);
      if (checkCallback) {
        data.map((item) => {
          checkCallback(item.id);
        });
      }
    }
    setAllChecked(!allChecked);
  };

  const handleCheckboxChange = (id: number) => {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };

  const updateSchoolCertificate = async (studentId: number) => {
    const response = await toggleSchoolCertificate(studentId);

    if (response) {
      toggleFloatingNotification(
        "La validité du certificat de scolarité a bien été modifiée"
      );

      // Update the `verified` status of the student's school certificate in the data state
      setData((prevData) =>
        prevData.map((student) =>
          student.id === studentId
            ? {
                ...student,
                studentSchoolCertificate: {
                  ...student.studentSchoolCertificate,
                  verified: !student.studentSchoolCertificate.verified // Toggle the verified status
                }
              }
            : student
        )
      );
    }
  };

  const updateCallTalentFinder = async (studentId: number) => {
    const response = await toggleCallTalentFinder(studentId);

    if (response) {
      toggleFloatingNotification("La validité du call a bien été modifiée");

      setData((prevData) =>
        prevData.map((student) =>
          student.id === studentId
            ? {
                ...student,
                hasDoneCall: !student.hasDoneCall
              }
            : student
        )
      );
    }
  };

  const handleSetNotation = async (studentId: number) => {
    if (studentId) {
      togglePopup("setNotation", undefined, [studentId]);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tempLimit < 10) {
        setLimit(10);
        setTempLimit(10);
      } else if (tempLimit > 100) {
        setLimit(100);
        setTempLimit(100);
      } else {
        setLimit(tempLimit);
      }

      setOffset(0);
      setPage(0);
    }, 500);

    return () => clearTimeout(timer);
  }, [tempLimit]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getStudents(page, limit, filters);
      if (response) {
        setData(response.students);
        setCount(response.count);
      }
    };

    fetch();
  }, [
    page,
    limit,
    filters.search,
    filters.categories,
    filters.city,
    filters.degrees,
    filters.skills,
    filters.languages,
    filters.school
  ]);

  return (
    <div className="flex flex-col gap-4">
      <table className="w-full table-auto">
        <thead className="border-b border-t bg-backgroundGray">
          <tr>
            <th>
              <input
                checked={allChecked}
                onChange={handleSelectAll}
                type="checkbox"
                className="checkbox-large !h-4 !w-4 cursor-pointer"
              />
            </th>
            {columns.map((column) => (
              <th key={column.name} className="px-4 py-2 text-left">
                {column.name}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody className="overflow-scroll">
          {data.map((item) => {
            return (
              <tr key={item.id} className="border-b pb-6 pt-6">
                <td className="px-4 py-2 pb-6 pt-6 text-left">
                  <input
                    type="checkbox"
                    className="checkbox-large !h-4 !w-4 cursor-pointer"
                    checked={checkedItems.includes(item.id)}
                    onChange={
                      checkCallback
                        ? () => {
                            checkCallback(item.id);
                            handleCheckboxChange(item.id);
                          }
                        : undefined
                    }
                  />
                </td>
                {columns.map((column) => (
                  <td
                    key={column.name}
                    className="px-4 py-2 pb-6 pt-6 text-left"
                  >
                    {resolveDataValue(item, column.dataValue)}
                  </td>
                ))}
                <td
                  onClick={(event) => handleClick(event, item.id)}
                  className="relative border-t px-4 py-2"
                >
                  <Text className="cursor-pointer">...</Text>
                  <AnimatePresence>
                    {isOpen && jobId === item.id && position && (
                      <motion.div
                        style={{
                          position: "absolute",
                          zIndex: 50
                        }}
                        initial={{ opacity: 0, y: 15 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 15 }}
                        className="absolute right-3 flex flex-col gap-2 rounded-lg border bg-white p-4 shadow-lg"
                      >
                        {item.studentSchoolCertificate.sent?.url && (
                          <div onClick={() => updateSchoolCertificate(item.id)}>
                            <Text className="cursor-pointer text-nowrap rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white">
                              {item.studentSchoolCertificate.verified
                                ? "Supprimer la validation du certificat de scolarité"
                                : "Valider le certificat de scolarité"}
                            </Text>
                          </div>
                        )}
                        <div onClick={() => handleSetNotation(item.id)}>
                          <Text className="cursor-pointer text-nowrap rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white">
                            Mettre une note individuelle
                          </Text>
                        </div>
                        <div onClick={() => updateCallTalentFinder(item.id)}>
                          <Text className="cursor-pointer text-nowrap rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white">
                            {item.hasDoneCall
                              ? "Supprimer la validité du call"
                              : "Valider le call"}
                          </Text>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex flex-row justify-between border-t p-4 text-sm">
        <div className="flex flex-row items-center gap-2">
          <Text>Afficher</Text>
          <input
            type="number"
            className="w-[80px] rounded-lg border p-2 focus:outline-blueViolet-600"
            value={tempLimit}
            min={10}
            max={100}
            onChange={handleInputChange}
          />
          <Text>étudiants sur {count}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => {
              setOffset(offset - limit);
              setPage(page - 1);
            }}
            disabled={offset === 0}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &lt;
          </button>
          {count &&
            [...Array(Math.ceil(count / limit))].map((_, i) => {
              const pageNumber = i + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => {
                    setOffset((pageNumber - 1) * limit);
                    setPage(pageNumber - 1);
                  }}
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                    pageNumber === Math.ceil(offset / limit) + 1
                      ? "bg-blueViolet-600 text-white"
                      : ""
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}
          <button
            onClick={() => {
              setOffset(offset + limit);
              setPage(page + 1);
            }}
            disabled={count === undefined ? true : offset + limit >= count}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableAdminStudents;
