// Icons
import {
  ArrowUp2,
  CallCalling,
  FolderAdd,
  FolderOpen,
  Video
} from "iconsax-react";

// Components
import Text from "../UI/Text";

// Require
import { AnimatePresence, motion } from "framer-motion";

// Context
import { usePopup } from "../../context/Popup";
import { useUI } from "../../utils/context/UI";

const Actions = ({
  OpenLD,
  ldStatic
}: {
  OpenLD: () => void;
  ldStatic: boolean;
}) => {
  const { chatDropdownIsOpen, toggleChatDropdown } = useUI();
  const { lastDocumentsIsOpen, toggleLastDocuments } = useUI();

  const { togglePopup } = usePopup();

  return (
    <div className="flex w-full flex-1 flex-row items-center justify-end gap-4">
      <div className="group flex cursor-pointer items-center justify-center rounded-lg p-2 hover:bg-blueViolet-50">
        <CallCalling size={20} className="group-hover:!text-blueViolet-600" />
      </div>
      <div className="relative flex flex-row gap-2">
        <div
          onClick={toggleChatDropdown}
          className="group flex cursor-pointer flex-row gap-2 rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Video size={20} className="group-hover:text-blueViolet-600" />
          <motion.div animate={{ rotate: chatDropdownIsOpen ? 180 : 0 }}>
            <ArrowUp2 size={20} className="group-hover:text-blueViolet-600" />
          </motion.div>
        </div>
        <AnimatePresence>
          {chatDropdownIsOpen && (
            <motion.div
              initial={{ y: 15, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 15, opacity: 0 }}
              className="absolute right-0 top-10 z-50 flex flex-col gap-2 rounded-lg border bg-white p-4"
            >
              <div
                onClick={() => {
                  togglePopup("scheduleVideo");
                  toggleChatDropdown();
                }}
              >
                <Text className="cursor-pointer text-nowrap rounded-lg p-2 text-xs hover:bg-blueViolet-600 hover:text-white">
                  Programmer une visio
                </Text>
              </div>
              <Text className="cursor-pointer text-nowrap rounded-lg p-2 text-xs hover:bg-blueViolet-600 hover:text-white">
                Démarrer une visio
              </Text>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {!ldStatic && (
        <div className="group flex cursor-pointer items-center justify-center rounded-lg p-2 hover:bg-blueViolet-50">
          {lastDocumentsIsOpen ? (
            <FolderOpen
              onClick={OpenLD}
              size={20}
              className="group-hover:text-blueViolet-600"
            />
          ) : (
            <FolderAdd
              onClick={OpenLD}
              size={20}
              className="group-hover:text-blueViolet-600"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Actions;
