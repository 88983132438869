import { fetchApi } from "../Misc/api";

// Add internal score to a student (admin user)
export const addInternalScore = async (
  studentId: number,
  score: number,
  comment: string
) => {
  const data = {
    studentId,
    score,
    comment
  };

  const response = await fetchApi("/scoring/internal", "POST", data);

  if (response.error) return false;

  return true;
};
