// Components
import { useUser } from "../../../utils/store/userStore";
import Company from "./Administration/Company";
import ScolarityCertificate from "./Administration/ScolarityCertificate";

const Administration = () => {
  const _COMPANY_MEMBER_MODE = useUser(
    (state) => state.userdata?.roleName === "company"
  );

  return (
    <>
      {!_COMPANY_MEMBER_MODE && <ScolarityCertificate />}
      <Company />
    </>
  );
};

export default Administration;
