// Requires
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// Layout
import Layout from "./Layout";

// Store
import { useAuth } from "../../../utils/store/authStore";

const ProtectedRoute = () => {
  const user = useAuth((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.isLogged === false || user?.isLogged === undefined) {
      navigate("/auth/login");
    }
  }, [user?.isLogged, navigate]);

  if (user?.isLogged) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return null;
};

export default ProtectedRoute;
