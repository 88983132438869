// Requires
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../../utils/config";

// Components
import Button from "../../../../../components/UI/Button";
import { HorizontalDivider } from "../../../../../components/UI/Divider";
import Text from "../../../../../components/UI/Text";
import Title from "../../../../../components/UI/Title";

// Context
import { useUI } from "../../../../../utils/context/UI";

// Services
import { registerCompanyUser } from "../../../../../services/User/user";

// Helpers
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../utils/helpers/localStorage";

// Validators
import {
  validateEmail,
  validatePassword
} from "../../../../../utils/helpers/validators";

// Assets
import GoogleIcon from "../../../../../assets/Icons/google.svg";
import LinkedInIcon from "../../../../../assets/Icons/linkedin.svg";

// Types
type ErrorType = {
  email?: string;
  password?: string;
  terms?: string;
};

const One = () => {
  const { step, handleNextStep } = useUI();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType>({});
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  // Prefilled if possible
  useEffect(() => {
    const localData = getLocalStorage("companyRegisterInfos");
    if (localData) {
      const parsedData: { step1: { email: string; password: string } } =
        JSON.parse(localData);
      if (parsedData.step1) {
        setEmail(parsedData.step1.email);
      }
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      nextStep();
    }
  };

  const nextStep = async () => {
    setIsLoading(true);

    const newError: ErrorType = {};
    setError(newError);

    if (!email || !validateEmail(email)) {
      newError.email = "L'adresse email n'est pas dans un format correct";
    }
    if (!email || !validateEmail(email)) {
      newError.email = "L'adresse email n'est pas dans un format correct";
    }

    // Validation de la case à cocher
    if (!acceptedTerms) {
      newError.terms =
        "Vous devez accepter les conditions de la charte des entreprise Juwa";
    }

    if (!password || !validatePassword(password)) {
      newError.password =
        "Vous devez entrer un mot de passe au format valide. (1 Maj, 1 Min et 1 caractère spécial, 6 caractères min)";
    }
    if (!password || !validatePassword(password)) {
      newError.password =
        "Vous devez entrer un mot de passe au format valide. (1 Maj, 1 Min et 1 caractère spécial, 6 caractères min)";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      setIsLoading(false);
      return;
    }

    const data: {
      email: string;
      password: string;
      role: string;
    } = {
      email: email,
      password: password,
      role: "company"
    };

    const response = await registerCompanyUser(data);

    if (response.error) {
      newError.email = response.message;
      setError(newError);
      setIsLoading(false);
      return;
    }

    const localData = [
      { key: "companyRegisterStep", value: "1" },
      {
        key: "companyRegisterInfos",
        value: JSON.stringify({
          step1: { email: email, password: password }
        })
      }
    ];

    setLocalStorage(localData);
    setIsLoading(false);
    handleNextStep();
  };

  return (
    <div className="flex min-w-[50%] flex-col gap-12 md:max-w-[50%]">
      <div className="flex items-center justify-center">
        <Title type="page" classname="text-3xl">
          Commençons par créer vos identifiants
        </Title>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Link to={`${config.backUrl}/auth/register/google?role=company`}>
            <Button type="fullLite" className="w-full">
              <img src={GoogleIcon} alt="Google" sizes="18" /> Continuer avec
              Google
            </Button>
          </Link>
          <Link to={`${config.backUrl}/auth/register/linkedin?role=company`}>
            <Button type="fullLite" className="w-full">
              <img src={LinkedInIcon} alt="LinkedIn" sizes="18" /> Continuer
              avec LinkedIn
            </Button>
          </Link>
        </div>
        <div className="flex flex-row items-center gap-2">
          <HorizontalDivider />
          <Text>OU</Text>
          <HorizontalDivider />
        </div>
        <div className="flex flex-col gap-2">
          <Title type="bloc">
            Email<span style={{ color: "red" }}> * </span>
          </Title>
          <input
            onKeyDown={handleKeyDown}
            minLength={5}
            maxLength={50}
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            type="text"
            className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${error.email ? "border-red-500" : "border"}`}
            placeholder="nom@email-professionnel.com"
          />
          {error.email && (
            <Text className="text-sm text-red-500">{error.email}</Text>
          )}
          <Title type="bloc">
            Mot de passe<span style={{ color: "red" }}> * </span>
          </Title>
          <div className="relative">
            <input
              onKeyDown={handleKeyDown}
              minLength={5}
              maxLength={50}
              onChange={(e) => setPassword(e.currentTarget.value)}
              type={isPasswordVisible ? "text" : "password"}
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${error.password ? "border-red-500" : "border"}`}
              placeholder="Mot de passe"
            />
            <button
              type="button"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              className="absolute inset-y-0 right-0 px-3 py-2 text-sm text-gray-600"
            >
              {isPasswordVisible ? "Cacher" : "Montrer"}
            </button>
          </div>
          {error.password && (
            <Text className="text-sm text-red-500">{error.password}</Text>
          )}
        </div>
        {/* Ajout de la case à cocher */}
        <div className="flex flex-col gap-2">
          <label className="flex cursor-pointer items-center">
            <input
              type="checkbox"
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
              className="mr-2 h-4 w-4 cursor-pointer"
            />
            <span>
              J’accepte les conditions de la{" "}
              <a
                href="https://storagettv.blob.core.windows.net/static/Charte%20des%20entreprises%20JUWA.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                charte des entreprises Juwa
              </a>
              <span style={{ color: "red" }}> * </span>
            </span>
          </label>
          {error.terms && <Text className="text-red-500">{error.terms}</Text>}
        </div>

        {/* Ajout du texte en dessous avec le lien */}
        <div className="flex flex-col gap-2">
          <Text className="text-sm">
            En m’inscrivant sur Juwa, je m’engage à respecter les{" "}
            <a
              href="https://juwa.co/cgv-cgu"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Conditions Générales d’Utilisation de la plateforme
            </a>
            .
          </Text>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex-1 text-center">
          <Text>Étape {step + 1}/5</Text>
        </div>
        <div onClick={nextStep}>
          <Button type="full">
            {isLoading ? <div className="loader" /> : "Suivant"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default One;
