// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// Icons
import { People, TableDocument, Warning2 } from "iconsax-react";

// Components
import { BlocTitleWithButton } from "../../../../components/Bloc/Title";
import Table from "../../../../components/Table/Company/CompanyJobs/Table";
import Filters from "../../../../components/Table/Filters";
import Button from "../../../../components/UI/Button";
import Title from "../../../../components/UI/Title";
import Avatar from "../../../../components/UI/Avatar/Avatar";
import CollaboratorHorizontalCardMin from "../../../../components/Cards/Company/CollaborationHorizontalCardMin";
import Informations from "../../../../components/Company/Informations";

// Services
import { getCompany } from "../../../../services/Company/company";

// Store
import { useUser } from "../../../../utils/store/userStore";

// Type
import type { companyType } from "../../../../types/company";

// Helpers
import { capitalizeWords } from "../../../../utils/helpers/capitalizeWords";

// Misc
import { _COMPANY } from "../../../../utils/misc";

const Compagny = () => {
  const { id } = useParams();

  const user = useUser((state) => state.userdata);

  const [isTabOpen, setIsTabOpen] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<companyType>();

  const _ADMIN_MODE = useUser((state) => state.userdata?.roleName === "admin");
  const _IS_COMPANY_OWNER = !!data?.members.find(
    (member) => member.role === "owner" && member.userId === user?.id
  );

  console.log(_IS_COMPANY_OWNER);
  console.log(_ADMIN_MODE);

  const _TABS = [
    {
      index: 0,
      label: "Informations sur votre entreprise",
      icon: <Warning2 size={18} />,
      component: data && <Collaborateurs data={data} />
    },
    {
      index: 1,
      label: "Missions des collaborateurs",
      icon: <TableDocument size={18} />,
      component: <CompanyJobs />
    }
  ];

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const response = await getCompany(id ? Number(id) : undefined);
      if (response) setData(response);
      setIsLoading(false);
    };
    fetch();
  }, [id]);

  if (!isLoading) {
    return (
      <div className="flex flex-col gap-8">
        <div
          className="relative h-[250px] rounded-t-lg bg-cover bg-center"
          style={{ backgroundImage: `url(${user?.company?.banner})` }}
        >
          {(_ADMIN_MODE || _IS_COMPANY_OWNER) && (
            <Link to={"edit"} className="absolute right-4 top-4">
              <Button type="fullLite">Modifier les informations</Button>
            </Link>
          )}
        </div>
        <div className="z-10 mt-[-110px] flex flex-col gap-4 p-4 md:h-[150px] md:flex-row md:gap-0">
          <div className="flex flex-row items-center gap-4 rounded-lg bg-white p-6 shadow-lg">
            <Avatar link={data?.image} className="size-20 rounded-lg" />
            <div className="flex flex-col gap-2">
              <Title type="custom" classname="text-xl">
                {capitalizeWords(data?.displayName)}
              </Title>
            </div>
          </div>
          <div className="flex grow flex-col items-center justify-center gap-3 md:flex-row md:items-end md:gap-12">
            {_TABS.map((tab) => (
              <div
                key={tab.index}
                className={`flex cursor-pointer flex-row items-center gap-2 rounded-lg p-2 hover:bg-backgroundGray ${
                  isTabOpen === tab.index && "bg-backgroundGray"
                }`}
                onClick={() => setIsTabOpen(tab.index)}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </div>
            ))}
          </div>
        </div>
        <AnimatePresence mode="wait">
          {isTabOpen === 0 && data && <Collaborateurs data={data} />}
          {isTabOpen === 1 && <CompanyJobs />}
        </AnimatePresence>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <div className="loader" />
    </div>
  );
};

const Collaborateurs = ({ data }: { data: companyType }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 15 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 15 }}
      className="flex flex-col gap-8 md:flex-row"
    >
      <div className="flex grow flex-col gap-8 rounded-lg border p-4 shadow-lg">
        <div className="flex flex-row items-center gap-2">
          <People size={18} />
          <Title type="bloc">Collaborateurs</Title>
        </div>
        <div className="flex flex-col flex-wrap gap-8 md:flex-row">
          {data?.members?.map((item) => (
            <CollaboratorHorizontalCardMin key={item.userId} item={item} />
          ))}
        </div>
      </div>
      <Informations data={data} />
    </motion.div>
  );
};

const CompanyJobs = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 15 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 0 }}
    >
      <div className="w-full flex-row gap-8 md:flex md:w-auto">
        <div className="flex grow flex-col gap-8 rounded-lg border p-4 shadow-lg">
          <BlocTitleWithButton>
            <div className="flex flex-row items-center gap-2">
              <TableDocument size={18} />
              <Title type="bloc">Tableau des missions</Title>
            </div>
            {/* <div className="flex flex-row items-center gap-4">
                      <CSVTag />
                      <PDFTag />
                    </div> */}
          </BlocTitleWithButton>
          <div className="flex flex-col rounded-lg border">
            <div className="p-4">
              <Filters typeList={_COMPANY.filters.membersJobs} />
            </div>
            <Table />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Compagny;
