import { fetchApi } from "./api";

// Retrieve the list of languages ​​or search within it
export const getLanguages = async (query?: string) => {
  const url = query
    ? `/misc/languages?search=${encodeURIComponent(query)}`
    : "/misc/languages";

  const response = await fetchApi(url, "GET");
  if (response.error) return false;

  return response;
};

// Retrieve the list of job categories
export const getCategories = async () => {
  const response = await fetchApi("/misc/categories", "GET");
  if (response.error) return false;

  return response;
};

// Retrieve a open graph data from url
export const getOgs = async (url: string) => {
  const response = await fetchApi(`/misc/ogs?url=${url}`, "GET");
  if (response.error) return false;

  return response;
};

// Retrieve the list of skills or search within it
export const getSkills = async (query?: string) => {
  // Construct the URL based on whether the query is provided and non-empty
  const url = query
    ? `/misc/skills?search=${encodeURIComponent(query)}`
    : "/misc/skills";

  // Fetch data from the API
  const response = await fetchApi(url, "GET");

  // Handle errors
  if (response.error) return false;

  return response;
};

// Retrieve the list of post office
export const getPostOffices = async (query?: string) => {
  const response = await fetchApi(
    `/misc/company/postoffices${query ? `?search=${query}` : ""}`,
    "GET"
  );

  if (response.error) return false;

  return response;
};

// Retrieve the list of size ranges
export const getCompanySizeRanges = async (query?: string) => {
  const response = await fetchApi(
    `/misc/company/sizeranges${query ? `?search=${query}` : ""}`,
    "GET"
  );

  if (response.error) return false;

  return response;
};

// Get id from nafcode
export const getIdFromNafCode = async (naf: string) => {
  const formatedNaf = naf.replace(".", "");
  const response = await fetchApi(`/misc/nafcode?code=${formatedNaf}`, "GET");
  if (!response) return false;

  return response;
};

// Search City
export const searchCity = async (query: string) => {
  const response = await fetchApi(`/misc/city?search=${query}`, "GET");
  if (response.error) return false;

  return response;
};

export const searchSchool = async (query: string) => {
  const response = await fetchApi("/misc/schools?search=" + query, "GET");
  if (response.error) return false;

  return response;
};
