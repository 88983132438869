// Requires
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

// Services
import { getProposal } from "../../../../services/Job/proposal";

// Components
import Text from "../../../UI/Text";
import Avatar from "../../../UI/Avatar/Avatar";

// Icons
import { DirectboxSend } from "iconsax-react";

// Utils
import { usePopup } from "../../../../context/Popup";

const TableAdminProposals = () => {
  // TODO: Ajouter la date de candidature
  const [data, setData] = useState<
    {
      id: number;
      type: string;
      price: number;
      student: {
        id: number;
        firstName: string;
        lastName: string;
        image: string;
      };
      job: {
        id: number;
        title: string;
        type: "recurent" | "punctual";
        startingType: "asap" | "unspecified" | "date";
        startingAt: string;
      };
      company: {
        id: number;
        displayName: string;
        image: string;
      };
    }[]
  >([]);

  const { togglePopup } = usePopup();

  const [limit, setLimit] = useState<number>(10);
  const [tempLimit, setTempLimit] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const [searchParams] = useSearchParams();

  const filters = {
    search: searchParams.get("search") || "",
    date: searchParams.get("date") || ""
  };

  const tableHeader: string[] = [
    "Mission",
    "Entreprise",
    "Date de candidature",
    "Etudiant",
    "Montant total (HT)",
    "",
    ""
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      setTempLimit(numericValue);
    }
  };

  const handleSelectAll = () => {
    if (allChecked) {
      setCheckedItems([]);
    } else {
      const visibleProposalIds = data.map((item) => item.id);
      setCheckedItems(visibleProposalIds);
    }
    setAllChecked(!allChecked);
  };

  const handleCheckboxChange = (id: number) => {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tempLimit < 10) {
        setLimit(10);
        setTempLimit(10);
      } else if (tempLimit > 100) {
        setLimit(100);
        setTempLimit(100);
      } else {
        setLimit(tempLimit);
      }

      setOffset(0);
      setPage(0);
    }, 500);

    return () => clearTimeout(timer);
  }, [tempLimit]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getProposal(page, limit, filters, true);
      console.log(response);
      if (response) {
        setCount(response.count);
        setData(response.proposals);
      }
    };
    fetch();
  }, [filters.search, filters.date, limit, page]);

  return (
    <div className="flex flex-col">
      <table className="w-full table-auto">
        <thead className="border-b border-t bg-backgroundGray">
          <tr>
            <th className="p-4 text-left">
              <input
                type="checkbox"
                checked={allChecked}
                onChange={handleSelectAll}
              />
            </th>
            {tableHeader.map((item, _id) => (
              <th key={_id} className="p-4 text-left">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, _id) => {
              return (
                <tr key={_id} className="cursor-pointer hover:bg-gray-100">
                  <th className="p-4 text-left">
                    <input
                      type="checkbox"
                      checked={checkedItems.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </th>
                  <td className="p-4 text-left">
                    <Link to={`/admin/jobs/${item.job.id}`}>
                      {item.job.title}
                    </Link>
                  </td>
                  <td className="p-4 text-left">
                    <Link
                      className="flex flex-row items-center gap-2"
                      to={`/admin/company/${item.company.id}`}
                    >
                      <Avatar
                        link={item.company.image}
                        className="size-8 rounded-lg"
                      />
                      {item.company.displayName}
                    </Link>
                  </td>
                  <td className="p-4 text-left">DATE DE CANDIDATURE</td>
                  <td className="p-4 text-left">
                    <Link
                      to={`/admin/student/${item.student.id}`}
                      className="flex flex-row items-center gap-2"
                    >
                      <Avatar
                        link={item.student.image}
                        className="size-8 rounded-lg"
                      />
                      {item.student.firstName} {item.student.lastName}
                    </Link>
                  </td>
                  <td className="p-4 text-left">{item.price}&euro;</td>
                  <td className="p-4 text-left font-semibold">...</td>
                  <td className="p-4 text-left">
                    <DirectboxSend
                      onClick={() => togglePopup("proposalsDetails", item.id)}
                      size={18}
                      className="cursor-pointer hover:text-blueViolet-600"
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <td className="p-4 text-left">Aucune proposition pour le moment</td>
          )}
        </tbody>
      </table>
      <div className="flex flex-row justify-between border-t p-4 text-sm">
        <div className="flex flex-row items-center gap-2">
          <Text>Afficher</Text>
          <input
            type="number"
            className="w-[80px] rounded-lg border p-2 focus:outline-blueViolet-600"
            value={tempLimit}
            min={10}
            max={100}
            onChange={handleInputChange}
          />
          <Text>propositions sur {count}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => {
              setOffset(offset - limit);
              setPage(page - 1);
            }}
            disabled={offset === 0}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &lt;
          </button>
          {count &&
            [...Array(Math.ceil(count / limit))].map((_, i) => {
              const pageNumber = i + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => {
                    setOffset((pageNumber - 1) * limit);
                    setPage(pageNumber - 1);
                  }}
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                    pageNumber === Math.ceil(offset / limit) + 1
                      ? "bg-blueViolet-600 text-white"
                      : ""
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}
          <button
            onClick={() => {
              setOffset(offset + limit);
              setPage(page + 1);
            }}
            disabled={count === undefined ? true : offset + limit >= count}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableAdminProposals;
