// Icons
import { CloseSquare } from "iconsax-react";

// Components
import Title from "../../UI/Title";

// Context
import { usePopup } from "../../../context/Popup";
import Text from "../../UI/Text";
import Button from "../../UI/Button";

const BankTransfertConfirmation = () => {
  const { closePopup } = usePopup();

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex flex-row items-center justify-between gap-8">
        <Title type="bloc">Confirmation du virement bancaire</Title>
        <CloseSquare
          size={18}
          className="cursor-pointer hover:text-blueViolet-600"
          onClick={closePopup}
        />
      </div>
      <Text>
        ATTENTION - En attente de votre virement <br /> La mission pourra
        démarrer mais sera susceptible d’être annulée si le paiement n’est pas
        reçu sous 7 jours
      </Text>
      <div className="flex flex-row items-end justify-end">
        <Button type="full">Confirmer</Button>
      </div>
    </div>
  );
};

export default BankTransfertConfirmation;
