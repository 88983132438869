// Icons
import { Edit } from "iconsax-react";

// Components
import FileCard from "../../../Chat/FileCard";
import Text from "../../../UI/Text";
import Button from "../../../UI/Button";

// Types
import type { JobCreateType } from "../../../../types/job";

// Import DOMPurify for sanitizing HTML content
import DOMPurify from "dompurify";

type FileData = {
  fileName: string;
  fileContent?: string;
  fileType: string;
};

const Description = ({
  data,
  edit
}: {
  data: JobCreateType;
  edit: (id: number) => void;
}) => {
  // File formatting
  const files: FileData[] =
    data?.attachments?.map((file) => {
      // Extract file name and extension
      const fileName = file.name.toString();
      const fileType = file.type.toString();

      return {
        fileName: fileName,
        fileType: fileType
      };
    }) || [];

  // Sanitize the HTML content
  const sanitizedContext = DOMPurify.sanitize(data?.context || "");
  const sanitizedObjectives = DOMPurify.sanitize(data?.objectives || "");
  const sanitizedDeliverables = DOMPurify.sanitize(data?.deliverables || "");

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <Text className="text-xl font-semibold">Description de la mission</Text>

      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex w-[calc(100%-46px)] flex-col gap-2 rounded-lg border p-4">
          <Text className="font-semibold">Titre de la mission</Text>
          <Text className="grow overflow-hidden whitespace-normal break-normal break-words">
            {data?.title}
          </Text>
        </div>
        {/* Edit Button */}
        <div
          onClick={() => edit(5)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>

      {/* Contexte */}
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex w-[calc(100%-46px)] flex-col gap-2 rounded-lg border p-4">
          <Text className="font-semibold">Contexte</Text>
          <div
            className="grow overflow-hidden whitespace-normal break-normal break-words"
            dangerouslySetInnerHTML={{ __html: sanitizedContext }}
          />
        </div>
        {/* Edit Button */}
        <div
          onClick={() => edit(5)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>

      {/* Objectifs */}
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex w-[calc(100%-46px)] flex-col gap-2 rounded-lg border p-4">
          <Text className="font-semibold">Objectifs</Text>
          <div
            className="grow overflow-hidden whitespace-normal break-normal break-words"
            dangerouslySetInnerHTML={{ __html: sanitizedObjectives }}
          />
        </div>
        {/* Edit Button */}
        <div
          onClick={() => edit(5)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>

      {/* Livrables */}
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex w-[calc(100%-46px)] flex-col gap-2 rounded-lg border p-4">
          <Text className="font-semibold">Livrables</Text>
          <div
            className="grow overflow-hidden whitespace-normal break-normal break-words"
            dangerouslySetInnerHTML={{ __html: sanitizedDeliverables }}
          />
        </div>
        {/* Edit Button */}
        <div
          onClick={() => edit(5)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>

      {/* Documents joints */}
      <div className="flex flex-row items-center justify-between">
        <div className="flex w-[calc(100%-46px)] flex-col gap-2 rounded-lg border p-4">
          <Text className="font-semibold">Documents joints</Text>
          <div className="flex w-full flex-row flex-wrap gap-2 rounded-lg">
            {files.map((item, _i) => (
              // Using index as key is acceptable here because files are not reordered
              <FileCard key={_i} file={item} />
            ))}
            {files.length < 1 && (
              <div onClick={() => edit(5)}>
                <Button type="full">Ajouter des fichiers</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
