// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

// Services
import { searchSchool } from "../../services/Misc/misc";

// Components
import Text from "../UI/Text";

const SchoolSearch = ({
  callback
}: {
  callback: (data: { id: number; name: string }) => void;
}) => {
  const [query, setQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<{ id: number; name: string }[]>();

  const [schoolSelected, setSchoolSelected] = useState<{
    id: number;
    name: string;
  }>();

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);

    if (query.length >= 3) {
      setIsLoading(true);
      const response = await search();
      if (response) {
        setResult(response);
        setIsLoading(false);
      }
    }
  };

  const search = async () => {
    const response = await searchSchool(query);
    return response;
  };

  useEffect(() => {
    if (schoolSelected) {
      callback(schoolSelected);
    }
  }, [schoolSelected]);

  return (
    <div className="flex flex-col gap-4">
      <input
        onChange={handleInputChange}
        type="text"
        placeholder="Nom de l'école"
        value={query}
        className="rounded-lg border p-2 focus:outline-blueViolet-600"
      />
      <AnimatePresence>
        {query && query.length > 3 && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            className="max-h-[150px] min-w-full overflow-y-auto rounded-lg border bg-white p-2 shadow-lg"
          >
            {isLoading && <div className="loader" />}
            {result && result.length < 1 && (
              <Text>Aucun résultat avec le mot-clé : {query}</Text>
            )}
            {result &&
              result.length > 0 &&
              result.map((school) => (
                <div
                  onClick={() => {
                    setSchoolSelected(school);
                    setQuery("");
                  }}
                  key={school.id}
                  className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-600 hover:text-white"
                >
                  {school.name}
                </div>
              ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SchoolSearch;
