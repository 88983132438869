// Components
import Content from "../../Bloc/Content";
import { BlocTitle } from "../../Bloc/Title";
import Text from "../../UI/Text";
import Title from "../../UI/Title";

const Description = ({ data }: { data: string }) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4">
      <BlocTitle>
        <Title type="bloc">Description de la proposition</Title>
      </BlocTitle>
      <Content>
        <Text className="break-words">{data}</Text>
      </Content>
    </div>
  );
};

export default Description;
