// Requires
import { useEffect, useState } from "react";

// Components
import Button from "../../../../../../components/UI/Button";
import Tag from "../../../../../../components/UI/Tag";
import TagSelectable from "../../../../../../components/UI/TagSelectable";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Services
import { getLanguages, getSkills } from "../../../../../../services/Misc/misc";

// Types
import type { LanguagesType, SkillType } from "../../../../../../types/misc";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import { JobCreateType } from "../../../../../../types/job";
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

interface Props {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  updateFormData: (newData: { [key: string]: any }) => void;
}

type Error = {
  skill?: string;
  langue?: string;
};

const Step2 = ({ updateFormData }: Props) => {
  const { handleNextStep, handlePrevStep, step } = useUI();

  const [skillsAvailable, setSkillsAvailable] = useState<SkillType[]>([]);
  const [skillsSelected, setSkillsSelected] = useState<SkillType[]>([]);
  const [skillsSearchQuery, setSkillsSearchQuery] = useState<string>("");

  const [languesSelected, setLanguesSelected] = useState<LanguagesType[]>([]);
  const [languagesAvailable, setLanguagesAvailable] = useState<LanguagesType[]>(
    []
  );
  const [languagesSearchQuery, setLanguagesSearchQuery] = useState<string>("");

  const [error, setError] = useState<Error>({});

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const AISearchQuery = getLocalStorage("step1");
        if (AISearchQuery) {
          const result: JobCreateType = JSON.parse(AISearchQuery);
          const skillsResponse = await getSkills(result.profileTitle);
          if (skillsResponse) setSkillsAvailable(skillsResponse);
        }
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    const fetchLanguages = async () => {
      try {
        const languagesResponse = await getLanguages("");
        if (languagesResponse) setLanguagesAvailable(languagesResponse);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    const localData = getLocalStorage("step2");
    if (localData) {
      const formatedLocalData = JSON.parse(localData);
      const skills: SkillType[] = formatedLocalData.skills;
      const languages: LanguagesType[] = formatedLocalData.languages;
      setSkillsSelected(skills || []);
      setLanguesSelected(languages || []);
    }

    fetchSkills();
    fetchLanguages();
  }, []);

  const handleSkill = (skill: SkillType) => {
    setSkillsSelected((prevSkills) => {
      if (prevSkills.some((s) => s.id === skill.id)) {
        return prevSkills.filter((s) => s.id !== skill.id);
      }

      if (prevSkills.length < 5) {
        return [...prevSkills, skill];
      }

      alert("Vous ne pouvez sélectionner que 5 compétences au maximum");
      return [...prevSkills];
    });
  };

  const handleLanguage = (language: LanguagesType) => {
    setLanguesSelected((prevLanguages) => {
      if (prevLanguages.some((l) => l.id === language.id)) {
        return prevLanguages.filter((l) => l.id !== language.id);
      }
      return [...prevLanguages, language];
    });
  };

  const suggestions = skillsAvailable
    .filter((item) => item.ai === true && !skillsSelected.includes(item))
    .slice(0, 5);

  const skillsSearchResults = skillsAvailable.filter(
    (skill) =>
      skill.title.toLowerCase().includes(skillsSearchQuery.toLowerCase()) &&
      !suggestions.some((s) => s.id === skill.id)
  );

  const languageSearchResults = languagesAvailable.filter(
    (language) =>
      language.title
        .toLowerCase()
        .includes(languagesSearchQuery.toLowerCase()) &&
      !languesSelected.includes(language)
  );

  const nextStep = () => {
    const newError: Error = {};

    if (skillsSelected.length < 1) {
      newError.skill = "Vous devez choisir au minimum une compétence";
    }

    if (skillsSelected.length > 5) {
      newError.skill = "Vous ne pouvez choisir au maximum que 5 compétences";
    }

    if (languesSelected.length < 1) {
      newError.langue = "Vous devez choisir au minimum une langue";
    }

    setError(newError);

    if (Object.keys(newError).length > 0) {
      return; // Stop the function if there are errors
    }

    const data: {
      skills: { title: string; id: number }[];
      languages: { title: string; id: number }[];
    } = {
      skills: skillsSelected,
      languages: languesSelected
    };

    const localData = [
      {
        key: "step2",
        value: JSON.stringify({
          skills: skillsSelected,
          languages: languesSelected
        })
      },
      { key: "publishJobStep", value: "2" }
    ];

    setLocalStorage(localData);
    updateFormData(data);
    handleNextStep();
  };

  useEffect(() => {
    setError({});
  }, [skillsSelected, languesSelected]);

  return (
    <div className="flex w-full flex-col gap-16 max-md:gap-8">
      <div className="flex flex-col items-center justify-center gap-16 max-md:gap-8">
        <Title type="page">Compétence techniques et linguistiques</Title>
        <div className="flex w-full flex-col gap-16 max-md:gap-8">
          <div className="flex flex-col gap-12 max-md:gap-6">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <Text className="text-xl font-semibold">
                  Compétences recherchées
                </Text>
                <Text className="text-sm">
                  Sélectionner jusqu'à 5 compétences par ordre d'importance
                </Text>
                <Text className="text-red-500">{error.skill}</Text>
              </div>
              <div className="relative flex flex-col gap-2">
                <input
                  type="text"
                  className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${error.skill && "border-red-500"} `}
                  placeholder="Rechercher une compétence..."
                  value={skillsSearchQuery}
                  onChange={(e) => setSkillsSearchQuery(e.target.value)}
                />
                {skillsSearchQuery && (
                  <div className="absolute top-10 z-50 mt-2 flex max-h-[150px] w-full flex-row flex-wrap gap-2 overflow-scroll rounded-lg border bg-white p-2 shadow-lg">
                    {skillsSearchResults.length > 0 ? (
                      skillsSearchResults.map((skill) => (
                        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                        <div
                          key={skill.id}
                          onClick={() => {
                            handleSkill(skill);
                            setSkillsSearchQuery("");
                          }}
                          className="z-50 cursor-pointer"
                        >
                          <Tag>{skill.title}</Tag>
                        </div>
                      ))
                    ) : (
                      <Text>Aucun résultat avec ce mot-clé</Text>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                {/* Header */}
                <div className="items-cente flex">
                  <h2 className="text-lg font-semibold text-gray-800">
                    Suggestions
                  </h2>
                </div>
                <div className="flex w-screen flex-row gap-2 overflow-y-clip overflow-x-scroll">
                  {suggestions && suggestions.length > 0 ? (
                    suggestions.map((skill) => (
                      <button
                        key={skill.id}
                        onClick={() => handleSkill(skill)}
                        className="my-4 flex-shrink-0"
                        aria-label={`Select ${skill.title}`}
                      >
                        <TagSelectable isActiveState={false}>
                          {skill.title}
                        </TagSelectable>
                      </button>
                    ))
                  ) : (
                    <div className="flex w-full">
                      <div className="loader animate-spin border-blue-500 border-t-transparent" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Text className="text-xl font-semibold">
                Compétences sélectionnées
              </Text>
              <div className="flex flex-row flex-wrap gap-2">
                {skillsSelected.map((skill) => (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <div key={skill.id} onClick={() => handleSkill(skill)}>
                    <TagSelectable isActiveState={true}>
                      {skill.title}
                    </TagSelectable>
                  </div>
                ))}

                {/* Compléter avec des tags vides si moins de 5 compétences sont sélectionnées */}
                {Array.from({ length: 5 - skillsSelected.length }).map(
                  (_, index) => (
                    <div key={`empty-${index}`}>
                      <div className="h-full min-h-[24px] w-fit min-w-[80px] cursor-pointer rounded-lg border border-dashed border-blueViolet-600 bg-blueViolet-50 px-3 py-1 text-sm" />
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Text className="text-xl font-semibold">Langues parlées</Text>
              <Text className="text-red-500">{error.langue}</Text>
              <div className="relative flex flex-col gap-2">
                <input
                  type="text"
                  className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${error.langue && "border-red-500"} `}
                  placeholder="Rechercher une langue..."
                  value={languagesSearchQuery}
                  onChange={(e) => setLanguagesSearchQuery(e.target.value)}
                />
                {languagesSearchQuery && (
                  <div className="absolute top-10 z-50 mt-2 flex max-h-[150px] w-full flex-row flex-wrap gap-2 overflow-scroll rounded-lg border bg-white p-2 shadow-lg">
                    {languageSearchResults.length > 0 ? (
                      languageSearchResults.map((language) => (
                        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                        <div
                          key={language.id}
                          onClick={() => {
                            handleLanguage(language);
                            setLanguagesSearchQuery("");
                          }}
                          className="cursor-pointer"
                        >
                          <Tag>{language.title}</Tag>
                        </div>
                      ))
                    ) : (
                      <Text>Aucun résultat avec ce mot-clé</Text>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Text className="text-xl font-semibold">
                Langues sélectionnées
              </Text>
              <div className="flex flex-row flex-wrap gap-2">
                {languesSelected.map((language) => (
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <div
                    key={language.id}
                    onClick={() => handleLanguage(language)}
                  >
                    <TagSelectable isActiveState>
                      {language.title}
                    </TagSelectable>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div onClick={handlePrevStep}>
          <Button type="fullLite">Précédent</Button>
        </div>
        <div className="flex-1 text-center">
          <Text>Étape {step + 1}/6</Text>
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div onClick={nextStep}>
          <Button type="full">Suivant</Button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
