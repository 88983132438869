// requires
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

// Store
import { useAuth } from "../../../utils/store/authStore";
import { useUser } from "../../../utils/store/userStore";

// Helpers
import { fetchApi } from "../../../services/Misc/api";

export const Logout = () => {
  const setIsLogged = useAuth((state) => state.setIsLogged);
  const resetUser = useUser((state) => state.resetUser);
  const isLogged = useAuth((state) => state.user?.isLogged);

  useEffect(() => {
    const fetch = async () => {
      await fetchApi("/auth/logout", "GET");
      resetUser();
      setIsLogged(false);
    };
    fetch();
  }, []);
  if (isLogged) {
    return (
      <div className="flex items-center justify-center">
        <div className="loader" />
      </div>
    );
  } else return <Navigate to="/auth/login" />;
};

export default Logout;
