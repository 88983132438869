import { type ReactNode, createContext, useContext, useState } from "react";

interface PopupInterface {
  isVisible: boolean;
  toggleVisibility: (type: string | undefined) => void;
  type: string | undefined;
}

export const PopupContext = createContext<PopupInterface | null>(null);

export const PopupProvider = ({ children }: { children: ReactNode }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [type, setType] = useState<string>();

  const toggleVisibility = (type: string | undefined) => {
    setIsVisible((prev) => !prev);
    setType(type);
  };

  return (
    <PopupContext.Provider value={{ isVisible, toggleVisibility, type }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
