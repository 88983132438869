import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

// Components
import Text from "../UI/Text";
import Title from "../UI/Title";
import { Verify, CloseCircle } from "iconsax-react"; // Import your icons

// Services
import { getCompanyInformations, searchSiret } from "../../services/User/user";

// Types
import type {
  CompanyInformationsType,
  CompanySearchType
} from "../../types/company";
import CitySearchComponent from "./CitySearchComponent";

interface SiretSearchComponentProps {
  companyInformations: CompanyInformationsType | undefined;
  setCompanyInformations: React.Dispatch<
    React.SetStateAction<CompanyInformationsType | undefined>
  >;
  siretSelected: string | undefined;
  setSiretSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const SiretSearchComponent = ({
  siretSelected,
  setSiretSelected,
  companyInformations,
  setCompanyInformations
}: SiretSearchComponentProps) => {
  const [isOnLoad, setIsOnLoad] = useState<boolean>(false);
  const [result, setResult] = useState<CompanySearchType[] | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [companyNotFound, setCompanyNotFound] = useState<boolean>(false);
  const [tooManyRequest, setTooManyRequest] = useState<boolean>(false);

  const [streetNumber, setStreetNumber] = useState<string>("");
  const [streetName, setStreetName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const [manualSiret, setManualSiret] = useState<string>("");

  const handleManualSiretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setManualSiret(value);
    // Optionally, update the companyInformations state with the manual SIRET
    setCompanyInformations((prev) => ({
      ...(prev || {
        name: "",
        siret: "",
        siren: "",
        activity: "",
        legalForm: "",
        creationDate: "",
        createdDate: "",
        address: ""
      }),
      siret: value
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setInputValue(value);
    setCompanyInformations((prev) => ({
      ...(prev || {
        name: "",
        address: "",
        siren: "",
        activity: "",
        legalForm: "",
        creationDate: "",
        createdDate: ""
      }),
      siret: value
    }));

    // Clear the previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // **Prevent Further Searches if Limit is Reached**
    if (tooManyRequest) {
      return;
    }

    if (value.length < 4) {
      setResult(null);
      setIsOnLoad(false);
      setShowDropdown(false);
      setCompanyNotFound(false);
    } else {
      setIsOnLoad(true);
      setShowDropdown(true);
      const newTimeoutId = setTimeout(async () => {
        const response = await searchSiret(value);
        if (response && response.length > 0) {
          setResult(response);
          setIsOnLoad(false);
          setCompanyNotFound(false);
        } else if (response && response.error) {
          // **Set the Limit Flag if Error Indicates Too Many Requests**
          setTooManyRequest(true);
          setIsOnLoad(false);
          setResult(null);
          setShowDropdown(false);
        } else {
          setResult(null);
          setIsOnLoad(false);
          setCompanyNotFound(true);
          setShowDropdown(false);
        }
      }, 2000); // 2 seconds delay

      setTimeoutId(newTimeoutId);
    }
  };

  const handleSelectCompany = async (siret: string) => {
    setSiretSelected(siret);
    setInputValue("");
    setResult(null);
    setShowDropdown(false);

    const response = await getCompanyInformations(siret);
    if (!response.error) setCompanyInformations(response);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "streetNumber") {
      setStreetNumber(value);
    } else if (name === "streetName") {
      setStreetName(value);
    } else if (name === "city") {
      setCity(value);
    } else if (name === "country") {
      setCountry(value);
    }

    // Update the companyInformations state
    setCompanyInformations((prev) => ({
      ...(prev || {
        name: "",
        siret: inputValue,
        siren: "",
        activity: "",
        legalForm: "",
        creationDate: "",
        createdDate: ""
      }),
      address: `${streetNumber} ${streetName}, ${city}, ${country}`
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node)
      ) {
        setResult(null);
        setIsOnLoad(false);
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Validate address fields
  const validateAddress = () => {
    const newErrors: { [key: string]: string } = {};
    if (!streetNumber) {
      newErrors["streetNumber"] = "Le numéro de rue est requis";
    }
    if (!streetName) {
      newErrors["streetName"] = "Le nom de rue est requis";
    }
    if (!city) {
      newErrors["city"] = "La ville est requise";
    }
    if (!country) {
      newErrors["country"] = "Le pays est requis";
    }
    if (!manualSiret) {
      newErrors["siret"] = "Le numéro de SIRET est requis";
    } else if (!/^\d{14}$/.test(manualSiret)) {
      newErrors["siret"] = "Le numéro de SIRET doit contenir 14 chiffres";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="relative" ref={searchContainerRef}>
      {!siretSelected && (
        <input
          onChange={handleInputChange}
          value={inputValue}
          type="text"
          placeholder="Numéro de SIRET/SIREN ou nom"
          className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
        />
      )}
      <AnimatePresence>
        {showDropdown && (
          <motion.div
            key={"animate"}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 5 }}
            className="absolute left-0 top-12 z-10 flex max-h-[150px] w-full flex-col gap-2 overflow-auto rounded-lg border bg-white p-4 shadow-lg"
          >
            {inputValue.length < 4 && (
              <Text>
                Vous devez entrer un minimum de 4 caractères pour effectuer une
                recherche
              </Text>
            )}
            {inputValue.length >= 4 && isOnLoad && (
              <div className="flex items-center justify-center">
                {/* Replace with your loader component */}
                <div className="loader" />
              </div>
            )}
            {inputValue.length >= 4 &&
              !isOnLoad &&
              result &&
              result.length > 0 &&
              result.map((item) => (
                <div
                  onClick={() => handleSelectCompany(item.siret)}
                  key={item.siret}
                  className="flex cursor-pointer flex-col gap-1 rounded-lg p-2 hover:bg-blueViolet-200"
                >
                  <Text className="font-semibold">{item.name}</Text>
                  <Text className="text-sm">{item.address}</Text>
                </div>
              ))}
          </motion.div>
        )}
      </AnimatePresence>
      {tooManyRequest && (
        <div className="mt-4 flex flex-col gap-2">
          <Text className="text-red-500">
            Vous avez dépassé la limite de 5 requêtes par jour. Vous pourrez
            réessayer dans 24 heures.
          </Text>
        </div>
      )}

      {inputValue.length >= 4 && !isOnLoad && companyNotFound && (
        <div className="flex flex-col gap-2">
          <Text>
            Aucun résultat trouvé. Veuillez entrer les informations de votre
            entreprise manuellement.
          </Text>

          <Text className="font-semibold">Adresse</Text>
          <input
            name="streetNumber"
            type="text"
            className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
              errors["streetNumber"] ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Numéro et nom de rue"
            onChange={handleAddressChange}
          />
          {errors["streetNumber"] && (
            <p className="text-sm text-red-500">{errors["streetNumber"]}</p>
          )}
          {/* <input
            name="streetName"
            type="text"
            className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
              errors["streetName"] ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Nom de rue"
            onChange={handleAddressChange}
          />
          {errors["streetName"] && (
            <p className="text-sm text-red-500">{errors["streetName"]}</p>
          )} */}
          <CitySearchComponent
            setCityData={setCity}
            setError={(val) => setErrors({ ...errors, city: val })}
          />
          {errors["city"] && (
            <p className="text-sm text-red-500">{errors["city"]}</p>
          )}
          <input
            name="country"
            type="text"
            className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
              errors["country"] ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Pays"
            onChange={handleAddressChange}
          />
          {errors["country"] && (
            <p className="text-sm text-red-500">{errors["country"]}</p>
          )}
          {/* Manual SIRET Input */}
          <Text className="font-semibold">SIRET</Text>
          <input
            name="manualSiret"
            type="text"
            className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
              errors["siret"] ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Numéro de SIRET"
            value={manualSiret}
            onChange={handleManualSiretChange}
          />
          {errors["siret"] && (
            <p className="text-sm text-red-500">{errors["siret"]}</p>
          )}
        </div>
      )}

      {/* Display company details when a company is selected */}
      {siretSelected && companyInformations && (
        <div className="mt-4 flex flex-col gap-2 rounded-lg border bg-white p-4 shadow-lg">
          <div className="flex max-w-[100%] flex-row items-center gap-2">
            <Title type="bloc">Détail de l'entreprise</Title>
            <Verify size={18} />
            <CloseCircle
              className="ml-auto cursor-pointer"
              size={24}
              onClick={() => {
                setSiretSelected(undefined);
                setCompanyInformations(undefined);
              }}
            />
          </div>

          <div className="flex flex-row gap-4">
            <div className="flex flex-row gap-2">
              <Text className="font-semibold">SIRET: </Text>
              <Text>{companyInformations?.siret}</Text>
            </div>
            <div className="flex flex-row gap-2">
              <Text className="font-semibold">SIREN: </Text>
              <Text>{companyInformations?.siren}</Text>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="flex flex-row gap-2">
              <Text className="font-semibold">Date de création:</Text>
              <Text>{companyInformations?.createdDate}</Text>
            </div>
            <div className="flex flex-row gap-2">
              <Text className="font-semibold">Activité: </Text>
              <Text>{companyInformations?.activity}</Text>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="flex flex-row gap-2">
              <Text className="font-semibold">Siège social: </Text>
              <Text>{companyInformations?.address}</Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
