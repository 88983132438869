// Components
import { Link } from "react-router-dom";
import Button from "../UI/Button";
import Text from "../UI/Text";
import Title from "../UI/Title";
import CustomTooltip from "../UI/Tooltip";
import { usePopup } from "../../context/Popup";

const CardLite = ({
  name,
  price,
  isActive,
  isCustom,
  facturationMode,
  isCancelled,
  formattedName
}: {
  name: string;
  price: number;
  isActive: boolean;
  isCustom?: boolean;
  facturationMode: number;
  isCancelled?: boolean;
  formattedName: string;
}) => {
  const { togglePopup } = usePopup();

  return (
    <div className="flex w-full flex-col rounded-lg md:w-[calc(20%-13px)]">
      <div
        className="flex flex-row items-center justify-center rounded-t-lg bg-blueViolet-600 p-2 text-white"
        style={{ opacity: isActive ? 1 : 0 }}
      >
        <Text className="text-white">Mon plan actuel</Text>
      </div>
      <div className="flex flex-col gap-4 rounded-b-lg border bg-white p-4">
        <Title type="custom" classname="text-xl">
          {name}
        </Title>
        <div className="flex flex-col gap-1">
          <Title type="custom" classname="text-2xl">
            {price === 0 ? "Gratuit" : isCustom ? "Sur-Mesure" : `${price}€`}
          </Title>
          {price === 0 || isCustom ? (
            <Text className="text-sm opacity-0">T/mois par utilisateurs</Text>
          ) : (
            <Text className="text-sm">
              HT/{" "}
              {facturationMode === 1
                ? "mois"
                : facturationMode === 3
                  ? "trimestre"
                  : "annuel"}{" "}
              par utilisateurs
            </Text>
          )}
        </div>
        <div className="flex min-h-[50px] items-center">
          {!isActive &&
            !isCustom &&
            (isCancelled ? (
              <Link
                to={`payment?plan=${formattedName.toLowerCase()}&facturationMode=${facturationMode}`}
              >
                <Button type="fullLite">Choisir ce plan</Button>
              </Link>
            ) : (
              <CustomTooltip
                content="Veuillez attendre la fin de votre abonnement en cours"
                position="top"
              >
                <Button type="disabled">Choisir ce plan</Button>
              </CustomTooltip>
            ))}
          {!isActive &&
            isCustom &&
            (isCancelled ? (
              <Button type="full">Faire évoluer mon plan</Button>
            ) : (
              <CustomTooltip
                position="top"
                content="Veuillez attendre la fin de votre abonnement en cours"
              >
                <Button type="disabled">Faire évoluer mon plan</Button>
              </CustomTooltip>
            ))}
          {isActive && name !== "Standard" && isCancelled && (
            <div onClick={() => togglePopup("cancelSubscription")}>
              <Button type="fullLite">Annuler le renouvellement</Button>
            </div>
          )}
          {isActive && name !== "Standard" && !isCancelled && (
            <Button type="fullLite">Abonnement annulé</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardLite;
