// Icons
import { CalendarSearch } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import Title from "../UI/Title";
import Text from "../UI/Text";

// Utils
import { useUser } from "../../utils/store/userStore";
import Disponibilities from "../Onboarding/Student/Disponibilities";
import { availibityType, WeeklyAvailabilityType } from "../../types/user";
import { useState } from "react";

interface InputData {
  monday: availibityType[];
  tuesday: availibityType[];
  wednesday: availibityType[];
  thursday: availibityType[];
  friday: availibityType[];
  saturday: availibityType[];
  sunday: availibityType[];
}

const Disponibility = ({
  publicMode,
  data,
  style
}: {
  publicMode?: boolean;
  data?: WeeklyAvailabilityType[];
  style?: string;
}) => {
  const _ADMIN_MODE = useUser((state) => state.userdata?.roleName === "admin");
  const _COMPANY_MODE = useUser(
    (state) => state.userdata?.roleName === "company"
  );
  const [error, setError] = useState<{ [key: string]: string }>({});

  const user = useUser((state) => state.userdata);

  const transformAvailability = (data: InputData): WeeklyAvailabilityType[] => {
    if (!data) return [];
    const daysOfWeek: (keyof InputData)[] = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday"
    ];

    return daysOfWeek.map((day) => ({
      availability: data[day] || []
    }));
  };

  // TODO: Mettre en place le composant "Disponibilité"

  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <CalendarSearch size={18} />
        <Title type="bloc">Disponibilités</Title>
      </BlocTitle>
      <Content>
        {(data || user?.weeklyAvailability) && (
          <Disponibilities
            defaultAvailability={transformAvailability(
              data ?? (user?.weeklyAvailability as any)
            )}
            error={error}
          />
        )}

        {!publicMode && !_ADMIN_MODE && !_COMPANY_MODE && (
          <div className="flex flex-row items-center justify-between gap-4 text-sm">
            <Text>Partager aux entreprises</Text>
            <div className="toggle-switch">
              <input className="toggle-input" id="toggle" type="checkbox" />
              <label className="toggle-label" htmlFor="toggle" />
            </div>
          </div>
        )}
      </Content>
    </div>
  );
};

export default Disponibility;
