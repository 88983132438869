// Requires
import { motion } from "framer-motion";
import { useState } from "react";

// Icons
import { Box2 } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import Tag from "../UI/Tag";
import Text from "../UI/Text";
import Title from "../UI/Title";

// Types
import { SkillType } from "../../types/misc";

const Skill = ({ data }: { data?: SkillType[] }) => {
  const [seeAll, setSeeAll] = useState<boolean>(false);

  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <Box2 size={18} />
        <Title type="bloc">Compétences</Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-row flex-wrap gap-2">
          {data &&
            data.slice(0, seeAll ? data.length : 5).map((item, _i) => (
              <motion.div
                key={item.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.05 * _i }}
              >
                <Tag className="bg-blueViolet-200 text-blueViolet-600">
                  {item.title}
                </Tag>
              </motion.div>
            ))}
          {!data && "Aucunes compétences pour le moment"}
        </div>
        {data && data.length > 5 && (
          <div onClick={() => setSeeAll((prev) => !prev)}>
            <Text className="cursor-pointer text-sm text-withCustom-500 hover:text-blueViolet-600">
              {seeAll ? "Voir moins" : "Voir plus"}
            </Text>
          </div>
        )}
        {!data ||
          (data && data.length < 1 && (
            <Text>Aucune compétence pour le moment</Text>
          ))}
      </Content>
    </div>
  );
};

export default Skill;
