import { CloseSquare } from "iconsax-react";

const TagFilledWithAction = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className="flex w-fit cursor-pointer flex-row items-center gap-2 rounded-lg bg-blueViolet-600 px-3 py-1 text-sm text-white">
      <span>{children}</span>
      <CloseSquare size={18} />
    </div>
  );
};

export default TagFilledWithAction;
