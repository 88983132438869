// Require
import dayjs from "dayjs";
require("dayjs/locale/fr");

const Date = ({
  format,
  date,
  className
}: {
  format?: string;
  date: string;
  className?: string;
}) => {
  return (
    <p className={className}>
      {date !== "Aujourd'hui" ? dayjs(date).locale("fr").format(format) : date}
    </p>
  );
};

export default Date;
