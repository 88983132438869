// Icons
import { Building } from "iconsax-react";

// Components
import Text from "../../UI/Text";
import Avatar from "../../UI/Avatar/Avatar";

// Types
import type { JobDetailsType } from "../../../types/job";

export const LittleCard = ({ data }: { data: JobDetailsType }) => {
  // TODO: Ajouter le secteur d'activité de l'entreprise

  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <Building size={18} />
        <Text className="font-semibold">Entreprise</Text>
      </div>
      <div className="flex flex-row gap-4 rounded-lg border p-2">
        <Avatar link={data.company.image} className="size-12 rounded-lg" />
        <div className="flex flex-col justify-between">
          <Text className="text-lg font-semibold">
            {data.company.displayName}
          </Text>
          <Text>Secteur d'activité de l'entreprise</Text>
        </div>
      </div>
    </div>
  );
};
