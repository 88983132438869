// Components
import JobBudgetSummary from "../../../../../../components/Jobs/Publish/Resume";
import Description from "../../../../../../components/Jobs/Publish/Resume/Description";
import Profil from "../../../../../../components/Jobs/Publish/Resume/Profil";
import Terms from "../../../../../../components/Jobs/Publish/Resume/Terms";
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import NextStep, {
  additionalServicesType
} from "../../../../../../components/Jobs/Publish/Resume/NextStep";

// Requires
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// Services
import { createJob } from "../../../../../../services/Job/job";

// Types
import type { JobCreateType } from "../../../../../../types/job";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import { removeFromLocalStorage } from "../../../../../../utils/helpers/localStorage";

const Step5 = ({ data }: { data: JobCreateType }) => {
  const navigate = useNavigate();

  const { handlePrevStep, specificStep, step, resetStep } = useUI();

  const [additionalServices, setAdditionalServices] =
    useState<additionalServicesType>({
      nda: false,
      contract: false
    });

  const edit = (id: number) => {
    specificStep(id);
  };

  const publishJob = async () => {
    if (data) {
      const formData = new FormData();

      if (data.categories && data.categories.length > 0) {
        const categoryIds = data.categories.map((category) => category.id);
        formData.append("categories", JSON.stringify(categoryIds));
      }

      if (data.degree) {
        formData.append("degree", data.degree);
      }

      if (data.context) {
        formData.append("context", data.context);
      }

      if (data.deliverables) {
        formData.append("deliverables", data.deliverables);
      }

      if (data.languages && data.languages.length > 0) {
        const languagesIds = data.languages.map((language) => language.id);
        formData.append("languages", JSON.stringify(languagesIds));
      }

      if (data.skills && data.skills.length > 0) {
        const skillsIds = data.skills.map((skill) => skill.id);
        formData.append("skills", JSON.stringify(skillsIds));
      }

      if (data.workMode) {
        formData.append("workMode", data.workMode);
      }

      if (data.workingFrequency) {
        formData.append("workingFrequency", data.workingFrequency.toString());
      }

      if (data.type) {
        formData.append(
          "type",
          data.type === "punctual" ? "punctual" : "recurent"
        );
      }

      if (data.title) {
        formData.append("title", data.title);
      }

      if (data.startingType) {
        formData.append("startingType", data.startingType);
      }

      if (data.startingAt) {
        formData.append("startingAt", data.startingAt);
      }

      if (data.rate) {
        formData.append("rate", data.rate.toString());
      }

      if (data.profileTitle) {
        formData.append("profileTitle", data.profileTitle);
      }

      if (data.cityId) {
        formData.append("cityId", data.cityId.toString());
      }

      if (data.objectives) {
        formData.append("objectives", data.objectives);
      }

      if (data.duration) {
        formData.append("duration", data.duration.toString());
      }

      if (data.endingAt) {
        formData.append("endingAt", data.endingAt);
      }

      if (data.endingType) {
        formData.append("endingType", data.endingType);
      }

      if (data.attachments && data.attachments.length > 0) {
        data.attachments?.forEach((file) => {
          formData.append("attachments", file);
        });
      }

      formData.append("nda", JSON.stringify(true));
      formData.append("contract", JSON.stringify(true));

      const response = await createJob(formData);

      if (typeof response === "number") {
        removeFromLocalStorage("publishJobStep");
        removeFromLocalStorage("job");
        removeFromLocalStorage("step1");
        removeFromLocalStorage("step2");
        removeFromLocalStorage("step3");
        removeFromLocalStorage("step4");
        removeFromLocalStorage("step5");
        resetStep();
        navigate(`/company/manages-jobs/${response}`);
        return;
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-16 max-md:gap-8">
      <div className="flex items-center justify-center">
        <Title type="page">Aperçu de la mission</Title>
      </div>
      <div className="flex w-full flex-col gap-16 max-md:gap-8 md:flex-row">
        <div className="flex w-full flex-col gap-16 max-md:gap-8 md:w-[calc(100%-374px)]">
          <div className="flex w-full flex-col gap-8">
            <Profil data={data} edit={edit} />
            <Terms data={data} edit={edit} />
            <Description data={data} edit={edit} />
            <NextStep
              additionalServices={additionalServices}
              setAdditionalServices={setAdditionalServices}
            />
          </div>
        </div>
        <div className="relative flex flex-col gap-8 md:max-w-[320px]">
          <JobBudgetSummary
            type={data?.type === "punctual" ? "punctual" : "punctual"}
            data={data}
            additionalServices={additionalServices}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between">
        <div>
          <div onClick={handlePrevStep}>
            <Button type="fullLite">Précédent</Button>
          </div>
        </div>
        <div className="flex-1 text-center">
          <Text>Étape {step}/6</Text>
        </div>
        <div onClick={publishJob}>
          <Button type="full">Publier</Button>
        </div>
      </div>
    </div>
  );
};

export default Step5;
