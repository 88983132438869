// Icons
import { Building, Calendar, Edit, Trash } from "iconsax-react";

// Types
import type { ExperienceType } from "../../types/user";

// Components
import Content from "../Bloc/Content";
import { BlocTitleWithButton } from "../Bloc/Title";
import Date from "../UI/Date";
import Tag from "../UI/Tag";
import Text from "../UI/Text";
import Title from "../UI/Title";

const ExperienceCard = ({
  data,
  index,
  onDelete,
  onEdit
}: {
  data: ExperienceType;
  index: number;
  onDelete?: (index: number) => void;
  onEdit?: (index: number) => void;
}) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg border border-blueViolet-200 p-4">
      <BlocTitleWithButton>
        <Title type="bloc">{data.title}</Title>
        <div className="flex flex-row items-center gap-2">
          {onDelete && onEdit && (
            <>
              <div
                onClick={() => onEdit(index)}
                className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
              >
                <Edit
                  size={18}
                  className="cursor-pointer hover:text-blueViolet-600"
                />
              </div>
              <div
                onClick={() => onDelete(index)}
                className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50 hover:text-red-500"
              >
                <Trash
                  size={18}
                  className="cursor-pointer hover:text-red-500"
                />
              </div>
            </>
          )}
        </div>
      </BlocTitleWithButton>
      <Content>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-row items-center gap-2">
              <Building size={18} />{" "}
              <Text className="text-sm">
                {data.companyName ? data.companyName : data.schoolName}
              </Text>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Calendar size={18} />{" "}
              <Date
                date={data?.start ? data.start : "Aujourd'hui"}
                format="MMMM YYYY"
              />{" "}
              -
              <Date
                date={data?.end ? data.end : "Aujourd'hui"}
                format="MMMM YYYY"
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap items-center gap-4">
            {data?.skills && data.skills.length > 0 ? (
              data.skills?.map((item) => <Tag key={item.id}>{item.title}</Tag>)
            ) : (
              <Text className="text-sm text-withCustom-500">
                Ajoute des compétences
              </Text>
            )}
          </div>
          {data.description && data.description !== "Empty" ? (
            <Text className="w-full text-wrap break-words break-all text-sm">
              {data.description}
            </Text>
          ) : (
            <Text className="text-sm text-withCustom-500">
              Ajoute une description
            </Text>
          )}
        </div>
      </Content>
    </div>
  );
};

export default ExperienceCard;
