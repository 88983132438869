// Icons
import { Calendar } from "iconsax-react";

// Components
import Button from "../../UI/Button";
import Text from "../../UI/Text";
import Date from "../../UI/Date";

// Types
import { CertificationType } from "../../../types/user";

const LittleCertificationCard = ({ item }: { item: CertificationType }) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-blueViolet-200 p-4">
      <div className="flex flex-col gap-2">
        <Text className="font-semibold">{item.name}</Text>
        <div className="flex flex-row items-center gap-2 text-sm">
          <Calendar /> <Date format="MMMM YYYY" date={item.issuedDate} />
        </div>
      </div>
      <Button type="fullLite" className="w-fit">
        Voir le justificatif
      </Button>
    </div>
  );
};

export default LittleCertificationCard;
