// Components
import Text from "../Text";

const Statut = ({
  State
}: {
  State: {
    index: number;
    name:
      | "hiring"
      | "hired"
      | "closed"
      | "underReview"
      | "accepted"
      | "rejected"
      | "inProgress";
  };
}) => {
  if (State.name === "underReview") {
    return (
      <div className="flex flex-row items-center justify-center gap-2 text-nowrap rounded-lg bg-blue-300 bg-opacity-50 p-2 text-xs text-black">
        <div className="h-2 w-2 rounded-sm bg-blue-400" />
        <Text className="text-xs">En validation</Text>
      </div>
    );
  }

  if (State.name === "hiring" || State.name === "accepted") {
    return (
      <div className="flex flex-row items-center justify-center gap-2 text-nowrap rounded-lg bg-green-300 bg-opacity-50 p-2 text-xs text-black">
        <div className="h-2 w-2 rounded-sm bg-green-400" />
        <Text className="text-xs">
          {State.name === "hiring" ? "En cours de recrutement" : "Accepté"}
        </Text>
      </div>
    );
  }

  if (State.name === "closed" || State.name === "rejected") {
    return (
      <div className="flex flex-row items-center justify-center gap-2 text-nowrap rounded-lg bg-red-300 bg-opacity-50 p-2 text-xs text-black">
        <div className="h-2 w-2 rounded-sm bg-red-400" />
        <Text className="text-xs">
          {State.name === "closed" ? "Termine" : "Refusé"}
        </Text>
      </div>
    );
  }

  if (State.name === "hired" || State.name === "inProgress") {
    return (
      <div className="flex flex-row items-center justify-center gap-2 text-nowrap rounded-lg bg-gray-300 bg-opacity-50 p-2 text-xs text-black">
        <div className="h-2 w-2 rounded-sm bg-gray-400" />
        <Text className="text-xs">En cours</Text>
      </div>
    );
  }

  return null;
};

export default Statut;
