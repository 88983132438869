// Icons
import { ImportSquare } from "iconsax-react";

// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

// Components
import Content from "../../../../../../components/Bloc/Content";
import { BlocTitle } from "../../../../../../components/Bloc/Title";
import PortfolioCard from "../../../../../../components/Cards/Portfolio/PortfolioCard";
import CertificationCard from "../../../../../../components/Register/CertificationCard";
import AddNew from "../../../../../../components/UI/AddNew";
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Services
import { getOgs } from "../../../../../../services/Misc/misc";
import {
  updateCertification,
  updatePorfolio
} from "../../../../../../services/User/user";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import {
  getLocalStorage,
  removeFromLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

// Types
import { BottomSheet } from "react-spring-bottom-sheet";
import type {
  CertificationType,
  CertificationsType,
  PortfolioType,
  PortfoliosType
} from "../../../../../../types/user";
import { Desktop, Mobile } from "../../../../../../utils/mobile/responsive";

type ErrorPortfolio = {
  title?: string;
  link?: string;
  start?: string;
};

const Eight = () => {
  const { step, handleNextStep } = useUI();

  const [isPortfolioVisible, setIsPortfolioVisible] = useState<boolean>(false);
  const [isCertificationVisible, setIsCertificationVisible] =
    useState<boolean>(false);
  const [isOnEdit, setIsOnEdit] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorPortfolio>({});

  const inputRef = useRef<HTMLInputElement>(null);

  const certifRef = useRef<HTMLInputElement>(null);

  const handleCertifFile = () => {
    certifRef.current?.click();
  };

  const handleFile = () => {
    inputRef.current?.click();
  };

  const [portfolio, setPortfolio] = useState<PortfolioType>({
    title: "",
    link: "",
    attachments: []
  });
  const [portfolios, setPortfolios] = useState<PortfoliosType>({
    portfolios: []
  });

  const [certification, setCertification] = useState<CertificationType>({
    name: "",
    issuedDate: "",
    link: "",
    attachment: undefined
  });

  const [certifications, setCertifications] = useState<CertificationsType>({
    certifications: []
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setPortfolio((prev) => ({ ...prev, [name]: value }));
  };

  const handleCertificationInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCertification((prev) => ({ ...prev, [name]: value }));
  };

  const togglePortfolio = () => {
    setPortfolio({ link: "", title: "" });
    setIsPortfolioVisible(true);
  };

  const addAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files); // Convertir FileList en tableau

      setPortfolio((prev) => ({
        ...prev,
        attachments: [...(prev.attachments || []), ...files]
      }));
    }
  };

  const addCertificationAttachment = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file) {
        setCertification((prev) => ({
          ...prev,
          attachment: file // Directly assign the file object to attachment
        }));
      }
    }
  };

  // Add portfolio
  const addPortfolio = async () => {
    const newErrors: ErrorPortfolio = {};

    if (!portfolio.title || portfolio.title.length < 5) {
      newErrors.title =
        "Tu dois indiquer le titre de ton portfolio (minimum 5 caractères)";
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return; // Stop the function if there are errors
    }

    const response = await ogPortfolio(portfolio.link);

    if (typeof isOnEdit === "number") {
      // Mise à jour d'une expérience existante
      setPortfolios((prev) => {
        const updatedExperiences = [...prev.portfolios];
        updatedExperiences[isOnEdit] = {
          ...portfolio,
          ogImage: response.image,
          ogTitle: response.title
        };
        return { portfolios: updatedExperiences };
      });
    } else {
      // Ajout d'une nouvelle expérience
      setPortfolios((prev) => ({
        portfolios: [
          ...(prev?.portfolios || []),
          { ...portfolio, ogImage: response.image, ogTitle: response.title }
        ]
      }));
    }

    resetPortofolioForm();
    setIsPortfolioVisible(false);
  };

  const ogPortfolio = async (url: string) => {
    const response = await getOgs(url);
    return response;
  };

  // Edit portfolio
  const editPorfolio = (index: number) => {
    setPortfolio({
      title: portfolios.portfolios[index].title,
      link: portfolios.portfolios[index].link
    });
    setIsPortfolioVisible(true);
    setIsOnEdit(index);
  };

  // Delete portfolio
  const deletePortfolio = (id: number) => {
    setPortfolios((prev) => ({
      ...prev,
      // biome-ignore lint/suspicious/noSelfCompare: <explanation>
      portfolios: prev.portfolios.filter((_, i) => i !== id)
    }));
  };

  const resetPortofolioForm = () => {
    setPortfolio({
      title: "",
      link: ""
    });
  };

  // Add Certification
  const addCertification = () => {
    if (typeof isOnEdit === "number") {
      // Mise à jour d'une expérience existante
      setCertifications((prev) => {
        const updatedExperiences = [...prev.certifications];
        updatedExperiences[isOnEdit] = certification;
        return { certifications: updatedExperiences };
      });
    } else {
      // Ajout d'une nouvelle expérience
      setCertifications((prev) => ({
        certifications: [...(prev?.certifications || []), certification]
      }));
    }

    resetCertificationForm();
    setIsCertificationVisible(false);
  };

  const resetCertificationForm = () => {
    setCertification({
      name: "",
      issuedDate: "", // Corrected format
      link: ""
    });
  };

  // Edit portfolio
  const editCertification = (index: number) => {
    setCertification({
      name: certifications.certifications[index].name,
      link: certifications.certifications[index].link,
      issuedDate: certifications.certifications[index].issuedDate
    });
    setIsCertificationVisible(true);
    setIsOnEdit(index);
  };

  // Delete portfolio
  const deleteCertification = (id: number) => {
    setCertifications((prev) => ({
      ...prev,
      // biome-ignore lint/suspicious/noSelfCompare: <explanation>
      certifications: prev.certifications.filter((_, i) => i !== id)
    }));
  };

  // Go to next step
  const nextStep = async () => {
    setIsLoading(true);

    if (
      portfolios.portfolios.length > 0 ||
      certifications.certifications.length > 0
    ) {
      try {
        const responses = await Promise.all(
          portfolios.portfolios.map(async (item) => {
            const data = new FormData();

            data.append("title", item.title);
            data.append("link", item.link || ""); // Optional link field
            data.append("date", item.date || new Date().toISOString()); // Optional date field

            item.attachments?.forEach((file, index) => {
              data.append("attachments", file);
            });

            const response = await updatePorfolio(data);
            return response;
          })
        );

        const responses2 = await Promise.all(
          certifications.certifications.map(async (item) => {
            const data = new FormData();

            data.append("name", item.name);
            data.append("link", item.link || ""); // Optional link field
            data.append("issuedDate", item.issuedDate || ""); // Optional date field

            if (item.attachment) {
              data.append("attachment", item.attachment);
            }

            const response = await updateCertification(data);
            return response;
          })
        );

        if ([...responses, ...responses2].every((response) => response)) {
          removeFromLocalStorage("portfolios");
          removeFromLocalStorage("certifications");
          const localData = [{ key: "studentStep", value: "9" }];
          setLocalStorage(localData);
          handleNextStep();
        } else {
          console.error(
            "One or more portfolio or certification updates failed.",
            { responses, responses2 }
          );
        }
      } catch (error) {
        console.error("An error occurred during the portfolio updates:", error);
      }
    } else {
      const localData = [{ key: "studentStep", value: "9" }];
      setLocalStorage(localData);
      handleNextStep();
    }

    setIsLoading(false);
  };

  // Fetch data from localstorage
  useEffect(() => {
    const data = getLocalStorage("portfolios");
    if (data) {
      const parse = JSON.parse(data);
      for (const item of parse) {
        (async () => {
          const response = await ogPortfolio(item.link);
          const test: PortfolioType = {
            title: item.title,
            link: item.link,
            ogImage: response.image,
            ogTitle: response.title
          };
          setPortfolios((prev) => ({
            portfolios: [...(prev?.portfolios || []), test]
          }));
        })();
      }
    }

    const cData = getLocalStorage("certifications");
    if (cData) {
      const parse = JSON.parse(cData);
      for (const item of parse) {
        const test: CertificationType = {
          name: item.name,
          issuedDate: item.issuedDate
        };

        setCertifications((prev) => ({
          certifications: [...(prev?.certifications || []), test]
        }));
      }
    }
  }, []);

  const NewPortfolio = (
    <motion.div
      key="portfolioKey"
      initial={{ y: 5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0, y: 5 }}
      className="flex flex-col gap-8 rounded-lg p-4 md:border"
    >
      <BlocTitle>
        <Title type="bloc">Nouveau document</Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-col items-start gap-6">
          <div className="flex w-full flex-col gap-2">
            <Text className="font-semibold">
              Titre<span style={{ color: "red" }}> * </span>
            </Text>
            <input
              name="title"
              value={portfolio.title}
              onChange={handleInputChange}
              type="text"
              placeholder="Mon Portfolio de développeur..."
              className={`w-full rounded-lg border p-2 ${error.title ? "border-red-500" : "border"}`}
            />
            {error.title && (
              <Text className="sm sm text-red-500d-">{error.title}</Text>
            )}
          </div>
          <div className="flex w-full flex-row gap-6">
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex flex-row items-end gap-1">
                <Text>Lien</Text>
                <Text className="text-sm">- Facultatif</Text>
              </div>
              <input
                value={portfolio.link}
                name="link"
                onChange={handleInputChange}
                type="text"
                placeholder="Lien vers le projet"
                className="w-full rounded-lg border p-2"
              />
            </div>
          </div>
          <div
            className="flex w-full flex-row items-center gap-4 rounded-lg border p-2"
            onClick={handleFile}
          >
            <div className="flex items-center justify-center rounded-lg bg-blueViolet-50 p-4">
              <ImportSquare size={20} />
            </div>
            <Text>Glisser-déposer le PDF ou</Text>
            <div className="rounded-lg bg-blueViolet-50 px-4 py-1">
              <Text className="text-blueViolet-600">
                Parcourir l'ordinateur
              </Text>
            </div>
            <input
              onChange={addAttachment}
              ref={inputRef}
              className="hidden"
              type="file"
              accept=".png,.jpg,.jpeg,.pdf"
              multiple
            />
          </div>
          <div className="flex w-full flex-row justify-end gap-4">
            <div onClick={() => setIsPortfolioVisible(false)}>
              <Button type="cancel">Annuler</Button>
            </div>
            <div onClick={addPortfolio}>
              <Button type="full">
                {typeof isOnEdit === "number" ? "Modifier" : "Ajouter"}
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </motion.div>
  );

  const NewCertification = (
    <motion.div
      key="certificationKey"
      initial={{ y: 5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0, y: 5 }}
      className="flex flex-col gap-8 rounded-lg p-4 md:border"
    >
      <BlocTitle>
        <Title type="bloc">Nouvelle certification</Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-col items-start gap-6">
          <div className="flex w-full flex-col gap-2">
            <Text className="font-semibold">Titre</Text>
            <input
              name="name"
              value={certification.name}
              onChange={handleCertificationInputChange}
              type="text"
              placeholder="Exemple: Développeur PHP"
              className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
            />
          </div>
          <div className="flex w-full flex-col gap-6 md:flex-row">
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex flex-row items-end gap-1">
                <Text>Lien de verification</Text>
                <Text className="text-sm">- Facultatif</Text>
              </div>
              <input
                value={certification.link}
                name="link"
                onChange={handleCertificationInputChange}
                type="text"
                placeholder="Lien vers la certification"
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
            </div>
            <div className="flex flex-1 flex-col gap-2">
              <Text>Date d'obtiention</Text>
              <input
                name="issuedDate"
                value={certification.issuedDate}
                onChange={handleCertificationInputChange}
                type="date"
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
            </div>
          </div>
          <div
            onClick={handleCertifFile}
            className="flex w-full flex-row items-center gap-4 rounded-lg border p-2"
          >
            <div className="flex items-center justify-center rounded-lg bg-blueViolet-50 p-4">
              <ImportSquare size={20} />
            </div>
            <Text>Glisser-déposer le PDF ou</Text>
            <div className="rounded-lg bg-blueViolet-50 px-4 py-1">
              <Text className="text-blueViolet-600">
                Parcourir l'ordinateur
              </Text>
            </div>
            <input
              onChange={addCertificationAttachment}
              ref={certifRef}
              type="file"
              className="hidden"
              multiple
              accept=".png,.pdf,.jpg,.jpeg"
            />
          </div>
          <div className="flex w-full flex-row justify-end gap-4">
            <div onClick={() => setIsCertificationVisible(false)}>
              <Button type="cancel">Annuler</Button>
            </div>
            <div onClick={addCertification}>
              <Button type="full">Ajouter</Button>
            </div>
          </div>
        </div>
      </Content>
    </motion.div>
  );

  return (
    <div className="flex w-full min-w-[50%] flex-row px-4 md:max-w-[50%] md:py-8">
      <div className="flex flex-1 grow flex-col items-center gap-12">
        <div className="flex w-full flex-col gap-8">
          <div className="cursor-pointer" onClick={togglePortfolio}>
            <AddNew type="portfolio" />
          </div>
          <AnimatePresence>
            <Mobile key="mobileNewPortfolio">
              <BottomSheet
                open={isPortfolioVisible}
                onDismiss={() => {
                  setIsPortfolioVisible(false);
                }}
                snapPoints={({ maxHeight }) => [(maxHeight * 9) / 10]}
              >
                {NewPortfolio}
              </BottomSheet>
            </Mobile>
            <Desktop key="desktopNewPortfolio">
              {isPortfolioVisible && NewPortfolio}
            </Desktop>
          </AnimatePresence>
          <div className="flex flex-col flex-wrap gap-4 md:flex-row">
            {portfolios &&
              portfolios.portfolios.length > 0 &&
              portfolios.portfolios.map((item, index) => (
                <div
                  key={item.id || index}
                  className="min-w-[calc(50%-8px)] md:max-w-[calc(50%-8px)]"
                >
                  <PortfolioCard
                    item={item}
                    onDelete={() => deletePortfolio(index)}
                    index={index}
                    onEdit={editPorfolio}
                  />
                </div>
              ))}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => setIsCertificationVisible(true)}
          >
            <AddNew type="certifications" />
          </div>
          <AnimatePresence>
            <Mobile key="mobileNewPortfolio">
              <BottomSheet
                open={isCertificationVisible}
                onDismiss={() => {
                  setIsCertificationVisible(false);
                }}
                snapPoints={({ maxHeight }) => [(maxHeight * 9) / 10]}
              >
                {NewCertification}
              </BottomSheet>
            </Mobile>
            <Desktop key="desktopNewPortfolio">
              {isCertificationVisible && NewCertification}
            </Desktop>
          </AnimatePresence>
          <div className="flex flex-col flex-wrap gap-4 md:flex-row">
            {certifications &&
              certifications.certifications.length > 0 &&
              certifications.certifications.map((item, index) => (
                <div
                  key={index}
                  className="min-w-[calc(50%-8px)] md:max-w-[calc(50%-8px)]"
                >
                  <CertificationCard
                    item={item}
                    index={index}
                    onDelete={() => deleteCertification(index)}
                    onEdit={editCertification}
                  />
                </div>
              ))}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex-1 text-center">
              <Text>Étape {step}/14</Text>
            </div>
            <div onClick={nextStep}>
              <Button type="full">
                {isLoading ? <div className="loader" /> : "Suivant"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eight;
