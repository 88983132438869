import { useUser } from "../../../utils/store/userStore";
import Highlight from "../Highlight";

const UserWarnings = () => {
  const user = useUser((state) => state.userdata);

  if (user?.warnings && Object.keys(user?.warnings).length > 0) {
    return (
      <Highlight type="Warning">
        {user.warnings.email && (
          <>
            Ton adresse email n'est pas validée.
            <br />
          </>
        )}
        {user.warnings.phone && (
          <>
            Ton numéro de téléphone n'est pas validé.
            <br />
          </>
        )}
      </Highlight>
    );
  }

  return null;
};

export default UserWarnings;
