// Requires
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

// Components
import { VerticalDivider } from "../../../UI/Divider";
import Text from "../../../UI/Text";

// Types
import { JobDetailsType } from "../../../../types/job";

const JobResume = ({ data }: { data: JobDetailsType }) => {
  return (
    <motion.div
      key={data.id}
      initial={{ opacity: 0, y: 15 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 15 }}
      className="flex flex-col gap-4"
    >
      <Link
        to={`/admin/jobs/${data.id}`}
        target="_blank"
        className="flex flex-row gap-4 rounded-lg border p-2"
      >
        <div className="flex flex-row gap-2">
          <img
            src={data.company?.image ?? "https://picsum.photos/50/50"}
            className="size-12 rounded-lg"
          />
          <div className="flex flex-col justify-between">
            <Text className="font-semibold">{data.company?.displayName}</Text>
            <Text className="text-sm">
              {data.owner.firstName} {data.owner.lastName}
            </Text>
          </div>
        </div>
        <VerticalDivider />
        <div className="flex flex-col justify-between">
          <Text className="font-semibold">{data.title}</Text>
          <div className="flex flex-row gap-2">
            <Text className="text-sm">Durée: {data.duration} heures</Text>
            <Text className="text-sm">-</Text>
            <Text className="text-sm">
              Montant de la mission : {data.rate * data.duration}&euro;
            </Text>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default JobResume;
