// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Components
import Statut from "../../../UI/Jobs/Statut";
import Date from "../../../UI/Date";
import Text from "../../../UI/Text";

// Services
import { getJob } from "../../../../services/Job/job";

// Types
import { JobsType } from "../../../../types/job";

// Context
import { useUser } from "../../../../utils/store/userStore";
import { deleteJob } from "../../../../services/Admin/Jobs";
import Avatar from "../../../UI/Avatar/Avatar";

export const Table = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const user = useUser((state) => state.userdata);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<JobsType>();
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [tempLimit, setTempLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [jobId, setJobId] = useState<number>();
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    jobId: number
  ) => {
    const el = event.currentTarget;
    const rect = el.getBoundingClientRect();

    setPosition({
      top: rect.top + window.scrollY + 45,
      left: rect.left + window.scrollX - 30
    });

    setJobId(jobId);
    setIsOpen(!isOpen);
  };

  // TODO: Move to service
  const handleDeleteJob = async () => {
    if (jobId) {
      const response = await deleteJob(jobId);
      if (response) {
        setData((prevData) => {
          if (prevData) {
            return {
              ...prevData,
              jobs: prevData.jobs.filter((job) => job.id !== jobId)
            };
          }
        });
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      setTempLimit(numericValue);
    }
  };

  const filters = {
    search: searchParams.get("search"),
    status: searchParams.get("status"),
    type: searchParams.get("type"),
    date: searchParams.get("date"),
    startingType: searchParams.get("startingType")
  };

  const tableHeader: string[] = [
    "Titre de la mission",
    "Status",
    "Etudiant",
    "Montant Total (HT)",
    "Date de début",
    "Action",
    ""
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tempLimit < 10) {
        setLimit(10);
        setTempLimit(10);
      } else if (tempLimit > 100) {
        setLimit(100);
        setTempLimit(100);
      } else {
        setLimit(tempLimit);
      }

      setOffset(0);
      setPage(0);
    }, 500);

    return () => clearTimeout(timer);
  }, [tempLimit]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      if (user) {
        const response = await getJob(page, limit, filters);
        setData(response);
        setCount(response.count);
      }
      setIsLoading(false);
    };
    fetch();
  }, [
    user,
    page,
    limit,
    filters.date,
    filters.search,
    filters.status,
    filters.type,
    filters.startingType
  ]);

  return (
    <div className="flex flex-col overflow-x-auto">
      <table className="w-full table-auto">
        <thead className="border-b border-t bg-backgroundGray">
          <tr>
            {tableHeader.map((item) => {
              return (
                <th key={item} className="px-4 py-2 text-left">
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        {!isLoading ? (
          <tbody>
            {data?.jobs.map((item) => {
              return (
                <tr
                  key={item.id}
                  className="cursor-pointer hover:bg-backgroundGray"
                >
                  <td
                    onClick={() => navigate(`${item.id}`)}
                    className="border-t px-4 py-2"
                  >
                    {item.title}
                  </td>
                  <td className="border-t px-4 py-2">
                    <Statut State={item.status} />
                  </td>
                  <td className="border-t px-4 py-2">
                    <div className="flex flex-row gap-2">
                      {item.studentAccepted &&
                      Object.keys(item.studentAccepted).length > 0 ? (
                        <div className="flex flex-row items-center gap-2">
                          <Avatar
                            link={item.studentAccepted.image}
                            className="size-8"
                          />
                          <Text>{item.studentAccepted.firstName}</Text>
                        </div>
                      ) : (
                        "Non défini"
                      )}
                    </div>
                  </td>
                  <td className="border-t px-4 py-2">{item.total}&euro;</td>
                  <td className="border-t px-4 py-2">
                    {item.startingType === "date" ? (
                      <Date date={item.startingAt} format="DD/MM/YYYY" />
                    ) : item.startingType === "asap" ? (
                      "Dès que possible"
                    ) : (
                      "Non défini"
                    )}
                  </td>
                  <td
                    onClick={() => navigate(`${item.id}`)}
                    className="border-t px-4 py-2"
                  >
                    {item.status.name === "underReview" && (
                      <Text>Voir les propositions</Text>
                    )}
                    {item.status.name === "hiring" && (
                      <Text>Choisir une candidature</Text>
                    )}
                    {item.status.name === "hired" && (
                      <Text>Contacter l'étudiant</Text>
                    )}
                    {item.status.name === "closed" && (
                      <Text>Mettre un avis à l'étudiant</Text>
                    )}
                  </td>
                  <td
                    onClick={(event) => handleClick(event, item.id)}
                    className="border-t px-4 py-2"
                  >
                    ....
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="px-4 py-2" colSpan={7}>
                <div className="flex items-center justify-center">
                  <div className="loader" />
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="flex flex-row justify-between border-t p-4 text-sm">
        <div className="flex flex-row items-center gap-2">
          <Text>Afficher</Text>
          <input
            type="number"
            className="w-[80px] rounded-lg border p-2 focus:outline-blueViolet-600"
            value={tempLimit}
            min={10}
            max={100}
            onChange={handleInputChange}
          />
          <Text>missions sur {count}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => {
              setOffset(offset - limit);
              setPage(page - 1);
            }}
            disabled={offset === 0}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &lt;
          </button>
          {count &&
            [...Array(Math.ceil(count / limit))].map((_, i) => {
              const pageNumber = i + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => {
                    setOffset((pageNumber - 1) * limit);
                    setPage(pageNumber - 1);
                  }}
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                    pageNumber === Math.ceil(offset / limit) + 1
                      ? "bg-blueViolet-600 text-white"
                      : ""
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}
          <button
            onClick={() => {
              setOffset(offset + limit);
              setPage(page + 1);
            }}
            disabled={count === undefined ? true : offset + limit >= count}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &gt;
          </button>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ top: position?.top, left: position?.left }}
            className="absolute z-50 flex flex-col gap-2 rounded-lg bg-white p-4 shadow-lg"
          >
            <Text className="cursor-pointer rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white">
              Modifier
            </Text>
            <div onClick={handleDeleteJob}>
              <Text className="cursor-pointer rounded-lg p-2 text-sm hover:bg-blueViolet-600 hover:text-white">
                Supprimer
              </Text>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Table;
