// Icons
import { TextalignJustifyleft } from "iconsax-react";

// Components
import Content from "../../Bloc/Content";
import { BlocTitle } from "../../Bloc/Title";
import Text from "../../UI/Text";
import Title from "../../UI/Title";

// Utils
import { useUser } from "../../../utils/store/userStore";
import { useEffect, useState } from "react";
import { CompanyInformationsType } from "../../../types/company";
import { SiretSearchComponent } from "../../Misc/SiretSearchComponent";

const Facturation = ({
  onChange,
  errors,
  setErrors
}: {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errors: any;
  setErrors: any;
}) => {
  const user = useUser((state) => state.userdata);

  const [companyInformations, setCompanyInformations] =
    useState<CompanyInformationsType>();

  const [siretSelected, setSiretSelected] = useState<string>();

  function handleAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.currentTarget.value;
    setErrors((prev: any) => ({
      ...prev,
      address: (val === "" && "L'adresse ne peut pas être vide.") || ""
    }));

    setCompanyInformations((prev) => ({
      siret: prev?.siret || "",
      address: val || prev?.address || "",
      siren: prev?.siren || "",
      activity: prev?.activity || "",
      createdDate: prev?.createdDate || ""
    }));
  }

  useEffect(() => {
    onChange({
      currentTarget: {
        name: "siret",
        value: companyInformations?.siret || ""
      }
    } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    onChange({
      currentTarget: {
        name: "address",
        value: companyInformations?.address || ""
      }
    } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
  }, [companyInformations]);

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <TextalignJustifyleft size={18} />
        <Title type="bloc">Informations légales</Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-col gap-8 md:max-w-[90%]">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Text className="font-semibold text-blueViolet-600">
                FACTURATION
              </Text>
              <Text className="text-sm">
                Tes informations de contact sont automatiquement ajoutées sur
                les factures générées par la plateforme Juwa à partir des
                informations que tu as renseigné dans les paramètres de ton
                compte.
              </Text>
              <div className="flex flex-col gap-4 rounded-lg border-b bg-blueViolet-200 p-6 md:max-w-[70%]">
                <Text className="text-lg font-semibold">
                  Tes informations<span className="text-red-500"> *</span>
                </Text>
                <div className="flex flex-col gap-4">
                  {user?.firstName} {user?.lastName} - Entreprise Individuelle
                  <Text>{user?.email}</Text>
                  {!user?.legalInfos?.siret ? (
                    <div className="flex flex-col gap-2">
                      <SiretSearchComponent
                        companyInformations={companyInformations}
                        setCompanyInformations={setCompanyInformations}
                        siretSelected={siretSelected}
                        setSiretSelected={setSiretSelected}
                      />
                    </div>
                  ) : (
                    <>
                      <Text>
                        <span className="font-semibold">SIRET: </span>
                        {user.legalInfos.siret}
                      </Text>
                      <Text>
                        <span className="font-semibold">Siège social: </span>
                        {user?.legalInfos?.address}
                      </Text>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Text className="font-semibold text-blueViolet-600">
                MENTIONS LÉGALES
              </Text>
              <Text className="text-sm">
                En cas de retard de paiement, une pénalité de 3 fois le taux
                d'intérét légal sera appliquée l'entreprise, à laquelle
                s'ajoutera une indemnité forfaitaire pour frais de recouvrement
                de 40€.
              </Text>
            </div>
            <div className="flex flex-col gap-2">
              <Text className="font-semibold text-blueViolet-600">
                MENTIONS SPÉCIFIQUES
              </Text>
              <div className="flex flex-row items-center gap-4">
                <Text className="text-sm">
                  Tu as également la possibilité d'ajouter des mentions légales
                  spécifiques qui apparaitront uniquement sur la facture généré
                  pour cette mission (exemple : numéro d'agrément de formation)
                </Text>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <textarea
                onChange={onChange}
                name="specificMentions"
                className="h-[150px] resize-none rounded-lg border p-2 focus:outline-blueViolet-600"
                placeholder="Ecrit les mentions spécifiques que tu souhaite voir apparaître sur la facture"
              />
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default Facturation;
