// Components
import { useChat } from "../../context/Chat/ChatContext";
import ChatContainer from "./ChatContainer";
import ChatInput from "./ChatInput";
import ChatHeader from "./ChatHeader";

const Chat = ({
  OpenLD,
  ldStatic,
  onExit
}: {
  ldStatic: boolean;
  OpenLD?: () => void;
  onExit?: () => void;
}) => {
  const { activeConversation, setIsExpertMode } = useChat();

  if (activeConversation) {
    return (
      <div className="flex w-full flex-1 flex-col gap-4 rounded-lg">
        <div className="flex flex-col">
          <ChatHeader ldStatic={ldStatic} OpenLD={OpenLD} />
          <ChatContainer />
          <ChatInput />
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-1 grow flex-col items-center justify-center gap-4 rounded-lg border bg-white shadow-lg">
      <div className="flex flex-col">
        <div className="loader" />
      </div>
    </div>
  );
};

export default Chat;
