import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

// Components
import SEPA from "../../../../components/Company/Payment/SEPA";
import Text from "../../../../components/UI/Text";
import Title from "../../../../components/UI/Title";
import Reduction from "../Jobs/Manage/Payment/Reduction";
import { subscriptionYouSignSuccess } from "../../../../services/Payment/payment";

const PlanPayment = () => {
  const [codeAdded, setCodeAdded] = useState<{
    id: number;
    title: string;
    percent: number;
    expiredDate: string;
  }>();

  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan") || "starter"; // Default to "starter" if null
  const facturationMode = parseInt(searchParams.get("facturationMode") || "1"); // Default to 1 if null

  const codeCallback = (code: {
    id: number;
    title: string;
    percent: number;
    expiredDate: string;
  }) => {
    setCodeAdded(code);
  };

  const _PRICING = {
    starter: {
      monthly: 99,
      quarterly: 79,
      annualy: 69
    },
    business: {
      monthly: 179,
      quarterly: 149,
      annualy: 129
    }
  };

  const getBasePrice = (plan: string, facturationMode: number) => {
    return _PRICING[plan as keyof typeof _PRICING]?.[
      facturationMode === 1
        ? "monthly"
        : facturationMode === 3
          ? "quarterly"
          : "annualy"
    ];
  };

  const calculateTotalBasePrice = (plan: string, facturationMode: number) => {
    const basePrice = getBasePrice(plan, facturationMode);

    // Ensure the base price exists and calculate the total based on the facturation mode
    if (basePrice !== undefined) {
      const multiplier =
        facturationMode === 1 ? 1 : facturationMode === 3 ? 3 : 12;
      return basePrice * multiplier;
    }
    return null; // or handle error as needed
  };

  const totalBasePrice = calculateTotalBasePrice(plan, facturationMode) ?? 0; // Use 0 as fallback
  const discount = codeAdded ? totalBasePrice * (codeAdded.percent / 100) : 0;
  const finalPrice = totalBasePrice - discount;

  const [signState, setSignState] = useState(false);

  const validateSign = useCallback(async () => {
    // Wrap validateSign in useCallback
    const token = searchParams.get("signingtoken");

    if (token) {
      const response = await subscriptionYouSignSuccess(token);
      if (response) {
        return;
      }
      return;
    }
  }, [searchParams]); // Add dependencies here if necessary

  useEffect(() => {
    const state = searchParams.get("state");
    const signingtoken = searchParams.get("signingtoken");

    if (state === "success" && signingtoken) {
      setSignState(true);
      validateSign();
      return;
    }

    setSignState(false);
    return;
  }, [searchParams, validateSign]);

  if (signState)
    return (
      <div className="flex h-full flex-col p-4">
        <Title type="page">
          Vérification de la signature du mandat de prélévement
        </Title>
        <div className="flex h-full items-center justify-center">
          <Text className="!text-2xl">
            Félicitation, vous avez signez votre mandat de prélévement SEPA !
          </Text>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-12 p-4">
      <Title type="page">Paiement de l'abonnement</Title>
      <div className="flex flex-col items-center gap-16">
        <div className="flex flex-row gap-4">
          <SEPA
            type="subscription"
            code={codeAdded ? codeAdded.title : undefined}
          />
          <div className="flex flex-col gap-4">
            <Reduction callback={codeCallback} />
            <div className="flex h-fit flex-col gap-8 rounded-lg border p-4 shadow-lg">
              <Title type="bloc">Informations de paiement</Title>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-1">
                  <Text>Type d'abonnement :</Text>
                  <Text className="font-semibold">
                    {plan === "starter"
                      ? "STARTER"
                      : plan === "business"
                        ? "BUSINESS"
                        : "BUSINESS+"}
                  </Text>
                </div>
                <div className="flex flex-row gap-1">
                  <Text>Type de facturation :</Text>
                  <Text className="font-semibold">
                    {facturationMode === 1
                      ? "Mensuelle"
                      : facturationMode === 3
                        ? "Trimestrielle"
                        : "Annuelle"}
                  </Text>
                </div>
                <div className="flex flex-row gap-1">
                  <Text>Prix de l'abonnement :</Text>
                  <Text className="font-semibold">{finalPrice * 1.2}€</Text>
                </div>
                {codeAdded && (
                  <div className="flex flex-row gap-1">
                    <Text>Réduction appliquée :</Text>
                    <Text className="font-semibold">
                      -{discount}€ ({codeAdded.percent}%)
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanPayment;
