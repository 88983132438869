// Requires
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

// Icons
import { Category } from "iconsax-react";

// Services
import { updateUserMainInfo } from "../../../../services/User/user";
import { getCategories } from "../../../../services/Misc/misc";

// Utils
import { _educationLevel } from "../../../../utils/misc";
import { useUser } from "../../../../utils/store/userStore";

// Components
import StaffMeAcademy from "../../../Ads/StaffMeAcademy";
import SkillsChanger from "../../../Misc/SkillsChanger";
import Button from "../../../UI/Button";
import Highlight from "../../../UI/Highlight";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";
import ToggleSwitch from "../../../UI/ToggleSwitch";
import Tag from "../../../UI/Tag";

// Types
import { SkillType } from "../../../../types/misc";
type Category = {
  id: number;
  title: string;
};

const Professionalization = () => {
  const user = useUser((state) => state.userdata);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [category, setCategory] = useState<{ id: number; title: string }>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [toggleSwitch, setToggleSwitch] = useState<boolean>(true);
  const [seeMore, setSeeMore] = useState<boolean>(false);

  const [data, setData] = useState<{
    profileTitle: string;
    degree: string;
    category: number;
    skills: number[];
  }>({
    profileTitle: user?.profileTitle || "",
    degree: user?.actualDegree || "",
    category: user?.category?.id || 1,
    skills: user?.skills ? user.skills.map((skill) => skill.id) : []
  });

  const SkillsCallback = (skills: SkillType[]) => {
    setData((prev) => ({
      ...prev,
      skills: skills.map((skill) => skill.id)
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCategory = (id: number, title: string) => {
    setCategory({ id: id, title: title });
  };

  const Update = async () => {
    setIsLoading(true);
    const response = await updateUserMainInfo(data);
    if (response) {
      setIsLoading(false);
      setSuccess(true);
      setTimeout(() => {
        useUser.getState().getUser();
        setSuccess(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const response2 = await getCategories();
      setCategories(response2);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (user?.category) {
      setCategory(user?.category);
    }
  }, [user]);

  useEffect(() => {
    if (category?.id) {
      setData((prev) => ({
        ...prev,
        category: category?.id
      }));
    }
  }, [category]);

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Ta situation</Title>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Text className="font-semibold">
            Le “métier” que tu veux faire sur Juwa
          </Text>
          <input
            type="text"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            placeholder="Indique ton métier"
            value={data.profileTitle}
            name="profileTitle"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Text className="font-semibold">Ton niveau d'études</Text>
          <select
            name="degree"
            onChange={handleChange}
            defaultValue={user?.actualDegree}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            <option value="">Aucun niveau d'étude sélectionné</option>
            {_educationLevel.map((item, _i) => (
              <option className="p-2" key={_i} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <Text className="text-xl font-semibold">Domaine d'étude</Text>
            {category?.title ? (
              <span className="w-fit rounded-lg border border-blueViolet-600 bg-blueViolet-50 px-1 text-xl">
                {category.title}
              </span>
            ) : isLoading ? (
              <div className="loader" />
            ) : (
              <></>
            )}
            <div
              className="cursor-pointer"
              onClick={() => setToggleSwitch((prev) => !prev)}
            >
              <ToggleSwitch isActive={toggleSwitch} />
            </div>
            {!toggleSwitch && (
              <motion.div
                key="animate"
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 5 }}
                className="flex flex-col gap-4 rounded-lg border p-4"
              >
                <div className="flex flex-row flex-wrap gap-4">
                  {categories?.slice(0, seeMore ? 14 : 7).map((item) => (
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCategory(item.id, item.title)}
                      >
                        <Tag key={item.id}>{item.title}</Tag>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <div onClick={() => setSeeMore((prev) => !prev)}>
                  <Text className="cursor-pointer">
                    {seeMore ? "Voir moins" : "Voir plus"}
                  </Text>
                </div>
              </motion.div>
            )}
          </div>
        </div>
        <SkillsChanger callback={SkillsCallback} />
        <StaffMeAcademy />
      </div>
      {success && (
        <Highlight type="Success">
          Les modifications ont bien été appliquées
        </Highlight>
      )}
      <div onClick={Update} className="flex flex-row justify-end">
        <Button type="full">
          {isLoading ? <div className="loader" /> : "Enregistrer"}
        </Button>
      </div>
    </div>
  );
};

export default Professionalization;
