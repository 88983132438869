// Icons
import { ProfileDelete, SmsEdit } from "iconsax-react";

// Components
import Text from "../../UI/Text";
import Title from "../../UI/Title";
import Tag from "../../UI/Tag";
import { DocWithButton } from "../../Documents/Doc";
import Button from "../../UI/Button";

// Helpers
import { capitalizeWords } from "../../../utils/helpers/capitalizeWords";

// Context
import { usePopup } from "../../../context/Popup";

// Types
import type { ProposalType } from "../../../types/proposal";

const StudentVerticalCardMaxProposal = ({ data }: { data: ProposalType }) => {
  const { togglePopup } = usePopup();

  return (
    <div className="flex w-[calc(33.33%-22px)] min-w-[calc(33.33%-22px)] max-w-[calc(33.33%-22px)] flex-col gap-8 rounded-lg border p-4">
      <div className="flex max-w-full flex-col gap-8">
        <div className="flex max-w-full flex-row gap-4">
          <img
            src={data.student.image}
            alt="pic"
            className="h-[50px] min-h-[50px] w-[50px] min-w-[50px] rounded-lg"
          />
          <div className="flex flex-col gap-2">
            <Title type="custom" classname="text-xl">
              {capitalizeWords(
                `${data.student.firstName} ${data.student.lastName}`
              )}
            </Title>
            <Text className="text-sm">{data.student.profileTitle}</Text>
          </div>
        </div>
        <div className="flex max-w-full flex-col gap-2">
          <Text className="text-sm font-semibold">Compétences clés :</Text>
          <div className="flex flex-row flex-wrap gap-2">
            {data.student.skills
              ?.slice(0, 5)
              .map((skill, _id) => <Tag key={_id}>{skill.title}</Tag>)}
          </div>
        </div>
        <div className="flex max-w-full flex-col gap-2">
          <Title type="custom" classname="text-base">
            Proposition de l'étudiant
          </Title>
          <p className="line-clamp-5 max-h-[150px] min-h-[150px] overflow-hidden text-ellipsis whitespace-normal break-normal break-words">
            {data.motivation}
          </p>
        </div>
        {data.attachment && (
          <div className="flex max-w-full flex-col gap-2">
            <Title type="custom" classname="text-base">
              Document(s) joint(s)
            </Title>
            <DocWithButton item={data.attachment} />
          </div>
        )}
        <div className="flex max-w-full flex-row gap-4">
          <div
            className="grow"
            onClick={() => togglePopup("proposalsDetails", data.id)}
          >
            <Button type="full" className="grow text-nowrap text-center">
              Voir la proposition
            </Button>
          </div>
          <div className="flex items-center justify-center rounded-lg border border-primaryOrange p-2">
            <ProfileDelete size={18} className="text-primaryOrange" />
          </div>
          <div className="flex items-center justify-center rounded-lg border border-blueViolet-600 p-2 text-blueViolet-600">
            <SmsEdit size={18} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentVerticalCardMaxProposal;
