// Requires
import { useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";

// Icons
import { InfoCircle } from "iconsax-react";

// Components
import Alternation from "../../../../../../components/Cards/Alternation/Alternation";
import AddNewAlternation from "../../../../../../components/Onboarding/Student/AddNewAlternation";
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import ToggleSwitch from "../../../../../../components/UI/ToggleSwitch";
import { StudentTransportation } from "../../../../../../components/Onboarding/Student/StudentTransportation";
import { NewAlternance } from "../../../../../../components/Onboarding/Student/NewAlternance";
import { NewIntership } from "../../../../../../components/Onboarding/Student/NewInternship";
import PhoneValidationComponent from "../../../../../../components/Onboarding/PhoneValidationComponent";
import CitySearchComponent from "../../../../../../components/Misc/CitySearchComponent";
import Disponibilities from "../../../../../../components/Onboarding/Student/Disponibilities";
import AddNewStage from "../../../../../../components/Onboarding/Student/AddNewStage";
import CustomTooltip from "../../../../../../components/UI/Tooltip";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Services
import { getLanguages } from "../../../../../../services/Misc/misc";
import {
  getUser,
  updateCustomization
} from "../../../../../../services/User/user";

// Helpers
import {
  getLocalStorage,
  removeFromLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

// Type
import type { LanguagesType } from "../../../../../../types/misc";
import type {
  CustomizationType,
  User,
  StudentAvailabilityType,
  transportType
} from "../../../../../../types/user";

// Utils
import { Desktop, Mobile } from "../../../../../../utils/mobile/responsive";
import LanguageSelector from "../../../../../../components/Misc/LanguageSelector";

// Types
export type ErrorType = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  phoneNumberId?: string;
  phoneNumberValidation?: string;
  language?: string;
  workMode?: string;
  city?: string;
  transportType?: string;
  alternationError?: {
    start?: string;
    end?: string;
    description?: string;
  };
  internshipError?: {
    start?: string;
    end?: string;
    description?: string;
  };
};

const Nine = () => {
  const { step, handleNextStep } = useUI();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType>({
    alternationError: {},
    internshipError: {}
  });

  const [phoneNumberIsValidate, setPhoneNumberIsValidate] =
    useState<boolean>(false);

  const [languagesList, setLanguagesList] = useState<LanguagesType[]>([]);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneNumberId, setPhoneNumberId] = useState<string>("+33");

  const [customization, setCustomization] = useState<CustomizationType>({
    firstName: "",
    lastName: "",
    gender: "male",
    isTih: false,
    studentAvailability: [],
    studentWeeklyAvailability: [],
    languages: [],
    transportType: [],
    workMode: "",
    cityId: 0
  });

  const [alternationSwitch, setAlternationSwitch] = useState<boolean>(false);
  const [alternationFormIsVisible, setAlternationFormIsVisible] =
    useState<boolean>(false);
  const [alternation, setAlternation] = useState<StudentAvailabilityType>({
    start: "",
    end: "",
    type: "alternation",
    description: ""
  });
  const [alternations, setAlternations] = useState<StudentAvailabilityType[]>(
    []
  );

  const [internshipSwitch, setInternshipSwitch] = useState<boolean>(false);
  const [internshipFormIsVisible, setInternshipFormIsVisible] =
    useState<boolean>(false);
  const [internship, setInternship] = useState<StudentAvailabilityType>({
    start: "",
    end: "",
    type: "internship",
    description: ""
  });
  const [internships, setInternships] = useState<StudentAvailabilityType[]>([]);

  const handleAlternationChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setAlternation((prev) => ({ ...prev, [name]: value }));
  };

  const handleInternshipChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setInternship((prev) => ({ ...prev, [name]: value }));
  };

  const addAlternation = () => {
    const newError: ErrorType = { alternationError: {}, internshipError: {} };

    if (!newError.alternationError) {
      newError.alternationError = {};
    }

    if (!alternation.start) {
      newError.alternationError.start = "Veuillez indiquer une date de début";
    }

    if (!alternation.end) {
      newError.alternationError.end = "Veuillez indiquer une date de fin";
    }

    if (!alternation.description || alternation.description.length < 50) {
      newError.alternationError.description =
        "Veuillez indiquer une description d'un minimum de 50 caractères";
    }

    if (Object.keys(newError.alternationError).length > 0) {
      setError(newError);
      return;
    }

    setAlternations((prev) => [...prev, alternation]);
    setAlternation({
      start: "",
      end: "",
      type: "alternation",
      description: ""
    });
    setAlternationFormIsVisible((prev) => !prev);
  };

  const addInternship = () => {
    const newError: ErrorType = { alternationError: {}, internshipError: {} };

    if (!newError.internshipError) {
      newError.internshipError = {};
    }

    if (!internship.start) {
      newError.internshipError.start = "Veuillez indiquer une date de début";
    }

    if (!internship.end) {
      newError.internshipError.end = "Veuillez indiquer une date de fin";
    }

    if (!internship.description || internship.description.length < 50) {
      newError.internshipError.description =
        "Veulliez indiquer une description d'un minimum de 50 caractères";
    }

    if (Object.keys(newError.internshipError).length > 0) {
      setError(newError);
      return;
    }

    setInternships((prev) => [...prev, internship]);
    setInternship({ start: "", end: "", type: "internship", description: "" });
    setInternshipFormIsVisible((prev) => !prev);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCustomization((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    let updatedValue: string | boolean = value;

    if (name === "isTih") {
      updatedValue = value === "true"; // Convert "true"/"false" to boolean
    }

    setCustomization((prev) => ({ ...prev, [name]: updatedValue }));
  };

  const handleTransportChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setCustomization((prev) => {
      const update = { ...prev };
      if (update.transportType.includes(value as transportType)) {
        update.transportType = update.transportType.filter(
          (item) => item !== value
        );
      } else {
        update.transportType = [
          ...update.transportType,
          value as transportType
        ];
      }

      return update;
    });
  };

  const fromProvider = async () => {
    const response = await getUser();
    return response;
  };

  const handleLanguageChange = (data: LanguagesType[]) => {
    const languageIds = data.map((language) => language.id);

    setCustomization((prev) => ({
      ...prev,
      languages: languageIds
    }));
  };

  const nextStep = async () => {
    setIsLoading(true);

    const newError: ErrorType = { alternationError: {}, internshipError: {} };

    if (!customization.firstName) {
      newError.firstName = "Tu dois indiquer ton prénom";
    }

    if (!customization.lastName) {
      newError.lastName = "Tu dois indiquer ton nom de famille";
    }

    if (customization.languages.length < 1) {
      newError.language = "Tu dois séléctionner au moins une langue";
    }

    if (customization.workMode.length < 1) {
      newError.workMode = "Tu dois indiquer ton mode de travail préféré";
    }

    if (customization.transportType.length < 1) {
      newError.transportType = "Tu dois indiquer tes modes de transport";
    }

    if (!phoneNumberIsValidate) {
      newError.phoneNumber = "Tu dois valider ton numéro de téléphone";
    }

    if (!customization.cityId) {
      newError.city = "Tu dois indiquer ta ville";
    }

    const hasErrors = Object.keys(newError).some(
      (key) =>
        newError[key as keyof ErrorType] !== undefined &&
        Object.keys(newError[key as keyof ErrorType]!).length > 0
    );

    if (hasErrors) {
      setError(newError);
      setIsLoading(false);
      return;
    }

    // Reorganizes the different data
    const data: CustomizationType = {
      ...customization,
      studentAvailability: [...internships, ...alternations]
    };

    const response = await updateCustomization(data);
    if (response) {
      const localData = [{ key: "studentStep", value: "6" }];
      const email = getLocalStorage("email");
      const calendly = [
        {
          key: "calendlyData",
          value: JSON.stringify({
            email: email,
            phoneNumberId: phoneNumberId,
            phoneNumber: phoneNumber,
            firstName: customization.firstName,
            lastName: customization.lastName
          })
        }
      ];
      setLocalStorage(calendly);
      setLocalStorage(localData);
      removeFromLocalStorage("custom");
      removeFromLocalStorage("email");
      handleNextStep();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      const data = getLocalStorage("custom");
      const provider = getLocalStorage("provider");

      if (data) {
        const test = JSON.parse(data);
        setCustomization((prev) => ({
          ...prev,
          firstName: test.firstName,
          lastName: test.lastName
        }));
        setPhoneNumber(test.phoneNumber);
      } else if (provider) {
        const providerData: User = await fromProvider();
        setCustomization((prev) => ({
          ...prev,
          firstName: providerData.firstName,
          lastName: providerData.lastName
        }));
        // setPhoneNumber(providerData.phoneNumber);
      }

      const response = await getLanguages("");
      if (response) setLanguagesList(response);
    };

    init();
  }, []);

  return (
    <div className="w-full min-w-full">
      <div className="flex items-center justify-center">
        <div className="flex w-full flex-col gap-12 p-5 md:min-w-[50%] md:max-w-[50%] md:gap-16 md:py-6">
          <div className="flex items-center justify-center">
            <Title type="page">
              C'est presque bon, il nous reste quelques détails à vérifier avec
              toi !
            </Title>
          </div>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col justify-between gap-8 md:flex-row">
                <div className="flex flex-1 flex-col gap-2">
                  <Text>
                    Nom<span style={{ color: "red" }}> * </span>
                  </Text>
                  <input
                    value={customization.lastName}
                    onChange={handleInputChange}
                    type="text"
                    name="lastName"
                    className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                      error.firstName ? "border-red-500" : "border"
                    }`}
                    placeholder="Indique ton nom"
                  />
                  {error.firstName && (
                    <Text className="text-sm text-red-500">
                      {error.firstName}
                    </Text>
                  )}
                </div>
                <div className="flex flex-1 flex-col gap-2">
                  <Text>
                    Prénom<span style={{ color: "red" }}> * </span>
                  </Text>
                  <input
                    value={customization.firstName}
                    onChange={handleInputChange}
                    name="firstName"
                    type="text"
                    className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                      error.lastName ? "border-red-500" : "border"
                    }`}
                    placeholder="Indique ton prénom"
                  />
                  {error.lastName && (
                    <Text className="text-sm text-red-500">
                      {error.lastName}
                    </Text>
                  )}
                </div>
              </div>

              <PhoneValidationComponent
                error={error}
                setError={setError}
                phoneNumber={phoneNumber}
                phoneNumberIsValidate={phoneNumberIsValidate}
                setPhoneNumber={setPhoneNumber}
                setPhoneNumberIsValidate={setPhoneNumberIsValidate}
                phoneNumberId={phoneNumberId}
                setPhoneNumberId={setPhoneNumberId}
              />

              <div className="flex flex-col justify-between gap-8 md:flex-row">
                <div className="flex flex-1 flex-col gap-2">
                  <div className="flex flex-row items-center gap-1">
                    <Text>Genre</Text>
                    <CustomTooltip
                      position="top"
                      content="Nous te demandons cette info pour mesurer la parité sur Juwa !"
                      className="flex cursor-pointer flex-row items-center gap-1"
                    >
                      <InfoCircle className="cursor-pointer" size={16} />
                    </CustomTooltip>
                  </div>
                  <select
                    onChange={handleSelectChange}
                    name="gender"
                    className="rounded-lg border p-2 focus:outline-blueViolet-600"
                  >
                    <option value="">Séléctionner une réponse</option>
                    <option value="male">Homme</option>
                    <option value="female">Femme</option>
                    <option value="unspecified">Autre</option>
                  </select>
                </div>
                <div className="flex flex-1 flex-col gap-2">
                  <div className="flex flex-row items-center gap-1">
                    <Text>Est-tu reconnu TIH</Text>
                    <CustomTooltip
                      position="top"
                      content="Le statut de Travailleur Indépendant Handicapé (TIH) concerne tous les entrepreneurs disposant d'une reconnaissance de handicap"
                    >
                      <InfoCircle className="cursor-pointer" size={16} />
                    </CustomTooltip>
                  </div>
                  <select
                    name="isTih"
                    onChange={handleSelectChange}
                    className="rounded-lg border p-2 focus:outline-blueViolet-600"
                  >
                    <option value="">Séléctionner une réponse</option>
                    <option value="false">Non</option>
                    <option value="true">Oui</option>
                    <option value="false">Ne souhaite pas répondre</option>
                  </select>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <LanguageSelector
                  initialData={languagesList}
                  callback={handleLanguageChange}
                />
                {error.language && (
                  <Text className="text-sm text-red-500">{error.language}</Text>
                )}
              </div>

              <div className="flex flex-row justify-between gap-8">
                <div className="flex flex-1 flex-col gap-2">
                  <Text>
                    Tu peux travailler
                    <span style={{ color: "red" }}> * </span>
                  </Text>
                  <select
                    onChange={handleSelectChange}
                    name="workMode"
                    className="rounded-lg border p-2 focus:outline-blueViolet-600"
                  >
                    <option value="">Séléctionner une réponse</option>
                    <option value="remote">A distance</option>
                    <option value="onSite">Sur site</option>
                    <option value="hybrid">Les deux</option>
                  </select>
                  {error.workMode && (
                    <Text className="text-sm text-red-500">
                      {error.workMode}
                    </Text>
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-between gap-8">
                <div className="flex flex-1 flex-col gap-2 max-md:w-full">
                  <Text>
                    Ville<span className="text-red-500"> * </span>
                  </Text>
                  <CitySearchComponent
                    setCityData={(selectedCity: {
                      id: number;
                      label: string;
                      zip_code: number;
                    }) => {
                      setCustomization((prev: any) => ({
                        ...prev,
                        cityId: selectedCity.id
                      }));
                    }}
                  />
                  {error && (
                    <Text className="text-sm text-red-500">{error.city}</Text>
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <Text>
                  Disponibilités régulières
                  <span style={{ color: "red" }}> * </span>
                </Text>
                <CustomTooltip
                  className="max-w-[300px]"
                  position="top"
                  content="Tu dois renseigner tes disponibilités hebdomadaires : n'oublie pas d'indiquer si tu as des après-midi ou des journées complètes dispo dans ta semaine !"
                >
                  <InfoCircle size={16} className="cursor-pointer" />
                </CustomTooltip>
              </div>
              <Disponibilities
                setDataWeeklyAvailability={(data: any) => {
                  setCustomization((prev: CustomizationType) => ({
                    ...prev,
                    studentWeeklyAvailability: data
                  }));
                }}
                error={error}
              />
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col justify-between gap-2">
                <div className="flex flex-1 flex-col gap-2">
                  <Text>
                    Ton cursus inclut-il des années en alternance ?<br />
                    <span className="text-xs text-gray-600">
                      Nous pourrons t'envoyer des offres en rapport avec tes
                      besoins.
                    </span>
                  </Text>
                </div>
                <div onClick={() => setAlternationSwitch((prev) => !prev)}>
                  <ToggleSwitch isActive={alternationSwitch} />
                </div>
              </div>
              {alternationSwitch && (
                <div className="flex flex-col gap-8 rounded-lg border p-4">
                  <div
                    onClick={() => setAlternationFormIsVisible((prev) => !prev)}
                  >
                    <AddNewAlternation />
                  </div>
                  <Mobile>
                    <BottomSheet
                      open={alternationFormIsVisible}
                      onDismiss={() => {
                        setAlternationFormIsVisible(false);
                      }}
                    >
                      <NewAlternance
                        addAlternation={addAlternation}
                        handleAlternationChange={handleAlternationChange}
                        setAlternationFormIsVisible={
                          setAlternationFormIsVisible
                        }
                        error={error}
                        alternation={alternation}
                      />
                    </BottomSheet>
                  </Mobile>
                  <Desktop>
                    {alternationFormIsVisible && (
                      <NewAlternance
                        addAlternation={addAlternation}
                        handleAlternationChange={handleAlternationChange}
                        setAlternationFormIsVisible={
                          setAlternationFormIsVisible
                        }
                        error={error}
                        alternation={alternation}
                      />
                    )}
                  </Desktop>
                  {alternations && alternations.length > 0 && (
                    <div className="flex flex-col gap-2">
                      <Text>Periodes d'alternance</Text>
                      {alternations.map((item, _i) => (
                        <Alternation key={_i} item={item} />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <div className="flex flex-1 flex-col gap-2">
                  <Text>Dois-tu effectuer un stage cette année ?</Text>
                  <span className="text-xs text-gray-600">
                    Nous pourrons t'envoyer des offres en rapport avec tes
                    besoins.
                  </span>
                </div>
                <div onClick={() => setInternshipSwitch((prev) => !prev)}>
                  <ToggleSwitch isActive={false} />
                </div>
              </div>
              {internshipSwitch && (
                <div className="flex flex-col gap-8 rounded-lg p-4 md:border">
                  <div
                    onClick={() => setInternshipFormIsVisible((prev) => !prev)}
                  >
                    <AddNewStage />
                  </div>
                  <Mobile>
                    <BottomSheet
                      open={internshipFormIsVisible}
                      onDismiss={() => {
                        setInternshipFormIsVisible(false);
                      }}
                      className="z-50"
                    >
                      {
                        <NewIntership
                          addInternship={addInternship}
                          handleInternshipChange={handleInternshipChange}
                          setInternshipFormIsVisible={
                            setInternshipFormIsVisible
                          }
                          error={error}
                          internship={internship}
                        />
                      }
                    </BottomSheet>
                  </Mobile>
                  <Desktop>
                    {internshipFormIsVisible && (
                      <NewIntership
                        addInternship={addInternship}
                        handleInternshipChange={handleInternshipChange}
                        setInternshipFormIsVisible={setInternshipFormIsVisible}
                        error={error}
                        internship={internship}
                      />
                    )}
                  </Desktop>
                  {internships && internships.length > 0 && (
                    <div className="flex flex-col gap-2">
                      <Text>Periodes de stage</Text>
                      {internships.map((item, _i) => (
                        <Alternation key={_i} item={item} />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <StudentTransportation
              error={error}
              handleTransportChange={handleTransportChange}
            />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex-1 text-center">
              <Text>Étape {step}/14</Text>
            </div>
            <div onClick={nextStep}>
              <Button type="full">
                {isLoading ? <div className="loader" /> : "Suivant"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nine;
