// Requires
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import Button from "../../../components/UI/Button";
import { HorizontalDivider } from "../../../components/UI/Divider";
import Text from "../../../components/UI/Text";
import Title from "../../../components/UI/Title";

// Store
import { useAuth } from "../../../utils/store/authStore";

// Helpers
import config from "../../../utils/config";

// Assets
import GoogleIcon from "../../../assets/Icons/google.svg";
import LinkedInIcon from "../../../assets/Icons/linkedin.svg";

// Services
import { SafeArea } from "@capacitor-community/safe-area";
import { getUser, login } from "../../../services/User/user";

// Types
type Errors = {
  email?: string;
  password?: string;
};

const Login = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Errors>();
  const [loginError, setLoginError] = useState<string>();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const setIsLogged = useAuth((state) => state.setIsLogged);

  const navigate = useNavigate();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    const newErrors: Errors = {};

    setIsLoading(true);
    setError(undefined);

    if (!email) {
      newErrors.email = "Veuillez renseigner votre adresse email";
    }

    if (!password) {
      newErrors.password = "Veuillez renseigner votre mot de passe";
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      setIsLoading(false);
      return;
    }

    if (email && password) {
      const data = {
        email: email,
        password: password
      };

      const response = await login(data);
      if (!response) {
        setIsLoading(false);
        setLoginError("Identifiant/Mot de passe incorrect");
        return;
      }

      setIsLogged(true);
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    // const info = StatusBar.getInfo();
    const fetch = async () => {
      SafeArea.enable({
        config: {
          navigationBarColor: "#000000"
        }
      });
      const response = await getUser();
      if (!response) return;

      setIsLogged(true);
      navigate("/dashboard");
    };

    setTimeout(() => {
      fetch();
    }, 100);
  }, []);

  return (
    <div className="flex h-full min-w-[50%] flex-col items-center pb-4">
      <div className="my-auto flex w-full max-w-md flex-col gap-16 p-4">
        <div className="flex items-center justify-center">
          <Title type="page">Se connecter à Juwa</Title>
        </div>
        <div className="flex flex-col gap-2">
          <Link to={`${config.backUrl}/auth/login/google?role=student`}>
            <Button type="fullLite">
              <img src={GoogleIcon} alt="Google Icon" sizes="18" /> Se connecter
              avec Google
            </Button>
          </Link>
          <Link to={`${config.backUrl}/auth/login/linkedin?role=student`}>
            <Button type="fullLite">
              <img src={LinkedInIcon} alt="LinkedIn Icon" sizes="18" /> Se
              connecter avec LinkedIn
            </Button>
          </Link>
        </div>
        <div className="flex flex-row items-center gap-2">
          <HorizontalDivider /> <Text>OU</Text> <HorizontalDivider />
        </div>
        <div className="flex flex-col gap-4">
          {loginError && <Text className="text-red-500">{loginError}</Text>}

          <input
            onKeyDown={handleKeyDown}
            onChange={(e) => setEmail(e.currentTarget.value)}
            type="email"
            placeholder="nom@email-professionnel.com"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          />
          {error?.email && <Text className="text-red-500">{error.email}</Text>}
          <div className="relative">
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => setPassword(e.currentTarget.value)}
              type={isPasswordVisible ? "text" : "password"}
              placeholder="********"
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600`}
            />
            <button
              type="button"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              className="absolute inset-y-0 right-0 px-3 py-2 text-sm text-gray-600"
            >
              {isPasswordVisible ? "Cacher" : "Montrer"}
            </button>
          </div>
          {error?.password && (
            <Text className="text-red-500">{error.password}</Text>
          )}
          <div className="flex justify-end">
            <Link
              className="text-sm hover:text-blueViolet-600"
              to="/auth/forgot"
            >
              Mot de passe oublié ?{" "}
            </Link>
          </div>
          <div onClick={handleLogin}>
            <Button type="full">
              {isLoading ? "Loading..." : "Se connecter"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
