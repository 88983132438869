// Components
import Button from "../../../UI/Button";
import Highlight from "../../../UI/Highlight";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";

// Requires
import { useState } from "react";

// Helpers
import { validatePassword } from "../../../../utils/helpers/validators";

// Services
import { updatePassword } from "../../../../services/User/user";

// Types
type Error = {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
};

const Password = () => {
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>();

  const [errors, setErrors] = useState<Error>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [success, setSuccess] = useState<boolean>(false);

  const update = async () => {
    const newErrors: Error = {};
    setErrors(newErrors);
    setIsLoading(true);

    if (!oldPassword) {
      newErrors.oldPassword = "Tu dois entrer ton ancien mot de passe";
    }

    if (!newPassword) {
      newErrors.newPassword = "Tu dois entrer un nouveau mot de passe";
    }

    if (newPassword && !validatePassword(newPassword)) {
      newErrors.newPassword =
        "Le format du nouveau mot de passe n'est pas correct";
    }

    if (!confirmNewPassword) {
      newErrors.confirmNewPassword =
        "Tu dois confirmer le nouveau mot de passe";
    }

    if (confirmNewPassword !== newPassword) {
      newErrors.confirmNewPassword =
        "Cette confirmation de mot de passe ne correspond pas au nouveau mot de passe";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    if (oldPassword && newPassword) {
      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword
      };

      const response = await updatePassword(data);
      if (response) {
        setSuccess(true);
        setIsLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Mot de passe</Title>
      {success && (
        <Highlight type="Success">
          Ton mot de passe a bien été mis à jour
        </Highlight>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Text className="font-semibold">Ancien mot de passe</Text>
          {errors.oldPassword && (
            <Text className="text-sm text-red-500">{errors.oldPassword}</Text>
          )}
          <input
            type="password"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            placeholder="******"
            onChange={(e) => setOldPassword(e.currentTarget.value)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Text className="font-semibold">Nouveau mot de passe</Text>
          {errors.newPassword && (
            <Text className="text-sm text-red-500">{errors.newPassword}</Text>
          )}
          <input
            type="password"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            placeholder="******"
            onChange={(e) => setNewPassword(e.currentTarget.value)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Text className="font-semibold">
            Confirmer le nouveau mot de passe
          </Text>
          {errors.confirmNewPassword && (
            <Text className="text-sm text-red-500">
              {errors.confirmNewPassword}
            </Text>
          )}
          <input
            type="password"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            placeholder="******"
            onChange={(e) => setConfirmNewPassword(e.currentTarget.value)}
          />
        </div>
      </div>
      {!oldPassword || !newPassword || !confirmNewPassword ? (
        <div className="flex justify-end">
          <Button type="disabled" className="w-fit">
            Enregister
          </Button>
        </div>
      ) : (
        <div onClick={update} className="flex flex-row justify-end">
          <Button type="full">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Password;
