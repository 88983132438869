// Requires
import { type AnimationProps, motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

// Components
import StudentVerticalCard from "../../../components/Cards/Student/StudentVerticalCard";
import Filters from "../../../components/FindTalent/Filters";
import TopFilter from "../../../components/FindTalent/TopFilter";
import Button from "../../../components/UI/Button";
import Text from "../../../components/UI/Text";
import Title from "../../../components/UI/Title";

// Services
import { GetAllStudents } from "../../../services/Student/student";

// Context
import { useUI } from "../../../utils/context/UI";

// Utils
import { useMobile } from "../../../utils/mobile/responsive";

// Types
import type { StudentType } from "../../../types/user";

type FiltersSelectedType = {
  categories: number[];
  skills: number[];
  languages: number[];
  search: string;
  location: string;
  degrees: number[];
};

const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const FindTalent = () => {
  const [data, setData] = useState<StudentType[]>([]);
  const [page, setPage] = useState<number>(0);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { findFilterIsOpen } = useUI();
  const filterWidth = useMobile() ? "100%" : "320px";
  const [filtersSelected, setFilterSelected] = useState<FiltersSelectedType>({
    categories: [],
    skills: [],
    languages: [],
    degrees: [],
    search: "",
    location: ""
  });
  const debouncedFilters = useDebounce(filtersSelected, 300);
  const endOfListRef = useRef<HTMLDivElement | null>(null);

  const handleUpdateFilter = (type: string, value: number | string) => {
    let updateFunc: (prev: FiltersSelectedType) => FiltersSelectedType;
    switch (type) {
      case "domaine":
        updateFunc = (prev) => ({
          ...prev,
          categories: prev.categories.includes(Number(value))
            ? prev.categories.filter((category) => category !== Number(value))
            : [...prev.categories, Number(value)]
        });
        break;
      case "skills":
        updateFunc = (prev) => ({
          ...prev,
          skills: prev.skills.includes(Number(value))
            ? prev.skills.filter((skill) => skill !== Number(value))
            : [...prev.skills, Number(value)]
        });
        break;
      case "languages":
        updateFunc = (prev) => ({
          ...prev,
          languages: prev.languages.includes(Number(value))
            ? prev.languages.filter((language) => language !== Number(value))
            : [...prev.languages, Number(value)]
        });
        break;
      case "degrees":
        updateFunc = (prev) => ({
          ...prev,
          degrees: prev.degrees.includes(Number(value))
            ? prev.degrees.filter((degree) => degree !== Number(value))
            : [...prev.degrees, Number(value)]
        });
        break;
      case "search":
        updateFunc = (prev) => ({
          ...prev,
          search: value === prev.search ? "" : String(value)
        });
        break;
      case "location":
        updateFunc = (prev) => ({
          ...prev,
          location: value === prev.location ? "" : String(value)
        });
        break;
      default:
        return;
    }
    setFilterSelected(updateFunc);
  };

  const fetchData = useCallback(
    async (pageNumber: number) => {
      setIsLoading(true);
      try {
        const response = await GetAllStudents(pageNumber, 9, debouncedFilters);
        if (response.students.length > 0) {
          setData((prev) =>
            pageNumber === 0
              ? response.students
              : [...prev, ...response.students]
          );
          if (response.students.length < 9) {
            setHasMoreData(false);
          }
        } else {
          setHasMoreData(false);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des étudiants :", error);
      } finally {
        setIsLoading(false);
      }
    },
    [debouncedFilters]
  );

  useEffect(() => {
    setPage(0);
    setHasMoreData(true);
    setData([]);
    fetchData(0);
  }, [debouncedFilters, fetchData]);

  useEffect(() => {
    if (!endOfListRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreData && !isLoading) {
          setPage((prev) => prev + 1);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
      }
    );

    observer.observe(endOfListRef.current);

    return () => {
      if (endOfListRef.current) {
        observer.unobserve(endOfListRef.current);
      }
    };
  }, [hasMoreData, isLoading]);

  useEffect(() => {
    if (page === 0) return;
    fetchData(page);
  }, [page, fetchData]);

  const animate: AnimationProps = {
    initial: {
      width: "0px",
      minWidth: "0px",
      x: -15,
      display: "none"
    },
    animate: {
      width: findFilterIsOpen ? filterWidth : "0px",
      minWidth: findFilterIsOpen ? filterWidth : "0px",
      x: findFilterIsOpen ? 0 : -15,
      display: findFilterIsOpen ? "flex" : "none",
      transition: { duration: 0.3 }
    }
  };

  return (
    <div className="flex flex-col gap-24 max-md:gap-6">
      <div className="flex flex-col items-start gap-3 md:flex-row md:items-center md:justify-between md:gap-0">
        <Title type="page" classname="text-start md:text-center">
          Trouvez les talents qui correspondent à vos besoins
        </Title>
        <div
          data-tooltip-id="my-tooltip"
          data-tooltip-content={
            "Décrivez votre besoin à votre expert Juwa qui vous accompagnera dans la publication de votre mission"
          }
          data-tooltip-place={"top-end"}
          className={`w-fit text-end`}
        >
          <Link to={"/company/expert"}>
            <Button type="info">Nous confier le recrutement</Button>
          </Link>
        </div>
        <Tooltip
          style={{ backgroundColor: "#33367c" }}
          id="my-tooltip"
          className={`z-50 bg-blueViolet-900`}
        />
      </div>
      <div className="flex flex-col gap-8">
        <TopFilter handleUpdateFilter={handleUpdateFilter} />
        <div className="flex flex-col gap-4">
          <div className="flex w-full flex-col gap-8 overflow-hidden md:flex-row">
            <motion.div
              className="flex-nowrap overflow-hidden"
              initial={animate.initial}
              animate={animate.animate}
            >
              <Filters handleUpdateFilter={handleUpdateFilter} />
            </motion.div>
            <div className="flex flex-1 flex-col">
              <div className="flex flex-wrap gap-8">
                {data.length > 0 ? (
                  data.map((item) => (
                    <StudentVerticalCard
                      key={item.id}
                      width={findFilterIsOpen}
                      item={item}
                    />
                  ))
                ) : (
                  <div className="flex w-full items-center justify-center">
                    <Text>Aucun étudiant actuellement disponible</Text>
                  </div>
                )}
              </div>
              {isLoading && (
                <div className="my-4 flex h-fit w-full justify-center">
                  <div className="loader" />
                </div>
              )}
              <div ref={endOfListRef} className="h-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FindTalent);
