// Icons
import { Menu } from "iconsax-react";

// Components
import Text from "../../UI/Text";
import Title from "../../UI/Title";
import Date from "../../UI/Date";

// Types
import type { JobDetailsType } from "../../../types/job";

// Utils
import { capitalizeFirstLetter } from "../../../utils/helpers/capitalizeFirstLetter";

const JobResume = ({ data }: { data: JobDetailsType }) => {
  return (
    <div className="flex h-fit w-full flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <Menu size={18} />
        <Title type="bloc">Détail de la mission</Title>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-col rounded-lg bg-blueViolet-50 p-4">
            <Text className="font-semibold">Date de début</Text>
            {data?.startingType === "date" ? (
              <Date date={data?.startingAt} format="DD-MM-YYYY" />
            ) : (
              <Text>
                {data?.startingType === "asap"
                  ? "Dès que possible"
                  : "Non spécifiée"}
              </Text>
            )}
          </div>
          <div className="flex flex-col rounded-lg bg-blueViolet-50 p-4">
            <Text className="font-semibold">Durée estimée</Text>
            <Text>{data?.duration} heures</Text>
          </div>
          <div className="flex flex-col rounded-lg bg-blueViolet-50 p-4">
            <Text className="font-semibold">Type de mission</Text>
            <Text>
              {data?.type === "punctual" ? "Ponctuelle" : "Récurrente"}
            </Text>
          </div>
        </div>
        <div className="flex w-fit w-full flex-row gap-6 rounded-lg bg-blueViolet-600 p-4 text-white">
          <div className="flex flex-col gap-2 text-white">
            <Text className="font-semibold text-white">Taux horaire</Text>
            <Text className="text-white">{data?.rate} &euro; par heure</Text>
          </div>
          <div className="flex flex-col gap-2">
            <Text className="font-semibold text-white">Montant total</Text>
            <Text className="text-white">
              {data?.duration * data?.rate} &euro;
            </Text>
          </div>
        </div>
        <div className="flex w-full flex-row gap-4 >:flex-1">
          <div className="flex flex-col rounded-lg bg-blueViolet-50 p-4">
            <Text className="font-semibold">Modalité de travail</Text>
            <Text>
              {data?.workMode === "onSite"
                ? "Sur site"
                : data?.workMode === "hybrid"
                  ? "Hybride"
                  : "Télétravail"}
            </Text>
          </div>
          {data?.workMode !== "remote" && (
            <div className="flex flex-col rounded-lg bg-blueViolet-50 p-4">
              <Text className="font-semibold">Localisation</Text>
              <Text>{capitalizeFirstLetter(data?.city.label)}</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobResume;
