import { useUser } from "../../../utils/store/userStore";
import Highlight from "../Highlight";

const UserErrors = () => {
  const user = useUser((state) => state.userdata);

  if (user?.errors && Object.keys(user?.errors).length > 0) {
    return (
      <Highlight type="Error">
        {user.errors.company && (
          <>
            Ton auto-entreprise n'est pas défini.
            <br />
          </>
        )}
      </Highlight>
    );
  }

  return null;
};

export default UserErrors;
