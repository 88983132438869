// Requires
import { useEffect, useState } from "react";

// Components
import Text from "../../UI/Text";

// Types
import { availibityType, WeeklyAvailabilityType } from "../../../types/user";

interface DisponibilitiesProps {
  setDataWeeklyAvailability?: any;
  error: any;
  defaultAvailability?: WeeklyAvailabilityType[];
}

const Disponibilities: React.FC<DisponibilitiesProps> = ({
  setDataWeeklyAvailability,
  error,
  defaultAvailability
}) => {
  const timePeriods = ["morning", "afternoon", "evening"];
  const periodLabels = ["Matin", "Après-midi", "Soir"];
  const [weeklyAvailability, setWeeklyAvailability] = useState<
    WeeklyAvailabilityType[]
  >(
    defaultAvailability || [
      { availability: ["evening"] },
      { availability: ["evening"] },
      { availability: ["evening"] },
      { availability: ["evening"] },
      { availability: ["evening"] },
      { availability: ["morning", "afternoon", "evening"] },
      { availability: ["afternoon", "morning", "evening"] }
    ]
  );

  const handleAvailibityChange = (
    dayIndex: number,
    period: "morning" | "evening" | "afternoon"
  ) => {
    setWeeklyAvailability((prev) => {
      if (!setDataWeeklyAvailability) return prev;
      const updated = [...prev];
      const dayAvailability = updated[dayIndex].availability;

      if (dayAvailability.includes(period)) {
        // Remove the period if it's already included
        updated[dayIndex].availability = dayAvailability.filter(
          (time) => time !== period
        );
      } else {
        // Add the period if it's not included
        updated[dayIndex].availability = [...dayAvailability, period];
      }

      return updated;
    });
  };

  useEffect(() => {
    if (setDataWeeklyAvailability) {
      setDataWeeklyAvailability(weeklyAvailability);
    }
  }, [weeklyAvailability]);

  return (
    <div className="flex flex-row justify-between gap-8">
      <div className="flex flex-1 flex-col gap-2">
        <div className="flex flex-col gap-2 rounded-lg border p-4">
          <div className="flex flex-row md:gap-4">
            <div className="min-w-11 flex-1"></div>
            {Array.from({ length: 7 }).map((_, index) => {
              const dayLabels = ["L", "M", "Me", "J", "V", "S", "D"];
              const availability = weeklyAvailability[index]?.availability;
              if (!availability) return null;
              const backgroundColor =
                availability.length > 2
                  ? "#F2FBF3"
                  : availability.length > 0
                    ? "#fff7ed"
                    : "white";
              const borderColor =
                availability.length > 2
                  ? "#2D803D"
                  : availability.length > 0
                    ? "#ea540c"
                    : "gray";

              return (
                <div
                  key={index}
                  className="flex flex-1 items-center justify-center"
                >
                  <div
                    style={{
                      backgroundColor,
                      borderColor
                    }}
                    className="flex h-1 w-1 items-center justify-center rounded-full border p-3 text-sm md:h-6 md:w-6 md:p-4"
                  >
                    <Text className="text-xs md:text-base">
                      {dayLabels[index]}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
          {timePeriods.map((period, periodIndex) => (
            <div
              key={period}
              className="flex flex-row justify-between md:gap-4"
            >
              <div className="min-w-11 flex-1">{periodLabels[periodIndex]}</div>
              {Array.from({ length: 7 }).map((_, dayIndex) => (
                <div
                  key={dayIndex}
                  className="flex flex-1 items-center justify-center"
                >
                  <input
                    type="checkbox"
                    checked={weeklyAvailability[
                      dayIndex
                    ]?.availability?.includes(period as availibityType)}
                    className="h-4 w-4"
                    onChange={() =>
                      handleAvailibityChange(dayIndex, period as availibityType)
                    }
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Disponibilities;
