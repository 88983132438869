// Requires
import { useState } from "react";

// Icons
import { InfoCircle } from "iconsax-react";

// Components
import { HorizontalDivider } from "../../UI/Divider";
import Highlight from "../../UI/Highlight";
import Text from "../../UI/Text";
import { additionalServicesType } from "./Resume/NextStep";
import CustomTooltip from "../../UI/Tooltip";

// Types
import type { JobCreateType } from "../../../types/job";

// Helpers
import { calculatePricing } from "../../../utils/helpers/pricingCalculation";

const JobBudgetSummary = ({
  type,
  data,
  additionalServices = { nda: false, contract: false }
}: {
  type: "punctual" | "recurent";
  data: JobCreateType;
  additionalServices?: additionalServicesType;
}) => {
  const juwaPercentage: number = 25; // user plan percentage

  const {
    studentPricing,
    juwaHT,
    totalPriceHT,
    totalPriceTTC,
    deposit,
    additionalServicesCost
  } = calculatePricing(
    data.duration ?? 0,
    data.rate ?? 0,
    juwaPercentage,
    20,
    20,
    additionalServices.contract,
    additionalServices.nda
  );

  if (type === "punctual" && data.duration && data.rate) {
    return (
      <PunctualJobBudgetSummary
        data={data}
        deposit={deposit}
        juwaHT={juwaHT}
        studentPricing={studentPricing}
        totalPriceHT={totalPriceHT}
        totalPriceTTC={totalPriceTTC}
        additionalServices={additionalServices}
        additionalServicesCost={additionalServicesCost}
      />
    );
  }

  if (type === "recurent") {
    return (
      <RecurrentJobBudgetSummary
        data={data}
        deposit={deposit}
        juwaHT={juwaHT}
        studentPricing={studentPricing}
        totalPriceHT={totalPriceHT}
        totalPriceTTC={totalPriceTTC}
        additionalServices={additionalServices}
        additionalServicesCost={additionalServicesCost}
      />
    );
  }

  return null;
};

export default JobBudgetSummary;

interface JobBudgetSummaryProps {
  data: JobCreateType;
  deposit: number;
  juwaHT: number;
  studentPricing: number;
  totalPriceHT: number;
  totalPriceTTC: number;
  additionalServices?: additionalServicesType;
  additionalServicesCost: number;
}

const PunctualJobBudgetSummary = ({
  data,
  deposit,
  juwaHT,
  studentPricing,
  totalPriceHT,
  totalPriceTTC,
  additionalServices,
  additionalServicesCost
}: JobBudgetSummaryProps) => {
  return (
    <div className="sticky top-0 flex flex-col gap-8">
      <div className="flex flex-col gap-8 rounded-lg border p-4">
        <div className="flex flex-col gap-2">
          <Text className="font-semibold">Budget estimé</Text>
          <Text className="break-normal break-words text-sm">
            Le montant est indiqué à titre informatif
          </Text>
        </div>
        <div className="flex flex-col gap-4 rounded-lg bg-blueViolet-50 p-4">
          <div className="flex flex-row items-center justify-between gap-4">
            <div className="flex flex-col">
              <Text className="font-semibold">Prestation de l'étudiant</Text>
              <Text className="text-sm">
                ({data.duration}h à {data.rate}
                &euro;/h)
              </Text>
            </div>
            {data?.duration && data.rate && (
              <Text>{studentPricing} &euro;</Text>
            )}
          </div>
          <HorizontalDivider className="bg-[#DCDCDC]" />
          <div className="flex flex-row items-center justify-between gap-4">
            <div className="flex flex-col">
              <CustomTooltip
                content="Seuls les frais de services de la plateforme sont soumis à la TVA à 20%."
                position="top"
              >
                <div className="flex flex-row items-center gap-2">
                  <Text className="font-semibold">Frais Juwa (HT)</Text>
                  <InfoCircle size={16} className="cursor-pointer" />
                </div>
              </CustomTooltip>
              <Text className="text-sm">(20% du montant de la prestation)</Text>
            </div>
            {data?.rate && data?.duration && (
              <Text>
                {juwaHT}
                &euro;
              </Text>
            )}
          </div>
          {additionalServices &&
            (additionalServices.contract || additionalServices.nda) && (
              <AdditionalServicesCostsComponent
                additionalServices={additionalServices}
                additionalServicesCost={additionalServicesCost}
                data={data}
              />
            )}
          <HorizontalDivider className="bg-[#DCDCDC]" />
          <div className="flex flex-row justify-between gap-4">
            <Text className="text-blueViolet-600">TOTAL (HT)</Text>
            <Text className="text-blueViolet-600">
              {totalPriceHT}
              &euro;
            </Text>
          </div>
          <HorizontalDivider className="bg-[#DCDCDC]" />
          <div className="flex flex-row justify-between gap-4">
            <Text className="font-semibold text-blueViolet-600">
              TOTAL (TTC)
            </Text>
            <Text className="font-semibold text-blueViolet-600">
              {totalPriceTTC}
              &euro;
            </Text>
          </div>
          {studentPricing >= 500 && (
            <>
              <HorizontalDivider className="bg-[#DCDCDC]" />
              <div className="flex flex-row justify-between gap-4">
                <Text className="text-blueViolet-600">
                  Accompte à régler au lancement de la mission
                </Text>
                <Text className="text-blueViolet-600">
                  {deposit}
                  &euro;
                </Text>
              </div>
            </>
          )}
        </div>
        {data.rate && data.rate < 15 && (
          <Highlight type="Warning" className="!items-start">
            <Text className="text-sm text-black">
              {/* Le tarif moyen d'un Développeur fullstack est de : <br />
              <br /> XX &euro; de l'heure <br /> */}
              Juwa rémunère ses étudiants à un taux minimal de 15 &euro; de
              l'heure
            </Text>
          </Highlight>
        )}
      </div>
      <Highlight type="Information" className="!items-start">
        <Text className="text-sm text-black">
          La majorité des étudiants sont disponibles en soirée et week-end
          uniquement sur les périodes scolaires.
        </Text>
      </Highlight>
    </div>
  );
};

const RecurrentJobBudgetSummary = ({
  data,
  deposit,
  juwaHT,
  studentPricing,
  totalPriceHT,
  totalPriceTTC,
  additionalServices,
  additionalServicesCost
}: JobBudgetSummaryProps) => {
  const [numberOfWeeks, setNumberOfWeeks] = useState<number>(1);

  return (
    <>
      {data.duration && data.rate && (
        <div className="sticky top-0 flex flex-col gap-8">
          <div className="flex flex-col gap-8 rounded-lg border p-4">
            <div className="gap flex flex-col">
              <Text className="font-semibold">
                Budget estimé{" "}
                {numberOfWeeks > 1 ? `pour ${numberOfWeeks}` : `par`} semaine
                {numberOfWeeks > 1 ? "s" : ""}
              </Text>
              <Text className="text-sm">
                Le montant est indiqué à titre informatif.
              </Text>
              <div className="flex flex-row items-center gap-2 py-2">
                <Text className="text-sm text-withCustom-500">Pour </Text>
                <input
                  onChange={(e) => setNumberOfWeeks(Number(e.target.value))}
                  name="numberOfWeeks"
                  type="number"
                  min={1}
                  value={numberOfWeeks ?? ""}
                  placeholder="1"
                  className="w-[80px] rounded-lg border p-1 focus:outline-blueViolet-600"
                />
                <Text className="text-sm text-withCustom-500">
                  semaine{numberOfWeeks > 1 ? "s" : ""}
                </Text>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Text className="text-sm font-semibold">
                Au lancement de la mission, vous paierez le solde pour la fin du
                mois en cours.
              </Text>
              <div className="flex flex-col gap-4 rounded-lg bg-blueViolet-50 p-4">
                <div className="flex flex-row items-center justify-between gap-4">
                  <div className="flex flex-col">
                    <Text className="font-semibold">
                      Prestation de l'étudiant
                    </Text>
                    <Text className="text-sm">
                      ({data.duration * numberOfWeeks}h à {data.rate}
                      &euro;/h)
                    </Text>
                  </div>
                  {data?.duration && data.rate && (
                    <Text>{studentPricing * numberOfWeeks}&euro;</Text>
                  )}
                </div>
                <HorizontalDivider className="bg-[#DCDCDC]" />
                <div className="flex flex-row items-center justify-between gap-4">
                  <div className="flex flex-col">
                    <CustomTooltip
                      content="Seuls les frais de services de la plateforme sont soumis à la TVA à 20%."
                      position="top"
                    >
                      <div className="flex flex-row items-center gap-2">
                        <Text className="font-semibold">Frais Juwa (HT)</Text>
                        <InfoCircle size={16} className="cursor-pointer" />
                      </div>
                    </CustomTooltip>
                    <Text className="text-sm">
                      (20% du montant de la prestation)
                    </Text>
                  </div>
                  {data?.rate && data?.duration && (
                    <Text>{juwaHT * numberOfWeeks}&euro;</Text>
                  )}
                </div>
                {additionalServices &&
                  (additionalServices.contract || additionalServices.nda) && (
                    <AdditionalServicesCostsComponent
                      additionalServices={additionalServices}
                      additionalServicesCost={additionalServicesCost}
                      data={data}
                    />
                  )}
                <HorizontalDivider className="bg-[#DCDCDC]" />
                <div className="flex flex-row justify-between gap-4">
                  <Text className="text-blueViolet-600">TOTAL (HT)</Text>
                  <Text className="text-blueViolet-600">
                    {totalPriceHT * numberOfWeeks}&euro;
                  </Text>
                </div>
                <HorizontalDivider className="bg-[#DCDCDC]" />
                <div className="flex flex-row justify-between gap-4">
                  <Text className="font-semibold text-blueViolet-600">
                    TOTAL (TTC)
                  </Text>
                  <Text className="font-semibold text-blueViolet-600">
                    {totalPriceTTC * numberOfWeeks}&euro;
                  </Text>
                </div>
              </div>
            </div>
            {data.rate && data.rate < 15 && (
              <Highlight type="Warning" className="!items-start">
                <Text className="text-sm text-black">
                  {/* Le tarif moyen d'un Développeur fullstack est de : <br />
              <br /> XX &euro; de l'heure <br /> */}
                  Juwa rémunère ses étudiants à un taux minimal de 15 &euro; de
                  l'heure
                </Text>
              </Highlight>
            )}
          </div>
          {data.type === "recurent" && data.duration && data.duration >= 15 && (
            <Highlight type="Information" className="!items-start">
              <Text className="text-sm text-black">
                La majorité des étudiants sont disponibles en soirée et week-end
                uniquement sur les périodes scolaires.
              </Text>
            </Highlight>
          )}
        </div>
      )}
    </>
  );
};

interface AdditionalServicesCostsComponentProps {
  additionalServices: additionalServicesType;
  additionalServicesCost: number;
  data: JobCreateType;
}

const AdditionalServicesCostsComponent = ({
  additionalServices,
  additionalServicesCost,
  data
}: AdditionalServicesCostsComponentProps) => {
  return (
    <div className="flex flex-row items-center justify-between gap-4">
      <div className="flex flex-col">
        <CustomTooltip
          content="Seuls les frais de services de la plateforme sont soumis à la TVA à 20%."
          position="top"
        >
          <div className="flex flex-row items-center gap-2">
            <Text className="font-semibold">Frais juridiques Juwa (HT)</Text>
            <InfoCircle size={16} className="cursor-pointer" />
          </div>
        </CustomTooltip>
        <Text className="text-sm">
          {additionalServices.contract && "Contrat"}
          {additionalServices.contract && additionalServices.nda && " et "}
          {additionalServices.nda && "NDA"}
        </Text>
      </div>
      {data?.rate && data?.duration && (
        <Text>{additionalServicesCost} &euro;</Text>
      )}
    </div>
  );
};
