// Requires
import { Outlet } from "react-router-dom";

// Stores
import { useUser } from "../../utils/store/userStore";

// Helpers
import usePathname from "../../hooks/usePathname";

const SubPage = () => {
  const user = useUser((state) => state.userdata);
  const pathname = usePathname();

  const _AUTHORIZATION: { [key in "student" | "company" | "admin"]: string[] } =
    {
      student: ["/student"],
      company: ["/company"],
      admin: ["/admin"]
    };

  const isAuthorized =
    user?.roleName &&
    _AUTHORIZATION[user.roleName as keyof typeof _AUTHORIZATION]?.some((path) =>
      pathname.pathname.includes(path)
    );

  if (isAuthorized) {
    return <Outlet />;
  }

  return <p>NOT AUTHORIZED</p>;
};

export default SubPage;
