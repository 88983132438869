// Requires
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

// Components
import Text from "../../../UI/Text";
import Statut from "../../../UI/Jobs/Statut";
import Date from "../../../UI/Date";

// Services
import { getJobs } from "../../../../services/Admin/Jobs";
import Avatar from "../../../UI/Avatar/Avatar";

const TableAdminJobs = () => {
  const [data, setData] = useState<
    {
      id: number;
      title: string;
      acceptedProposalId: number;
      context: string;
      deliverables: string;
      duration: number;
      endingAt: string;
      location: string;
      objectives: string;
      company: {
        displayName: string;
        id: number;
        image: string;
      };
      rate: number;
      startingAt: string;
      startingType: string;
      type: string;
      updateAd: string;
      workMode: string;
      status: {
        index: number;
        name: "hiring" | "hired" | "closed" | "underReview";
      };
      owner: {
        firstName: string;
        lastName: string;
        image: string;
      };
      studentAccepted: {
        firstName: string;
        lastName: string;
        image: string;
        id: number;
      };
      total: number;
    }[]
  >([]);

  const [count, setCount] = useState<number>();
  const [limit, setLimit] = useState<number>(10);
  const [tempLimit, setTempLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const [searchParams] = useSearchParams();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      setTempLimit(numericValue);
    }
  };

  const filters = {
    status: searchParams.get("status"),
    date: searchParams.get("date"),
    search: searchParams.get("search"),
    startingType: searchParams.get("startingType")
  };

  const tableHeader: string[] = [
    "Titre de la mission",
    "Entreprise",
    "Statut",
    "Etudiant",
    "Montant total (HT)",
    "Date de début",
    ""
  ];

  const handleSelectAll = () => {
    if (allChecked) {
      setCheckedItems([]);
    } else {
      const visibleIds = data.map((item) => item.id);
      setCheckedItems(visibleIds);
    }
    setAllChecked(!allChecked);
  };

  const handleCheckboxChange = (id: number) => {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tempLimit < 10) {
        setLimit(10);
        setTempLimit(10);
      } else if (tempLimit > 100) {
        setLimit(100);
        setTempLimit(100);
      } else {
        setLimit(tempLimit);
      }

      setOffset(0);
      setPage(0);
    }, 500);

    return () => clearTimeout(timer);
  }, [tempLimit]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getJobs(
        page,
        limit,
        filters.status,
        filters.date,
        filters.search,
        filters.startingType
      );

      if (response) {
        setData(response.jobs);
        setCount(response.count);
      }
    };

    fetch();
  }, [
    filters.search,
    filters.status,
    filters.date,
    limit,
    page,
    filters.startingType
  ]);

  return (
    <div className="flex flex-col">
      <table className="w-full table-auto">
        <thead className="border-b border-t bg-backgroundGray">
          <tr>
            <th className="px-4 py-2 text-left">
              <input
                type="checkbox"
                checked={allChecked}
                onChange={handleSelectAll}
              />
            </th>
            {tableHeader.map((item, _id) => (
              <th key={_id} className="px-4 py-2 text-left">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, _id) => {
              const isChecked = checkedItems.includes(item.id);
              return (
                <tr key={_id} className="cursor-pointer hover:bg-gray-100">
                  <th className="px-4 py-2 text-left">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </th>
                  <td className="px-4 py-2 text-left">
                    <Link to={`/admin/jobs/${item.id}`}>{item.title}</Link>
                  </td>
                  <td className="px-4 py-2 text-left">
                    <Link
                      className="flex flex-row items-center gap-2"
                      to={`/admin/company/${item?.company?.id}`}
                    >
                      <Avatar
                        link={item?.company?.image}
                        className="size-8 rounded-lg"
                      />
                      {item?.company?.displayName}
                    </Link>
                  </td>
                  <td className="px-4 py-2 text-left">
                    <Statut State={{ index: 0, name: item.status.name }} />
                  </td>
                  <td className="px-4 py-2 text-left">
                    {item.studentAccepted &&
                    Object.keys(item.studentAccepted).length > 0 ? (
                      <Link
                        to={`/admin/student/${item.studentAccepted.id}`}
                        className="flex flex-row items-center gap-2"
                      >
                        <Avatar
                          link={item.studentAccepted.image}
                          className="size-8 rounded-lg"
                        />
                        {item.studentAccepted.firstName}{" "}
                        {item.studentAccepted.lastName}
                      </Link>
                    ) : (
                      "Non défini"
                    )}
                  </td>
                  <td className="px-4 py-2 text-left">{item.total}&euro;</td>
                  <td className="px-4 py-2 text-left">
                    {item.startingType === "date" ? (
                      <Date format="DD/MM/YYYY" date={item.startingAt} />
                    ) : item.startingType === "asap" ? (
                      "Dès que possible"
                    ) : (
                      "Non défini"
                    )}
                  </td>
                  <td className="px-4 py-2 text-left">...</td>
                </tr>
              );
            })
          ) : (
            <td className="px-4 py-2 text-left">
              Aucune mission pour le moment
            </td>
          )}
        </tbody>
      </table>
      <div className="flex flex-row justify-between border-t p-4 text-sm">
        <div className="flex flex-row items-center gap-2">
          <Text>Afficher</Text>
          <input
            type="number"
            className="w-[80px] rounded-lg border p-2 focus:outline-blueViolet-600"
            value={tempLimit}
            min={10}
            max={100}
            onChange={handleInputChange}
          />
          <Text>missions sur {count}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => {
              setOffset(offset - limit);
              setPage(page - 1);
            }}
            disabled={offset === 0}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &lt;
          </button>
          {count &&
            [...Array(Math.ceil(count / limit))].map((_, i) => {
              const pageNumber = i + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => {
                    setOffset((pageNumber - 1) * limit);
                    setPage(pageNumber - 1);
                  }}
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                    pageNumber === Math.ceil(offset / limit) + 1
                      ? "bg-blueViolet-600 text-white"
                      : ""
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}
          <button
            onClick={() => {
              setOffset(offset + limit);
              setPage(page + 1);
            }}
            disabled={count === undefined ? true : offset + limit >= count}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableAdminJobs;
