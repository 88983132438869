// Requires
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

// Components
import { HorizontalDivider } from "../UI/Divider";
import Admin from "./Menu/Admin";
import CompagnyMenu from "./Menu/CompagnyMenu";
import StudentMenu from "./Menu/StudentMenu";
import Header from "./Header/Header";

// Utils
import { useUser } from "../../utils/store/userStore";
import { useMobile } from "../../utils/mobile/responsive";

// Context
import { useUI } from "../../utils/context/UI";

const Sidebar = () => {
  const user = useUser((state) => state.userdata);

  const { sidebarIsOpen, toggleSidebar, setSidebar } = useUI();

  const isMobile = useMobile();
  const minWidth = isMobile ? "0px" : "80px";
  const openedWidth = isMobile ? "100vw" : "300px";

  const menu = {
    student: <StudentMenu isOpen={sidebarIsOpen} />,
    company: <CompagnyMenu isOpen={sidebarIsOpen} />,
    admin: <Admin isOpen={sidebarIsOpen} />
  };
  const MenuComponent = menu[user?.roleName as keyof typeof menu];

  const location = useLocation();
  const mobile = useMobile();
  useEffect(() => void (mobile && setSidebar(false)), [location]);

  return (
    <motion.div
      initial={{
        ...(isMobile
          ? { width: 0, minWidth: 0 }
          : {
              width: sidebarIsOpen ? openedWidth : minWidth,
              minWidth: sidebarIsOpen ? openedWidth : minWidth
            })
      }}
      animate={{
        width: sidebarIsOpen ? openedWidth : minWidth,
        minWidth: sidebarIsOpen ? openedWidth : minWidth
      }}
      style={{ whiteSpace: "nowrap", overflow: "hidden" }}
      className={`flex h-screen max-md:h-full w-[${openedWidth}] max-w-[${openedWidth}] flex-col justify-between overflow-hidden border-r bg-white ${
        isMobile && "absolute z-50"
      }`}
    >
      <div className="flex h-full flex-col gap-4 p-6 md:gap-6">
        <Header sidebarIsOpen={sidebarIsOpen} toggleSidebar={toggleSidebar} />
        <HorizontalDivider />
        {MenuComponent}
      </div>
    </motion.div>
  );
};

export default Sidebar;
