// Icons
import { User } from "iconsax-react";

// Components
import Content from "../../Bloc/Content";
import { BlocTitle } from "../../Bloc/Title";
import Text from "../../UI/Text";
import Title from "../../UI/Title";

// Types
import { JobDetailsType } from "../../../types/job";

// Helpers
import { getDegreeName } from "../../../utils/helpers/getDegreeName";

const ProfilSought = ({ data }: { data: JobDetailsType }) => {
  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <User size={18} />
        <Title type="bloc">Profil recherché</Title>
      </BlocTitle>
      <Content>
        <div className="flex w-fit flex-row items-center gap-2 rounded-lg bg-blueViolet-50 p-4">
          <Text className="font-semibold">Niveau d'étude :</Text>
          <Text className="rounded-lg bg-white px-2 py-1 text-blueViolet-600">
            {getDegreeName(data.degree)}
          </Text>
        </div>
        <div className="flex w-fit flex-row items-center gap-2 rounded-lg bg-blueViolet-50 p-4">
          <Text className="font-semibold">Domaine d'étude :</Text>
          {data.categories?.map((item, _i) => (
            <Text
              key={_i}
              className="rounded-lg bg-white px-2 py-1 text-blueViolet-600"
            >
              {item.title}
            </Text>
          ))}
        </div>
        <div className="flex w-fit flex-row gap-2 rounded-lg bg-blueViolet-50 p-4">
          <Text className="text-nowrap font-semibold">Compétences :</Text>
          <div className="flex flex-row flex-wrap items-center gap-2">
            {data.skills?.map((item, _i) => (
              <Text
                key={_i}
                className="rounded-lg bg-white px-2 py-1 text-blueViolet-600"
              >
                {item.title}
              </Text>
            ))}
          </div>
        </div>
      </Content>
    </div>
  );
};

export default ProfilSought;
