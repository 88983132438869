// Requires
import { useEffect, useState } from "react";

// Components
import Content from "../../../../../../components/Bloc/Content";
import { BlocTitle } from "../../../../../../components/Bloc/Title";
import Experience from "../../../../../../components/Register/ExperienceCard";
import AddNew from "../../../../../../components/UI/AddNew";
import Button from "../../../../../../components/UI/Button";
import TagSelectable from "../../../../../../components/UI/TagSelectable";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Services
import { getSkills } from "../../../../../../services/Misc/misc";
import { updateExperience } from "../../../../../../services/User/user";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Type
import type { SkillType } from "../../../../../../types/misc";
import type {
  ExperienceType,
  ExperiencesType
} from "../../../../../../types/user";

// Helpers
import { BottomSheet } from "react-spring-bottom-sheet";
import NextPrevious from "../../../../../../components/NextPrevious";
import {
  getLocalStorage,
  removeFromLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";
import { Desktop, Mobile } from "../../../../../../utils/mobile/responsive";

type ErrorType = {
  title?: string;
  companyName?: string;
  description?: string;
  start?: string;
  end?: string;
  skills?: string;
  location?: string;
};

const Six = () => {
  const { step, handleNextStep } = useUI();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [experiences, setExperiences] = useState<ExperiencesType>({
    experiences: []
  });
  const [experience, setExperience] = useState<ExperienceType>({
    title: "",
    companyName: "",
    actual: false,
    description: "",
    start: "2024-07-30T08:57:41.067Z",
    end: "2024-07-30T08:57:41.067Z",
    skills: [],
    location: ""
  });

  const [skillsAvailable, setSkillsAvailable] = useState<SkillType[]>([]);
  const [filteredSkills, setFilteredSkills] = useState<SkillType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [isOnEdit, setIsOnEdit] = useState<number>();
  const [error, setError] = useState<ErrorType>({});

  // When input value change, update Formation State
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setExperience((prev) => ({ ...prev, [name]: value }));
  };

  // When checkbox value change, update Formation State
  const handleCheckboxChange = () => {
    setExperience((prev) => ({ ...prev, actual: !prev.actual }));
  };

  // Add formation to make a list of formations
  const addExperience = () => {
    const newError: ErrorType = {};

    if (!experience.title || experience.title.length < 5) {
      newError.title =
        "Tu dois indiquer le titre de ta formation (minimum 5 caractères)";
    }

    if (!experience.companyName || experience.companyName.length < 3) {
      newError.companyName =
        "Tu dois indiquer le nom de l'établissement de formation (minimum 3 caractères)";
    }

    if (!experience.start) {
      newError.start = "Tu dois indiquer la date de début";
    }

    if (!experience.description || experience.description.length < 50) {
      newError.description =
        "Une description de ta formation est obligatoire (minimum 50 caractères)";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return; // Stop the function if there are errors
    }

    if (typeof isOnEdit === "number") {
      // Mise à jour d'une expérience existante
      setExperiences((prev) => {
        const updatedExperiences = [...prev.experiences];
        updatedExperiences[isOnEdit] = experience;
        return { experiences: updatedExperiences };
      });
    } else {
      // Ajout d'une nouvelle expérience
      setExperiences((prev) => ({
        experiences: [...(prev?.experiences || []), experience]
      }));
    }

    setExperience({
      title: "",
      companyName: "",
      actual: false,
      description: "",
      start: "2024-07-30T08:57:41.067Z",
      end: "2024-07-30T08:57:41.067Z",
      skills: [],
      location: ""
    });
    setIsVisible(false);
    setError({});
  };

  // Update formation
  const editFormation = (index: number) => {
    setError({});
    setExperience({
      title: experiences.experiences[index].title,
      actual: experiences.experiences[index].actual,
      companyName: experiences.experiences[index].companyName,
      description: experiences.experiences[index].description,
      start: experiences.experiences[index].start,
      end: experiences.experiences[index].end,
      location: experiences.experiences[index].location,
      skills: experiences.experiences[index].skills
    });
    setIsVisible(true);
    setIsOnEdit(index);
  };

  // Delete formation
  const handleDelete = (index: number) => {
    setExperiences((prev) => ({
      experiences: [...prev.experiences.filter((_, i) => i !== index)]
    }));
  };

  // Search in skills list
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    const filtered = skillsAvailable.filter(
      (skill) =>
        skill.title.toLowerCase().includes(e.target.value.toLowerCase()) &&
        !experience?.skills?.some((s) => s.id === skill.id)
    );
    setFilteredSkills(filtered);
  };

  // Select a skill
  const handleSkillSelect = (skillId: number) => {
    const skill = skillsAvailable.find((s) => s.id === skillId);
    if (skill) {
      setExperience((prev) => ({
        ...prev,
        skills: (prev.skills || []).some((s) => s.id === skillId)
          ? prev?.skills?.filter((s) => s.id !== skillId)
          : [...(prev.skills || []), { id: skillId, title: skill.title }]
      }));
    }
    setSearchTerm(""); // Reset the search term
  };

  // Type guard to ensure the transformed object conforms to FormationsType
  const isExperiencesType = (data: any): data is ExperiencesType => {
    return data.experiences.every((experience: any) => {
      return (
        Array.isArray(experience.skills) &&
        experience.skills.every((skill: any) => typeof skill === "number")
      );
    });
  };

  // Go to next step
  const nextStep = async () => {
    setIsLoading(true);

    // Transform formations to conform to API requirements
    const transformedExperiences = {
      experiences: experiences.experiences.map((experience) => ({
        ...experience,
        skills: experience.skills?.map((skill) => skill.id) // Only keep the id for the API call
      }))
    };

    // Ensure transformedFormations matches the type expected by updateFormation
    if (isExperiencesType(transformedExperiences)) {
      const response = await updateExperience(transformedExperiences);
      if (response) {
        const localData = [{ key: "studentStep", value: "7" }];
        setLocalStorage(localData);
        removeFromLocalStorage("experiences");
        handleNextStep();
      }
    } else {
      console.error("Transformed formations do not match the expected type.");
    }

    setIsLoading(false);
  };

  // Data fetch
  useEffect(() => {
    const fetch = async () => {
      const response = await getSkills("");
      if (response) {
        setSkillsAvailable(response);
        setFilteredSkills(response);
      }
    };
    fetch();

    const data = getLocalStorage("experiences");
    if (data) {
      const parse = JSON.parse(data);
      for (const item of parse) {
        // Utilisation de for...of à la place de map
        const test: ExperienceType = {
          title: item.title,
          companyName: item.companyName,
          description: item.description,
          location: item.location,
          start: item.start,
          end: item.end,
          skills: item.skills,
          actual: false
        };

        setExperiences((prev) => ({
          experiences: [...(prev?.experiences || []), test]
        }));
      }
    }
  }, []);

  // Update skill search container
  useEffect(() => {
    const filtered = skillsAvailable.filter(
      (skill) =>
        skill.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !experience?.skills?.some((s) => s.id === skill.id)
    );
    setFilteredSkills(filtered);
  }, [searchTerm, experience.skills, skillsAvailable]);

  const NewFormation = (
    <div className="flex flex-col gap-8 rounded-lg p-4 md:border">
      <BlocTitle>
        <Title type="bloc">Nouvelle expérience</Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-col items-start gap-6">
          <div className="flex w-full flex-col gap-2">
            <Text className="font-semibold">
              Titre<span style={{ color: "red" }}> * </span>
            </Text>
            <input
              name="title"
              value={experience.title}
              onChange={handleInputChange}
              type="text"
              placeholder="Développeur Web, Graphiste, Community Mananger..."
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${error.title ? "border-red-500" : "border"}`}
            />
            {error.title && (
              <Text className="text-sm text-red-500">{error.title}</Text>
            )}
          </div>
          <div className="flex w-full flex-col gap-2">
            <Text className="font-semibold">
              Nom de l'entreprise<span style={{ color: "red" }}> * </span>
            </Text>
            <input
              name="companyName"
              value={experience.companyName}
              onChange={handleInputChange}
              type="text"
              placeholder="Google, Facebook..."
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${error.companyName ? "border-red-500" : "border"}`}
            />
            {error.companyName && (
              <Text className="text-sm text-red-500">{error.companyName}</Text>
            )}
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="items items ite flex flex-row">
              <Text className="font-semibold">Lieu</Text>
            </div>
            <input
              name="location"
              value={experience.location}
              onChange={handleInputChange}
              type="text"
              placeholder="Paris, Lille, Nantes..."
              className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
            />
            {error.location && (
              <Text className="text-sm text-red-500">{error.location}</Text>
            )}
          </div>
          <div className="flex flex-row gap-8">
            <div className="flex w-full flex-col gap-2">
              <Text className="font-semibold">Date de début</Text>
              <input
                name="start"
                value={experience.start}
                onChange={handleInputChange}
                type="date"
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
            </div>
            <div className="flex w-full flex-col gap-2">
              <Text className="font-semibold">Date de fin</Text>
              <input
                name="end"
                value={experience.end}
                onChange={handleInputChange}
                type="date"
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
            </div>
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <input
                checked={experience.actual}
                onChange={handleCheckboxChange}
                type="checkbox"
                className="w-fit focus:outline-blueViolet-600"
              />
              <Text>J'effectue en ce moment cette formation</Text>
            </div>
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-end gap-1">
                <Text className="font-semibold">Compétences</Text>
                <Text className="text-sm">- Facultatif</Text>
              </div>
              <Text className="text-xs">
                Indiques jusqu'à 5 compétences sur ce poste
              </Text>
            </div>
            <div className="relative flex flex-col gap-2">
              <input
                type="text"
                placeholder="Recherche une compétences..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
              {searchTerm && filteredSkills.length > 0 && (
                <div className="max-h-[150px] w-full overflow-scroll rounded-lg border bg-white shadow-lg">
                  {filteredSkills.map((skill) => (
                    <div
                      key={skill.id}
                      className="cursor-pointer p-2 hover:bg-gray-100"
                      onClick={() => handleSkillSelect(skill.id)}
                    >
                      <TagSelectable isActiveState={false}>
                        {skill.title}
                      </TagSelectable>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-2 flex flex-wrap gap-2">
              {experience?.skills?.map((skill) => (
                <div onClick={() => handleSkillSelect(skill.id)} key={skill.id}>
                  <TagSelectable isActiveState>{skill.title}</TagSelectable>
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full flex-col gap-2">
            <Text className="font-semibold">
              Description<span style={{ color: "red" }}> * </span>
            </Text>
            <textarea
              name="description"
              value={experience.description}
              onChange={handleInputChange}
              placeholder="Tu peux décrire ton expérience plus en détails..."
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${error.description ? "border-red-500" : "border"}`}
            />
            <div className="flex flex-row items-end justify-between">
              <Text className="text-sm text-red-500">{error.description}</Text>
              <Text className="text-sm">
                {experience.description?.length}/200 caractères
              </Text>
            </div>
          </div>
          <div className="flex w-full flex-row justify-end gap-4">
            <div onClick={() => setIsVisible(false)}>
              <Button type="cancel">Annuler</Button>
            </div>
            <div onClick={addExperience}>
              <Button type="full">
                {typeof isOnEdit === "number" ? "Modifier" : "Ajouter"}
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );

  return (
    <div className="flex w-full items-center justify-center px-4 md:py-8">
      <div className="flex min-w-[50%] flex-1 grow flex-col items-center gap-12 md:max-w-[50%]">
        <div className="flex w-full flex-col gap-8">
          <div className="cursor-pointer" onClick={() => setIsVisible(true)}>
            <AddNew type="expériences" />
          </div>
          <Mobile>
            <BottomSheet
              open={isVisible}
              onDismiss={() => {
                setIsVisible(false);
              }}
              snapPoints={({ maxHeight }) => [(maxHeight * 9) / 10]}
            >
              {NewFormation}
            </BottomSheet>
          </Mobile>
          <Desktop>{isVisible && NewFormation}</Desktop>
          {experiences.experiences.length > 0 &&
            experiences.experiences.map((item, index) => {
              return (
                <Experience
                  key={index}
                  index={index}
                  data={item}
                  onDelete={handleDelete}
                  onEdit={editFormation}
                />
              );
            })}
          <NextPrevious isLoading={isLoading} step={step} nextStep={nextStep} />
        </div>
      </div>
    </div>
  );
};

export default Six;
