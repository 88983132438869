// Requires
import { useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

// Components
import Title from "../../../../../../components/UI/Title";
import NextPrevious from "../../../../../../components/NextPrevious";

// Context & Utils
import { useUI } from "../../../../../../utils/context/UI";
import { useMobile } from "../../../../../../utils/mobile/responsive";

// Helpers
import {
  getLocalStorage,
  removeFromLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

const Twelve = () => {
  const { step, handleNextStep } = useUI();

  const [callIsScheduled, setCallIsScheduled] = useState<boolean>(false);

  const [data, setData] = useState<{
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber: string;
    phoneNumberId: string;
  }>({
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: "",
    phoneNumberId: ""
  });

  const isMobile = useMobile();

  const nextStep = () => {
    const localData = [{ key: "studentStep", value: "12" }];
    setLocalStorage(localData);
    removeFromLocalStorage("calendlyData");
    handleNextStep();
  };

  useCalendlyEventListener({
    onEventScheduled: () => setCallIsScheduled(true)
  });

  useEffect(() => {
    const calendlyData = getLocalStorage("calendlyData");
    if (calendlyData) {
      const formatedData = JSON.parse(calendlyData);
      setData({
        lastName: "",
        firstName: `${formatedData.firstName} ${formatedData.lastName}`,
        email: formatedData.email,
        phoneNumber: formatedData.phoneNumber,
        phoneNumberId: formatedData.phoneNumberId
      });
    }
  }, []);

  return (
    <div className="w-full md:p-16">
      <div className="flex items-center justify-center">
        <div className="flex w-full flex-col justify-center gap-16 md:gap-0">
          <div className="flex items-center justify-center">
            <Title type="page">
              Finalise ton inscription avec notre Talent Finder
            </Title>
          </div>
          <div className="h-[850px] w-full overflow-hidden md:h-auto">
            <InlineWidget
              pageSettings={{
                backgroundColor: "ffffff",
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: "00a2ff",
                textColor: "4d5055"
              }}
              prefill={{
                name: data.firstName,
                email: data.email,
                location: data.phoneNumberId + data.phoneNumber
              }}
              styles={
                isMobile
                  ? { width: "100%", height: "850px" }
                  : { width: "100%", height: "700px" }
              }
              url="https://calendly.com/d/cnfp-why-xfd/visio-de-bienvenue-decouvre-juwa"
            />
          </div>
          <NextPrevious
            step={step}
            nextStep={nextStep}
            isLoading={false}
            disableNext={!callIsScheduled}
          />
        </div>
      </div>
    </div>
  );
};

export default Twelve;
