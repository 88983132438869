// Icons
import { ChartSquare } from "iconsax-react";

// Components
import Title from "../../../UI/Title";
import Text from "../../../UI/Text";

const CA12Month = () => {
  // TODO: Connect backend to get CA since 12 months

  return (
    <div className="flex flex-col gap-4 rounded-lg border bg-white p-4 shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <ChartSquare size={18} />
        <Title type="bloc">Chiffre d'affaires (12 derniers mois)</Title>
      </div>
      <div className="flex items-center justify-center">
        <Text>Aucunes données pour le moment</Text>
      </div>
    </div>
  );
};

export default CA12Month;
