// Requires
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

// Components
import Card from "../../../../components/MyPlan/Card";
import CardLite from "../../../../components/MyPlan/CardLite";
import { PlanTable } from "../../../../components/MyPlan/Table";
import Text from "../../../../components/UI/Text";
import Title from "../../../../components/UI/Title";
import Button from "../../../../components/UI/Button";

// Utils
import { Desktop, Mobile } from "../../../../utils/mobile/responsive";
import { useUser } from "../../../../utils/store/userStore";

const MyPlan = () => {
  const user = useUser((state) => state.userdata);

  const [facturationMode, setFacturationMode] = useState<number>(
    user?.subscription?.frequency === "MONTHLY"
      ? 1
      : user?.subscription?.frequency === "QUARTERLY"
        ? 3
        : user?.subscription?.frequency === "YEARLY"
          ? 12
          : 1
  );
  const [actualPlan, setActualPlan] = useState<
    "STANDARD" | "STARTER" | "BUSINESS" | "BUSINESSPLUS"
  >(user?.subscription?.level ?? "STANDARD");

  const [isCancelled, setIsCancelled] = useState<boolean>(
    user?.subscription?.hasValidSubscription ? true : false
  );

  const _FACTURATION_MODE: { label: string; labelNumber: number }[] = [
    { label: "Facturation mensuelle", labelNumber: 1 },
    { label: "Facturation trimestrielle", labelNumber: 3 },
    { label: "Facturation annuelle", labelNumber: 12 }
  ];

  const _PRICING = {
    Starter: {
      monthly: 99,
      quarterly: 79,
      annualy: 69
    },
    Business: {
      monthly: 179,
      quarterly: 149,
      annualy: 129
    }
  };

  return (
    <div className="flex flex-col gap-7 md:gap-24">
      <Title type="page">
        Recrutez des compétences spécifiques à votre façon
      </Title>
      <div className="flex flex-col gap-5 md:gap-24">
        <div className="flex items-center justify-center">
          <div className="flex flex-row items-center gap-2 rounded-lg bg-blueViolet-600 px-3 py-2">
            {_FACTURATION_MODE.map((mode, _i) => (
              <div
                key={_i}
                style={{
                  backgroundColor:
                    facturationMode === mode.labelNumber
                      ? "white"
                      : "transparent"
                }}
                className="cursor-pointer rounded-lg px-2 py-1 text-white hover:bg-white hover:text-black"
                onClick={() => setFacturationMode(mode.labelNumber)}
              >
                <Text
                  className={`${facturationMode === mode.labelNumber ? "text-black" : "text-white"}`}
                >
                  {mode.label}
                </Text>
              </div>
            ))}
          </div>
        </div>
        <Mobile>
          <Carousel
            showStatus={false}
            showIndicators
            showArrows={false}
            renderIndicator={(clickHandle, isSelected) => {
              return (
                <li
                  onClick={clickHandle}
                  className={`${isSelected ? "bg-blueViolet-600" : "bg-blueViolet-200"} m-1 inline-block h-2 w-2 rounded-full`}
                />
              );
            }}
          >
            <Card
              name="Standard"
              formatedName="Standard"
              commission={20}
              price={0 * facturationMode}
              options={["Chat, Appel et visios sécurisés"]}
              isActive={false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <Card
              name="Starter"
              formatedName="Starter"
              commission={5}
              price={49 * facturationMode}
              options={["Chat, Appel et visios sécurisés"]}
              lastPlan="Standard"
              isActive={false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <Card
              name="Business"
              formatedName="Business"
              commission={5}
              price={99 * facturationMode}
              options={["Chat, Appel et visios sécurisés"]}
              lastPlan="Starter"
              isActive={true}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <Card
              name="Business+"
              formatedName="Business_plus"
              commission={0}
              price={299 * facturationMode}
              options={["Chat, Appel et visios sécurisés"]}
              lastPlan="Business"
              isActive={false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
          </Carousel>
        </Mobile>
        <Desktop>
          <div className="flex flex-row flex-wrap gap-6">
            <Card
              name="Standard"
              formatedName="Standard"
              commission={20}
              price={0 * facturationMode}
              options={["Chat, Appel et visios sécurisés"]}
              isActive={actualPlan === "STANDARD" ? true : false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <Card
              name="Starter"
              formatedName="Starter"
              commission={5}
              price={
                _PRICING["Starter"][
                  facturationMode === 1
                    ? "monthly"
                    : facturationMode === 3
                      ? "quarterly"
                      : "annualy"
                ] * facturationMode
              }
              options={["Chat, Appel et visios sécurisés"]}
              lastPlan="Standard"
              isActive={actualPlan === "STARTER" ? true : false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <Card
              name="Business"
              formatedName="Business"
              commission={5}
              price={
                _PRICING["Business"][
                  facturationMode === 1
                    ? "monthly"
                    : facturationMode === 3
                      ? "quarterly"
                      : "annualy"
                ] * facturationMode
              }
              options={["Chat, Appel et visios sécurisés"]}
              lastPlan="Starter"
              isActive={actualPlan === "BUSINESS" ? true : false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <Card
              name="Business+"
              formatedName="Business_plus"
              commission={5}
              price={299 * facturationMode}
              options={["Chat, Appel et visios sécurisés"]}
              lastPlan="Business"
              isActive={actualPlan === "BUSINESSPLUS" ? true : false}
              facturationMode={facturationMode}
              isCustom={true}
              isCancelled={isCancelled}
            />
          </div>
        </Desktop>
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-6 rounded-lg border border-blueViolet-200 bg-blueViolet-50 p-8 shadow-lg">
        <Title type="custom" classname="text-xl">
          Offre sur-mesure
        </Title>
        <Text className="!text-3xl">
          Déchargez vous du recrutement et obtenez les meilleurs profils pour
          vos besoin
        </Text>
        <Link to="https://juwa.co/contact">
          <Button type="full">Construisons votre plan ensemble</Button>
        </Link>
      </div>
      <div className="relative flex flex-col gap-4">
        <Desktop>
          <div className="flex flex-row flex-wrap gap-4">
            <div className="flex w-[calc(20%-13px)] flex-col rounded-lg md:p-4" />
            <CardLite
              name="Standard"
              formattedName="Standard"
              price={0 * facturationMode}
              isActive={actualPlan === "STANDARD" ? true : false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <CardLite
              name="Starter"
              formattedName="Starter"
              price={
                _PRICING["Starter"][
                  facturationMode === 1
                    ? "monthly"
                    : facturationMode === 3
                      ? "quarterly"
                      : "annualy"
                ] * facturationMode
              }
              isActive={actualPlan === "STARTER" ? true : false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <CardLite
              name="Business"
              formattedName="Business"
              price={
                _PRICING["Business"][
                  facturationMode === 1
                    ? "monthly"
                    : facturationMode === 3
                      ? "quarterly"
                      : "annualy"
                ] * facturationMode
              }
              isActive={actualPlan === "BUSINESS" ? true : false}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
            <CardLite
              formattedName="Business_plus"
              name="Business+"
              price={29 * facturationMode}
              isActive={actualPlan === "BUSINESSPLUS" ? true : false}
              isCustom={true}
              facturationMode={facturationMode}
              isCancelled={isCancelled}
            />
          </div>
        </Desktop>
        <PlanTable />
      </div>
    </div>
  );
};

export default MyPlan;
