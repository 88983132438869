// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import NextPrevious from "../../../../../../components/NextPrevious";

// Services
import {
  resendEmailConfirmation,
  updateEmail,
  validateUserEmail
} from "../../../../../../services/User/user";

// Types
import type { ValidateUserEmail } from "../../../../../../types/user";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";
import { validateEmail } from "../../../../../../utils/helpers/validators";

const Four = () => {
  const { step, handleNextStep } = useUI();

  const [email, setEmail] = useState<string>();
  const [newEmail, setNewEmail] = useState<string>();
  const [code, setCode] = useState<string>();

  const [newEmailErrror, setNewEmailError] = useState<string>();
  const [error, setError] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [success, setSuccess] = useState<string>();

  useEffect(() => {
    const email = getLocalStorage("email");
    if (email) setEmail(email);
  }, []);

  const resendEmail = async () => {
    const response = await resendEmailConfirmation();
    if (response) {
      setSuccess(
        `Un nouveau code à était envoyé sur l'adresse email suivante : ${email}`
      );
      return true;
    }
  };

  const changeEmail = async () => {
    setSuccess("");
    if (newEmail && validateEmail(newEmail) && email !== newEmail) {
      const data = {
        email: newEmail
      };
      const response = await updateEmail(data);
      if (response) {
        setNewEmailError("");
        setSuccess(
          `Un nouveau code à était envoyé sur l'adresse email suivante : ${newEmail}`
        );
        setEmail(newEmail);
        setIsVisible(false);
        return;
      }
    } else {
      setNewEmailError(
        "Le format de l'adresse email n'est pas valide ou est identique à la précédente"
      );
    }
  };

  const nextStep = async () => {
    setIsLoading(true);

    if (code === undefined || !code) {
      setIsLoading(false);
      setError("Tu dois entrer le code de validation");
      return;
    }

    if (code !== undefined) {
      const data: ValidateUserEmail = {
        code: code ? code : ""
      };

      const response = await validateUserEmail(data);
      if (response) {
        const localData = [{ key: "studentStep", value: "4" }];
        setLocalStorage(localData);
        return handleNextStep();
      }

      setError("Le code de validation n'est pas valide");
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-w-[50%] flex-col md:max-w-[50%]">
      <div className="flex flex-col gap-12">
        <div className="flex items-center justify-center">
          <Title type="page">Vérifiez votre adresse email</Title>
        </div>
        <div className="flex flex-col gap-2 rounded-lg bg-whiteSimple p-4">
          <Text>
            Nous avons envoyé un code de confirmation à six caractères à
            l’adresse mail suivante :{" "}
            <span className="font-semibold text-blueViolet-600">{email}</span>{" "}
            afin que nous puissions vérifier que c’est bien la votre. Ce code
            est valable pendant une heure.
          </Text>
        </div>
        <div className="flex flex-col gap-4">
          {success && (
            <Text className="text-sm text-blueViolet-600">{success}</Text>
          )}
          <Text className="text-xl font-semibold">
            Saisissez le code de confirmation
            <span style={{ color: "red" }}> * </span>
          </Text>
          <div className="flex flex-col gap-1">
            <input
              onChange={(e) => setCode(e.currentTarget.value)}
              type="text"
              placeholder="XYX-Z54"
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${
                error ? "border-red-500" : "border"
              }`}
            />
            {error && <Text className="text-sm text-red-500">{error}</Text>}
          </div>
          <div className="flex flex-col md:flex-row md:gap-2">
            <Text>Vous n'avez pas reçu le code ?</Text>
            <div className="flex flex-row flex-wrap gap-1">
              {" "}
              <Link
                to={"#"}
                onClick={resendEmail}
                className="text-nowrap text-blueViolet-600 underline"
              >
                Recevoir un nouveau
              </Link>
              <Text>ou</Text>
              <Link
                onClick={() => setIsVisible(true)}
                to={"#"}
                className="text-blueViolet-600 underline"
              >
                Modifier l'adresse email
              </Link>
            </div>
          </div>
          <AnimatePresence>
            {isVisible && (
              <motion.div
                key="visiblity"
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 5 }}
                className="flex flex-row items-center gap-2"
              >
                <input
                  onChange={(e) => setNewEmail(e.currentTarget.value)}
                  type="text"
                  placeholder="Nouvelle adresse email"
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                    newEmailErrror ? "border-red-500" : "border"
                  }`}
                />
                <div onClick={changeEmail}>
                  <Button type="full">Modifier</Button>
                </div>
                <div onClick={() => setIsVisible(false)}>
                  <Button type="cancel">Annuler</Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          {newEmailErrror && (
            <Text className="text-sm text-red-500">{newEmailErrror}</Text>
          )}
        </div>
        <NextPrevious step={step} nextStep={nextStep} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Four;
