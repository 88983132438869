// Components
import Content from "../../../../Bloc/Content";
import { BlocTitle } from "../../../../Bloc/Title";
import Button from "../../../../UI/Button";
import Title from "../../../../UI/Title";
import Text from "../../../../UI/Text";
import SkillsSelector from "../../../../Misc/SkillsSelector";

// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

// Helpers
import { formatDate } from "../../../../../utils/helpers/formatDate";

//Types
import { ExperienceType } from "../../../../../types/user";
import { SkillType } from "../../../../../types/misc";

type Error = {
  title?: string;
  companyName?: string;
  location?: string;
  start?: string;
  end?: string;
  actual?: string;
  skills?: string;
  description?: string;
};

const Form = ({
  callback,
  initialData,
  cancel
}: {
  callback: (data: ExperienceType) => void;
  initialData?: ExperienceType;
  cancel: () => void;
}) => {
  const [data, setData] = useState<ExperienceType>({
    actual: initialData?.actual || false,
    companyName: initialData?.companyName || "",
    description: initialData?.description || "",
    location: initialData?.location || "",
    title: initialData?.title || "",
    skills: initialData?.skills || [],
    start: formatDate(initialData?.start) || "",
    end: formatDate(initialData?.end) || ""
  });

  const [error, setError] = useState<Error>();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;

    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSkillChange = (data: SkillType[]) => {
    setData((prev) => ({
      ...prev,
      skills: [...data]
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;

    setData((prev) => ({
      ...prev,
      actual: checked
    }));
  };

  const AddOrUpdate = () => {
    const newError: Error = {};
    setError(newError);

    if (!data.title) {
      newError.title = "Tu dois indiquer le titre de l'expérience";
    }

    if (!data.companyName) {
      newError.companyName = "Tu dois indiqué le nom de l'entreprise";
    }

    if (!data.description || data.description.length < 50) {
      newError.description =
        "Tu dois décrire ton expérience avec un minimum de 50 caractères";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    callback(data);
    setData({
      title: "",
      schoolName: "",
      location: "",
      start: "",
      end: "",
      actual: false,
      skills: [],
      description: ""
    });
    setError(newError);
  };

  return (
    <AnimatePresence key={"ani"}>
      <motion.div
        key={"animated"}
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 5 }}
        className="flex flex-col gap-8 rounded-lg border p-4"
      >
        <BlocTitle>
          <Title type="bloc">Nouvelle expérience</Title>
        </BlocTitle>
        <Content>
          <div className="flex flex-col items-start gap-6">
            <div className="flex w-full flex-col gap-2">
              <Text className="font-semibold">
                Titre<span style={{ color: "red" }}> * </span>
              </Text>
              <input
                name="title"
                value={data.title}
                onChange={handleChange}
                type="text"
                placeholder="Développeur Web, Graphiste, Community Mananger..."
                className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600`}
              />
              {error?.title && (
                <Text className="text-sm text-red-500">{error.title}</Text>
              )}
            </div>
            <div className="flex w-full flex-col gap-2">
              <Text className="font-semibold">
                Nom de l'entreprise<span style={{ color: "red" }}> * </span>
              </Text>
              <input
                name="companyName"
                value={data.companyName}
                onChange={handleChange}
                type="text"
                placeholder="OpenClassRoom, Study, Le Wagon..."
                className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600`}
              />
              {error?.companyName && (
                <Text className="text-sm text-red-500">
                  {error.companyName}
                </Text>
              )}
            </div>
            <div className="flex w-full flex-col gap-2">
              <div className="items items ite flex flex-row">
                <Text className="font-semibold">Lieu</Text>
              </div>
              <input
                name="location"
                value={data.location}
                onChange={handleChange}
                type="text"
                placeholder="Paris, Lille, Nantes..."
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
              {error?.location && (
                <Text className="text-sm text-red-500">{error.location}</Text>
              )}
            </div>
            <div className="flex flex-row gap-8">
              <div className="flex w-full flex-col gap-2">
                <Text className="font-semibold">Date de début</Text>
                <input
                  name="start"
                  value={data.start}
                  onChange={handleChange}
                  type="date"
                  className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
                />
              </div>
              <div className="flex w-full flex-col gap-2">
                <Text className="font-semibold">Date de fin</Text>
                <input
                  name="end"
                  value={data.end}
                  onChange={handleChange}
                  type="date"
                  className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
                />
              </div>
            </div>
            <div className="flex w-full flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                <input
                  checked={data.actual}
                  onChange={handleCheckboxChange}
                  type="checkbox"
                  className="w-fit focus:outline-blueViolet-600"
                />
                <Text>Je suis actuellement dans cette entreprise</Text>
              </div>
            </div>
            <div className="flex w-full flex-col gap-2">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row items-end gap-1">
                  <Text className="font-semibold">Compétences</Text>
                  <Text className="text-sm">- Facultatif</Text>
                </div>
                <Text className="text-xs">
                  Indiques jusqu'à 5 compétences sur ce poste
                </Text>
              </div>
              <div className="relative flex flex-col gap-2">
                <SkillsSelector
                  callback={handleSkillChange}
                  initialData={data.skills ?? undefined}
                />
              </div>
            </div>
            <div className="flex w-full flex-col gap-2">
              <Text className="font-semibold">
                Description<span style={{ color: "red" }}> * </span>
              </Text>
              <textarea
                name="description"
                value={data.description}
                onChange={handleChange}
                placeholder="Tu peux décrire ton expérience plus en détails..."
                className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600`}
              />
              <div className="flex flex-row items-end justify-between">
                <Text className="text-sm text-red-500">
                  {error?.description}
                </Text>
                <Text className="text-sm">
                  {data.description?.length}/200 caractères
                </Text>
              </div>
            </div>
            <div className="flex w-full flex-row justify-end gap-4">
              <div onClick={cancel}>
                <Button type="cancel">Annuler</Button>
              </div>
              <div onClick={AddOrUpdate}>
                <Button type="full">
                  {initialData ? "Modifier" : "Ajouter"}
                </Button>
              </div>
            </div>
          </div>
        </Content>
      </motion.div>
    </AnimatePresence>
  );
};

export default Form;
