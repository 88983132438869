// Require
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Routers
import { authRouters } from "./Router/auth";
import { commonRouters } from "./Router/common";
import { publicRouters } from "./Router/public";
import { studentRouters } from "./Router/student";
import { CompanyRouters } from "./Router/company";

// Pages
import NotFound from "./pages/Error/NotFound";
import { adminRouters } from "./Router/admin";
import Unauthorized from "./pages/Error/Unauthorized";

const router = createBrowserRouter([
  authRouters,
  commonRouters,
  CompanyRouters,
  studentRouters,
  publicRouters,
  adminRouters,
  {
    path: "*",
    element: <NotFound />
  },
  { path: "unauthorized", element: <Unauthorized /> }
]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
