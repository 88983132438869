// Requires
import { useRef, useState } from "react";

// Stores
import { useUser } from "../../utils/store/userStore";

// Components
import Text from "../UI/Text";

// Icons
import { Additem } from "iconsax-react";

const AvatarChanger = ({
  callback,
  companyMode
}: {
  callback: (image: File) => void;
  companyMode?: boolean;
}) => {
  const user = useUser((state) => state.userdata);

  const [imagePreview, setImagePreview] = useState<string>(
    companyMode ? (user?.company?.image ?? "") : (user?.image ?? "")
  );
  const [imageFile, setImageFile] = useState<File>();
  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.size > 20000000) {
      return alert("Maximum 2mo");
    }
    if (file && file.size < 20000000) {
      setImageFile(file);
      callback(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDiv = () => {
    imageInputRef.current?.click();
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <Text className="font-semibold">Photo de profil</Text>
        <Text className="text-sm">
          Nous te conseillons d'ajouter une photo professionnelle
        </Text>
      </div>
      <div className="relative flex h-24 w-24 items-center justify-center rounded-lg border border-dotted bg-blueViolet-50">
        {imagePreview && (
          <img
            src={imagePreview}
            className="h-24 w-24 rounded-lg object-cover object-center"
          />
        )}
        <div
          onClick={handleImageDiv}
          className="absolute bottom-auto left-auto right-auto top-auto z-40 cursor-pointer rounded-lg bg-blueViolet-200 bg-opacity-70 p-2"
        >
          <Additem size={18} />
        </div>
        <input
          onChange={handleFileChange}
          type="file"
          className="hidden"
          ref={imageInputRef}
          accept="image/png, image/jpg, image/jpeg"
        />
      </div>
    </div>
  );
};

export default AvatarChanger;
