// TODO: Lazy Loading

// Requires
import { useState } from "react";
import { Link } from "react-router-dom";

// Icons
import { Building4, Calendar, Lovely, Location, Timer } from "iconsax-react";

// Components
import Button from "../../UI/Button";
import Date from "../../UI/Date";
import Text from "../../UI/Text";

// Services
import { addOrRemoveLikedJob } from "../../../services/Job/job";

// Types
import type { JobType } from "../../../types/job";
import { capitalizeFirstLetter } from "../../../utils/helpers/capitalizeFirstLetter";

const JobCardExtra = ({ item }: { item: JobType }) => {
  // State to track if the job is favorited
  const [isFavorite, setIsFavorite] = useState(item.favorite || false);

  // Handler for clicking the Lovely icon
  const handleFavoriteClick = async () => {
    if (!isFavorite) {
      const response = await addOrRemoveLikedJob(item.id, "add");
      if (response) {
        setIsFavorite(true);
        return;
      }
    } else {
      const response = await addOrRemoveLikedJob(item.id, "remove");
      if (!response.error) {
        setIsFavorite(false);
        return;
      }
    }
  };

  return (
    <div className="flex w-full flex-col gap-8 rounded-lg border p-4 shadow-lg max-md:gap-4">
      <Text className="text-xl font-semibold">{item.title}</Text>
      <div className="flex flex-row items-center gap-8 max-md:justify-between max-md:px-4">
        <div className="flex flex-col gap-1">
          <Text className="text-sm">Rémunération</Text>
          <Text className="font-semibold">{item.total ?? "1000"}&euro;</Text>
        </div>
        <div className="flex flex-row items-center gap-1">
          <Timer size={18} />
          <Text className="font-semibold">{item.duration}h</Text>
        </div>
      </div>

      <div
        className="w-full overflow-hidden text-ellipsis whitespace-normal break-words bg-white"
        dangerouslySetInnerHTML={{ __html: item?.context || "" }}
      />
      <div className="flex flex-row items-center gap-6 max-md:flex-wrap">
        <div className="flex flex-row items-center gap-1">
          <Building4 size={18} />
          <Text>{item.company?.displayName ?? "company name"}</Text>
        </div>
        <div className="flex flex-row items-center gap-1">
          <Location size={18} />
          <Text>
            {item.workMode === "remote"
              ? "Distanciel"
              : `${capitalizeFirstLetter(item.company.city)} ${item.workMode === "hybrid" ? "et télétravail" : ""}`}
          </Text>
        </div>
        <div className="flex flex-row items-center gap-1">
          <Calendar size={18} />
          {item.startingType === "date" ? (
            <Date date={item.startingAt} format="DD/MM/YYYY" />
          ) : item.startingType === "asap" ? (
            "Dès que possible"
          ) : (
            "Non spécifié"
          )}
        </div>
      </div>
      <div className="flex flex-row items-center gap-4">
        <Link to={`/student/jobs/${item.id.toString()}`}>
          <Button type="full">Voir la mission</Button>
        </Link>
        <Lovely
          className="cursor-pointer"
          onClick={handleFavoriteClick}
          size={18}
          variant={isFavorite ? "Bold" : "Linear"}
          color="#FF5B5B"
        />
      </div>
    </div>
  );
};

export default JobCardExtra;
