import Email from "./PasswordSecurity/Email";
import Password from "./PasswordSecurity/Password";

const PasswordSecurity = () => {
  return (
    <>
      <Email />
      <Password />
    </>
  );
};

export default PasswordSecurity;
