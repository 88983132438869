// Requires
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// Components
import Text from "../UI/Text";
import Tag from "../UI/Tag";

// Services
import { getSkills } from "../../services/Misc/misc";

// Types
import { SkillType } from "../../types/misc";

const SkillsSelector = ({
  callback,
  initialData
}: {
  callback: (skills: SkillType[]) => void;
  initialData?: SkillType[] | undefined;
}) => {
  const [skillsList, setSkillsList] = useState<SkillType[]>();
  const [skillsSelected, setSkillsSelected] = useState<SkillType[]>(
    initialData ?? []
  );

  const [error, setError] = useState<string>();

  const [query, setQuery] = useState<string>();

  // Load and save a list of skills
  useEffect(() => {
    const fetch = async () => {
      const response = await getSkills();
      setSkillsList(response);
    };

    fetch();
  }, []);

  // Callback
  useEffect(() => {
    if (skillsSelected.length > 0) {
      callback(skillsSelected);
    }
  }, [skillsSelected]);

  // Select (Add/Remove skill)
  const handleSelectedSkill = (skill: SkillType) => {
    const isSelected = skillsSelected.some(
      (selected) => selected.id === skill.id
    );

    if (isSelected) {
      setSkillsSelected((prev) =>
        prev.filter((selected) => selected.id !== skill.id)
      );
    } else {
      if (skillsSelected.length < 25) {
        setSkillsSelected((prev) => [...prev, skill]);
      } else {
        setError("Vous ne pouvez sélectionner plus de 25 compétences");
      }
    }
  };

  // Search into a skill list
  const filteredSkills =
    query &&
    skillsList
      ?.filter((skill) =>
        skill.title.toLowerCase().includes(query.toLowerCase())
      )
      .filter(
        (skill) => !skillsSelected.some((selected) => selected.id === skill.id)
      );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row flex-wrap items-center gap-4 rounded-lg">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Recherche une compétence"
                className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600`}
                onChange={(e) => setQuery(e.currentTarget.value)}
              />
            </div>
          </div>
          <AnimatePresence>
            {filteredSkills && (
              <motion.div
                key={"result"}
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 5 }}
                className="flex max-h-[150px] flex-row flex-wrap gap-2 overflow-scroll rounded-lg border p-4 shadow-lg"
              >
                {filteredSkills.length > 0 &&
                  filteredSkills.map((skill) => (
                    <div
                      key={skill.id}
                      onClick={() => handleSelectedSkill(skill)}
                    >
                      <Tag className="cursor-pointer">{skill.title}</Tag>
                    </div>
                  ))}
                {filteredSkills.length < 1 && (
                  <Text className="text-sm">
                    Aucun résultat avec ce mot-clé
                  </Text>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {skillsSelected && skillsSelected.length > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            {skillsSelected.map((item) => (
              <div key={item.id} onClick={() => handleSelectedSkill(item)}>
                <Tag className="cursor-pointer bg-blueViolet-600 text-white">
                  {item.title}
                </Tag>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillsSelector;
