// StartDateSelector.tsx

import React from "react";
import Text from "../../../UI/Text";
import DateTypeModalSelector from "../../../Jobs/Publish/DateTypeModalSelector";

// Types
interface JobCreateType {
  startingType?: "asap" | "unspecified" | "date";
  startingAt?: string;
  // Add other fields if necessary
}

interface ErrorType {
  startingType?: string;
  startingAt?: string;
  // Add other fields if necessary
}

interface StartDateSelectorProps {
  data: JobCreateType;
  setData: React.Dispatch<React.SetStateAction<JobCreateType>>;
  errors: ErrorType;
}

const StartDateSelector: React.FC<StartDateSelectorProps> = ({
  data,
  setData,
  errors
}) => {
  const handleDateTypeClick = (type: "asap" | "unspecified" | "date") => {
    setData((prev) => ({ ...prev, startingType: type }));
  };

  return (
    <div className="flex flex-col gap-2">
      <Text className="text-xl font-semibold">Date de début souhaitée</Text>
      {errors.startingType && (
        <Text className="text-sm text-red-500">{errors.startingType}</Text>
      )}

      <div className="flex flex-row flex-nowrap items-stretch gap-4">
        {/* ASAP Option */}
        <div
          className={`flex flex-1 cursor-pointer items-end justify-center rounded-lg border p-2 text-center ${
            data.startingType === "asap"
              ? "border-blueViolet-600 bg-blueViolet-50 text-blueViolet-600"
              : "border-blueViolet-200 text-blueViolet-600 hover:bg-blueViolet-50"
          }`}
          onClick={() => handleDateTypeClick("asap")}
        >
          Dès que possible
        </div>

        {/* Date Selector Modal */}
        <DateTypeModalSelector data={data} setData={setData} />

        {/* Unspecified Option */}
        <div
          className={`flex flex-1 cursor-pointer items-end justify-center rounded-lg border p-2 text-center ${
            data.startingType === "unspecified"
              ? "border-blueViolet-600 bg-blueViolet-50 text-blueViolet-600"
              : "border-blueViolet-200 text-blueViolet-600 hover:bg-blueViolet-50"
          }`}
          onClick={() => handleDateTypeClick("unspecified")}
        >
          Je ne sais pas
        </div>
      </div>

      {errors.startingAt && (
        <Text className="text-sm text-red-500">{errors.startingAt}</Text>
      )}
    </div>
  );
};

export default StartDateSelector;
