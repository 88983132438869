// Requires
import { fetchApi } from "../Misc/api";

// Get a specific category with IA from a query
export const getCategoryFromIA = async (query: string) => {
  const response = await fetchApi(`/misc/category?search=${query}`, "GET");
  if (response.error) return false;

  return response;
};
