// Requires
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Services
import { getJobs } from "../../../../services/Admin/Jobs";

// Components
import Text from "../../../UI/Text";
import { JobType } from "../../../../types/job";

const TablePreview = () => {
  const [data, setData] = useState<JobType[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getJobs(
        0,
        5,
        "hiring",
        undefined,
        undefined,
        undefined
      );
      setData(response.jobs);
    };
    fetch();
  }, []);

  return (
    <div className="flex flex-col gap-4 rounded-lg border">
      <table className="w-full table-auto">
        <thead className="border-b bg-backgroundGray">
          <tr>
            <th className="px-4 py-2 text-left">Titre de la mission</th>
            <th className="px-4 py-2 text-left">Entreprise</th>
            <th className="px-4 py-2 text-left">Etudiant</th>
            <th className="px-4 py-2 text-left">Montant total (HT)</th>
          </tr>
        </thead>
        {data && data.length > 0 && (
          <tbody>
            {data.map((item, _id) => (
              <tr key={_id}>
                <td className="px-4 py-2 text-left">
                  <Link to={`/admin/jobs/${item.id}`}>{item.title}</Link>
                </td>
                <td className="px-4 py-2 text-left">
                  <Link to={`/admin/company/${item.company.id}`}>
                    {item.company.displayName}
                  </Link>
                </td>
                <td className="px-4 py-2 text-left">
                  <Link to={`/admin/student/${item.studentAccepted?.id}`}>
                    {item.studentAccepted &&
                    Object.keys(item.studentAccepted).length > 0
                      ? `${item.studentAccepted.firstName} ${item.studentAccepted.lastName}`
                      : "Non défini"}
                  </Link>
                </td>
                <td className="px-4 py-2 text-left">{item.total}&euro;</td>
              </tr>
            ))}
          </tbody>
        )}
        {!data ||
          (data && data.length < 1 && (
            <tbody>
              <tr>
                <td>
                  <Text className="p-2">
                    Aucunes missions en cours pour le moment.
                  </Text>
                </td>
              </tr>
            </tbody>
          ))}
      </table>
    </div>
  );
};

export default TablePreview;
