// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

// Components
import NextPrevious from "../../../../../../components/NextPrevious";
import Tag from "../../../../../../components/UI/Tag";
import TagFilledWithAction from "../../../../../../components/UI/TagFilledWithAction";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import ToggleSwitch from "../../../../../../components/UI/ToggleSwitch";

// Services
import { getCategoryFromIA } from "../../../../../../services/IA/ia";
import { getCategories, getSkills } from "../../../../../../services/Misc/misc";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

const Two = () => {
  const { step, handleNextStep, handlePrevStep } = useUI();

  const [job, setJob] = useState<string>();

  const [toggleSwitch, setToggleSwitch] = useState<boolean>(true);

  const [skillsSelected, setSkillsSelected] = useState<number[]>([]);
  const [skillsSelectedError, setSkillsSelectedError] = useState<string | null>(
    null
  );
  const [skillsAvailable, setSkillsAvailable] = useState<
    { id: number; title: string; ai: boolean }[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const [categories, setCategories] =
    useState<{ id: number; title: string }[]>();
  const [category, setCategory] = useState<{ id: number; title: string }>();

  const [seeMore, setSeeMore] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [inputFocused, setInputFocused] = useState<boolean>(false);

  // Ref to store the timeout ID
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // Start the 3-second timeout
      timeoutRef.current = setTimeout(() => {
        setIsLoading(false);
      }, 3000);

      try {
        const response2 = await getCategories();
        setCategories(response2);

        const storedJob = getLocalStorage("job");
        if (storedJob) {
          setJob(storedJob);
          const response3 = await getCategoryFromIA(storedJob);
          if (response3) setCategory(response3);
        }

        const response = await getSkills(storedJob || "");
        if (response) setSkillsAvailable(response);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Optionally, you can set an error state here to inform the user
      } finally {
        setIsLoading(false);
        // Clear the timeout if data fetch completes before 3 seconds
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
      }
    };

    fetchData();

    const storedSkillsSelected = getLocalStorage("skills");

    if (storedSkillsSelected) {
      setSkillsSelected(JSON.parse(storedSkillsSelected));
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const aiSkills = skillsAvailable
      .filter((skill) => skill.ai === true)
      .map((skill) => skill.id);

    setSkillsSelected(aiSkills.slice(0, 10));
  }, [skillsAvailable]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target as Node)
      ) {
        setSearchTerm("");
        setInputFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectedSkill = (skillId: number) => {
    setSkillsSelected((prev) => {
      if (prev.includes(skillId)) {
        return prev.filter((id) => id !== skillId);
      }

      if (prev.length < 25) {
        return [...prev, skillId];
      }

      setSkillsSelectedError(
        "Tu ne peux sélectionner que 25 compétences au maximum"
      );
      return prev;
    });
  };

  const nextStep = () => {
    if (skillsSelected.length < 5) {
      setSkillsSelectedError("Tu dois choisir au minimum 5 compétences");
      return;
    }

    setSkillsSelectedError(null);

    const localData = [
      { key: "skills", value: JSON.stringify(skillsSelected) },
      { key: "category", value: category ? category?.id.toString() : "0" },
      { key: "studentStep", value: "2" }
    ];
    setLocalStorage(localData);

    handleNextStep();
  };

  const prevStep = () => {
    const localData = [{ key: "studentStep", value: "0" }];
    setLocalStorage(localData);
    handlePrevStep();
  };

  const searchResults = skillsAvailable.filter(
    (skill) =>
      skill.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !skillsSelected.includes(skill.id)
  );

  const searchResultsIA = skillsAvailable.filter(
    (skill) =>
      skill.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !skillsSelected.includes(skill.id) &&
      skill.ai === true
  );

  const handleCategory = (id: number, title: string) => {
    setCategory({ id: id, title: title });
  };

  return (
    <div className="flex min-w-[50%] flex-col gap-10 max-md:w-full md:max-w-[50%] md:gap-24">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <span className="text-xl md:text-nowrap">
            Super ! En tant que <span className="font-semibold">{job}</span>,
            fais-tu bien des études en :
          </span>
          {category?.title ? (
            <span className="w-fit rounded-lg border border-blueViolet-600 bg-blueViolet-50 px-1 text-xl">
              {category.title}
            </span>
          ) : isLoading ? (
            <div className="loader" />
          ) : (
            <></>
          )}
        </div>
        <div
          className="cursor-pointer"
          onClick={() => setToggleSwitch((prev) => !prev)}
        >
          <ToggleSwitch isActive={toggleSwitch} />
        </div>
        <AnimatePresence>
          {!toggleSwitch && (
            <motion.div
              key="animate"
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 5 }}
              className="flex flex-col gap-4 rounded-lg border p-4"
            >
              <div className="flex flex-row flex-wrap gap-4">
                {categories?.slice(0, seeMore ? 14 : 7).map((item) => (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => handleCategory(item.id, item.title)}
                    >
                      <Tag key={item.id}>{item.title}</Tag>
                    </div>
                  </motion.div>
                ))}
              </div>
              <div onClick={() => setSeeMore((prev) => !prev)}>
                <Text className="cursor-pointer">
                  {seeMore ? "Voir moins" : "Voir plus"}
                </Text>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <Title type="page">
            Tes compétences<span style={{ color: "red" }}> * </span>
          </Title>
          <Text>
            Indique entre 5 et 25 compétences, outils, languages de
            programmation...
          </Text>
          {skillsSelectedError !== null && (
            <Text className="text-red-500">{skillsSelectedError}</Text>
          )}
        </div>
        <div className="flex flex-row flex-wrap items-center gap-4 rounded-lg border p-4">
          {skillsSelected.length > 0 ? (
            skillsAvailable
              .filter((skill) => skillsSelected.includes(skill.id))
              .map((item) => (
                <div key={item.id} onClick={() => handleSelectedSkill(item.id)}>
                  <TagFilledWithAction key={item.id}>
                    {item.title}
                  </TagFilledWithAction>
                </div>
              ))
          ) : isLoading ? (
            <div className="loader" />
          ) : (
            <></>
          )}
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Recherche une compétence"
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${
                skillsSelectedError ? "border-red-500" : "border"
              }`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => {
                if (searchResultsIA.length > 0) {
                  setInputFocused(true);
                }
              }}
            />
            {(searchTerm || inputFocused) && (
              <motion.div
                key="animate"
                initial={{ y: 5, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 5, opacity: 0 }}
                className="absolute top-10 mt-2 flex max-h-[150px] w-full flex-row flex-wrap gap-4 overflow-scroll rounded-lg border bg-white p-2 shadow-lg"
                ref={searchResultsRef}
              >
                {searchTerm ? (
                  searchResults.length > 0 ? (
                    searchResults.map((skill) => (
                      <div
                        key={skill.id}
                        onClick={() => {
                          handleSelectedSkill(skill.id);
                        }}
                        className="cursor-pointer"
                      >
                        <Tag>{skill.title}</Tag>
                      </div>
                    ))
                  ) : (
                    <Text>Aucun résultat avec ce mot-clé</Text>
                  )
                ) : (
                  searchResultsIA.length > 0 &&
                  searchResultsIA.map((skill) => (
                    <div
                      key={skill.id}
                      onClick={() => {
                        handleSelectedSkill(skill.id);
                      }}
                      className="cursor-pointer"
                    >
                      <Tag>{skill.title}</Tag>
                    </div>
                  ))
                )}
              </motion.div>
            )}
          </div>
        </div>
      </div>
      <NextPrevious
        step={step}
        nextStep={nextStep}
        prevStep={prevStep}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Two;
