import { fetchApi } from "../Misc/api";

export const getNotifications = async (page: number, limit: number) => {
  const response = await fetchApi(
    `/notification?page=${page}&limit=${limit}`,
    "GET"
  );
  if (response.error) return false;

  return response;
};

export const setNotificationAsRead = async (id: number) => {
  const response = await fetchApi(`/notification/${id}`, "PATCH");
  if (response.error) return false;

  return true;
};
