// Requires
import { useEffect, useState } from "react";

// Types
import { DashboardCharts } from "../../../types/admin";

// Services
import { getChartsData } from "../../../services/Admin/Charts";

const Popup = () => {
  const [dashboardStats, setDashboardStats] = useState<DashboardCharts>({
    jobsByCategory: [],
    studentGenderStats: undefined,
    tihStats: undefined,
    userCounts: undefined
  });

  const COLORS = [
    "#6A0DAD", // Violet profond
    "#FF6F61", // Rouge rosé
    "#FFD700", // Doré
    "#6BCB77", // Vert frais
    "#4A90E2", // Bleu doux
    "#F7CAC9", // Rose pastel
    "#92A8D1", // Bleu gris
    "#955251", // Brun terracotta
    "#B565A7", // Mauve
    "#009688", // Vert émeraude
    "#F8B400", // Jaune moutarde
    "#FF6347", // Tomate
    "#E0BBE4", // Lavande clair
    "#F4A261" // Orange doux
  ];

  const others = dashboardStats.jobsByCategory
    .sort((a, b) => b.jobCount - a.jobCount)
    .slice(4);

  useEffect(() => {
    const fetch = async () => {
      const response = await getChartsData();
      setDashboardStats(response);
    };

    fetch();
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-4">
      {others.map((item, _id) => {
        return (
          <div key={_id} className="flex flex-row gap-2">
            <div
              className="h-auto w-[15px] rounded-lg"
              style={{ backgroundColor: COLORS[_id] }}
            />
            <div className="flex flex-col">
              <p className="text-sm">{item.categoryName}</p>
              <p className="text-sm">{item.jobCount * 10}%</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Popup;
