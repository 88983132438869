// Requires
import { useEffect, useState } from "react";

// Components
import Text from "../../../../components/UI/Text";
import Title from "../../../../components/UI/Title";
import JobCardExtra from "../../../../components/Jobs/Card/JobCardExtra";

// Services
import { getRecommendedJobs } from "../../../../services/Job/job";

// Types
import { JobType } from "../../../../types/job";

const Recommend = () => {
  const [data, setData] = useState<JobType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const response = await getRecommendedJobs(0, 10);
      setData(response.jobs);
      setIsLoading(false);
    };

    fetch();
  }, []);

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex flex-col gap-1">
        <Title type="page">Missions proposées par notre équipe</Title>
        <Text>
          Tu as été spécialement choisi pour ces missions en fonction de ton
          profil par nos soins. Ne rate pas ces occasions !
        </Text>
      </div>
      <div className="flex flex-row flex-wrap gap-4">
        {!isLoading ? (
          data && data.length > 0 ? (
            data.map((item) => <JobCardExtra key={item.id} item={item} />)
          ) : (
            <Text>Aucune mission recommandée pour vous pour le moment.</Text>
          )
        ) : (
          <div className="flex w-full items-center justify-center">
            <div className="loader" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Recommend;
