// Requires
import { Link } from "react-router-dom";

// Icons
import { TableDocument } from "iconsax-react";

// Components
import Button from "../UI/Button";
import Title from "../UI/Title";
import TablePreview from "../Table/Admin/Jobs/TablePreview";

const LastMissionsInProgress = () => {
  return (
    <div className="flex flex-col gap-4 rounded-lg p-4 shadow-lg">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <TableDocument size={18} />
          <Title type="bloc">Dernières missions en cours</Title>
        </div>
        <Link to="/admin/jobs/all">
          <Button type="full">Voir toutes les missions</Button>
        </Link>
      </div>
      <TablePreview />
    </div>
  );
};

export default LastMissionsInProgress;
