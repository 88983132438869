// Icons
import { TableDocument } from "iconsax-react";

// Components
import Content from "../../Bloc/Content";
import { BlocTitle } from "../../Bloc/Title";
import Title from "../../UI/Title";

// Utils
import { useUser } from "../../../utils/store/userStore";

// Types
import type { JobDetailsType } from "../../../types/job";

const Remember = ({ data }: { data?: JobDetailsType }) => {
  const userRolename = useUser((state) => state.userdata?.roleName);

  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <TableDocument size={18} />
        <Title type="bloc">
          {userRolename === "student"
            ? "Description de la mission"
            : "Rappel de la mission"}
        </Title>
      </BlocTitle>
      <div className="flex flex-col gap-4 rounded-lg border p-4">
        <BlocTitle>
          <Title type="bloc" classname="text-blueViolet-600">
            Contexte
          </Title>
        </BlocTitle>
        <Content>
          <div
            className="w-full overflow-hidden text-ellipsis whitespace-normal break-words bg-white p-2"
            dangerouslySetInnerHTML={{ __html: data?.context || "" }}
          />
        </Content>
      </div>

      <div className="flex flex-col gap-4 rounded-lg border p-4">
        <BlocTitle>
          <Title type="bloc" classname="text-blueViolet-600">
            Objectifs
          </Title>
        </BlocTitle>
        <Content>
          <div
            className="w-full overflow-hidden text-ellipsis whitespace-normal break-words bg-white p-2"
            dangerouslySetInnerHTML={{ __html: data?.objectives || "" }}
          />
        </Content>
      </div>
      <div className="flex flex-col gap-4 rounded-lg border p-4">
        <BlocTitle>
          <Title type="bloc" classname="text-blueViolet-600">
            Livrables
          </Title>
        </BlocTitle>
        <Content>
          <div
            className="w-full overflow-hidden text-ellipsis whitespace-normal break-words bg-white p-2"
            dangerouslySetInnerHTML={{ __html: data?.deliverables || "" }}
          />
        </Content>
      </div>
    </div>
  );
};

export default Remember;
