// Components
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Requires
import { useEffect, useState } from "react";

// Components
import Button from "../../../../../../components/UI/Button";

// Services
import { fetchApi } from "../../../../../../services/Misc/api";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import { InfoCircle } from "iconsax-react";
import TagSelectable from "../../../../../../components/UI/TagSelectable";
import CustomTooltip from "../../../../../../components/UI/Tooltip";
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

// Types
interface Props {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  updateFormData: (newData: { [key: string]: any }) => void;
}

type FormData = {
  profileTitle: string;
  categories: Category[];
  degree: string;
};

type Category = {
  id: number;
  title: string;
};

type Error = {
  title?: string;
  categorie?: string;
  degree?: string;
};

const Step1 = ({ updateFormData }: Props) => {
  const [data, setData] = useState<FormData>({
    profileTitle: "",
    categories: [],
    degree: ""
  });

  const [categories, setCategories] = useState<Category[]>([]);

  const [error, setError] = useState<Error>({});

  const { handleNextStep, handlePrevStep, step } = useUI();

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchApi("/misc/categories", "GET");
      setCategories(response);
    };
    fetch();

    // Prefilled if already exist
    const localData = getLocalStorage("step1");
    if (localData) {
      const formatedLocalData: FormData = JSON.parse(localData);
      setData({
        profileTitle: formatedLocalData.profileTitle || "",
        categories: formatedLocalData.categories || [],
        degree: formatedLocalData.degree || ""
      });
    }
  }, []);

  const nextStep = () => {
    const newError: Error = {};

    if (data.profileTitle.length < 5) {
      newError.title =
        "Le métier recherché doit comprendre entre 5 et 50 caractères";
    }

    if (data.categories.length < 1) {
      newError.categorie = "Au moins une catégorie doit être sélectionnée";
    }

    if (data.degree === "") {
      newError.degree = "Un niveau d'étude doit être sélectionné";
    }

    setError(newError);

    if (Object.keys(newError).length > 0) {
      return; // Stop the function if there are errors
    }

    const localData = [
      {
        key: "step1",
        value: JSON.stringify({
          profileTitle: data.profileTitle,
          categories: data.categories,
          degree: data.degree
        })
      },
      { key: "publishJobStep", value: "1" }
    ];

    setLocalStorage(localData);
    updateFormData(data);
    handleNextStep();
  };

  const handleCategoryToggle = (category: Category) => {
    setData((prevData) => {
      const isCategorySelected = prevData.categories.some(
        (cat: any) => cat.id === category.id
      );
      const newCategories = isCategorySelected
        ? prevData.categories.filter((cat) => cat.id !== category.id)
        : [...prevData.categories, category];
      return { ...prevData, categories: newCategories };
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  if (data && data.categories) {
    return (
      <div className="flex flex-col gap-16 max-md:gap-8">
        <div className="flex flex-col items-center justify-center gap-16 max-md:gap-8">
          <Title type="page">Détails du profil recherché</Title>
          <div className="flex w-full flex-col gap-16">
            <div className="flex flex-col gap-8 max-md:gap-4">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center gap-2">
                  <Text className="text-xl font-semibold">
                    Métier recherché
                  </Text>
                  <CustomTooltip
                    position="top"
                    content="Décrivez en quelques mots le métier qui correspond le mieux à votre besoin. Ce champs nous permet d’optimiser la recherche du profil idéal pour votre besoin !"
                    className="max-w-[250px]"
                  >
                    <InfoCircle size={18} />
                  </CustomTooltip>
                </div>
                <input
                  maxLength={50}
                  name="profileTitle"
                  onChange={handleChange}
                  value={data.profileTitle}
                  type="text"
                  className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${error.title && "border-red-500"} `}
                  placeholder="Développeur web, graphiste, comptable..."
                />
                <div className="flex items-end justify-between">
                  <Text className="text-red-500">
                    {error.title && error.title}
                  </Text>
                  <Text>{data.profileTitle.length}/50 caractères</Text>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <Text className="text-xl font-semibold">Domaine d'étude</Text>
                <div className="flex flex-row flex-wrap gap-2">
                  {categories.map((item) => (
                    <div
                      key={item.id}
                      onClick={() => handleCategoryToggle(item)}
                    >
                      {Array.isArray(data.categories) ? (
                        <TagSelectable
                          isActiveState={data.categories.some(
                            (cat) => cat.id === item.id
                          )}
                        >
                          {item.title}
                        </TagSelectable>
                      ) : (
                        <p>test</p>
                      )}
                    </div>
                  ))}
                </div>
                <Text className="text-red-500">
                  {error.categorie && error.categorie}
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <Text className="text-xl font-semibold">
                  Niveau d'étude de l'étudiant
                </Text>
                <select
                  value={data.degree}
                  name="degree"
                  onChange={handleChange}
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${error.degree && "border-red-500"}`}
                >
                  <option value="">Sélectionnez un niveau</option>
                  <option value="highSchool">Lycée</option>
                  <option value="bachelor1">Bac</option>
                  <option value="bachelor2">Bac +2</option>
                  <option value="bachelor3">Bac +3</option>
                  <option value="bachelor4">Bac +4</option>
                  <option value="bachelor5">Bac +5 | Master</option>
                  <option value="bachelor6">Bac +6</option>
                  <option value="bachelor7">Bac +7</option>
                  <option value="bachelor8">Bac +8 | Doctorat</option>
                </select>
                <Text className="text-red-500">
                  {error.degree && error.degree}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <div onClick={handlePrevStep}>
            <Button type="fullLite">Précédent</Button>
          </div>
          <div className="flex-1 text-center">
            <Text>Étape {step + 1}/6</Text>
          </div>
          <div>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div onClick={nextStep}>
              <Button type="full">Suivant</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Step1;
