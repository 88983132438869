// Components
import { Link } from "react-router-dom";
import { HorizontalDivider } from "../../../UI/Divider";
import Text from "../../../UI/Text";

const Card = ({
  data
}: {
  data: {
    company: {
      displayName: string;
      id: number;
      image: string;
    };
    context: string;
    duration: number;
    id: number;
    owner: {
      id: number;
      firstName: string;
      lastName: string;
      image: string;
    };
    title: string;
    total: number;
    type: string;
    workMode: string;
  };
}) => {
  return (
    <Link
      to={"/admin/jobs/" + data.id}
      className="flex cursor-pointer flex-col gap-4 rounded-lg border p-4 shadow-lg hover:bg-blueViolet-50"
    >
      <Text className="max-w-full text-wrap break-words font-semibold">
        {data.title}
      </Text>
      <Text className="max-w-full text-wrap break-words text-sm">
        {data.context}
      </Text>
      <HorizontalDivider />
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <img
            src={data.company.image ?? "https://piscum.photos/100/100"}
            className="h-6 w-6 rounded-full bg-blueViolet-50"
          />
          <Text>{data.company.displayName}</Text>
        </div>
        <Text>{data.total}&euro;</Text>
      </div>
    </Link>
  );
};

export default Card;
