// Requires
import { useState } from "react";

// Components
import Title from "../../../UI/Title";
import Text from "../../../UI/Text";
import Button from "../../../UI/Button";
import Highlight from "../../../UI/Highlight";

// Stores
import { useUser } from "../../../../utils/store/userStore";

// Services

// Types
import {
  availibityType,
  transportType,
  WeeklyAvailabilityType
} from "../../../../types/user";
import Disponibilities from "../../../Onboarding/Student/Disponibilities";
import { updateWeeklyAvailability } from "../../../../services/User/user";

const DisponibilityAndPreferences = () => {
  const user = useUser((state) => state.userdata);
  const [error, setError] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [data, setData] = useState<{
    transportType: transportType[];
    workMode: "remote" | "onSite" | "hybrid" | undefined;
    studentAvailability: WeeklyAvailabilityType[];
  }>({
    transportType: user?.transportType || [],
    workMode: user?.workMode || undefined,
    studentAvailability: user?.weeklyAvailability || []
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;

    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const save = async () => {
    setError({});
    setIsLoading(true);

    // Validate required fields
    if (data.transportType.length === 0) {
      setError({
        transportType: "Veuillez sélectionner au moins un moyen de transport"
      });
      setIsLoading(false);
      return;
    }

    const dataToSend = {
      studentWeeklyAvailability:
        data.studentAvailability || user?.weeklyAvailability,
      workMode: data.workMode || user?.workMode,
      transportTypes: data.transportType || user?.transportType
    };

    // Send data via API call
    const response = await updateWeeklyAvailability(dataToSend);

    if (response) {
      setIsLoading(false);
      setSuccess(true);
      useUser.getState().getUser();
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } else {
      // Handle error
      setIsLoading(false);
      setError({ general: "Une erreur s'est produite lors de la mise à jour" });
    }
  };

  const handleTransportChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    setData((prev) => {
      const currentTransportTypes = prev.transportType || [];

      const newTransportTypes = checked
        ? [...currentTransportTypes, value as transportType]
        : currentTransportTypes.filter((type) => type !== value);

      return {
        ...prev,
        transportType: newTransportTypes as transportType[]
      };
    });
  };

  interface InputData {
    // id: number;
    // studentId: number;
    monday: availibityType[];
    tuesday: availibityType[];
    wednesday: availibityType[];
    thursday: availibityType[];
    friday: availibityType[];
    saturday: availibityType[];
    sunday: availibityType[];
    // jobId: number | null;
    // updateAt: string;
    // userId: number | null;
  }

  const transformAvailability = (data: InputData): WeeklyAvailabilityType[] => {
    const daysOfWeek: (keyof InputData)[] = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday"
    ];

    return daysOfWeek.map((day) => ({
      availability: data[day] || []
    }));
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Disponibilités et préférences</Title>
      <Disponibilities
        setDataWeeklyAvailability={(availabilityData: any) => {
          setData((prev: any) => ({
            ...prev,
            studentAvailability: availabilityData
          }));
        }}
        defaultAvailability={transformAvailability(
          data.studentAvailability as any
        )}
        error={error}
      />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Text className="font-semibold">Tu préfères travailler : </Text>
          <select
            name="workMode"
            value={data.workMode}
            onChange={handleChange}
            className="w-fit rounded-lg border p-2"
          >
            <option value="remote">À distance</option>
            <option value="onSite">Sur site</option>
            <option value="hybrid">Les deux</option>
          </select>
        </div>
        <div className="flex flex-col gap-4">
          <Text>
            Tu te déplaces en<span style={{ color: "red" }}> * </span>
          </Text>
          {error.transportType && (
            <Text className="text-xs text-red-500">{error.transportType}</Text>
          )}
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <input
                name="transportType"
                value="motorcycle"
                type="checkbox"
                className="checkbox-large"
                onChange={handleTransportChange}
                checked={data.transportType.includes("motorcycle")}
              />
              <label>Deux-roues</label>
            </div>
            <div className="flex flex-row items-center gap-2">
              <input
                name="transportType"
                value="publicTransport"
                type="checkbox"
                className="checkbox-large"
                onChange={handleTransportChange}
                checked={data.transportType.includes("publicTransport")}
              />
              <label>Transport en commun</label>
            </div>
            <div className="flex flex-row items-center gap-2">
              <input
                name="transportType"
                value="bike"
                type="checkbox"
                className="checkbox-large"
                onChange={handleTransportChange}
                checked={data.transportType.includes("bike")}
              />
              <label>Vélo</label>
            </div>
            <div className="flex flex-row items-center gap-2">
              <input
                name="transportType"
                value="car"
                type="checkbox"
                className="checkbox-large"
                onChange={handleTransportChange}
                checked={data.transportType.includes("car")}
              />
              <label>Voiture</label>
            </div>
          </div>
        </div>
      </div>
      {success && (
        <Highlight type="Success">
          Les modifications ont bien été appliquées.
        </Highlight>
      )}
      {error.general && <Highlight type="Error">{error.general}</Highlight>}
      <div onClick={save} className="flex flex-row justify-end">
        <Button type="full">
          {isLoading ? <div className="loader" /> : "Enregistrer"}
        </Button>
      </div>
    </div>
  );
};

export default DisponibilityAndPreferences;
