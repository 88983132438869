// Icons
import { Star } from "iconsax-react";

// Assets
import Illu from "../../../../../../assets/illu.png";

// Components
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Requires
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// Context
import { usePopup } from "../../../../../../utils/context/Popup";
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import {
  removeFromLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

// Utils
import { Desktop } from "../../../../../../utils/mobile/responsive";

const Five = () => {
  const { step, handleNextStep } = useUI();

  const { toggleVisibility } = usePopup();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    removeFromLocalStorage("skills");
    removeFromLocalStorage("category");
    removeFromLocalStorage("education");
    removeFromLocalStorage("job");

    const provider = searchParams.get("provider");

    if (provider) {
      const token = searchParams.get("access");
      const refresh = searchParams.get("refresh");

      if (token && refresh) {
        const localData = [
          { key: "token", value: token },
          { key: "refresh", value: refresh }
        ];
        setLocalStorage(localData);
      }
    }
  }, []);

  const nextStep = () => {
    const localData = [{ key: "studentStep", value: "5" }];
    setLocalStorage(localData);
    handleNextStep();
  };

  return (
    <div className="flex min-w-[80%] flex-row md:max-w-[80%]">
      <div className="flex flex-1 grow flex-col items-center justify-center gap-12 overflow-y-auto p-6 md:px-12 md:py-8">
        <div className="flex flex-col gap-12">
          <Title type="page">Fais briller tes expériences !</Title>
          <Text className="rounded-lg bg-whiteSimple p-4">
            Améliore ta visibilité et tes chances d’être recommandé auprès des
            entreprises en complétant ton profil avec tes expériences
            professionnelles et les formations que tu as pu faire.
          </Text>
          <div className="flex flex-col gap-4">
            <Text className="font-semibold">
              Comment souhaites-tu remplir ton profil ?
            </Text>
            <div
              className="flex cursor-pointer flex-col rounded-lg border-4 border-gray-400 p-4 hover:border-blueViolet-600"
              onClick={() => toggleVisibility("linkedInExplenation")}
            >
              <div className="flex flex-row items-center justify-between gap-4">
                <div className="flex flex-col">
                  <div className="items-top flex gap-4">
                    <Text>Importer mes informations depuis LinkedIn</Text>
                    <Star size="32" color="#e3e846" variant="Bold" />
                  </div>
                  <Text className="text-sm">5 minutes</Text>
                </div>
              </div>
            </div>
            <div
              onClick={nextStep}
              className="flex cursor-pointer flex-col rounded-lg border p-4 hover:border-blueViolet-600"
            >
              <Text>
                Compléter mes expériences et mes formations manuellement
              </Text>
              <Text className="text-sm">15 minutes</Text>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex-1 text-center">
              <Text>Étape {step}/14</Text>
            </div>
          </div>
        </div>
      </div>
      <Desktop>
        <div className="flex flex-1 grow items-center justify-center p-12">
          <div>
            <img src={Illu} alt="Illustration" />
          </div>
        </div>
      </Desktop>
    </div>
  );
};

export default Five;
