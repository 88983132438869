// Requires
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Assets
import GoogleIcon from "../../../../../../assets/Icons/google.svg";
import LinkedInIcon from "../../../../../../assets/Icons/linkedin.svg";

// Components
import Button from "../../../../../../components/UI/Button";
import { HorizontalDivider } from "../../../../../../components/UI/Divider";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import NextPrevious from "../../../../../../components/NextPrevious";

// Services
import {
  registerUser,
  updateUserMainInfo
} from "../../../../../../services/User/user";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Services
import config from "../../../../../../utils/config";

// Helpers
import {
  getLocalStorage,
  removeFromLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";
import {
  validateEmail,
  validatePassword
} from "../../../../../../utils/helpers/validators";

// Types
import type {
  UserRegisterType,
  UserStudentMainInfoType
} from "../../../../../../types/user";

type ErrorType = {
  email?: string;
  password?: string | JSX.Element;
  register?: string;
  terms?: string;
};

const Three = () => {
  const { step, handleNextStep, handlePrevStep, specificStep } = useUI();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType>({});

  const [registerError, setRegisterError] = useState<string>();

  const [searchParams] = useSearchParams();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const nextStep = async () => {
    setIsLoading(true);

    const newError: ErrorType = {};

    if (!email || !validateEmail(email)) {
      newError.email = "Tu dois entrer un email au format valide";
    }

    if (!password || !validatePassword(password)) {
      newError.password = (
        <>
          <Text className="text-red-500">
            Tu dois entrer un mot de passe au format valide.
          </Text>
          <Text className="text-red-500">
            (1 Maj, 1 Min, 1 caractère spécial (@, $, !, %, *, ?, &, _, -, .), 1
            chiffre et 6 caractères min)
          </Text>
        </>
      );
    }

    // Validation de la case à cocher
    if (!acceptedTerms) {
      newError.terms =
        "Tu dois accepter les conditions de la charte des freelances étudiants Juwa";
    }

    setError(newError);

    if (Object.keys(newError).length > 0) {
      setIsLoading(false);
      return; // Stop the function if there are errors
    }

    const localData = [{ key: "email", value: email }];
    setLocalStorage(localData);

    // Reorganize data
    const data: UserRegisterType = {
      email: email,
      password: password,
      role: "student"
    };

    const response = await registerUser(data);

    if (response.error) {
      newError.register = response.message;
      setIsLoading(false);
      return setError(newError);
    }

    const job = getLocalStorage("job");
    const education = getLocalStorage("education");
    const skills = getLocalStorage("skills");
    const category = getLocalStorage("category");

    if (job && education && skills && category) {
      // Convertir les compétences de chaîne JSON en tableau de nombres
      const skillsArray = JSON.parse(skills).map((id: string) =>
        Number.parseInt(id, 10)
      );

      // Réorganiser les données
      const mainInfoData: UserStudentMainInfoType = {
        profileTitle: job,
        category: Number(category),
        skills: skillsArray,
        degree: education
      };

      const updateResponse = await updateUserMainInfo(mainInfoData);

      if (updateResponse) {
        const localData = [{ key: "studentStep", value: "3" }];
        setLocalStorage(localData);
        removeFromLocalStorage("skills");
        removeFromLocalStorage("category");
        removeFromLocalStorage("education");
        removeFromLocalStorage("job");
        handleNextStep();
      }
    }

    setIsLoading(false);
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      nextStep();
    }
  };

  const prevStep = () => {
    const localData = [{ key: "studentStep", value: "2" }];
    setLocalStorage(localData);
    handlePrevStep();
  };

  const autoLoginWithGoogle = async () => {
    try {
      setIsLoading(true);
      window.location.href = `${config.backUrl}/auth/login/google?role=student`;
    } catch (err) {
      console.error("Auto-login with Google failed:", err);
      setRegisterError("La connexion automatique a échoué.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickProvider = (provider: string) => {
    const url = `${config.backUrl}/auth/register/${provider}?role=student`;

    const newError: ErrorType = {};
    if (!acceptedTerms) {
      newError.terms =
        "Tu dois accepter les conditions de la charte des freelances étudiants Juwa";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    window.location.href = url;
  };

  // Effect to handle step from URL parameters
  useEffect(() => {
    const localStep = searchParams.get("step");
    if (localStep) {
      const localData = [{ key: "studentStep", value: localStep }];
      setLocalStorage(localData);
      specificStep(Number(step));
    }
  }, []);

  useEffect(() => {
    const error = searchParams.get("error");
    const provider = searchParams.get("provider");
    const code = searchParams.get("code"); // Assuming 'code' is returned by Google OAuth

    if (
      error === "Un compte avec ce mail existe déjà." &&
      provider === "google"
    ) {
      autoLoginWithGoogle();
    }

    if (
      error &&
      !(
        error === "Un compte avec ce mail existe déjà." && provider === "google"
      )
    ) {
      setRegisterError(error);
    }
  }, [searchParams]);

  return (
    <div className="flex min-w-[50%] flex-col md:max-w-[50%]">
      <div className="flex flex-col gap-12">
        <div className="flex items-center justify-center">
          <Title type="page">On peut maintenant créer tes identifiants !</Title>
        </div>
        <div className="flex flex-col gap-2">
          <div onClick={() => handleClickProvider("google")}>
            <Button type="fullLite" className="w-full">
              <img src={GoogleIcon} sizes="18" alt="Google Icon" /> Continuer
              avec Google
            </Button>
          </div>
          <div onClick={() => handleClickProvider("linkedin")}>
            <Button type="fullLite" className="w-full">
              <img src={LinkedInIcon} sizes="18" alt="LinkedIn Icon" />{" "}
              Continuer avec LinkedIn
            </Button>
          </div>
        </div>
        {registerError && (
          <div className="flex">
            <Text className="text-sm text-red-500">{registerError}</Text>
          </div>
        )}
        <div className="flex flex-row items-center gap-4">
          <HorizontalDivider />
          <Text>OU</Text>
          <HorizontalDivider />
        </div>
        <div className="flex flex-col gap-8">
          {error?.register && (
            <Text className="text-red-500">{error.register}</Text>
          )}
          <div className="flex flex-col gap-2">
            <Title type="bloc">
              Email<span style={{ color: "red" }}> * </span>
            </Title>
            <input
              onKeyDown={handleKey}
              onChange={(e) => setEmail(e.currentTarget.value)}
              type="email"
              placeholder="nom@email-académique.com"
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${
                error.email ? "border-red-500" : "border"
              }`}
            />
            {error.email && <Text className="text-red-500">{error.email}</Text>}
          </div>
          <div className="flex flex-col gap-2">
            <Title type="bloc">
              Mot de passe<span style={{ color: "red" }}> * </span>
            </Title>
            <div className="relative">
              <input
                onKeyDown={handleKey}
                onChange={(e) => setPassword(e.currentTarget.value)}
                type={isPasswordVisible ? "text" : "password"}
                placeholder="********"
                className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600 ${
                  error.password ? "border-red-500" : "border"
                }`}
              />
              <button
                type="button"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className="absolute inset-y-0 right-0 px-3 py-2 text-sm text-gray-600"
                aria-label={
                  isPasswordVisible
                    ? "Cacher le mot de passe"
                    : "Montrer le mot de passe"
                }
              >
                {isPasswordVisible ? "Cacher" : "Montrer"}
              </button>
            </div>
            {error.password && (
              <Text className="text-red-500">{error.password}</Text>
            )}
          </div>

          {/* Ajout de la case à cocher */}
          <div className="flex flex-col gap-2">
            <label className="flex cursor-pointer items-center">
              <input
                type="checkbox"
                checked={acceptedTerms}
                onChange={(e) => setAcceptedTerms(e.target.checked)}
                className="mr-2 h-4 w-4 cursor-pointer"
              />
              <span>
                J’accepte les{" "}
                <a
                  href="https://storagettv.blob.core.windows.net/static/Charte%20des%20e%CC%81tudiants%20JUWA.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  conditions de la charte des freelances étudiants Juwa
                </a>
                <span style={{ color: "red" }}> * </span>
              </span>
            </label>
            {error.terms && <Text className="text-red-500">{error.terms}</Text>}
          </div>

          {/* Ajout du texte en dessous avec le lien */}
          <div className="flex flex-col gap-2">
            <Text className="text-sm">
              En m’inscrivant sur Juwa, je m’engage à respecter les{" "}
              <a
                href="https://juwa.co/cgv-cgu"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                Conditions Générales d’Utilisation de la plateforme
              </a>
              .
            </Text>
          </div>
        </div>
        <NextPrevious
          step={step}
          nextStep={nextStep}
          prevStep={prevStep}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Three;
