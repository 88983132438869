import { User } from "../../types/user";
import { fetchApi } from "../Misc/api";

type Filter = {
  date?: string | null;
  search?: string | null;
  type?: string | null;
  startingType?: string | null;
  status?: string | null;
  jobStatus?: string | null;
};

// Retrieve the list of proposals made on all missions posted by the connected company member
export const getProposal = async (
  page: number,
  limit: number,
  filters?: Filter,
  details?: boolean
) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString()
  });

  if (filters) {
    if (filters.search) queryParams.append("search", filters.search);
    if (filters.date) queryParams.append("date", filters.date);
    if (filters.type) queryParams.append("jobType", filters.type);
    if (filters.startingType)
      queryParams.append("startingType", filters.startingType);
    if (filters.jobStatus) queryParams.append("jobStatus", filters.jobStatus);
  }

  const response = await fetchApi(
    `/proposal?${queryParams.toString()}&details=${details ?? false}`,
    "GET"
  );

  if (response.error) throw new Error("getProposal call API failed !");

  return response;
};

// Retrieve the list of proposals made on a specific mission
export const getProposalFromJob = async (
  page: number,
  limit: number,
  jobId: number,
  details?: boolean
) => {
  const response = await fetchApi(
    `/proposal?jobId=${jobId}&page=${page}&limit=${limit}&details=${details ?? false}`,
    "GET"
  );

  if (response.error) throw new Error("getProposalFromJobId call API failed !");

  return response;
};

// Create a new proposal to a specific job
export const createNewProposal = async (data: FormData, jobId: number) => {
  const response = await fetchApi(`/proposal/${jobId}`, "POST", data);
  if (response.error) return false;

  return true;
};

// Get a proposal details from a specific job
export const getProposalDetailsFromJob = async (
  jobId: number,
  proposalId: number
) => {
  const response = await fetchApi(`/proposal/${proposalId}`, "GET");
  if (response.error) return false;
  return response;
};

// Response to a proposal from a specific job
export const responseToProposal = async (
  proposalId: number,
  action: "accepted" | "refused",
  user: User
) => {
  const data = {
    response: action,
    mobileNumber: user.phoneNumber
  };

  const response = await fetchApi(
    `/proposal/${proposalId}/response`,
    "PATCH",
    data
  );

  if (response.error) return false;

  return response;
};
