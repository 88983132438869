// Requires
import { fetchApi } from "../Misc/api";

// Types
type Filter = {
  status?: string | null;
  type?: string | null;
  owner?: string | null;
  date?: string | null;
  search?: string | null;
};

// Retrieve all companies (limit, page, filters)
export const getCompanies = async (
  page: string,
  limit: string,
  filters: Filter
) => {
  const queryParams = new URLSearchParams({
    page: page,
    limit: limit
  });

  if (filters.search) queryParams.append("search", filters.search);

  const response = await fetchApi(`/company?${queryParams.toString()}`, "GET");
  if (response.error) return false;

  return response;
};
