// Requires
import { fetchApi } from "../Misc/api";

// Types
type Filter = {
  status?: string | null;
  type?: string | null;
  search?: string | null;
  date?: string | null;
  startingType?: string | null;
};

// Retrieve company information and its employees
export const getCompany = async (id?: number) => {
  const url = id ? `/company/${id}` : `/company`;

  const response = await fetchApi(url, "GET");
  if (response.error) throw new Error("getCompany with siret call api failed!");

  return response;
};

// Retrieve the lists of missions posted by all members of the company
export const getCompanyJobs = async (
  page: number,
  limit: number,
  filters: Filter
) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString()
  });

  if (filters.status) queryParams.append("status", filters.status);
  if (filters.type) queryParams.append("type", filters.type);
  if (filters.date) queryParams.append("date", filters.date);
  if (filters.search) queryParams.append("search", filters.search);
  if (filters.startingType)
    queryParams.append("startingType", filters.startingType);

  const response = await fetchApi(`/job?${queryParams.toString()}`, "GET");

  if (response.error) throw new Error("getCompanyJobs call api failed!");

  return response;
};

export const newCompanyTechnicalAccount = async (data: {
  country: string;
  birthdate: string;
  nationality: string;
}) => {
  const response = await fetchApi(`/payment/legal/technical`, "POST", data);
  if (response.error) return false;

  return true;
};

export const updateCompanyProfile = async (data: FormData) => {
  const response = await fetchApi("/company/profile", "POST", data);

  if (response.error) return false;

  return true;
};
