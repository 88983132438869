// Icons
import { Location, SearchNormal1 } from "iconsax-react";

// Requires
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Context
import { useUI } from "../../utils/context/UI";

// Components
import Button from "../UI/Button";

const TopFilter = ({
  handleUpdateFilter
}: {
  handleUpdateFilter?: (type: string, value: string) => void;
}) => {
  const { findFilterIsOpen, toggleFindFilter } = useUI();

  const handleToggleFilter = useCallback(() => {
    toggleFindFilter();
  }, [toggleFindFilter]);

  const [searchTerms, setSearchTerms] = useState<string>(
    useSearchParams()[0].get("search") ?? ""
  );
  const [locationSearchTerms, setLocationSearchTerms] = useState<string>();

  const handleSearch = () => {
    if (handleUpdateFilter) {
      if (searchTerms && searchTerms.length > 0) {
        handleUpdateFilter("search", searchTerms);
      }

      if (!searchTerms || searchTerms?.length < 1) {
        handleUpdateFilter("search", "");
      }

      if (locationSearchTerms && locationSearchTerms.length > 0) {
        handleUpdateFilter("location", locationSearchTerms);
      }

      if (!locationSearchTerms || locationSearchTerms?.length < 1) {
        handleUpdateFilter("location", "");
      }
    }
  };

  return (
    <div className="flex flex-col gap-4 md:flex-row">
      <div className="relative inline-block h-fit flex-1">
        <SearchNormal1
          size={18}
          className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 transform text-textGray"
        />
        <input
          onChange={(e) => setSearchTerms(e.currentTarget.value)}
          value={searchTerms}
          type="text"
          className="w-full rounded-md border py-2 pl-10 pr-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Entrez votre besoin, essayer 'communication'"
        />
      </div>
      <div className="relative inline-block h-fit flex-1">
        <Location
          size={18}
          className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 transform text-textGray"
        />
        <input
          onChange={(e) => setLocationSearchTerms(e.currentTarget.value)}
          type="text"
          className="w-full rounded-md border py-2 pl-10 pr-4 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Lieu"
        />
      </div>
      <div onClick={handleSearch}>
        <Button type="full" className="flex items-center text-center">
          Rechercher un étudiant
        </Button>
      </div>
      <div onClick={handleToggleFilter}>
        <Button type="outline" className="flex items-center text-center">
          Ouvrir les filtres
        </Button>
      </div>
    </div>
  );
};

export default TopFilter;
