// Requires
import { AnimatePresence, motion } from "framer-motion";
import type React from "react";
import { useEffect, useState } from "react";

// Icons
import { InfoCircle, Verify } from "iconsax-react";

// Components
import Button from "../../../../../components/UI/Button";
import Text from "../../../../../components/UI/Text";
import Title from "../../../../../components/UI/Title";
import CustomTooltip from "../../../../../components/UI/Tooltip";
import CompanySizeDropdown from "../../../../../components/Onboarding/Student/CompanySizeDropdown";

// Services
import {
  getIdFromNafCode,
  getPostOffices
} from "../../../../../services/Misc/misc";
import {
  searchSiret,
  setCompanyToCompanyUser
} from "../../../../../services/User/user";

// Context
import { useUI } from "../../../../../utils/context/UI";

// Helpers
import { setLocalStorage } from "../../../../../utils/helpers/localStorage";

// Types
import type { CompanySearchType } from "../../../../../types/company";

type ErrorType = {
  global?: string;
  userActivity?: string;
  companyActivity?: string;
};

const Four = () => {
  const { step, handleNextStep } = useUI();

  const [errors, setErrors] = useState<ErrorType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [results, setResults] = useState<CompanySearchType[] | null>(null);

  const [data, setData] = useState<{
    siret?: string;
    userActivity?: string;
    companyActivity?: number;
    companySizeId?: number;
  }>({ siret: "", userActivity: "", companyActivity: 0, companySizeId: 0 });

  const [postOffices, setPostOffices] =
    useState<{ id: number; name: string }[]>();

  const [companySelected, setCompanySelected] = useState<{
    name: string;
    siren: string;
    siret: string;
    address: string;
    nafCode: string;
    nafName: string;
  }>();

  const [query, setQuery] = useState<string>("");

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const response = await getPostOffices();
      if (response) setPostOffices(response);
    };

    fetch();
  }, []);

  useEffect(() => {
    if (companySelected?.nafCode) {
      const fetch = async () => {
        const response: { id: number; name: string } = await getIdFromNafCode(
          companySelected?.nafCode
        );
        setData((prev) => ({
          ...(prev || {}),
          companyActivity: response.id
        }));
      };
      fetch();
    }
  }, [companySelected?.nafCode]);

  const handleSearchCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setQuery(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (value.length < 4) {
      setResults(null);
      setIsSearching(false);
    }

    if (value.length >= 4) {
      setIsSearching(true);
      const newTimeoutId = setTimeout(async () => {
        const response = await searchSiret(value);
        if (response) {
          setResults(response);
          setIsSearching(false);
        } else {
          setResults(null);
          setIsSearching(false);
        }
      }, 2000);

      setTimeoutId(newTimeoutId);
    }
  };

  const handleSelectCompany = (item: {
    siret: string;
    siren: string;
    name: string;
    address: string;
    nafCode: string;
    nafName: string;
  }) => {
    setCompanySelected(item);
    setData((prev) => ({
      ...prev,
      // userActivity: prev?.userActivity || "",
      // companyActivity: prev?.companyActivity || 0,
      siret: item.siret
    }));
    setErrors({});
  };

  const resetCompany = () => {
    setCompanySelected(undefined);
    setData((prev) => ({
      ...(prev || {}),
      userActivity: "",
      companyActivity: 0,
      siret: "",
      companySizeId: 0
    }));
  };

  const nextStep = async () => {
    setIsLoading(true);
    setError(undefined);
    const newErrors: ErrorType = {};
    setErrors(newErrors);

    if (!companySelected) {
      newErrors.global = "Vous devez remplir le formulaire ci-dessous";
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    if (!data.userActivity) {
      newErrors.userActivity =
        "Vous devez indiquer votre activité dans l'entreprise";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    if (data) {
      const response = await setCompanyToCompanyUser(data);
      if (response) {
        setLocalStorage([{ key: "companyRegisterStep", value: "4" }]);
        setIsLoading(false);
        handleNextStep();
      }
    }

    setIsLoading(false);
    setError("Une erreur est survenue, veuillez reessayer.");
    return;
  };

  return (
    <div className="flex w-full min-w-[50%] flex-col max-md:max-w-[80%] md:max-w-[50%]">
      <div className="flex w-full flex-col gap-12 max-md:gap-6">
        <div className="flex items-center justify-center">
          <Title type="page">Informations sur votre entreprise</Title>
        </div>

        <div className="flex flex-row gap-12 max-md:flex-col max-md:gap-4">
          <div className="flex grow flex-col gap-2">
            <div className="flex flex-col gap-2">
              <CustomTooltip
                position="top"
                content="Rentrez le nom de votre entreprise, et nous récupérerons toutes les informations légales de votre entreprise. Rapide, efficace."
              >
                <div className="flex flex-row items-center gap-2">
                  <InfoCircle size={16} />
                  <Text className="font-semibold">
                    Nom de votre entreprise ou n°SIRET
                    <span style={{ color: "red" }}> * </span>
                  </Text>
                </div>
              </CustomTooltip>
              {errors?.global && (
                <Text className="text-sm text-red-500">{errors.global}</Text>
              )}
              <div className="flex flex-row gap-2 max-md:w-full">
                <input
                  value={query}
                  onChange={handleSearchCompany}
                  type="text"
                  className={`grow rounded-lg border p-2 focus:outline-blueViolet-600 max-md:w-full max-md:shrink ${errors?.global && "border border-red-500"}`}
                  placeholder={
                    companySelected
                      ? companySelected.name
                      : "Entrer le nom ou le siret de l'entreprise"
                  }
                  disabled={companySelected ? true : false}
                />
                {companySelected && (
                  <div onClick={resetCompany}>
                    <Button type="full">Modifier</Button>
                  </div>
                )}
              </div>
              <AnimatePresence mode="sync">
                {query && (
                  <motion.div
                    key={"animate"}
                    initial={{ y: 5, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 5, opacity: 0 }}
                    className="max-h-[200px] overflow-scroll rounded-lg border p-2 shadow-lg"
                  >
                    {isSearching ? (
                      <div className="loader" />
                    ) : results && results?.length > 0 ? (
                      results?.map((item, _i) => (
                        <div
                          onClick={() => {
                            setQuery("");
                            handleSelectCompany(item);
                          }}
                          key={item.siret}
                          className="flex cursor-pointer flex-col gap-1 rounded-lg p-2 hover:bg-blueViolet-600 hover:text-white"
                        >
                          <Text className="font-semibold">{item.name}</Text>
                          <Text className="text-sm">{item.address}</Text>
                        </div>
                      ))
                    ) : (
                      <p>Aucun resultat</p>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <AnimatePresence mode="sync">
              {companySelected && (
                <motion.div
                  className="flex flex-col gap-2"
                  key="animtedDiv"
                  initial={{ opacity: 0, y: 5 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                  exit={{ opacity: 0, y: 5 }}
                >
                  <div className="flex flex-col gap-2">
                    <Text className="font-semibold">
                      Votre équipe au sein de l'entreprise
                    </Text>
                    {errors?.userActivity && (
                      <Text className="text-sm text-red-500">
                        {errors.userActivity}
                      </Text>
                    )}
                    <select
                      onChange={(e) => {
                        const selectedValue = e.currentTarget.value;
                        setData((prev) => ({
                          ...prev,
                          userActivity: selectedValue || ""
                        }));
                      }}
                      className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${errors?.userActivity && "border border-red-500"}`}
                    >
                      <option value={""}>Indiquer votre équipe</option>
                      {postOffices?.map((item, _i) => (
                        <option key={_i} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <CompanySizeDropdown errors={errors} setData={setData} />

                  <div className="flex flex-col gap-2">
                    {!companySelected.nafName && (
                      <Text className="font-semibold">Secteur d'activité</Text>
                    )}
                    {errors?.companyActivity && (
                      <Text className="text-sm text-red-500">
                        {errors.companyActivity}
                      </Text>
                    )}
                    {!companySelected.nafName && (
                      <select
                        value={
                          companySelected?.nafCode
                            ? companySelected.nafCode
                            : ""
                        }
                        aria-readonly
                        className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${errors?.companyActivity && "border border-red-500"}`}
                      >
                        <option value={""}>
                          Indiquer le secteur d'activité
                        </option>
                        {companySelected?.nafCode &&
                          companySelected.nafName && (
                            <option value={companySelected.nafCode}>
                              {companySelected.nafName}
                            </option>
                          )}
                      </select>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <AnimatePresence mode="sync">
            {companySelected && (
              <motion.div
                key={"animation"}
                initial={{ x: 5, opacity: 0, width: 0 }}
                animate={{
                  x: 0,
                  opacity: 1,
                  width: "100%"
                }} // Adjust the width as needed
                exit={{ x: 5, opacity: 0, width: 0 }}
                className="flex h-fit flex-col gap-4 rounded-lg border border-blueViolet-600 p-6"
              >
                <div className="flex flex-row items-center gap-1 font-semibold text-blueViolet-600">
                  <Verify size={18} />
                  <Text>Détail de l'entreprise</Text>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row gap-2">
                    <Text className="font-semibold">NOM:</Text>
                    <Text>{companySelected.name}</Text>
                  </div>
                  <div className="flex flex-row gap-2">
                    <Text className="font-semibold">SIRET:</Text>
                    <Text>{companySelected.siret}</Text>
                  </div>
                  <div className="flex flex-row gap-2">
                    <Text className="font-semibold">SIREN:</Text>
                    <Text>{companySelected.siren}</Text>
                  </div>
                  <div className="flex flex-row gap-2">
                    <Text className="font-semibold">Siège social:</Text>
                    <Text>{companySelected.address}</Text>
                  </div>
                  {companySelected.nafName && (
                    <div className="flex flex-row gap-2">
                      <Text className="font-semibold">Secteur d'activité:</Text>
                      <Text>{companySelected.nafName}</Text>
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {error && <Text className="text-red-500">{error}</Text>}
        <div className="flex items-center justify-between">
          <div className="flex-1 text-center">
            <Text>Étape {step + 1}/5</Text>
          </div>
          <div onClick={nextStep}>
            <Button type="full">
              {isLoading ? <div className="loader" /> : "Suivant"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
