// Requires
import { useState } from "react";

// Components
import Button from "../../../UI/Button";
import Title from "../../../UI/Title";
import Text from "../../../UI/Text";
import Highlight from "../../../UI/Highlight";

// Stores
import { useUser } from "../../../../utils/store/userStore";

// Types
import { updateIdentity } from "../../../../services/User/user";
import { formatDate } from "../../../../utils/helpers/formatDate";

type Errors = {
  firstName?: string;
  lastName?: string;
  birthDate?: string;
};

const Identity = () => {
  const user = useUser((state) => state.userdata);

  const [data, setData] = useState<{
    firstName: string;
    lastName: string;
    gender: "male" | "female" | "unspecified";
    birth: string;
  }>({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    birth: formatDate(user?.birth) || "",
    gender: user?.gender || "unspecified"
  });

  const [error, setErrors] = useState<Errors>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setData((prev) => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const updateInformations = async () => {
    const newErrors: Errors = {};
    setErrors(newErrors);
    setIsLoading(true);

    if (data) {
      if (!data.firstName) {
        newErrors.firstName = "Tu dois indiquer ton prénom";
      }

      if (!data.lastName) {
        newErrors.lastName = "Tu dois indiquer ton nom de famille avec";
      }

      if (!data.birth) {
        newErrors.birthDate = "Tu dois indiquer ta date de naissance";
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        setIsLoading(false);
        return;
      }

      if (user) {
        const response = await updateIdentity(data, user?.roleName);
        if (response) {
          setIsLoading(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            useUser.getState().getUser();
          }, 2000);
        }
      }
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Identité</Title>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Text className="font-semibold">Nom</Text>
          {error?.lastName && (
            <Text className="text-sm text-red-500">{error.lastName}</Text>
          )}
          <input
            type="text"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            placeholder="Ton nom"
            name="lastName"
            onChange={handleInputChange}
            value={data?.lastName}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Text className="font-semibold">Prénom</Text>
          {error?.firstName && (
            <Text className="text-sm text-red-500">{error.firstName}</Text>
          )}
          <input
            type="text"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            placeholder="Ton prénom"
            name="firstName"
            onChange={handleInputChange}
            value={data?.firstName}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Text className="font-semibold">Date de naissance</Text>
          {error?.birthDate && (
            <Text className="text-sm text-red-500">{error.birthDate}</Text>
          )}
          <input
            type="date"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
            name="birth"
            onChange={handleInputChange}
            value={data?.birth}
          />
        </div>
        <div className="flex flex-col gap-1">
          <Text className="font-semibold">Genre</Text>
          <select
            onChange={handleInputChange}
            value={data?.gender}
            name="gender"
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            <option value={"unspecified"}>Non spécifié</option>
            <option value={"male"}>Homme</option>
            <option value={"female"}>Femme</option>
            <option value={"unspecified"}>Autre</option>
          </select>
        </div>
      </div>
      <Highlight type="Information">
        Ces informations ne sont communiquées à aucun tiers et sont utilisées
        uniquement dans le cadre de notre mission
      </Highlight>
      {success && (
        <Highlight type="Success">
          Les modifications ont étaient appliquées.
        </Highlight>
      )}

      {data.firstName !== user?.firstName ||
      data.lastName !== user.lastName ||
      formatDate(data.birth) !== formatDate(user?.birth) ||
      data.gender !== user.gender ? (
        <div
          onClick={updateInformations}
          className="flex flex-row items-end justify-end"
        >
          <Button type={"full"}>
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      ) : (
        <div className="flex flex-row items-end justify-end">
          <Button type={"disabled"}>Enregistrer</Button>
        </div>
      )}
    </div>
  );
};

export default Identity;
