// Icons
import { Routing2 } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import Title from "../UI/Title";
import Text from "../UI/Text";
import ExperienceCard from "../Register/ExperienceCard";

// Types
import { ExperienceType } from "../../types/user";

const Experience = ({ data }: { data?: ExperienceType[] }) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <Routing2 size={18} />
        <Title type="bloc">Expériences</Title>
      </BlocTitle>
      <Content>
        {data &&
          data.map((item, _i) => (
            <ExperienceCard key={_i} data={item} index={_i} />
          ))}
        {!data ||
          (data && data.length < 1 && (
            <Text>Aucune expériences pour le moment</Text>
          ))}
      </Content>
    </div>
  );
};

export default Experience;
