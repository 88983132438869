import { create } from "zustand";
import { persist } from "zustand/middleware";

// Services
import { loginUser, logoutUser } from "../../services/User/user";

// Types
import type { UserLogin } from "../../types/user";

type User = {
  isLogged: boolean;
};

type Store = {
  user: User | null;
  loginUser: (email: string, password: string) => Promise<boolean>;
  logoutUser: () => void;
  setIsLogged: (isLogged: boolean) => void; // Nouvelle méthode pour modifier isLogged
};

export const useAuth = create<Store>()(
  persist(
    (set) => ({
      user: null,
      loginUser: async (email, password) => {
        const data: UserLogin = {
          email: email,
          password: password
        };
        const response = await loginUser(data);

        if (!response) {
          return false;
        }

        set({
          user: { isLogged: true }
        });
        return true;
      },
      logoutUser: async () => {
        await logoutUser();
        set({ user: { isLogged: false } });
      },
      setIsLogged: (isLogged) => {
        set({ user: { isLogged } });
      }
    }),
    {
      name: "isLoggedState", // unique name for storage key
      getStorage: () => localStorage // (optional) by default 'localStorage' is used
    }
  )
);
