// Requies
import { Link, Outlet } from "react-router-dom";
import usePathname from "../../hooks/usePathname";
import { AnimatePresence, motion } from "framer-motion";

// Assets
import logo from "../../assets/logos/Juwa_logo_marine.svg";

// Components
import LinkedInPDF from "../../components/Popup/LinkedInPDF";
import Button from "../../components/UI/Button";
import CustomTooltip from "../../components/UI/Tooltip";

// Context
import { usePopup } from "../../utils/context/Popup";
import { usePopup as Popup } from "../../context/Popup";
import FullScreenPopup from "../../components/UI/FullScreenPopup";

const Layout = () => {
  const { pathname } = usePathname();
  const { isVisible, type } = usePopup();
  const { togglePopup } = Popup();

  return (
    <>
      <div className="h-full">
        <div className="sticky top-0 flex h-20 w-full items-center justify-between border-b bg-white p-4 shadow-md">
          <Link to={"/auth/login"}>
            <img src={logo} alt="Juwa Logo" className="w-[120px]" />
          </Link>
          <div className="flex flex-row gap-4">
            <CustomTooltip position="bottom" content="Signaler un problème">
              <div onClick={() => togglePopup("createTicket")}>
                <Button type="fullLite" className="!text-base !text-black">
                  ?
                </Button>
              </div>
            </CustomTooltip>
            {pathname.includes("auth/login") && (
              <Link to="/auth/register">
                <Button type="full">S'inscrire</Button>
              </Link>
            )}
            {pathname.includes("auth/register") && (
              <Link to="/auth/login">
                <Button type="full">Se connecter</Button>
              </Link>
            )}
          </div>
        </div>

        <div className="flex h-[calc(100vh-80px)] w-full flex-1 flex-col items-stretch justify-center">
          <Outlet />
        </div>
      </div>

      <AnimatePresence>
        {isVisible && (
          <motion.div
            key="voilerAnimate"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-0 top-0 flex h-screen w-full items-center justify-center bg-black bg-opacity-50"
          >
            {type === "linkedInExplenation" && (
              <motion.div
                key={type}
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 5 }}
              >
                <LinkedInPDF />
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <FullScreenPopup />
    </>
  );
};

export default Layout;
