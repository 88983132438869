import { Box, Document, Gallery } from "iconsax-react"; // Assurez-vous d'avoir importé les icônes nécessaires

export const _getIconForFileType = (fileType: string) => {
  // Extensions pour les images
  const imageExtensions = ["png", "jpg", "jpeg", "gif"];
  // Extensions pour les documents
  const documentExtensions = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx"
  ];

  // Vérifier les types MIME pour les images
  if (
    ["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(fileType) ||
    imageExtensions.some((ext) => fileType.endsWith(ext)) // Vérifie si l'extension correspond à une image
  ) {
    return <Gallery size={18} className="text-blueViolet-600" />;
  }

  // Vérifier les types MIME pour les documents
  if (
    [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ].includes(fileType) ||
    documentExtensions.some((ext) => fileType.endsWith(ext)) // Vérifie si l'extension correspond à un document
  ) {
    return <Document size={18} className="text-blueViolet-600" />;
  }

  // Pour tout autre type de fichier, utiliser l'icône Box
  return <Box size={18} className="text-blueViolet-600" />;
};
