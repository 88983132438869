// Requires
import { AnimatePresence, motion } from "framer-motion";

// Components
import Button from "../../UI/Button";
import Text from "../../UI/Text";

interface NewAlternanceProps {
  handleAlternationChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error: {
    alternationError?: {
      start?: string;
      end?: string;
      description?: string;
    };
  };
  setAlternationFormIsVisible: (visible: boolean) => void;
  addAlternation: () => void;
  alternation: {
    description: string;
  };
}

export const NewAlternance: React.FC<NewAlternanceProps> = ({
  handleAlternationChange,
  error,
  setAlternationFormIsVisible,
  addAlternation,
  alternation
}) => {
  return (
    <AnimatePresence>
      <motion.div
        key="aternationAnimate"
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 5 }}
        className="flex flex-col gap-4 rounded-lg p-4 md:border"
      >
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="flex flex-col gap-2">
            <label>Date de début</label>
            <input
              onChange={handleAlternationChange}
              type="date"
              name="start"
              id=""
              className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              placeholder="JJ/MM/AA"
            />
            {error.alternationError?.start && (
              <Text className="text-sm text-red-500">
                {error.alternationError.start}
              </Text>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label>Date de fin</label>
            <input
              onChange={handleAlternationChange}
              type="date"
              name="end"
              id=""
              className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              placeholder="JJ/MM/AA"
            />
            {error.alternationError?.end && (
              <Text className="text-sm text-red-500">
                {error.alternationError.end}
              </Text>
            )}
          </div>
        </div>
        <textarea
          onChange={handleAlternationChange}
          name="description"
          className="h-fit rounded-lg border p-2 focus:outline-blueViolet-600"
          placeholder="Description"
        />
        <div className="flex w-full flex-row items-end justify-between">
          <Text className="text-sm text-red-500">
            {error.alternationError?.description}
          </Text>
          <Text className="text-sm">
            {alternation.description.length}/500 caractères
          </Text>
        </div>
        <div className="flex flex-row justify-end gap-2">
          <div onClick={() => setAlternationFormIsVisible(false)}>
            <Button type="cancel">Annuler</Button>
          </div>
          <div onClick={addAlternation}>
            <Button type="full">Ajouter</Button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
