// Icons
import { CloseSquare, ImportSquare } from "iconsax-react";

// Requires
import { useRef, useState } from "react";

// Assets
import Explenation from "../../assets/linkedInExplenation.png";

// Services
import { sendOnboardingPdf } from "../../services/User/user";

// Utils
import { usePopup } from "../../utils/context/Popup";
import { useUI } from "../../utils/context/UI";

// Helpers
import { setLocalStorage } from "../../utils/helpers/localStorage";

// Components
import Button from "../UI/Button";
import Text from "../UI/Text";
import Title from "../UI/Title";

const LinkedInPDF = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { handleNextStep } = useUI();
  const { toggleVisibility } = usePopup();
  const [error, setError] = useState<string>();

  const handleFile = () => {
    inputRef.current?.click();
  };

  const sendFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = e.target.files?.[0];

    if (file) {
      const data = new FormData();
      data.append("attachment", file);
      const response = await sendOnboardingPdf(data);

      if (response) {
        const localData = [
          { key: "experiences", value: JSON.stringify(response.experiences) },
          { key: "educations", value: JSON.stringify(response.educations) },
          { key: "portfolios", value: JSON.stringify(response.portfolios) },
          { key: "custom", value: JSON.stringify(response.custom) },
          {
            key: "certifications",
            value: JSON.stringify(response.certifications)
          },
          { key: "studentStep", value: "5" }
        ];
        setLocalStorage(localData);
        toggleVisibility(undefined);
        handleNextStep();
      }

      if (!response) {
        setError(
          "Une erreur s'est produite, tu peux de nouveau envoyer le fichier"
        );
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border bg-white p-4 shadow-md">
      <div className="flex flex-row items-center justify-between gap-16">
        <Title type="bloc">
          Importe tes informations depuis ton profil Linkedin ou ton CV
        </Title>
        <CloseSquare
          size={18}
          className="cursor-pointer"
          onClick={() => toggleVisibility(undefined)}
        />
      </div>
      <div className="flex flex-col gap-4 rounded-lg bg-blueViolet-50 p-2">
        <div className="flex flex-col gap-1">
          <Text className="font-semibold text-blueViolet-600">
            Télécharge ton fichier depuis ton profil LinkedIn{" "}
            <span className="underline">LinkedIn</span>
          </Text>
          <ul className="px-4 text-blueViolet-600">
            <li>Depuis ton profil, clique sur "Plus"</li>
            <li>Clique ensuite sur "Enregistrer au format PDF"</li>
          </ul>
        </div>
        <img
          src={Explenation}
          alt="LinkedIn PDF"
          className="w-full rounded-lg"
        />
      </div>
      {error && <Text className="text-sm text-red-500">{error}</Text>}
      <div
        className="flex flex-row items-center gap-4 rounded-lg border p-2"
        onClick={handleFile}
      >
        {!isLoading && (
          <>
            <div className="flex h-14 w-14 items-center justify-center rounded-lg bg-blueViolet-50">
              <ImportSquare size={18} />
            </div>
            <Text>Glisser-Déposer le PDF ou</Text>
            <Button type="fullLite">Parcourir l'ordinateur</Button>
            <input
              onChange={sendFile}
              type="file"
              accept=".pdf"
              className="hidden"
              ref={inputRef}
            />
          </>
        )}
        {isLoading && (
          <div className="flex w-full items-center justify-center">
            <div className="loader" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkedInPDF;
