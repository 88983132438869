// Components
import Content from "../../Bloc/Content";
import { BlocTitle } from "../../Bloc/Title";
import { HorizontalDivider } from "../../UI/Divider";
import Title from "../../UI/Title";
import Text from "../../UI/Text";

// Types
import { Proposal } from "../../../types/proposal";

const Resume = ({ data }: { data: Proposal }) => {
  const total = data.quotation
    ? data.quotation.reduce((acc, item) => acc + item.price * item.quantity, 0)
    : 0;

  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg md:max-w-[350px]">
      <BlocTitle>
        <Title type="bloc">Récapitulatif de la proposition</Title>
      </BlocTitle>
      <Text className="text-sm">
        Le montant est indiqué à titre informatif et peu varier selon la date de
        lancement
      </Text>
      <Content>
        <div className="flex flex-col gap-3 rounded-lg bg-blueViolet-50 p-4">
          {data.quotation &&
            data.quotation.map((item, _i) => (
              <div key={_i} className="flex flex-col">
                <div className="flex flex-row justify-between">
                  <Text className="font-semibold">Ligne {_i + 1}</Text>
                  <Text className="font-semibold">
                    {item.price * item.quantity}&euro;
                  </Text>
                </div>
                {_i < data.quotation.length - 1 && (
                  <HorizontalDivider className="bg-withCustom-200" />
                )}
              </div>
            ))}
          <HorizontalDivider className="bg-withCustom-200" />
          <div className="flex flex-row justify-between">
            <Text className="font-semibold text-blueViolet-600">
              TOTAL (HT)
            </Text>
            <Text className="font-semibold text-blueViolet-600">
              {total}&euro;
            </Text>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default Resume;
