import Essentials from "./NotificationSettings/Essentiaals";
import MessagesAndFiles from "./NotificationSettings/MessagesAndFiles";
import Missions from "./NotificationSettings/Missions";

const NotificationSettings = () => {
  return (
    <>
      <MessagesAndFiles />
      <Missions />
      <Essentials />
    </>
  );
};

export default NotificationSettings;
