// Icons
import { Edit } from "iconsax-react";

// Helpers
import { capitalizeFirstLetter } from "../../../../utils/helpers/capitalizeFirstLetter";

// Components
import Date from "../../../UI/Date";
import Text from "../../../UI/Text";

// Types
import type { JobCreateType } from "../../../../types/job";

const Terms = ({
  data,
  edit
}: {
  data: JobCreateType;
  edit: (id: number) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <Text className="text-xl font-semibold">Condition de mission</Text>
      <div className="flex flex-row items-center gap-4 md:justify-between">
        <div className="flex flex-1 flex-col gap-8 rounded-lg bg-blueViolet-50 p-4 md:flex-none md:flex-row">
          <div className="flex flex-col gap-2">
            <Text className="font-semibold">Date de début</Text>
            {data?.startingType === "date" ? (
              <Date
                date={data.startingAt ? data.startingAt : ""}
                format="DD/MM/YY"
              />
            ) : data.startingType === "asap" ? (
              <Text>Dès que possible</Text>
            ) : (
              <Text>Non spécifié</Text>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <Text className="font-semibold">Type de mission</Text>
            {data?.type && (
              <Text>
                {capitalizeFirstLetter(
                  data.type === "punctual" ? "ponctuel" : "récurrente"
                )}
              </Text>
            )}
          </div>
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          onClick={() => edit(3)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
      <div className="flex flex-row items-center gap-4 md:justify-between">
        <div className="flex flex-1 flex-col gap-4 rounded-lg bg-blueViolet-50 p-4 md:flex-none">
          <Text className="font-semibold">Disponibilités spécifiques</Text>
          <div className="flex flex-row flex-wrap gap-4 md:flex-nowrap">
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white p-1 text-sm">
              L
            </div>
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white p-1 text-sm">
              M
            </div>
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white p-1 text-sm">
              Me
            </div>
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white p-1 text-sm">
              J
            </div>
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white p-1 text-sm">
              V
            </div>
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white p-1 text-sm">
              S
            </div>
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white p-1 text-sm">
              D
            </div>
          </div>
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          onClick={() => edit(4)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
      <div className="flex flex-row items-center gap-4 md:justify-between">
        <div className="flex flex-1 flex-col gap-8 rounded-lg bg-blueViolet-50 p-4 md:flex-none md:flex-row">
          <div className="flex flex-col gap-2">
            <Text className="font-semibold">Taux horaire</Text>
            <Text>{data?.rate}&euro;</Text>
          </div>
          <div className="flex flex-col gap-2">
            <Text className="font-semibold">Nombre d'heures estimé </Text>
            <Text className="text-wrap">
              {data?.duration} heures
              {data?.type !== "punctual" && "/semaine"}
            </Text>
          </div>
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          onClick={() => edit(4)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
      <div className="flex flex-row items-center gap-4 md:justify-between">
        <div className="flex flex-1 flex-row gap-8 rounded-lg bg-blueViolet-50 p-4 md:flex-none">
          <div className="flex flex-col">
            <Text className="font-semibold">Modalité de travail</Text>
            <Text>
              {data?.workMode === "remote"
                ? "Télétravail"
                : data?.workMode === "onSite"
                  ? "Sur site"
                  : "Hybride"}
            </Text>
          </div>
          {data?.workMode === "hybrid" && (
            <div className="flex flex-col gap-2">
              <Text className="font-semibold">Fréquence de télétravail</Text>
              <Text>{data.workingFrequency} jours par semaine</Text>
            </div>
          )}
          {data?.workMode !== "remote" && (
            <div className="flex flex-col">
              <Text className="font-semibold">Localisation de la mission</Text>
              <Text>
                {data.location && capitalizeFirstLetter(data.location)}
              </Text>
            </div>
          )}
        </div>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          onClick={() => edit(4)}
          className="cursor-pointer rounded-lg p-2 hover:bg-blueViolet-50"
        >
          <Edit size={18} />
        </div>
      </div>
    </div>
  );
};

export default Terms;
