// Requires
import { useState } from "react";

// Icons
import {
  ArrowUp2,
  Briefcase,
  Building4,
  Heart,
  Home,
  InfoCircle,
  LogoutCurve,
  Messages1,
  SearchNormal,
  Setting2
} from "iconsax-react";

// Hooks
import usePathname from "../../../hooks/usePathname";

// Components
import { HorizontalDivider } from "../../UI/Divider";
import URL from "../../UI/URL";

// Type
type LinksType = {
  name: string;
  path?: string;
  icon?: React.ReactElement;
  sub?: LinksType[];
  extern?: boolean;
};

const StudentMenu = ({ isOpen }: { isOpen: boolean }) => {
  const { pathname } = usePathname();

  const [subMenuIsOpen, setSubMenuIsOpen] = useState<boolean>(false);

  const size: number = 16;

  const MainLinks: LinksType[] = [
    {
      name: "Dashboard",
      path: "/Dashboard",
      icon: (
        <Home
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Messagerie",
      path: "/messages",
      icon: (
        <Messages1
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Trouver une mission",
      path: "/student/jobs",
      icon: (
        <SearchNormal
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Missions",
      icon: (
        <Briefcase
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      ),
      sub: [
        {
          name: "Tes candidatures",
          path: "/student/jobs/candidatures"
        },
        {
          name: "Pour toi",
          path: "/student/jobs/forme"
        },
        {
          name: "En cours",
          path: "/student/jobs/inprogress"
        }
      ]
    },
    {
      name: "Favoris",
      path: "/student/jobs/favoris",
      icon: (
        <Heart
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    }
  ];

  const SecondaryLinks: LinksType[] = [
    {
      name: "Ressources",
      path: "https://juwa.co/blog",
      extern: true,
      icon: (
        <Building4
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Mon compte",
      path: "/student/profil/edit",
      icon: (
        <Setting2
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    }
  ];

  const OthersLinks: LinksType[] = [
    {
      name: "Déconnexion",
      path: "/auth/logout",
      icon: (
        <LogoutCurve
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    },
    {
      name: "Besoin d'aide ?",
      path: "https://juwa.co/help",
      extern: true,
      icon: (
        <InfoCircle
          size={size}
          className="max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px]"
        />
      )
    }
  ];

  const handleIsOpen = () => {
    setSubMenuIsOpen(!subMenuIsOpen);
  };

  return (
    <div className="flex grow flex-col justify-between">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-6 overflow-hidden">
          <ul className="flex flex-col gap-4 overflow-hidden">
            {MainLinks.map((item) => (
              <li key={item.name} className="cursor-pointer">
                <URL
                  target={item.path}
                  sub={item.sub}
                  classname={`flex group-hover:text-primaryPurple flex-row flex-nowrap items-center p-2 ${pathname === item.path && "bg-backgroundGray rounded-lg"}`}
                  style={{
                    gap: isOpen ? 24 : 0,
                    justifyContent: isOpen ? "space-between" : "center"
                  }}
                  callback={handleIsOpen}
                >
                  <div className="flex flex-row gap-6">
                    {item.icon}
                    {isOpen && <span className="text-nowrap">{item.name}</span>}
                  </div>
                  {item.sub && isOpen && (
                    <ArrowUp2
                      size={18}
                      className={
                        !subMenuIsOpen
                          ? "rotate-180 duration-100"
                          : "rotate-0 duration-100"
                      }
                    />
                  )}
                </URL>
              </li>
            ))}
          </ul>
        </div>
        <HorizontalDivider />
        <div className="flex flex-col gap-6 overflow-hidden">
          <ul className="flex flex-col gap-4 overflow-hidden">
            {SecondaryLinks.map((item) => (
              <li key={item.name}>
                <URL
                  target={item.path}
                  sub={item.sub}
                  extern={item.extern}
                  classname={`flex group-hover:text-primaryPurple flex-row flex-nowrap items-center p-2 ${pathname === item.path && "bg-backgroundGray rounded-lg"}`}
                  style={{
                    gap: isOpen ? 24 : 0,
                    justifyContent: isOpen ? "start" : "center"
                  }}
                >
                  {item.icon}
                  {isOpen && <span className="text-nowrap">{item.name}</span>}
                </URL>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <ul className="flex flex-col gap-4 overflow-hidden">
          {OthersLinks.map((item) => (
            <li key={item.name}>
              <URL
                target={item.path}
                sub={item.sub}
                extern={item.extern}
                classname="flex flex-row flex-nowrap items-center"
                style={{
                  gap: isOpen ? 24 : 0,
                  justifyContent: isOpen ? "start" : "center"
                }}
              >
                {item.icon}
                {isOpen && <span className="text-nowrap">{item.name}</span>}
              </URL>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StudentMenu;
