// Requires
import { useState } from "react";

// Components
import Button from "../../../UI/Button";
import Highlight from "../../../UI/Highlight";
import Title from "../../../UI/Title";
import SchoolCertificateFileView from "./SchoolCertificateFileView";
import UploadFile from "../../../UI/File/UploadFile";

// Services
import { updateSchoolCertificate } from "../../../../services/User/user";

// Utils
import { useUser } from "../../../../utils/store/userStore";

const ScolarityCertificate = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [file, setFile] = useState<File | null>();

  const handleFileChange = (data: File) => {
    if (data) {
      setFile(data);
    }
  };

  const update = async () => {
    const data = new FormData();
    if (file) {
      setIsLoading(true);
      // data.append("certificateExpirationDate", "2022-12-31");
      data.append("attachment", file);
      const response = await updateSchoolCertificate(data);

      if (response) {
        setSuccess(true);
        setIsLoading(false);
        useUser.getState().getUser();

        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Certificat de scolarité</Title>
      <div className="flex flex-col gap-2">
        <UploadFile callback={handleFileChange} />
        {!file && <SchoolCertificateFileView />}
      </div>
      {success && (
        <Highlight type={"Success"}>
          Les modifications ont bien été enregistré
        </Highlight>
      )}
      <div onClick={update} className="flex flex-row justify-end">
        <Button type="full">
          {isLoading ? <div className="loader" /> : "Enregistrer"}
        </Button>
      </div>
    </div>
  );
};

export default ScolarityCertificate;
