// Requires
import { useRef, useState } from "react";

// Components
import Text from "../UI/Text";
import Title from "../UI/Title";

// Icons
import { Additem } from "iconsax-react";

// Stores
import { useUser } from "../../utils/store/userStore";

// Notification
import { enqueueSnackbar } from "notistack";

// Define a constant for the maximum file size (20MB)
const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

const BannerChanger = ({
  callback,
  companyMode
}: {
  callback: (image: File) => void;
  companyMode?: boolean;
}) => {
  const user = useUser((state) => state.userdata);

  const bannerInputRef = useRef<HTMLInputElement>(null);
  const [bannerPreview, setBannerPreview] = useState<string>(
    companyMode ? (user?.company?.banner ?? "") : (user?.banner ?? "")
  );

  const handleBannerDiv = () => {
    bannerInputRef.current?.click();
  };

  const handleFileBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        enqueueSnackbar("Taille maximale de 20 Mo dépassée.", {
          variant: "warning"
        });
        return;
      }

      callback(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-2">
          <Title type="page">Bannière</Title>
        </div>
        <Text className="text-sm">
          Nous te conseillons d’ajouter une bannière qui mette en valeur tes
          compétences
        </Text>
      </div>
      <div className="relative flex h-44 w-full items-center justify-center rounded-lg border bg-blueViolet-50">
        {bannerPreview && (
          <img
            src={bannerPreview}
            alt="Bannière"
            className="h-44 w-full rounded-lg object-cover object-center"
          />
        )}
        <div
          onClick={handleBannerDiv}
          className="absolute bottom-auto left-auto right-auto top-auto cursor-pointer rounded-lg bg-blueViolet-200 bg-opacity-70 p-2"
        >
          <Additem size={18} />
        </div>
        <input
          ref={bannerInputRef}
          onChange={handleFileBannerChange}
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          className="hidden"
        />
      </div>
    </div>
  );
};

export default BannerChanger;
