// components/User/AccountDeletion/Deletion.tsx

import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import Button from "../../../UI/Button";
import Text from "../../../UI/Text";
import Title from "../../../UI/Title";
import Modal from "../../../Jobs/Proposal/Modal"; // Ensure correct import path

// Service
import { accountDeletion } from "../../../../services/User/user";
import { useUser } from "../../../../utils/store/userStore";

const Deletion = () => {
  const user = useUser((state) => state.userdata);
  const navigate = useNavigate();

  // State to manage modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // Optional: to handle loading state

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const deleteAccount = async () => {
    if (user && user.roleName) {
      setIsDeleting(true); // Optional: start loading
      try {
        const response = await accountDeletion(user.roleName);

        if (response) {
          navigate("/auth/logout");
        } else {
          // Handle unsuccessful deletion if necessary
          console.error("Account deletion failed.");
        }
      } catch (error) {
        console.error("An error occurred during account deletion:", error);
      } finally {
        setIsDeleting(false); // Optional: stop loading
        closeModal();
      }
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-col">
        <Title type="bloc">Suppression du compte</Title>
        <Text className="text-sm">
          Lors de la suppression de votre compte, les données de votre profil ne
          seront plus disponibles et les informations que vous avez postées
          seront anonymisées. Les propositions envoyées ainsi que les messages
          échangés seront archivés.
        </Text>
      </div>
      <div>
        <Button type="fullLite" onClick={openModal}>
          Je souhaite supprimer mon compte
        </Button>
      </div>

      {/* Confirmation Modal */}
      <Modal maxSize={false} isOpen={isModalOpen} onClose={closeModal}>
        <div className="flex w-[350px] flex-col gap-4">
          <Title type="bloc">Confirmer la suppression</Title>
          <Text className="text-sm">
            Êtes-vous sûr de vouloir supprimer votre compte ?
          </Text>
          <Text className="text-sm">Cette action est irréversible.</Text>
          <div className="flex justify-end gap-2">
            <Button type="outline" onClick={closeModal}>
              Annuler
            </Button>
            <Button
              type={isDeleting ? "disabled" : "fullLite"}
              onClick={deleteAccount}
            >
              {isDeleting ? "Suppression..." : "Confirmer"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Deletion;
