/* eslint-disable no-console */
// Requires
import { createContext, useContext } from "react";

// Components
import type { ConversationType, Message } from "../../types/chat";
import { FileType } from "../../types/file";

// Define the chat interface
interface ChatInterface {
  conversations: ConversationType[] | undefined;
  // setConversations: React.Dispatch<
  //   React.SetStateAction<ConversationType[] | undefined>
  // >;
  userId: number | undefined;
  // setUserId: React.Dispatch<React.SetStateAction<number | undefined>>;
  activeUsers: { users: number[] };
  // setActiveUsers: React.Dispatch<React.SetStateAction<{ users: number[] }>>;
  fetchConversations: () => void;
  messages: Messages | undefined;
  isExpertMode: boolean;
  setIsExpertMode: React.Dispatch<React.SetStateAction<boolean>>;

  sendMessage: (content: string, files: any[]) => void;
  createConversation: (
    targetId: number,
    jobId: number,
    content: string,
    files: any[]
  ) => Promise<any>;

  activeConversation: ConversationType | undefined;
  setActiveConversation: React.Dispatch<
    React.SetStateAction<ConversationType | undefined>
  >;
  loadMessagesBeforeId: (
    limit?: number,
    before_id?: number,
    switchedConversation?: boolean
  ) => void;
  page: number;
  documents: FileType[];
  newDocs: FileType[];
  setNewDocs: React.Dispatch<React.SetStateAction<FileType[]>>;
  isFetching: boolean;
}

export type FileData = {
  fileName: string;
  fileContent: string; // Contenu du fichier encodé en base64
  fileType: string; // Stocker le type de fichier ici
};

export type Messages = {
  messages: Message[];
  shouldAutoScroll: boolean;
};

// Create the context with a default value of null
export const ChatContext = createContext<ChatInterface | null>(null);

// Custom hook to use the chat context
export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
