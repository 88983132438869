// Components
import Text from "../UI/Text";
import MessageDate from "./MessageDate";

// Types
import { ConversationType } from "../../types/chat";
import Avatar from "../UI/Avatar/Avatar";

const UserCardLastMessage = ({ item }: { item: ConversationType }) => {
  return (
    <div className="group flex grow cursor-pointer flex-row items-center">
      <div className="flex w-full flex-row gap-2">
        <Avatar
          link={item.target.image}
          className="h-[32px] min-h-[32px] w-[32px] min-w-[32px]"
        />
        <div className="flex w-full flex-row items-center justify-between gap-2">
          <div className="flex flex-col">
            <Text className="text-textGray group-hover:text-blueViolet-600">
              {item.target.firstName} {item.target.lastName}
            </Text>
            <Text className="line-clamp-3 max-w-full overflow-hidden text-ellipsis !text-xs text-textGray group-hover:text-blueViolet-600">
              {item.lastMessage.content?.message ?? ""}
            </Text>
          </div>
          <Text className="text-textGray group-hover:text-blueViolet-600">
            <MessageDate createdAt={item.lastMessage.createdAt} />
          </Text>
        </div>
      </div>
    </div>
  );
};

export default UserCardLastMessage;
