// Requires
import { useState } from "react";

// Icons
import { InfoCircle } from "iconsax-react";

// Components
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import CustomTooltip from "../../../../../../components/UI/Tooltip";
import { SiretSearchComponent } from "../../../../../../components/Misc/SiretSearchComponent";
import NextPrevious from "../../../../../../components/NextPrevious";
import UploadFile from "../../../../../../components/UI/File/UploadFile";

// Services
import {
  updateSchoolCertificate,
  updateStudentCompany
} from "../../../../../../services/User/user";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Utils
import { setLocalStorage } from "../../../../../../utils/helpers/localStorage";

// Types
import type { CompanyInformationsType } from "../../../../../../types/company";

const Eleven = () => {
  const { step, handleNextStep } = useUI();

  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyInformations, setCompanyInformations] =
    useState<CompanyInformationsType>();
  const [siretSelected, setSiretSelected] = useState<string>();

  const handleFileCallback = (data: File) => {
    setFile(data);
  };

  const nextStep = async () => {
    setIsLoading(true);

    if (file || siretSelected) {
      try {
        const data = new FormData();
        let response;
        let response2;

        if (file) {
          data.append("attachment", file);
          response = await updateSchoolCertificate(data);
        }

        if (siretSelected) {
          response2 = await updateStudentCompany(siretSelected);
        }

        if (response || response2) {
          const localData = [{ key: "studentStep", value: "11" }];
          setLocalStorage(localData);
          handleNextStep();
        } else {
          console.error("One or more updates failed.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("An error occurred during the updates:", error);
        setIsLoading(false);
      }
    } else {
      const localData = [{ key: "studentStep", value: "11" }];
      setLocalStorage(localData);
      handleNextStep();
    }
  };

  return (
    <div className="w-full overflow-scroll md:p-8">
      <div className="flex items-center justify-center">
        <div className="flex min-w-[50%] flex-col gap-16 md:max-w-[50%]">
          <div className="flex items-center justify-center">
            <Title type="page">
              Cette partie est moins fun mais c'est l'une des plus importantes
            </Title>
          </div>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center gap-1">
                  <Title type="page">Certificat de scolarité</Title>
                </div>
                <Text>
                  Pour utiliser Juwa, tu dois justifier ton statut étudiant
                </Text>
              </div>
              <UploadFile callback={handleFileCallback} />
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center gap-1">
                  <Title type="page">SIRET</Title>
                  <CustomTooltip
                    position="right"
                    content="Trouve ton numéro de SIRET via la recherche pour compléter toutes tes infos légales. Si tu ne le trouves pas, tu devras les rentrer au moment de postuler à une mission."
                  >
                    <InfoCircle size={18} />
                  </CustomTooltip>
                </div>
                <Text>Justifie ton statut en entrant ton numéro de SIRET</Text>
              </div>

              <SiretSearchComponent
                companyInformations={companyInformations}
                setCompanyInformations={setCompanyInformations}
                siretSelected={siretSelected}
                setSiretSelected={setSiretSelected}
              />
            </div>
          </div>
          <NextPrevious step={step} nextStep={nextStep} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default Eleven;
