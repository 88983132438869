// Components
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Icons
import { Additem } from "iconsax-react";

// Requires
import { useEffect, useRef, useState } from "react";

// Services
import { fetchApi } from "../../../../../../services/Misc/api";

// Context
import { getUser } from "../../../../../../services/User/user";
import { useUI } from "../../../../../../utils/context/UI";
import { setLocalStorage } from "../../../../../../utils/helpers/localStorage";

// Rich text editor
import type { Sources } from "quill";
import type ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { enqueueSnackbar, SnackbarProvider } from "notistack";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],

  [{ list: "ordered" }, { list: "bullet" }],

  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"] // remove formatting button
];

// Define a constant for the maximum file size (20MB)
const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

const Ten = () => {
  const { step, handleNextStep } = useUI();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const imageInputRef = useRef<HTMLInputElement>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const [bannerPreview, setBannerPreview] = useState<string | null>(null);
  const [bannerFile, setBannerFile] = useState<File | null>(null);

  const quillEditor = useRef<ReactQuill>(null);

  const [presentation, setPresentation] = useState<string>("");

  const handleImageDiv = () => {
    imageInputRef.current?.click();
  };

  const handleBannerDiv = () => {
    bannerInputRef.current?.click();
  };

  // Picture Profile Change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        enqueueSnackbar("Taille maximale de 20 Mo dépassée.", {
          variant: "warning"
        });
        return;
      }

      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Banner Change
  const handleFileBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        enqueueSnackbar("Taille maximale de 20 Mo dépassée.", {
          variant: "warning"
        });
        return;
      }

      setBannerFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Quill character limit
  const limitCharacters = (
    value: string,
    delta: any,
    source: Sources,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    // If the content is within the limit, update the state
    if (editor.getLength() <= 2000) {
      setPresentation(value);
    } else {
      quillEditor.current
        ?.getEditor()
        .deleteText(2000, editor.getLength() - 2000);
      setPresentation(value.slice(0, 2000));
    }
  };

  const nextStep = async () => {
    setError("");
    setIsLoading(true);

    if (!presentation || presentation.length < 200) {
      setError("Le contenu doit comprendre au minimum 200 caractères.");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("presentation", presentation);
    if (imageFile) formData.append("image", imageFile);
    if (bannerFile) formData.append("banner", bannerFile);

    try {
      const response = await fetchApi("/user/profile", "POST", formData);
      if (response) {
        const localData = [{ key: "studentStep", value: "10" }];
        setLocalStorage(localData);
        handleNextStep();
      } else {
        setError("Une erreur s'est produite lors de la mise à jour du profil.");
      }
    } catch (err) {
      console.error("Erreur lors de la mise à jour du profil:", err);
      setError("Une erreur inattendue s'est produite.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getUser();
      if (response) setImagePreview(response.image);
    };

    fetch();
  }, []);

  return (
    <div className="w-full p-4 md:p-4">
      <SnackbarProvider />
      <div className="flex items-center justify-center">
        <div className="flex min-w-[50%] flex-col gap-24 md:max-w-[50%] md:p-0 md:p-5">
          {/* Loading Bar */}
          {isLoading && (
            <div className="mb-4 w-full">
              <div className="h-2.5 w-full rounded-full bg-gray-200">
                <div className="h-2.5 animate-pulse rounded-full bg-blueViolet-600"></div>
              </div>
            </div>
          )}

          <div className="flex items-center justify-center">
            <Title type="page">Personnalise ton profil</Title>
          </div>
          <div className="flex flex-col gap-16">
            {/* Photo de profil */}
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center gap-2">
                  <Title type="page">Photo de profil</Title>
                  <Text className="text-xs">- Recommandé</Text>
                </div>
                <Text>
                  Nous te conseillons d'ajouter une photo professionnelle
                </Text>
              </div>
              <div className="relative flex h-28 w-28 items-center justify-center rounded-lg border bg-blueViolet-50">
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Photo de profil"
                    className="h-28 w-28 rounded-lg object-cover object-center"
                  />
                )}
                <div
                  onClick={handleImageDiv}
                  className="absolute cursor-pointer rounded-lg bg-blueViolet-200 bg-opacity-70 p-2"
                >
                  <Additem size={18} />
                </div>
                <input
                  onChange={handleFileChange}
                  type="file"
                  className="hidden"
                  ref={imageInputRef}
                  accept="image/png, image/jpg, image/jpeg"
                />
              </div>
            </div>

            {/* Bannière */}
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center gap-2">
                  <Title type="page">Bannière</Title>
                  <Text className="text-xs">- Recommandé</Text>
                </div>
                <Text>
                  Nous te conseillons d’ajouter une bannière qui mette en valeur
                  tes compétences
                </Text>
              </div>
              <div className="relative flex h-44 w-full items-center justify-center rounded-lg border bg-blueViolet-50">
                {bannerPreview && (
                  <img
                    src={bannerPreview}
                    alt="Bannière"
                    className="h-44 w-full rounded-lg object-cover object-center"
                  />
                )}
                <div
                  onClick={handleBannerDiv}
                  className="absolute cursor-pointer rounded-lg bg-blueViolet-200 bg-opacity-70 p-2"
                >
                  <Additem size={18} />
                </div>
                <input
                  ref={bannerInputRef}
                  onChange={handleFileBannerChange}
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  className="hidden"
                />
              </div>
            </div>

            {/* Présentation */}
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center gap-2">
                  <Title type="page">
                    Présentation<span style={{ color: "red" }}> * </span>
                  </Title>
                </div>
                <Text>
                  Présente-toi de manière engageante pour montrer aux
                  entreprises ta personnalité, ton expertise et leur faire
                  comprendre pourquoi ils devraient faire appel à toi
                </Text>
              </div>
              <textarea
                maxLength={2000}
                placeholder="Présente toi le mieux possible"
                value={presentation}
                onChange={(e) => setPresentation(e.currentTarget.value)}
                className="textareaautoresize min-h-28 w-full resize-none rounded-lg border p-2 focus:outline-blueViolet-600"
              />
              <div className="flex flex-row items-end justify-between">
                <div>
                  {error && <Text className="text-red-500">{error}</Text>}
                </div>
                <Text className="text-sm">
                  {presentation?.length}/2000 caractères
                </Text>
              </div>
            </div>
          </div>

          {/* Navigation */}
          <div className="flex items-center justify-between">
            <div className="flex-1 text-center">
              <Text>Étape {step}/14</Text>
            </div>
            <div onClick={nextStep}>
              <Button type="full">
                {isLoading ? <div className="loader" /> : "Suivant"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ten;
