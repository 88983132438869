import { fetchApi } from "../Misc/api";

type Ticket = {
  title: string;
  content: string;
  type: string;
};

// Create a new ticket
export const createTicket = async (data: Ticket) => {
  const response = await fetchApi("/ticket", "POST", data);

  if (response.error) return false;

  return true;
};
