// Icons
import { Warning2 } from "iconsax-react";

// Components
import Text from "../../UI/Text";
import Title from "../../UI/Title";
import Button from "../../UI/Button";
import { usePopup } from "../../../context/Popup";
import { useEffect, useState } from "react";
import { useUser } from "../../../utils/store/userStore";
import { getLemonwayAccountId } from "../../../services/User/user";

const BankTransfert = () => {
  // PAYMENT INFORMATIONS
  const accountName: string = "Lemonway SAS";
  const ibanNumber: string = "FR76 3000 0000 0000 0000 0185";
  const bicNumber: string = "BNPAFRPP";

  const [lemonwayId, setLemonwayId] = useState<{
    lemonwayId: number | undefined;
  }>({
    lemonwayId: undefined
  });

  const { togglePopup } = usePopup();

  const userId = useUser((state) => state.userdata?.id);

  const handleSubmit = () => {
    togglePopup("bankTransfertConfirmation");
  };

  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        const response = await getLemonwayAccountId(userId);
        setLemonwayId(response);
      }
    };

    fetch();
  }, [userId]);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
        <Title type="bloc">Transfert de fonds</Title>
        <Text>Virez le montant suivant depuis votre banque : MONTANT</Text>

        <div className="flex w-fit flex-col gap-2 rounded-lg bg-blueViolet-50 p-4">
          <div className="flex flex-row gap-2">
            <Text className="font-semibold">Bénéficiaire :</Text>
            <Text>{accountName}</Text>
          </div>
          <div className="flex flex-row gap-2">
            <Text className="font-semibold">IBAN :</Text>
            <Text>{ibanNumber}</Text>
          </div>
          <div className="flex flex-row gap-2">
            <Text className="font-semibold">BIC :</Text>
            <Text>{bicNumber}</Text>
          </div>
          <div className="flex flex-row gap-2">
            <Text className="font-semibold">Description :</Text>
            <Text>JUWA - {lemonwayId.lemonwayId}</Text>
          </div>
        </div>
        <div className="flex flex-row items-center gap-1 text-red-500">
          <Warning2 size={18} />
          <Text className="!text-red-500">
            Vous devez impérativement ajouter la bonne description pour que
            votre virement soit bien réceptionné.
          </Text>
        </div>
      </div>
      <div className="flex items-end justify-end">
        <div onClick={handleSubmit}>
          <Button type="full">Suivant</Button>
        </div>
      </div>
    </div>
  );
};

export default BankTransfert;
