// Requires
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

// Icons
import { Location, Star } from "iconsax-react";

// Types
import type { StudentType } from "../../../types/user";

// Components
import Text from "../../UI/Text";
import Avatar from "../../UI/Avatar/Avatar";

// Utils
import { Desktop, Mobile, useMobile } from "../../../utils/mobile/responsive";
import { capitalizeFirstLetter } from "../../../utils/helpers/capitalizeFirstLetter";

// eslint-disable-next-line react/display-name
const StudentVerticalCard = React.memo(
  ({ width, item }: { width: boolean; item: StudentType }) => {
    const size: string = width ? "calc(33.33% - 22px)" : "calc(25% - 25px)";
    const duration: number = 0.2;

    return (
      <>
        <Desktop>
          <motion.div
            initial={{ minWidth: size, maxWidth: useMobile() ? -1 : size }}
            animate={{ minWidth: size, maxWidth: useMobile() ? -1 : size }}
            transition={{ duration }}
            className="flex min-h-[250px] flex-1 flex-col rounded-lg border shadow-lg"
          >
            <Link
              className="flex max-h-96 flex-col"
              to={`/company/student/${item.id}`}
            >
              <div className="h-[150px] w-full rounded-t-lg bg-blueViolet-50">
                {item.banner && (
                  <img
                    src={item.banner}
                    className="h-[150px] w-full rounded-t-lg object-cover"
                  />
                )}
              </div>
              <div className="mt-[-40px] flex flex-row items-end gap-4 p-4">
                {item.image && (
                  <Avatar
                    link={item.image}
                    className="size-20 border-4 border-white shadow-lg"
                  />
                )}

                <div className="flex grow flex-row items-center justify-between gap-2">
                  <div className="flex flex-col">
                    <Text className="font-semibold">
                      {item.firstName} {item.lastName}
                    </Text>
                    <div className="flex flex-row items-center gap-1">
                      <Location size={18} />
                      <Text>
                        {item?.city?.label
                          ? capitalizeFirstLetter(item.city.label)
                          : "Non défini"}
                      </Text>
                    </div>
                  </div>
                  <div className="flex grow flex-col items-end justify-end">
                    <Star size={18} />
                    <Text>{item.publicMark}</Text>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-6 p-4">
                <Text className="text-center">{item.profileTitle}</Text>
                <div className="flex max-h-14 flex-wrap justify-center gap-2 overflow-hidden">
                  {item.skills?.map((item) => (
                    <span
                      key={item.id}
                      className="max-w-xs break-words rounded-lg bg-blueViolet-50 px-3 py-1 text-sm leading-4"
                    >
                      {item.title}
                    </span>
                  ))}
                </div>
              </div>
            </Link>
          </motion.div>
        </Desktop>
        <Mobile>
          <Link
            className="flex max-h-96 flex-col"
            to={`/company/student/${item.id}`}
          >
            <div className="h-[150px] w-full rounded-t-lg bg-blueViolet-50">
              {item.banner && (
                <img
                  src={item.banner}
                  className="h-[150px] w-full rounded-t-lg object-cover"
                />
              )}
            </div>
            <div className="mt-[-40px] flex flex-row items-end gap-4 p-4">
              {item.image && (
                <Avatar
                  link={item.image}
                  className="size-20 border-4 border-white shadow-lg"
                />
              )}

              <div className="flex grow flex-row items-center justify-between gap-2">
                <div className="flex flex-col">
                  <Text className="font-semibold">
                    {item.firstName} {item.lastName}
                  </Text>
                  <div className="flex flex-row items-center gap-1">
                    <Location size={18} />
                    <Text>
                      {item?.city?.label
                        ? capitalizeFirstLetter(item.city.label)
                        : "Non défini"}
                    </Text>
                  </div>
                </div>
                <div className="flex grow flex-col items-end justify-end">
                  <Star size={18} />
                  <Text>{item.publicMark}</Text>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center gap-6 p-4">
              <Text className="text-center">{item.profileTitle}</Text>
              <div className="flex max-h-14 flex-wrap justify-center gap-2 overflow-hidden">
                {item.skills?.map((item) => (
                  <span
                    key={item.id}
                    className="max-w-xs break-words rounded-lg bg-blueViolet-50 px-3 py-1 text-sm leading-4"
                  >
                    {item.title}
                  </span>
                ))}
              </div>
            </div>
          </Link>
        </Mobile>
      </>
    );
  }
);

export default StudentVerticalCard;
