// Components
import { BlocTitle } from "../../Bloc/Title";
import Title from "../../UI/Title";
import Text from "../../UI/Text";

// Types
import { ProposalQuotation } from "../../../types/proposal";

const Quotations = ({ data }: { data?: ProposalQuotation[] }) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4">
      <BlocTitle>
        <Title type="bloc">Détails de l'estimation chiffrée</Title>
      </BlocTitle>
      <div className="flex flex-col gap-4">
        {data?.map((item, index) => (
          <div key={index} className="flex flex-col gap-2">
            <Text className="font-semibold">Etape {index + 1}</Text>
            <Text className="break-words">{item.content}</Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Quotations;
