// Requires
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Icons
import { TableDocument } from "iconsax-react";

// Components
import { BlocTitle } from "../Bloc/Title";
import Title from "../UI/Title";
import StudentVerticalCardMaxProposal from "../Cards/Student/StudentVerticalCardMaxProposal";

// Types
import type { ProposalsType } from "../../types/proposal";

// Services
import { getProposalFromJob } from "../../services/Job/proposal";

const Proposal = ({
  alreadyDisplay
}: {
  alreadyDisplay: {
    id: number;
    firstName: string;
    lastName: string;
    proposalId: number;
  }[];
}) => {
  const { id } = useParams();
  const [data, setData] = useState<ProposalsType>();

  useEffect(() => {
    const fetch = async () => {
      const response = await getProposalFromJob(0, 10, Number(id), true);
      setData(response);
    };

    fetch();
  }, [id]);

  if (data?.proposals && data.proposals.length > 0) {
    const newProposals = data.proposals.filter(
      (proposal) =>
        !alreadyDisplay.some((item) => item.id === proposal.student.id)
    );

    if (newProposals.length === 0) {
      return (
        <div className="flex w-full flex-col gap-8">
          <BlocTitle>
            <TableDocument size={18} />
            <Title type="bloc">Toutes les propositions</Title>
          </BlocTitle>
          <div className="flex h-fit flex-col gap-8">
            <div className="flex w-full flex-row flex-wrap gap-8">
              Aucune proposition pour le moment...
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex w-full flex-col gap-8">
        <BlocTitle>
          <TableDocument size={18} />
          <Title type="bloc">Toutes les propositions</Title>
        </BlocTitle>
        <div className="flex h-fit flex-col gap-8">
          <div className="flex w-full flex-row flex-wrap gap-8">
            {newProposals.map((proposal, _id) => (
              <StudentVerticalCardMaxProposal key={_id} data={proposal} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return <p>Aucune proposition pour le moment.</p>;
};

export default Proposal;
