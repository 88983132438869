// Requires
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

// Icons
import { Star } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import StudentHorizontalCardMin from "../Cards/Student/StudentHorizontalCardMin";
import Title from "../UI/Title";
import Text from "../UI/Text";

const Suggestion = ({
  data
}: {
  data: {
    id: number;
    firstName: string;
    lastName: string;
    image: string;
    proposalId: number;
  }[];
}) => {
  const [newData, setNewData] = useState<
    {
      id: number;
      firstName: string;
      lastName: string;
      image: string;
      proposalId: number;
    }[]
  >(data);

  const handleDelete = (id: number) => {
    setNewData(newData.filter((item) => item.id !== id));
  };

  return (
    <div className="flex h-fit w-full grow flex-col gap-8 rounded-lg border bg-blueViolet-600 p-4 text-white shadow-lg">
      <BlocTitle>
        <Star size={18} />
        <Title type="bloc" classname="text-white">
          Profil séléctionés par Juwa
        </Title>
      </BlocTitle>
      <Content>
        {newData.length > 0 ? (
          <AnimatePresence>
            {newData.map((item, _id) => (
              <StudentHorizontalCardMin
                key={_id}
                item={item}
                callbackDelete={handleDelete}
              />
            ))}
          </AnimatePresence>
        ) : (
          <div className="flex items-center justify-center">
            <Text className="text-white">
              Nos experts s'occupent de la pré-qualification des meilleurs
              talents pour vous proposer un top 3 des meilleurs profils. Vous
              pourrez ensuite les contacter puis les embaucher.
            </Text>
          </div>
        )}
      </Content>
    </div>
  );
};

export default Suggestion;
