// Components
import Identity from "./PersonnalInformations/Identity";
import GeneralInformations from "./PersonnalInformations/GeneralInformations";

const PersonnalInformations = () => {
  return (
    <>
      <Identity />
      <GeneralInformations />
    </>
  );
};

export default PersonnalInformations;
