// Requires
import { useState, useEffect } from "react";

// Components
import Title from "../../UI/Title";
import Text from "../../UI/Text";
import Card from "./Cards/Card";

// Services
import { getJobs } from "../../../services/Admin/Jobs";

const Validated = () => {
  const [data, setData] = useState<
    {
      company: {
        displayName: string;
        id: number;
        image: string;
      };
      context: string;
      duration: number;
      id: number;
      owner: {
        id: number;
        firstName: string;
        lastName: string;
        image: string;
      };
      title: string;
      total: number;
      type: string;
      workMode: string;
    }[]
  >([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getJobs(
        0,
        10,
        "hiring",
        undefined,
        undefined,
        undefined
      );
      setData(response.jobs);
    };
    fetch();
  }, []);

  return (
    <div className="flex min-w-[calc(25%-6px)] max-w-[calc(25%-6px)] flex-col gap-4 p-2">
      <div className="flex flex-row items-center gap-4">
        <Title type="bloc">Validées</Title>
        <Text className="text-sm text-withCustom-500">255</Text>
      </div>
      <div className="flex max-h-[400px] flex-col gap-4 overflow-scroll md:pr-7">
        {data && data.length > 0 ? (
          data.map((item, _id) => <Card data={item} key={_id} />)
        ) : (
          <Text className="text-sm">Aucune mission validée pour le moment</Text>
        )}
      </div>
    </div>
  );
};

export default Validated;
