// Requires
import { Link } from "react-router-dom";

// Components
import Text from "../../../components/UI/Text";
import Title from "../../../components/UI/Title";

// Assets
import companyIllustration from "../../../assets/companyIllustration.svg";
import studentIllustration from "../../../assets/studentIllustration.svg";
import { Desktop, Mobile } from "../../../utils/mobile/responsive";

const Register = () => {
  return (
    <div className="flex max-h-[calc(100vh-108px)] w-full flex-col overflow-scroll max-md:pb-[108px] md:p-16">
      <div className="flex flex-col gap-8 p-5 md:p-0">
        <div className="flex flex-col items-center justify-center gap-2">
          <Title type="page" classname="md:text-3xl text-center">
            On ne se connait pas encore ?
          </Title>
          <Text className="text-wrap text-center">
            Sélectionnez le type de compte que vous souhaitez créer
          </Text>
        </div>
        <div className="flex flex-col gap-8 max-md:flex-col-reverse max-md:px-8 md:flex-row md:justify-center">
          <Link
            to="company"
            className="flex cursor-pointer flex-col items-center gap-8 rounded-lg border p-4 hover:bg-blueViolet-50 max-md:gap-2 md:min-w-[300px]"
          >
            <Mobile>
              <img
                src={companyIllustration}
                alt="Illustration entreprise"
                className="w-fit"
              />
            </Mobile>
            <Desktop>
              <img
                src={companyIllustration}
                alt="Illustration entreprise"
                height={400}
                width={400}
              />
            </Desktop>
            <div className="flex flex-col items-center justify-center gap-2">
              <Title type="bloc">Entreprise</Title>
              <Text className="text-center">
                Je cherche des étudiants motivés pour travailler avec nous
              </Text>
            </div>
          </Link>
          <Link
            to="student"
            className="flex cursor-pointer flex-col items-center gap-8 rounded-lg border p-4 hover:bg-blueViolet-50 max-md:gap-2 md:min-w-[300px]"
          >
            <Mobile>
              <img
                src={studentIllustration}
                alt="Illustration Etudiant"
                className="w-fit"
              />
            </Mobile>
            <Desktop>
              <img
                src={studentIllustration}
                alt="Illustration Etudiant"
                height={400}
                width={400}
              />
            </Desktop>{" "}
            <div className="flex flex-col items-center justify-center gap-2">
              <Title type="bloc">Etudiant</Title>
              <Text className="text-center">
                Je créé mon profil pour accéder aux missions
              </Text>
            </div>
          </Link>
        </div>
        <Text className="text-center text-textGray">
          Vous avez déjà un compte ?{" "}
          <Link className="cursor-pointer text-blue-950" to="/auth/login">
            Je me connecte
          </Link>
        </Text>
      </div>
    </div>
  );
};

export default Register;
