// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Services
import { getCompanyJobs } from "../../../../services/Company/company";

// Utils
import { useUser } from "../../../../utils/store/userStore";

// Components
import Statut from "../../../UI/Jobs/Statut";
import Text from "../../../UI/Text";
import Date from "../../../UI/Date";

// Types
import { JobsType } from "../../../../types/job";
import Avatar from "../../../UI/Avatar/Avatar";

export const Table = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const user = useUser((state) => state.userdata);

  const [data, setData] = useState<JobsType>();
  const [count, setCount] = useState<number>();
  const [limit, setLimit] = useState<number>(10);
  const [tempLimit, setTempLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filters = {
    status: searchParams.get("status"),
    type: searchParams.get("type"),
    date: searchParams.get("date"),
    search: searchParams.get("search"),
    startingType: searchParams.get("startingType")
  };

  const tableHeader: string[] = [
    "Titre de la mission",
    "Status",
    "Responsable",
    "Montant Total (HT)",
    "Date de début",
    "Type",
    "Action",
    ""
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      setTempLimit(numericValue);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const el = event.currentTarget;
    const rect = el.getBoundingClientRect();

    setPosition({
      top: rect.top + window.scrollY + 45,
      left: rect.left + window.scrollX - 30
    });

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tempLimit < 10) {
        setLimit(10);
        setTempLimit(10);
      } else if (tempLimit > 100) {
        setLimit(100);
        setTempLimit(100);
      } else {
        setLimit(tempLimit);
      }

      setOffset(0);
      setPage(0);
    }, 500);

    return () => clearTimeout(timer);
  }, [tempLimit]);

  useEffect(() => {
    const fetch = async () => {
      if (user) {
        const response = await getCompanyJobs(page, limit, filters);
        setData(response);
        setCount(response.count);
      }
    };
    fetch();
  }, [
    user,
    page,
    limit,
    filters.date,
    filters.search,
    filters.status,
    filters.type,
    filters.startingType
  ]);

  return (
    <div className="flex flex-col overflow-x-auto">
      <table className="w-full table-auto">
        <thead className="border-b border-t bg-backgroundGray">
          <tr>
            {tableHeader.map((item, index) => (
              <th key={index} className="px-4 py-2 text-left">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {!isLoading && data && data?.jobs.length > 0 ? (
          <tbody>
            {data?.jobs.map((item) => {
              return (
                <tr
                  key={item.id}
                  className="cursor-pointer hover:bg-backgroundGray"
                >
                  <td
                    onClick={() => navigate(`/company/manages-jobs/${item.id}`)}
                    className="border-t px-4 py-2"
                  >
                    {item.title}
                  </td>
                  <td className="border-t px-4 py-2">
                    <Statut State={item.status} />
                  </td>
                  <td className="border-t px-4 py-2">
                    {item.owner ? (
                      <>
                        <div className="flex flex-row gap-2">
                          <Avatar link={item.owner?.image} className="size-8" />
                          <Text>
                            {item.owner?.firstName} {item.owner?.lastName}
                          </Text>
                        </div>
                      </>
                    ) : null}
                  </td>
                  <td className="border-t px-4 py-2">
                    {item.total}&euro;{item.type === "recurent" && "/semaine"}
                  </td>
                  <td className="border-t px-4 py-2">
                    {item.startingType === "date" ? (
                      <Date date={item.startingAt} format="DD/MM/YYYY" />
                    ) : item.startingType === "asap" ? (
                      "Dès que possible"
                    ) : (
                      "Non defini"
                    )}
                  </td>
                  <td className="border-t px-4 py-2">
                    {item.type === "recurent" ? "Récurent" : "Ponctuel"}
                  </td>
                  <td className="border-t px-4 py-2">
                    {item.status.name === "underReview"
                      ? "Attendre la validation"
                      : item.status.name === "hiring"
                        ? "Choisir une candidature"
                        : item.status.name === "hired"
                          ? "Attendre la fin de la mission"
                          : "Mission terminée"}
                  </td>
                  <td onClick={handleClick} className="border-t px-4 py-2">
                    ...
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : !isLoading && data && data?.jobs.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={7}>
                <div className="px-4 py-2 text-center">
                  <Text>Aucune mission</Text>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={7}>
                <div className="flex items-center justify-center px-4 py-2">
                  <div className="loader" />
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="flex flex-row justify-between border-t p-4 text-sm">
        <div className="flex flex-row items-center gap-2">
          <Text>Afficher</Text>
          <input
            type="number"
            className="w-[80px] rounded-lg border p-2 focus:outline-blueViolet-600"
            min={10}
            max={100}
            value={tempLimit}
            onChange={handleInputChange}
          />
          <Text>missions sur {data?.count}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => {
              setOffset(offset - limit);
              setPage(page - 1);
            }}
            disabled={offset === 0}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &lt;
          </button>
          {count &&
            [...Array(Math.ceil(count / limit))].map((_, i) => {
              const pageNumber = i + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => {
                    setOffset((pageNumber - 1) * limit);
                    setPage(pageNumber - 1);
                  }}
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                    pageNumber === Math.ceil(offset / limit) + 1
                      ? "bg-blueViolet-600 text-white"
                      : ""
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}
          <button
            onClick={() => {
              setOffset(offset + limit);
              setPage(page + 1);
            }}
            disabled={count === undefined ? true : offset + limit >= count}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &gt;
          </button>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ top: position?.top, left: position?.left }}
            className="absolute z-50 flex flex-col gap-2 rounded-lg bg-white p-4 shadow-lg"
          >
            <Text className="text-sm">Modifier</Text>
            <Text className="text-sm">Supprimer</Text>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Table;
