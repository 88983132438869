import { useMediaQuery } from "react-responsive";

export const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
export const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
export const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: mobileWidth });
  return isMobile ? children : null;
};

export const useMobile = () =>
  useMediaQuery({ query: `(max-width: ${mobileWidth}px)` });

export const mobileWidth = 768;
