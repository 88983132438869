// Requires
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Components
import Date from "../../../UI/Date";
import Text from "../../../UI/Text";

// Services
import { getCompanies } from "../../../../services/Admin/Company";

const TableAdminCompagnies = () => {
  const [data, setData] = useState<
    {
      activity: string;
      city: string;
      createdAt: string;
      id: number;
      job: number;
      members: number;
      running: number;
      displayName: string;
    }[]
  >([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [limit, setLimit] = useState<number>(10);
  const [tempLimit, setTempLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      setTempLimit(numericValue);
    }
  };

  const handleSelectAll = () => {
    if (allChecked) {
      setCheckedItems([]);
    } else {
      const visibleCompanyIds = data.map((item) => item.id);
      setCheckedItems(visibleCompanyIds);
    }
    setAllChecked(!allChecked);
  };

  const handleCheckboxChange = (id: number) => {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };

  const filters = {
    search: searchParams.get("search")
  };

  const tableHeader: string[] = [
    "Nom",
    "Date d'inscription",
    "Siège social",
    "Collaborateurs",
    "Secteur d'activité",
    "Missions totales",
    "En cours",
    ""
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tempLimit < 10) {
        setLimit(10);
        setTempLimit(10);
      } else if (tempLimit > 100) {
        setLimit(100);
        setTempLimit(100);
      } else {
        setLimit(tempLimit);
      }

      setOffset(0);
      setPage(0);
    }, 500);

    return () => clearTimeout(timer);
  }, [tempLimit]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const response = await getCompanies(
          page.toString(),
          limit.toString(),
          filters
        );
        setData(response.companies);
        setTotalCount(response.count);
      } catch (error) {
        console.error("Erreur de chargement des données", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, [filters.search, page, limit]);

  return (
    <div className="flex flex-col">
      <table className="w-full table-auto">
        <thead className="border-b border-t bg-backgroundGray">
          <tr>
            <th className="px-4 py-2 text-left">
              <input
                type="checkbox"
                checked={allChecked}
                onChange={handleSelectAll}
              />
            </th>
            {tableHeader.map((item, _id) => (
              <th key={_id} className="px-4 py-2 text-left">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td
                colSpan={tableHeader.length + 1}
                className="px-4 py-2 text-center"
              >
                <div className="flex items-center justify-center">
                  <div className="loader" />
                </div>
              </td>
            </tr>
          ) : data.length > 0 ? (
            data.map((item) => (
              <tr
                key={item.id}
                className="cursor-pointer hover:bg-backgroundGray"
              >
                <td className="px-4 py-2">
                  <input
                    type="checkbox"
                    checked={checkedItems.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                    className="checkbox"
                  />
                </td>
                <td className="px-4 py-2">{item.displayName}</td>
                <td className="px-4 py-2">
                  <Date date={item.createdAt} format="DD/MM/YYYY" />
                </td>
                <td className="px-4 py-2">{item.city ?? "Inconnu"}</td>
                <td className="px-4 py-2">{item.members}</td>
                <td className="px-4 py-2">{item.activity}</td>
                <td className="px-4 py-2">{item.job}</td>
                <td className="px-4 py-2">{item.running}</td>
                <td className="px-4 py-2">...</td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={tableHeader.length + 1}
                className="px-4 py-2 text-center"
              >
                Aucune entreprise pour le moment.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex flex-row justify-between border-t p-4 text-sm">
        <div className="flex flex-row items-center gap-2">
          <Text>Afficher</Text>
          <input
            type="number"
            className="w-[80px] rounded-lg border p-2 focus:outline-blueViolet-600"
            value={tempLimit}
            min={10}
            max={100}
            onChange={handleInputChange}
          />
          <Text>entreprises sur {totalCount}</Text>
        </div>
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => setOffset(offset - limit)}
            disabled={offset === 0}
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &lt;
          </button>
          {totalCount &&
            [...Array(Math.ceil(totalCount / limit))].map((_, i) => {
              const pageNumber = i + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => setOffset((pageNumber - 1) * limit)}
                  className={`rounded-lg border p-2 focus:outline-blueViolet-600 ${
                    pageNumber === Math.ceil(offset / limit) + 1
                      ? "bg-blueViolet-600 text-white"
                      : ""
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}
          <button
            onClick={() => setOffset(offset + limit)}
            disabled={
              totalCount === undefined ? true : offset + limit >= totalCount
            }
            className="rounded-lg border p-2 focus:outline-blueViolet-600"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableAdminCompagnies;
