// Icons
import { User } from "iconsax-react";

// Components
import { BlocTitle } from "../Bloc/Title";
import Button from "../UI/Button";
import Text from "../UI/Text";
import Title from "../UI/Title";

const MyExpertInformations = () => {
  // useEffect(() => {

  // },[])

  return (
    <div className="flex h-fit flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <BlocTitle>
        <User size={18} />
        <Title type="bloc">Votre expert attitré</Title>
      </BlocTitle>
      <div className="flex flex-row gap-4">
        <img
          src={"https://storagejuwa.blob.core.windows.net/static/IMG_8554.jpeg"}
          className="h-20 w-20 rounded-lg object-cover object-center md:h-28 md:w-28"
          alt="Pic"
        />
        <div className="flex flex-col gap-1">
          <Text className="font-semibold">Alexandre Hachet</Text>
          <Text className="text-sm">alexandre.hachet@juwa.co</Text>
          <Text className="text-sm">+33 6 31 30 24 21</Text>
          <Text className="text-sm font-semibold">Disponible de 7h à 19h</Text>
        </div>
      </div>
      <Button type="full" className="w-full text-center">
        Programmer une visio
      </Button>
    </div>
  );
};

export default MyExpertInformations;
