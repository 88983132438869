// Icons
import { Filter } from "iconsax-react";

// Components
import Content from "../Bloc/Content";
import { BlocTitle } from "../Bloc/Title";
import Title from "../UI/Title";
import Dropdown from "./Dropdown";
import DropdownWithSearch from "./DropdownWithSearch";

// Types
type DropdownType = {
  title: string;
  type: string;
  withSearch: boolean;
};

const Filters = ({
  handleUpdateFilter
}: {
  handleUpdateFilter?: (type: string, value: number) => void;
}) => {
  const dropdownList: DropdownType[] = [
    { title: "Domaines d'études", type: "domaine", withSearch: false },
    { title: "Compétences", type: "skills", withSearch: true },
    { title: "Langues", type: "languages", withSearch: true },
    { title: "Niveau d'études", type: "degrees", withSearch: false }
  ];

  return (
    <div className="flex h-fit w-full flex-col flex-nowrap gap-6 overflow-hidden rounded-lg border bg-white shadow-lg md:w-[320px] md:max-w-[320px]">
      <div className="flex flex-col flex-nowrap gap-6 p-4 md:min-w-[320px]">
        <BlocTitle>
          <Filter size={18} /> <Title type="bloc">Filtres</Title>
        </BlocTitle>
        <Content>
          {dropdownList.map((el) =>
            el.withSearch ? (
              <DropdownWithSearch
                key={el.title}
                title={el.title}
                type={el.type}
                withSearch={el.withSearch}
                handleUpdateFilter={handleUpdateFilter}
              />
            ) : (
              <Dropdown
                key={el.title}
                title={el.title}
                type={el.type}
                handleUpdateFilter={handleUpdateFilter}
              />
            )
          )}
        </Content>
      </div>
    </div>
  );
};

export default Filters;
