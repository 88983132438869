// Requires
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Components
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import { HorizontalDivider } from "../../../../../../components/UI/Divider";

// Services
import { getPaymentResume } from "../../../../../../services/Payment/payment";

const PaymentResume = () => {
  const { id } = useParams();

  const [data, setData] = useState<{
    company: { id: number; name: string };
    job: { id: number; title: string };
    student: { id: number; firstName: string; lastName: string; image: string };
    fees: {
      commissionHT: {
        formatted: string;
        raw: number;
      };
      commissionTVA: {
        formatted: string;
        raw: number;
      };
      legalFees: {
        formatted: string;
        raw: number;
      };
    };
    service: {
      hours: number;
      hourlyRate: {
        formatted: string;
        raw: number;
      };
      totalAmountMonthly: { formatted: string; raw: number };
      totalAmountProrata: {
        formatted: string;
        raw: number;
      };
      totalAmount: { formatted: string; raw: number };
    };
    totals: {
      totalHT: { formatted: string; raw: number };
      totalTTC: { formatted: string; raw: number };
      totalNextMonth: { formatted: string; raw: number };
      initialStudentPayment: { formatted: string; raw: number };
    };
    totalAmount: number;
    paymentType: "SIMPLE" | "COMPLEX" | "RECURRING";
  }>();

  useEffect(() => {
    const fetch = async () => {
      const response = await getPaymentResume(Number(id));
      setData(response);
    };

    fetch();
  }, [id]);

  if (data) {
    if (data.paymentType === "SIMPLE") {
      return (
        <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
          <Title type="bloc">Résumé tarifaire</Title>
          <div className="flex flex-col gap-4 rounded-lg bg-blueViolet-50 p-4">
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">Prestation de l'étudiant</Text>
                <Text>
                  ({data?.service.hours}h à {data?.service.hourlyRate.formatted}
                  &euro;/h)
                </Text>
              </div>
              <Text>
                {data?.service.totalAmount.formatted}
                &euro;
              </Text>
            </div>
            <HorizontalDivider className="!bg-withCustom-200" />
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">Frais Juwa (HT)</Text>
              </div>
              <Text>{data?.fees.commissionHT.formatted}&euro;</Text>
            </div>
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">
                  Frais juridiques Juwa (TTC)
                </Text>
              </div>
              <Text>{data.fees.legalFees.formatted} &euro;</Text>
            </div>
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col gap-1">
                <Text className="font-semibold">TOTAL (HT)</Text>
              </div>
              <Text>{data.totals.totalHT.formatted}&euro;</Text>
            </div>
            <HorizontalDivider className="!bg-withCustom-200" />
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">TOTAL (TTC)</Text>
                <Text className="text-sm">à payer aujourd'hui</Text>
              </div>
              <Text>{data.totals.totalTTC.formatted}&euro;</Text>
            </div>
          </div>
        </div>
      );
    }

    if (data.paymentType === "COMPLEX") {
      return (
        <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
          <Title type="bloc">Résumé tarifaire</Title>
          <div className="flex flex-col gap-4 rounded-lg bg-blueViolet-50 p-4">
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">Prestation de l'étudiant</Text>
                <Text>
                  ({data?.service.hours}h à {data?.service.hourlyRate.formatted}
                  &euro;/h)
                </Text>
              </div>
              <Text>
                {data?.service.totalAmount.formatted}
                &euro;
              </Text>
            </div>
            <HorizontalDivider className="!bg-withCustom-200" />
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">Frais Juwa (HT)</Text>
              </div>
              <Text>{data?.fees.commissionHT.formatted}&euro;</Text>
            </div>
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">
                  Frais juridiques Juwa (HT)
                </Text>
              </div>
              <Text>{data.fees.legalFees.formatted} &euro;</Text>
            </div>
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col gap-1">
                <Text className="font-semibold">TOTAL (HT)</Text>
              </div>
              <Text>{data.totals.totalHT.formatted}&euro;</Text>
            </div>
            <HorizontalDivider className="!bg-withCustom-200" />
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col gap-1">
                <Text className="font-semibold">TOTAL (TTC)</Text>
              </div>
              <Text>{data.totals.totalTTC.formatted}&euro;</Text>
            </div>
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">ACOMPTE (TTC)</Text>
                <Text className="text-sm">à payer aujourd'hui</Text>
              </div>
              <Text>{data.totals.initialStudentPayment.formatted}&euro;</Text>
            </div>
          </div>
        </div>
      );
    }

    if (data.paymentType === "RECURRING") {
      return (
        <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
          <Title type="bloc">Résumé tarifaire</Title>
          <div className="flex flex-col gap-4 rounded-lg bg-blueViolet-50 p-4">
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">Prestation de l'étudiant</Text>
                <Text>
                  ({data?.service.hours}h à {data?.service.hourlyRate.formatted}
                  &euro;/h)
                </Text>
              </div>
              <div className="flex flex-col items-end">
                <Text>
                  {Number(data?.service.hourlyRate.formatted) *
                    data?.service.hours}{" "}
                  &euro;
                </Text>
                <Text className="text-sm">par semaine</Text>
              </div>
            </div>
            <HorizontalDivider className="h-[1px] !bg-withCustom-200" />
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">Frais Juwa (HT)</Text>
              </div>
              <Text>{data?.fees.commissionHT.formatted} &euro;</Text>
            </div>
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">
                  Frais juridiques Juwa (HT)
                </Text>
              </div>
              <Text>{data.fees.legalFees.formatted} &euro;</Text>
            </div>
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col gap-1">
                <Text className="font-semibold">Total (HT)</Text>
              </div>
              <Text>{data.totals.totalHT.formatted} &euro;</Text>
            </div>
            <HorizontalDivider className="h-[1px] !bg-withCustom-200" />
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">Total par mois (TTC)</Text>
                <Text className="text-sm">à payer chaque mois</Text>
              </div>
              <Text>{data.totals.totalNextMonth.formatted} &euro;</Text>
            </div>
            <div className="flex flex-row items-center justify-between gap-8">
              <div className="flex flex-col">
                <Text className="font-semibold">Total (TTC)</Text>
                <Text className="text-sm">à payer aujourd'hui</Text>
              </div>
              <Text>{data.totals.totalTTC.formatted} &euro;</Text>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex w-full items-center justify-center border p-4 shadow-lg">
      <div className="loader" />
    </div>
  );
};

export default PaymentResume;
