// Icons
import { ImportSquare } from "iconsax-react";

// Requires
import { useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

// Components
import Button from "../../../../../../components/UI/Button";
import { HorizontalDivider } from "../../../../../../components/UI/Divider";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";

// Services
import { createJobWithIA } from "../../../../../../services/Job/job";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import { setLocalStorage } from "../../../../../../utils/helpers/localStorage";

// Types
import type { JobCreateType } from "../../../../../../types/job";

interface Props {
  updateFormData: (newData: { [key: string]: any }) => void;
}

type Error = {
  prompt?: string;
  file?: string;
};

const IA = ({ updateFormData }: Props) => {
  const { handleNextStep, step } = useUI();

  const [textarea, setTextarea] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const [searchParams] = useSearchParams();
  const withIA = searchParams.get("ia");

  const handleClick = () => {
    if (inputRef) {
      inputRef.current?.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.size > 2000000) {
      return alert("Maximum 2mo");
    }

    if (file && file.size < 2000000) {
      setFile(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files?.[0];
    if (droppedFile && droppedFile.size > 2000000) {
      return alert("Maximum 2mo");
    }

    if (droppedFile && droppedFile.size < 2000000) {
      setFile(droppedFile);
    }
  };

  const nextStep = async () => {
    const newError: Error = {};
    setError(newError);

    if ((!file && !textarea) || (textarea && textarea.length < 200)) {
      newError.prompt =
        "Vous devez remplir le besoin avec un minimum de 200 caractères";
      newError.file = "Vous devez indiquer un document";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return; // Stop the function if there are errors
    }

    if (textarea.length > 200 || file) {
      setIsLoading(true);
      if (file) {
        const formData = new FormData();
        formData.append("attachment", file);
        const response: JobCreateType = await createJobWithIA(formData);
        updateFormData(response);
        const localData = [
          { key: "publishJobStep", value: "1" },
          {
            key: "step1",
            value: JSON.stringify({
              profileTitle: response.profileTitle,
              categories: response.categories,
              degree: response.degree
            })
          },
          {
            key: "step2",
            value: JSON.stringify({
              skills: response.skills?.splice(0, 5),
              languages: response.languages
            })
          },
          {
            key: "step3",
            value: JSON.stringify({
              workMode: response.workMode,
              workingFrequency: response.workingFrequency,
              location: response.location
            })
          },
          {
            key: "step4",
            value: JSON.stringify({
              startingAt: response.startingAt,
              startingType: response.startingType,
              type: response.type,
              duration: response.duration,
              endingType: response.endingType,
              endingAt: response.endingAt,
              rate: response.rate
            })
          },
          {
            key: "step5",
            value: JSON.stringify({
              title: response.title,
              context: response.context,
              objectives: response.objectives,
              deliverables: response.deliverables
            })
          }
        ];
        setLocalStorage(localData);
        handleNextStep();
        return;
      }

      if (!file && textarea) {
        const data = {
          text: textarea
        };
        const response: JobCreateType = await createJobWithIA(data);
        const localData = [
          { key: "publishJobStep", value: "1" },
          {
            key: "step1",
            value: JSON.stringify({
              profileTitle: response.profileTitle,
              categories: response.categories,
              degree: response.degree
            })
          },
          {
            key: "step2",
            value: JSON.stringify({
              skills: response.skills?.splice(0, 5),
              languages: response.languages
            })
          },
          {
            key: "step3",
            value: JSON.stringify({
              workMode: response.workMode,
              workingFrequency: response.workingFrequency,
              location: response.location
            })
          },
          {
            key: "step4",
            value: JSON.stringify({
              startingAt: response.startingAt,
              startingType: response.startingType,
              type: response.type,
              duration: response.duration,
              endingType: response.endingType,
              endingAt: response.endingAt,
              rate: response.rate
            })
          },
          {
            key: "step5",
            value: JSON.stringify({
              title: response.title,
              context: response.context,
              objectives: response.objectives,
              deliverables: response.deliverables
            })
          }
        ];
        setLocalStorage(localData);
        handleNextStep();
        return;
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-8 max-md:gap-8">
      <div className="flex flex-col items-center justify-center gap-8 max-md:gap-8">
        <Title type="page">Rédiger avec l'IA</Title>
        <div className="flex w-full flex-col gap-8 max-md:gap-8">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <Text className="text-lg font-semibold">
                Décrivez en quelques mots votre besoin
              </Text>
              <ul className="flex flex-col gap-1">
                <li>
                  1. Commencez par nous donner une idée de ce dont vous avez
                  besoin : type de projet, budget, taux horaire, compétences
                  recherchées...
                </li>
                <li>2. Recevez une mission personnalisée</li>
                <li>3. Ajustez selon vos préférences</li>
              </ul>
            </div>
            <div className="flex flex-col gap-1">
              <textarea
                value={textarea}
                onChange={(e) => setTextarea(e.currentTarget.value)}
                className={`textarea-autorezise min-h-[150px] resize-none rounded-lg p-2 focus:outline-blueViolet-600 ${
                  error?.prompt ? "border border-red-500" : "border"
                }`}
                placeholder="Je recherche un étudiant en école de commerce pour réaliser un business plan..."
              />
              <div className="flex flex-row justify-between">
                <Text className="text-sm text-red-500">
                  {error?.prompt && error.prompt}
                </Text>
                <Text className="text-sm text-withCustom-500">
                  {textarea.length}/1000 caractères
                </Text>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <HorizontalDivider />
            <Text>OU</Text>
            <HorizontalDivider />
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <Text className="text-lg font-semibold">Fiche de poste</Text>
              <Text>Déposez une fiche de poste que vous avez déjà rédigé</Text>
            </div>
            <div
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={handleClick}
              className={`flex h-28 w-full flex-row items-center gap-2 rounded-lg border p-4 ${isDragging && "border-blueViolet-600"}`}
            >
              <div className="flex size-16 items-center justify-center rounded-lg bg-blueViolet-50">
                <ImportSquare size={18} />
              </div>
              <Text>Glisser-déposer le PDF ou</Text>
              <div>
                <Button type="fullLite">
                  {file ? file.name : "Parcourir l'ordinateur"}
                </Button>
              </div>
              <input
                onChange={handleFileChange}
                ref={inputRef}
                type="file"
                accept="application/pdf"
                className="hidden"
              />
            </div>
            <Text className="text-sm text-red-500">
              {error?.file && error.file}
            </Text>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Link to="/publish-job">
          <Button type="fullLite">Précédent</Button>
        </Link>
        <div className="flex-1 text-center">
          <Text>
            Étape {step + 1}/{withIA ? "6" : "5"}
          </Text>
        </div>
        <div>
          <div onClick={nextStep}>
            <Button type="full">
              {isLoading ? <div className="loader" /> : "Suivant"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IA;
