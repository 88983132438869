// Requires
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Context
import { usePopup } from "../../../../context/Popup";

// Services
import { getSpecificUser } from "../../../../services/User/user";

// Icons
import { CloseSquare } from "iconsax-react";

// Components
import Title from "../../../UI/Title";
import CustomTooltip from "../../../UI/Tooltip";
import Text from "../../../UI/Text";
import JobSearch from "../../../Misc/JobSearch";
import Button from "../../../UI/Button";

// Types
import { User } from "../../../../types/user";
import { JobDetailsType } from "../../../../types/job";

// Services
import { recommendJobToStudents } from "../../../../services/Admin/Jobs";

// Context
import { useFloatingNotification } from "../../../../context/FloatingNotification";

const SuggestJob = () => {
  const { studentsChecked, closePopup } = usePopup();
  const [dataStudents, setDataStudents] = useState<User[]>([]);
  const [job, setJob] = useState<JobDetailsType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { toggleFloatingNotification } = useFloatingNotification();

  const [notification, setNotification] = useState<{
    email: boolean;
    sms: boolean;
  }>({
    email: false,
    sms: false
  });

  const handleToggleChange = (type: "email" | "sms") => {
    setNotification((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleValidate = async () => {
    if (!studentsChecked || studentsChecked.length < 1) {
      alert("Vous devez sélectionner au moins un etudiant");
      return;
    }

    if (!job) {
      alert("Vous devez sélectionner une mission");
      return;
    }

    setIsLoading(true);

    const response = await recommendJobToStudents(
      job.id,
      studentsChecked,
      notification
    );
    if (response) {
      toggleFloatingNotification("La mission a bien été sugère");
      closePopup();
      setIsLoading(false);
      return;
    }

    alert(
      "Un ou plusieurs étudiants ont déjà été recommandés pour cette mission"
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchStudents = async () => {
      if (studentsChecked && studentsChecked.length > 0) {
        const studentPromises = studentsChecked.map((studentId) =>
          getSpecificUser(studentId)
        );
        const responses = await Promise.all(studentPromises);
        setDataStudents(responses);
      }
    };

    fetchStudents();
  }, [studentsChecked]);

  if (!dataStudents.length) {
    return (
      <div className="p-4">
        <div className="loader" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex flex-row items-center justify-between gap-8">
        <Title type="bloc">Suggerer une mission</Title>
        <CloseSquare
          size={18}
          className="cursor-pointer hover:text-blueViolet-600"
          onClick={closePopup}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Text>Vous avez sélectionné les étudiants suivants :</Text>
        <div className="flex w-fit flex-row gap-4 rounded-lg border p-2">
          {dataStudents.map((student, index) => (
            <CustomTooltip
              key={index}
              id={index.toString()}
              position="top"
              content={`${student.firstName} ${student.lastName}`}
            >
              <Link target="_blank" to={`/admin/student/${student.id}`}>
                <img
                  src={student.image}
                  alt={`${student.firstName} ${student.lastName}`}
                  className="size-11 rounded-lg"
                />
              </Link>
            </CustomTooltip>
          ))}
        </div>
      </div>
      <JobSearch callback={(data: JobDetailsType) => setJob(data)} />
      <div className="flex flex-col gap-2">
        <Text>Envoyer une notification par :</Text>
        <div className="flex flex-row gap-8">
          <div className="flex flex-row items-center gap-4">
            <div className="toggle-switch">
              <input
                className="toggle-input"
                id="sms"
                type="checkbox"
                checked={notification.sms}
                onChange={() => handleToggleChange("sms")}
              />
              <label className="toggle-label" htmlFor="sms" />
            </div>
            <Text>SMS</Text>
          </div>
          <div className="flex flex-row items-center gap-4">
            <div className="toggle-switch">
              <input
                className="toggle-input"
                id="email"
                type="checkbox"
                checked={notification.email}
                onChange={() => handleToggleChange("email")}
              />
              <label className="toggle-label" htmlFor="email" />
            </div>
            <Text>Email</Text>
          </div>
        </div>
      </div>
      <div onClick={handleValidate}>
        <Button type="full">
          {isLoading ? <div className="loader" /> : "Valider"}
        </Button>
      </div>
    </div>
  );
};

export default SuggestJob;
