// Requires
import { Link } from "react-router-dom";

// Icons
import { Message } from "iconsax-react";

// Components
import Date from "../../Date";
import Text from "../../Text";

// Services
import { setNotificationAsRead } from "../../../../services/Notification/notification";

// Types
import type { NotificationType } from "../../../../types/notification";

const Notify = ({
  data,
  updateCount
}: {
  data: NotificationType;
  updateCount: () => void;
}) => {
  const markAsRead = async () => {
    await setNotificationAsRead(data.id);
    updateCount();
  };

  return (
    <Link
      to={data.path}
      onMouseEnter={markAsRead}
      className="flex flex-col flex-nowrap gap-2"
    >
      <div className="flex flex-row flex-nowrap items-center gap-2">
        <Message size={18} />{" "}
        <Text className="text-nowrap text-sm">{data.title}</Text>
      </div>
      <Text className="text-xs">{data.message}</Text>
      <Date
        className="text-right text-xs text-withCustom-500"
        date={data.createdAt}
        format="DD/MM à HH:MM"
      />
    </Link>
  );
};

export default Notify;
