// Requires
import { AnimatePresence, motion } from "framer-motion";

// Components
import Button from "../../UI/Button";
import Text from "../../UI/Text";

interface NewInternshipProps {
  handleInternshipChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error: {
    internshipError?: {
      start?: string;
      end?: string;
      description?: string;
    };
  };
  setInternshipFormIsVisible: (visible: boolean) => void;
  addInternship: () => void;
  internship: {
    description: string;
  };
}

export const NewIntership: React.FC<NewInternshipProps> = ({
  handleInternshipChange,
  error,
  setInternshipFormIsVisible,
  addInternship,
  internship
}) => {
  return (
    <AnimatePresence>
      <motion.div
        key="internshipAnimate"
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 5 }}
        className="flex flex-col gap-4 rounded-lg p-4 md:border"
      >
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="flex flex-col gap-2">
            <label>Date de début</label>
            <input
              onChange={handleInternshipChange}
              type="date"
              name="start"
              id=""
              className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              placeholder="JJ/MM/AA"
            />
            {error.internshipError?.start && (
              <Text className="text-sm text-red-500">
                {error.internshipError.start}
              </Text>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label>Date de fin</label>
            <input
              onChange={handleInternshipChange}
              type="date"
              name="end"
              id=""
              className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              placeholder="JJ/MM/AA"
            />
            {error.internshipError?.end && (
              <Text className="text-sm text-red-500">
                {error.internshipError.end}
              </Text>
            )}
          </div>
        </div>
        <textarea
          onChange={handleInternshipChange}
          name="description"
          className="h-fit rounded-lg border p-2 focus:outline-blueViolet-600"
          placeholder="Description"
        />
        <div className="flex flex-row items-end justify-between">
          <Text className="text-sm text-red-500">
            {error.internshipError?.description}
          </Text>
          <Text className="text-sm">
            {internship.description.length}/500 caractères
          </Text>
        </div>
        <div className="flex flex-row justify-end gap-2">
          <div onClick={() => setInternshipFormIsVisible(false)}>
            <Button type="cancel">Annuler</Button>
          </div>
          <div onClick={addInternship}>
            <Button type="full">Ajouter</Button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
