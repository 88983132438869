// Icons
import { InfoCircle } from "iconsax-react";

// Components
import Button from "../UI/Button";
import Text from "../UI/Text";
import Title from "../UI/Title";
import CustomTooltip from "../UI/Tooltip";

// Utils
import { Desktop } from "../../utils/mobile/responsive";
import { Link } from "react-router-dom";

type CardType = {
  title: string;
  buttonText: string;
  subscriptionRequired: boolean;
  plan: number;
  tooltip: string;
  buttonLink: string;
  image: string;
};

const Card = ({ card }: { card: CardType }) => {
  return (
    <div className="relative flex min-w-[calc(33%-10px)] flex-col gap-4 rounded-lg border shadow-lg">
      <Desktop>
        <img
          alt="pic"
          src={card.image}
          className="h-[200px] w-full rounded-t-lg object-cover object-center"
        />
      </Desktop>
      <div className="flex flex-col gap-4 p-4">
        <Title type="bloc">{card.title}</Title>
        <Link to={card.buttonLink}>
          <Button type="full" className="w-full text-center">
            <Text className="text-white">{card.buttonText}</Text>
          </Button>
        </Link>
        <div className="flex flex-row items-center gap-2 text-sm text-textGray">
          <CustomTooltip
            position="top"
            content={card.tooltip}
            className="max-w-[250px]"
          >
            <div className="flex flex-row items-center gap-2">
              <Text className="text-sm">Comment ça marche ?</Text>{" "}
              <InfoCircle size={16} />
            </div>
          </CustomTooltip>
        </div>
        {card.subscriptionRequired && (
          <div className="flex justify-end text-sm">* Réservé aux abonnés</div>
        )}
      </div>
      {card.plan < 1 && (
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center rounded-lg bg-gray-500 bg-opacity-70 p-8">
          <div className="flex flex-col gap-4 rounded-lg bg-blueViolet-50 p-4 shadow-lg">
            <Text className="font-semibold">Publier une mission régulière</Text>
            <Text className="text-sm">
              Pour pouvoir poster une mission régulière, passez au plan
              supérieur
            </Text>
            <Button type="full">Passer au plan supérieur</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
