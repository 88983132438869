import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

// Context
import { useUI } from "../../../../utils/context/UI";

// Components
import Text from "../../../../components/UI/Text";
import One from "./Step/Part1/One";
import Two from "./Step/Part1/Two";
import Four from "./Step/Part2/Four";
import Three from "./Step/Part2/Three";
import Eight from "./Step/Part3/Eight";
import Five from "./Step/Part3/Five";
import Seven from './Step/Part3/Seven';
import Six from "./Step/Part3/Six";
import Eleven from "./Step/Part4/Eleven";
import Fourteen from "./Step/Part4/Fourteen";
import Nine from "./Step/Part4/Nine";
import Ten from "./Step/Part4/Ten";
import Thirteen from "./Step/Part4/Thirteen";
import Twelve from "./Step/Part4/Twelve";

const Student = () => {
  const { step, direction, specificStep, resetStep } = useUI();

  // Nombre d'étapes par partie
  const stepByPart = [2, 2, 3, 7];

  const [progressValue, setProgressValue] = useState({
    part1: 0,
    part2: 0,
    part3: 0,
    part4: 0
  });

  useEffect(() => {
    const step = localStorage.getItem("studentStep");
    if (step) {
      specificStep(Number(step));
    } else {
      resetStep();
    }
  }, [step]);

  useEffect(() => {
    let currentPart = 1;
    let accumulatedSteps = 0;

    for (let i = 0; i < stepByPart.length; i++) {
      accumulatedSteps += stepByPart[i];
      if (step <= accumulatedSteps) {
        currentPart = i + 1;
        break;
      }
    }

    const newProgress = {
      part1:
        currentPart >= 1
          ? Math.min(((step - 1) / stepByPart[0]) * 100, 100)
          : 0,
      part2:
        currentPart >= 2
          ? Math.min(((step - stepByPart[0] - 1) / stepByPart[1]) * 100, 100)
          : 0,
      part3:
        currentPart >= 3
          ? Math.min(
              ((step - stepByPart[0] - stepByPart[1] - 1) / stepByPart[2]) *
                100,
              100
            )
          : 0,
      part4:
        currentPart >= 4
          ? Math.min(
              ((step - stepByPart[0] - stepByPart[1] - stepByPart[2] - 1) /
                stepByPart[3]) *
                100,
              100
            )
          : 0
    };

    setProgressValue(newProgress);
  }, [step]);

  const pageTransition = {
    initial: { opacity: 0, x: direction === "forward" ? "100%" : "-100%" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: direction === "forward" ? "-100%" : "100%" },
    transition: { duration: 0.3 }
  };

  const steps = [
    { id: 0, step: 1, element: <One /> },
    { id: 1, step: 2, element: <Two /> },
    { id: 2, step: 3, element: <Three /> },
    { id: 3, step: 4, element: <Four /> },
    { id: 4, step: 5, element: <Five /> },
    { id: 5, step: 6, element: <Nine /> },
    { id: 6, step: 7, element: <Six /> },
    { id: 7, step: 8, element: <Seven /> },
    { id: 8, step: 9, element: <Eight /> },
    { id: 9, step: 10, element: <Ten /> },
    { id: 10, step: 11, element: <Eleven /> },
    { id: 11, step: 12, element: <Twelve /> },
    { id: 12, step: 13, element: <Fourteen /> },
    { id: 13, step: 14, element: <Thirteen /> }
  ];

  return (
    <div className="flex w-full grow flex-col justify-center">
      <div className="md:gap flex max-h-[calc(100vh-108px)] min-h-[calc(100vh-108px)] w-full flex-col gap-4 overflow-scroll max-md:pb-[108px] md:gap-8 md:p-16">
        <div className="flex items-center justify-center p-2 md:p-0">
          <div
            id="progressBar"
            className="flex min-w-[50%] flex-row justify-center gap-1 md:max-w-[50%] md:gap-4"
          >
            <div id="progressBarPart" className="flex flex-1 flex-row md:gap-6">
              <div className="flex w-full flex-col items-center gap-2">
                <div className="h-4 w-full rounded-full bg-blueViolet-100">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${progressValue.part1}%` }}
                    className="h-4 rounded-full bg-blueViolet-600"
                  />
                </div>
                <div
                  className={`h-2 w-2 rounded-full ${
                    progressValue.part1 === 100
                      ? "bg-blueViolet-600"
                      : "bg-blueViolet-100"
                  }`}
                />
                <Text
                  className={`text-xs md:text-sm ${
                    progressValue.part1 === 100
                      ? "text-blueViolet-600"
                      : "text-blueViolet-100"
                  }`}
                >
                  Métier
                </Text>
              </div>
            </div>
            <div id="progressBarPart" className="flex flex-1 flex-row md:gap-6">
              <div className="flex w-full flex-col items-center gap-2">
                <div className="h-4 w-full rounded-full bg-blueViolet-100">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${progressValue.part2}%` }}
                    className="h-4 rounded-full bg-blueViolet-600"
                  />
                </div>
                <div
                  className={`h-2 w-2 rounded-full ${
                    progressValue.part2 === 100
                      ? "bg-blueViolet-600"
                      : "bg-blueViolet-100"
                  }`}
                />
                <Text
                  className={`text-center text-xs md:text-sm ${
                    progressValue.part2 === 100
                      ? "text-blueViolet-600"
                      : "text-blueViolet-100"
                  }`}
                >
                  Authentification
                </Text>
              </div>
            </div>
            <div id="progressBarPart" className="flex flex-1 flex-row md:gap-6">
              <div className="flex w-full flex-col items-center gap-2">
                <div className="h-4 w-full rounded-full bg-blueViolet-100">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${progressValue.part3}%` }}
                    className="h-4 rounded-full bg-blueViolet-600"
                  />
                </div>
                <div
                  className={`h-2 w-2 rounded-full ${
                    progressValue.part3 === 100
                      ? "bg-blueViolet-600"
                      : "bg-blueViolet-100"
                  }`}
                />
                <Text
                  className={`text-center text-xs md:text-sm ${
                    progressValue.part3 === 100
                      ? "text-blueViolet-600"
                      : "text-blueViolet-100"
                  }`}
                >
                  Expériences et formations
                </Text>
              </div>
            </div>
            <div id="progressBarPart" className="flex flex-1 flex-row md:gap-6">
              <div className="flex w-full flex-col items-center gap-2">
                <div className="h-4 w-full rounded-full bg-blueViolet-100">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${progressValue.part4}%` }}
                    className="h-4 rounded-full bg-blueViolet-600"
                  />
                </div>
                <div
                  className={`h-2 w-2 rounded-full ${
                    progressValue.part4 === 100
                      ? "bg-blueViolet-600"
                      : "bg-blueViolet-100"
                  }`}
                />
                <Text
                  className={`text-xs md:text-sm ${
                    progressValue.part4 === 100
                      ? "text-blueViolet-600"
                      : "text-blue-100"
                  }`}
                >
                  Personnalisation
                </Text>
              </div>
            </div>
          </div>
        </div>
        <AnimatePresence mode="wait">
          <div className="flex items-center justify-center">
            <motion.div
              key={steps[step].id}
              initial={pageTransition.initial}
              animate={pageTransition.animate}
              exit={pageTransition.exit}
              transition={pageTransition.transition}
              className="flex w-full grow flex-col items-center justify-center gap-8 p-4 md:p-0"
            >
              {steps[step].element}
            </motion.div>
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Student;
