// Icons
import { Verify } from "iconsax-react";

// Components
import { BlocTitle } from "../../Bloc/Title";
import Text from "../../UI/Text";

const HorizontalCompanyCard = ({
  initialData
}: {
  initialData?: {
    siren: string;
    activity: string;
    address: string;
  } | null;
}) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4">
      <BlocTitle>
        <Verify size={18} /> <Text>Détail de l'entreprise</Text>
      </BlocTitle>
      <div className="flex flex-row flex-wrap gap-8">
        <div className="flex flex-row items-center gap-1">
          <Text className="font-semibold">SIREN:</Text>
          <Text>{initialData?.siren || "Numéro de siren"}</Text>
        </div>
        <div className="flex flex-row items-center gap-1">
          <Text className="font-semibold">Activité:</Text>
          <Text>{initialData?.activity || "Secteur d'activité"}</Text>
        </div>
        <div className="flex flex-row items-center gap-1">
          <Text className="font-semibold">Siège social:</Text>
          <Text>{initialData?.address || "Adresse"}</Text>
        </div>
      </div>
    </div>
  );
};

export default HorizontalCompanyCard;
