// Components
import Text from "../../../UI/Text";

export type additionalServicesType = {
  nda: boolean;
  contract: boolean;
};

type NextStepProps = {
  additionalServices: additionalServicesType;
  setAdditionalServices: React.Dispatch<
    React.SetStateAction<additionalServicesType>
  >;
};

const NextStep = ({
  additionalServices,
  setAdditionalServices
}: NextStepProps) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <Text className="text-xl font-semibold">Et ensuite...</Text>
      <div className="flex flex-col gap-1">
        <Text>En publiant votre mission sur Juwa :</Text>
        <div className="flex flex-col gap-1 pl-2">
          <Text>
            - Nous pré-sélectionnons des profils qui correspondent au mieux à
            votre besoin
          </Text>
          <Text>
            - En 48h vous recevez 3 profils disponibles pour travailler avec
            vous
          </Text>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <Text className="font-semibold">
          Créer automatiquement un contrat de prestation de service
        </Text>
        <Text
          className={`text-sm ${additionalServices.nda && additionalServices.contract && "line-through"}`}
        >
          30€ HT
        </Text>
        <div className="flex flex-row items-center gap-4">
          <div className="toggle-switch">
            {/* <input className="toggle-input" id="toggle" type="checkbox" /> */}
            <input
              className="toggle-input"
              id="toggleContract"
              type="checkbox"
              name="createContract"
              checked={additionalServices.contract || false}
              onChange={() => {
                setAdditionalServices((prev: additionalServicesType) => ({
                  ...prev,
                  contract: !prev.contract
                }));
              }}
            />
            <label className="toggle-label" htmlFor="toggleContract" />
          </div>
          <Text>Le contrat sera créé pour le début de la mission</Text>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <Text className="font-semibold">
          Créer automatiquement un NDA pour la mission
        </Text>
        <Text
          className={`text-sm ${additionalServices.nda && additionalServices.contract && "line-through"}`}
        >
          30€ HT
        </Text>
        <div className="flex flex-row items-center gap-4">
          <div className="toggle-switch">
            {/* <input className="toggle-input" id="toggleNDA" type="checkbox" /> */}
            <input
              className="toggle-input"
              id="toggleNDA"
              type="checkbox"
              name="createNDA"
              checked={additionalServices.nda || false}
              onChange={() => {
                setAdditionalServices((prev: additionalServicesType) => ({
                  ...prev,
                  nda: !prev.nda
                }));
              }}
            />
            <label className="toggle-label" htmlFor="toggleNDA" />
          </div>
          <Text>La mission nécessite un NDA</Text>
        </div>
      </div>
      {additionalServices.nda && additionalServices.contract && (
        <Text className="font-semibold">Contrat et NDA: 50€ HT</Text>
      )}
    </div>
  );
};

export default NextStep;
