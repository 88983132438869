import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { type Socket, io } from "socket.io-client";
import config from "../utils/config";
import { useUser } from "../utils/store/userStore";

interface SocketInterface {
  socket: Socket | null;
  socketInitialization: () => void;
}

export const SocketContext = createContext<SocketInterface | null>(null);

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const user = useUser((state) => state.userdata);

  const socketInitialization = async () => {
    if (config.socketUrl) {
      const Socket: Socket = io(config.socketUrl, {
        withCredentials: true
      });

      Socket?.emit("init", (response: string) => {
        console.log(response);
      });

      setSocket(Socket);
      return;
    }
  };

  useEffect(() => {
    socketInitialization();
  }, [user]);

  return (
    <SocketContext.Provider value={{ socket, socketInitialization }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
