// Icons
import { MedalStar } from "iconsax-react";

// Components
import Title from "../UI/Title";
import Text from "../UI/Text";
import { HorizontalDivider } from "../UI/Divider";
import Avatar from "../UI/Avatar/Avatar";

const BestClients = () => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <MedalStar size={18} />
        <Title type="bloc">Meilleur clients</Title>
      </div>
      {[0, 1, 2].map((item, _id) => (
        <div key={_id} className="flex flex-col gap-4">
          <div className="flex flex-row items-center justify-between gap-2">
            <div className="flex flex-row items-center gap-2">
              <Avatar
                link="https://picsum.photos/100/100"
                className="size-9 rounded-lg"
              />
              <Text className="font-semibold">Nom du client</Text>
            </div>
            <Text className="font-semibold">10.000 &euro;</Text>
          </div>
          <HorizontalDivider />
        </div>
      ))}
    </div>
  );
};

export default BestClients;
