// Requires
import { motion } from "framer-motion";
import { useState } from "react";

// Icons

// Components
import Content from "../../../../Bloc/Content";
import { BlocTitle } from "../../../../Bloc/Title";
import Button from "../../../../UI/Button";
import Title from "../../../../UI/Title";
import Text from "../../../../UI/Text";
import JoinFile from "../../../../UI/File/JoinFile";

// Types
import { PortfolioType } from "../../../../../types/user";

type Errors = {
  title?: string;
  noContent?: string;
};

const Form = ({
  initialData,
  cancel,
  callback
}: {
  initialData?: PortfolioType;
  cancel: () => void;
  callback: (data: PortfolioType) => void;
}) => {
  const [portfolio, setPortfolio] = useState<PortfolioType>({
    portfolioId: initialData?.id || undefined,
    title: initialData?.title || "",
    link: initialData?.link || "",
    date: initialData?.date || "",
    ogImage: initialData?.ogImage || "",
    ogTitle: initialData?.ogTitle || "",
    attachments: initialData?.attachments || []
  });

  const [errors, setErrors] = useState<Errors>({});

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setPortfolio((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (files: File[]) => {
    setPortfolio((prev) => ({ ...prev, attachments: files }));
  };

  const handleFileRemove = (index: number) => {
    setPortfolio((prev) => ({
      ...prev,
      attachments: prev?.attachments?.length
        ? prev.attachments.filter((_, i) => i !== index)
        : []
    }));
  };

  const AddOrUpdate = () => {
    const newErrors: Errors = {};

    if (!portfolio.title || portfolio.title.length < 5) {
      newErrors.title =
        "Tu dois indiquer le titre de ton portfolio (minimum 5 caractères)";
    }

    if (
      !portfolio.link &&
      portfolio?.attachments &&
      portfolio.attachments.length < 1
    ) {
      newErrors.noContent =
        "Tu dois indiquer un lien ou un fichier pour ton portfolio";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    callback(portfolio);
  };

  return (
    <motion.div
      key="portfolioKey"
      initial={{ y: 5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0, y: 5 }}
      className="flex flex-col gap-8 rounded-lg p-4 md:border"
    >
      <BlocTitle>
        <Title type="bloc">
          {initialData
            ? `Modifier ${initialData.title}`
            : `Ajouter un portfolio ou un autre document`}
        </Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-col items-start gap-6">
          <div className="flex w-full flex-col gap-2">
            <Text className="font-semibold">
              Titre<span style={{ color: "red" }}> * </span>
            </Text>
            <input
              name="title"
              value={portfolio.title}
              onChange={handleInputChange}
              type="text"
              placeholder="Mon Portfolio de développeur..."
              className={`w-full rounded-lg border p-2 focus:outline-blueViolet-600`}
            />
            {errors.title && (
              <Text className="text-sm text-red-500">{errors.title}</Text>
            )}
          </div>
          <div className="flex w-full flex-row gap-6">
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex flex-row items-end gap-1">
                <Text>Lien</Text>
                <Text className="text-sm">- Facultatif</Text>
              </div>
              <input
                value={portfolio.link}
                name="link"
                onChange={handleInputChange}
                type="text"
                placeholder="Lien vers le projet"
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <JoinFile onChange={handleFileChange} onRemove={handleFileRemove} />
          </div>
          {errors.noContent && (
            <Text className="text-sm text-red-500">{errors.noContent}</Text>
          )}
          <div className="flex w-full flex-row justify-end gap-4">
            <div onClick={cancel}>
              <Button type="cancel">Annuler</Button>
            </div>
            <div onClick={AddOrUpdate}>
              <Button type="full">
                {initialData ? "Modifier" : "Ajouter"}
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </motion.div>
  );
};

export default Form;
