// Requires
import { motion } from "framer-motion";

const FloatingNotification = ({
  position,
  children
}: {
  position:
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "top-center"
    | "bottom-center";
  children: React.ReactNode;
}) => {
  const getPositionStyle = (position: string) => {
    switch (position) {
      case "top-left":
        return { top: 20, left: 20, justifyContent: "start" };
      case "top-right":
        return { top: 20, right: 20, justifyContent: "end" };
      case "bottom-left":
        return { bottom: 20, left: 20, justifyContent: "start" };
      case "bottom-right":
        return { bottom: 20, right: 20, justifyContent: "end" };
      case "top-center":
        return { top: 20, justifyContent: "center" };
      case "bottom-center":
        return { bottom: 20, justifyContent: "center" };
    }
  };

  const getAnimationStyle = (position: string) => {
    switch (position) {
      case "top-center":
      case "bottom-center":
        return {
          initial: { opacity: 0, y: 15 },
          animate: { opacity: 1, y: 0 },
          exit: { opacity: 0, y: 15 }
        };
      case "top-left":
      case "bottom-left":
        return {
          initial: { opacity: 0, x: -15 },
          animate: { opacity: 1, x: 0 },
          exit: { opacity: 0, x: -15 }
        };
      case "top-right":
      case "bottom-right":
        return {
          initial: { opacity: 0, x: 15 },
          animate: { opacity: 1, x: 0 },
          exit: { opacity: 0, x: 15 }
        };
    }
  };

  return (
    <motion.div
      key={"animatedFloatingNotification"}
      {...getAnimationStyle(position)}
      className="absolute z-50 flex w-full flex-row"
      style={getPositionStyle(position)}
    >
      <div className="min-w-fit max-w-fit text-nowrap rounded-lg bg-black bg-opacity-50 px-3 py-1 text-white">
        {children}
      </div>
    </motion.div>
  );
};

export default FloatingNotification;
