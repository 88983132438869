// Components
import Button from "../UI/Button";
import Text from "../UI/Text";

const StaffMeAcademy = () => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4">
      <div className="flex flex-row gap-2">
        <Text className="font-semibold">
          Monte en compétences pour 0&euro; & en 1 semaine avec StaffMe Academy
        </Text>
        <img
          src="https://storagettv.blob.core.windows.net/static/StaffMeAcademyxJuwa.webp"
          className="h-[54px] w-[150px]"
          alt="StaffMe Academy"
        />
      </div>
      <Text>
        Profit de formations certifiées Qualiopi, 100% financées par l'Etat,
        pour te former dans un domaine précis.
      </Text>
      <Button
        type="full"
        className="w-fit"
        onClick={() => {
          window.open(
            "https://formations.staffmeacademy.fr/staffmeacademytaftavie",
            "_blank"
          );
        }}
      >
        Profiter de l'offre
      </Button>
    </div>
  );
};

export default StaffMeAcademy;
