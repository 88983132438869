// Requires
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Components
import Button from "../../../components/UI/Button";
import Highlight from "../../../components/UI/Highlight";
import Text from "../../../components/UI/Text";
import Title from "../../../components/UI/Title";

// Services
import { forgotPassword, resetPassword } from "../../../services/User/user";

// Validators
import {
  validateEmail,
  validatePassword
} from "../../../utils/helpers/validators";
import { ArrowLeft2 } from "iconsax-react";

const Forgot = () => {
  const [email, setEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>();
  const [counter, setCounter] = useState<number>(3); // Ajout du compteur

  const [searchParams] = useSearchParams();
  const isRedirected = searchParams.get("token");
  const navigate = useNavigate(); // Hook pour la redirection

  // Effet pour gérer la redirection après la modification du mot de passe
  useEffect(() => {
    if (success && isRedirected) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      // Redirection après 3 secondes
      const timeout = setTimeout(() => {
        navigate("/auth/login");
      }, 3000);

      // Cleanup de l'intervalle et du timeout
      return () => {
        clearInterval(timer);
        clearTimeout(timeout);
      };
    }
  }, [success, isRedirected, navigate]);

  const sendNewPassword = async () => {
    setIsLoading(true);
    setError("");

    if (!email || !validateEmail(email)) {
      setError("Vous devez indiquer une adresse email valide.");
      setIsLoading(false);
      return;
    }

    const response = await forgotPassword(email);

    if (response.error) {
      setError("Aucun compte avec cette adresse email.");
      setIsLoading(false);
      return;
    }

    setSuccess(true);
    setIsLoading(false);
  };

  const updatePassword = async () => {
    setError("");
    setIsLoading(true);
    if (!newPassword || !validatePassword(newPassword)) {
      setError("Vous devez indiquer un mot de passe valide.");
      setIsLoading(false);
      return;
    }

    if (newPassword && validatePassword(newPassword) && isRedirected) {
      const data = { newPassword: newPassword, token: isRedirected };
      const response = await resetPassword(data);
      if (response) {
        setIsLoading(false);
        setSuccess(true);
      }
    }
  };

  return (
    <div className="flex w-full place-content-center px-4">
      <div className="flex w-[30%] min-w-72 max-w-full flex-col gap-8 max-md:gap-4">
        <button
          className="flex cursor-pointer items-center gap-1 text-primaryPurple transition-colors duration-200 ease-in-out hover:text-blueViolet-600 active:text-blueViolet-700 max-md:mb-6"
          onClick={() => navigate("/auth/login")}
        >
          <ArrowLeft2 size={20} />
          Retour à la connexion
        </button>
        {!isRedirected && (
          <Title type="page">Recevoir un nouveau mot de passe</Title>
        )}
        {isRedirected && <Title type="page">Changer votre mot de passe</Title>}
        {success && (
          <Highlight type="Success">
            <div>
              {isRedirected ? (
                <div>
                  Votre mot de passe a bien été modifié, vous allez être
                  redirigé vers la page de connexion dans {counter} seconde
                  {counter > 1 ? "s" : ""}.
                </div>
              ) : (
                <div>
                  Un lien pour changer votre mot de passe vient de vous être
                  envoyé par email à l'adresse{" "}
                  <span className="font-semibold text-blueViolet-600">
                    {email}
                  </span>
                  .
                </div>
              )}
            </div>
          </Highlight>
        )}
        <div className="flex flex-col gap-2">
          {!isRedirected && <Text className="font-semibold">Email</Text>}
          {error && <Text className="text-sm text-red-500">{error}</Text>}
          {!isRedirected && (
            <input
              onChange={(e) => setEmail(e.currentTarget.value)}
              type="text"
              placeholder="votre-email@email.com"
              className="rounded-lg border p-2 focus:outline-blueViolet-600"
              value={email}
            />
          )}
          {isRedirected && isRedirected.length > 0 && (
            <input
              type="password"
              onChange={(e) => setNewPassword(e.currentTarget.value)}
              placeholder="Nouveau mot de passe"
              className="rounded-lg border p-2 focus:outline-blueViolet-600"
            />
          )}
        </div>
        <div className="flex flex-row justify-end">
          <div onClick={!isRedirected ? sendNewPassword : updatePassword}>
            <Button type="full">
              {isLoading ? (
                <div className="loader" />
              ) : isRedirected ? (
                "Modifier"
              ) : (
                "Envoyer"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
