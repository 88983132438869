// Components
import AdminDashboard from "../../../components/Dashboard/Admin/Dashboard";
import CompanyDashboard from "../../../components/Dashboard/Company/Dashboard";
import StudentDashboard from "../../../components/Dashboard/Student/Dashboard";
import UpdateDisponibility from "../../../components/Dashboard/Student/UpdateDisponibility";
import Title from "../../../components/UI/Title";
import DashboardLoading from "../.././Loading/DashboardLoading";

// Helpers
import { capitalizeWords } from "../../../utils/helpers/capitalizeWords";

// Store
import { useUser } from "../../../utils/store/userStore";

export const Dashboard = () => {
  const user = useUser((state) => state.userdata);

  let welcomeMessage = "";
  if (user) {
    switch (user.roleName) {
      case "admin":
        welcomeMessage = `Bonjour ${capitalizeWords(
          user?.firstName
        )}, bienvenue sur votre tableau de bord`;
        break;
      case "student":
        welcomeMessage = `Bonjour ${capitalizeWords(
          user?.firstName
        )}, ravis de te retrouver !`;
        break;
      case "company":
        welcomeMessage = `Bonjour ${capitalizeWords(
          user?.firstName
        )}, quel est votre besoin aujourd'hui ?`;
        break;
      default:
        break;
    }
  }

  if (user) {
    return (
      <div className="flex flex-col gap-8">
        <Title type="page">{welcomeMessage}</Title>
        {user?.roleName === "student" && <UpdateDisponibility />}
        {user?.roleName === "company" && <CompanyDashboard />}
        {user?.roleName === "student" && <StudentDashboard />}
        {user?.roleName === "admin" && <AdminDashboard />}
      </div>
    );
  }

  return <DashboardLoading />;
};
