// Types
import { LanguagesType } from "../../types/misc";
import type {
  CertificationType,
  CustomizationType,
  ExperiencesType,
  FormationsType,
  PortfolioType,
  StudentAvailabilityType,
  UserLogin,
  UserRegisterType,
  UserStudentMainInfoType,
  ValidateUserEmail,
  WeeklyAvailabilityType
} from "../../types/user";

// Helpers
import { removeFromLocalStorage } from "../../utils/helpers/localStorage";

// Requires
import { fetchApi } from "../Misc/api";

// Retrieve basic information of logged in user
export const getUser = async () => {
  const response = await fetchApi("/user/", "GET");
  if (response.error) return false;
  return response;
};

// Retrieve a specific user
export const getSpecificUser = async (id: number) => {
  const response = await fetchApi(`/user/${id}`, "GET");
  return response;
};

// Login user
export const loginUser = async (data: UserLogin) => {
  const response = await fetchApi("/auth/login", "POST", data);
  if (response.error) return false;
  return true;
};

export const fcm = async (token: string) => {
  const response = await fetchApi(`/notification?token=${token}`, "POST");
  if (response.error) return false;
  return true;
};

export const login = async (data: UserLogin) => {
  const response = await fetchApi("/auth/login", "POST", data);
  if (response.error) return false;
  return true;
};

// Logout user
export const logoutUser = async () => {
  removeFromLocalStorage("token");
  removeFromLocalStorage("refresh");
  return true;
};

// Register a new user
export const registerUser = async (data: UserRegisterType) => {
  const response = await fetchApi("/auth/register", "POST", data);
  if (response.error) return response;
  return true;
};

// Update the student's main information
export const updateUserMainInfo = async (data: UserStudentMainInfoType) => {
  const response = await fetchApi("/student/professionalization", "POST", data);
  if (response.error) return false;

  return true;
};

// Validate the user's email using the code sent
export const validateUserEmail = async (data: ValidateUserEmail) => {
  const response = await fetchApi("/auth/confirm-email", "POST", data);
  if (response.error) return false;

  return true;
};

// Send a new confirmation code by email
export const resendEmailConfirmation = async () => {
  const response = await fetchApi("/auth/resend-email", "GET");
  if (response.error) return false;

  return true;
};

// Update the user's email address
export const updateEmail = async (data: { email: string }) => {
  const response = await fetchApi("/user/change-email", "POST", data);
  if (response.error) return false;

  return true;
};

// Update student's experience
export const updateExperience = async (data: ExperiencesType) => {
  const response = await fetchApi("/student/experiences", "POST", data);
  if (response.error) return false;

  return true;
};

// Update student's formation
export const updateFormation = async (data: FormationsType) => {
  const response = await fetchApi("/student/formations", "POST", data);
  if (response.error) return false;

  return true;
};

// Update student's portfolio
export const updatePorfolio = async (data: PortfolioType | FormData) => {
  const response = await fetchApi("/student/portfolio", "POST", data);
  if (response.error) return false;

  return true;
};

// Update student's certification
export const updateCertification = async (
  data: CertificationType | FormData
) => {
  const response = await fetchApi("/student/certificate", "POST", data);
  if (response.error) return false;

  return true;
};

// Update student's school certificate
export const updateSchoolCertificate = async (data: FormData) => {
  const response = await fetchApi("/student/school-certificate", "POST", data);
  if (response.error) return false;

  return true;
};

// Get own school certificate
export const getOwnSchoolCertificate = async () => {
  const response = await fetchApi("/student/school-certificate", "GET");
  // if (response.error) return false;

  return response;
};

// Send linkedIn PDF and auto-complete student onboarding
export const sendOnboardingPdf = async (data: FormData) => {
  const response = await fetchApi("/pdf/student/", "POST", data);
  if (response.error) return false;

  return response;
};

// Update student's profil
export const updateCustomization = async (data: CustomizationType) => {
  const response = await fetchApi("/student/customization", "POST", data);
  if (response.error) return false;

  return true;
};

// Search a company on Pappers with siret/name/siren query
export const searchSiret = async (data: string) => {
  const response = await fetchApi(`/misc/companies?search=${data}`, "GET");
  return response;
};

// Retrieve information about a company using SIRET number
export const getCompanyInformations = async (siret: string) => {
  const response = await fetchApi(`/misc/company?siret=${siret}`, "GET");
  if (response.error) return false;

  return response;
};

// Update student's company
export const updateStudentCompany = async (siret: string) => {
  const data = {
    siret: siret
  };
  const response = await fetchApi("/student/company", "POST", data);
  if (response.error) return false;

  return true;
};

// Change a phone number for user
export const changePhoneNumber = async (data: { phone: string }) => {
  const response = await fetchApi("/user/change-phone", "POST", data);
  if (response.error) return response;

  return true;
};

// Valide a phone number with SMS Code for user
export const validatePhoneNumberCode = async (data: { code: string }) => {
  const response = await fetchApi("/auth/confirm-phone", "POST", data);
  if (response.error) return false;

  return true;
};

// Send a new SMS validation code
export const resendPhoneNumberCode = async () => {
  const response = await fetchApi("/auth/resend-phone", "GET");
  if (response.error) return false;

  return true;
};

// Register a new user (with company role)
export const registerCompanyUser = async (data: {
  email: string;
  password: string;
  role: string;
}) => {
  const response = await fetchApi("/auth/register", "POST", data);
  return response;
};

// Update user profile (with company role)
export const companySetProfile = async (data: {
  firstName: string;
  lastName: string;
  phone: string;
  gender?: string;
  birthdate?: string;
}) => {
  if (data.birthdate === "") {
    delete data.birthdate;
  }
  const response = await fetchApi("/company/profile", "POST", data);
  if (response.error) return false;

  return true;
};

// Set a new company to user (with company role)
export const setCompanyToCompanyUser = async (data: {
  siret?: string;
  userActivity?: string;
  companyActivity?: number;
  companySizeId?: number;
}) => {
  const updatedData = {
    siret: data.siret || "",
    userActivity: data.userActivity || "",
    companyActivity: data.companyActivity || 0,
    companySizeId: data.companySizeId || 1
  };
  const response = await fetchApi("/company/link", "POST", updatedData);

  if (response.error) return false;

  return true;
};

// Change user password
export const updatePassword = async (data: {
  oldPassword: string;
  newPassword: string;
}) => {
  const response = await fetchApi("/auth/change-password", "POST", data);
  if (response.error) return false;

  return true;
};

// Set/Update Student Profile (image, banner and presentation)
export const updateStudentProfile = async (data: FormData) => {
  const response = await fetchApi("/user/profile", "POST", data);
  if (response.error) return false;

  return true;
};

export const accountDeletion = async (roleName: string) => {
  // const response = await fetchApi(`/${roleName}/user/`, "DELETE");
  const response = await fetchApi(`/user/`, "DELETE");
  if (response.error) return false;

  return true;
};

export const updateIdentity = async (
  data: {
    firstName: string;
    lastName: string;
    birth: string;
    gender: "male" | "female" | "unspecified";
  },
  roleName: string
) => {
  const response = await fetchApi(`/user/identity`, "POST", data);
  if (response.error) return false;
  return true;
};

export const updateGeneralInformations = async (
  data: {
    phoneNumber: string;
    email: string;
    isTih: boolean;
    languagesId: LanguagesType[];
  },
  roleName: string
) => {
  const formatedData = {
    email: data.email,
    isTih: data.isTih,
    phoneNumber: data.phoneNumber,
    languagesId: data.languagesId.map((item) => item.id)
  };

  // const response = await fetchApi(
  //   `/${roleName}/general-info`,
  //   "POST",
  //   formatedData
  // );
  const response = await fetchApi(`/user/general-info`, "POST", formatedData);

  if (response.error) return false;

  return true;
};

export const forgotPassword = async (email: string) => {
  const data = {
    email: email
  };

  const response = await fetchApi("/auth/forgot-password", "POST", data);
  if (response) return response;
};

export const resetPassword = async (data: {
  newPassword: string;
  token: string;
}) => {
  const response = await fetchApi("/auth/reset-password", "POST", data);
  if (response) return response;
};

// Delete a portfolio from id
export const deletePortfolio = async (id: number) => {
  const response = await fetchApi(`/student/portfolio/${id}`, "DELETE");
  if (response.error) return false;
  return true;
};

// Delete a certification from id
export const deleteCertification = async (id: number) => {
  const response = await fetchApi(`/student/certificate/${id}`, "DELETE");
  if (response.error) return false;
  return true;
};

// Add Or Update alternation and internship
export const updateAvailability = async (data: {
  availability: StudentAvailabilityType[];
}) => {
  const response = await fetchApi("/student/availability", "POST", data);
  if (response.error) return false;

  return true;
};

// Add Or Update Weekly Availability
export const updateWeeklyAvailability = async (data: {
  studentWeeklyAvailability: WeeklyAvailabilityType[];
  workMode: "remote" | "onSite" | "hybrid" | undefined;
  transportTypes: string[];
}) => {
  const response = await fetchApi("/student/weekly-availability", "POST", data);
  if (response.error) return false;

  return true;
};

export const getLemonwayAccountId = async (userId: number) => {
  const response = await fetchApi(`/payment/account/${userId}`, "GET");
  if (response.error) return false;
  return response;
};
