// Requires
import { useParams } from "react-router-dom";

// Components
import Button from "../UI/Button";

// Context
import { useFloatingNotification } from "../../context/FloatingNotification";

// Services
import { validateJob } from "../../services/Admin/Jobs";

const AdminTools = () => {
  const { id } = useParams();

  const { toggleFloatingNotification } = useFloatingNotification();

  const copyUniqueId = () => {
    const url = `${id}`;
    navigator.clipboard.writeText(url);
    toggleFloatingNotification(
      "Identifiant unique copié dans le presse-papier."
    );
  };

  const handleValidateJob = async () => {
    if (id) {
      const response = await validateJob(parseInt(id));
      if (response) {
        toggleFloatingNotification("La mission a bien été validée.");
        setTimeout(() => {
          window.location.reload();
        }, 200);
        return;
      }

      alert("Une erreur est survenu");
      return;
    }
  };

  return (
    <div className="rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row flex-wrap gap-4">
        <div onClick={copyUniqueId}>
          <Button type="full">Copier l'identifiant unique de la mission</Button>
        </div>
        <Button type="full">Supprimer la mission</Button>
        <Button type="full">Editer la mission</Button>
        <Button type="full">Ajouter à la shortlist</Button>
        <div onClick={handleValidateJob}>
          <Button type="full">Valider la mission</Button>
        </div>
      </div>
    </div>
  );
};

export default AdminTools;
