export function capitalizeWords(sentence: string | undefined): string | null {
  if (sentence) {
    return sentence
      .split(" ") // Divise la phrase en mots
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Met en majuscule la première lettre de chaque mot
      .join(" "); // Recompose la phrase
  }

  return null;
}
