import React, { useId } from "react";
import { Tooltip } from "react-tooltip";

type Position = "top" | "left" | "right" | "bottom";

interface CustomTooltipProps {
  children: React.ReactNode;
  position: Position;
  content: string;
  className?: string;
  id?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  children,
  position,
  content,
  className = "",
  id
}) => {
  const generatedId = useId();
  const tooltipId = id || generatedId;

  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        data-tooltip-content={content}
        data-tooltip-place={position}
      >
        {children}
      </div>
      <Tooltip
        style={{ backgroundColor: "#33367c" }}
        id={tooltipId}
        className={`z-50 bg-blueViolet-900 ${className}`}
      />
    </>
  );
};

export default CustomTooltip;
