// Requires
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";

// Components
import Button from "../UI/Button";
import Text from "../UI/Text";

// Helpers
import { phoneNumberIndicatif } from "../../utils/misc";

// Utils
import { validatePhoneNumber } from "../../utils/helpers/validators";

// Services
import {
  changePhoneNumber,
  resendPhoneNumberCode,
  validatePhoneNumberCode
} from "../../services/User/user";

// Type

export type ErrorType = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  phoneNumberId?: string;
  phoneNumberValidation?: string;
  language?: string;
  workMode?: string;
  city?: string;
  transportType?: string;
  alternationError?: {
    start?: string;
    end?: string;
    description?: string;
  };
  internshipError?: {
    start?: string;
    end?: string;
    description?: string;
  };
};

interface PhoneValidationComponentProps {
  error: ErrorType;
  setError: Dispatch<SetStateAction<ErrorType>>;
  phoneNumber: string;
  phoneNumberIsValidate: boolean;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setPhoneNumberIsValidate: Dispatch<SetStateAction<boolean>>;
  phoneNumberId: string;
  setPhoneNumberId: Dispatch<SetStateAction<string>>;
  title?: boolean;
}

const PhoneValidationComponent = ({
  error,
  setError,
  phoneNumberIsValidate,
  setPhoneNumberIsValidate,
  phoneNumber,
  setPhoneNumber,
  phoneNumberId,
  setPhoneNumberId,
  title = true
}: PhoneValidationComponentProps) => {
  const [phoneNumberValidatorIsVisible, setPhoneNumberValidatorIsVisible] =
    useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [resendCode, setResendCode] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendCodeToPhoneNumber = async () => {
    const newError: ErrorType = { alternationError: {}, internshipError: {} };
    setError(newError);
    setIsLoading(true);

    if (!phoneNumberId) {
      newError.phoneNumberId = "L'indicatif n'est pas valide";
    }

    if (
      phoneNumber === "" ||
      !phoneNumberId ||
      !validatePhoneNumber(phoneNumberId + phoneNumber)
    ) {
      newError.phoneNumber = "Tu dois entrer un numéro de téléphone valide";
    }

    // Check if any errors are present
    const hasErrors = Object.keys(newError).some(
      (key) =>
        newError[key as keyof ErrorType] !== undefined &&
        Object.keys(newError[key as keyof ErrorType]!).length > 0
    );

    if (hasErrors) {
      setError(newError);
      setPhoneNumberValidatorIsVisible(false);
      setIsLoading(false);
      return;
    }

    const response = await changePhoneNumber({
      phone: phoneNumberId + phoneNumber
    });

    if (response.error) {
      newError.phoneNumber =
        "Le numéro de téléphone est indentique au précédent, aucun nouveau code n'a été envoyé";
      setError({ ...newError });
      setIsLoading(false);
      return;
    }

    setPhoneNumberValidatorIsVisible(true);
    setIsLoading(false);

    return false;
  };

  const confirmPhoneNumberCode = async () => {
    setResendCode(undefined);
    const newError: ErrorType = { alternationError: {}, internshipError: {} };

    if (!code || (code && code.length < 1)) {
      newError.phoneNumberValidation = "Tu dois indiquer le code de validation";
      setError({ ...newError });
      return;
    }

    setError({ ...newError });

    if (code && code.length === 6) {
      const response = await validatePhoneNumberCode({ code: code });
      if (response) {
        setPhoneNumberIsValidate(true);
        return;
      }

      newError.phoneNumberValidation = "Le code n'est pas valide ou à expiré";
      setError({ ...newError });
      return;
    }
  };

  const sendNewPhoneNumberCode = async () => {
    const response = await resendPhoneNumberCode();
    if (response) {
      setResendCode("Un nouveau code à était envoyé");
    }
  };

  return (
    <>
      <div className="flex flex-col items-center gap-8 md:flex-row">
        <div className="flex w-full flex-1 flex-col gap-2">
          {title && (
            <Text>
              Téléphone<span style={{ color: "red" }}> * </span>
            </Text>
          )}
          <div className="flex flex-col gap-4 md:flex-row md:items-center">
            <div className="flex flex-row gap-1 md:gap-4">
              <select
                onChange={(e) => setPhoneNumberId(e.currentTarget.value)}
                className="rounded-lg border p-2 focus:outline-blueViolet-600"
              >
                {phoneNumberIndicatif.map((e, _i) => (
                  <option key={_i} value={e.code}>
                    {e.code}
                  </option>
                ))}
              </select>
              <input
                value={phoneNumber}
                disabled={!!phoneNumberIsValidate}
                onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                name="phoneNumber"
                type="text"
                className={`rounded-lg border p-2 focus:outline-blue-600 max-md:w-full ${error.phoneNumber ? "border-red-500" : "border"}`}
                placeholder="Indique ton numéro de téléphone"
              />
            </div>
            {!phoneNumberIsValidate && (
              <div onClick={sendCodeToPhoneNumber}>
                <Button type="fullLite">
                  {isLoading ? (
                    <div className="loader" />
                  ) : (
                    "Vérifier le numéro"
                  )}
                </Button>
              </div>
            )}
            {phoneNumberIsValidate && (
              <Button
                className=":hover:scale-100 cursor-default bg-lime-300"
                type="validated"
              >
                Le numéro de téléphone est validé
              </Button>
            )}
          </div>
          {error.phoneNumber && (
            <Text className="text-sm text-red-500">{error.phoneNumber}</Text>
          )}
        </div>
      </div>
      <AnimatePresence>
        {!phoneNumberIsValidate && phoneNumberValidatorIsVisible && (
          <motion.div
            key="phoneNumberValidatorAnimate"
            initial={{ y: 5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0, y: 5 }}
            className="flex flex-col gap-2 rounded-lg border p-2"
          >
            <div className="rounded-lg bg-whiteSimple p-2">
              <Text className="text-sm">
                Nous avons envoyé un code de confirmation à six caractères au{" "}
                <span className="text-blueViolet-600">
                  {phoneNumber ? phoneNumber : "00.00.00.00.00"}
                </span>{" "}
                afin de vérifier qu'il s'agit bien de toi. Ce code est valable
                pendant 15 minutes.
              </Text>
            </div>
            <div className="flex flex-col gap-2">
              <Text className="font-semibold">
                Saisir le code de confirmation
              </Text>
              <div className="flex flex-row items-center gap-2">
                <input
                  onChange={(e) => setCode(e.currentTarget.value)}
                  type="text"
                  placeholder="_ _ _ _ _ _"
                  maxLength={6}
                  className="w-fit rounded-lg border p-2 focus:outline-blueViolet-600 max-md:w-full max-md:shrink"
                />
                <div onClick={confirmPhoneNumberCode}>
                  <Button type="full">Valider</Button>
                </div>
              </div>
              {resendCode && (
                <Text className="text-sm text-blueViolet-600">
                  {resendCode}
                </Text>
              )}
              <div className="flex flex-row items-center gap-1">
                <Text className="text-sm">Tu n'as pas reçu le code ?</Text>
                <Link
                  onClick={sendNewPhoneNumberCode}
                  to="#"
                  className="text-sm text-blueViolet-600 underline"
                >
                  Recevoir un nouveau code
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {error.phoneNumberValidation && (
        <Text className="text-sm text-red-500">
          {error.phoneNumberValidation}
        </Text>
      )}
    </>
  );
};

export default PhoneValidationComponent;
