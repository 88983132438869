const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&_\-.])[A-Za-z\d@$!%*?&_\-.]{6,}$/;
const phoneNumberRegex = /^\+\d{1,3}\d{1,14}$/;
const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

export const validateEmail = (email: string) => {
  return emailRegex.test(email);
};

export const validatePassword = (password: string) => {
  return passwordRegex.test(password);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return phoneNumberRegex.test(phoneNumber);
};

export const validateIban = (iban: string) => {
  return ibanRegex.test(iban);
};

export const validateBic = (bic: string) => {
  return bicRegex.test(bic);
};
