// Proposal.tsx
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Icons
import { Menu, TextalignJustifyleft } from "iconsax-react";

// Components
import Content from "../../../../../../../components/Bloc/Content";
import { BlocTitle } from "../../../../../../../components/Bloc/Title";
import Estimate from "../../../../../../../components/Jobs/Proposal/Estimate";
import Facturation from "../../../../../../../components/Jobs/Proposal/Facturation";
import Resume from "../../../../../../../components/Jobs/Proposal/Resume";
import Button from "../../../../../../../components/UI/Button";
import JoinFile from "../../../../../../../components/UI/File/JoinFile";
import Text from "../../../../../../../components/UI/Text";
import Title from "../../../../../../../components/UI/Title";
import {
  validateContent,
  validatePrice,
  validateQuantity
} from "../../../../../../../components/Jobs/Proposal/Line";

// Stores
import { useUser } from "../../../../../../../utils/store/userStore";

// Services
import { getJobDetails } from "../../../../../../../services/Job/job";
import { createNewProposal } from "../../../../../../../services/Job/proposal";
import { updateStudentCompany } from "../../../../../../../services/User/user";

// Types
import { JobDetailsType } from "../../../../../../../types/job";
import {
  ProposalQuotation,
  Proposal as ProposalType
} from "../../../../../../../types/proposal";
import { usePopup } from "../../../../../../../context/Popup";
import Avatar from "../../../../../../../components/UI/Avatar/Avatar";

export type ProposalErrorsType = {
  motivation?: string;
  facturation?: string;
  attachments?: string;
  [key: string]: string | undefined;
};

export enum ProposalFields {
  motivation = "motivation",
  line = "line",
  content = "content",
  price = "price",
  quantity = "quantity",
  attachments = "attachments"
}

const Proposal = () => {
  const { id } = useParams();
  const { togglePopup } = usePopup();
  const navigate = useNavigate();

  const user = useUser((state) => state.userdata);

  const [data, setData] = useState<ProposalType>({
    motivation: "",
    quotation: [{ content: "", price: 15, quantity: 1 }],
    attachments: []
  });
  const [errors, setErrors] = useState<ProposalErrorsType>({});
  const [job, setJob] = useState<JobDetailsType>();

  const validateMotivation = (value: string): string => {
    if (!value.trim()) {
      return "La motivation ne peut pas être vide.";
    }
    if (value.length < 50) {
      return "La motivation doit contenir au moins 50 caractères.";
    }
    return "";
  };

  const validateAttachments = (files: File[]): string => {
    const maxSize = 20 * 1024 * 1024; // 20MB
    for (const file of files) {
      if (file.size > maxSize) {
        return `Le fichier ${file.name} dépasse la taille maximale de 20MB.`;
      }
      // Optionally, validate file types
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        return `Le fichier ${file.name} a un type non autorisé.`;
      }
    }
    return "";
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));

    // Perform validation based on the field name
    let error = "";
    if (name === "motivation") {
      error = validateMotivation(value);
    }
    // Add validations for other fields if necessary

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error
    }));
  };

  const updateQuotation = (quotation: ProposalQuotation, id: number) => {
    setData((prev) => {
      const updatedQuotations = [...prev.quotation];
      updatedQuotations[id] = { ...quotation };

      return {
        ...prev,
        quotation: updatedQuotations
      };
    });
  };

  const removeQuotation = (id: number) => {
    setData((prev) => {
      const updatedQuotations = prev.quotation.filter(
        (_, index) => index !== id
      );

      return {
        ...prev,
        quotation: updatedQuotations
      };
    });
  };

  const handleFileChange = (files: File[]) => {
    if (files) {
      const newAttachments = [...(data.attachments ?? []), ...files];
      setData((prev) => ({
        ...prev,
        attachments: newAttachments
      }));

      const attachmentError = validateAttachments(newAttachments);
      setErrors((prevErrors) => ({
        ...prevErrors,
        attachments: attachmentError
      }));
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedAttachments =
      data.attachments?.filter((_, i) => i !== index) ?? [];
    setData((prev) => ({
      ...prev,
      attachments: updatedAttachments
    }));

    const attachmentError = validateAttachments(updatedAttachments);
    setErrors((prevErrors) => ({
      ...prevErrors,
      attachments: attachmentError
    }));
  };

  const validateAddress = (address: string): string => {
    if (!address.trim()) {
      return "L'adresse ne peut pas être vide.";
    }
    return "";
  };

  const validateSiret = (siret: string): string => {
    if (!siret.trim()) {
      return "Le SIRET ne peut pas être vide.";
    }
    if (siret.length !== 14) {
      return "Le SIRET doit contenir 14 chiffres.";
    }
    return "";
  };

  const createProposal = async () => {
    const newErrors: ProposalErrorsType = {};

    const motivationError = validateMotivation(data.motivation);
    if (motivationError) {
      newErrors.motivation = motivationError;
    }

    const attachmentsError = validateAttachments(data.attachments ?? []);
    if (attachmentsError) {
      newErrors.attachments = attachmentsError;
    }

    const addressError = validateAddress(
      user?.legalInfos?.address || data.address || ""
    );
    if (addressError) {
      newErrors.address = addressError;
    }

    const siretError = validateSiret(
      user?.legalInfos?.siret || data.siret || ""
    );
    if (siretError) {
      newErrors.siret = siretError;
    }

    if (data.quotation.length === 0) {
      newErrors.quotation = "Ajoutez au moins une ligne de devis.";
    }

    data.quotation.forEach((item, index) => {
      const contentError = validateContent(item.content);
      const priceError = validatePrice(item.price);
      const quantityError = validateQuantity(item.quantity);

      if (contentError) {
        newErrors[`content${index}`] = contentError;
      }
      if (priceError) {
        newErrors[`price${index}`] = priceError;
      }
      if (quantityError) {
        newErrors[`quantity${index}`] = quantityError;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = new FormData();

    formData.append("motivation", data.motivation);
    formData.append("specificMentions", data.specificMentions || "");

    formData.append(
      "proposalQuotation",
      JSON.stringify(
        data.quotation.map((item) => ({
          content: item.content,
          price: item.price,
          quantity: item.quantity
        }))
      )
    );

    data.attachments?.forEach((file) => {
      formData.append("attachments", file);
    });

    if (!user?.legalInfos?.siret) {
      if (!data.siret) {
        setErrors({ ...newErrors, siret: "Le SIRET ne peut pas être vide." });
        return;
      }

      try {
        await updateStudentCompany(data.siret);
      } catch (error) {
        setErrors({
          ...newErrors,
          submission:
            "Une erreur est survenue lors de l'envoi. Veuillez réessayer."
        });
        return;
      }
    }

    try {
      const response = await createNewProposal(formData, Number(id));
      if (response) navigate(`/student/jobs/${id}`);
    } catch (error) {
      setErrors({
        ...newErrors,
        submission:
          "Une erreur est survenue lors de l'envoi. Veuillez réessayer."
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getJobDetails(id);
      setJob(response);
    };

    fetch();
  }, [id]);

  if (!job)
    return (
      <div className="flex items-center justify-center">
        <div className="loader" />
      </div>
    );

  return (
    <div className="flex flex-col gap-24 max-md:gap-6">
      <div className="flex flex-col gap-1">
        <Title type="page">
          Créé ta proposition commerciale - {job?.title}
        </Title>
        <Text className="text-sm text-blueViolet-600">
          Pour {job?.owner.firstName} {job?.owner.lastName} de{" "}
          {job?.company.displayName}
        </Text>
      </div>
      <div className="flex flex-row gap-8 max-md:flex-col-reverse max-md:gap-4">
        <Button
          className="md:hidden"
          onClick={createProposal}
          type={`${Object.values(errors).some((error) => error !== "") ? "disabled" : "full"}`}
        >
          Soumettre la proposition
        </Button>

        <div className="flex grow flex-col gap-4">
          <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
            <BlocTitle>
              <TextalignJustifyleft size={18} />
              <Title type="bloc">
                Ajoute une description à ta proposition
                <span className="text-red-500"> *</span>
              </Title>
            </BlocTitle>
            <textarea
              name="motivation"
              onChange={handleInputChange}
              value={data.motivation}
              className={`h-[150px] resize-none rounded-lg border p-2 focus:outline-blueViolet-600 ${
                errors.motivation ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="Décris tes motivations, tes expériences et tout ce qui justifie de tes qualités pour la mission"
            />
            {errors.motivation && (
              <p className="text-sm text-red-500">{errors.motivation}</p>
            )}
            <JoinFile
              title={true}
              onChange={handleFileChange}
              onRemove={handleRemoveFile}
            />
            {errors.attachments && (
              <p className="text-sm text-red-500">{errors.attachments}</p>
            )}
          </div>

          <Estimate
            updateQuotation={updateQuotation}
            removeQuotation={removeQuotation}
            errors={errors}
            setErrors={setErrors}
          />

          <Facturation
            onChange={handleInputChange}
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <div className="flex min-w-[350px] flex-col gap-4">
          <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
            <BlocTitle>
              <Menu size={18} />
              <Title type="bloc">Récapitulatif de la mission</Title>
            </BlocTitle>
            <Content>
              <div className="flex flex-row gap-4">
                <Avatar
                  link={job?.owner.image}
                  className="size-10 rounded-lg"
                />
                <div className="flex flex-col justify-between">
                  <Text className="font-semibold">
                    {job?.owner.firstName} {job?.owner.lastName}
                  </Text>
                  <Text className="text-sm">{job?.company.displayName}</Text>
                </div>
              </div>
              <div onClick={() => togglePopup("jobDetails")}>
                <Button type="fullLite">Voir le rappel de la mission</Button>
              </div>
            </Content>
          </div>
          {data.quotation &&
            data.quotation.length > 0 &&
            data.quotation.every(
              (item) =>
                item.price > 0 &&
                item.quantity > 0 &&
                item.content.trim() !== ""
            ) && <Resume data={data} />}
          {errors.submission && (
            <p className="text-sm text-red-500">{errors.submission}</p>
          )}
          <Button
            className="max-md:hidden"
            onClick={createProposal}
            type={`${Object.values(errors).some((error) => error !== "") ? "disabled" : "full"}`}
          >
            Soumettre la proposition
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Proposal;
