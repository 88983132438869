// Require
import { motion } from "framer-motion";
import { useState } from "react";

// Icons
import { ArrowRight, DocumentDownload, LampOn } from "iconsax-react";

// Components
import Text from "./Text";

type ButtonType =
  | "outline"
  | "full"
  | "arrow"
  | "lite"
  | "info"
  | "fullLite"
  | "cancel"
  | "disabled"
  | "validated";

const Button = ({
  children,
  type,
  className,
  onClick
}: {
  children: React.ReactNode;
  type: ButtonType;
  className?: string;
  onClick?: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  if (type === "full") {
    return (
      <div
        className={`flex cursor-pointer items-center justify-center text-nowrap rounded-lg bg-blueViolet-600 px-4 py-2 text-white duration-150 hover:bg-blueViolet-700 ${className}`}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

  if (type === "fullLite") {
    return (
      <div
        className={`flex cursor-pointer flex-row items-center justify-center gap-2 text-nowrap rounded-lg bg-blueViolet-50 px-4 py-2 text-blueViolet-600 duration-150 hover:bg-blueViolet-100 ${className}`}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

  if (type === "outline") {
    return (
      <motion.div
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        onTap={() => setIsHovered(true)}
        onTouchEndCapture={() => setIsHovered(false)}
        className={`group relative flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg border border-blueViolet-600 px-4 py-2 ${className}`}
        onClick={onClick}
      >
        <motion.div
          initial={{ width: "100%", height: 0 }}
          animate={{ height: isHovered ? "100%" : 0 }}
          className="absolute bottom-0 left-0 h-full w-3 bg-blueViolet-600"
        />
        <span className="z-40 text-blueViolet-600 group-hover:text-white">
          {children}
        </span>
      </motion.div>
    );
  }

  if (type === "arrow") {
    return (
      <div
        onClick={onClick}
        className="flex cursor-pointer flex-row items-center gap-1 rounded-lg border border-backgroundGray px-2 py-1 text-sm hover:text-primaryOrange"
      >
        <Text>{children}</Text>
        <ArrowRight size={18} />
      </div>
    );
  }

  if (type === "info") {
    return (
      <div
        onClick={onClick}
        className="flex cursor-pointer flex-row items-center gap-2 rounded-lg bg-blueViolet-50 px-2 py-2 text-blueViolet-600"
      >
        <LampOn size={18} /> <Text className="text-sm">{children}</Text>
      </div>
    );
  }

  if (type === "lite") {
    return (
      <div
        onClick={onClick}
        className="flex cursor-pointer flex-row items-center gap-2 rounded-lg bg-blueViolet-50 px-2 py-1 text-sm hover:text-blueViolet-950"
      >
        <DocumentDownload size={18} />
        <Text>{children}</Text>
      </div>
    );
  }

  if (type === "cancel") {
    return (
      <div
        onClick={onClick}
        className={`flex cursor-pointer items-center justify-center text-nowrap rounded-lg bg-transparent px-4 py-2 text-red-500 ${className}`}
      >
        {children}
      </div>
    );
  }

  if (type === "disabled") {
    return (
      <div
        className={` ${className} flex cursor-default items-center justify-center text-nowrap rounded-lg bg-withCustom-200 px-4 py-2 text-withCustom-500 duration-150`}
      >
        {children}
      </div>
    );
  }

  if (type === "validated") {
    return (
      <div
        onClick={onClick}
        className={` ${className} flex cursor-pointer items-center justify-center text-nowrap rounded-lg px-4 py-2 text-withCustom-500 duration-150`}
      >
        {children}
      </div>
    );
  }

  return null;
};

export default Button;
