// Icons
import { Menu } from "iconsax-react";

// Components
import Title from "../../../UI/Title";

// Assets
import graphique from "../../../../assets/graphique.png";

const CAEvolution = () => {
  return (
    <div className="flex w-full flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <Menu size={18} />
        <Title type="bloc">Evolution du chiffre d'affaires</Title>
      </div>
      <div className="flex-col gap-4">
        <div className="relative flex">
          <img src={graphique} className="w-full blur-sm" />
          <div className="absolute flex h-full w-full items-center justify-center">
            <div className="absolute rounded-lg border bg-white p-2 shadow-lg">
              Aucun chiffre d'affaires pour le moment
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CAEvolution;
