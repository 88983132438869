// Requires
import { Outlet } from "react-router-dom";

// Layout
import Layout from "../pages/Auth/Layout";

// Pages
import Login from "../pages/Auth/Login/Login";
import Logout from "../pages/Auth/Logout/Logout";
import Register from "../pages/Auth/Register/Register";
import Student from "../pages/Auth/Register/Student/Student";
import Company from "../pages/Auth/Register/Company/Company";
import Forgot from "../pages/Auth/Forgot/Forgot";

export const authRouters = {
  path: "auth",
  element: <Layout />,
  children: [
    {
      path: "login",
      element: <Login />
    },
    {
      path: "forgot",
      element: <Forgot />
    },
    {
      path: "register",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <Register />
        },
        {
          path: "student",
          element: <Student />
        },
        {
          path: "company",
          element: <Company />
        }
      ]
    },
    {
      path: "logout",
      element: <Logout />
    }
  ]
};
