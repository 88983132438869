// Requires
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

// Type
type LinksType = {
  name: string;
  path?: string;
  icon?: React.ReactElement;
  sub?: LinksType[];
};

const URL = ({
  classname,
  target,
  children,
  style,
  sub,
  extern,
  callback
}: {
  classname: string;
  target?: string;
  children: React.ReactNode;
  style: React.CSSProperties;
  sub?: LinksType[];
  extern?: boolean;
  callback?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (callback) {
      callback();
    }
  }, [isOpen]);

  const Component = sub ? "div" : Link;

  return (
    <div>
      <Component
        className={`${classname} group flex flex-row justify-between text-sm hover:text-primaryPurple`}
        to={target || "#"}
        style={style}
        onClick={() => sub && setIsOpen((prev) => !prev)}
        target={extern ? "_blank" : undefined}
      >
        {children}
      </Component>
      <AnimatePresence>
        {isOpen && sub && (
          <motion.ul
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="flex flex-col gap-2 pl-[51px] text-sm"
          >
            {sub.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.path || ""}
                  className="flex items-center gap-2 hover:text-primaryPurple"
                >
                  {item.icon}
                  <span>{item.name}</span>
                </Link>
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default URL;
