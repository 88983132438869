// Requires
import { fetchApi } from "../Misc/api";

// Retrieve charts data for administrator dashboard
export const getChartsData = async () => {
  const response = await fetchApi("/dashboard", "GET");
  if (response.error) return false;

  return response;
};
