// Requires
import { create } from "zustand";

// Services
import { getUser } from "../../services/User/user";

// Types
import type { User } from "../../types/user";

type Store = {
  userdata: User | undefined;
  getUser: () => Promise<boolean>;
  resetUser: () => Promise<void>;
};

export const useUser = create<Store>()((set) => ({
  userdata: undefined,
  getUser: async () => {
    const data = await getUser();
    set((state) => ({
      userdata: {
        ...state.userdata,
        ...data
      } as User
    }));

    if (data) {
      return true;
    }

    return false;
  },
  resetUser: async () => {
    set(() => ({ userdata: undefined }));
  }
}));
