// Icons
import { Warning2 } from "iconsax-react";

// Requires
import { Link } from "react-router-dom";

// Components
import Text from "../../../UI/Text";
import Avatar from "../../../UI/Avatar/Avatar";

// Utils
import { useUser } from "../../../../utils/store/userStore";

const ProfilComplete = () => {
  const user = useUser((state) => state.userdata);

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row gap-4">
        <Avatar link={user?.image} className="size-12" />
        <div className="flex flex-col gap-1">
          <Text className="text-sm font-semibold">
            {user?.firstName} {user?.lastName}
          </Text>
          <Text className="text-sm">{user?.profileTitle}</Text>
        </div>
      </div>
      <div className="flex flex-row gap-2 rounded-lg bg-withCustom-200 p-2">
        <Warning2 size={18} className="min-h-[16px] min-w-[16px]" />
        <Text className="flex flex-col gap-4 text-sm">
          Pour un profil plus attractif et te voir proposer plus de missions
          complète ton profil: <br />{" "}
          <ul className="ml-[10px]">
            <li>- Indique les formations que tu as suivi</li>{" "}
            <li>- Ajoute tes expériences professionnelles</li>{" "}
            <li>- Indique tes disponibilités régulières</li>
          </ul>
          <Link
            className="cursor-pointer underline-offset-4 hover:underline"
            to={"/student/profil/edit"}
          >
            Compléter mon profil
          </Link>
        </Text>
      </div>
    </div>
  );
};

export default ProfilComplete;
