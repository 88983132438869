// pricingCalculations.ts

export interface PricingResults {
  studentPricing: number;
  juwaHT: number;
  totalPriceHT: number;
  totalPriceTTC: number;
  deposit: number;
  additionalServicesCost: number;
}

export function calculatePricing(
  duration: number,
  rate: number,
  juwaPercentage: number,
  depositPercentage: number,
  TVAPercentage: number,
  contract: boolean,
  nda: boolean
): PricingResults {
  const studentPricing = duration * rate;
  const juwaHT = (studentPricing * juwaPercentage) / 100;
  const additionalServicesCost =
    contract && nda ? 50 : contract || nda ? 30 : 0;
  const totalPriceHT = studentPricing + juwaHT + additionalServicesCost;
  const totalPriceTTC =
    totalPriceHT + (TVAPercentage * (juwaHT + additionalServicesCost)) / 100;
  const deposit = studentPricing * 0.5;

  return {
    studentPricing,
    juwaHT,
    totalPriceHT,
    totalPriceTTC,
    deposit,
    additionalServicesCost: additionalServicesCost
  };
}
