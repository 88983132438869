// Icons
import { SearchNormal1 } from "iconsax-react";

// Components
import { HorizontalDivider } from "../UI/Divider";
import Premium from "../UI/Premium";
import Text from "../UI/Text";
import Title from "../UI/Title";
import UserCardLastMessage from "../Chat/UserCardLastMessage";
import Avatar from "../UI/Avatar/Avatar";

// Helpers
import { capitalizeWords } from "../../utils/helpers/capitalizeWords";

// Utils
import { useChat } from "../../context/Chat/ChatContext";
import { useUser } from "../../utils/store/userStore";

const ChatConversationsPanel = () => {
  const user = useUser((state) => state.userdata);

  const {
    conversations,
    setActiveConversation,
    isFetching,
    activeConversation
  } = useChat();

  return (
    <div className="flex h-fit flex-col gap-6 overflow-hidden rounded-lg max-md:gap-4 md:max-h-[600px] md:max-w-[300px] md:border md:p-4 md:shadow-lg">
      <div className="flex flex-row gap-4">
        <Avatar link={user?.image} className="h-[44px]" />
        <div className="flex flex-col">
          <Text className="font-semibold text-blueViolet-950">
            {capitalizeWords(user?.firstName)}
          </Text>
          <Text className="text-sm text-textGray">
            {user?.company?.displayName}
          </Text>
        </div>
      </div>
      <div className="relative inline-block h-fit">
        <SearchNormal1
          size={18}
          className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 transform text-textGray"
        />
        <input
          type="text"
          className="w-full rounded-md border py-2 pl-10 pr-4 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Rechercher"
        />
      </div>
      <HorizontalDivider />
      <div className="flex flex-col gap-4 overflow-y-scroll">
        <div className="w-full">
          <div className="flex h-[100%] flex-col gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex hidden flex-col gap-4 overflow-hidden">
                <Premium>
                  <Title type="custom" classname="text-base">
                    Vous souhaitez contacter un étudiant ?
                  </Title>
                  <Text className="text-xs">
                    Passez à un plan premium pour contacter directement les
                    étudiants et accéder à des profils qualifiés pour dynamiser
                    vos projets.
                  </Text>
                </Premium>
              </div>
              {conversations &&
                conversations.length > 0 &&
                conversations.map((item) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => {
                        if (isFetching || activeConversation?.id === item.id)
                          return;
                        setActiveConversation(item);
                      }}
                    >
                      <UserCardLastMessage key={item.id} item={item} />
                    </div>
                  );
                })}
              {!conversations ||
                (conversations && conversations.length < 1 && (
                  <Text>Aucune conversation</Text>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatConversationsPanel;
