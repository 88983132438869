// Requires
import { Link } from "react-router-dom";

// Icons
import { Discover } from "iconsax-react";

// Components
import Title from "../UI/Title";
import Button from "../UI/Button";
import Published from "./Pipe/Published";
import Validated from "./Pipe/Validated";
import Closed from "./Pipe/Closed";
import InProgress from "./Pipe/InProgress";

const Pipe = () => {
  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <Discover size={18} />
          <Title type="bloc">Résumé de la pipe</Title>
        </div>
        <Link to={"/admin/jobs/all"}>
          <Button type="full">Voir toutes les missions</Button>
        </Link>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        <Published />
        <Validated />
        <InProgress />
        <Closed />
      </div>
    </div>
  );
};

export default Pipe;
