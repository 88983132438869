// Requires
import { useEffect, useState } from "react";

// Components
import Title from "../../../UI/Title";
import HorizontalCompanyCard from "../../../Cards/Company/HorizontalCompanyCard";
import Button from "../../../UI/Button";
import Highlight from "../../../UI/Highlight";
import Text from "../../../UI/Text";

// Utils
import { useUser } from "../../../../utils/store/userStore";

// Services
import { updateStudentCompany } from "../../../../services/User/user";

// Types
import {
  CompanyInformationsType,
  CompanySearchType
} from "../../../../types/company";
import GetBiz from "../../../Ads/GetBiz";
import { SiretSearchComponent } from "../../../Misc/SiretSearchComponent";

const Company = () => {
  const _ADMIN_MODE = useUser((state) => state.userdata?.roleName === "admin");
  const _COMPANY_MODE = useUser(
    (state) => state.userdata?.roleName === "company"
  );
  const user = useUser((state) => state.userdata);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [companyInformations, setCompanyInformations] =
    useState<CompanyInformationsType>();

  const [siretSelected, setSiretSelected] = useState<string>();

  const [company, setCompany] = useState<{
    siren: string;
    activity: string;
    address: string;
    siret: string;
  } | null>(
    user && user.legalInfos
      ? {
          address: user.legalInfos.address,
          siren: user.legalInfos.siren,
          activity: user.legalInfos.activity,
          siret: user.legalInfos.siret
        }
      : null
  );

  useEffect(() => {
    setCompany({
      activity: companyInformations?.activity || "",
      address: companyInformations?.address || "",
      siren: companyInformations?.siren || "",
      siret: companyInformations?.siret || ""
    });
  }, [companyInformations]);

  useEffect(() => {
    if (user && user.legalInfos) {
      setCompany({
        address: user.legalInfos.address,
        siren: user.legalInfos.siren,
        activity: user.legalInfos.activity,
        siret: user.legalInfos.siret
      });
    }
  }, [user]);

  const handleSelectCompany = (data: CompanySearchType) => {
    setCompany({
      activity: data.nafName,
      address: data.address,
      siren: data.siren,
      siret: data.siret
    });
  };

  const setOrUpdate = async () => {
    if (company && company.siren) {
      setIsLoading(true);
      const response = await updateStudentCompany(company.siret);

      if (response) {
        setIsLoading(false);
        setSuccess(true);
        useUser.getState().getUser();

        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">SIRET</Title>
      {!user?.legalInfos?.verified ? (
        <SiretSearchComponent
          companyInformations={companyInformations}
          setCompanyInformations={setCompanyInformations}
          siretSelected={siretSelected}
          setSiretSelected={setSiretSelected}
        />
      ) : (
        <Text className="text-sm italic">
          Contacte le support si tu souhaites modifier les infos que tu as
          remplies
        </Text>
      )}
      {company && !companyInformations && company.siren && (
        <HorizontalCompanyCard initialData={company} />
      )}
      {!_COMPANY_MODE && <GetBiz />}

      {success && (
        <Highlight type="Success">
          Les modifications ont été été enregistrées.
        </Highlight>
      )}
      <div className="flex flex-row justify-end">
        <Button
          onClick={setOrUpdate}
          type={`${user?.legalInfos?.siren != company?.siren ? "full" : "disabled"}`}
        >
          Enregistrer
        </Button>
      </div>
    </div>
  );
};

export default Company;
