// Step3.tsx

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Components
import Button from "../../../../../../components/UI/Button";
import Text from "../../../../../../components/UI/Text";
import Title from "../../../../../../components/UI/Title";
import JobBudgetSummary from "../../../../../../components/Jobs/Publish/Resume";

// Custom Components
import StartDateSelector from "../../../../../../components/Jobs/Publish/Step3/StartDateSelector";
import NeedTypeSelector from "../../../../../../components/Jobs/Publish/Step3/NeedTypeSelector";
import RateInput from "../../../../../../components/Jobs/Publish/Step3/RateInput";
import PreciseAvailabilityCheckbox from "../../../../../../components/Jobs/Publish/Step3/PreciseAvailabilityCheckbox";

// Context
import { useUI } from "../../../../../../utils/context/UI";

// Helpers
import {
  getLocalStorage,
  setLocalStorage
} from "../../../../../../utils/helpers/localStorage";

// Types
export interface JobCreateType {
  startingType?: "asap" | "unspecified" | "date";
  startingAt?: string;
  type?: "punctual" | "recurent";
  duration?: number;
  endingType?: "asap" | "unspecified" | "date";
  endingAt?: string;
  rate?: number;
  studentWeeklyAvailability?: any; // Replace 'any' with the correct type if known
  // Add other fields if necessary
}

type ErrorType = {
  startingType?: string;
  startingAt?: string;
  type?: string;
  rate?: string;
  duration?: string;
  endingType?: string;
  endingAt?: string;
  checkbox?: string;
};

type WarningType = {
  duration?: string;
};

interface Step1Props {
  updateFormData: (newData: JobCreateType) => void;
  AISearch?: string;
}

const Step3: React.FC<Step1Props> = ({ updateFormData }) => {
  const [searchParams] = useSearchParams();
  const withIA = searchParams.get("ia");

  const [data, setData] = useState<JobCreateType>({});

  const [errors, setErrors] = useState<ErrorType>({});
  const [warnings, setWarnings] = useState<WarningType>({});

  const { handleNextStep, handlePrevStep, step } = useUI();

  useEffect(() => {
    const localData = getLocalStorage("step4");
    if (localData) {
      const formatedLocalData: JobCreateType = JSON.parse(localData);
      setData({
        startingType: formatedLocalData.startingType || undefined,
        startingAt: formatedLocalData.startingAt || undefined,
        type: formatedLocalData.type || undefined,
        duration: formatedLocalData.duration || undefined,
        endingType: formatedLocalData.endingType || undefined,
        endingAt: formatedLocalData.endingAt || undefined,
        rate: formatedLocalData.rate || undefined
      });
    }
  }, []);

  const nextStep = () => {
    const newError: ErrorType = {};
    const newWarning: WarningType = {};
    setWarnings(newWarning);
    setErrors(newError);

    // Validation Logic
    if (!data.startingType) {
      newError.startingType = "Vous devez choisir une date de début";
    }

    if (data.startingType === "date" && !data.startingAt) {
      newError.startingAt =
        "Vous devez choisir une date de démarrage pour la mission";
    }

    if (!data.type) {
      newError.type = "Vous devez choisir le type de besoin";
    }

    if (!data.duration || data.duration < 1) {
      newError.duration =
        "Vous devez indiquer le nombre d'heure estimé (Au minimum 1 heure)";
    }

    if (data.type === "recurent" && data.duration && data.duration > 15) {
      newWarning.duration =
        "Sauf cas exceptionnel, nos étudiants sont pour la majorité disponibles 10 à 15h par semaine maximum";
    }

    if (data.type === "punctual" && !data.endingType) {
      newError.endingType =
        "Vous devez séléctionner la date de livraison de la mission";
    }

    if (data.endingType === "date" && !data.endingAt) {
      newError.endingAt =
        "Vous devez séléctionner une date de livraison valide";
    }

    if (!data.rate || data.rate < 15) {
      newError.rate =
        "Les étudiants Juwa sont rémunérés minimum 15€/h. Veuillez augmenter votre taux horaire étudiant";
    }

    setWarnings(newWarning);

    if (Object.keys(newError).length > 0) {
      setErrors(newError);
      return;
    }

    const localData = [
      {
        key: "publishJobStep",
        value: "4"
      },
      {
        key: "step4",
        value: JSON.stringify({
          startingType: data.startingType,
          startingAt: data.startingAt,
          type: data.type,
          duration: data.duration,
          endingType: data.endingType,
          endingAt: data.endingAt,
          rate: data.rate,
          studentWeeklyAvailability: data.studentWeeklyAvailability
        })
      }
    ];

    setLocalStorage(localData);
    updateFormData(data);
    handleNextStep();
  };

  return (
    <div className="flex flex-col items-center justify-center gap-16 max-md:gap-8">
      <Title type="page">Durée et budget de la mission</Title>
      <div className="relative flex w-full flex-col gap-16 max-md:gap-8 md:flex-row">
        <div className="flex grow flex-col gap-16 max-md:gap-8">
          <div className="flex flex-col gap-12 max-md:gap-6">
            {/* Start Date Selector */}
            <StartDateSelector data={data} setData={setData} errors={errors} />

            {/* Need Type Selector */}
            <NeedTypeSelector
              data={data}
              setData={setData}
              errors={errors}
              setErrors={setErrors}
              warnings={warnings}
            />

            {/* Rate Input */}
            <RateInput
              data={data}
              setData={setData}
              errors={errors}
              setErrors={setErrors}
            />

            {/* Precise Availability Checkbox */}
            <PreciseAvailabilityCheckbox
              data={data}
              setData={setData}
              errors={errors}
            />
          </div>
        </div>
        <div className="flex flex-col gap-8 md:relative md:h-fit md:min-w-[350px] md:max-w-[350px]">
          <JobBudgetSummary type={data.type ?? "punctual"} data={data} />
        </div>
      </div>
      <div className="flex w-full items-center justify-between">
        {/* Previous Button */}
        <div onClick={handlePrevStep}>
          <Button type="fullLite">Précédent</Button>
        </div>
        <div className="flex-1 text-center">
          <Text>
            Étape {step + 1}/{withIA ? "7" : "6"}
          </Text>
        </div>
        <div>
          {/* Next Button */}
          <div onClick={nextStep}>
            <Button type="full">Suivant</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
