import { useState } from "react";

const TagSelectable = ({
  children,
  isActiveState
}: {
  children: React.ReactNode;
  isActiveState: boolean;
}) => {
  const [isActive, setIsActive] = useState<boolean>(isActiveState);

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <span
      onClick={() => setIsActive((prev) => !prev)}
      className={`w-fit cursor-pointer whitespace-nowrap rounded-lg bg-blueViolet-50 px-3 py-1 text-sm ${
        isActive ? "bg-blueViolet-600 text-white" : "bg-gray-200"
      }`}
    >
      {children}
    </span>
  );
};

export default TagSelectable;
