// Components
import Title from "../../../UI/Title";
import Button from "../../../UI/Button";
import Alternation from "../../../Cards/Alternation/Alternation";
import Text from "../../../UI/Text";
import AddAlternation from "../../../Cards/Alternation/AddAlternation";
import Highlight from "../../../UI/Highlight";

// Stores
import { useUser } from "../../../../utils/store/userStore";

// Requires
import { useState } from "react";

// Types
import { StudentAvailabilityType } from "../../../../types/user";

// Services
import { updateAvailability } from "../../../../services/User/user";

const AlternationsAndInterships = () => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useUser((state) => state.userdata);
  const [data, setData] = useState<StudentAvailabilityType[]>(
    user?.availability ? user.availability : []
  );

  const [isEditAlternation, setIsEditAlternation] = useState<
    number | undefined
  >(undefined);
  const [isEditInternship, setIsEditInternship] = useState<number | undefined>(
    undefined
  );

  const updateInternship = (newData: StudentAvailabilityType) => {
    setData((prev) => [...prev, newData]);
  };

  const updateAlternation = (newData: StudentAvailabilityType) => {
    setData((prev) => [...prev, newData]);
  };

  const remove = (index: number) => {
    setData((prev) => prev.filter((_, i) => i !== index));
  };

  const handleEditAlternation = (index: number) => {
    setIsEditAlternation(index);
    setIsEditInternship(undefined);
  };

  const handleEditInternship = (index: number) => {
    setIsEditInternship(index);
    setIsEditAlternation(undefined);
  };

  const handleEditSaveAlternation = (
    index: number,
    newData: StudentAvailabilityType
  ) => {
    setData((prev) =>
      prev.map((item, i) => (i === index ? { ...newData } : item))
    );
    setIsEditAlternation(undefined);
  };

  const handleEditSaveInternship = (
    index: number,
    newData: StudentAvailabilityType
  ) => {
    setData((prev) =>
      prev.map((item, i) => (i === index ? { ...newData } : item))
    );
    setIsEditInternship(undefined);
  };

  const cancelEditAlternation = () => {
    setIsEditAlternation(undefined);
  };

  const cancelEditInternship = () => {
    setIsEditInternship(undefined);
  };

  const AddOrUpdate = async () => {
    setIsLoading(true);
    const formatedData = {
      availability: [...data.map(({ id, ...rest }) => rest)]
    };

    const response = await updateAvailability(formatedData);

    if (response) {
      setSuccess(true);
      setIsLoading(false);

      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  };

  return (
    <div className="flex flex-col gap-8 rounded-lg border p-4 shadow-lg">
      <Title type="bloc">Alternances et stages</Title>

      <div className="flex flex-col gap-4 rounded-lg border p-4">
        <Text className="font-semibold">Alternances</Text>
        {data &&
        data.filter((item) => item.type === "alternation").length > 0 ? (
          <div className="flex flex-col gap-2">
            {data
              .filter((item) => item.type === "alternation")
              .map((item, _i) => {
                const globalIndex = data.findIndex((d) => d === item);
                return (
                  <Alternation
                    key={globalIndex}
                    index={globalIndex}
                    item={item}
                    removeCallback={remove}
                    editCallback={handleEditAlternation}
                  />
                );
              })}
          </div>
        ) : (
          <Text>Aucune période d'alternance</Text>
        )}
        <AddAlternation
          title={false}
          callback={updateAlternation}
          type="alternation"
          isEdit={isEditAlternation}
          existingData={
            isEditAlternation !== undefined
              ? data[isEditAlternation]
              : undefined
          }
          onEditSave={handleEditSaveAlternation}
          onCancelEdit={cancelEditAlternation}
        />
      </div>
      <div className="flex flex-col gap-4 rounded-lg border p-4">
        <Text className="font-semibold">Stages</Text>
        {data &&
        data.filter((item) => item.type === "internship").length > 0 ? (
          <div className="flex flex-col gap-2">
            {data
              .filter((item) => item.type === "internship")
              .map((item, _i) => {
                const globalIndex = data.findIndex((d) => d === item);
                return (
                  <Alternation
                    key={globalIndex}
                    index={globalIndex}
                    item={item}
                    removeCallback={remove}
                    editCallback={handleEditInternship}
                  />
                );
              })}
          </div>
        ) : (
          <Text>Aucune période de stage</Text>
        )}
        <AddAlternation
          title={false}
          callback={updateInternship}
          type="internship"
          isEdit={isEditInternship}
          existingData={
            isEditInternship !== undefined ? data[isEditInternship] : undefined
          }
          onEditSave={handleEditSaveInternship}
          onCancelEdit={cancelEditInternship}
        />
      </div>
      {success && <Highlight type="Success">Mise à jour réussie</Highlight>}
      {user?.availability !== data ? (
        <div onClick={AddOrUpdate} className="flex flex-row justify-end">
          <Button type="full">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      ) : (
        <div className="flex flex-row justify-end">
          <Button type="disabled">
            {isLoading ? <div className="loader" /> : "Enregistrer"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AlternationsAndInterships;
