import { type ReactNode, createContext, useContext, useState } from "react";

interface FloatNotificationInterface {
  isOpen: boolean;
  message: string;
  toggleFloatingNotification: (message: string) => void;
}

export const FloatingNotificationContext =
  createContext<FloatNotificationInterface | null>(null);

export const FloatingNotificationProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const toggleFloatingNotification = (message: string) => {
    setIsOpen(true);
    setMessage(message);

    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <FloatingNotificationContext.Provider
      value={{ isOpen, message, toggleFloatingNotification }}
    >
      {children}
    </FloatingNotificationContext.Provider>
  );
};

export const useFloatingNotification = () => {
  const context = useContext(FloatingNotificationContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
