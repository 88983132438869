// Requires
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// Services
import { searchCity } from "../../services/Misc/misc";

// Components
import Text from "../UI/Text";

// Helpers
import { capitalizeFirstLetter } from "../../utils/helpers/capitalizeFirstLetter";

const LanguageSearch = ({
  callback
}: {
  callback: (data: { id: number; label: string; zip_code: string }) => void;
}) => {
  const [query, setQuery] = useState<string>("");

  const [citySelected, setCitySelected] = useState<{
    id: number;
    label: string;
    zip_code: string;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<
    { id: number; label: string; zip_code: string }[]
  >([]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const response = await searchCity(query);
      if (response) {
        setData(response);
        setIsLoading(false);
        return;
      }
    };

    if (query.length > 3) {
      fetch();
    }
  }, [query]);

  useEffect(() => {
    if (citySelected) {
      callback(citySelected);
    }
  }, [citySelected]);

  return (
    <div className="relative flex flex-col gap-2">
      <input
        type="text"
        className="rounded-lg border p-2 focus:outline-blueViolet-600"
        placeholder="Ville ou code postal"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <AnimatePresence>
        {query && query.length > 0 && (
          <motion.div className="top-12 flex max-h-[100px] flex-col gap-2 overflow-y-scroll rounded-lg border p-4 shadow-lg">
            {isLoading && <div className="loader" />}
            {data &&
              data.length > 0 &&
              data.map((item, _id) => {
                return (
                  <div
                    onClick={() => {
                      setCitySelected(item);
                      setQuery("");
                    }}
                    key={_id}
                    className="group flex cursor-pointer flex-col gap-1 rounded-lg p-2 hover:bg-blueViolet-600 hover:text-white"
                  >
                    <Text className="font-semibold group-hover:text-white">
                      {capitalizeFirstLetter(item.label)}
                    </Text>
                    <Text className="text-sm group-hover:text-white">
                      {item.zip_code}
                    </Text>
                  </div>
                );
              })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LanguageSearch;
