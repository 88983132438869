// Components
import Text from "../../../../../components/UI/Text";
import Title from "../../../../../components/UI/Title";

// Requires
import { Link } from "react-router-dom";

// Assets
import publishJobWithAi from "../../../../../assets/publishJobWithAi.png";
import publishJobManually from "../../../../../assets/publishJobManually.png";

const PublishJob = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-8 p-4">
      <div className="flex flex-col items-center gap-2">
        <Title type="page">Publier une mission</Title>
        <Text className="text-center">
          Tout bon travail commence par un bon brief, <br /> séléctionnez la
          manière dont vous souhaitez le rédiger
        </Text>
      </div>
      <div className="flex flex-col gap-8 md:flex-row">
        <Link
          to={"publier?ia=true"}
          className="flex max-w-[400px] flex-col items-center gap-4 rounded-lg border p-4 shadow-lg"
        >
          <img src={publishJobWithAi} className="max-w-[300px]" alt="Avec IA" />
          <Text className="text-center font-semibold">Avec l'IA</Text>
          <Text className="text-center">
            Aidée de vos indications, l'IA redigera en 30s le brief de mission
            pour vous
          </Text>
        </Link>
        <Link
          to={"publier"}
          className="flex max-w-[400px] flex-col items-center gap-4 rounded-lg border p-4 shadow-lg"
        >
          <img
            src={publishJobManually}
            className="max-w-[300px]"
            alt="Sans IA"
          />
          <Text className="text-center font-semibold">Manuellement</Text>
          <Text className="text-center">
            Vous savez exactement ce que vous cherchez ? Publiez votre brief en
            3 minutes
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default PublishJob;
