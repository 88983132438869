// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

// Components
import Button from "../../UI/Button";
import Text from "../../UI/Text";

// Icons
import { Additem } from "iconsax-react";

// Types
import { StudentAvailabilityType } from "../../../types/user";

type Errors = {
  description?: string;
  start?: string;
  end?: string;
};

const AddAlternation = ({
  title,
  callback,
  type,
  isEdit,
  existingData,
  onEditSave,
  onCancelEdit
}: {
  title: boolean;
  callback: (data: StudentAvailabilityType) => void;
  type: "alternation" | "internship";
  isEdit: number | undefined;
  existingData?: StudentAvailabilityType;
  onEditSave?: (index: number, newData: StudentAvailabilityType) => void;
  onCancelEdit?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<StudentAvailabilityType>({
    description: "",
    start: "",
    end: "",
    type: type
  });

  const [errors, setErrors] = useState<Errors>();

  useEffect(() => {
    if (isEdit !== undefined && existingData) {
      setData(existingData);
      setIsOpen(true);
    }
  }, [isEdit, existingData]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const validateInputs = () => {
    const newErrors: Errors = {};
    setErrors(newErrors);

    if (!data.description || data.description.length < 50) {
      newErrors.description =
        "Tu dois indiquer une description avec 50 caractères minimum";
    }

    if (!data.start) {
      newErrors.start = "Tu dois indiquer une date de début";
    }

    if (!data.end) {
      newErrors.end = "Tu dois indiquer une date de fin";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const addOrUpdateInternship = () => {
    if (!validateInputs()) return;

    if (isEdit !== undefined && onEditSave) {
      onEditSave(isEdit, data);
    } else {
      callback(data);
    }

    closeForm();
  };

  const closeForm = () => {
    setIsOpen(false);
    setData({ description: "", start: "", end: "", type: type });
    if (onCancelEdit) {
      onCancelEdit();
    }
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)} className="flex flex-col gap-4">
        {title && (
          <div className="flex flex-row items-center gap-2">
            <Text className="font-semibold">
              Périodes {type === "internship" ? "stage" : "alternance"}
            </Text>
          </div>
        )}
        <div className="flex cursor-pointer flex-row items-center gap-4 rounded-lg border p-4 hover:border-blueViolet-600">
          <div className="flex items-center justify-center rounded-lg bg-blueViolet-50 p-4">
            <Additem size={18} />
          </div>
          <Text>
            Ajouter une période{" "}
            {type === "internship" ? "stage" : "d'alternance"}
          </Text>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="animated-alternation"
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 5 }}
            className="flex flex-col gap-4 rounded-lg border p-4"
          >
            <div className="flex flex-row gap-4">
              <div className="flex flex-col gap-2">
                <label>Date de début</label>
                <input
                  value={data.start}
                  onChange={handleInputChange}
                  type="date"
                  name="start"
                  className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
                  placeholder="JJ/MM/AA"
                />
                {errors?.start && (
                  <Text className="text-sm text-red-500">{errors?.start}</Text>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <label>Date de fin</label>
                <input
                  value={data.end}
                  onChange={handleInputChange}
                  type="date"
                  name="end"
                  className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
                  placeholder="JJ/MM/AA"
                />
                {errors?.end && (
                  <Text className="text-sm text-red-500">{errors?.end}</Text>
                )}
              </div>
            </div>
            <textarea
              value={data.description}
              onChange={handleInputChange}
              name="description"
              className="h-fit rounded-lg border p-2 focus:outline-blueViolet-600"
              placeholder="Description"
            />
            <div className="flex w-full flex-row items-end justify-between">
              <Text className="text-sm text-red-500">
                {errors?.description}
              </Text>
              <Text className="text-sm">
                {data.description.length}/500 caractères
              </Text>
            </div>
            <div className="flex flex-row justify-end gap-2">
              <div onClick={closeForm}>
                <Button type="cancel">Annuler</Button>
              </div>
              <div onClick={addOrUpdateInternship}>
                <Button type="full">
                  {isEdit !== undefined ? "Enregistrer" : "Ajouter"}
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddAlternation;
