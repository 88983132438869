// Requires
import { motion } from "framer-motion";
import React, { useState } from "react";

// Components
import Content from "../../../../Bloc/Content";
import { BlocTitle } from "../../../../Bloc/Title";
import Button from "../../../../UI/Button";
import Title from "../../../../UI/Title";
import Text from "../../../../UI/Text";
import JoinFile from "../../../../UI/File/JoinFile";

// Helpers
import { formatDate } from "../../../../../utils/helpers/formatDate";

// Types
import { CertificationType } from "../../../../../types/user";

type Errors = {
  name?: string;
  issuedDate?: string;
  attachment?: string;
  link?: string;
  noContent?: string;
};

const Form = ({
  callBackForm,
  initialData,
  cancel
}: {
  callBackForm: (data: CertificationType) => void;
  initialData?: CertificationType;
  cancel: () => void;
}) => {
  const [certification, setCertification] = useState<CertificationType>({
    name: initialData?.name || "",
    issuedDate: formatDate(initialData?.issuedDate) || "",
    attachment: undefined,
    link: initialData?.link || ""
  });

  const [errors, setErrors] = useState<Errors>({});

  const handleCertificationInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setCertification((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (data: File[]) => {
    setCertification((prev) => ({
      ...prev,
      attachment: data[0]
    }));
  };

  const handleFileRemove = () => {
    setCertification((prev) => ({
      ...prev,
      attachment: undefined
    }));
  };

  const AddOrUpdate = () => {
    const newErrors: Errors = {};
    setErrors(newErrors);

    if (!certification.name || certification.name.length < 5) {
      newErrors.name =
        "Tu dois indiquer le titre de ta certification (minimum 5 caractères)";
    }

    if (!certification.issuedDate) {
      newErrors.issuedDate =
        "Tu dois indiquer la date d'obtention de ta certification";
    }

    if (!certification.attachment && !certification.link) {
      newErrors.noContent =
        "Tu dois indiquer un lien ou un fichier pour ta certification";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    callBackForm(certification);
  };

  return (
    <motion.div
      key="certificationKey"
      initial={{ y: 5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0, y: 5 }}
      className="flex flex-col gap-8 rounded-lg p-4 md:border"
    >
      <BlocTitle>
        <Title type="bloc">
          {initialData
            ? `Modifier ${initialData.name}`
            : "Ajouter une certification"}
        </Title>
      </BlocTitle>
      <Content>
        <div className="flex flex-col items-start gap-6">
          <div className="flex w-full flex-col gap-2">
            <Text className="font-semibold">
              Titre <span className="text-red-500">*</span>
            </Text>
            <input
              name="name"
              value={certification.name}
              onChange={handleCertificationInputChange}
              type="text"
              placeholder="Exemple: Développeur PHP"
              className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
            />
            {errors.name && (
              <Text className="text-sm text-red-500">{errors.name}</Text>
            )}
          </div>
          <div className="flex w-full flex-col gap-6 md:flex-row">
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex flex-row items-end gap-1">
                <Text>Lien de verification</Text>
                <Text className="text-sm">- Facultatif</Text>
              </div>
              <input
                value={certification.link}
                name="link"
                onChange={handleCertificationInputChange}
                type="text"
                placeholder="Lien vers la certification"
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
            </div>
            <div className="flex flex-1 flex-col gap-2">
              <Text>
                Date d'obtiention<span className="text-red-500">*</span>
              </Text>
              <input
                name="issuedDate"
                value={certification.issuedDate}
                onChange={handleCertificationInputChange}
                type="date"
                className="w-full rounded-lg border p-2 focus:outline-blueViolet-600"
              />
              {errors.issuedDate && (
                <Text className="text-sm text-red-500">
                  {errors.issuedDate}
                </Text>
              )}
            </div>
          </div>
          <div className="w-full">
            <JoinFile onChange={handleFileChange} onRemove={handleFileRemove} />
          </div>
          {errors.noContent && (
            <Text className="text-sm text-red-500">{errors.noContent}</Text>
          )}
          <div className="flex w-full flex-row justify-end gap-4">
            <div onClick={cancel}>
              <Button type="cancel">Annuler</Button>
            </div>
            <div onClick={AddOrUpdate}>
              <Button type="full">
                {initialData ? "Modifier" : "Ajouter"}
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </motion.div>
  );
};

export default Form;
