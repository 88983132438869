// Icons
import { TableDocument } from "iconsax-react";

// Components
import Content from "../../../../components/Bloc/Content";
import { BlocTitleWithButton } from "../../../../components/Bloc/Title";
import Title from "../../../../components/UI/Title";
import Table from "../../../../components/Student/Actives/Table";
import Filters from "../../../../components/Table/Filters";

// Utils
import { _STUDENT } from "../../../../utils/misc";

const InProgress = () => {
  return (
    <div className="flex h-full flex-col gap-8">
      <Title type="page">Mes missions en cours</Title>
      <div className="w-full flex-row gap-8 md:flex md:w-auto">
        <div className="flex grow flex-col gap-4 rounded-lg border p-4 shadow-lg">
          <BlocTitleWithButton>
            <div className="flex flex-row items-center gap-2">
              <TableDocument size={18} />
              <Title type="bloc">Missions en cours</Title>
            </div>
            {/* <div className="flex flex-row items-center gap-4">
              <CSVTag />
              <PDFTag />
            </div> */}
          </BlocTitleWithButton>
          <Content>
            <div className="flex flex-col rounded-lg border">
              <div className="p-2">
                <Filters typeList={_STUDENT.filters.actives} />
              </div>
              <Table />
            </div>
          </Content>
        </div>
      </div>
    </div>
  );
};

export default InProgress;
