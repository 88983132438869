// Requires
import { fetchApi } from "../Misc/api";

// Types
type Filters = {
  search?: string | null;
  categories: string | null;
  city: string | null;
  degrees: string | null;
  skills: string | null;
  languages: string | null;
  school: string;
};

// Retrieve all students (page, limit, filters)
export const getStudents = async (
  page: number,
  limit: number,
  filters?: Filters
) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString()
  });

  if (filters) {
    if (filters.search) queryParams.append("search", filters.search);
    if (filters.categories)
      queryParams.append("categories", filters.categories);
    if (filters.city) queryParams.append("city", filters.city);
    if (filters.degrees) queryParams.append("degrees", filters.degrees);
    if (filters.skills) queryParams.append("skills", filters.skills);
    if (filters.languages) queryParams.append("languages", filters.languages);
    if (filters.school) queryParams.append("school", filters.school);
  }

  const response = await fetchApi(`/student?${queryParams.toString()}`, "GET");
  if (response.error) return false;

  return response;
};

// Set if school certificate is valid or not
export const toggleSchoolCertificate = async (studentId: number) => {
  const response = await fetchApi(
    `/student/${studentId}/school-certificate`,
    "PATCH"
  );
  if (response.error) return false;

  return true;
};

// Set if call talent finder is done or not
export const toggleCallTalentFinder = async (studentId: number) => {
  const response = await fetchApi(
    `/student/${studentId}/has-done-call`,
    "PATCH"
  );
  if (response.error) return false;

  return true;
};
