// Icons
import { Document } from "iconsax-react";

// Requires
import { Link } from "react-router-dom";
// Components
import Button from "../UI/Button";
import Date from "../UI/Date";
import Text from "../UI/Text";

// Types
import { FileType } from "../../types/file";

export const Doc = ({ item }: { item: FileType }) => {
  return (
    <Link
      to={item.url}
      className="flex flex-row flex-nowrap justify-between gap-4"
    >
      <div className="flex flex-col">
        <Text className="font-semibold">{item.fileName}</Text>
        <Date date={item.createdAt} format="DD/MM/YY" />
      </div>
      <div className="flex flex-row items-center gap-4">
        <Button type="lite">{item.fileExt.toUpperCase()}</Button>
      </div>
    </Link>
  );
};

export const DocWithButton = ({
  item
}: {
  item: { url: string; id: number; fileExt: string; fileName: string };
}) => {
  return (
    <div className="flex flex-row flex-nowrap justify-between gap-4">
      <div className="flex flex-row items-center gap-2">
        <Document size={22} />
        <div className="flex flex-col text-xs">
          <Text className="font-semibold">{item.fileName}</Text>
          <Text className="text-sm text-textGray">
            {item.fileExt.toUpperCase()}
          </Text>
        </div>
      </div>
      <div className="flex flex-row items-center gap-4">
        <Link to={item.url}>
          <Button type="fullLite" className="text-sm">
            Télécharger
          </Button>
        </Link>
      </div>
    </div>
  );
};
