// Icons
import { Trash } from "iconsax-react";

// Types
import type { CertificationType } from "../../types/user";

// Components
import Date from "../UI/Date";
import Text from "../UI/Text";

const CertificationCard = ({
  item,
  index,
  onDelete,
  onEdit
}: {
  index: number;
  item: CertificationType;
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
}) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-blueViolet-200 p-4">
      <div className="relative h-[180px] w-full rounded-lg bg-blueViolet-50">
        <div className="absolute bottom-3 left-3 flex items-center justify-center rounded-lg bg-gray-500 bg-opacity-50 px-2 py-1">
          <Date
            className="text-sm text-white"
            format="DD/MM/YY"
            date={item.issuedDate}
          />
        </div>
        <div className="absolute right-3 top-3 flex flex-row gap-4 rounded-lg bg-white p-2">
          <Trash
            onClick={() => onDelete(index)}
            className="cursor-pointer"
            size={18}
            color="red"
          />
          {/* <Edit2
            className="cursor-pointer"
            size={18}
            onClick={() => onEdit(index)}
          /> */}
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <Text className="font-semibold">{item.name}</Text>
        <Text>{item.link}</Text>
      </div>
    </div>
  );
};

export default CertificationCard;
