// Icons
import { InfoCircle } from "iconsax-react";

// Components
import Text from "../../UI/Text";

const UpdateDisponibility = () => {
  return (
    <div className="flex w-full flex-row gap-4 rounded-lg border-2 border-blue-400 bg-blue-300 bg-opacity-30 p-4">
      <InfoCircle size={18} className="text-blue-400" />
      <div className="flex flex-col gap-1">
        <Text className="text-sm">
          Pensez à mettre à jour vos disponibilités, cela nous permet de vous
          recommander auprès des entreprises.
        </Text>
        <Text className="text-xs underline">Valider mes disponibilités</Text>
      </div>
    </div>
  );
};

export default UpdateDisponibility;
