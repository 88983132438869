// Icons
import { MedalStar } from "iconsax-react";

// Components
import Text from "../UI/Text";
import Title from "../UI/Title";
import LittleCertificationCard from "../Cards/Certification/LittleCertificationCard";

// Types
import { CertificationType } from "../../types/user";

const Certification = ({ data }: { data?: CertificationType[] }) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border p-4 shadow-lg">
      <div className="flex flex-row items-center gap-2">
        <MedalStar size={18} />
        <Title type="bloc">Certifications</Title>
      </div>
      <div className="flex flex-col gap-4">
        {data &&
          data.length > 0 &&
          data.map((item, _id) => (
            <LittleCertificationCard key={_id} item={item} />
          ))}
        {!data || (data && data.length < 1 && <Text>Aucun certificat</Text>)}
      </div>
    </div>
  );
};

export default Certification;
