// Components
import Actions from "./Actions";
import UserInformations from "./UserInformations";

interface ChatHeaderProps {
  OpenLD?: () => void;
  ldStatic: boolean;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ OpenLD, ldStatic }) => {
  const handleOpenLD = () => {
    if (OpenLD) {
      OpenLD();
    }
  };

  return (
    <div className="flex flex-row flex-wrap justify-between gap-2 rounded-t-lg border-b max-md:pb-2 md:border md:p-4 md:shadow-lg">
      <UserInformations />
      <Actions OpenLD={handleOpenLD} ldStatic={ldStatic} />
    </div>
  );
};

export default ChatHeader;
