import { type ReactNode, createContext, useContext, useState } from "react";

interface UIInterface {
  setSidebar: (state: boolean) => void;
  toggleSidebar: () => void;
  sidebarIsOpen: boolean;
  toggleLastDocuments: () => void;
  lastDocumentsIsOpen: boolean;
  toggleChatDropdown: () => void;
  chatDropdownIsOpen: boolean;
  findFilterIsOpen: boolean;
  toggleFindFilter: () => void;
  step: number;
  direction: string;
  isProvider: boolean;
  handleNextStep: () => void;
  handlePrevStep: () => void;
  resetStep: () => void;
  specificStep: (number: number) => void;
  handleIsProvider: () => void;
}

export const UIContext = createContext<UIInterface | null>(null);

export const UIProvider = ({ children }: { children: ReactNode }) => {
  // Bloc Visibility
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true);
  const [lastDocumentsIsOpen, setLastDocumentsIsOpen] = useState<boolean>(true);
  const [chatDropdownIsOpen, setChatDropdownIsOpen] = useState<boolean>(false);
  const [findFilterIsOpen, setFindFilterIsOpen] = useState<boolean>(false);

  // Student Onboarding
  const [step, setStep] = useState<number>(0);
  const [isProvider, setIsProvider] = useState<boolean>(false);
  const [direction, setDirection] = useState<string>("forward");

  const toggleSidebar = () => {
    setSidebarIsOpen((prev) => !prev);
  };

  const setSidebar = (state: boolean) => {
    setSidebarIsOpen(state);
  };

  const toggleLastDocuments = () => {
    setLastDocumentsIsOpen((prev) => !prev);
  };

  const toggleChatDropdown = () => {
    setChatDropdownIsOpen((prev) => !prev);
  };

  const toggleFindFilter = () => {
    setFindFilterIsOpen((prev) => !prev);
  };

  const handleNextStep = () => {
    if (direction === "backward") {
      setDirection("forward");
    } else if (direction === "forward") {
      setStep((prev) => Math.max(prev + 1, 0));
    }
  };

  const handlePrevStep = () => {
    if (direction === "forward") {
      setDirection("backward");
    } else if (direction === "backward") {
      // Ideally, we should use the following line to update the step in the localStorage
      // setLocalStorage([{ key: "studentStep", value: (step - 1).toString() }]);
      setStep((prev) => Math.max(prev - 1, 0));
    }
  };

  // When direction change, update step
  // useEffect(() => {
  //   if (direction === "forward") {
  //     setStep((prev) => Math.min(prev + 1, 14));
  //   } else if (direction === "backward") {
  //     setStep((prev) => Math.max(prev - 1, 0));
  //   }
  // }, [direction]);

  const resetStep = () => {
    setStep(0); // Réinitialisation du step à 1
  };

  const specificStep = (number: number) => {
    if (number) {
      setStep(number);
    }
  };

  const handleIsProvider = () => {
    setIsProvider((prev) => !prev);
  };

  return (
    <UIContext.Provider
      value={{
        setSidebar,
        sidebarIsOpen,
        isProvider,
        handleIsProvider,
        toggleSidebar,
        specificStep,
        lastDocumentsIsOpen,
        toggleLastDocuments,
        chatDropdownIsOpen,
        toggleChatDropdown,
        findFilterIsOpen,
        toggleFindFilter,
        step,
        handleNextStep,
        handlePrevStep,
        direction,
        resetStep
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => {
  const context = useContext(UIContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
