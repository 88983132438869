// Requires
import { useEffect, useState } from "react";

// Components
import BestClients from "../../Charts/BestClients";
import CA from "../../Charts/CA";
import LastMissionsInProgress from "../../Charts/LastMissionsInProgress";
import MissionsByCategories from "../../Charts/MissionsByCategories";
import Parity from "../../Charts/Parity";
import Pipe from "../../Charts/Pipe";
import ProfilTIH from "../../Charts/ProfilTIH";
import UsersStats from "../../Charts/UsersStats";

// Types
import { DashboardCharts } from "../../../types/admin";

// Services (why?)
import { getChartsData } from "../../../services/Admin/Charts";

const AdminDashboard = () => {
  const [dashboardStats, setDashboardStats] = useState<DashboardCharts>({
    jobsByCategory: [],
    studentGenderStats: undefined,
    tihStats: undefined,
    userCounts: undefined
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await getChartsData();
      setDashboardStats(response);
    };

    fetch();
  }, []);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-row gap-8">
        <div className="flex h-fit grow flex-col gap-4">
          <CA />
          <MissionsByCategories data={dashboardStats.jobsByCategory} />
          <LastMissionsInProgress />
        </div>
        <div className="flex min-w-[500px] max-w-[500px] flex-col gap-4">
          <BestClients />
          <UsersStats
            data={
              dashboardStats.userCounts ?? { companyCount: 0, studentCount: 0 }
            }
          />
          <Parity
            data={
              dashboardStats.studentGenderStats ?? {
                counts: { female: 0, male: 0, notSpecified: 0, other: 0 },
                percentages: { female: 0, male: 0, notSpecified: 0, other: 0 }
              }
            }
          />
          <ProfilTIH data={dashboardStats.tihStats ?? { tih: 0, nonTih: 0 }} />
        </div>
      </div>
      <Pipe />
    </div>
  );
};

export default AdminDashboard;
