// Required Imports
import { useEffect, useLayoutEffect, useRef, useState } from "react";

// Context and Utilities
import { useChat } from "../../context/Chat/ChatContext";
import { useUser } from "../../utils/store/userStore";

// Components
import ChatDate from "./DateSeparator";
import Receiver from "./Receiver";
import Sender from "./Sender";

const ChatContainer = () => {
  const {
    messages,
    loadMessagesBeforeId,
    page,
    setIsExpertMode,
    activeConversation,
    isExpertMode
  } = useChat();

  const [openMessageId, setOpenMessageId] = useState<number | null>(null);
  const user = useUser((state) => state.userdata);

  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef<boolean>(false);
  const prevScrollHeightRef = useRef<number>(0);
  const prevScrollTopRef = useRef<number>(0);

  // Handle loading more messages when scrolling near the top
  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const handleScroll = async () => {
      if (
        container.scrollTop < 800 && // Threshold to trigger loading
        !isLoadingRef.current &&
        messages &&
        (messages?.messages.length ?? 0) >= 20 &&
        page !== -1
      ) {
        isLoadingRef.current = true;
        setIsLoading(true);

        // Capture current scrollHeight and scrollTop before loading new messages
        prevScrollHeightRef.current = container.scrollHeight;
        prevScrollTopRef.current = container.scrollTop;

        await loadMessagesBeforeId();

        isLoadingRef.current = false;
        setIsLoading(false);
      }
    };

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [loadMessagesBeforeId, messages, page]);

  // Adjust scroll position after new messages are loaded
  useLayoutEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    if (isLoadingRef.current) {
      // Calculate the new scrollHeight after loading messages
      const newScrollHeight = container.scrollHeight;
      const scrollDifference = newScrollHeight - prevScrollHeightRef.current;

      // Maintain the scroll position by adjusting scrollTop
      container.scrollTop = prevScrollTopRef.current + scrollDifference;

      // Reset loading refs
      isLoadingRef.current = false;
      setIsLoading(false);
    }
  }, [messages]);

  // Handle autoscroll when new messages arrive
  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container || !messages) return;

    if (messages.shouldAutoScroll) {
      // Scroll to the bottom if autoscroll is enabled
      container.scrollTop = container.scrollHeight;
    }
    // If autoscroll is false, do not change the scroll position
    // This ensures the user's view remains unchanged
  }, [messages]);

  return (
    <>
      <div
        ref={chatContainerRef}
        className={
          "flex w-full flex-col gap-4 overflow-y-scroll md:h-[450px]" +
          " max-md:h-[55vh] md:border-x" +
          " bg-red-blue p-4"
        }
      >
        <ChatDate />
        <div className="flex flex-col gap-4">
          {isLoading && (messages?.messages.length ?? 0) > 0 && (
            <div className="flex h-fit w-full items-center justify-center">
              <div className="loader" />
            </div>
          )}
          {messages?.messages ? (
            (messages?.messages.length ?? 0) > 0 ? (
              messages?.messages.map((item, id) =>
                item.sender.id === user?.id ? (
                  <Receiver
                    isOpen={openMessageId === item.messageId}
                    setIsOpen={setOpenMessageId}
                    key={id}
                    item={item}
                  />
                ) : (
                  <Sender
                    isOpen={openMessageId === item.messageId}
                    setIsOpen={setOpenMessageId}
                    key={id}
                    item={item}
                  />
                )
              )
            ) : (
              <div className="flex h-fit w-full items-center justify-center text-gray-600">
                Aucun message
              </div>
            )
          ) : (
            <div className="flex h-fit w-full items-center justify-center">
              <div className="loader" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatContainer;
