// Context
import { ChatProvider } from "../../../context/Chat/ChatProvider";

// Utils
import { useMobile } from "../../../utils/mobile/responsive";

// Components
import { LastDocumentsStatic } from "../../Documents/LastDocuments";
import LastTransactions from "../../Transactions/LastTransactions";
import Card from "../Card";

const CompanyDashboard = () => {
  const isMobile = useMobile();

  const cards: {
    title: string;
    buttonText: string;
    subscriptionRequired: boolean;
    plan: number;
    tooltip: string;
    buttonLink: string;
    image: string;
  }[] = [
    {
      title: "Recrutez les meilleurs étudiants avec une expertise précise",
      buttonText: "Publier une mission ponctuelle",
      plan: 1,
      subscriptionRequired: false,
      tooltip:
        "Publiez, nous revenons vers vous avec 3 talents présélectionnés, parfaitement adaptés à votre besoin.",
      buttonLink: "/company/jobs/publier-une-mission",
      image: "https://storagejuwa.blob.core.windows.net/static/IMG_8487.CR2"
    },
    {
      title:
        "Boostez vos projets avec le renfort intellectuel de nos étudiants",
      buttonText: "Publier une mission récurrente",
      plan: 1,
      subscriptionRequired: false,
      tooltip:
        "Publiez, nous revenons vers vous avec 3 talents présélectionnés, parfaitement adaptés à votre besoin. Intégrez un étudiant dans votre projet pour décupler votre agilité !",
      buttonLink: "/company/jobs/publier-une-mission",
      image: "https://storagejuwa.blob.core.windows.net/static/IMG_8362.CR2"
    },
    {
      title:
        "Un accompagnement sur-mesure pour vous faciliter vos recrutements",
      buttonText: isMobile
        ? "Contacter mon expert"
        : "Contacter mon expert recrutement Juwa",
      plan: 2,
      subscriptionRequired: true,
      tooltip:
        "Votre expert Juwa vous conseille et vous décharge de tous vos recrutements étudiants, en freelance, en alternance ou en stage",
      buttonLink: "/company/expert",
      image: "https://storagejuwa.blob.core.windows.net/static/IMG_8299.CR2"
    }
  ];

  return (
    <>
      <div className="flex h-fit flex-col gap-6 md:flex-row">
        {cards.map((card) => (
          <Card key={card.title} card={card} />
        ))}
      </div>
      <div className="flex flex-col gap-6 md:flex-row">
        <ChatProvider>
          <LastDocumentsStatic attachmentsType="all" className="flex-1" />
        </ChatProvider>
        <LastTransactions className="flex-1" />
      </div>
    </>
  );
};

export default CompanyDashboard;
