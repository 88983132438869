// Requires
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";

// Icons
import { ArrowDown2 } from "iconsax-react";

// Services
import { getCategories, getSkills } from "../../services/Misc/misc";

// Components
import Text from "../UI/Text";

enum StudentDegree {
  notGraduated,
  highSchool,
  bachelor1,
  bachelor2,
  bachelor3,
  bachelor4,
  bachelor5,
  bachelor6,
  bachelor7,
  bachelor8
}

const Dropdown = ({
  title,
  type,
  handleUpdateFilter
}: {
  title: string;
  type: string;
  handleUpdateFilter?: (type: string, value: number) => void;
}) => {
  const [dropdownIsOpen, setDropDownIsOpen] = useState<boolean>(false);
  const [elementsSelected, setElementsSelected] = useState<
    { id: number; title: string }[]
  >([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [categories, setCategories] =
    useState<{ id: number; title: string }[]>();
  const [skills, setSkills] = useState<{ id: number; title: string }[]>();
  const [degrees, setDegrees] = useState<{ id: number; title: string }[]>([
    { id: StudentDegree.notGraduated, title: "Non diplômé" },
    { id: StudentDegree.highSchool, title: "Bac" },
    { id: StudentDegree.bachelor1, title: "Bac+1" },
    { id: StudentDegree.bachelor2, title: "Bac+2" },
    { id: StudentDegree.bachelor3, title: "Bac+3" },
    { id: StudentDegree.bachelor4, title: "Bac+4" },
    { id: StudentDegree.bachelor5, title: "Bac+5" },
    { id: StudentDegree.bachelor6, title: "Bac+6" },
    { id: StudentDegree.bachelor7, title: "Bac+7" },
    { id: StudentDegree.bachelor8, title: "Bac+8" }
  ]);

  const addElement = useCallback(
    (id: number, title: string) => {
      setElementsSelected((prev) => {
        const isAlreadySelected = prev.some((el) => el.id === id);
        if (isAlreadySelected) {
          return prev.filter((el) => el.id !== id);
        }
        if (handleUpdateFilter) handleUpdateFilter(type, id);
        return [...prev, { id: id, title: title }];
      });
    },
    [handleUpdateFilter, type]
  );

  const removeElement = useCallback(
    (id: number) => {
      setElementsSelected((prev) => prev.filter((el) => el.id !== id));
      if (handleUpdateFilter) handleUpdateFilter(type, id);
    },
    [handleUpdateFilter, type]
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropDownIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (type === "domaine") {
        const response = await getCategories();
        setCategories(response);
      }
      if (type === "skills") {
        const response = await getSkills();
        setSkills(response);
      }
    };

    fetch();
  }, [type]);

  // Filtrer les éléments affichés dans le dropdown pour exclure ceux déjà sélectionnés
  const filteredCategories = categories?.filter(
    (category) => !elementsSelected.some((el) => el.id === category.id)
  );
  const filteredSkills = skills?.filter(
    (skill) => !elementsSelected.some((el) => el.id === skill.id)
  );
  const filteredDegrees = degrees?.filter(
    (degrees) => !elementsSelected.some((el) => el.id === degrees.id)
  );

  return (
    <div
      ref={dropdownRef}
      className="flex w-full cursor-pointer flex-col gap-2"
    >
      <Text className="font-semibold">{title}</Text>
      <div
        className="relative flex flex-row items-center justify-between gap-4 rounded-lg border p-2"
        onClick={() => setDropDownIsOpen((prev) => !prev)}
      >
        <div className="flex grow flex-row flex-wrap gap-2">
          {elementsSelected.length < 1 && (
            <Text className="text-sm">
              Aucun {title.toLowerCase()} sélectionné
            </Text>
          )}
          {elementsSelected.map((item) => (
            <span
              key={item.id}
              onClick={() => removeElement(item.id)}
              className="h-fit cursor-pointer rounded-lg bg-blueViolet-600 px-2 py-1 text-xs text-white"
            >
              {item.title}
            </span>
          ))}
        </div>
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: dropdownIsOpen ? 180 : 0 }}
          className="h-fit"
        >
          <ArrowDown2 size={18} className="min-h-[18px] min-w-[18px]" />
        </motion.div>
      </div>
      <AnimatePresence>
        {dropdownIsOpen && (
          <motion.div
            className="flex max-h-[150px] w-full flex-row flex-wrap gap-2 overflow-scroll rounded-lg border bg-whiteSimple p-2 shadow-lg"
            initial={{ y: 15, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 15, opacity: 0 }}
          >
            {type === "skills" &&
              filteredSkills &&
              filteredSkills.length > 0 &&
              filteredSkills.map((item) => (
                <span
                  key={item.id}
                  onClick={() => addElement(item.id, item.title)}
                  className="cursor-pointer rounded-lg bg-white px-2 py-1 text-xs"
                >
                  {item.title}
                </span>
              ))}
            {type === "domaine" &&
              filteredCategories &&
              filteredCategories.length > 0 &&
              filteredCategories.map((item) => (
                <span
                  key={item.id}
                  onClick={() => addElement(item.id, item.title)}
                  className="cursor-pointer rounded-lg bg-white px-2 py-1 text-xs"
                >
                  {item.title}
                </span>
              ))}
            {type === "degrees" &&
              filteredDegrees &&
              filteredDegrees.length > 0 &&
              filteredDegrees.map((item) => (
                <span
                  key={item.id}
                  onClick={() => addElement(item.id, item.title)}
                  className="cursor-pointer rounded-lg bg-white px-2 py-1 text-xs"
                >
                  {item.title}
                </span>
              ))}
            {((type === "skills" && filteredSkills?.length === 0) ||
              (type === "domaine" && filteredCategories?.length === 0)) && (
              <Text className="text-sm">Aucun résultat</Text>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dropdown;
