type degreeType = {
  name: string;
  value: string;
};

const degrees: degreeType[] = [
  { name: "notGraduated", value: "Non spécifié" },
  { name: "highSchool", value: "Lycée" },
  { name: "bachelor", value: "Bac" },
  { name: "bachelor1", value: "Bac +1" },
  { name: "bachelor2", value: "Bac +2" },
  { name: "bachelor3", value: "Bac +3" },
  { name: "bachelor4", value: "Bac +4" },
  { name: "bachelor5", value: "Bac +5 | Master" },
  { name: "bachelor6", value: "Bac +6" },
  { name: "bachelor7", value: "Bac +7" },
  { name: "bachelor8", value: "Bac +8 | Doctorat" }
];

export const getDegreeName = (value: string | undefined) => {
  if (value) {
    const degree = degrees.find((degree) => degree.name === value);
    if (degree) return degree.value;

    return "Non spécifié";
  }
};
