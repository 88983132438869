// Requires
import { motion } from "framer-motion";

// Components
import { HorizontalDivider } from "../../Divider";
import Notify from "./Notify";
import Text from "../../Text";

// Types
import type { NotificationType } from "../../../../types/notification";

const NotifyDrawer = ({
  data,
  updateCount
}: {
  data: NotificationType[];
  updateCount: () => void;
}) => {
  if (!data || (data && data.length < 1)) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 15 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 15 }}
        className="absolute right-[-10px] top-10 z-40 flex w-fit min-w-[200px] max-w-[280px] flex-col gap-4 rounded-lg border bg-white p-4 shadow-lg"
      >
        <Text>Aucune notification</Text>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 15 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 15 }}
      className="absolute right-[-10px] top-10 z-40 flex max-h-[250px] w-fit min-w-[200px] max-w-[300px] flex-col gap-4 overflow-y-auto rounded-lg border bg-white p-4 shadow-lg"
    >
      {data.map((item, _i) => (
        <div className="flex flex-col flex-nowrap gap-2" key={_i}>
          <Notify data={item} updateCount={updateCount} />
          <HorizontalDivider />
        </div>
      ))}
    </motion.div>
  );
};

export default NotifyDrawer;
