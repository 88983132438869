// Requires
import { Navigate } from "react-router-dom";

// Components
import ProtectedRoute from "../pages/Protected/Common/ProtectedRoute";

// Pages
import { Dashboard } from "../pages/Protected/Common/Dashboard";
import Messages from "../pages/Protected/Common/Messages";
import { ChatProvider } from "../context/Chat/ChatProvider";

export const commonRouters = {
  path: "",
  element: <ProtectedRoute />,
  children: [
    {
      index: true,
      element: <Navigate to="dashboard" /> // Redirect to Dashboard
    },
    {
      path: "dashboard",
      element: <Dashboard />
    },
    {
      path: "messages",
      element: (
        <ChatProvider>
          <Messages />
        </ChatProvider>
      )
    }
  ]
};
